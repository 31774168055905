import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';
import Typography from '@mui/material/Typography';


export const HeliumSwitcherDialog = ({
  onClose, open, title, children,
}) => {
  const handleClose = () => { onClose(); };

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <Typography component='span' variant='h6' marginBottom='0px' color='black'>
            {`Switch ${title}`}
          </Typography>
          <Typography variant='body2' sx={{ color: 'black' }}>
            {`Select which ${title} you would like to access below`}
          </Typography>
        </DialogTitle>
        <DialogContent>
          {children}
        </DialogContent>
      </Dialog>
    </>
  );
};
