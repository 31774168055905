import Logger from 'components/Common/Logger';
import get from 'lodash/get';
import moment from 'moment';
import { ChartSubTypeMap, InputTypesMap, YAxisPlacement } from 'components/CleanRooms/constants';
import { US_DATE_FORMAT } from 'utils/appConstants';
import {
  abbrNum, formatDecimal, formatNumber, snakeToDisplayString,
} from 'utils/jsUtils';


const BRACKET_CONTENT_REGEX = /\(([^)]+)\)/;
const OPERATIONS = ['formatNumber', 'formatDecimal', 'abbrNum'];

export const dimensionSorters = {
  [InputTypesMap.STRING.key]: (i, j) => ((i.dimensions[0].value > j.dimensions[0].value) ? 1 : -1),
  [InputTypesMap.INTEGER.key]: (i, j) => ((Number(i.dimensions[0].value)
    > Number(j.dimensions[0].value)) ? 1 : -1),
  [InputTypesMap.DATE.key]: (i, j) => (moment(i.dimensions[0].value).valueOf()
    - moment(j.dimensions[0].value).valueOf()),
  [InputTypesMap.TIMESTAMP.key]: (i, j) => (moment(i.dimensions[0].value).valueOf()
    - moment(j.dimensions[0].value).valueOf()),
  default: (i, j) => ((i.dimensions[0].value > j.dimensions[0].value) ? 1 : -1),
};

export const getDimensionValue = (dimensionInputType, value, useUTC = true) => {
  switch (dimensionInputType) {
    case InputTypesMap.DATE.key:
    case InputTypesMap.TIMESTAMP.key:
      return useUTC ? moment.utc(value).format(US_DATE_FORMAT)
        : moment(value).format(US_DATE_FORMAT);
    default:
      return value;
  }
};

export const getDimensionDisplayName = (dimensionKey, question) => {
  const dimension = question.dimensions.find(i => i.name === dimensionKey);
  return dimension?.displayName || snakeToDisplayString(dimensionKey);
};

export const getMetricByDisplayName = (displayName, question) => question.metrics
  .find(i => i.displayName === displayName);

export const getMetricDisplayName = (fact, question) => {
  const m = question.metrics.find(i => i.name === fact);
  return m?.displayName || snakeToDisplayString(fact);
};

export const getMetricSeriesChartType = (fact, question) => {
  const metric = question.metrics.find(i => i.name === fact);
  return get(metric, 'metricConfig.chartType', 'column').toLowerCase();
};

export const getMetricsDisplayNames = (facts, question) => facts
  .map(f => getMetricDisplayName(f, question));

/**
 * Determines if passed string is expression with or without brackets
 * @param exp
 * @param ops
 * @returns {boolean|*}
 */
const isExpression = (exp) => {
  if (!exp) return false;
  let clause = exp;
  const brackets = exp.match(BRACKET_CONTENT_REGEX);
  if (brackets) {
    clause = clause.replace(brackets[0], '');
    if (OPERATIONS.includes(clause)) {
      return true;
    }
    return false;
  }
  else {
    return OPERATIONS.includes(exp);
  }
};

/**
 * Evaluate given expression array from bottom down for getting right to left
 * evaluation of values
 * @param expStack
 * @param value
 * @returns {*}
 */
const evaluateExpressionStack = (expStack, value) => {
  const stack = [...expStack].reverse();
  let strParams = [];

  const result = stack.reduce((acc, item) => {
    let clause = item;
    const brackets = item.match(BRACKET_CONTENT_REGEX);
    if (brackets) {
      clause = clause.replace(brackets[0], '');
      strParams = brackets[1].split(',');
    }
    switch (clause) {
      case 'formatNumber':
        return formatNumber(acc);
      case 'abbrNum':
        return abbrNum.apply(this, [acc, strParams[0] === 'true']);
      case 'formatDecimal': {
        const decimalNum = strParams.length > 0 ? parseInt(strParams[0], 10) : 2;
        return formatDecimal.apply(this, [acc, decimalNum]);
      }
      default:
    }
    return acc;
  }, value);
  return result;
};

/**
 * Retrieves array of string & sub array stack from valueFormatting string
 * @param expression
 * @param value
 * @returns {*[]}
 */
const getExpressionArray = (expression, value) => {
  try {
    const parts = expression.split('^');
    const r = [];
    for (let i = 0; i < parts.length; i += 1) {
      const t = [];
      while (i <= parts.length && isExpression(parts[i])) {
        t.push(parts[i]);
        i += 1;
      }
      if (t.length === 0) {
        r.push(parts[i]);
      }
      else {
        r.push(t);
      }
    }
    return r;
  }
  catch (e) {
    Logger.error(e, `Failed to retrieve expression ${expression} with value`);
    return [value];
  }
};

/**
 * Evaluates given expression string
 * @param expression
 * @param value
 * @param showZero
 * @returns {*}
 */
export const evaluateValueFormattingExpression = (expression, value, showZero = false) => {
  try {
    const parts = getExpressionArray(expression);
    const formattedParts = parts.reduce((acc, item) => {
      if (Array.isArray(item)) {
        acc.push(evaluateExpressionStack(item, value, showZero));
      }
      else {
        acc.push(item);
      }
      return acc;
    }, []);
    return formattedParts.join('');
  }
  catch (e) {
    Logger.error(e, `Failed to evaluate expression ${expression} with value ${value}`);
    return value;
  }
};

export const getValueFormattingConfigMap = question => question.metrics
  .reduce((acc, item, index) => {
    acc[index] = get(item, 'metricConfig.valueFormatting');
    return acc;
  }, {});

export const getStrokeWidthFromSeries = series => series.map((i) => {
  if ([ChartSubTypeMap.BAR.key.toLowerCase(),
    ChartSubTypeMap.COLUMN.key.toLowerCase()].indexOf(i.type.toLowerCase()) !== -1) {
    return 0;
  }
  else {
    return 2;
  }
});

export const getMarkerSizeFromSeries = series => series.map((i) => {
  if ([ChartSubTypeMap.BAR.key, ChartSubTypeMap.AREA.key].indexOf(i.type) !== -1) {
    return 0;
  }
  else {
    return 5;
  }
});

const allowedCombinedYAxis = [YAxisPlacement.y0.key, YAxisPlacement.y1.key];

const getYAxisOppositeValue = (placement) => {
  switch (placement) {
    case YAxisPlacement.y0.key:
    case YAxisPlacement.yLeft.key:
      return false;
    case YAxisPlacement.y1.key:
    case YAxisPlacement.yRight.key:
      return true;
    default:
      return false;
  }
};

const getSeriesName = (metric, generatedMap) => {
  const position = get(metric, 'metricConfig.yAxisPosition', YAxisPlacement.y0.key);
  if (generatedMap[position]) {
    return generatedMap[position].join(', ');
  }

  return metric.displayName;
};

export const getYAxisConfig = (question, availableMetrics) => {
  const axisMap = {};

  let result = question.metrics.filter(k => availableMetrics.indexOf(k.name) !== -1)
    .reduce((acc, item) => {
      const placement = get(item.metricConfig, 'yAxisPosition', YAxisPlacement.y0.key);
      const valueFormatting = get(item.metricConfig, 'valueFormatting', undefined);
      let show = true;
      if (!axisMap[placement] && allowedCombinedYAxis.indexOf(placement) > -1) {
        axisMap[placement] = [item.displayName];
        show = axisMap[placement] ? axisMap[placement].length === 1 : true;
      }
      else if (axisMap[placement]) {
        axisMap[placement] = [...axisMap[placement], item.displayName];
        show = false;
      }
      acc.push({
        opposite: getYAxisOppositeValue(placement),
        show,
        valueFormatting,
      });
      return acc;
    }, []);
  result = result.map((item, index) => ({
    ...item,
    seriesName: getSeriesName(question.metrics
      .filter(k => availableMetrics.indexOf(k.name) !== -1)[index], axisMap),
  }));

  return result;
};
