import React from 'react';
import moment from 'moment/moment';
import {
  Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
} from '@mui/material';
import { HButton } from 'BaseComponents';
import { UI_DISPLAY_DATE_TIME_FORMAT } from 'utils/appConstants';


const GPGKeyList = ({ keys, onDownloadKey }) => (
  <TableContainer component={Card}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell sx={{ minWidth: '200px' }}>Fingerprint</TableCell>
          <TableCell sx={{ minWidth: '200px' }}>Identity</TableCell>
          <TableCell sx={{ minWidth: '200px' }}>Created At</TableCell>
          <TableCell sx={{ minWidth: '200px' }}>Updated At</TableCell>
          <TableCell sx={{ minWidth: '180px' }}>Updated By</TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {
          keys.map(row => (
            <TableRow key={row.ID}>
              <TableCell>
                {row.fingerprint || 'N/A'}
              </TableCell>
              <TableCell>
                {row.identity || 'N/A'}
              </TableCell>
              <TableCell>
                {row.timeAudit.createdAt ? moment(row.timeAudit.createdAt).format(UI_DISPLAY_DATE_TIME_FORMAT) : 'N/A'}
              </TableCell>
              <TableCell>
                {row.timeAudit.updatedAt ? moment(row.timeAudit.updatedAt).format(UI_DISPLAY_DATE_TIME_FORMAT) : 'N/A'}
              </TableCell>
              <TableCell>
                {
                  row.userAudit.updatedByUser.name
                    ? row.userAudit.updatedByUser.name
                    : (row.userAudit.createdByUser.name || 'N/A')
                }
              </TableCell>
              <TableCell>
                <HButton
                  size='small'
                  variant='contained'
                  onClick={() => {
                    onDownloadKey(row);
                  }}
                >
                  Download
                </HButton>
              </TableCell>
            </TableRow>
          ))
        }
      </TableBody>
    </Table>
  </TableContainer>
);

export default GPGKeyList;
