import React from 'react';
import {
  Card, CardActions, Grid, Tooltip, Typography,
} from '@mui/material';
import { HButton } from 'BaseComponents';
import { InfoOutlined } from '@mui/icons-material';


const GPGHeader = ({ title, button, tooltipText }) => (
  <Card sx={{ display: 'flex', p: 2 }}>
    <Grid container spacing={2}>
      <Grid item xs={6} container direction='row' display='flex' alignItems='center'>
        <Typography variant='h5'>
          {title}
        </Typography>
        <Tooltip
          title={tooltipText}
          componentsProps={{
            tooltip: {
              sx: { textAlign: 'justify', maxWidth: '400px' },
            },
          }}
        >
          <InfoOutlined fontSize='medium' sx={{ ml: 1 }} />
        </Tooltip>
      </Grid>
      <Grid item xs={6}>
        <CardActions sx={{ p: 0, justifyContent: 'flex-end' }}>
          <HButton onClick={button.onClick} variant='contained' color='secondary' startIcon={button.icon}>
            {button.text}
          </HButton>
        </CardActions>
      </Grid>
    </Grid>
  </Card>
);

export default GPGHeader;
