import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import DestinationModal from 'components/InternalAdmin/DestinationModal';
import Grid from '@mui/material/Grid';
import ListViewTable from 'components/Common/ListViewTable';
import PageHeader from 'components/Common/PageHeader';
import React from 'react';
import SyntheticDataTable
  from 'components/InternalAdmin/SyntheticDataTable';
import { crumbs } from 'components/Common/BreadcrumbsNavigation';
import { fetchSyntheticDatasetsList } from 'pages/InternalAdmin/organization/redux/SyntheticDatasets';
import { useDispatch, useSelector } from 'react-redux';


const SyntheticDatasets = () => {
  const dispatch = useDispatch();
  const orgID = useSelector(state => state.session.user.primaryOrganization.organization.ID);
  const orgName = useSelector(state => state.session.user.primaryOrganization.organization.name);
  const datasetList = useSelector(state => state.syntheticDatasets?.datasets.list);
  const [list, updateList] = React.useState(datasetList);
  const [runModalDetails, setRunModalDetails] = React.useState();


  React.useEffect(() => {
    updateList(datasetList);
  }, [datasetList]);

  React.useEffect(() => {
    dispatch(fetchSyntheticDatasetsList.ignite(orgID));
  }, [dispatch, orgID]);


  return (
    <>
      <PageHeader title={`${orgName}: Synthetic Data`} breadcrumbs={[crumbs.helium, crumbs.internalAdmin]} />
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <ListViewTable response={list} list={list?.datasets}>
                <SyntheticDataTable
                  list={list?.datasets}
                  openModal={setRunModalDetails}
                />
              </ListViewTable>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {runModalDetails
        ? (
          <DestinationModal
            modalDetails={runModalDetails}
            onClose={() => setRunModalDetails(undefined)}
          />
        ) : null}
    </>
  );
};

export default SyntheticDatasets;
