import React from 'react';
import { FlowNode, FlowNodeBody } from 'pages/Flow/Nodes/FlowNode';
import { Typography } from '@mui/material';


export const NodeError = ({ type, selected }) => (
  <FlowNode type={type} selected={selected}>
    <FlowNodeBody>
      <div>
        <Typography>
          Error loading this node
        </Typography>
      </div>
    </FlowNodeBody>
  </FlowNode>
);
