import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import OrganizationRoleDetailsForm
  from 'pages/OrganizationManagement/organization/pages/OrganizationRoles/OrganizationRoleDetailsForm';
import PageHeader from 'components/Common/PageHeader';
import React from 'react';
import { Card } from '@mui/material';
import { Redirect, useParams } from 'react-router-dom';
import {
  createRole,
  fetchOrganizationProducts,
  fetchRole,
  updateRole,
} from 'redux/actions/IamActions';
import { crumbs } from 'components/Common/BreadcrumbsNavigation';
import { organizationManagement } from 'utils/spaUrls';
import { useDispatch, useSelector } from 'react-redux';


const OrganizationRole = () => {
  const { roleId: id } = useParams();
  const roleDetails = useSelector(state => state.iam.roleDetail);
  const { meta } = roleDetails;
  const orgId = useSelector(state => state.session.user.primaryOrganization.organization.ID);
  const products = useSelector(state => state.iam.organizationProducts
    .filter(p => p.permissions?.length > 0));
  const [submitted, setSubmitted] = React.useState(false);

  const dispatch = useDispatch();
  React.useEffect(() => {
    if (orgId && id) {
      dispatch(fetchRole(orgId, id));
    }
    dispatch(fetchOrganizationProducts(orgId));
  }, [dispatch, id, orgId]);

  if (meta.redirect && submitted) {
    return <Redirect to={organizationManagement.organization.roles.list} />;
  }

  const saveRole = (data, orgUid) => {
    setSubmitted(true);
    if (id) {
      return dispatch(updateRole(orgUid, data.ID, { role: data }));
    }
    return dispatch(createRole(orgUid, { roles: [data] }));
  };

  return (
    <>
      <PageHeader title={id ? 'Edit Role' : 'Create Role'} breadcrumbs={[crumbs.home, crumbs.orgRoleList]} />
      <Grid item>
        <Card>
          <CardContent>
            <OrganizationRoleDetailsForm
              save={data => saveRole(data, orgId)}
              details={id ? roleDetails : {}}
              products={products}
            />
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

export default OrganizationRole;
