import Grid from '@mui/material/Grid';
import React, { memo } from 'react';
import { FlowNodeContextProvider } from 'pages/Flow/Nodes/FlowNode/FlowNodeContext';
import { flowPalette } from 'pages/Flow/HelpFlow/contacts';
import { habuColors } from 'Theme';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';


const StyledGrid = styled(Grid, {
  shouldForwardProp: (prop) => !['selected', 'nodeSize', 'type'].includes(prop),
})(({
  theme, nodeSize, type, selected,
}) => ({
  maxWidth: nodeSize === 'MD' ? '400px' : '150px',
  border: nodeSize === 'MD' ? '1px solid' : '',
  borderColor: nodeSize === 'MD' && type ? flowPalette(theme, type).borderColor : '',
  padding: nodeSize === 'MD' ? '' : '8px',
  ...(selected ? {
    borderRadius: 2,
    background: nodeSize === 'MD' ? habuColors.neutral[10] : habuColors.neutral[5],
    boxShadow: '0px 4px 10px 0px rgba(69, 72, 77, 0.12), 0px 1px 2px 0px rgba(59, 66, 77, 0.20)',
    borderTop: nodeSize === 'SM' && type ? `2px solid ${flowPalette(theme, type).borderColor}` : '',
  } : {
    borderRadius: 0,
    background: nodeSize === 'MD' ? theme.palette.background.paper : '',
  }),
}));


export const FlowNode = memo(({
  children, selected, type, customNodeSize,
}) => {
  const canvasNodeSize = useSelector(state => state.flow.nodeSize);
  const nodeSize = customNodeSize || canvasNodeSize;

  return (
    <FlowNodeContextProvider value={{ selected, type }}>
      <StyledGrid
        container
        data-testid='flow-node'
        type={type}
        nodeSize={nodeSize}
        selected={selected}
      >
        {children}
      </StyledGrid>
    </FlowNodeContextProvider>
  );
});
