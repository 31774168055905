import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import React from 'react';
import Switch from 'components/Common/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { HButton } from 'BaseComponents';
import { StyledGrid } from 'components/IAM/Admin/StyledComponents';
import { productStatusDisplayMap } from 'pages/InternalAdmin/constants';
import { styled } from '@mui/material/styles';


const StyledTableCell = styled(TableCell)({
  color: '#000',
  fontWeight: 'bold',
});

const ProductListTable = ({
  accProducts = [], updateAccProducts, productList = [], onSubmit,
}) => {
  const onToggle = (product, isChecked) => {
    if (!isChecked) {
      updateAccProducts(accProducts.filter(i => i.ID !== product.ID));
    }
    else {
      updateAccProducts([...accProducts, product]);
    }
  };


  return (
    <Card>
      <CardContent>
        <Grid item xs={12}>
          <Table size='small'>
            <TableHead>
              <TableRow>
                <TableCell width='5%' />
                <StyledTableCell align='left' width='20%'> Name</StyledTableCell>
                <StyledTableCell align='left' width='20%'> Description</StyledTableCell>
                <StyledTableCell align='left' width='20%'> Status</StyledTableCell>
                <StyledTableCell align='left' width='20%'> Product Group</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {productList.map(row => (
                <TableRow key={row.ID}>
                  <TableCell>
                    <Switch
                      checked={accProducts.some(el => el.ID === row.ID)}
                      value={row.ID}
                      onChange={e => onToggle(row, e.target.checked)}
                    />
                  </TableCell>
                  <TableCell>
                    {row.displayName}
                  </TableCell>
                  <TableCell>
                    {row.description}
                  </TableCell>
                  <TableCell>
                    {productStatusDisplayMap[row.status]}
                  </TableCell>
                  <TableCell>
                    {row.productGroup}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
        <StyledGrid item xs={12}>
          <HButton
            color='primary'
            size='small'
            variant='contained'
            onClick={onSubmit}
          >
            Update Product Access Settings
          </HButton>
        </StyledGrid>
      </CardContent>
    </Card>
  );
};

export default ProductListTable;
