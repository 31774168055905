import { PERMISSION } from 'redux/actions/Types';


const initialState = {
  permissionDetail: {
    name: '',
    displayName: '',
    isInternal: false,
  },
};
const permissionReducer = (state = initialState, action) => {
  switch (action.type) {
    case PERMISSION.CREATE_PERMISSION_SUCCESS:
      return { ...state, permissionDetail: action.payload };
    case PERMISSION.FETCH_PRODUCT_PERMISSION_SUCCESS:
      return { ...state, productPermissions: action.payload };
    case PERMISSION.UPDATE_PRODUCT_PERMISSIONS_SUCCESS:
      return { ...state, productPermissions: action.payload };
    default:
      return state;
  }
};

export default permissionReducer;
