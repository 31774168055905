import { AUTHENTICATION as authActions } from 'redux/actions/Types';


const AuthenticationReducer = (state = {}, action) => {
  switch (action.type) {
    case authActions.GET_USER_AUTH_TYPE:
      return { loading: true };

    case authActions.GET_USER_AUTH_TYPE_SUCCESS:
      return { authorization: action.payload, loading: false };

    case authActions.GET_USER_AUTH_TYPE_FAILURE:
      return { error: action.payload, loading: false };

    case authActions.SEND_LOGIN:
      return { ...state, loading: true };

    case authActions.LOGIN_SUCCESS:
      return { user: action.payload, loading: false };

    case authActions.LOGIN_FAILURE:
      return { ...state, error: action.payload, loading: false };

    case authActions.REQUEST_LOGOUT:
      return { user: undefined };

    case authActions.AUTH_TOKEN_EXPIRED:
      return { ...state, authTokenExpired: true };

    case authActions.REFRESH_TOKEN_EXPIRED:
      return { ...state, refreshTokenExpired: true };

    case authActions.RESET_REDIRECT:
      return { ...state, redirect: false };

    case authActions.SWITCH_ACCOUNT:
      return { ...state, loading: true };

    case authActions.SWITCH_ACCOUNT_SUCCESS:
      return {
        user: { ...state.user, primaryAccount: action.payload },
        redirect: true,
        loading: false,
      };

    case authActions.SWITCH_ACCOUNT_FAILURE:
      return { ...state, redirect: false };

    case authActions.SWITCH_ORGANIZATION:
      return { ...state, loading: true };

    case authActions.SWITCH_ORGANIZATION_FAILURE:
      return { user: { ...state.user, primaryOrganization: action.payload, loading: false } };

    case authActions.SWITCH_ORGANIZATION_SUCCESS: {
      const {
        user, organization, authToken, refreshToken,
      } = action.payload;
      return {
        user: {
          ...state.user, user, authToken, refreshToken, primaryOrganization: organization,
        },
        loading: false,
      };
    }

    case authActions.REQUEST_FORGOT_PASSWORD_LINK_SUCCESS:
      return { ...state, forgotPassword: { email: action.payload, success: true } };

    case authActions.REQUEST_FORGOT_PASSWORD_LINK_FAILURE:
      return { ...state, forgotPassword: { error: action.payload } };

    case authActions.CHANGE_PASSWORD:
      return { redirect: false, loading: true };

    case authActions.CHANGE_PASSWORD_SUCCESS:
      return { redirect: true, loading: false };

    case authActions.CHANGE_PASSWORD_FAILURE:
      return { redirect: false, loading: false };

    case authActions.PERMISSION_DENIED:
      return { ...state, neededPermissionList: action.payload };

    default:
      return state;
  }
};

// selectors
export const activeOrgSelector = state => state?.session?.user?.primaryOrganization?.organization;

export default AuthenticationReducer;
