import LoadingButton from '@mui/lab/LoadingButton';
import PageHeader from 'components/Common/PageHeader';
import React, { useEffect } from 'react';
import {
  Box, Button, MenuItem, Paper, Stack, TextField,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { crumbs } from 'components/Common/BreadcrumbsNavigation';
import { organizationManagement } from 'utils/spaUrls';
import { useAddIdentity, useGetIdentity, useUpdateIdentity } from 'api/OrganizationIdentity';
import { useHistory, useRouteMatch } from 'react-router-dom';


const FOUR_ALPHANUMERIC_REGEX = /^[a-zA-Z0-9]{4}$/;

const IdentityForm = () => {
  const history = useHistory();
  const { path, params } = useRouteMatch();
  const isEdit = path === organizationManagement.organization.identity.edit();

  const {
    isLoading: isLoadingIdentity,
    data: { domainIdentifier } = {},
  } = useGetIdentity(params.id, isEdit);
  const {
    isLoading: isAddingIdentity,
    mutate: addIdentity,
  } = useAddIdentity();
  const {
    isLoading: isUpdatingIdentity,
    mutate: updateIdentity,
  } = useUpdateIdentity();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      displayName: '',
      domainIdentifierID: '',
      credentialType: 'RampID',
    },
  });

  useEffect(() => {
    if (isEdit && domainIdentifier) {
      reset({
        ID: domainIdentifier.ID,
        displayName: domainIdentifier.displayName,
        domainIdentifierID: domainIdentifier.domainIdentifierID,
        credentialType: 'RampID',
      });
    }
  }, [domainIdentifier, isEdit, reset]);

  const handleCancel = () => {
    history.push(organizationManagement.organization.identity.list);
  };

  const onSubmit = (data) => {
    const payload = { domainIdentifier: data };
    if (isEdit) {
      updateIdentity(payload, {
        onSuccess: () => handleCancel(),
      });
    }
    else {
      addIdentity(payload, {
        onSuccess: () => handleCancel(),
      });
    }
  };

  return (
    <>
      <PageHeader
        title={isEdit ? 'Edit Identity' : 'Add Identity'}
        breadcrumbs={[crumbs.home, crumbs.orgIdentity]}
      />
      <Paper sx={{ p: 2 }}>
        <Box
          component='form'
          onSubmit={handleSubmit(onSubmit)}
          sx={{ width: { lg: '75%' } }}
          noValidate
        >
          <Controller
            name='displayName'
            control={control}
            rules={{ required: 'Name is required' }}
            render={({ field }) => (
              <TextField
                fullWidth
                label='Name'
                variant='outlined'
                required
                error={!!errors.displayName}
                helperText={errors.displayName ? errors.displayName.message : ''}
                {...field}
              />
            )}
          />
          <Controller
            name='credentialType'
            control={control}
            rules={{ required: 'Credential Type is required' }}
            render={({ field }) => (
              <TextField
                select
                label='Credential Type'
                variant='outlined'
                fullWidth
                margin='normal'
                required
                error={!!errors.credentialType}
                helperText={errors.credentialType ? errors.credentialType.message : ''}
                {...field}
              >
                <MenuItem value='RampID'>RampID</MenuItem>
              </TextField>
            )}
          />
          <Controller
            name='domainIdentifierID'
            control={control}
            rules={{
              required: 'RampID Domain Identifier is required',
              pattern: {
                value: FOUR_ALPHANUMERIC_REGEX,
                message: 'Valid format is four alphanumeric characters',
              },
            }}
            render={({ field }) => (
              <TextField
                label='RampID Domain Identifier'
                variant='outlined'
                fullWidth
                margin='normal'
                required
                inputProps={{ maxLength: 4 }}
                error={!!errors.domainIdentifierID}
                helperText={errors.domainIdentifierID ? errors.domainIdentifierID.message : 'Four Characters'}
                {...field}
              />
            )}
          />
          <Stack direction='row' justifyContent='flex-end' spacing={1} py={1} mt={3}>
            <Button
              variant='outlined'
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <LoadingButton
              type='submit'
              variant='contained'
              color='primary'
              loading={isLoadingIdentity || isAddingIdentity || isUpdatingIdentity}
            >
              {isEdit ? 'Update' : 'Save'}
            </LoadingButton>
          </Stack>
        </Box>
      </Paper>
    </>
  );
};

export default IdentityForm;
