import ControlPointIcon from '@mui/icons-material/ControlPoint';
import Grid from '@mui/material/Grid';
import LiveRampLogoBlack from 'assets/logo/liveRampLogoBlack.svg';
import React, { useContext } from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import { FlowContext } from 'pages/Flow/FlowContextProvider';
import { HButton } from 'BaseComponents';


export const FlowLandingPage = () => {
  const {
    handleCreateFlow,
    isLoadingCreateFlow,
  } = useContext(FlowContext);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid container spacing={1} justifyContent='center' alignItems='center'>
              <Grid container item xs={12} justifyContent='center'>
                <img
                  width='150'
                  height='100'
                  src={LiveRampLogoBlack}
                  alt='Permission denied'
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h4' mb={3} align='center'>
                  Create your first Flow
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography variant='h5' align='center'>
                  Flows allow you to connect multiple
                  Questions, Activations, and other functions to get you to your desired result.
                </Typography>
              </Grid>
              <Grid item xs={12} align='center'>
                <HButton
                  onClick={handleCreateFlow}
                  disabled={isLoadingCreateFlow}
                  color='primary'
                  variant='contained'
                >
                  <ControlPointIcon fontSize='small' style={{ marginRight: '5px' }} />
                  New Flow
                </HButton>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
