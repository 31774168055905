import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import MultiSelect from 'components/Common/MultiSelect';
import PropertyConfigurationBowlineForm from 'components/InternalAdmin/PropertyConfiguration/PropertyConfigurationBowlineForm';
import React from 'react';
import SectionHeader from 'components/IAM/Admin/SectionHeader';
import TextField from '@mui/material/TextField';
import { HButton } from 'BaseComponents';
import { Link } from 'react-router-dom';
import { QuarterdeckConstants } from 'components/InternalAdmin/PropertyConfiguration/service';
import { internalAdmin } from 'utils/spaUrls';


const PropertyConfigurationForm = ({ configuration, onSubmit, property }) => {
  const [collectPageViews, setCollectPageViews] = React.useState(
    QuarterdeckConstants.collectPageViews,
  );
  const [consent, setConsent] = React.useState(QuarterdeckConstants.consent[0].value);
  const [partners, setPartners] = React.useState(QuarterdeckConstants.partners);
  const [version, setVersion] = React.useState(QuarterdeckConstants.version[0].value);
  const [bowline, setBowline] = React.useState();

  React.useEffect(() => {
    if (configuration) {
      setCollectPageViews(configuration.details.collectPageViews);
      setConsent(configuration.details.consent);
      setPartners(configuration.details.partners);
      setVersion(configuration.details.version);
      setBowline(configuration.details.bowline);
    }
  }, [configuration]);

  const handleSubmit = (e) => {
    // Substitute Select All for the actual partners' list
    const selPartners = (partners === 'Select All') ? QuarterdeckConstants.partners : partners;
    onSubmit({
      details: {
        collectPageViews,
        consent,
        partners: selPartners,
        version,
        bowline,
      },
    });
    e.preventDefault();
  };

  return (
    <Card>
      <CardContent>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} direction='column'>
            <Grid item sm={12}>
              <SectionHeader title={`${property.name} - ${property.url}`} />
            </Grid>
            <Grid item sm={6}>
              <TextField
                label='Version'
                required
                onChange={e => setVersion(e.target.value)}
                fullWidth
                value={version}
                variant='outlined'
                select
              >
                {QuarterdeckConstants.version.map(
                  v => <MenuItem key={v.value} value={v.value}>{v.name}</MenuItem>,
                )}
              </TextField>
            </Grid>
            <Grid item sm={6}>
              <FormControlLabel
                label='Data Collection on Page'
                labelPlacement='end'
                control={(
                  <Checkbox
                    checked={collectPageViews}
                    onChange={e => setCollectPageViews(e.target.checked)}
                    value='primary'
                    inputProps={{ 'aria-label': 'Data Collection on Page' }}
                  />
                  )}
              />
            </Grid>
            <Grid item sm={6}>
              <MultiSelect
                disabled={!collectPageViews}
                label='Partners'
                menuOptions={QuarterdeckConstants.partners}
                selectAllLabel='Select All'
                selectedValues={partners}
                updateSelectedValues={setPartners}
              />
            </Grid>
            <Grid item sm={12}>
              <PropertyConfigurationBowlineForm details={bowline} updateBowline={setBowline} />
            </Grid>
            <Grid item sm={6}>
              <Box marginTop={2} display='flex' justifyContent='flex-end'>
                <Box marginRight={5}>
                  <HButton component={Link} to={internalAdmin.organization.organizationProperties} variant='contained'>
                    Cancel
                  </HButton>
                </Box>
                <HButton color='primary' type='submit' variant='contained'>
                  Save Configuration
                </HButton>
              </Box>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
};

export default PropertyConfigurationForm;
