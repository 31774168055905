import React from 'react';
import {
  OrganizationUsersContextProvider,
} from 'pages/OrganizationManagement/organization/pages/OrganizationUsers/OrganizationUsersTable/OrganizationUsersContextProvider';
import { OrganizationUsersPageLayout } from 'pages/OrganizationManagement/organization/pages/OrganizationUsers/OrganizationUsersTable/OrganizationUsersPageLayout';


export const OrganizationUsersTable = ({
  list, sendEmail, deleteUser, currentOrgId,
}) => (
  <OrganizationUsersContextProvider
    list={list}
    sendEmail={sendEmail}
    deleteUser={deleteUser}
    currentOrgId={currentOrgId}
  >
    <OrganizationUsersPageLayout />
  </OrganizationUsersContextProvider>
);
