import { useEffect, useRef } from 'react';


export const useResizeTableHeight = (height) => {
  const tableRef = useRef(null);

  useEffect(() => {
    const updateSize = () => {
      const bodyEl = document.body;
      const html = document.documentElement;

      const windowHeight = Math.min(
        bodyEl.scrollHeight, bodyEl.offsetHeight,
        html.clientHeight, html.scrollHeight, html.offsetHeight,
      );
      const elementPosition = tableRef.current?.offsetTop;

      if (tableRef?.current) {
        const newWindowHeight = windowHeight - elementPosition - 80;
        tableRef.current.style.height = `${newWindowHeight}px`;
      }
    };

    if (!height) {
      window.addEventListener('resize', updateSize);
      updateSize();
    }
    else {
      tableRef.current.style.height = `${height}px`;
    }

    return () => window.removeEventListener('resize', updateSize);
  }, [height, tableRef]);

  return tableRef;
};
