import { useHistory } from 'react-router-dom';


const SupportPortal = () => {
  const history = useHistory();
  const url = 'https://habu.zendesk.com/hc/en-us/requests';
  window.open(url, '_blank');
  history.goBack();
  return null;
};

export default SupportPortal;
