import Logger from 'components/Common/Logger';
import http from 'utils/http';
import {
  call, put,
} from 'redux-saga/effects';
import { setFlowDetailsError, setFlowDetailsSuccess } from 'redux/actions/FlowAction';
import { showAlert } from 'redux/actions/AlertActions';


export function* fetchFlowDetails({ url, payload }) {
  const { error, cleanRoomFlow } = yield call(http.get, url);

  if (error) {
    Logger.error(error, `Could not fetch flow details. Url: ${url}. Error: ${error}`);
    yield put(showAlert({ message: error, type: 'error' }));
    return yield put(setFlowDetailsError(payload));
  }

  yield put(showAlert({ message: 'Fetch flow details successfully', type: 'success' }));
  return yield put(setFlowDetailsSuccess(cleanRoomFlow));
}
