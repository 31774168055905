import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { ContentCopy } from '@mui/icons-material';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { habuColors } from 'Theme';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch } from 'react-redux';


const NodeIDHandler = React.memo(({ nodeID, nodeQuestionName, prefix = 'Node ID:' }) => {
  const dispatch = useDispatch();

  const handleCopy = () => {
    dispatch(showAlert({ message: 'Node ID Copied', type: 'success' }));
  };

  return (
    <Box display='flex' flexDirection='column'>
      {nodeQuestionName && nodeQuestionName}
      <Box display='flex' alignItems='center'>
        <Typography variant='subtitle3' sx={{ color: habuColors.neutral[60] }}>
          {`${prefix} ${nodeID}`}
        </Typography>
        <Tooltip title='Copy Node ID'>
          <IconButton onClick={handleCopy}>
            <CopyToClipboard text={nodeID}>
              <ContentCopy />
            </CopyToClipboard>
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
});

NodeIDHandler.propTypes = {
  nodeID: PropTypes.string.isRequired,
  nodeQuestionName: PropTypes.string,
  prefix: PropTypes.string,
};

NodeIDHandler.defaultProps = {
  nodeQuestionName: '',
  prefix: 'Node ID:',
};

export default NodeIDHandler;
