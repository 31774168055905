import NoData from 'components/Common/NoData';
import React, { memo, useContext } from 'react';
import { DataContext } from 'BaseComponents/HDataTable/DataContextProvider';
import { HCardsList } from 'BaseComponents/HDataTable/HCardsList';
import { HTable } from 'BaseComponents/HDataTable/HTable';
import { LayoutStyles } from 'components/Common/ListControls';
import { ResizableContextProvider } from 'BaseComponents/HDataTable/ResizableContextProvider';


export const HLayoutView = memo(({ layout }) => {
  const {
    rows,
    cardElement,
  } = useContext(DataContext);

  const haveData = rows?.length > 0;

  if (!haveData) {
    return (
      <NoData />
    );
  }

  if (cardElement === undefined) {
    return (
      <ResizableContextProvider>
        <HTable />
      </ResizableContextProvider>
    );
  }

  return (haveData && layout === LayoutStyles.list.text ? (
    <ResizableContextProvider>
      <HTable />
    </ResizableContextProvider>
  ) : (
    <HCardsList />
  )
  );
});

HLayoutView.displayName = 'HLayoutView';

