import AlertsRequestControlPanel from 'pages/InternalAdmin/organization/pages/AlertsRequest/AlertsRequestControlPanel';
import React, { useContext } from 'react';
import moment from 'moment';
import {
  AlertsRequestPageContext,
} from 'pages/InternalAdmin/organization/pages/AlertsRequest/AlertsRequestContext';
import {
  CreateAlertCell,
  StatusCell,
  ViewChatCell,
} from 'pages/InternalAdmin/organization/pages/AlertsRequest/AlertsRequestTableCells';
import { HDataTable } from 'BaseComponents';
import { US_DATE_TIME_FORMAT } from 'utils/appConstants';
import { activeOrgSelector } from 'redux/reducer/AuthenticationReducer';
import { alertBuilder } from 'utils/urlConstants';
import { useSelector } from 'react-redux';


const columns = [
  {
    field: 'displayID',
    name: 'Chat ID',
    width: 150,
  },
  {
    field: 'STATUS',
    name: 'Status',
    width: 150,
    component: StatusCell,
  },
  {
    field: 'REQUESTED_AT',
    name: 'Requested At',
    width: 200,
    valueGetter: ({ row }) => (moment(row.requestedAt).format(US_DATE_TIME_FORMAT)),
  },
  {
    field: 'requestedBy',
    name: 'Requested By',
    width: 200,
  },
  {
    field: 'View Chat',
    name: '',
    width: 150,
    component: ViewChatCell,
  },
  {
    field: 'Create Alert',
    name: '',
    width: 170,
    component: CreateAlertCell,
  },
];

const AlertsRequestTable = () => {
  const { crId } = useContext(AlertsRequestPageContext);
  const { ID: orgId } = useSelector(activeOrgSelector);

  const dataURL = alertBuilder(orgId).alertsRequest(crId);
  const filtersURL = alertBuilder(orgId).alertsRequestFilters(crId);

  return (
    <HDataTable
      columns={columns}
      dataURL={dataURL}
      filtersURL={filtersURL}
      tableName='alert-request-table'
      dataBaseField='chatMetadata'
      controlPanel={<AlertsRequestControlPanel />}
    />
  );
};

export default AlertsRequestTable;
