import { DATA_IN_ADMIN } from 'redux/actions/Types';
import { dataIn } from 'utils/urlConstants';


export const fetchAllImportSources = () => ({
  type: DATA_IN_ADMIN.FETCH_ALL_IMPORT_SOURCES,
  url: dataIn().listAllDataSources,
});

export const fetchAllImportSourcesSuccess = payload => ({
  type: DATA_IN_ADMIN.FETCH_ALL_IMPORT_SOURCES_SUCCESS,
  payload,
});

export const fetchAllImportSourcesError = payload => ({
  type: DATA_IN_ADMIN.FETCH_ALL_IMPORT_SOURCES_ERROR,
  payload,
});

export const fetchOrgImportSources = orgId => ({
  type: DATA_IN_ADMIN.FETCH_ORG_IMPORT_SOURCES,
  url: dataIn(orgId).listOrgDataSources,
});

export const fetchOrgImportSourcesSuccess = payload => ({
  type: DATA_IN_ADMIN.FETCH_ORG_IMPORT_SOURCES_SUCCESS,
  payload,
});

export const fetchOrgImportSourcesError = payload => ({
  type: DATA_IN_ADMIN.FETCH_ORG_IMPORT_SOURCES_ERROR,
  payload,
});

export const updateOrgImportSources = (orgId, payload) => ({
  type: DATA_IN_ADMIN.UPDATE_ORG_IMPORT_SOURCES,
  url: dataIn(orgId).updateOrgImportSources,
  payload,
});

export const updateOrgImportSourcesSuccess = payload => ({
  type: DATA_IN_ADMIN.UPDATE_ORG_IMPORT_SOURCES_SUCCESS,
  payload,
});

export const updateOrgImportSourcesError = payload => ({
  type: DATA_IN_ADMIN.UPDATE_ORG_IMPORT_SOURCES_ERROR,
  payload,
});

export const fetchAllImportDataTypes = () => ({
  type: DATA_IN_ADMIN.FETCH_ALL_IMPORT_DATA_TYPES,
  url: dataIn().importDataTypeList(),
});

export const fetchAllImportDataTypesSuccess = payload => ({
  type: DATA_IN_ADMIN.FETCH_ALL_IMPORT_DATA_TYPES_SUCCESS,
  payload,
});

export const fetchAllImportDataTypesError = payload => ({
  type: DATA_IN_ADMIN.FETCH_ALL_IMPORT_DATA_TYPES_ERROR,
  payload,
});

export const fetchOrgImportDataTypes = orgId => ({
  type: DATA_IN_ADMIN.FETCH_ORG_IMPORT_DATA_TYPES,
  url: dataIn(orgId).importDataTypeList(),
});

export const fetchOrgImportDataTypesSuccess = payload => ({
  type: DATA_IN_ADMIN.FETCH_ORG_IMPORT_DATA_TYPES_SUCCESS,
  payload,
});

export const fetchOrgImportDataTypesError = payload => ({
  type: DATA_IN_ADMIN.FETCH_ORG_IMPORT_DATA_TYPES_ERROR,
  payload,
});

export const updateOrgImportDataTypes = (orgId, payload) => ({
  type: DATA_IN_ADMIN.UPDATE_ORG_IMPORT_DATA_TYPES,
  url: dataIn(orgId).updateOrgImportDataTypes,
  payload,
});

export const updateOrgImportDataTypesSuccess = payload => ({
  type: DATA_IN_ADMIN.UPDATE_ORG_IMPORT_DATA_TYPES_SUCCESS,
  payload,
});

export const updateOrgImportDataTypesError = payload => ({
  type: DATA_IN_ADMIN.UPDATE_ORG_IMPORT_DATA_TYPES_ERROR,
  payload,
});
