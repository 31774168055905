import AuthService from 'components/Common/AuthService';
import Logger from 'components/Common/Logger';
import RootReducer from 'RootReducer';
import RootSaga from 'RootSaga';
import createSagaMiddleware from 'redux-saga';
import { applyMiddleware, compose, createStore } from 'redux';


const authInterceptor = next => (effect) => {
  if (effect.type === 'PUT' && effect.payload.action.payload === 'Unauthenticated') {
    AuthService.handle401Error();
    return undefined;
  }
  return next(effect);
};

const getStore = () => {
  // eslint-disable-next-line no-underscore-dangle
  const reduxHelper = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  const composeEnhancers = (typeof window === 'object' && reduxHelper) ? reduxHelper({}) : compose;
  const sagaMiddleware = createSagaMiddleware({ effectMiddlewares: [authInterceptor] });
  const enhancers = composeEnhancers(applyMiddleware(sagaMiddleware));
  const store = createStore(RootReducer, enhancers);
  sagaMiddleware.run(RootSaga, store.dispatch).toPromise().catch((e) => {
    Logger.error('Application saga halted', e);
  });

  return store;
};

export default getStore();
