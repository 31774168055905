import { alertBuilder } from 'utils/urlConstants';
import { axiosInstance } from 'utils/http';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch } from 'react-redux';
import { useMutation } from 'react-query';


const useChatDownload = (orgID, crID) => {
  const dispatch = useDispatch();

  const {
    isLoading, isError, data, mutate,
  } = useMutation(
    async (chatID) => {
      const { data: responseData } = await axiosInstance.get(alertBuilder(orgID)
        .alertChatDownload(crID, chatID));
      return responseData;
    },
    {
      onError: (err) => {
        dispatch(showAlert({
          message: err.response.data.message ?? err.response.statusText,
          type: 'error',
        }));
      },
    },
  );

  return {
    isLoading, isError, data, mutate,
  };
};

export { useChatDownload };
