import CleanRoomTypesTable
  from 'pages/InternalAdmin/organization/pages/CleanRoomTypes/CleanRoomTypesTable';
import ListViewTable from 'components/Common/ListViewTable';
import PageHeader from 'components/Common/PageHeader';
import React from 'react';
import { Card, CardContent, Grid } from '@mui/material';
import { HButton } from 'BaseComponents';
import { StyledGrid } from 'components/IAM/Admin/StyledComponents';
import { crumbs } from 'components/Common/BreadcrumbsNavigation';
import { internalAdmin } from 'utils/spaUrls';
import {
  useFetchAccountCleanRoomTypes,
  useFetchCleanRoomTypes,
  useUpdateAccountCleanRoomTypes,
} from 'hooks/cleanRoomAPIs';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';


const CleanRoomTypes = () => {
  const history = useHistory();
  const account = useSelector(state => state.session.user.primaryAccount);
  const { data: cleanRoomTypesAll } = useFetchCleanRoomTypes();
  const { data: accountCrTypes } = useFetchAccountCleanRoomTypes(account.ID);
  const [selectedCleanRoomTypes, setSelectedCleanRoomTypes] = React.useState([]);
  const updateAccountCleanRoomTypes = useUpdateAccountCleanRoomTypes();

  const onSubmit = () => {
    const selectedCrTypes = [];
    selectedCleanRoomTypes.map(crTypeID => selectedCrTypes.push({ ID: crTypeID }));
    const payload = { cleanRoomTypes: selectedCrTypes };
    updateAccountCleanRoomTypes.mutate({ accountID: account.ID, payload });
  };

  React.useEffect(() => {
    if (!accountCrTypes) {
      return;
    }

    setSelectedCleanRoomTypes(accountCrTypes.map(crType => crType.ID));
  }, [accountCrTypes, setSelectedCleanRoomTypes]);


  React.useEffect(() => {
    if (updateAccountCleanRoomTypes.isSuccess || updateAccountCleanRoomTypes.isError) {
      updateAccountCleanRoomTypes.reset();
      setSelectedCleanRoomTypes([]);
      history.push(internalAdmin.landing);
    }
  }, [updateAccountCleanRoomTypes, history]);


  return (
    <>
      <PageHeader title={`${account.name}: Manage Supported Clean Room Types`} breadcrumbs={[crumbs.home, crumbs.internalAdmin]} />
      <Grid container spacing={0} direction='column'>
        <Grid item>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ListViewTable list={cleanRoomTypesAll}>
                    <CleanRoomTypesTable
                      list={cleanRoomTypesAll}
                      cleanRoomTypes={selectedCleanRoomTypes}
                      setCleanRoomType={setSelectedCleanRoomTypes}
                    />
                  </ListViewTable>
                </Grid>
                <StyledGrid item xs={12}>
                  <HButton
                    color='primary'
                    size='small'
                    variant='contained'
                    onClick={() => onSubmit()}
                  >
                    Update Clean Room Types
                  </HButton>
                </StyledGrid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default CleanRoomTypes;
