import React from 'react';
import TextField from '@mui/material/TextField';


const TextFieldInput = ({
  label, margin, multiline, onBlur, placeholder, required, rows, size, value, variant,
  testID, ...rest
}) => {
  const [localValue, setLocalValue] = React.useState(value);

  const handleOnBlur = () => {
    onBlur(localValue);
  };

  return (
    <TextField
      data-testid={testID}
      size={size || 'small'}
      variant={variant || 'outlined'}
      label={label}
      required={required}
      placeholder={placeholder}
      multiline={multiline}
      rows={rows}
      fullWidth
      value={localValue}
      onChange={e => setLocalValue(e.target.value)}
      onBlur={handleOnBlur}
      margin={margin || 'dense'}
      {...rest}
    />
  );
};

export default TextFieldInput;
