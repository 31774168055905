import { CACHE } from 'utils/appConstants';
import { axiosInstance } from 'utils/http';
import { homepage } from 'utils/spaUrls';
import { impersonation } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch } from 'react-redux';
import { useMutation } from 'react-query';
import { useState } from 'react';
import { warmLocalStorage } from 'redux/saga/AuthenticationSaga';


export const useBeginImpersonation = () => {
  const dispatch = useDispatch();
  const [errorMsg, setErrorMsg] = useState();

  const {
    isLoading, isError, isSuccess, mutate,
  } = useMutation(
    async ({ ImpersonatorID, UserID, comments }) => axiosInstance.post(impersonation.begin, {
      userImpersonation: {
        ImpersonatorID, UserID,
      },
      comments,
    }),
    {
      onSuccess: (response) => {
        const impersonator = localStorage.getItem(CACHE.impersonation.impersonator);
        const currentUser = localStorage.getItem(
          impersonator ? CACHE.impersonation.impersonator : CACHE.user.userDetails,
        );
        const authToken = localStorage.getItem(
          impersonator ? CACHE.impersonation.refreshToken : CACHE.user.jwtRefreshToken,
        );
        warmLocalStorage(response.data);
        localStorage.setItem(CACHE.impersonation.impersonator, currentUser);
        localStorage.setItem(CACHE.impersonation.refreshToken, authToken);
        window.location.replace(homepage);
        dispatch(showAlert({
          message: 'Impersonation session started.',
          type: 'success',
        }));
      },
      onError: (err) => {
        dispatch(showAlert({
          message: err.response.data.message,
          type: 'error',
        }));
        setErrorMsg(err.response.data.message);
      },
    },
  );

  return {
    errorMsg,
    isLoading,
    isSuccess,
    isError,
    mutate,
  };
};

export const useEndImpersonation = () => {
  const dispatch = useDispatch();
  const [errorMsg, setErrorMsg] = useState();

  const {
    isLoading, isError, isSuccess, mutate,
  } = useMutation(
    async () => axiosInstance.post(impersonation.end, {}),
    {
      onSuccess: () => {
        const authToken = localStorage.getItem(CACHE.impersonation.refreshToken);
        localStorage.setItem(CACHE.user.jwtRefreshToken, authToken);
        localStorage.removeItem(CACHE.impersonation.impersonator);
        localStorage.removeItem(CACHE.impersonation.refreshToken);
        window.location.replace(homepage);
        dispatch(showAlert({
          message: 'Impersonation session has been ended.',
          type: 'success',
        }));
      },
      onError: (err) => {
        dispatch(showAlert({
          message: err.response.data.message,
          type: 'error',
        }));
        setErrorMsg(err.response.data.message);
      },
    },
  );

  return {
    errorMsg,
    isLoading,
    isSuccess,
    isError,
    mutate,
  };
};
