import { PROPERTIES } from 'redux/actions/Types';
import { properties } from 'utils/urlConstants';


export const archiveProperty = (orgId, propertyIDs, configId) => ({
  type: PROPERTIES.ARCHIVE_PROPERTY,
  moonrakerUrl: properties(orgId).archive,
  quarterdeckUrl: properties(orgId).updateQuarterdeckConfig(propertyIDs, configId),
  payload: { propertyIDs },
});

export const archivePropertySuccess = payload => ({
  type: PROPERTIES.ARCHIVE_PROPERTY_SUCCESS,
  payload,
});

export const archivePropertyError = payload => ({
  type: PROPERTIES.ARCHIVE_PROPERTY_FAILURE,
  payload,
});

export const createProperty = (orgId, property) => ({
  type: PROPERTIES.CREATE_PROPERTY,
  url: properties(orgId).create,
  payload: { properties: [property] },
});

export const createPropertySuccess = payload => ({
  type: PROPERTIES.CREATE_PROPERTY_SUCCESS,
  payload,
});

export const createPropertyError = payload => ({
  type: PROPERTIES.CREATE_PROPERTY_FAILURE,
  payload,
});

export const createConfiguration = (orgId, propertyId, configuration) => ({
  type: PROPERTIES.CREATE_CONFIGURATION,
  url: properties(orgId).createQuarterdeckConfig(propertyId),
  payload: { configuration },
});

export const createConfigurationSuccess = payload => ({
  type: PROPERTIES.CREATE_CONFIGURATION_SUCCESS,
  payload,
});

export const createConfigurationError = payload => ({
  type: PROPERTIES.CREATE_CONFIGURATION_FAILURE,
  payload,
});

export const createTagJob = (orgId, payload) => ({
  type: PROPERTIES.CREATE_TAG_JOB,
  url: properties(orgId).updateForebittConfig,
  payload,
});

export const createTagJobSuccess = payload => ({
  type: PROPERTIES.CREATE_TAG_JOB_SUCCESS,
  payload,
});

export const createTagJobError = payload => ({
  type: PROPERTIES.CREATE_TAG_JOB_FAILURE,
  payload,
});

export const fetchPropertyConfigurations = orgId => ({
  type: PROPERTIES.FETCH_PROPERTY_CONFIGURATIONS,
  url: properties(orgId).configurations,
});

export const fetchPropertyConfigurationsSuccess = data => ({
  type: PROPERTIES.FETCH_PROPERTY_CONFIGURATIONS_SUCCESS,
  payload: data,
});

export const fetchPropertyConfigurationsFailure = data => ({
  type: PROPERTIES.FETCH_PROPERTY_CONFIGURATIONS_FAILURE,
  payload: data,
});

export const fetchPropertyConfigurationDetail = (orgId, propertyId, id) => ({
  type: PROPERTIES.FETCH_PROPERTY_CONFIGURATION_DETAIL,
  url: properties(orgId).updateQuarterdeckConfig(propertyId, id),
});

export const fetchPropertyConfigurationDetailSuccess = data => ({
  type: PROPERTIES.FETCH_PROPERTY_CONFIGURATION_DETAIL_SUCCESS,
  payload: data,
});

export const fetchPropertyConfigurationDetailFailure = data => ({
  type: PROPERTIES.FETCH_PROPERTY_CONFIGURATION_DETAIL_FAILURE,
  payload: data,
});

export const fetchForebittConfigurationDetail = (orgId, propertyId) => ({
  type: PROPERTIES.FETCH_FOREBITT_CONFIGURATION_DETAIL,
  url: properties(orgId).fetchForebittConfig(propertyId),
});

export const fetchForebittConfigurationDetailSuccess = data => ({
  type: PROPERTIES.FETCH_FOREBITT_CONFIGURATION_DETAIL_SUCCESS,
  payload: data,
});

export const fetchForebittConfigurationDetailFailure = data => ({
  type: PROPERTIES.FETCH_FOREBITT_CONFIGURATION_DETAIL_FAILURE,
  payload: data,
});

export const fetchProperties = orgId => ({
  type: PROPERTIES.FETCH_PROPERTIES,
  url: properties(orgId).list,
});

export const fetchPropertiesSuccess = data => ({
  type: PROPERTIES.FETCH_PROPERTIES_SUCCESS,
  payload: data,
});

export const fetchPropertiesError = data => ({
  type: PROPERTIES.FETCH_PROPERTIES_ERROR,
  payload: data,
});

export const fetchArchivedList = orgId => ({
  type: PROPERTIES.FETCH_ARCHIVED_PROPERTIES,
  url: properties(orgId).archived,
});

export const fetchArchivedListSuccess = data => ({
  type: PROPERTIES.FETCH_ARCHIVED_PROPERTIES_SUCCESS,
  payload: data,
});

export const fetchArchivedListError = data => ({
  type: PROPERTIES.FETCH_ARCHIVED_PROPERTIES_FAILURE,
  payload: data,
});

export const fetchProperty = (orgId, propertyId) => ({
  type: PROPERTIES.FETCH_PROPERTY,
  url: properties(orgId).details(propertyId),
});

export const clearDetails = () => ({
  type: PROPERTIES.CLEAR_PROPERTY,
});

export const receiveProperty = data => ({
  type: PROPERTIES.FETCH_PROPERTY_SUCCESS,
  payload: data,
});

export const fetchPropertyError = data => ({
  type: PROPERTIES.FETCH_PROPERTY_ERROR,
  payload: data,
});

export const updateQuarterdeckConfiguration = (orgId, propertyId, id, configuration) => ({
  type: PROPERTIES.UPDATE_QUARTERDECK_CONFIGURATION,
  url: properties(orgId).updateQuarterdeckConfig(propertyId, id),
  payload: { configuration },
});

export const updateQuarterdeckConfigurationSuccess = payload => ({
  type: PROPERTIES.UPDATE_QUARTERDECK_CONFIGURATION_SUCCESS,
  payload,
});

export const updateQuarterdeckConfigurationError = payload => ({
  type: PROPERTIES.UPDATE_QUARTERDECK_CONFIGURATION_ERROR,
  payload,
});

export const updateForebittConfiguration = (orgId, config) => ({
  type: PROPERTIES.UPDATE_FOREBITT_CONFIGURATION,
  url: properties(orgId).updateForebittConfig,
  payload: config,
});

export const updateForebittConfigurationSuccess = payload => ({
  type: PROPERTIES.UPDATE_FOREBITT_CONFIGURATION_SUCCESS,
  payload,
});

export const updateForebittConfigurationError = payload => ({
  type: PROPERTIES.UPDATE_FOREBITT_CONFIGURATION_ERROR,
  payload,
});

export const updateProperty = (orgId, propertyId, property) => ({
  type: PROPERTIES.UPDATE_PROPERTY,
  url: properties(orgId).update(propertyId),
  payload: { property },
});

export const updatePropertySuccess = payload => ({
  type: PROPERTIES.UPDATE_PROPERTY_SUCCESS,
  payload,
});

export const updatePropertyError = payload => ({
  type: PROPERTIES.UPDATE_PROPERTY_FAILURE,
  payload,
});
