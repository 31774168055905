import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { DataContext } from 'BaseComponents/HDataTable/DataContextProvider';
import { minimumCellWidth } from 'BaseComponents/HDataTable/constants';
import { useTableresize } from 'BaseComponents/HDataTable/useTableresize';


export const ResizableContext = createContext({});

export const ResizableContextProvider = ({
  minCellWidth = minimumCellWidth,
  children,
}) => {
  const {
    columnsMap,
    setColumnsLocalStorage,
  } = useContext(DataContext);

  const [refSelectedCell, setRefSelectedCell] = useState(null);
  const [tableHeight, setTableHeight] = useState('auto');
  const tableElement = useRef(null);
  const selectedCell = useRef({ width: 0, mouseStartingPosition: 0 });
  const isTableFullSize = useTableresize(tableElement);

  useEffect(() => {
    setTableHeight(tableElement.current.offsetHeight);
  }, []);

  const mouseDown = (e, newRef, fieldName) => {
    selectedCell.current.width = e.target.parentElement.offsetWidth;
    selectedCell.current.mouseStartingPosition = e.pageX;

    setRefSelectedCell({ ref: newRef, fieldName });
  };

  const mouseMove = useCallback(
    (e) => {
      if (refSelectedCell === null) {
        return;
      }
      const mouseMoveX = e.pageX - selectedCell.current.mouseStartingPosition;
      const selectedCel2Drag = refSelectedCell.ref.current;
      const selectedCelFieldName = columnsMap.get(refSelectedCell.fieldName);

      const newCellWidth = selectedCell.current.width + mouseMoveX;

      const width = newCellWidth >= minCellWidth ? newCellWidth : minCellWidth;

      selectedCel2Drag.width = width;
      selectedCelFieldName.width = width;
    },
    [columnsMap, minCellWidth, refSelectedCell],
  );

  const removeListeners = useCallback(() => {
    window.removeEventListener('mousemove', mouseMove);
    window.removeEventListener('mouseup', removeListeners);
  }, [mouseMove]);

  const mouseUp = useCallback(() => {
    setColumnsLocalStorage();

    selectedCell.current.width = 0;
    selectedCell.current.mouseStartingPosition = 0;
    setRefSelectedCell(null);
    removeListeners();
  }, [setRefSelectedCell, removeListeners, setColumnsLocalStorage]);

  useEffect(() => {
    if (refSelectedCell !== null) {
      window.addEventListener('mousemove', mouseMove);
      window.addEventListener('mouseup', mouseUp);
    }

    return () => {
      removeListeners();
    };
  }, [refSelectedCell, mouseMove, mouseUp, removeListeners]);

  return (
    <ResizableContext.Provider value={{
      tableHeight,
      mouseDown,
      tableElement,
      isTableFullSize,
    }}
    >
      {children}
    </ResizableContext.Provider>
  );
};
