import * as monaco from 'monaco-editor';
import CloseIcon from '@mui/icons-material/Close';
import Editor, { loader } from '@monaco-editor/react';
import React from 'react';
import {
  Box, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton,
} from '@mui/material';


export default function ViewSQLModal({ open, onClose, sql }) {
  loader.config({ monaco });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth='md'
    >
      <>
        <Grid container spacing={1}>
          <Grid item xs={5}>
            <DialogTitle sx={{ fontSize: 18, fontWeight: 'bold' }}>
              Alert SQL
            </DialogTitle>
          </Grid>
          <Grid item xs={7}>
            <DialogActions>
              <IconButton size='medium' justifyContent='flex-end' onClick={onClose}>
                <CloseIcon fontSize='medium' />
              </IconButton>
            </DialogActions>
          </Grid>
        </Grid>
        <Divider />
        <DialogContent>
          <Grid container spacing={3} paddingTop={2}>
            <Grid item xs={12}>
              <Box positon='inherit'>
                <Editor
                  height='650px'
                  defaultValue=''
                  language='sql'
                  theme='vs-dark'
                  options={{
                    minimap: { enabled: false },
                    wordWrap: true,
                    fontSize: 15,
                    readOnly: true,
                    contextmenu: false,
                  }}
                  value={sql}
                />
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
      </>
    </Dialog>
  );
}
