import React from 'react';
import Typography from '@mui/material/Typography';
import { HELP_DOC_PATH_MAP } from 'utils/appConstants';
import { LinkComponent } from 'BaseComponents';
import { redirect } from 'utils/spaUrls';


export const HelpLink = ({ path, text = 'Need help?', ...restProps }) => (
  <LinkComponent
    to={{
      pathname: redirect.habuHelp,
      state: { key: HELP_DOC_PATH_MAP[path] },
    }}
    {...restProps}
  >
    <Typography variant='linkText'>{text}</Typography>
  </LinkComponent>
);

