import { DATA_IN } from 'redux/actions/Types';
import { v4 as uuidGen } from 'uuid';


const initialState = {
  dataInJobsSummary: {},
  dataSourceJobs: [],
  dataSourceParameters: [],
  dependentParameters: {},
  meta: {},
};

const DataInReducer = (state = initialState, action) => {
  switch (action.type) {
    case DATA_IN.RESET_DATA_IN_JOB:
      return {
        ...state,
        jobDetails: undefined,
        jobParameters: undefined,
        jobLevelConsent: undefined,
        dataTypeList: undefined,
      };
    case DATA_IN.JOB_SAVE:
      return { ...state, meta: { loading: true } };
    case DATA_IN.JOB_SAVE_FAILURE:
      return { ...state, jobDetails: { error: action.payload }, meta: { loading: false } };
    case DATA_IN.JOB_SAVE_SUCCESS:
      return {
        ...state,
        jobDetails: action.payload.job,
        jobParameters: action.payload.parameters,
        jobConsent: action.payload?.consent,
        meta: { created: true, loading: false },
      };
    case DATA_IN.FETCH_DATA_IN_JOBS:
      return { ...state, jobs: undefined, meta: initialState.meta };
    case DATA_IN.FETCH_DATA_IN_JOBS_ERROR:
      return { ...state, jobs: { error: action.payload, list: [] }, meta: initialState.meta };
    case DATA_IN.FETCH_DATA_IN_JOBS_SUCCESS:
      return { ...state, jobs: { list: action.payload }, meta: initialState.meta };
    case DATA_IN.RECEIVE_DATA_IN_DASHBOARD:
      return { ...state, dashboard: action.payload };
    case DATA_IN.FETCH_DATA_IN_DETAILS:
      return { ...state, jobDetails: undefined, meta: initialState.meta };
    case DATA_IN.FETCH_DATA_IN_DETAILS_SUCCESS:
      return { ...state, jobDetails: action.payload, meta: initialState.meta };
    case DATA_IN.FETCH_DATA_IN_JOB_SUMMARY:
      return { ...state, jobSummary: undefined };
    case DATA_IN.FETCH_DATA_IN_JOB_SUMMARY_ERROR:
      return { ...state, jobSummary: {} };
    case DATA_IN.FETCH_DATA_IN_JOB_SUMMARY_SUCCESS:
      return { ...state, jobSummary: action.payload };
    case DATA_IN.FETCH_DATA_IN_FILTERS_ERROR:
      return { ...state, dataInFilters: { error: action.payload } };
    case DATA_IN.FETCH_DATA_IN_FILTERS_SUCCESS:
      return { ...state, dataInFilters: action.payload };
    case DATA_IN.DATA_IN_CHANGE_JOB_STATUS_ERROR:
      return { ...state, jobDetails: { error: action.payload } };
    case DATA_IN.FETCH_DATA_SOURCE:
      return { ...state, details: undefined, dataSourceParameters: [] };
    case DATA_IN.FETCH_DATA_SOURCE_SUCCESS:
      return { ...state, details: action.payload };
    case DATA_IN.FETCH_DATA_SOURCE_JOBS_SUCCESS:
      return { ...state, dataSourceJobs: action.payload };
    case DATA_IN.RECEIVE_DATA_SOURCES_SUCCESS:
      return { ...state, dataSourceList: action.payload, meta: initialState.meta };
    case DATA_IN.FETCH_DATA_SOURCE_PARAMETERS:
      return { ...state, dataSourceParameters: { loading: true }, dependentParameters: {} };
    case DATA_IN.FETCH_DATA_SOURCE_PARAMETERS_SUCCESS:
      return { ...state, dataSourceParameters: action.payload };
    case DATA_IN.FETCH_DATA_SOURCE_DEPENDENT_PARAMETERS_SUCCESS:
      return {
        ...state,
        dependentParameters: {
          ...state.dependentParameters,
          [`${action.payload.id}:${action.payload.value}`]: action.payload.dependentParameters
            .map(dp => ({
              ID: uuidGen(),
              importJobID: dp.importJobID,
              organizationID: action.payload.orgId,
              importDataSourceParameterID: dp.id,
              importDataSourceID: dp.importDataSourceID,
              index: dp.index,
              displayName: dp.displayName,
              name: dp.name,
              lookupValues: dp.lookupValues,
              lookupTable: dp.lookupTable,
              type: dp.type,
            })),
        },
      };
    case DATA_IN.FETCH_DATA_SOURCE_PARAMETERS_FAILURE:
      return { ...state, dataSourceParameters: { error: action.payload } };
    case DATA_IN.FETCH_JOB_PARAMETERS_SUCCESS:
      return { ...state, jobParameters: action.payload };
    case DATA_IN.FETCH_JOB_PARAMETERS_ERROR:
      return { ...state, jobParameters: { error: action.payload } };
    case DATA_IN.FETCH_DATA_SET_TYPE_LIST:
      return { ...state, dataTypeList: { loading: true } };
    case DATA_IN.RECEIVE_DATA_SET_TYPE_LIST:
      return { ...state, dataTypeList: action.payload };
    case DATA_IN.RECEIVE_DEVICE_TYPE_LIST:
      return { ...state, deviceTypeList: action.payload };
    case DATA_IN.RECEIVE_ATTRIBUTE_CATEGORY_LIST:
      return { ...state, attributeCategoryList: action.payload };
    case DATA_IN.SUBMIT_DATA_IN_FIELD_MAPPING:
      return { ...state, meta: { loading: true } };
    case DATA_IN.SUBMIT_DATA_IN_FIELD_MAPPING_SUCCESS:
      return { ...state, fieldMapping: action.payload, meta: { redirect: true, loading: false } };
    case DATA_IN.SUBMIT_DATA_IN_FIELD_MAPPING_ERROR:
      return { ...state, meta: { redirect: false, loading: false } };
    case DATA_IN.FETCH_FIELD_MAPPING_LIST:
      return { ...state, fieldMapping: undefined };
    case DATA_IN.FETCH_FIELD_MAPPING_LIST_SUCCESS:
      return { ...state, fieldMapping: action.payload };
    case DATA_IN.FETCH_DATA_IN_JOB_CONSENT:
      return { ...state, jobConsent: undefined };
    case DATA_IN.RECEIVE_DATA_IN_JOB_CONSENT:
      return { ...state, jobConsent: action.payload };
    case DATA_IN.CREATE_DATA_IN_JOB_CONSENT:
      return { ...state, meta: { loading: true } };
    case DATA_IN.SUBMIT_DATA_IN_JOB_CONSENT_SUCCESS:
      return { ...initialState, jobConsent: action.payload, meta: { consentCreated: true } };
    case DATA_IN.SUBMIT_DATA_IN_JOB_CONSENT_ERROR:
      return { ...state, jobConsent: action.payload };
    case DATA_IN.SUBMIT_JOB_STATUS_SUCCESS:
      return { ...state, jobDetails: { ...initialState.jobDetails, meta: { updated: true } } };
    case DATA_IN.SUBMIT_JOB_STATUS_ERROR:
      return { ...state, status: action.payload };
    case DATA_IN.UPDATE_JOB_PARAMETERS:
      return { ...state, meta: { loading: true } };
    case DATA_IN.UPDATE_JOB_PARAMETERS_SUCCESS:
      return { ...state, meta: { parametersSubmitted: true } };
    case DATA_IN.UPDATE_JOB_PARAMETERS_FAILURE:
      return { ...state, meta: { error: action.payload } };
    case DATA_IN.FETCH_DATA_IN_JOBS_SUMMARY_SUCCESS:
      return { ...state, dataInJobsSummary: action.payload };
    case DATA_IN.FETCH_DATA_IN_JOB_STATS_SUCCESS:
      return { ...state, dataInJobStats: action.payload };
    case DATA_IN.FETCH_DATA_IN_JOB_TREND:
      return { ...state, jobTrend: undefined };
    case DATA_IN.FETCH_DATA_IN_JOB_TREND_ERROR:
      return { ...state, jobTrend: [] };
    case DATA_IN.FETCH_DATA_IN_JOB_TREND_SUCCESS:
      return { ...state, jobTrend: action.payload };
    case DATA_IN.FETCH_DATA_IN_PEOPLE_HOUSEHOLDS:
      return { ...state, peopleHouseholds: undefined };
    case DATA_IN.FETCH_DATA_IN_PEOPLE_HOUSEHOLDS_ERROR:
      return { ...state, peopleHouseholds: [] };
    case DATA_IN.FETCH_DATA_IN_PEOPLE_HOUSEHOLDS_SUCCESS:
      return { ...state, peopleHouseholds: action.payload };
    default:
      return state;
  }
};

export default DataInReducer;
