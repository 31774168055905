import { activeOrgSelector } from 'redux/reducer/AuthenticationReducer';
import { axiosInstance } from 'utils/http';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation, useQueryClient } from 'react-query';
import { userDefinedFunctions } from 'utils/urlConstants';


export const useDeleteUserDefinedFunction = () => {
  const { ID: orgId } = useSelector(activeOrgSelector);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation(
    (payload) => axiosInstance.delete(userDefinedFunctions(orgId).delete(payload.udfID)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['userDefinedFunctions']);
        dispatch(showAlert({
          message: 'User-defined function deleted successfully',
          type: 'success',
        }));
      },
      onError: (err) => {
        dispatch(showAlert({
          message: err.response?.data?.message ?? 'Error deleting user-defined function',
          type: 'error',
        }));
      },
    },
  );
};
