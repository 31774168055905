import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import NavbarLayout from 'components/Layouts/NavbarLayout';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import liveRampLogoBlack from 'assets/logo/liveRampLogoBlack.svg';
import {
  Box, Card, CardContent, Typography,
} from '@mui/material';
import { HSpinner } from 'BaseComponents';
import { Redirect, useLocation } from 'react-router-dom';
import { errorPages } from 'utils/spaUrls';
import { fetchOrganizationList } from 'redux/actions/IamActions';
import { styled } from '@mui/material/styles';
import { switchAccount, switchOrganization } from 'redux/actions/AuthenticationActions';
import { useDispatch, useSelector } from 'react-redux';
import { useFetchCleanRoomUserOrgName } from 'hooks/cleanRoomAPIs';


const StyledImg = styled('img')({
  display: 'block',
  marginLeft: 'auto',
  marginRight: 'auto',
});

const DeeplinkRedirect = () => {
  const location = useLocation();
  const query = useMemo(() => new URLSearchParams(location.search),
    [location.search]);
  const orgIdParam = query.get('organization');
  const redirectTo = query.get('redirect_to');
  const type = query.get('type');
  const [crId, setcrId] = useState();
  const [orgName, setOrgName] = useState();
  const dispatch = useDispatch();

  const { user, loading: isStateLoading } = useSelector(state => state.session);
  const { organizationList: userOrganizationList, orgListMeta } = useSelector(state => state.iam);

  const {
    primaryAccount: activeAccount,
    primaryOrganization: { organization: activeOrg },
    accounts: accountList,
    organizations,
  } = user;

  const { data, isLoading, isError } = useFetchCleanRoomUserOrgName(orgIdParam, crId);

  useEffect(() => {
    if (type === 'cleanroom') {
      setcrId(query.get(type));
      setOrgName(data.organizationName);
    }
    else {
      setOrgName(organizations.find(o => o.organization.ID === orgIdParam)?.organization?.name || '');
    }
  }, [data, orgIdParam, organizations, query, type]);

  // Fetch Organization List if empty
  useEffect(() => {
    if (user && userOrganizationList?.length === 0 && !orgListMeta?.loading) {
      dispatch(fetchOrganizationList(activeAccount.ID));
    }
  }, [dispatch, user, activeAccount.ID, userOrganizationList?.length, orgListMeta?.loading]);

  const switchOrganizationOrAccount = useCallback((targetOrg) => {
    const targetAccount = accountList.find(a => a.ID === targetOrg.organization.accountID);
    if (targetAccount.ID === activeAccount.ID) {
      dispatch(switchOrganization(targetOrg));
    }
    else {
      dispatch(switchAccount(targetAccount));
    }
  }, [accountList, activeAccount.ID, dispatch]);


  /**
   * This effect checks if the user is trying to switch to a different organization/account
   * using a deep-link and performs the switch if necessary.
   */
  useEffect(() => {
    if (orgIdParam && orgIdParam !== activeOrg.ID) {
      const targetOrg = userOrganizationList.find(org => org.organization.ID === orgIdParam);
      if (targetOrg && targetOrg.organization.accountID === activeAccount.ID) {
        dispatch(switchOrganization(targetOrg));
      }
      else {
        const targetOrgParam = organizations.find(o => o.organization.ID === orgIdParam);
        if (targetOrgParam) {
          switchOrganizationOrAccount(targetOrgParam);
        }
      }
    }
  }, [
    dispatch,
    orgIdParam,
    organizations,
    activeOrg.ID,
    accountList,
    activeAccount.ID,
    userOrganizationList,
    switchOrganizationOrAccount,
  ]);

  if (orgIdParam === activeOrg.ID || data.organizationID === activeOrg.ID) {
    return <Redirect to={redirectTo} />;
  }

  if (isStateLoading || isLoading) {
    return (
      <Box height='100vh'>
        <HSpinner />
      </Box>
    );
  }

  if (orgName === '' || isError) {
    return <Redirect to={errorPages.forbidden} />;
  }

  return (
    <>
      <Grid container justifyContent='center' spacing={1}>
        <Grid item xs={6}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Card>
                <Grid item xs={12}>
                  <h2 style={{ fontSize: '1.5em', marginLeft: 15 }}>
                    Change your organization
                  </h2>
                  <Divider />
                </Grid>
                <CardContent style={{ minHeight: 'auto' }}>
                  <Grid item xs={12}>
                    <StyledImg
                      src={liveRampLogoBlack}
                      alt='Permission denied'
                    />
                  </Grid>
                  <Typography variant='subtitle1' paddingTop={2}>
                    {`This page is for a different Organization: ${orgName}.`}
                  </Typography>
                  <Typography variant='subtitle2' paddingTop={3}>
                    Use the Organization Switcher at the top right of the screen to continue.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default NavbarLayout(DeeplinkRedirect);
