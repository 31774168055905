import { axiosInstance } from 'utils/http';
import { flow } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'react-query';


export const useDeleteCrFlowRun = () => {
  const dispatch = useDispatch();
  const orgId = useSelector(state => state.session.user.primaryOrganization.organization.ID);
  const {
    isLoading,
    isError,
    isSuccess,
    mutate,
    mutateAsync,
  } = useMutation(
    ({
      crFlowId,
      crId,
      runId,
    }) => axiosInstance.delete(flow(orgId)
      .deleteCrFlowRun(crId, crFlowId, runId)),
    {
      onSuccess: () => {
        dispatch(showAlert({
          message: 'Flow run has been deleted.',
          type: 'success',
        }));
      },
      onError: (error) => dispatch(showAlert({
        message: error.response.data.message,
        type: 'error',
      })),
    },
  );
  return {
    isLoading,
    isError,
    isSuccess,
    mutate,
    mutateAsync,
  };
};
