import Box from '@mui/material/Box';
import React from 'react';
import { BlankCard } from 'components/Common/CardStructures';
import { Typography } from '@mui/material';


const BasicCard = ({ children, title }) => (
  <BlankCard elevation={0}>
    <Box p={2}>
      <BasicCardHeader title={title} />
      <BasicCardBody>
        {children}
      </BasicCardBody>
    </Box>
  </BlankCard>
);

const BasicCardHeader = ({ title }) => (
  <Typography variant='h5'>{title}</Typography>
);

const BasicCardBody = ({ children }) => (
  <Box pt={3}>{children}</Box>
);

export default BasicCard;
