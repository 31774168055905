import AccountListTable from 'components/PrimaryHeader/AccountListTable';
import Bouncer from 'components/Common/Bouncer';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import ListViewTable from 'components/Common/ListViewTable';
import OrganizationListTable from 'components/PrimaryHeader/OrganizationListTable';
import PermissionService from 'components/Common/PermissionService';
import React from 'react';
import Switch from 'components/Common/Switch';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { HeliumSwitcherDialog } from 'components/CleanRooms/Header/HeliumSwitcherDialog';
import {
  fetchAccountList,
  fetchDemoAccountList,
  fetchOrganizationList,
} from 'redux/actions/IamActions';
import { ourColors } from 'Theme';
import { searchBy } from 'utils/jsUtils';
import { styled } from '@mui/material/styles';
import { switchAccount, switchOrganization } from 'redux/actions/AuthenticationActions';
import { useDispatch, useSelector } from 'react-redux';
import { userTypes } from 'utils/appConstants';


const SwitchOrgButton = styled(Button)`
  width: 350px;
  border: 1px solid;
`;

const accAndOrgTabValues = {
  organizations: 'Organizations',
  accounts: 'Accounts',
  demoAccounts: 'DemoAccounts',
};


const AccountAndOrgSwitcher = ({
  disabled, hideAccounts, preSwitchCallback,
}) => {
  const dispatch = useDispatch();
  const accountList = useSelector(state => state.iam.accountList);
  const demoAccountList = useSelector(state => state.iam.demoAccountList);
  const currentAcc = useSelector(state => state.session.user.primaryAccount);
  const currentOrg = useSelector(state => state.session.user.primaryOrganization.organization);
  const { organizationList, orgListMeta } = useSelector(state => state.iam);
  const [open, setOpen] = React.useState(false);
  const [organizationSwitched, setOrganizationSwitched] = React.useState(false);
  const [tabValue, updateTab] = React.useState(accAndOrgTabValues.organizations);
  const handleClose = () => { setOpen(false); };
  const isSu = PermissionService.isAuthorized([userTypes.superuser]);
  const [accSearch, updateAccSearch] = React.useState('');
  const [accDemoSearch, updateAccDemoSearch] = React.useState('');
  const [orgSearch, updateOrgSearch] = React.useState('');
  const [accList, updateAccList] = React.useState([]);
  const [demoAccList, updateDemoAccList] = React.useState([]);
  const [orgList, updateOrgList] = React.useState([]);
  const [showClient, setShowClient] = React.useState(false);
  const showClientLabel = 'Show Account Name';

  React.useEffect(() => {
    if (isSu) {
      dispatch(fetchDemoAccountList());
    }
  }, [dispatch, isSu]);

  React.useEffect(() => {
    dispatch(fetchAccountList());
  }, [dispatch]);

  React.useEffect(() => {
    if (organizationList?.length === 0 && !orgListMeta?.loading) {
      dispatch(fetchOrganizationList(currentAcc.ID));
    }
  }, [currentAcc.ID, dispatch, orgListMeta, organizationList?.length]);

  React.useEffect(() => {
    updateAccList(accountList);
  }, [accountList]);

  React.useEffect(() => {
    updateOrgList(organizationList);
  }, [organizationList]);

  React.useEffect(() => {
    updateDemoAccList(demoAccountList);
  }, [demoAccountList]);

  const accountSwitch = (newAccount) => {
    dispatch(switchAccount(newAccount));
    updateTab(accAndOrgTabValues.organizations);
  };

  const organizationSwitch = async (newOrg) => {
    if (typeof preSwitchCallback === 'function') {
      await preSwitchCallback(newOrg);
    }
    dispatch(switchOrganization(newOrg));
    setOrganizationSwitched(true);
  };

  React.useEffect(() => {
    if (organizationSwitched) {
      setOpen(false);
      setOrganizationSwitched(false);
    }
  }, [organizationSwitched]);

  const onSearch = React.useCallback((field, currTabValue, text) => {
    if (currTabValue === accAndOrgTabValues.organizations) {
      updateOrgSearch(text);
      const matchingOrganizations = searchBy(organizationList.map(o => o.organization), field, text)
        .map(i => i.ID);
      updateOrgList(organizationList
        .filter(i => matchingOrganizations.includes(i.organization.ID)));
    }
    else if (currTabValue === accAndOrgTabValues.accounts) {
      updateAccSearch(text);
      updateAccList(searchBy(accountList, field, text));
    }
    else if (currTabValue === accAndOrgTabValues.demoAccounts) {
      updateAccDemoSearch(text);
      updateDemoAccList(searchBy(demoAccountList, field, text));
    }
    // eslint-disable-next-line no-empty
    else { }
  }, [accountList, organizationList, demoAccountList]);

  const renderTab = () => {
    switch (tabValue) {
      case accAndOrgTabValues.accounts:
        return (
          <>
            <Box paddingBottom={2} paddingTop={2}>
              <TextField
                fullWidth
                label='Account Search'
                variant='outlined'
                value={accSearch || ''}
                onChange={e => onSearch('displayName', tabValue, e.target.value)}
              />
            </Box>
            <Switch
              checked={showClient}
              label={showClientLabel}
              onChange={event => setShowClient(event.target.checked)}
            />
            <ListViewTable list={accList}>
              <AccountListTable
                accId={currentAcc.ID}
                list={accList}
                onSwitch={newAccount => accountSwitch(newAccount)}
                hide={!showClient}
              />
            </ListViewTable>
          </>
        );
      case accAndOrgTabValues.organizations:
        return (
          <>
            <Box paddingBottom={2} paddingTop={2}>
              <TextField
                fullWidth
                label='Organization Search'
                variant='outlined'
                value={orgSearch || ''}
                onChange={e => onSearch('name', tabValue, e.target.value)}
              />
            </Box>
            <ListViewTable list={orgList}>
              <OrganizationListTable
                list={orgList}
                onSwitch={newOrg => organizationSwitch(newOrg)}
                orgId={currentOrg.ID}
                hide={false}
              />
            </ListViewTable>
          </>
        );
      case accAndOrgTabValues.demoAccounts:
        return (
          <>
            <Box paddingBottom={2} paddingTop={2}>
              <TextField
                fullWidth
                label='Demo Account Search'
                variant='outlined'
                value={accDemoSearch || ''}
                onChange={e => onSearch('displayName', tabValue, e.target.value)}
              />
            </Box>
            <Bouncer allowList={[userTypes.superuser]}>
              <Switch
                checked={showClient}
                label={showClientLabel}
                onChange={event => setShowClient(event.target.checked)}
              />
            </Bouncer>
            <Bouncer allowList={[userTypes.superuser]}>
              <ListViewTable list={demoAccountList}>
                <AccountListTable
                  accId={currentAcc.ID}
                  list={demoAccList}
                  onSwitch={newAccount => accountSwitch(newAccount)}
                  hide={!showClient}
                />
              </ListViewTable>
            </Bouncer>
          </>
        );
      default:
        return (
          <>
            <Box paddingBottom={2} paddingTop={2}>
              <TextField
                fullWidth
                label='Organization Search'
                variant='outlined'
                value={orgSearch || ''}
                onChange={e => onSearch('organization.name', tabValue, e.target.value)}
              />
            </Box>
            <ListViewTable list={organizationList}>
              <OrganizationListTable
                list={organizationList}
                onSwitch={newOrg => organizationSwitch(newOrg)}
                orgId={currentOrg.ID}
              />
            </ListViewTable>
          </>
        );
    }
  };


  return (
    <>
      <Box sx={{ pr: '1rem' }}>
        <Typography variant='subtitle1' sx={{ color: ourColors.linkColorActive }}>{currentAcc.name}</Typography>
      </Box>
      <SwitchOrgButton
        variant='outlined'
        onClick={() => {
          setOpen(true);
          setShowClient(false);
        }}
        disabled={disabled}
      >
        <Grid
          container
          direction='column'
          justifyContent='center'
          alignItems='flex-start'
        >
          <Grid item xs={12}>
            <Typography variant='caption'>Organization</Typography>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <Typography
              variant='bold'
              sx={{
                display: 'block',
                maxWidth: '220px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {currentOrg.name}
            </Typography>
          </Grid>
        </Grid>
        <ExpandMore />
      </SwitchOrgButton>
      <HeliumSwitcherDialog onClose={handleClose} open={open} title={tabValue}>
        {!hideAccounts ? (
          <Tabs
            indicatorColor='primary'
            onChange={(e, newValue) => {
              updateTab(newValue);
              setShowClient(false);
            }}
            value={tabValue}
          >
            <Tab label='Accounts' value={accAndOrgTabValues.accounts} />
            <Tab label='Organizations' value={accAndOrgTabValues.organizations} />
            {isSu ? <Tab label='Demo Accounts' value={accAndOrgTabValues.demoAccounts} /> : null}
          </Tabs>
        ) : null}
        {renderTab(tabValue)}
      </HeliumSwitcherDialog>
    </>
  );
};

export default AccountAndOrgSwitcher;


