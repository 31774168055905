import Logger from 'components/Common/Logger';
import http from 'utils/http';
import {
  call, put,
} from 'redux-saga/effects';
import { deleteFlowNodeError, setCreateNodeSuccess } from 'redux/actions/FlowAction';
import { showAlert } from 'redux/actions/AlertActions';


export function* createQuestionNode({ url, payload }) {
  const { nodeId, nodePayload } = payload;
  const { error, node } = yield call(http.post, url, nodePayload);

  if (error) {
    Logger.error(error, `Could not create question node. Url: ${url}. Error: ${error}`);
    yield put(showAlert({ message: error, type: 'error' }));
    return yield put(deleteFlowNodeError(nodePayload));
  }

  yield put(showAlert({ message: 'Question node successfully create', type: 'success' }));
  return yield put(setCreateNodeSuccess({ node, oldNodeId: nodeId }));
}
