import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import NavbarLayout from 'components/Layouts/NavbarLayout';
import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import { GoBackToPage } from 'pages/ErrorPages/PageNotFound';
import { HButton } from 'BaseComponents';
import { Link } from 'react-router-dom';
import { StyledH2 } from 'pages/ErrorPages/StyledComponents';
import { habuSupport } from 'utils/appConstants';
import { homepage, redirect } from 'utils/spaUrls';


const ResourceNotFound = () => (
  <Grid container justifyContent='center' spacing={1}>
    <Grid item xs={6}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Card>
            <Grid item xs={12}>
              <h2 style={{ fontSize: '1.5em', marginLeft: 15 }}>
                Resource Not Found
              </h2>
              <Divider />
            </Grid>
            <CardContent style={{ minHeight: 'auto' }}>
              <Typography variant='subtitle1'>
                The resource that is being accessed cannot be found
                at the moment. If you think this is an error, please
                reach out to us.
              </Typography>
              <GoBackToPage />
              <StyledH2>
                Go Back To Home Page
                {' '}
                <IconButton
                  component={Link}
                  to={homepage}
                >
                  <ArrowForwardIcon fontSize='small' />
                </IconButton>
              </StyledH2>
              <StyledH2>
                Learn More in Our Help Center
                {' '}
                <IconButton
                  component={Link}
                  to={redirect.habuHelp}
                >
                  <ArrowForwardIcon fontSize='small' />
                </IconButton>
              </StyledH2>
              <HButton
                color='secondary'
                href={`mailto:${habuSupport.email}`}
                variant='contained'
              >
                Contact Us
              </HButton>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

export { ResourceNotFound };

export default NavbarLayout(ResourceNotFound);
