import { ALERTS_CHAT } from 'redux/actions/Types';
import { ALERT_VIEWS } from 'pages/CleanRooms/CrHabuIntelligence/constant';


const alertChatState = {
  chatID: '',
  cleanRoom: {},
  messages: [],
  chatInitiated: false,
  isChatProposed: false,
};

const alertChatUIState = {
  currentAlertView: ALERT_VIEWS.list,
};

const alertChatReducer = (state = alertChatState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ALERTS_CHAT.ADD_MESSAGE: {
      return {
        ...state,
        messages: [
          ...state.messages,
          payload,
        ],
      };
    }
    case ALERTS_CHAT.ADD_CHAT_ID: {
      return {
        ...state,
        chatID: payload,
      };
    }
    case ALERTS_CHAT.INIT_CHAT: {
      return {
        ...state,
        chatInitiated: payload.chatInitiated,
        cleanRoom: payload.cleanRoom,
      };
    }
    case ALERTS_CHAT.PROPOSE_CHAT: {
      return {
        ...state,
        isChatProposed: payload,
      };
    }
    case ALERTS_CHAT.RESET_CHAT: {
      return {
        ...alertChatState,
      };
    }
    default: {
      return state;
    }
  }
};

const alertChatUIReducer = (state = alertChatUIState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ALERTS_CHAT.SET_ALERT_VIEW: {
      return {
        ...state,
        currentAlertView: payload,
      };
    }
    default: {
      return state;
    }
  }
};

export { alertChatReducer, alertChatUIReducer };
