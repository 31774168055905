import * as spaUrls from 'utils/spaUrls';
import AuthService from 'components/Common/AuthService';
import PermissionService from 'components/Common/PermissionService';
import React from 'react';
import { HSpinner } from 'BaseComponents';
import { Redirect, Route } from 'react-router-dom';
import { auth } from 'utils/spaUrls';
import { permissionDenied } from 'redux/actions/AuthenticationActions';
import { useDispatch } from 'react-redux';


const PrivateRoute = ({
  allowList = [], blockList = [], accessList = [], component: Component, ...rest
}) => {
  const dispatch = useDispatch();
  return (
    <Route
      {...rest}
      render={(props) => {
        const isLoggedIn = AuthService.getAuthStatus(props.history, props.location);
        if (isLoggedIn === false) {
          return <Redirect to={{ pathname: auth.login, state: { from: props.location } }} />;
        }
        else if (isLoggedIn === 'unsure') {
          return <HSpinner />;
        }
        else {
          const isAuthorized = PermissionService.isAuthorized(allowList, blockList, accessList);
          if (!isAuthorized) {
            dispatch(permissionDenied({ allowList, blockList, accessList }));
            return <Redirect to={spaUrls.errorPages.forbidden} />;
          }
          else {
            return <Component {...props} />;
          }
        }
      }}
    />
  );
};

export default PrivateRoute;
