import React from 'react';
import { ExpandableMenuItem } from 'components/Layouts/SideNav/ExpandableMenuItem';
import { MENU_ITEM_SIZE } from 'components/Layouts/SideNav/utils';
import { SingleMenuItem } from 'components/Layouts/SideNav/SingleMenuItem';


const CollapseMenu = ({
  menuItem, currPath, list = {}, setList, size = MENU_ITEM_SIZE.LARGE, isMainSideNav = false,
}) => {
  const subMenu = menuItem.items;
  const showExpandableMenu = subMenu.length >= 1;

  if (showExpandableMenu) {
    return (
      <ExpandableMenuItem
        data-testid={`single-menu-item-${menuItem.title}`}
        menuItem={menuItem}
        list={list}
        setList={setList}
        currPath={currPath}
        size={size}
        isMainSideNav={isMainSideNav}
      />
    );
  }

  return (
    <SingleMenuItem
      data-testid={`single-menu-item-${menuItem.title}`}
      menuItem={menuItem}
      currPath={currPath}
      size={size}
      isMainSideNav={isMainSideNav}
    />
  );
};


export default CollapseMenu;
