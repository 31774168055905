import AddNewSectionButton from 'components/InternalAdmin/QuestionConfigure/AddNewSectionButton';
import Box from '@mui/material/Box';
import ChartSubType from 'components/InternalAdmin/QuestionConfigure/ChartSubType';
import CrAggrregationField from 'components/InternalAdmin/QuestionConfigure/CrAggrregationField';
import DeleteIcon from '@mui/icons-material/Delete';
import Grid from '@mui/material/Grid';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import InputAdornment from '@mui/material/InputAdornment';
import React from 'react';
import SectionHeader from 'components/IAM/Admin/SectionHeader';
import Tooltip from '@mui/material/Tooltip';
import ValueFormattingHelperDialog
  from 'components/InternalAdmin/QuestionConfigure/ValueFormattingHelperDialog';
import { InputTypesMap, UserListDefaultValues, YAxisPlacement } from 'components/CleanRooms/constants';
import {
  QuestionBooleanField, QuestionSelectField, QuestionTextField,
} from 'components/InternalAdmin/QuestionConfigure/QuestionRenderer';
import { debounce } from '@mui/material';
import { v4 as uuidGen } from 'uuid';


const inputTypes = Object.values(InputTypesMap)
  .map(i => ({ name: i.label, value: i.key }));

const yAxisPositions = Object.keys(YAxisPlacement).map(e => ({
  name: YAxisPlacement[e].label,
  value: YAxisPlacement[e].key,
}));

const DEBOUNCE_TIMER = 40;

const QuestionMetrics = ({
  metrics, onPropertyUpdate, isAggregate, isUserList,
}) => {
  const [config, setConfig] = React.useState(metrics);
  const [isQuestionAggregate, setIsQuestionAggregate] = React.useState(isAggregate);
  const [valueFormattingHelper, setValueFormattingHelper] = React.useState(false);

  const onPropertyArrayChange = React.useCallback((i, name) => debounce((value) => {
    setConfig(prevState => ([
      ...prevState.slice(0, i),
      Object.assign({}, prevState[i], {
        [name]: value,
      }),
      ...prevState.slice(i + 1),
    ]));
  }, DEBOUNCE_TIMER), []);

  const onPropertyArrayMetricPropChange = React.useCallback((i, name) => debounce((value) => {
    setConfig(prevState => ([
      ...prevState.slice(0, i),
      Object.assign({}, prevState[i], {
        metricConfig: Object.assign({}, prevState[i]?.metricConfig, {
          [name]: value,
        }),
      }),
      ...prevState.slice(i + 1),
    ]));
  }, DEBOUNCE_TIMER), []);

  const onAddItem = React.useCallback(() => {
    setConfig(prevState => ([
      ...prevState,
      { ID: uuidGen(), computed: false },
    ]));
  }, [setConfig]);

  const onDeleteItem = React.useCallback((i) => {
    setConfig(prevState => [
      ...prevState.slice(0, i),
      ...prevState.slice(i + 1),
    ].map((item, newIndex) => ({ ...item, index: newIndex + 1 })));
  }, [setConfig]);

  const onValueFormattingHelpShow = () => setValueFormattingHelper(true);
  const onValueFormattingHelpHide = () => setValueFormattingHelper(false);

  React.useEffect(() => {
    onPropertyUpdate('isAggregate', isQuestionAggregate);
  }, [isQuestionAggregate, onPropertyUpdate]);

  React.useEffect(() => {
    onPropertyUpdate('metrics', config);
  }, [config, onPropertyUpdate]);

  React.useEffect(() => {
    if (isUserList) {
      onPropertyArrayChange(0, 'name')(UserListDefaultValues.TOTAL_COUNT);
      onPropertyArrayChange(0, 'type')(UserListDefaultValues.INTEGER);
      onPropertyArrayMetricPropChange(0, 'yAxisPosition')(UserListDefaultValues.y0);
    }
  }, [isUserList, onPropertyArrayChange, onPropertyArrayMetricPropChange]);


  return (
    <>
      <Grid container alignItems='center'>
        <Grid item>
          <SectionHeader title='Measures' />
        </Grid>
        <Grid item>
          <Tooltip title='Think quantities. Y-axis of chart. Eg. unique user counts'>
            <HelpOutlineIcon fontSize='small' style={{ marginLeft: '5px' }} />
          </Tooltip>
        </Grid>
        <Grid item>
          <Box pl={2}>
            <QuestionBooleanField
              value={isQuestionAggregate}
              fieldLabel='Aggregate Question'
              onChange={value => setIsQuestionAggregate(value)}
              disabled={isUserList}
            />
          </Box>
        </Grid>
      </Grid>
      {config.map((metric, index) => (
        <Grid container spacing={2} key={`metric-${String(index)}-${metric.ID}`} alignItems='center'>
          <Grid item xs={4} md={3} lg={2}>
            <QuestionTextField
              value={metric.name || ''}
              fieldLabel='System Field Name'
              onChange={onPropertyArrayChange(index, 'name')}
              disabled={isUserList}
            />
          </Grid>
          <Grid item xs={4} md={3} lg={2}>
            <QuestionTextField
              value={metric.displayName || ''}
              fieldLabel='Display Name'
              onChange={onPropertyArrayChange(index, 'displayName')}
            />
          </Grid>
          <Grid item xs={4} md={3} lg={2}>
            <QuestionSelectField
              value={metric.type}
              fieldLabel='Input Type'
              list={inputTypes}
              onChange={onPropertyArrayChange(index, 'type')}
              disabled={isUserList}
            />
          </Grid>
          <Grid item xs={4} md={3} lg={2}>
            <QuestionSelectField
              fieldLabel='YAxis Position'
              list={yAxisPositions}
              onChange={onPropertyArrayMetricPropChange(index, 'yAxisPosition')}
              notRequired
              value={metric.metricConfig?.yAxisPosition}
              disabled={isUserList}
            />
          </Grid>
          {isQuestionAggregate ? (
            <Grid item xs={4} md={3} lg={2}>
              <CrAggrregationField
                aggregateOperator={metric.metricConfig?.aggregateOperator}
                onAggregateValueChange={onPropertyArrayMetricPropChange(index, 'aggregateOperator')}
                disabled={isUserList}
              />
            </Grid>
          ) : null}
          <Grid item xs={4} md={3} lg={2}>
            <QuestionTextField
              value={metric.metricConfig?.valueFormatting}
              fieldLabel='Value formatting'
              required={false}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton size='small' aria-label='help' color='primary' onClick={onValueFormattingHelpShow}>
                      <InfoIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={onPropertyArrayMetricPropChange(index, 'valueFormatting')}
              disabled={isUserList}
            />
          </Grid>
          <ChartSubType
            onChange={onPropertyArrayMetricPropChange(index, 'chartType')}
            metric={metric}
            index={index}
          />
          <Grid item xs={4} md={2}>
            <Grid container direction='column'>
              <Grid item>
                <QuestionBooleanField
                  value={metric.computed}
                  fieldLabel='Needs Computing'
                  onChange={onPropertyArrayChange(index, 'computed')}
                  disabled={isUserList}
                />
              </Grid>
              <Grid item>
                <QuestionBooleanField
                  value={metric.metricConfig?.tabular}
                  fieldLabel='Show only in table'
                  onChange={onPropertyArrayMetricPropChange(index, 'tabular')}
                  disabled={isUserList}
                />
              </Grid>
              <Grid item>
                <QuestionBooleanField
                  value={metric.metricConfig?.noise}
                  fieldLabel='Enable Noise'
                  onChange={onPropertyArrayMetricPropChange(index, 'noise')}
                  disabled={isUserList}
                />
              </Grid>
              <Grid item>
                <QuestionBooleanField
                  value={metric.metricConfig?.isIdentity}
                  fieldLabel='Enable K-min'
                  onChange={onPropertyArrayMetricPropChange(index, 'isIdentity')}
                  disabled={isUserList}
                />
              </Grid>
            </Grid>
          </Grid>
          {index !== 0 && (
          <Grid item>
            <IconButton aria-label='delete' onClick={() => onDeleteItem(index)} size='large'>
              <DeleteIcon />
            </IconButton>
          </Grid>
          )}
        </Grid>
      ))}
      <AddNewSectionButton list={config} onClick={onAddItem} disabled={isUserList} />
      <ValueFormattingHelperDialog
        valueFormattingHelper={valueFormattingHelper}
        onValueFormattingHelpHide={onValueFormattingHelpHide}
      />
    </>
  );
};

export default QuestionMetrics;
