import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import React from 'react';
import TextField from '@mui/material/TextField';
import { HButton } from 'BaseComponents';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import { internalAdmin } from 'utils/spaUrls';


const OrgPropertyDetailsForm = ({ onSubmit, details, propertyTypes }) => {
  const [name, setName] = React.useState('');
  const [url, setUrl] = React.useState('');
  const [propertyType, setPropertyType] = React.useState('');
  const [propertyGroup, setPropertyGroup] = React.useState('');

  React.useEffect(() => {
    if (!details) { return; }
    setName(details.name);
    setUrl(details.url);
    setPropertyType(details.propertyType);
  }, [details]);

  const handleSubmit = (e) => {
    onSubmit({
      name,
      propertyGroup,
      propertyType,
      url,
    });
    e.preventDefault();
  };

  return (
    <Card>
      <CardContent>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} direction='column'>
            <Grid item xs={12}>
              <Typography>Please fill out the form below to create a new organization</Typography>
            </Grid>
            <Grid item sm={12} md={6}>
              <TextField
                label='Name'
                onChange={event => setName(event.target.value)}
                fullWidth
                required
                value={name}
                variant='outlined'
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <TextField
                label='Type'
                onChange={event => setPropertyType(event.target.value)}
                fullWidth
                required
                value={propertyType}
                variant='outlined'
                select
              >
                {propertyTypes.map(
                  p => <MenuItem key={p} value={p}>{p}</MenuItem>,
                )}
              </TextField>
            </Grid>
            <Grid item sm={12} md={6}>
              <TextField
                label='Domain'
                onChange={event => setUrl(event.target.value)}
                fullWidth
                required={propertyType === 'Website'}
                type='url'
                value={url}
                variant='outlined'
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <TextField
                label='Group'
                onChange={event => setPropertyGroup(event.target.value)}
                fullWidth
                value={propertyGroup}
                variant='outlined'
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <Box marginTop={2} display='flex' justifyContent='flex-end'>
                <Box marginRight={2}>
                  <HButton
                    component={Link}
                    to={internalAdmin.organization.organizationProperties}
                    variant='contained'
                    size='small'
                  >
                    Cancel
                  </HButton>
                </Box>
                <HButton
                  variant='contained'
                  color='primary'
                  size='small'
                  type='submit'
                >
                  {details?.ID ? 'Update Property' : 'Add Property'}
                </HButton>
              </Box>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
};

export default OrgPropertyDetailsForm;
