import NodeIDHandler from 'pages/Flow/NodeIDHandler';
import PartitionIcon from 'components/Common/PartitionIcon';
import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import orderBy from 'lodash/orderBy';
import { Box } from '@mui/material';
import { HTableSkeleton } from 'BaseComponents/HDataTable/HTableSkeleton';
import { PARAMETER_TYPE } from '../constants';
import { habuColors } from 'Theme';


const flowNodeRunParameters = (parameters, updateNodeRunParam) => (
  <>
    {parameters.map((p) => (
      <>
        {orderBy(p.nodeRunParameters, ['index'], ['asc']).map((nodeParam) => (
          <TableRow key={`${p.nodeID}-${nodeParam.displayName}`}>
            <TableCell>
              <NodeIDHandler nodeID={p.nodeID} prefix='' />
            </TableCell>
            <TableCell>{p?.nodeQuestionName || ''}</TableCell>
            <TableCell>
              <Box display='flex' alignItems='center'>
                <Box
                  component='span'
                  style={{
                    display: 'inline-block',
                    width: '150px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {nodeParam.displayName}
                </Box>
                {nodeParam?.parameterType === PARAMETER_TYPE.PARTITION_PARAMETER && (
                  <PartitionIcon />
                )}
              </Box>
            </TableCell>
            <TableCell>
              <TextField
                fullWidth
                helperText={nodeParam.displayName}
                margin='dense'
                onChange={(e) => updateNodeRunParam({
                  e,
                  nodeID: p.nodeID,
                  nodeParam,
                })}
                required
                type={nodeParam.type}
                value={nodeParam.value || ''}
                variant='outlined'
              />
            </TableCell>
          </TableRow>
        ))}
      </>
    ))}
  </>
);

const FlowRunForm = ({ flowRunTimeParameters, updateNodeRunParam, loading }) => {
  if (loading) {
    return <HTableSkeleton />;
  }
  const showRunParamTable = flowRunTimeParameters.some(
    (item) => item.nodeRunParameters && item.nodeRunParameters.length > 0,
  );

  if (!showRunParamTable) {
    return null;
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Node ID</TableCell>
            <TableCell>Question Name</TableCell>
            <TableCell>
              <Typography variant='subtitle2' sx={{ color: habuColors.neutral['60'] }}>
                Parameter
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant='subtitle2' sx={{ color: habuColors.neutral['60'] }}>
                Value
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{flowNodeRunParameters(flowRunTimeParameters, updateNodeRunParam)}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default FlowRunForm;
