import React, { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { HDialog, HDialogContent } from 'BaseComponents';
import {
  MenuItem, Skeleton, Stack, TextField, Typography,
} from '@mui/material';
import { isEmpty } from 'lodash';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch } from 'react-redux';
import {
  useFetchOrgCredentials,
  useFetchUdfParameters,
  useFetchUdfTypes,
  useSaveUserDefinedFunction,
} from 'api/userDefinedFunctions';


const LoadingSkeleton = () => Array.from({ length: 5 },
  (_, i) => <Skeleton variant='rectangular' key={i} height={32} />);

const AddFunctionDialog = ({ addFuncOpen, handleRefreshUDF, handleAddFuncClose }) => {
  const dispatch = useDispatch();
  const [udfTypeID, setTypeID] = useState('');

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const {
    data: { udfTypes = [] } = {},
  } = useFetchUdfTypes();

  const udfTypeName = useMemo(() => {
    const selectedType = udfTypes.find(type => type.ID === udfTypeID);
    return selectedType?.displayName ?? '';
  }, [udfTypeID, udfTypes]);

  const {
    data: { credentials = [] } = {},
  } = useFetchOrgCredentials(udfTypeName);

  const {
    isSuccess: isUdfParametersSuccess,
    isError: isUdfParametersError,
    isLoading: isUdfParametersLoading,
    data: { udfJobCreationParameters: udfParameters = [] } = {},
  } = useFetchUdfParameters(udfTypeID);

  const {
    isLoading: isSavingUdf,
    mutate: saveUserDefinedFunction,
  } = useSaveUserDefinedFunction();

  const onSave = (data) => {
    const {
      name, credentialID, typeID, ...parameters
    } = data;

    if (isEmpty(parameters) && isUdfParametersError) {
      dispatch(showAlert({
        message: 'No Parameter Found. Please select a different user-defined function type.',
        type: 'warning',
      }));
      return;
    }

    const udfParams = Object.entries(parameters)
      .map(([paramName, value]) => ({ name: paramName, value }));

    const savePayload = {
      udfJob: {
        name,
        typeID,
        udfParameters: udfParams,
        credentialID,
      },
    };

    saveUserDefinedFunction(savePayload, {
      onSuccess: () => {
        handleRefreshUDF();
        handleAddFuncClose();
      },
    });
  };

  const handleSaveClick = () => {
    handleSubmit(onSave)();
  };

  const handleTypeIDChange = (event) => {
    setTypeID(event.target.value);
  };

  return (
    <HDialog
      fullWidth
      open={addFuncOpen}
      maxWidth='sm'
      title='Add User-Defined Function'
      submitText='Save'
      loading={isSavingUdf}
      onClose={handleAddFuncClose}
      onSubmit={handleSaveClick}
    >
      <HDialogContent>
        <Stack rowGap={2}>
          <Controller
            name='name'
            control={control}
            defaultValue=''
            rules={{
              required: 'Function name is required',
              minLength: {
                value: 3,
                message: 'Function name must be at least 3 characters long',
              },
            }}
            render={({ field }) => (
              <TextField
                id='name'
                label='Function Name'
                required
                fullWidth
                error={!!errors.name}
                helperText={errors.name?.message ?? ''}
                {...field}
              />
            )}
          />
          <Controller
            name='typeID'
            control={control}
            defaultValue=''
            rules={{ required: 'User-Defined Function Type is required' }}
            render={({ field: { onChange, ...restFields } }) => (
              <TextField
                id='typeID'
                label='User-Defined Function Type'
                select
                fullWidth
                value={udfTypeID}
                onChange={(e) => {
                  onChange(e);
                  handleTypeIDChange(e);
                }}
                error={!!errors.typeID}
                helperText={errors.typeID?.message ?? ''}
                {...restFields}
              >
                {udfTypes.map(option => (
                  <MenuItem key={option.ID} value={option.ID}>
                    {option.displayName}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
          <Controller
            name='credentialID'
            control={control}
            defaultValue=''
            rules={{ required: 'Credentials is required' }}
            render={({ field }) => (
              <TextField
                id='credentialID'
                label='Credentials'
                select
                fullWidth
                error={!!errors.credentialID}
                helperText={errors.credentialID?.message ?? ''}
                {...field}
              >
                {
                  credentials.filter(crd => crd.ID).map(option => (
                    <MenuItem key={option.ID} value={option.ID}>
                      {option.name}
                    </MenuItem>
                  ))
                }
              </TextField>
            )}
          />
          {
            isUdfParametersLoading && <LoadingSkeleton />
          }
          {
            isUdfParametersSuccess && !isUdfParametersLoading && (
              <>
                <Typography variant='body1' fontWeight='fontWeightMedium'>Parameters</Typography>
                {udfParameters.map(parameter => (
                  parameter.name === 'DB_FUNCTION_LANGUAGE' ? (
                    <Controller
                      key={parameter.ID}
                      name={parameter.name}
                      control={control}
                      defaultValue='SQL'
                      render={({ field }) => (
                        <TextField
                          id={parameter.name}
                          label={parameter.displayName}
                          fullWidth
                          InputProps={{
                            readOnly: true,
                          }}
                          {...field}
                        />
                      )}
                    />
                  )
                    : (
                      <Controller
                        key={parameter.ID}
                        name={parameter.name}
                        control={control}
                        defaultValue=''
                        rules={{ required: `${parameter.displayName} is required` }}
                        render={({ field }) => (
                          <TextField
                            id={parameter.name}
                            label={parameter.displayName}
                            fullWidth
                            required
                            error={!!errors[parameter.name]}
                            helperText={errors[parameter.name]?.message ?? ''}
                            {...field}
                          />
                        )}
                      />
                    )
                ))}
              </>
            )
          }
        </Stack>
      </HDialogContent>
    </HDialog>
  );
};

export default AddFunctionDialog;
