import { activeOrgSelector } from 'redux/reducer/AuthenticationReducer';
import { axiosInstance } from 'utils/http';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { userDefinedFunctions } from 'utils/urlConstants';


export const useFetchUserDefinedFunction = (udfID) => {
  const { ID: orgId } = useSelector(activeOrgSelector);
  const dispatch = useDispatch();

  return useQuery(
    ['userDefinedFunction', { orgId, udfID }],
    async () => {
      const { data } = await axiosInstance.get(userDefinedFunctions(orgId).one(udfID));
      return data;
    },
    {
      enabled: !!orgId && !!udfID,
      onError: (err) => {
        dispatch(showAlert({
          message: err.response?.data?.message ?? err.response?.statusText ?? '',
          type: 'error',
        }));
      },
    },
  );
};
