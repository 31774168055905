import { activeOrgSelector } from 'redux/reducer/AuthenticationReducer';
import { axiosInstance } from 'utils/http';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'react-query';
import { userDefinedFunctions } from 'utils/urlConstants';


export const useSaveUserDefinedFunction = () => {
  const dispatch = useDispatch();
  const { ID: orgId } = useSelector(activeOrgSelector);

  return useMutation(
    async (payload) => {
      const { data } = await axiosInstance.post(userDefinedFunctions(orgId).create, payload);
      return data;
    },
    {
      onError: (err) => {
        dispatch(showAlert({
          message: err.response?.data?.message ?? err.response?.statusText ?? '',
          type: 'error',
        }));
      },
    },
  );
};
