const defaultDesignToken = {
  fontFamily: [
    'Helvetica',
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    'Arial',
    'sans-serif',
  ],
};

const cleanRoomDesignToken = {
  colorPrimary: '#FFFFFF',
  colorPrimaryDarker: '#212022',
  fontColor: '#212022',
  fontOptions: [
    'Montserrat',
    'Open Sans',
    'Roboto',
    'Arial',
  ],
};

// Motif - Palettes for Charts/Data Visualization
const qualitative18 = {
  10: '#1c2b7f',
  20: '#973490',
  30: '#8acdce',
  40: '#f87018',
  50: '#235845',
  60: '#ccc820',
  70: '#3d91be',
  80: '#ee8b97',
  90: '#2d5e9e',
  100: '#fbb56e',
  110: '#2fd7b0',
  120: '#72690d',
  130: '#b3ddcc',
  140: '#742796',
  150: '#f9812d',
  160: '#1da398',
  170: '#f3aca2',
  180: '#979438',
};
const qualitative27 = {
  ...qualitative18,
  190: '#24448e',
  200: '#e96a8d',
  210: '#62bed2',
  220: '#585723',
  230: '#5e1f88',
  240: '#f99243',
  250: '#3577ae',
  260: '#fcc683',
  270: '#0d7265',
};
const qualitative36 = {
  ...qualitative27,
  280: '#db5087',
  290: '#4d1a70',
  300: '#46aace',
  310: '#b8428c',
  320: '#faa358',
  330: '#6ac1ba',
  340: '#9d64ee',
  350: '#2b3a5c',
  360: '#d1a109',
};

const motifDesignToken = {
  color: {
    blue: {
      50: '#d6f6fc',
      100: '#bbf1fa',
      200: '#9febf7',
      300: '#76e2f4',
      400: '#67cbf2',
      500: '#26b5ed',
      600: '#0d8de0',
      700: '#0680ef',
      800: '#2d2ea8',
      900: '#0d115b',
    },
    darkBlue: {
      50: '#e0eaf3',
      100: '#b3c9e0',
      200: '#80a6cb',
      300: '#4d82b6',
      400: '#2667a7',
      500: '#004c97',
      600: '#00458f',
      700: '#003c84',
      800: '#00337a',
      900: '#002469',
    },
    midnightBlue: {
      50: '#e0e7ed',
      100: '#b3c3d1',
      200: '#809cb2',
      300: '#4d7493',
      400: '#26567c',
      500: '#003865',
      600: '#00325d',
      700: '#002b53',
      800: '#002449',
      900: '#001737',
    },
    lightBlue: {
      50: '#f1f8fc',
      100: '#ddeff8',
      200: '#c6e4f4',
      300: '#afd9ef',
      400: '#9ed0eb',
      500: '#8dc8e8',
      600: '#85c2e5',
      700: '#7abbe2',
      800: '#70b4de',
      900: '#5da7d8',
    },
    green: {
      50: '#e6fbf0',
      100: '#b6f9dc',
      200: '#99edc3',
      300: '#70e6aa',
      400: '#51e098',
      500: '#32db86',
      600: '#1ea464',
      700: '#0a6d41',
      800: '#05502a',
      900: '#043326',
    },
    red: {
      50: '#fde4e9',
      100: '#ffc5d0',
      200: '#ff9fb1',
      300: '#f68297',
      400: '#fa5372',
      500: '#f21d44',
      600: '#de3652',
      700: '#d1213b',
      800: '#a50428',
      900: '#820612',
    },
    orange: {
      50: '#ffece5',
      100: '#ffdAcb',
      200: '#ffb192',
      300: '#ff9166',
      400: '#ff7a45',
      500: '#ff6224',
      600: '#ed5227',
      700: '#da2b09',
      800: '#c82000',
      900: '#b81e00',
    },
    yellow: {
      50: '#fffae1',
      100: '#fef3b3',
      200: '#feec86',
      300: '#ffe763',
      400: '#ffe137',
      500: '#fdd703',
      600: '#f8ce02',
      700: '#f5c100',
      800: '#f1b808',
      900: '#ecb203',
    },
    purple: {
      50: '#edeaff',
      100: '#d9d1f8',
      200: '#c8b2e2',
      300: '#9582e3',
      400: '#866fe7',
      500: '#735dd0',
      600: '#6857b7',
      700: '#4a37a1',
      800: '#3f2286',
      900: '#231673',
    },
    grey: {
      50: '#fafafa',
      100: '#f5f5f5',
      200: '#eeeeee',
      300: '#e0e0e0',
      400: '#b3b3b3',
      500: '#808080',
      600: '#606060',
      700: '#4d4d4d',
      800: '#222222',
      900: '#000000',
    },
    darkGrey: {
      50: '#eff3f5',
      100: '#d7e0e6',
      200: '#bdccd6',
      300: '#a2b8c5',
      400: '#8ea8b8',
      500: '#7a99ac',
      600: '#7291a5',
      700: '#67869b',
      800: '#5d7c92',
      900: '#4a6b82',
    },
    teal: {
      50: '#e1f5f5',
      100: '#b5e5e6',
      200: '#84d4d5',
      300: '#53c2c4',
      400: '#2eb5b8',
      500: '#09a8ab',
      600: '#08a0a4',
      700: '#06979a',
      800: '#058d91',
      900: '#027d80',
    },
    qualitative18,
    qualitative27,
    qualitative36,
    black: '#000000',
    white: '#fff',
  },
};

export { defaultDesignToken, cleanRoomDesignToken, motifDesignToken };
