import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import Snackbar from '@mui/material/Snackbar';


const AUTO_HIDE_DURATIONS = {
  error: 10000,
  warning: 5000,
  success: 3000,
  info: 3000,
};

const Toast = ({
  message, type = 'success', onClose, ...props
}) => {
  const [open, setOpen] = useState(true);

  const handleClose = useCallback((event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);

    if (typeof onClose === 'function') {
      onClose();
    }
  }, [onClose]);

  const getAutoHideDuration = (toastType) => AUTO_HIDE_DURATIONS[toastType] || 3000;

  useEffect(() => {
    setOpen(true);
  }, [message]);

  const action = useMemo(() => (
    <IconButton
      size='small'
      aria-label='close'
      color='inherit'
      onClick={handleClose}
    >
      <CloseIcon fontSize='small' />
    </IconButton>
  ), [handleClose]);

  return (
    <Snackbar
      key={message}
      sx={{ maxWidth: 550, whiteSpace: 'pre-line' }}
      anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      autoHideDuration={getAutoHideDuration(type)}
      onClose={handleClose}
      open={open}
      {...props}
    >
      <Alert
        data-testid='toast-alert'
        action={action}
        severity={type}
        variant='filled'
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default Toast;
