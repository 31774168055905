import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import DataSourceLocationTable
  from 'pages/OrganizationManagement/organization/pages/OrganizationDataSourceLocations/DataSourceLocationTable';
import Grid from '@mui/material/Grid';
import ListViewTable from 'components/Common/ListViewTable';
import PageHeader from 'components/Common/PageHeader';
import React, { useState } from 'react';
import Toast from 'components/Common/Toast';
import { ListControls } from 'components/Common/ListControls';
import {
  createDataLocation,
  createDataLocationReset,
  fetchDataLocations,
} from 'redux/actions/DataLocationActions';
import { crumbs } from 'components/Common/BreadcrumbsNavigation';
import { useDispatch, useSelector } from 'react-redux';


const OrganizationDataSourceLocations = () => {
  const dispatch = useDispatch();
  const accountId = useSelector(state => state.session.user.primaryAccount.ID);
  const orgId = useSelector(state => state.session.user.primaryOrganization.organization.ID);

  const dataLocations = useSelector(state => state.dataLocation.list);
  const newEntry = useSelector(state => state.dataLocation.newDataLocation);
  const [savingDataSource, setSavingDataSource] = useState();

  const createNewDataLocation = (name, region = null) => {
    setSavingDataSource(name);
    dispatch(createDataLocation({
      accountID: accountId,
      organizationID: orgId,
      dataSourceName: name,
      ...(region ? { credentialConfigListValue: region } : {}),
    }));
  };

  React.useEffect(() => { dispatch(fetchDataLocations(orgId)); }, [dispatch, orgId]);

  React.useEffect(() => {
    if (newEntry?.ID) {
      dispatch(fetchDataLocations(orgId));
      setSavingDataSource(undefined);
    }
  }, [dispatch, orgId, newEntry]);

  React.useEffect(() => () => {
    dispatch(createDataLocationReset());
  }, [dispatch]);

  return (
    <>
      {(newEntry?.error) ? <Toast message={newEntry.error} type='error' /> : null}
      {(newEntry?.ID) ? <Toast message='Data Location Generated' type='success' /> : null}
      <PageHeader
        title='Data Source Locations'
        hideBackIcon
        breadcrumbs={[crumbs.home]}
      />
      <Box paddingBottom={2}>
        <ListControls onRefresh={() => dispatch(fetchDataLocations(orgId))} />
      </Box>
      <Grid container spacing={0} direction='column'>
        <Grid item>
          <Card>
            <CardContent>
              <ListViewTable list={dataLocations}>
                <DataSourceLocationTable
                  generateNew={createNewDataLocation}
                  list={dataLocations}
                  isSaving={newEntry && newEntry.isSaving}
                  savingDataSource={savingDataSource}
                />
              </ListViewTable>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default OrganizationDataSourceLocations;
