import Grid from '@mui/material/Grid';
import OrgPropertyDetailsForm
  from 'pages/InternalAdmin/organization/pages/OrganizationPropertyDetails/OrgPropertyDetailsForm';
import PageHeader from 'components/Common/PageHeader';
import React from 'react';
import { Redirect } from 'react-router-dom';
import {
  createProperty,
  createTagJob,
  fetchProperty,
  updateProperty,
} from 'redux/actions/PropertiesActions';
import { crumbs } from 'components/Common/BreadcrumbsNavigation';
import { fetchPropertyTypes } from 'redux/actions/LookupActions';
import { internalAdmin } from 'utils/spaUrls';
import { useDispatch, useSelector } from 'react-redux';


const OrganizationPropertyDetails = (props) => {
  const { propertyId } = props.match.params;
  const dispatch = useDispatch();
  const meta = useSelector(state => state.properties.meta);
  const properties = useSelector(state => state.properties.list);
  const details = useSelector(state => state.properties.details);
  const propertyTypes = useSelector(state => state.lookups.propertyTypes);
  const orgId = useSelector(state => state.session.user.primaryOrganization.organization.ID);
  const [submitted, setSubmitted] = React.useState(false);

  React.useEffect(() => {
    if (orgId && propertyId) {
      dispatch(fetchProperty(orgId, propertyId));
    }
    dispatch(fetchPropertyTypes());
  }, [dispatch, propertyId, orgId]);

  React.useEffect(() => {
    if (meta.created && properties.length) {
      const property = properties[properties.length - 1];
      dispatch(createTagJob(orgId, { propertyID: property.ID }));
    }
  }, [dispatch, propertyId, orgId, meta.created, properties]);

  const onSubmit = (property) => {
    setSubmitted(true);
    if (propertyId) {
      return dispatch(updateProperty(orgId, propertyId, property));
    }
    return dispatch(createProperty(orgId, property));
  };

  if (meta.redirect && submitted) {
    return <Redirect to={internalAdmin.organization.organizationProperties} />;
  }

  return (
    <>
      <PageHeader
        title={propertyId ? 'Edit Property' : 'New Property'}
        breadcrumbs={[crumbs.helium, crumbs.internalAdmin]}
      />
      <Grid container spacing={2}>
        <Grid item xl={12} sm={12}>
          <OrgPropertyDetailsForm
            onSubmit={onSubmit}
            details={details}
            propertyTypes={propertyTypes}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default OrganizationPropertyDetails;
