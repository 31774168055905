import React from 'react';
import { activeOrgSelector } from 'redux/reducer/AuthenticationReducer';
import { axiosInstance } from 'utils/http';
import { organizationParameters } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';


export const useFetchOrganizationParametersWithCategory = () => {
  const dispatch = useDispatch();
  const { ID: orgId } = useSelector(activeOrgSelector);
  const [data, setData] = React.useState([]);
  const {
    isLoading, isError, isSuccess, refetch, isFetching,
  } = useQuery(
    ['OrganizationParametersWithCategory', orgId],
    async () => axiosInstance.get(organizationParameters(orgId).listWithCategory),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: (res) => setData(res.data?.parameters),
      onError: (err) => dispatch(showAlert({ message: err.response?.data.message, type: 'error' })),
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    data,
    refetch,
    isFetching,
  };
};
