import { PERMISSION } from 'redux/actions/Types';
import { permission } from 'utils/urlConstants';


export const createPermission = data => ({
  type: PERMISSION.CREATE_PERMISSION,
  payload: data,
  url: permission.create,
});

export const createPermissionSuccess = data => ({
  type: PERMISSION.CREATE_PERMISSION_SUCCESS,
  payload: data,
});

export const createPermissionError = data => ({
  type: PERMISSION.CREATE_PERMISSION_ERROR,
  payload: data,
});

export const fetchProductPermission = prodID => ({
  type: PERMISSION.FETCH_PRODUCT_PERMISSION,
  url: permission.productPermission(prodID),
});

export const fetchProductPermissionSuccess = data => ({
  type: PERMISSION.FETCH_PRODUCT_PERMISSION_SUCCESS,
  payload: data,
});

export const fetchProductPermissionError = data => ({
  type: PERMISSION.FETCH_PRODUCT_PERMISSION_ERROR,
  payload: data,
});

export const updateProductPermissions = (prodID, data) => ({
  type: PERMISSION.UPDATE_PRODUCT_PERMISSIONS,
  url: permission.productPermission(prodID),
  payload: data,
});

export const updateProductPermissionsSuccess = data => ({
  type: PERMISSION.UPDATE_PRODUCT_PERMISSIONS_SUCCESS,
  payload: data,
});

export const updateProductPermissionsError = data => ({
  type: PERMISSION.UPDATE_PRODUCT_PERMISSIONS_ERROR,
  payload: data,
});
