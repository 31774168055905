export const DATA_OUT = {
  FETCH_ACTIVATION: 'FETCH_ACTIVATION',
  FETCH_ACTIVATION_ERROR: 'FETCH_ACTIVATION_ERROR',
  FETCH_ACTIVATION_SUCCESS: 'FETCH_ACTIVATION_SUCCESS',
  FETCH_ACTIVATION_NAMES: 'FETCH_ACTIVATION_NAMES',
  FETCH_ACTIVATION_NAMES_ERROR: 'FETCH_ACTIVATION_NAMES_ERROR',
  FETCH_ACTIVATION_NAMES_SUCCESS: 'FETCH_ACTIVATION_NAMES_SUCCESS',
  CREATE_ACTIVATION: 'CREATE_ACTIVATION',
  CREATE_ACTIVATION_ERROR: 'CREATE_ACTIVATION_ERROR',
  CREATE_ACTIVATION_SUCCESS: 'CREATE_ACTIVATION_SUCCESS',
  FETCH_DATA_OUT_JOBS: 'FETCH_DATA_OUT_JOBS',
  CREATE_EXPORT: 'CREATE_EXPORT',
  CREATE_PARTNER_ACCOUNT: 'CREATE_PARTNER_ACCOUNT',
  CREATE_PARTNER_ACCOUNT_ERROR: 'CREATE_PARTNER_ACCOUNT_ERROR',
  CREATE_PARTNER_ACCOUNT_SUCCESS: 'CREATE_PARTNER_ACCOUNT_SUCCESS',
  DELETE_ACTIVATION: 'DELETE_ACTIVATION',
  DELETE_ACTIVATION_ERROR: 'DELETE_ACTIVATION_ERROR',
  DELETE_ACTIVATION_SUCCESS: 'DELETE_ACTIVATION_SUCCESS',
  DELETE_ACTIVATION_RUN: 'DELETE_ACTIVATION_RUN',
  DELETE_ACTIVATION_RUN_SUCCESS: 'DELETE_ACTIVATION_RUN_SUCCESS',
  DELETE_ACTIVATION_RUN_ERROR: 'DELETE_ACTIVATION_RUN_ERROR',
  FETCH_ACTIVATION_FILTERS: 'FETCH_ACTIVATION_FILTERS',
  FETCH_ACTIVATION_FILTERS_ERROR: 'FETCH_ACTIVATION_FILTERS_ERROR',
  FETCH_ACTIVATION_FILTERS_SUCCESS: 'FETCH_ACTIVATION_FILTERS_SUCCESS',
  FETCH_ACTIVATION_REPORT: 'FETCH_ACTIVATION_REPORT',
  FETCH_ACTIVATION_REPORT_ERROR: 'FETCH_ACTIVATION_REPORT_ERROR',
  FETCH_ACTIVATION_REPORT_SUCCESS: 'FETCH_ACTIVATION_REPORT_SUCCESS',
  FETCH_DATA_OUT_SUMMARY: 'FETCH_DATA_OUT_SUMMARY',
  FETCH_DATA_OUT_SUMMARY_ERROR: 'FETCH_DATA_OUT_SUMMARY_ERROR',
  FETCH_DATA_OUT_SUMMARY_SUCCESS: 'FETCH_DATA_OUT_SUMMARY_SUCCESS',
  FETCH_PARTNER_ACCOUNTS: 'FETCH_PARTNER_ACCOUNTS',
  FETCH_PARTNER_ACCOUNTS_ERROR: 'FETCH_PARTNER_ACCOUNTS_ERROR',
  FETCH_PARTNER_ACCOUNTS_SUCCESS: 'FETCH_PARTNER_ACCOUNTS_SUCCESS',
  FETCH_PARTNER_ACCOUNT_FILTERS: 'FETCH_PARTNER_ACCOUNT_FILTERS',
  FETCH_PARTNER_ACCOUNT_FILTERS_ERROR: 'FETCH_PARTNER_ACCOUNT_FILTERS_ERROR',
  FETCH_PARTNER_ACCOUNT_FILTERS_SUCCESS: 'FETCH_PARTNER_ACCOUNT_FILTERS_SUCCESS',
  FETCH_PARTNER_JOBS_VERIFICATION: 'FETCH_PARTNER_JOBS_VERIFICATION',
  FETCH_PARTNER_JOBS_VERIFICATION_ERROR: 'FETCH_PARTNER_JOBS_VERIFICATION_ERROR',
  FETCH_PARTNER_JOBS_VERIFICATION_SUCCESS: 'FETCH_PARTNER_JOBS_VERIFICATION_SUCCESS',
  VERIFY_JOB_PARTNERS: 'VERIFY_JOB_PARTNERS',
  VERIFY_JOB_PARTNERS_ERROR: 'VERIFY_JOB_PARTNERS_ERROR',
  VERIFY_JOB_PARTNERS_SUCCESS: 'VERIFY_JOB_PARTNERS_SUCCESS',
  FETCH_PARTNERS: 'FETCH_PARTNERS',
  FETCH_PARTNERS_ERROR: 'FETCH_PARTNERS_ERROR',
  FETCH_PARTNERS_SUCCESS: 'FETCH_PARTNERS_SUCCESS',
  GET_PARTNER_ACCOUNT: 'GET_PARTNER_ACCOUNT',
  UPDATE_PARTNER_ACCOUNT: 'UPDATE_PARTNER_ACCOUNT',
  UPDATE_ACTIVATION_STATUS: 'UPDATE_ACTIVATION_STATUS',
  UPDATE_ACTIVATION_STATUS_ERROR: 'UPDATE_ACTIVATION_STATUS_ERROR',
  UPDATE_ACTIVATION_STATUS_SUCCESS: 'UPDATE_ACTIVATION_STATUS_SUCCESS',
};
