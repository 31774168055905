import Box from '@mui/material/Box';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import List from '@mui/material/List';
import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import { HButton } from 'BaseComponents';
import { HelpItem } from 'pages/Flow/HelpFlow/HelpItem';
import { HtmlTooltip } from 'pages/Flow/HelpFlow/HtmlTooltip';
import { ReactComponent as LiveRampLogoBlack } from 'assets/logo/liveRampLogoBlack.svg';
import { objectList } from 'pages/Flow/HelpFlow/contacts';


export const HelpFlow = () => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <HtmlTooltip
      PopperProps={{
        disablePortal: true,
      }}
      onClickAway={handleTooltipClose}
      open={open}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      title={(
        <Box display='flex' flexDirection='column'>
          <LiveRampLogoBlack style={{ margin: '5px auto 10px' }} />
          <Typography variant='h5'>
            Welcome to Flow Builder!
          </Typography>
          <Typography variant='subtitle2'>
            Select an object below to get started
          </Typography>
          <List>
            {objectList.map((item) => (
              <HelpItem key={item.type} {...item} />
            ))}
          </List>
        </Box>
      )}
    >
      <HButton
        onClick={handleTooltipOpen}
        startIcon={<HelpOutlineIcon />}
        variant='text'
        color='link'
        size='small'
      >
        Help
      </HButton>
    </HtmlTooltip>
  );
};
