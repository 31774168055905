import { getNodesConnection, getNodesTypeConnect } from 'redux/reducer/FlowReducer/utils';


export const openFlowDialogEdgesConnection = (edge, nodes) => {
  // TODO: need nodes here to add information to the dialog from it
  const nodesData = getNodesConnection(nodes, edge.source, edge.target);
  const connectionType = getNodesTypeConnect(nodesData[0].type, nodesData[1].type);

  const dialogData = {
    ...edge,
  };

  return {
    open: true,
    type: connectionType,
    data: dialogData,
    nodesData,
  };
};
