import Grid from '@mui/material/Grid';
import OrganizationUserForm from 'pages/OrganizationManagement/organization/pages/OrganizationUsers/OrganizationUserForm';
import PageHeader from 'components/Common/PageHeader';
import React from 'react';
import Toast from 'components/Common/Toast';
import { HSpinner } from 'BaseComponents';
import { Redirect } from 'react-router-dom';
import {
  createUser,
  fetchRoleList,
  fetchUser,
  sendEmail,
  updateUser,
} from 'redux/actions/IamActions';
import { crumbs } from 'components/Common/BreadcrumbsNavigation';
import { habuSupport } from 'utils/appConstants';
import { organizationManagement } from 'utils/spaUrls';
import { useDispatch, useSelector } from 'react-redux';


const saveUser = dispatch => (data, orgUid) => {
  if (data.user.ID) {
    return dispatch(updateUser(orgUid, data.user.ID, { user: data }));
  }
  return dispatch(createUser(orgUid, { users: [data] }));
};

const OrganizationUser = (props) => {
  const { id } = props.match.params;
  const dispatch = useDispatch();
  const userDetails = useSelector(state => state.iam.userDetail);
  const orgId = useSelector(state => state.session.user.primaryOrganization.organization.ID);
  const roleList = useSelector(state => state.iam.roleList);
  const sessionUserId = useSelector(state => state.session.user.user.ID);
  const orgType = useSelector(state => state.session.user
    .primaryOrganization.organization.organizationType);
  const [submitted, setSubmitted] = React.useState(false);
  const [email, setEmail] = React.useState();
  const { meta } = userDetails;

  React.useEffect(() => {
    dispatch(fetchRoleList(orgId));
  }, [orgId, id, dispatch]);

  React.useEffect(() => {
    if (orgId && id) {
      dispatch(fetchUser(orgId, id));
    }
  }, [dispatch, id, orgId]);


  if (submitted && meta.redirect) {
    if (email) {
      dispatch(sendEmail(orgId, email));
    }
    return <Redirect to={organizationManagement.organization.users.list} />;
  }

  if (!roleList) return <HSpinner />;
  else if (roleList.error) {
    const message = `Cannot create a user due to an error fetching list of roles. ${habuSupport.message}`;
    return (
      <>
        <Toast type='error' message={message} />
        <Redirect to={organizationManagement.organization.users.list} />
      </>
    );
  }
  else {
    return (
      <>
        <PageHeader title={id ? 'Edit User' : 'New User'} breadcrumbs={[crumbs.home, crumbs.orgUserList]} />
        <Grid container direction='row' spacing={2}>
          <Grid item xl={12} l={12} md={12} sm={12}>
            <OrganizationUserForm
              save={(data) => {
                const { user, roleName, sendWelcomeEmail } = data;
                saveUser(dispatch)({ user, roleName }, orgId);
                setEmail(sendWelcomeEmail ? user.email : null);
                setSubmitted(true);
              }}
              orgType={orgType}
              details={id ? { ...userDetails.user, role: userDetails.roleName } : {}}
              allRoles={roleList}
              sessionUserId={sessionUserId}
            />
          </Grid>
        </Grid>
      </>
    );
  }
};

export default OrganizationUser;
