import React, { memo } from 'react';
import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';


export const HSubRowIndicatorCell = memo(({ showSubRows }) => (
  <td>
    {showSubRows && <KeyboardArrowDown style={{ verticalAlign: 'inherit' }} />}
    {!showSubRows && <KeyboardArrowRight style={{ verticalAlign: 'inherit' }} />}
  </td>
));

HSubRowIndicatorCell.displayName = 'HSubRowIndicatorCell';
