import Grid from '@mui/material/Grid';
import React from 'react';
import Switch from 'components/Common/Switch';
import TextField from '@mui/material/TextField';


const PermissionConfigurationForm = ({ permissionDetails = {}, updatePermission }) => {
  const updatePermissionObject = (key, value) => {
    updatePermission({
      ...permissionDetails,
      [key]: value,
    });
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label='Name'
          margin='normal'
          onChange={e => updatePermissionObject('name', e.target.value)}
          required
          value={permissionDetails.name || ''}
          variant='outlined'
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label='Display Name'
          margin='normal'
          onChange={e => updatePermissionObject('displayName', e.target.value)}
          required
          value={permissionDetails.displayName || ''}
          variant='outlined'
        />
      </Grid>
      <Grid item md={9} xs={12}>
        <Switch
          label='Internal Permission'
          checked={permissionDetails.isInternal}
          value={permissionDetails.isInternal}
          onChange={e => updatePermissionObject('isInternal', e.target.checked)}
        />
      </Grid>
    </Grid>
  );
};

export default PermissionConfigurationForm;
