import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import React from 'react';
import {
  BaseEdge,
  EdgeLabelRenderer,
  getBezierPath,
} from 'reactflow';
import { editQuestionEdge } from 'redux/actions/FlowAction';
import { useDispatch, useSelector } from 'react-redux';


const ButtonEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
}) => {
  const dispatch = useDispatch();
  const edges = useSelector(state => state.flow.edges);
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });


  const onEdgeClick = () => {
    const currentEdge = edges.find(edge => edge.id === id);
    dispatch(editQuestionEdge({
      type: 'QUESTION_TO_QUESTION',
      data: currentEdge,
      edgeId: currentEdge.id,
    }));
  };

  return (
    <>
      <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
      <EdgeLabelRenderer>
        <div
          style={{
            position: 'absolute',
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            fontSize: 12,
            // everything inside EdgeLabelRenderer has no pointer events by default
            // if you have an interactive element, set pointer-events: all
            pointerEvents: 'all',
          }}
          className='nodrag nopan'
        >
          <IconButton onClick={onEdgeClick} color='primary' sx={{ backgroundColor: 'white' }}>
            <InfoIcon />
          </IconButton>
        </div>
      </EdgeLabelRenderer>
    </>
  );
};

export default ButtonEdge;
