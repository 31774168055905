import React, { memo } from 'react';
import Typography from '@mui/material/Typography';
import { CHAT_STATUS } from 'pages/InternalAdmin/organization/pages/AlertsRequest/constant';


export const StatusCell = memo(({ row }) => (
  <Typography component='div' sx={{ color: CHAT_STATUS[row.status].color }}>
    {CHAT_STATUS[row.status].label}
  </Typography>
));

StatusCell.displayName = 'StatusCell';
