import { activeOrgSelector } from 'redux/reducer/AuthenticationReducer';
import { axiosInstance } from 'utils/http';
import { dataCredential } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation, useQueryClient } from 'react-query';


export const useSaveIdentityCredential = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { ID: orgId } = useSelector(activeOrgSelector);

  return useMutation(
    (newCredential) => axiosInstance.post(dataCredential(orgId).createOrgCredSource, newCredential),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['identityCredentials', orgId]);
        dispatch(showAlert({
          message: 'Identity credential saved successfully',
          type: 'success',
        }));
      },
      onError: () => {
        dispatch(showAlert({
          message: 'Failed to save identity credential',
          type: 'error',
        }));
      },
    },
  );
};
