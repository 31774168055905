import { activeOrgSelector } from 'redux/reducer/AuthenticationReducer';
import { axiosInstance } from 'utils/http';
import { dataCredential } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation, useQueryClient } from 'react-query';


export const useDeleteIdentityCredential = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { ID: orgId } = useSelector(activeOrgSelector);

  return useMutation(
    (credId) => axiosInstance.delete(dataCredential(orgId).deleteOrgCredSource(credId)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['identityCredentials', orgId]);
        dispatch(showAlert({
          message: 'Identity credential deleted successfully',
          type: 'success',
        }));
      },
      onError: () => {
        dispatch(showAlert({
          message: 'Failed to delete identity credential',
          type: 'error',
        }));
      },
    },
  );
};
