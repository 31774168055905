import React, { memo, useContext } from 'react';
import { DataContext } from 'BaseComponents/HDataTable/DataContextProvider';
import { FilterList } from '@mui/icons-material';
import { HButton } from 'BaseComponents';


export const HFiltersButton = memo(({
  toggleShowFilters,
  searchAttribute,
  showFilters,
}) => {
  const {
    handleSearchChange,
  } = useContext(DataContext);

  return (
    <HButton
      onClick={() => toggleShowFilters(!showFilters)}
      size='small'
      variant='outlined'
    >
      <FilterList fontSize='small' style={{ marginRight: '5px' }} />
      {handleSearchChange && searchAttribute ? 'Filters & Search' : 'Filters'}
    </HButton>
  );
});

HFiltersButton.displayName = 'HFiltersButton';
