import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import OrgParameterTable from './OrgParameterTable';
import PageHeader from 'components/Common/PageHeader';
import React, { useState } from 'react';
import { HButton } from 'BaseComponents';
import { StyledGrid } from 'components/IAM/Admin/StyledComponents';
import { crumbs } from 'components/Common/BreadcrumbsNavigation';
import { isInputOrgParameter } from './constant';
import {
  useCreateOrganizationParameters,
} from 'api/organizationParameters/useCreateOrganizationParameters';
import {
  useDeleteOrganizationParameters,
} from 'api/organizationParameters/useDeleteOrganizationParameters';
import {
  useFetchOrganizationParametersWithCategory,
} from 'api/organizationParameters/useFetchOrganizationParametersWithCategory';
import {
  useUpdateOrganizationParameters,
} from 'api/organizationParameters/useUpdateOrganizationParameters';
import { v4 as uuidGen } from 'uuid';


const OrganizationParameters = () => {
  const [paramList, setParamList] = useState([]);
  const {
    data: orgParameters,
    refetch: refetchOrgParameters,
  } = useFetchOrganizationParametersWithCategory();

  const {
    isSuccess: isCreatingOrgParametersSuccess,
    mutate: createOrgParameters,
    reset: resetCreatingOrgParameters,
  } = useCreateOrganizationParameters();

  const {
    isSuccess: isDeletingOrgParametersSuccess,
    mutate: deleteOrgParameters,
    reset: resetDeletingOrgParameters,
  } = useDeleteOrganizationParameters();

  const {
    isSuccess: isUpdatingOrgParametersSuccess,
    mutate: updateOrgParameters,
    reset: resetUpdatingOrgParameters,
  } = useUpdateOrganizationParameters();

  React.useEffect(() => {
    if (isDeletingOrgParametersSuccess || isCreatingOrgParametersSuccess
      || isUpdatingOrgParametersSuccess || orgParameters.length === 0) {
      refetchOrgParameters();
      resetCreatingOrgParameters();
      resetDeletingOrgParameters();
      resetUpdatingOrgParameters();
    }
  }, [isCreatingOrgParametersSuccess, isDeletingOrgParametersSuccess,
    isUpdatingOrgParametersSuccess, orgParameters, refetchOrgParameters,
    resetCreatingOrgParameters, resetDeletingOrgParameters, resetUpdatingOrgParameters]);

  React.useEffect(() => {
    const transformedList = orgParameters ? orgParameters.map(row => (
      {
        ID: uuidGen(),
        name: row.name,
        value: row.value,
        category: row.category,
      }
    )) : [{}];
    setParamList(transformedList);
  }, [orgParameters]);

  const handleAdd = React.useCallback(() => {
    const newList = [...paramList];
    newList.push({
      ID: uuidGen(), name: '', value: '', category: '', type: 'add',
    });
    setParamList(newList);
  }, [paramList]);

  const handleCancel = (ID) => {
    const newList = [...paramList];
    const filtered = newList.filter(item => item.ID !== ID);
    setParamList(filtered);
  };

  const handleUpdate = (value, row, key) => {
    const newList = [...paramList];
    const filtered = newList.map(item => {
      if (item.ID === row.ID) {
        const newItem = { ...item };
        newItem[key] = value;
        return newItem;
      }
      return item;
    });
    setParamList(filtered);
  };

  const createNewParams = () => {
    const newList = [...paramList];
    const newParamList = newList
      .filter(isInputOrgParameter)
      .map(item => ({ name: item.name, value: item.value, category: item.category }));
    const body = {
      parameters: newParamList,
    };
    const filtered = newList.map(item => ({ ...item, type: '' }));
    setParamList(filtered);
    createOrgParameters({ payload: body });
  };

  const isSaveDisabled = () => {
    const [inputOrgParameter] = paramList.filter(isInputOrgParameter);
    const isInputUndefined = !inputOrgParameter;

    const doesParamAlreadyExist = orgParameters
      .some(({ name }) => name === inputOrgParameter?.name);

    return isInputUndefined || doesParamAlreadyExist;
  };

  return (
    <>
      <PageHeader title='Organization Parameters' breadcrumbs={[crumbs.helium, crumbs.internalAdmin]} />
      <Grid container spacing={0} direction='column'>
        <Grid item>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <OrgParameterTable
                    update={handleUpdate}
                    cancel={handleCancel}
                    deleteOrgParameters={deleteOrgParameters}
                    updateOrgParameters={updateOrgParameters}
                    orgParameters={paramList}
                  />
                </Grid>
                <Grid item xs={4}>
                  <HButton
                    color='primary'
                    size='small'
                    variant='contained'
                    onClick={() => handleAdd()}
                  >
                    Add new
                  </HButton>
                </Grid>
                <StyledGrid item xs={12}>
                  <HButton
                    color='primary'
                    size='small'
                    variant='contained'
                    onClick={() => createNewParams()}
                    disabled={isSaveDisabled()}
                  >
                    Save
                  </HButton>
                </StyledGrid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default OrganizationParameters;
