import Box from '@mui/material/Box';
import React, { useEffect, useRef, useState } from 'react';
import localStorage from 'utils/localStorage';
import { CACHE } from 'utils/appConstants';
import { hIEmbed } from 'utils/urlConstants';
import { useHIAuthHandler } from 'hooks/habuIntelligenceAPI';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';


const MetabaseAppEmbed = (props) => {
  const { crId } = useParams();
  const crDetail = useSelector(state => state.cleanRooms.detail);
  const iframeEl = useRef(null);
  const [src, setSrc] = useState();

  const token = localStorage.get(CACHE.user.jwtAccessToken);
  const userOrgId = localStorage.get(CACHE.user.primaryOrg);

  const height = window.innerHeight - 100;

  const {
    data: handlerRes,
  } = useHIAuthHandler(crDetail?.organizationID, crId);

  useEffect(() => {
    if (!crId) {
      const urlQuery = {
        token,
        organization: userOrgId,
      };
      const url = hIEmbed.auth(urlQuery);
      setSrc(url);
    }
  }, [token, crId, userOrgId]);

  useEffect(() => {
    if (handlerRes && !props.activeHIBookmark) {
      const {
        cleanRoomID, groupName, habuIntelligenceURL, organizationID,
      } = handlerRes.data;
      const urlQuery = {
        token,
        organization: organizationID,
        return_to: habuIntelligenceURL,
        'group-name': groupName,
        'clean-room': cleanRoomID,
      };
      const url = hIEmbed.auth(urlQuery);
      setSrc(url);
    }
    else if (props.activeHIBookmark) {
      const urlQuery = {
        token,
        organization: crDetail?.organizationID,
        return_to: `/dashboard/${props.activeHIBookmark.dashboardID}`,
        'group-name': props.activeHIBookmark.groupName,
        'clean-room': crId,
      };
      setSrc(hIEmbed.auth(urlQuery));
    }
  }, [token, handlerRes, props.activeHIBookmark, crDetail?.organizationID, crId]);

  return (
    <Box>
      <iframe
        ref={iframeEl}
        src={src}
        title='Metabase'
        style={{ border: 'none', width: '100%', height }}
      />
    </Box>
  );
};

export default MetabaseAppEmbed;
