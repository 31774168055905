export const setColumnsLocalStorage = (tableName, columnsObject) => {
  // Distributes only the data we need in localStorage
  const columns = columnsObject.map(({ field, width }) => ({ field, width }));

  const columnsString = JSON.stringify(columns);

  localStorage.setItem(tableName, columnsString);
};

export const getColumnsLocalStorage = (tableName) => {
  const localStorageColumns = localStorage.getItem(tableName);

  const localStorageColumnsMap = new Map(
    (JSON.parse(localStorageColumns) ?? []).map(i => [i.field, i]),
  );

  return localStorageColumnsMap;
};
