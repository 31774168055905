import { DATA_LOCATION } from 'redux/actions/Types';


const DataLocationReducer = (state = {}, action) => {
  switch (action.type) {
    case DATA_LOCATION.CREATE_DATA_LOCATION:
      return { ...state, newDataLocation: { isSaving: true } };
    case DATA_LOCATION.CREATE_DATA_LOCATION_SUCCESS:
      return { ...state, newDataLocation: action.payload };
    case DATA_LOCATION.CREATE_DATA_LOCATION_ERROR:
      return { ...state, newDataLocation: { error: action.payload } };
    case DATA_LOCATION.CREATE_DATA_LOCATION_RESET:
      return { ...state, newDataLocation: undefined };
    case DATA_LOCATION.FETCH_DATA_LOCATIONS:
      return { ...state, list: undefined };
    case DATA_LOCATION.FETCH_DATA_LOCATIONS_SUCCESS:
      return { ...state, list: action.payload };
    case DATA_LOCATION.FETCH_DATA_LOCATIONS_ERROR:
      return { ...state, list: { error: action.payload } };
    default:
      return state;
  }
};

export default DataLocationReducer;
