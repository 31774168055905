import Bouncer from 'components/Common/Bouncer';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { FLOW_STAGE } from 'utils/ignoramusEnums';
import { HButton } from 'BaseComponents';
import { Link, useHistory, useParams } from 'react-router-dom';
import { cleanRoom } from 'utils/spaUrls';
import { cleanRoomPermissions } from 'utils/appConstants';
import { updateSelectedFlow } from 'redux/actions/FlowAction';
import { useDispatch } from 'react-redux';


const FlowDatasetsManagement = ({ flowDetails }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { crId } = useParams();
  const flowDatasetStage = flowDetails?.stage;
  const onClickManageDatasets = () => {
    dispatch(updateSelectedFlow({ flowID: flowDetails.flow.ID }));
    history.push(cleanRoom.flowManageDatasets(crId, flowDetails.ID));
  };

  if (!flowDetails) {
    return null;
  }

  return (
    <>
      <Bouncer allowList={[cleanRoomPermissions.manageDatasets]}>
        <HButton
          size='small'
          data-testid='flow-management-dataset-button'
          component={Link}
          to='#'
          onClick={onClickManageDatasets}
          variant='outlined'
        >
          Manage Datasets
        </HButton>
      </Bouncer>
      <Tooltip title={FLOW_STAGE[flowDatasetStage].label} placement='top'>
        <IconButton data-testid='check-circle-icon'>
          {(flowDatasetStage === FLOW_STAGE.READY.key) ? (
            <CheckCircleIcon color='success' />
          ) : (
            <InfoIcon color='action' />
          )}
        </IconButton>
      </Tooltip>
    </>
  );
};


export default FlowDatasetsManagement;
