import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import InfoIcon from '@mui/icons-material/Info';
import MotifStepIcon from 'BaseComponents/MotifStepIcon';
import PageHeader from 'components/Common/PageHeader';
import React from 'react';
import SectionHeader from 'components/IAM/Admin/SectionHeader';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import {
  Card, CardContent, IconButton, StepConnector, TableHead,
  Tooltip,
} from '@mui/material';
import {
  CloudDownload,
  Refresh,
} from '@mui/icons-material';
import { HButton } from 'BaseComponents';
import { checkDagStatus, uploadDemoData } from 'pages/InternalAdmin/organization/DemoData/redux';
import { crumbs } from 'components/Common/BreadcrumbsNavigation';
import { demoData } from 'utils/urlConstants';
import { downloadDemoErrorReport } from 'redux/actions/DownloadActions';
import { showAlert } from 'redux/actions/AlertActions';
import { uploadDagStatus } from 'pages/InternalAdmin/organization/DemoData/constants';
import { useDispatch, useSelector } from 'react-redux';


const MAX_FILE_SIZE = 5242880; // 5MB

const DemoData = () => {
  const step = 0;
  const dispatch = useDispatch();
  const orgId = useSelector(state => state.session.user.primaryOrganization.organization.ID);
  const uploadStatus = useSelector(state => state.demoData.upload.status);
  const dagStatus = useSelector(state => state.demoData.dag.status);
  const [demoFile, setDemoFile] = React.useState();


  React.useEffect(() => {
    if (uploadStatus && uploadStatus.success) {
      dispatch(showAlert({ message: 'File uploaded', type: 'success' }));
      // TODO don't null existing files if they haven't been uploaded
      setDemoFile(null);
      dispatch(uploadDemoData.reset());
    }
    else if (uploadStatus && uploadStatus.error) {
      dispatch(showAlert({ message: uploadStatus.error, type: 'error' }));
    }
    // need to check dag status when refreshing the page
  }, [dispatch, uploadStatus]);


  React.useEffect(() => {
    dispatch(checkDagStatus.ignite(orgId));
  }, [dispatch, orgId]);


  const upload = (f) => {
    const formData = new FormData();
    formData.append('file', f);
    formData.append('organizationId', orgId);
    dispatch(uploadDemoData.ignite(formData));
  };

  const getStatusComponent = () => {
    switch (dagStatus?.status) {
      case uploadDagStatus.FAILED.val:
        return (
          <>
            <Typography color='error'>{uploadDagStatus.FAILED.label}</Typography>
            <Tooltip title='Download Error Report'>
              <IconButton
                aria-label='download'
                color='error'
                onClick={() => dispatch(downloadDemoErrorReport(orgId, 'demo_error_report.xlsx', demoData.download(orgId)))}
                size='large'
              >
                <CloudDownload />
              </IconButton>
            </Tooltip>
          </>
        );

      case uploadDagStatus.PENDING.val:
        return (<Typography color='warning'>{uploadDagStatus.PENDING.label}</Typography>);

      case uploadDagStatus.SUCCESS.val:
        return (<Typography color='success'>{uploadDagStatus.SUCCESS.label}</Typography>);

      default:
        return (<Typography>{uploadDagStatus.UNKNOWN.label}</Typography>);
    }
  };

  const demoFileBlock = (file, setFile) => (
    <TableRow>
      <TableCell>
        <label>
          <input
            accept='.xlsx'
            name='upload-demo-data'
            style={{ display: 'none' }}
            type='file'
            onChange={e => setFile(e.target.files[0])}
          />
          <HButton variant='contained' color='primary' component='span'>Select XLSX for Demo Data</HButton>
        </label>
      </TableCell>
      <TableCell>
        {file ? `Name: ${file.name} Size: ${Number(file.size / 1024).toFixed(0)}KB` : null}
        {file && file.size > MAX_FILE_SIZE ? (
          <Typography color='error'>File is too big</Typography>
        ) : null}
      </TableCell>
      <TableCell>
        <HButton
          variant='contained'
          color='primary'
          component='span'
          disabled={!file || file.size > MAX_FILE_SIZE
          || !dagStatus || dagStatus.status === uploadDagStatus.PENDING.val}
          onClick={() => upload(file)}
        >
          Upload
        </HButton>
      </TableCell>
      <TableCell>
        { getStatusComponent() }
      </TableCell>
    </TableRow>
  );


  const getStepComponent = () => (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}><SectionHeader title='Before you begin' /></Grid>
          <Grid item xs={12}><Divider /></Grid>
          <Grid item xs={1}>
            <InfoIcon />
          </Grid>
          <Grid item xs={11}>
            Prepare your sheet with demo data.
          </Grid>
          <Grid item xs={12}><Divider /></Grid>
          <Grid item xs={1}>
            <InfoIcon />
          </Grid>
          <Grid item xs={11}>
            File should have .xlsx extension and be less than 5MB
          </Grid>
          <Grid item xs={12}><Divider /></Grid>
          <Grid item xs={12}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Select File</TableCell>
                  <TableCell>File Info</TableCell>
                  <TableCell>Upload</TableCell>
                  <TableCell>
                    Status
                    <IconButton
                      aria-label='refresh'
                      onClick={() => dispatch(checkDagStatus.ignite(orgId))}
                      size='large'
                    >
                      <Refresh />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {demoFileBlock(demoFile, setDemoFile)}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );

  return (
    <>
      <PageHeader title='Upload Demo Data' breadcrumbs={[crumbs.home, crumbs.internalAdmin]} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <SectionHeader title='Steps' />
          <Box mt={2}><Divider /></Box>
          <CardContent>
            <Stepper orientation='vertical' activeStep={step} connector={<StepConnector />}>
              <Step>
                <StepLabel StepIconComponent={MotifStepIcon}>
                  Upload File
                </StepLabel>
              </Step>
            </Stepper>
          </CardContent>
          <Box mb={2}><Divider /></Box>
        </Grid>
        <Grid item xs={12} md={9}>
          {getStepComponent()}
        </Grid>
      </Grid>
    </>
  );
};

export default DemoData;
