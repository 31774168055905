import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import HeaderMenu from 'components/Layouts/Header/HeaderMenu';
import ImpersonationBar
  from 'pages/OrganizationManagement/organization/pages/OrganizationUsers/ImpersonationBar';
import LiveRampBanner from 'LiveRampBanner';
import PermissionService from 'components/Common/PermissionService';
import React from 'react';
import SupportModeBar from 'pages/SupportMode/SupportModeBar';
import { CACHE, userTypes } from 'utils/appConstants';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';


const StyledHeader = styled(Box)({
  display: 'flex',
  height: '100%',
  alignItems: 'center',
  width: '97%',
  justifyContent: 'space-between',
});

const StyledSubHeader = styled(Box)({
  display: 'flex',
  height: '100%',
  alignItems: 'center',
});

const Header = ({
  items = [],
  exitButton,
  left,
  right,
}) => {
  const user = useSelector(state => state.session.user.user);
  const impersonator = localStorage.getItem(CACHE.impersonation.impersonator);
  const supportModeEnabled = PermissionService.isCXUser() && user.userType === userTypes.superuser;

  return (
    <>
      <AppBar
        elevation={0}
        position='fixed'
        color='header'
        sx={{ borderBottom: 1, borderColor: '#E1E5EB' }}
      >
        <LiveRampBanner />
        <StyledHeader mx='auto' my='8px'>
          {exitButton ? (
            <StyledSubHeader>{ exitButton }</StyledSubHeader>
          ) : null}
          {left ? (
            <StyledSubHeader flex='1 0 auto'>{ left }</StyledSubHeader>
          ) : <Box />}
          <StyledSubHeader style={{ width: '100%' }}>
            {items.map(m => (
              <HeaderMenu
                key={m.title}
                title={m.title}
                items={m.items}
              />
            ))}
          </StyledSubHeader>
          {right ? (
            <StyledSubHeader>{ right }</StyledSubHeader>
          ) : <Box />}
        </StyledHeader>
        {impersonator ? (
          <ImpersonationBar />
        ) : null}
        {supportModeEnabled && (
          <SupportModeBar />
        )}
      </AppBar>
    </>
  );
};

export default Header;
