import React, { memo, useCallback, useContext } from 'react';
import {
  AlertRequestContext,
} from 'pages/InternalAdmin/organization/pages/AlertsRequest/AlertsRequestContext';
import { HButton } from 'BaseComponents/HButton';


export const ViewChatCell = memo(({ row }) => {
  const { handleChatViewOpen } = useContext(AlertRequestContext);

  const handleOnViewChatClick = useCallback(() => {
    handleChatViewOpen(row.chatID);
  }, [handleChatViewOpen, row.chatID]);

  return (
    <HButton variant='contained' onClick={handleOnViewChatClick}>View Chat</HButton>
  );
});

ViewChatCell.displayName = 'ViewChatCell';
