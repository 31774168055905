import { actionCellWidth } from 'BaseComponents/HDataTable/constants';
import {
  useEffect,
  useState,
} from 'react';


export const useTableresize = (tableElement) => {
  const [isTableFullSize, setIsTableFullSize] = useState(false);

  useEffect(() => {
    const tableResize = () => {
      const arr = Array.from(tableElement.current.querySelectorAll('th'));
      const tableElementWidth = arr.reduce((result, current, index) => {
        const num = index === arr.length ? 0 : Number(current.width);
        return result + num;
      }, actionCellWidth);

      const resizeableTableWidth = tableElement.current.parentElement.offsetWidth;

      setIsTableFullSize(resizeableTableWidth - tableElementWidth - (actionCellWidth / 2) <= 0);
    };
    tableResize();

    window.addEventListener('resize', tableResize);

    return () => {
      window.removeEventListener('resize', tableResize);
    };
  }, [tableElement]);

  return isTableFullSize;
};
