import * as propertiesActions from 'redux/actions/PropertiesActions';
import Logger from 'components/Common/Logger';
import http from 'utils/http';
import localStorage from 'utils/localStorage';
import { CACHE, habuSupport } from 'utils/appConstants';
import { PROPERTIES } from 'redux/actions/Types';
import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import { showAlert } from 'redux/actions/AlertActions';


function* archiveProperty(action) {
  const [moonrakerResponse, quarterdeckResponse] = yield all([
    call(http.post, action.moonrakerUrl, action.payload),
    call(http.delete, action.quarterdeckUrl),
  ]);
  if (moonrakerResponse.error || quarterdeckResponse.error) {
    if (moonrakerResponse.error) {
      Logger.error({
        comment: `Could not archive a property. ID: ${action.payload.propertyIDs[0]}`,
        error: moonrakerResponse.error,
        url: action.moonrakerUrl,
        userId: localStorage.get(CACHE.user.id),
      }, 'Archive Property Moonraker');
    }
    if (quarterdeckResponse.error) {
      Logger.error({
        comment: `Could not archive a property. ID: ${action.payload.propertyIDs[0]}`,
        error: quarterdeckResponse.error,
        url: action.quarterdeckUrl,
        userId: localStorage.get(CACHE.user.id),
      }, 'Archive Property Quarterdeck');
    }
    return yield put(propertiesActions.archivePropertyError(
      `Could not archive the property. ${habuSupport.message}`,
    ));
  }
  else {
    return yield put(propertiesActions.archivePropertySuccess('Property successfully archived'));
  }
}

function* createConfiguration(action) {
  const { configuration, error } = yield call(http.post, action.url, action.payload);
  if (error) {
    yield put(showAlert({ message: error, type: 'error' }));
    return yield put(propertiesActions.createConfigurationError(error));
  }
  yield put(showAlert({ message: 'Property configuration created', type: 'success' }));
  return yield put(propertiesActions.createConfigurationSuccess(configuration));
}

function* createTagJob(action) {
  const { job, error } = yield call(http.post, action.url, action.payload);
  if (error) {
    Logger.error(`Could not create Habu JS tag. Url: ${action.url}. Error: ${error}`);
    yield put(showAlert({ message: error, type: 'error' }));
    return yield put(propertiesActions.createTagJobError(error));
  }
  return yield put(propertiesActions.createTagJobSuccess(job));
}

function* createProperty(action) {
  const { properties, error } = yield call(http.post, action.url, action.payload);
  if (error) {
    yield put(showAlert({ message: error, type: 'error' }));
    return yield put(propertiesActions.createPropertyError(error));
  }
  const message = 'Your property has been created. Please contact your Habu Customer Success Manager '
    + 'to complete your property configuration. Your tag can not be issued until the property configurations is complete.';
  yield put(showAlert({ message, type: 'success' }));
  return yield put(propertiesActions.createPropertySuccess(properties));
}

function* fetchArchivedProperties(action) {
  const { properties, error } = yield call(http.get, action.url);
  if (error) {
    yield put(showAlert({ message: error, type: 'error' }));
    return yield put(propertiesActions.fetchArchivedListError(error));
  }
  return yield put(propertiesActions.fetchArchivedListSuccess(properties));
}

function* fetchProperties(action) {
  const { properties, error } = yield call(http.get, action.url);
  if (error) {
    yield put(showAlert({ message: error, type: 'error' }));
    return yield put(propertiesActions.fetchPropertiesError(error));
  }
  return yield put(propertiesActions.fetchPropertiesSuccess(properties));
}

function* fetchProperty(action) {
  const { property, error } = yield call(http.get, action.url);
  if (error) {
    yield put(showAlert({ message: error, type: 'error' }));
    return yield put(propertiesActions.fetchPropertyError(error));
  }
  return yield put(propertiesActions.receiveProperty(property));
}

function* fetchPropertyConfigurations(action) {
  const { configurations, error } = yield call(http.get, action.url);
  if (error) {
    yield put(showAlert({ message: error, type: 'error' }));
    return yield put(propertiesActions.fetchPropertyConfigurationsFailure(error));
  }
  return yield put(propertiesActions.fetchPropertyConfigurationsSuccess(configurations));
}

function* fetchPropertyConfigurationDetails(action) {
  const { configuration, error } = yield call(http.get, action.url);
  if (error) {
    yield put(showAlert({ message: error, type: 'error' }));
    return yield put(propertiesActions.fetchPropertyConfigurationDetailFailure(error));
  }
  return yield put(propertiesActions.fetchPropertyConfigurationDetailSuccess(configuration));
}

function* fetchForebittConfigurationDetails(action) {
  const { fieldConfigurations, error } = yield call(http.get, action.url);
  if (error) {
    yield put(showAlert({ message: error, type: 'error' }));
    Logger.error(`Could not fetch Forebitt Configuration. Url: ${action.url}. Error: ${error}`,
      'Properties: Fetch Forebitt Configuration');
    return yield put(propertiesActions.fetchForebittConfigurationDetailFailure(error));
  }
  return yield put(propertiesActions.fetchForebittConfigurationDetailSuccess(fieldConfigurations));
}

function* updateProperty(action) {
  const { properties, error } = yield call(http.post, action.url, action.payload);
  if (error) {
    yield put(showAlert({ message: error, type: 'error' }));
    return yield put(propertiesActions.updatePropertyError(error));
  }
  yield put(showAlert({ message: 'Property successfully updated', type: 'success' }));
  return yield put(propertiesActions.updatePropertySuccess(properties));
}

function* updateQuarterdeckPropertyConfiguration(action) {
  const { configuration, error } = yield call(http.put, action.url, action.payload);
  if (error) {
    yield put(showAlert({ message: error, type: 'error' }));
    return yield put(propertiesActions.updateQuarterdeckConfigurationError(error));
  }
  yield put(showAlert({ message: 'Property configuration successfully updated', type: 'success' }));
  return yield put(propertiesActions.updateQuarterdeckConfigurationSuccess(configuration));
}

function* updateForebittPropertyAttributes(action) {
  const { job, error } = yield call(http.post, action.url, action.payload);
  if (error) {
    yield put(showAlert({ message: error, type: 'error' }));
    Logger.error(`Could not update Forebitt Property Attributes. Url: ${action.url}. Error: ${error}`,
      'Properties: Update Forebitt Property Attributes');
    return yield put(propertiesActions.updateForebittConfigurationError(error));
  }
  yield put(showAlert({ message: 'Property configuration successfully updated', type: 'success' }));
  return yield put(propertiesActions.updateForebittConfigurationSuccess(job));
}

export default function* root() {
  yield all([
    takeLatest(PROPERTIES.ARCHIVE_PROPERTY, archiveProperty),
    takeLatest(PROPERTIES.CREATE_CONFIGURATION, createConfiguration),
    takeLatest(PROPERTIES.CREATE_TAG_JOB, createTagJob),
    takeLatest(PROPERTIES.CREATE_PROPERTY, createProperty),
    takeLatest(PROPERTIES.FETCH_ARCHIVED_PROPERTIES, fetchArchivedProperties),
    takeLatest(PROPERTIES.FETCH_PROPERTIES, fetchProperties),
    takeLatest(PROPERTIES.FETCH_PROPERTY, fetchProperty),
    takeLatest(PROPERTIES.FETCH_PROPERTY_CONFIGURATIONS, fetchPropertyConfigurations),
    takeLatest(PROPERTIES.FETCH_PROPERTY_CONFIGURATION_DETAIL, fetchPropertyConfigurationDetails),
    takeLatest(PROPERTIES.FETCH_FOREBITT_CONFIGURATION_DETAIL, fetchForebittConfigurationDetails),
    takeLatest(PROPERTIES.UPDATE_QUARTERDECK_CONFIGURATION, updateQuarterdeckPropertyConfiguration),
    takeLatest(PROPERTIES.UPDATE_FOREBITT_CONFIGURATION, updateForebittPropertyAttributes),
    takeLatest(PROPERTIES.UPDATE_PROPERTY, updateProperty),
  ]);
}
