import { REFRESH } from 'redux/actions/Types';
import { all, call, takeLatest } from 'redux-saga/effects';


function* refreshPage(action) {
  yield call(() => window.location.assign(action.url));
}

export default function* root() {
  yield all([
    takeLatest(REFRESH.REFRESH_PAGE, refreshPage),
  ]);
}

export {
  refreshPage,
};
