import React from 'react';
import Skeleton from '@mui/material/Skeleton';


const CardSkeleton = ({ width = 350 }) => (
  <Skeleton
    height={200}
    variant='rectangular'
    width={width}
  />
);


export default CardSkeleton;
