import Logger from 'components/Common/Logger';
import { userTypes } from 'utils/appConstants';


let userDetail;
let userPerms;
let cleanRoomPerms = [];

const evalPermsList = reqdPerms => reqdPerms.some(perm => userPerms.includes(perm));
const evalCrPermsList = reqdPerms => reqdPerms.some(perm => cleanRoomPerms.includes(perm));

const PermissionService = {
  init: (up) => {
    userPerms = up;
  },
  initCrPermissions: (crPermissions) => {
    cleanRoomPerms = crPermissions.map(perm => `CR-${perm}`);
  },
  initUser: (user) => {
    userDetail = user;
  },
  isAuthorized: (allowList = [], blockList = [], accessList = []) => {
    if (!Array.isArray(userPerms)) {
      Logger.error('Permission Service not initialized correctly', 'evalPerms');
      return false;
    }

    const isAccess = accessList.every(perm => userPerms.includes(perm));

    // Check if user is a superuser
    if (userPerms.includes(userTypes.superuser) && isAccess) {
      return true;
    }

    // Separate userAllowList and crAllowList based on the prefix 'CR-'
    const userAllowList = allowList.filter(p => p && !p.startsWith('CR-'));
    const crAllowList = allowList.filter(p => p && p.startsWith('CR-'));

    // Evaluate permissions for ORG and CR
    const isAllowed = (!userAllowList.length && !crAllowList.length)
      || evalPermsList(userAllowList);
    const isBlocked = evalPermsList(blockList);
    const orgAllowed = isAllowed && !isBlocked && isAccess;
    const crAllowed = crAllowList.length ? evalCrPermsList(crAllowList) : false;

    return orgAllowed || crAllowed;
  },
  isAuthorizedSuperUser: (allowList = [], blockList = []) => {
    if (!userPerms || !Array.isArray(userPerms)) {
      Logger.error('Permission Service not initialized correctly', 'evalPerms');
      return false;
    }
    else if (userPerms.indexOf(userTypes.superuser) >= 0) {
      const isAllowed = !allowList || allowList.length === 0 || evalPermsList(allowList);
      const isBlocked = evalPermsList(blockList);
      return (isAllowed && !isBlocked);
    }
    else {
      return false;
    }
  },
  isProductEnabled: (allowList = [], blockList = []) => {
    if (!userPerms || !Array.isArray(userPerms)) {
      Logger.error('Permission Service not initialized correctly', 'evalPerms');
      return false;
    }
    else {
      const isAllowed = !allowList || allowList.length === 0 || evalPermsList(allowList);
      const isBlocked = evalPermsList(blockList);
      return (isAllowed && !isBlocked);
    }
  },
  isCXUser: () => userDetail.isCXUser,
};

PermissionService.clear = () => {
  PermissionService.init();
};

export default PermissionService;
