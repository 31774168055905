import * as actions from 'redux/actions/DataLocationActions';
import Logger from 'components/Common/Logger';
import http from 'utils/http';
import { DATA_LOCATION } from 'redux/actions/Types';
import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import { showAlert } from 'redux/actions/AlertActions';


function* createDataLocation(action) {
  const { error, dataSourceLocation } = yield call(http.post, action.url, action.payload);
  if (error) {
    yield put(showAlert({ message: error, type: 'error' }));
    return yield put(actions.createDataLocationError(error));
  }
  return yield put(actions.createDataLocationSuccess(dataSourceLocation));
}

function* fetchDataLocations(action) {
  const { dataSourceLocations, error } = yield call(http.get, action.url);
  if (error) {
    Logger.error(`Could not fetch organization data location's. Url: ${action.url}. Error: ${error}`,
      'Data Source Locations: Fetch Data Source Locations  list');
    return yield put(actions.fetchDataLocationsError(error));
  }
  return yield put(actions.fetchDataLocationsSuccess(dataSourceLocations));
}


export default function* root() {
  yield all([
    takeLatest(DATA_LOCATION.CREATE_DATA_LOCATION, createDataLocation),
    takeLatest(DATA_LOCATION.FETCH_DATA_LOCATIONS, fetchDataLocations),
  ]);
}

export { createDataLocation, fetchDataLocations };
