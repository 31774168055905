import { DATA_IN_ATTRS } from 'redux/actions/Types';


const initialState = {
  attributes: {},
  attributeValuesByName: {},
  attributeValues: {
    hasMorePages: true,
    list: [],
    loading: false,
    nextPage: 0,
    query: undefined,
  },
};

const DataInAttrsReducer = (state = initialState, action) => {
  switch (action.type) {
    case DATA_IN_ATTRS.FETCH_DATASET_ATTRIBUTE_LIST_ERROR:
      return {
        ...state,
        attributes: {
          ...state.attributes,
          [action.payload.jobId]: action.payload,
        },
      };
    case DATA_IN_ATTRS.FETCH_DATASET_ATTRIBUTE_LIST_SUCCESS:
      return {
        ...state,
        attributes: {
          ...state.attributes,
          [action.payload.jobId]: action.payload,
        },
      };
    case DATA_IN_ATTRS.FETCH_DATASET_ATTRIBUTE_NAME_LIST:
      return {
        ...state,
        attributeValuesByName: undefined,
      };
    case DATA_IN_ATTRS.FETCH_DATASET_ATTRIBUTE_NAME_LIST_ERROR:
      return {
        ...state,
        attributeValuesByName: { error: action.payload },
      };
    case DATA_IN_ATTRS.FETCH_DATASET_ATTRIBUTE_NAME_LIST_SUCCESS:
      return {
        ...state,
        attributeValuesByName: action.payload,
      };
    case DATA_IN_ATTRS.FETCH_DATASET_ATTRIBUTE_VALUE_LIST:
      return {
        ...state,
        attributeValues: {
          ...state.attributeValues,
          loading: true,
        },
      };
    case DATA_IN_ATTRS.FETCH_DATASET_ATTRIBUTE_VALUE_LIST_ERROR:
      return {
        ...state,
        attributeValues: {
          loading: false,
          list: { error: action.payload },
        },
      };
    case DATA_IN_ATTRS.FETCH_DATASET_ATTRIBUTE_VALUE_LIST_SUCCESS:
      return {
        ...state,
        attributeValues: {
          hasMorePages: action.payload.hasMorePages,
          list: [...state.attributeValues.list, ...action.payload.list],
          loading: false,
          nextPage: state.attributeValues.nextPage + 1,
          query: action.payload.query,
        },
      };
    case DATA_IN_ATTRS.RESET_DATASET_ATTRIBUTE_LIST:
      return {
        ...state,
        attributeValues: {
          hasMorePages: true,
          list: [],
          loading: false,
          nextPage: 0,
          query: undefined,
        },
      };
    default:
      return state;
  }
};

export default DataInAttrsReducer;
