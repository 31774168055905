import http from 'utils/http';
import { DEMO_DATA } from 'redux/actions/Types';
import { SyntheticRedux } from 'synthetic-redux';
import { all } from 'redux-saga/effects';
import { combineReducers } from 'redux';
import { demoData } from 'utils/urlConstants';


const uploadDemoData = new SyntheticRedux({ type: DEMO_DATA.UPLOAD_DEMO_FILE }, 'status');

const checkDagStatus = new SyntheticRedux({ type: DEMO_DATA.CHECK_DAG_STATUS }, 'status');

uploadDemoData.ignite = (payload) => uploadDemoData.actions.ignite({
  errorMeta: 'Upload demo data',
  method: http.postFile,
  url: demoData.upload(),
  payload,
});

checkDagStatus.ignite = (orgId) => checkDagStatus.actions.ignite({
  errorMeta: 'Check status',
  method: http.get,
  url: demoData.status(orgId),
});

const demoDataReducer = combineReducers({
  upload: uploadDemoData.reducer,
  dag: checkDagStatus.reducer,
});

function* demoDataSaga() {
  yield all([
    uploadDemoData.sagaEffect,
    checkDagStatus.sagaEffect,
  ]);
}

export {
  demoDataSaga,
  demoDataReducer,
  uploadDemoData,
  checkDagStatus,
};
