import React, { createContext, useEffect, useRef } from 'react';
import { organizationManagement } from 'utils/spaUrls';
import { useDeleteOrganizationRole } from 'hooks/organizationAPI';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';


export const OrganizationRolesContext = createContext({});

export const OrganizationRolesContextProvider = ({
  list, children,
}) => {
  const [roleList, setRoleList] = React.useState(list);
  const history = useHistory();
  const orgId = useSelector(state => state.session.user.primaryOrganization.organization.ID);
  const [showModal, setShowModal] = React.useState(false);
  const [selectedRole, setSelectedRole] = React.useState(null);
  const refsObject = useRef();

  const {
    isLoading: isDeleting,
    isSuccess: isDeleted,
    mutateAsync: asyncDeleteOrgRole,
    reset: resetDeleteOrganizationRoleState,
  } = useDeleteOrganizationRole();

  useEffect(() => {
    if (isDeleted) {
      const filteredRoleList = roleList?.filter(
        role => role.ID !== selectedRole.ID,
      );
      setRoleList(filteredRoleList);
      resetDeleteOrganizationRoleState();
    }
  }, [isDeleted, resetDeleteOrganizationRoleState, roleList, selectedRole]);

  React.useEffect(() => {
    setRoleList(list);
  }, [list]);

  const onCloseModal = () => {
    setShowModal(false);
  };

  const clickedDeleteOrgRole = row => {
    setSelectedRole(row);
    setShowModal(true);
  };

  const deleteOrgRole = async () => {
    await asyncDeleteOrgRole({ organizationID: orgId, roleID: selectedRole.ID });
    refsObject.current?.refreshListData();
    setShowModal(false);
  };

  const getActions = row => {
    const actions = [
      { name: 'Edit Role', action: () => history.push(organizationManagement.organization.roles.edit(row.ID)) },
      { name: 'Delete Role', action: () => clickedDeleteOrgRole(row) },
    ];
    return actions;
  };

  const providerObject = {
    refsObject,
    selectedRole,
    showModal,
    orgId,
    isDeleting,
    onCloseModal,
    deleteOrgRole,
    getActions,
    roleList,
  };

  return (
    <OrganizationRolesContext.Provider value={providerObject}>
      {children}
    </OrganizationRolesContext.Provider>
  );
};
