import { activeOrgSelector } from 'redux/reducer/AuthenticationReducer';
import { axiosInstance } from 'utils/http';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation, useQueryClient } from 'react-query';
import { userDefinedFunctions } from 'utils/urlConstants';


export const useReconfigureCrUdf = (crId) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { ID: orgId } = useSelector(activeOrgSelector);

  return useMutation(
    async (payload) => {
      const { data } = await axiosInstance.put(userDefinedFunctions(orgId)
        .crUdfReconfigure(crId, payload.cleanRoomUDFID));
      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['crUdfList', { orgId, crId }] });
        dispatch(showAlert({
          message: 'Cleanroom user-defined function reconfigured successfully',
          type: 'success',
        }));
      },
      onError: (err) => {
        dispatch(showAlert({
          message: err.response?.data?.message ?? 'Error reconfiguring user-defined function',
          type: 'error',
        }));
      },
    },
  );
};
