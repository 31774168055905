import { axiosInstance } from 'utils/http';
import { flow } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'react-query';


export const useUpdateNode = (flowID) => {
  const dispatch = useDispatch();
  const orgId = useSelector(state => state.session.user.primaryOrganization.organization.ID);

  const {
    isLoading,
    isSuccess,
    isError,
    data,
    mutate,
  } = useMutation(
    `node-update-${orgId}-${flowID}`,
    ({ payload, nodeID }) => axiosInstance.put(flow(orgId)
      .updateNode(flowID, nodeID), payload),
    {
      onSuccess: () => {
        dispatch(showAlert({
          message: 'Node has been updated.',
          type: 'success',
        }));
      },
      onError: (error) => dispatch(showAlert({
        message: error.response.data.message,
        type: 'error',
      })),
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    data,
    mutate,
  };
};
