import { auth, iamOrganizations } from 'utils/urlConstants';
import { AUTHENTICATION as authActions } from './Types';


export const sendLogin = payload => ({
  type: authActions.SEND_LOGIN,
  payload,
  url: auth.login,
});

export const receiveLoginSuccess = payload => ({
  type: authActions.LOGIN_SUCCESS,
  payload,
});

export const receiveLoginError = payload => ({
  type: authActions.LOGIN_FAILURE,
  payload,
});

export const getUserAuthType = payload => ({
  type: authActions.GET_USER_AUTH_TYPE,
  payload,
  url: auth.userAuthType,
});

export const getUserAuthTypeSuccess = payload => ({
  type: authActions.GET_USER_AUTH_TYPE_SUCCESS,
  payload,
});

export const getUserAuthTypeFailure = payload => ({
  type: authActions.GET_USER_AUTH_TYPE_FAILURE,
  payload,
});

export const authUserCallBack = (code, state) => ({
  type: authActions.AUTH_CALL_BACK,
  url: auth.moonrakerCallBack(code, state),
});

export const authUserCallBackSuccess = payload => ({
  type: authActions.AUTH_CALL_BACK_SUCCESS,
  payload,
});

export const authUserCallBackError = payload => ({
  type: authActions.AUTH_CALL_BACK_ERROR,
  payload,
});

export const refreshToken = (payload, cb) => ({
  type: authActions.REFRESH_TOKEN,
  payload,
  callback: cb,
  url: auth.refreshToken,
});

export const switchOrganization = org => ({
  type: authActions.SWITCH_ORGANIZATION,
  payload: org,
  url: auth.switchOrganization(org.organization.ID),
});

export const switchOrganizationSuccess = payload => ({
  type: authActions.SWITCH_ORGANIZATION_SUCCESS,
  payload,
});

export const switchOrganizationFailure = payload => ({
  type: authActions.SWITCH_ORGANIZATION_FAILURE,
  payload,
});

export const switchAccount = account => ({
  type: authActions.SWITCH_ACCOUNT,
  payload: account,
  url: iamOrganizations(account.ID).listOrganizations,
});

export const resetRedirect = () => ({
  type: authActions.RESET_REDIRECT,
});

export const switchAccountSuccess = payload => ({
  type: authActions.SWITCH_ACCOUNT_SUCCESS,
  payload,
});

export const switchAccountFailure = () => ({
  type: authActions.SWITCH_ACCOUNT_FAILURE,
});

export const requestForgotPasswordLink = payload => ({
  type: authActions.REQUEST_FORGOT_PASSWORD_LINK,
  payload,
  url: auth.forgotPassword,
});

export const requestForgotPasswordLinkSuccess = payload => ({
  type: authActions.REQUEST_FORGOT_PASSWORD_LINK_SUCCESS,
  payload,
});

export const requestForgotPasswordLinkFailure = payload => ({
  type: authActions.REQUEST_FORGOT_PASSWORD_LINK_FAILURE,
  payload,
});

export const resetPassword = payload => ({
  type: authActions.CHANGE_PASSWORD,
  payload,
  url: auth.resetPassword,
});

export const resetPasswordSuccess = payload => ({
  type: authActions.CHANGE_PASSWORD_SUCCESS,
  payload,
});

export const resetPasswordFailure = payload => ({
  type: authActions.CHANGE_PASSWORD_FAILURE,
  payload,
});

export const requestLogout = () => ({
  type: authActions.REQUEST_LOGOUT,
  url: auth.logout,
});

export const authTokenExpired = () => ({
  type: authActions.AUTH_TOKEN_EXPIRED,
});

export const refreshTokenExpired = () => ({
  type: authActions.REFRESH_TOKEN_EXPIRED,
});

export const permissionDenied = payload => ({
  type: authActions.PERMISSION_DENIED,
  payload,
});
