import ConfirmModal from 'components/Common/confirmModal';
import React, { useContext } from 'react';
import StartImpersonationDialog
  from 'pages/OrganizationManagement/organization/pages/OrganizationUsers/StartImpersonationDialog';
import { OrganizationUsersContext } from 'pages/OrganizationManagement/organization/pages/OrganizationUsers/OrganizationUsersTable/OrganizationUsersContextProvider';
import { OrganizationUsersTableLayout } from 'pages/OrganizationManagement/organization/pages/OrganizationUsers/OrganizationUsersTable/OrganizationUsersTableLayout';


export const OrganizationUsersPageLayout = () => {
  const {
    impersonateUser,
    impersonate,
    impersonationBegin,
    setImpersonateUser,
    selectedUser,
    showModal,
    toggleUserDelete,
    isDeleting,
    onCloseModal,
    deactivateUser,
    deleteOrgUser,
    deletedUserResponse,
  } = useContext(OrganizationUsersContext);

  return (
    <>
      <OrganizationUsersTableLayout />
      <StartImpersonationDialog
        open={!!impersonateUser}
        title='Begin Impersonation Session'
        message='Are you sure you want to begin impersonation session?'
        onCancel={() => setImpersonateUser(null)}
        onConfirm={comment => impersonate(impersonateUser, comment)}
        loading={impersonationBegin.isLoading}
      />
      {showModal && (
        <ConfirmModal
          open={showModal}
          onClose={onCloseModal}
          loading={toggleUserDelete === 'USER' ? deletedUserResponse?.deleteUserLoading : isDeleting}
          onConfirm={toggleUserDelete === 'USER' ? deactivateUser : deleteOrgUser}
          confirmButtonText='Confirm'
          denyButtonText='Cancel'
          title={toggleUserDelete === 'USER' ? 'Delete User from system' : 'Delete Organization'}
          body={toggleUserDelete === 'USER' ? `Would you like to delete ${selectedUser.name} from System?` : `Would you like to delete ${selectedUser.name} from current organization?`}
        />
      )}
    </>
  );
};
