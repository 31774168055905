import { useHistory } from 'react-router-dom';


const HelpDocumentation = () => {
  const history = useHistory();
  const url = 'https://docs.liveramp.com/clean-room/index.html';
  window.open(url, '_blank');
  history.goBack();
  return null;
};

export default HelpDocumentation;
