import React from 'react';
import { Aggregations } from 'components/CleanRooms/constants';
import { QuestionSelectField } from 'components/InternalAdmin/QuestionConfigure/QuestionRenderer';


const CrAggrregationField = ({ onAggregateValueChange, aggregateOperator, disabled }) => {
  const [aggregation] = React.useState(aggregateOperator || Aggregations[0].value);

  return (
    <QuestionSelectField
      fieldLabel='Aggregation'
      list={Aggregations}
      onChange={onAggregateValueChange}
      required
      value={aggregation}
      disabled={disabled}
    />
  );
};

export default CrAggrregationField;
