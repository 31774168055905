import { NODES_CONNECTION_TYPE, NODES_TYPE } from 'pages/Flow/contacts';


export const getNodesTypeConnect = (firstType, secondType) => {
  if ([NODES_TYPE[firstType], NODES_TYPE[secondType]].includes(NODES_TYPE.question)) {
    return NODES_CONNECTION_TYPE.QUESTION_TO_QUESTION;
  }

  return NODES_CONNECTION_TYPE.QUESTION_TO_QUESTION;
};
