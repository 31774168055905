import React, { memo } from 'react';
import moment from 'moment';
import { CellText } from 'BaseComponents/HDataTable/CellTypes/CellText';
import { getDateFormat } from 'BaseComponents/HDataTable/utils';


export const CellDateFormat = memo(({
  type,
  children,
}) => {
  const dateFormat = getDateFormat(type);
  const textData = moment(children).format(dateFormat);

  return (
    <CellText type={type}>
      {textData}
    </CellText>
  );
});

CellDateFormat.displayName = 'CellDateFormat';
