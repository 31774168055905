import { FVA } from 'redux/actions/Types';
import { dataCredential, fullViewAutomation } from 'utils/urlConstants';


export const changeFvaStatus = (orgId, id, status, meta) => ({
  type: FVA.CHANGE_FVA_STATUS,
  payload: { status },
  url: fullViewAutomation(orgId).changeFvaStatus(id),
  meta,
});

export const changeFvaStatusError = payload => ({
  type: FVA.CHANGE_FVA_STATUS_ERROR,
  payload,
});

export const changeFvaStatusSuccess = payload => ({
  type: FVA.CHANGE_FVA_STATUS_SUCCESS,
  payload,
});

export const changeFvaQuestionStatus = (organizationID, fvaID, questionID, status, meta) => ({
  type: FVA.CHANGE_FVA_QUESTION_STATUS,
  payload: {
    organizationID,
    fvaID,
    questionID,
    status,
  },
  url: fullViewAutomation(organizationID).changeFvaQuestionStatus(fvaID, questionID),
  meta,
});

export const changeFvaQuestionStatusSuccess = payload => ({
  type: FVA.CHANGE_FVA_QUESTION_STATUS_SUCCESS,
  payload,
});

export const changeFvaQuestionStatusError = payload => ({
  type: FVA.CHANGE_FVA_QUESTION_STATUS_ERROR,
  payload,
});

export const createFva = (orgId, payload) => ({
  type: FVA.CREATE_FVA,
  url: fullViewAutomation(orgId).create,
  payload,
});

export const createFvaSuccess = payload => ({
  type: FVA.CREATE_FVA_SUCCESS,
  payload,
});

export const createFvaError = payload => ({
  type: FVA.CREATE_FVA_ERROR,
  payload,
});

export const fetchFvaFilters = orgId => ({
  type: FVA.FETCH_FVA_FILTERS,
  url: fullViewAutomation(orgId).fvaFilters,
});

export const fetchFvaFiltersSuccess = payload => ({
  type: FVA.FETCH_FVA_FILTERS_SUCCESS,
  payload,
});

export const fetchFvaFiltersError = payload => ({
  type: FVA.FETCH_FVA_FILTERS_ERROR,
  payload,
});

export const fetchFvaList = (orgId, filter) => ({
  type: FVA.FETCH_FVA_LIST,
  url: fullViewAutomation(orgId).listFva,
  payload: filter,
});

export const fetchFvaListSuccess = payload => ({
  type: FVA.FETCH_FVA_LIST_SUCCESS,
  payload,
});

export const fetchFvaListError = payload => ({
  type: FVA.FETCH_FVA_LIST_ERROR,
  payload,
});

export const fetchFvaPartnersList = orgId => ({
  type: FVA.FETCH_FVA_PARTNERS,
  url: fullViewAutomation(orgId).partnerList,
});

export const fetchFvaPartnersListSuccess = payload => ({
  type: FVA.FETCH_FVA_PARTNERS_SUCCESS,
  payload,
});

export const fetchFvaPartnersListError = payload => ({
  type: FVA.FETCH_FVA_PARTNERS_ERROR,
  payload,
});

export const fetchFvaPartnerCredentialSourcesList = (orgId, filter) => ({
  type: FVA.FETCH_FVA_PARTNER_CREDENTIAL_SOURCES,
  url: dataCredential(orgId).listOrgCredentials(filter),
});

export const fetchFvaPartnerCredentialSourcesListSuccess = payload => ({
  type: FVA.FETCH_FVA_PARTNER_CREDENTIAL_SOURCES_SUCCESS,
  payload,
});

export const fetchFvaPartnerCredentialSourcesListError = payload => ({
  type: FVA.FETCH_FVA_PARTNER_CREDENTIAL_SOURCES_ERROR,
  payload,
});

export const fetchFvaQuestions = (orgId, fvaId, filter) => ({
  type: FVA.FETCH_FVA_QUESTIONS,
  url: fullViewAutomation(orgId).listFvaQuestions(fvaId),
  payload: filter,
});

export const fetchFvaQuestionsError = payload => ({
  type: FVA.FETCH_FVA_QUESTION_DETAILS_ERROR,
  payload,
});

export const fetchFvaQuestionsSuccess = payload => ({
  type: FVA.FETCH_FVA_QUESTIONS_SUCCESS,
  payload,
});

export const fetchFvaQuestionDetails = questionId => ({
  type: FVA.FETCH_FVA_QUESTION_DETAILS,
  url: fullViewAutomation().questionDetails(questionId),
});

export const fetchFvaQuestionDetailsError = payload => ({
  type: FVA.FETCH_FVA_QUESTION_DETAILS_ERROR,
  payload,
});

export const fetchFvaQuestionDetailsSuccess = payload => ({
  type: FVA.FETCH_FVA_QUESTION_DETAILS_SUCCESS,
  payload,
});

export const fetchFvaQuestionsFilters = (orgId, fvaId) => ({
  type: FVA.FETCH_FVA_QUESTIONS_FILTERS,
  url: fullViewAutomation(orgId).fvaQuestionsFilters(fvaId),
});

export const fetchFvaQuestionsFiltersError = payload => ({
  type: FVA.FETCH_FVA_QUESTIONS_FILTERS_ERROR,
  payload,
});

export const fetchFvaQuestionsFiltersSuccess = payload => ({
  type: FVA.FETCH_FVA_QUESTIONS_FILTERS_SUCCESS,
  payload,
});

export const fetchFvaReport = (orgId, fvaId, questionId, fvaQuestionRunID, filters = []) => ({
  type: FVA.FETCH_FVA_REPORT,
  url: fullViewAutomation(orgId).report(fvaId, questionId),
  payload: { fvaQuestionRunID, filters },
});

export const fetchFvaReportError = payload => ({
  type: FVA.FETCH_FVA_REPORT_ERROR,
  payload,
});

export const fetchFvaReportSuccess = payload => ({
  type: FVA.FETCH_FVA_REPORT_SUCCESS,
  payload,
});

export const fetchFvaReportFilters = (orgId, fvaId, questionId) => ({
  type: FVA.FETCH_FVA_REPORT_FILTERS,
  url: fullViewAutomation(orgId).reportFilters(fvaId, questionId),
});

export const fetchFvaReportFiltersError = payload => ({
  type: FVA.FETCH_FVA_REPORT_FILTERS_ERROR,
  payload,
});

export const fetchFvaReportFiltersSuccess = payload => ({
  type: FVA.FETCH_FVA_REPORT_FILTERS_SUCCESS,
  payload,
});

export const fetchFvaReportFreq = (orgId, fvaId, questionId) => ({
  type: FVA.FETCH_FVA_REPORT_FREQ,
  url: fullViewAutomation(orgId).reportFrequency(fvaId, questionId),
});

export const fetchFvaReportFreqError = payload => ({
  type: FVA.FETCH_FVA_REPORT_FREQ_ERROR,
  payload,
});

export const fetchFvaReportFreqSuccess = payload => ({
  type: FVA.FETCH_FVA_REPORT_FREQ_SUCCESS,
  payload,
});

export const fetchFvaSummary = orgId => ({
  type: FVA.FETCH_FVA_SUMMARY,
  url: fullViewAutomation(orgId).summary,
});

export const fetchFvaSummaryError = payload => ({
  type: FVA.FETCH_FVA_SUMMARY_ERROR,
  payload,
});

export const fetchFvaSummarySuccess = payload => ({
  type: FVA.FETCH_FVA_SUMMARY_SUCCESS,
  payload,
});

export const resetFvaPage = () => ({
  type: FVA.FVA_REPORT_RESET,
});
