import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import FileCopy from '@mui/icons-material/FileCopy';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { CopyToClipboard } from 'react-copy-to-clipboard/lib/Component';
import { HButton } from 'BaseComponents';
import { cardsPerPageOptions } from 'utils/appConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch } from 'react-redux';


const ExpandableRow = ({ row, dispatch }) => {
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            size='small'
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{row.dataSource.displayName}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell>Location</TableCell>
                    <TableCell width='10%' />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.entries(row.location).map((x, index) => (
                    <TableRow key={x[0]}>
                      <TableCell>{x[0]}</TableCell>
                      <TableCell>{x[1]}</TableCell>
                      <TableCell>
                        <CopyToClipboard text={x[1]}>
                          <HButton
                            data-testid={`copy-location-${index}`}
                            onClick={() => dispatch(showAlert({ message: 'Data Location Copied to Clipboard', type: 'success' }))}
                          >
                            <FileCopy />
                          </HButton>
                        </CopyToClipboard>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};


const SyntheticDataLocationTable = ({ list }) => {
  const dataMunging = list.map(x => ({ ...x, location: JSON.parse(x.location) }));
  const dispatch = useDispatch();
  const [rowsPerPage, setRowsPerPage] = React.useState(cardsPerPageOptions[0]);
  const [page, setPage] = React.useState(0);
  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width='5%' />
              <TableCell>Data Sources</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataMunging
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(row => (
                <ExpandableRow key={row.ID} row={row} dispatch={dispatch} />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={cardsPerPageOptions}
        component='div'
        count={dataMunging.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{ 'aria-label': 'Previous Page' }}
        nextIconButtonProps={{ 'aria-label': 'Next Page' }}
        onPageChange={(e, newPage) => setPage(newPage)}
        onRowsPerPageChange={e => setRowsPerPage(e.target.value)}
      />
    </>
  );
};

export default SyntheticDataLocationTable;
