import { EXPERIMENTS } from 'redux/actions/Types';


const initialState = {};
const experimentReducer = (state = initialState, action) => {
  switch (action.type) {
    case EXPERIMENTS.FETCH_AUDIENCES:
      return { ...state, userLists: undefined };
    case EXPERIMENTS.FETCH_AUDIENCES_ERROR:
      return { ...state, userLists: { error: action.payload } };
    case EXPERIMENTS.FETCH_AUDIENCES_SUCCESS:
      return { ...state, userLists: action.payload };
    case EXPERIMENTS.FETCH_EXP:
      return { ...state, detail: undefined, isSaved: false };
    case EXPERIMENTS.FETCH_EXP_ERROR:
      return { ...state, detail: { error: action.payload } };
    case EXPERIMENTS.FETCH_EXP_SUCCESS:
      return { ...state, detail: action.payload };
    case EXPERIMENTS.FETCH_CR_SUCCESS:
      return { ...state, detail: action.payload };
    case EXPERIMENTS.FETCH_EXP_LIST_ERROR:
      return { ...state, list: { error: action.payload } };
    case EXPERIMENTS.FETCH_EXP_LIST_SUCCESS:
      return { ...state, list: action.payload, detail: undefined };
    case EXPERIMENTS.FETCH_EXP_PARTNER_CONNECTIONS:
      return { ...state, partnerConnections: undefined };
    case EXPERIMENTS.FETCH_EXP_PARTNER_CONNECTIONS_ERROR:
      return { ...state, partnerConnections: { error: action.payload } };
    case EXPERIMENTS.FETCH_EXP_PARTNER_CONNECTIONS_SUCCESS:
      return { ...state, partnerConnections: action.payload };
    case EXPERIMENTS.FETCH_EXP_SUMMARIES_ERROR:
      return { ...state, summaries: { error: action.payload } };
    case EXPERIMENTS.FETCH_EXP_SUMMARIES_SUCCESS:
      return { ...state, summaries: action.payload };
    case EXPERIMENTS.SAVE_EXP:
      return { ...state, isSaved: false, loading: true };
    case EXPERIMENTS.SAVE_EXP_ERROR:
      return {
        ...state,
        isSaved: false,
        loading: false,
        error: action.payload,
      };
    case EXPERIMENTS.SAVE_EXP_SUCCESS:
      return { ...state, isSaved: true, loading: false };
    case EXPERIMENTS.UPDATE_EXP_STATUS:
      return { ...state, expStatus: undefined };
    case EXPERIMENTS.UPDATE_EXP_STATUS_ERROR:
      return { ...state, expStatus: { error: action.payload } };
    case EXPERIMENTS.UPDATE_EXP_STATUS_SUCCESS:
      return { ...state, expStatus: action.payload };

    case EXPERIMENTS.FETCH_EXP_FILTERS_ERROR:
      return { ...state, experimentFilters: { error: action.payload } };
    case EXPERIMENTS.FETCH_EXP_FILTERS_SUCCESS:
      return { ...state, experimentFilters: action.payload };

    case EXPERIMENTS.END_EXP:
      return { ...state, meta: { expEnding: true } };
    case EXPERIMENTS.END_EXP_ERROR:
      return { ...state, meta: { expEnding: false, expEndingError: action.payload } };
    case EXPERIMENTS.END_EXP_SUCCESS:
      return { ...state, meta: { expEnded: true } };
    default:
      return state;
  }
};

export default experimentReducer;
