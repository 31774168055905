import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import React from 'react';
import Typography from '@mui/material/Typography';
import { HButton } from 'BaseComponents';
import { ourColors } from 'Theme';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';


const StyledDivider = styled(Divider)(({ theme }) => ({
  height: '2px',
  backgroundColor: theme.palette.primary.main,
}));

const StyledTypography = styled(Typography)({
  marginBottom: '0px',
  fontWeight: '400',
});
const StyledAccessTimeIcon = styled(AccessTimeIcon)({
  color: ourColors.primaryColor,
  display: 'inline-flex',
  verticalAlign: 'middle',
  paddingBottom: '0.5rem',
  fontSize: 35,
});

const message = 'We will try refreshing your session or will ask you to re-authenticate.';

const SessionTimeoutDialog = () => {
  const [open, setOpen] = React.useState(false);
  const authExpired = useSelector(state => state.session.authTokenExpired) || false;
  const refreshExpired = useSelector(state => state.session.refreshTokenExpired) || false;

  React.useEffect(() => {
    setOpen((authExpired || refreshExpired) && !(window.location.href.includes('/login')));
  }, [setOpen, authExpired, refreshExpired]);

  return (
    <Dialog open={open}>
      <DialogTitle>
        <StyledAccessTimeIcon />
        &nbsp;
        <StyledTypography component='span' variant='h2'>Session Expired</StyledTypography>
        <StyledDivider />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography paragraph variant='subtitle1'>
            {message}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <HButton
          onClick={() => { window.location.reload(); }}
          color='primary'
          size='small'
          variant='contained'
        >
          Click here to continue
        </HButton>
      </DialogActions>
    </Dialog>
  );
};

export default SessionTimeoutDialog;
