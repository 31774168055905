import * as actionGenerators from 'redux/actions/ExperimentActions';
import Logger from 'components/Common/Logger';
import http from 'utils/http';
import { EXPERIMENTS } from 'redux/actions/Types';
import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import { habuSupport } from 'utils/appConstants';
import { showAlert } from 'redux/actions/AlertActions';


function* endExperiment(action) {
  const { success, error } = yield call(http.post, action.url);
  if (error) {
    Logger.error(`Could not end Experiment. Url: ${action.url}. Error: ${error}`,
      'Experiments: End Experiment.');

    return yield put(actionGenerators.endExperimentError(error));
  }
  return yield put(actionGenerators.endExperimentSuccess(success));
}
function* fetchAudiences(action) {
  const { userLists, error } = yield call(http.get, action.url);
  if (error) {
    Logger.error(`Could not fetch user list. Url: ${action.url}. Error: ${error}`,
      'EXPERIMENTS: Fetch user list');
    return yield put(actionGenerators.fetchAudiencesError(error));
  }
  return yield put(actionGenerators.fetchAudiencesSuccess(userLists));
}

function* fetchExperiment(action) {
  const { experiment, error } = yield call(http.get, action.url);
  if (error) {
    Logger.error(`Could not fetch experiment. Url: ${action.url}. Error: ${error}`,
      'EXPERIMENTS: Fetch experiment');
    return yield put(actionGenerators.fetchExpError(error));
  }
  return yield put(actionGenerators.fetchExpSuccess(experiment));
}

function* fetchExperimentsList(action) {
  const { experiments, error } = yield call(http.post, action.url, action.payload);
  if (error) {
    Logger.error(`Could not fetch experiment list. Url: ${action.url}. Error: ${error}`,
      'EXPERIMENTS: Fetch experiments list');
    return yield put(actionGenerators.fetchExpListError(error));
  }
  return yield put(actionGenerators.fetchExpListSuccess(experiments));
}

function* fetchPartnerConnections(action) {
  const { connections, error } = yield call(http.get, action.url);
  if (error) {
    Logger.error(`Could not fetch partner connections. Url: ${action.url}. Error: ${error}`,
      'EXPERIMENTS: Fetch partner connections');
    return yield put(actionGenerators.fetchExpPartnerConnectionsError(error));
  }
  return yield put(actionGenerators.fetchExpPartnerConnectionsSuccess(connections));
}

function* fetchExpSummaries(action) {
  const { summaries, error } = yield call(http.get, action.url);
  if (error) {
    Logger.error(`Could not fetch Experiments summaries. Url: ${action.url}. Error: ${error}`,
      'EXPERIMENTS: Fetch experiments summaries');
    return yield put(actionGenerators.fetchExperimentsSummaryError(error));
  }
  return yield put(actionGenerators.fetchExperimentsSummarySuccess(summaries));
}

function* saveExperiment(action) {
  const method = action.id ? http.put : http.post;
  const { experiment, error } = yield call(method, action.url, action.payload);
  if (error) {
    yield put(showAlert({ message: error, type: 'error' }));
    Logger.error(`Could not save experiment. Url: ${action.url}. Error: ${error}`,
      'EXPERIMENTS: Save experiment');
    return yield put(actionGenerators.saveExpError(error));
  }
  yield put(showAlert({ message: 'Experiment Successfully Saved', type: 'success' }));
  return yield put(actionGenerators.saveExpSuccess(experiment));
}

function* fetchExperimentFilters(action) {
  const { sorts, filters, error } = yield call(http.get, action.url);
  if (error) {
    yield put(showAlert({
      type: 'error',
      message: `Error fetching Experiment List Filters. ${habuSupport.message}`,
    }));
    Logger.error(`Could not fetch Experiment Filters. Url: ${action.url}. Error: ${error}`,
      'Experiments: Fetch Experiment List Filters');
    return yield put(actionGenerators.fetchExperimentFiltersError(error));
  }
  return yield put(actionGenerators.fetchExperimentFiltersSuccess({ filters, sorts }));
}

function* updateExperimentStatus(action) {
  const { success, error } = yield call(http.post, action.url, action.payload);
  if (error) {
    yield put(showAlert({
      type: 'error',
      message: `Error updating Experiment status. ${habuSupport.message}`,
    }));
    Logger.error(`Could not update Experiment status. Url: ${action.url}. Error: ${error}`,
      'Experiments: Update Experiment status.');

    return yield put(actionGenerators.updateExpStatusError(error));
  }
  return yield put(actionGenerators.updateExpStatusSuccess(success));
}

export default function* root() {
  yield all([
    takeLatest(EXPERIMENTS.SAVE_EXP, saveExperiment),
    takeLatest(EXPERIMENTS.END_EXP, endExperiment),
    takeLatest(EXPERIMENTS.FETCH_AUDIENCES, fetchAudiences),
    takeLatest(EXPERIMENTS.FETCH_EXP, fetchExperiment),
    takeLatest(EXPERIMENTS.FETCH_EXP_LIST, fetchExperimentsList),
    takeLatest(EXPERIMENTS.FETCH_EXP_FILTERS, fetchExperimentFilters),
    takeLatest(EXPERIMENTS.FETCH_EXP_SUMMARIES, fetchExpSummaries),
    takeLatest(EXPERIMENTS.FETCH_EXP_PARTNER_CONNECTIONS, fetchPartnerConnections),
    takeLatest(EXPERIMENTS.UPDATE_EXP_STATUS, updateExperimentStatus),
  ]);
}

export {
  fetchExperimentsList,
};
