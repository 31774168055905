import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import ConfirmationDialog from 'components/Common/ConfirmationDialog';
import EyeIcon from 'assets/icons/eye-icon.svg';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import React from 'react';
import Stack from '@mui/material/Stack';
import StartImpersonationDialog from 'pages/OrganizationManagement/organization/pages/OrganizationUsers/StartImpersonationDialog';
import Typography from '@mui/material/Typography';
import { CACHE } from 'utils/appConstants';
import { TextField } from '@mui/material';
import { fetchAccountAdminList, fetchUserList } from 'redux/actions/IamActions';
import { habuColors } from 'Theme';
import { styled } from '@mui/material/styles';
import { useBeginImpersonation, useEndImpersonation } from 'hooks/Impersonation/ImpersonationAPI';
import { useDispatch, useSelector } from 'react-redux';


const OrangeBox = styled(Box)`
  background-color: ${habuColors.primary['100']};
  height: 33px;
  margin-left: -1rem;
  padding-left: 50px;
  display: flex;
  align-items: center;
  color: ${habuColors.neutral[90]};
`;

const LeftNavigationSpace = styled(Box)`
  width: 280px;
`;

const UserDropdown = styled(TextField)`
  background-color: ${habuColors.neutral.white};
  border-radius: 4px;
  > div {
    height: 25px;
  }
`;

const usersFilter = { filters: [], sortBy: {} };

const ImpersonationBar = () => {
  const dispatch = useDispatch();
  const impersonatorUser = JSON.parse(localStorage.getItem(CACHE.impersonation.impersonator));
  const currentUser = JSON.parse(localStorage.getItem(CACHE.user.userDetails));
  const impersonationEnd = useEndImpersonation();
  const impersonationBegin = useBeginImpersonation();
  const users = useSelector(state => state.iam.userList);
  const accountAdminsUsers = useSelector(state => state.iam.accountAdmins.map(isUser => ({
    roleName: '',
    user: isUser,
  })));
  const orgId = useSelector(state => state.session.user.primaryOrganization.organization.ID);
  const accountId = useSelector(state => state.session.user.primaryAccount.ID);
  const [impersonateUser, setImpersonateUser] = React.useState();
  const [endImpersonation, setEndImpersonation] = React.useState(false);

  React.useEffect(() => {
    dispatch(fetchUserList(orgId, usersFilter));
    dispatch(fetchAccountAdminList(accountId));
  }, [orgId, accountId, dispatch]);

  if (!impersonatorUser) {
    return null;
  }

  const onUserChange = (userID, comments) => {
    const impersonatorsToken = localStorage.getItem(CACHE.impersonation.refreshToken);
    localStorage.setItem(CACHE.user.jwtAccessToken, impersonatorsToken);
    impersonationBegin.mutate({ ImpersonatorID: impersonatorUser.ID, UserID: userID, comments });
    setImpersonateUser(null);
  };

  const onClose = () => {
    impersonationEnd.mutate();
  };

  return (
    <>
      <OrangeBox data-testid='impersonation-bar'>
        <LeftNavigationSpace />
        <Stack direction='row' spacing={1}>
          <img
            src={EyeIcon}
            alt='eye'
          />
          <Typography>Viewing as</Typography>
          <Box width='200px'>
            <UserDropdown
              onChange={(e) => setImpersonateUser(e.target.value)}
              select
              variant='outlined'
              value={currentUser?.ID}
              size='small'
              fullWidth
            >
              {[...accountAdminsUsers, ...users].map(user => (
                <MenuItem value={user.user.ID} key={user.user.ID}>{user.user.name}</MenuItem>
              ))}
            </UserDropdown>
          </Box>
        </Stack>
        <Box flex={1} display='flex' justifyContent='flex-end' pr={4}>
          <IconButton
            aria-label='stop-impersonation'
            onClick={() => setEndImpersonation(true)}
            data-testid='end-impersonation-button'
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </OrangeBox>
      <StartImpersonationDialog
        open={!!impersonateUser}
        title='Begin Impersonation Session'
        message='Are you sure you want to change impersonated user?'
        onCancel={() => setImpersonateUser(null)}
        onConfirm={comment => onUserChange(impersonateUser, comment)}
        loading={impersonationBegin.isLoading}
      />
      <ConfirmationDialog
        open={endImpersonation}
        title='Notice'
        message='Are you sure you want to end impersonation session?'
        onCancel={() => setEndImpersonation(false)}
        onConfirm={() => onClose()}
        loading={impersonationEnd.isLoading}
      />
    </>
  );
};

export default ImpersonationBar;
