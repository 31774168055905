import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, { forwardRef } from 'react';
import { HIconButton } from 'BaseComponents/HIconButton';
import { pxToRem } from 'MotifTheme';
import { styled } from '@mui/material/styles';


const StyledIconButton = styled(HIconButton)(({ theme }) => ({
  ...theme.components.MuiButton.styleOverrides.contained,
  borderRadius: `0 ${pxToRem(4)} ${pxToRem(4)} 0`,
}));

const StyledButtonGroup = styled(
  forwardRef((props, ref) => (<ButtonGroup {...props} ref={ref} />)),
)(() => ({
  boxShadow: 'none',
  '& .MuiButtonGroup-firstButton': {
    borderWidth: 1,
  },
  '& .MuiButtonGroup-firstButton:hover': {
    borderWidth: 1,
  },
  '& .MuiButtonBase-root.Mui-disabled': {
    backgroundColor: 'transparent',
  },
}));


export const HSplitButton = ({
  options,
  handleClick = () => {},
  onItemChange = () => {},
  ...props
}) => {
  const [open, setOpen] = React.useState(false);
  const actionRef = React.useRef(null);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleOnClick = () => {
    handleClick(options[selectedIndex]);
  };

  const handleOnItemChange = (newIndex) => {
    onItemChange(options[newIndex]);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
    handleOnItemChange(index);
  };

  return (
    <>
      <StyledButtonGroup
        ref={anchorRef}
        variant='contained'
        aria-label='split button'
        size='small'
      >
        <Button onClick={handleOnClick} {...props}>{options[selectedIndex]}</Button>
        <StyledIconButton
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label='select merge strategy'
          aria-haspopup='menu'
          onMouseDown={() => {
            actionRef.current = () => setOpen(!open);
          }}
          onKeyDown={() => {
            actionRef.current = () => setOpen(!open);
          }}
          onClick={() => {
            actionRef.current?.();
          }}
          size='small'
          {...props}
        >
          {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </StyledIconButton>
      </StyledButtonGroup>
      <Menu open={open} onClose={() => setOpen(false)} anchorEl={anchorRef.current}>
        {options.map((option, index) => (
          <MenuItem
            key={option}
            selected={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, index)}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
