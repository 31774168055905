import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import GearPopover from 'components/Common/GearPopover';
import Grid from '@mui/material/Grid';
import HSearchComponent from 'BaseComponents/HSearchComponent';
import React from 'react';


const AccountDetails = (
  {
    search = '',
    setSearch,
    accountName = '',
    accounts = [],
    switchAccount,
  },
) => {
  const accountList = accounts.map(o => ({
    name: o.name,
    action: () => switchAccount(o),
  }));
  return (
    <Card>
      <CardContent>
        <Grid container justifyContent='flex-start' alignItems='center' spacing={2}>
          <Grid item xs={3}>
            <HSearchComponent
              label='Search Flag'
              placeholder='Search Flag'
              value={search}
              onSearch={(value) => setSearch(value)}
            />
          </Grid>
          <Grid item xs={5}>
            {'Active Account: '}
            <GearPopover
              label={accountName}
              hideIcon
              actions={accountList}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AccountDetails;
