import { activeOrgSelector } from 'redux/reducer/AuthenticationReducer';
import { axiosInstance } from 'utils/http';
import { flow } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';


export const useFetchFlowDetails = ({ crFlowId, crId }) => {
  const dispatch = useDispatch();
  const { ID: orgId } = useSelector(activeOrgSelector);

  const {
    isLoading, isError, isSuccess, data,
  } = useQuery(
    ['Fetch-Flow-Details', orgId],
    async () => axiosInstance.get(flow(orgId).details(crFlowId, crId)),
    {
      onError: (error) => dispatch(showAlert({ message: error.response.data.message, type: 'error' })),
      refetchOnWindowFocus: false,
    },
  );

  return {
    isLoading, isError, isSuccess, data,
  };
};
