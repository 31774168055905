import { DATA_IN_ATTRS } from 'redux/actions/Types';
import { dataIn } from 'utils/urlConstants';


export const fetchDatasetAttributeList = (orgId, dataImportJobID) => ({
  jobId: dataImportJobID,
  type: DATA_IN_ATTRS.FETCH_DATASET_ATTRIBUTE_LIST,
  url: dataIn(orgId).attributeList({ dataImportJobID }),
});

export const fetchDatasetAttributeListError = payload => ({
  type: DATA_IN_ATTRS.FETCH_DATASET_ATTRIBUTE_LIST_ERROR,
  payload,
});

export const fetchDatasetAttributeListSuccess = payload => ({
  type: DATA_IN_ATTRS.FETCH_DATASET_ATTRIBUTE_LIST_SUCCESS,
  payload,
});

export const fetchDatasetAttributeValuesByName = (orgId, name, dataInJobId) => ({
  type: DATA_IN_ATTRS.FETCH_DATASET_ATTRIBUTE_NAME_LIST,
  url: dataIn(orgId).attributeValuesByName(name, dataInJobId),
});

export const fetchDatasetAttributeValuesByNameError = payload => ({
  type: DATA_IN_ATTRS.FETCH_DATASET_ATTRIBUTE_NAME_LIST_ERROR,
  payload,
});

export const fetchDatasetAttributeValuesByNameSuccess = payload => ({
  type: DATA_IN_ATTRS.FETCH_DATASET_ATTRIBUTE_NAME_LIST_SUCCESS,
  payload,
});

export const fetchDatasetAttributeValueList = (orgId, attrId, page = 0, query = undefined) => ({
  attrId,
  page,
  query,
  type: DATA_IN_ATTRS.FETCH_DATASET_ATTRIBUTE_VALUE_LIST,
  url: dataIn(orgId).attributeValueList(attrId),
});

export const fetchDatasetAttributeValueListError = payload => ({
  type: DATA_IN_ATTRS.FETCH_DATASET_ATTRIBUTE_VALUE_LIST_ERROR,
  payload,
});

export const fetchDatasetAttributeValueListSuccess = payload => ({
  type: DATA_IN_ATTRS.FETCH_DATASET_ATTRIBUTE_VALUE_LIST_SUCCESS,
  payload,
});

export const resetDatasetAttributeValueList = () => ({
  type: DATA_IN_ATTRS.RESET_DATASET_ATTRIBUTE_LIST,
});
