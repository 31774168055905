import moment from 'moment';
import { SORT_DIRECTION, sortDirections } from 'utils/appConstants';


export const addOrderByField = (fields = [], fieldName) => {
  const newOrderByFields = [];
  newOrderByFields.push(...fields
    .filter(field => field.key === fieldName)
    .map(field => (field.order === sortDirections.asc
      ? null
      : { key: field.key, order: sortDirections.asc })));
  if (fields.filter(field => field.key === fieldName).length === 0) {
    newOrderByFields.push({ key: fieldName, order: 'desc' });
  }
  return newOrderByFields.filter(field => field !== null);
};

export const compareByField = field => (a, b) => {
  if (!field) {
    return 0;
  }
  if (a[field] > b[field]) {
    return 1;
  }
  if (a[field] < b[field]) {
    return -1;
  }
  return 0;
};

export const compareDatesByField = field => (a, b) => {
  if (!field) {
    return 0;
  }
  if (moment(a[field]) > moment(b[field])) {
    return 1;
  }
  if (moment(a[field]) < moment(b[field])) {
    return -1;
  }
  return 0;
};

export const getOrder = (fields = [], field) => (
  fields.filter(f => f.key === field)[0] || {}
).order;

export const changeDirection = (direction) => (
  direction === SORT_DIRECTION.DESCENDING ? SORT_DIRECTION.ASCENDING : SORT_DIRECTION.DESCENDING);

export const getSortByObj = (attribute, direction) => ({
  attribute: attribute || '',
  direction: direction || SORT_DIRECTION.DESCENDING,
});

export const setSortByObj = (sortBy, attribute) => {
  if (sortBy.attribute === attribute) {
    return {
      ...sortBy,
      direction: changeDirection(sortBy.direction),
    };
  }
  return {
    ...sortBy,
    attribute,
    direction: SORT_DIRECTION.DESCENDING,
  };
};
