import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import React from 'react';
import {
  Dialog, DialogContent, DialogTitle, Divider, TextField, Typography,
} from '@mui/material';
import { HButton } from 'BaseComponents';
import { evaluateValueFormattingExpression } from 'utils/chartUtils';


const supportedFunctions = {
  abbrNum: 'Show number in shortest form with (K,M) metrics. For e.g. 1K, 240K, 2.4M',
  formatDecimal: 'Format a number to 2 decimal points. For e.g. 51.02',
  formatNumber: 'Format given number with comma. For e.g. 71,209,038',
  '^': 'Separates multiple function or text. For e.g $51',
};

const exampleFunctions = ['abbrNum', '$^abbrNum', 'abbrNum(true)', 'formatDecimal(0)', '$^formatDecimal', '$^formatDecimal(3)', 'formatNumber', '$^formatNumber', '$^formatNumber^formatDecimal(2)'];
const exampleValue = 123456789.2345;

const RenderSupportedFunction = ({ name, description }) => (
  <Grid container spacing={0} justifycontent='center' alignItems='center'>
    <Grid item>
      <Typography variant='h6'>
        {`${name} : `}
      </Typography>
    </Grid>
    <Grid item>
      <Box pl={1}>
        <Typography variant='caption'>{description}</Typography>
      </Box>
    </Grid>
  </Grid>
);


const ValueFormattingHelperDialog = ({ valueFormattingHelper, onValueFormattingHelpHide }) => {
  const [value, setValue] = React.useState('51');
  const [expression, setExpression] = React.useState('');

  const onValueChange = (e) => {
    setValue(e.currentTarget.value);
  };

  const onExpressionChange = (e) => {
    setExpression(e.currentTarget.value);
  };

  const result = evaluateValueFormattingExpression(expression, Number(value));

  return (
    <Dialog
      open={valueFormattingHelper}
      onClose={onValueFormattingHelpHide}
      aria-labelledby='value-formatter-helper'
      aria-describedby='value-formatter-helper'
    >
      <DialogTitle>Value Formatter Expression Validator</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <TextField size='small' label='Enter Value' onChange={onValueChange} variant='outlined' value={value} />
          </Grid>
          <Grid item xs={4}>
            <TextField size='small' label='Value Formatting' onChange={onExpressionChange} variant='outlined' value={expression} />
          </Grid>
          <Grid item>
            <Box display='flex' justifycontent='center' alignItems='center' height='100%'>
              =
            </Box>
          </Grid>
          <Grid item xs={4}>
            <TextField size='small' label='Result' disabled variant='outlined' value={result} />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box pt={1} pb={1}>
              <Typography variant='h6'>Legends:</Typography>
            </Box>
          </Grid>
        </Grid>
        {Object.keys(supportedFunctions).map(key => (
          <RenderSupportedFunction key={key} name={key} description={supportedFunctions[key]} />
        ))}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box pt={1}>
              <Typography variant='h6'>
                {`Examples (with value ${exampleValue} )`}
                :
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          {exampleFunctions.map(key => (
            <React.Fragment key={key}>
              <Grid item xs={6} onClick={() => setExpression(key)}>
                {key}
              </Grid>
              <Grid item xs={6}>
                {evaluateValueFormattingExpression(key, exampleValue)}
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </DialogContent>
      <Divider />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box pt={1} display='flex' justifycontent='flex-end'>
              <HButton color='primary' variant='contained' onClick={onValueFormattingHelpHide}>Close</HButton>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ValueFormattingHelperDialog;
