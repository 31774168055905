import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import { FlowNode, FlowNodeBody } from 'pages/Flow/Nodes/FlowNode';


export const NodeSkeleton = ({ type, selected }) => (
  <FlowNode type={type} selected={selected}>
    <FlowNodeBody>
      <Skeleton height={200} width={350} animation='wave' />
    </FlowNodeBody>
  </FlowNode>
);
