import localStorage from 'utils/localStorage';
import { CACHE } from 'utils/appConstants';
import { helpDocs } from 'utils/urlConstants';
import { useHistory, useLocation } from 'react-router-dom';


const APIDoc = () => {
  const location = useLocation();
  const { key } = location.state ? location.state : '';
  const history = useHistory();
  const token = localStorage.get(CACHE.user.jwtAccessToken);
  const url = helpDocs.url(token, key);
  window.open(url);
  history.goBack();
  return null;
};

export default APIDoc;
