import Checkbox from '@mui/material/Checkbox';
import React, {
  memo,
  useContext,
} from 'react';
import { DataContext } from 'BaseComponents/HDataTable/DataContextProvider';


export const HSelectHeadCell = memo(() => {
  const {
    rows,
    selectAll,
    handleOnSelectRow,
    multiSelect,
  } = useContext(DataContext);

  if (!multiSelect) return null;

  return (
    <td width={25}>
      <Checkbox
        checked={selectAll}
        onChange={(e) => handleOnSelectRow(e?.target?.checked ?? false, rows, 'SELECT_ALL')}
      />
    </td>
  );
});

HSelectHeadCell.displayName = 'HSelectHeadCell';
