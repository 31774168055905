import { ITEM_PER_PAGE_OPTIONS } from 'utils/appConstants';
import { LOCAL_STORAGE_KEYS } from 'BaseComponents/HDataTable/constants';
import { isEmpty } from 'lodash';


export const getFilterFromQueryParams = (query, isActiveQuery = true) => {
  const itemsPerPage = localStorage.getItem(LOCAL_STORAGE_KEYS.TABLE_ITEMS_PER_PAGE);
  const limit = itemsPerPage ? Number(itemsPerPage) : ITEM_PER_PAGE_OPTIONS[0];
  const filter = {
    filters: [], sortBy: {}, limit, offset: 0, search: '',
  };

  if (!isActiveQuery) {
    return filter;
  }

  query.forEach((value, attr) => {
    if (attr === 'sortBy') {
      filter.sortBy.attribute = value;
    }
    else if (attr === 'direction') {
      filter.sortBy.direction = value;
    }
    else if (attr === 'search') {
      filter.search = value;
    }
    else {
      filter.filters.push({ attribute: attr, values: [value] });
    }
  });

  return filter;
};

export const filterToPlainObject = (filter) => {
  if (!filter) return {};

  // Filter contains limit and offset, but not necessary to put them on the object.
  const { filters, sortBy, search } = filter;

  if (isEmpty(filters) && isEmpty(sortBy) && isEmpty(search)) {
    return {};
  }

  const a = filters.reduce((acc, item) => ({
    ...acc,
    [item.attribute]: item.values,
  }), {});

  return {
    ...a, sortBy: sortBy.attribute, direction: sortBy.direction, search,
  };
};
