import Grid from '@mui/material/Grid';
import React from 'react';
import SelectList from 'components/Common/SelectList';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { HButton } from 'BaseComponents';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import { organizationManagement } from 'utils/spaUrls';


const OrganizationRoleDetailsForm = (
  {
    save,
    details,
    products,
  },
) => {
  const [roleName, updateRoleName] = React.useState('');
  const [roleDescription, updateRoleDescription] = React.useState('');
  React.useEffect(() => {
    updateRoleName(details.roleName || '');
    updateRoleDescription(details.roleDescription || '');
  }, [details.roleDescription, details.roleName]);

  const [rolePerms, updateRolePerms] = React.useState([]);
  React.useEffect(() => {
    updateRolePerms(details.permissions || []);
  }, [details.permissions]);

  const isValid = () => roleName && rolePerms.length > 0;

  return (
    <Grid container alignItems='stretch' direction='column' spacing={2}>
      <Grid item md={10} sm={12}>
        <TextField
          fullWidth
          InputLabelProps={{ shrink: true }}
          label='Role Name'
          onChange={({ target }) => updateRoleName(target.value)}
          required
          style={{ margin: 8 }}
          value={roleName}
          variant='outlined'
        />
        <TextField
          fullWidth
          InputLabelProps={{ shrink: true }}
          label='Role Description'
          onChange={({ target }) => updateRoleDescription(target.value)}
          required
          style={{ margin: 8 }}
          value={roleDescription}
          variant='outlined'
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          {products.map(product => (
            <Grid item xs={12} md={6} key={product.ID}>
              <Typography variant='h3'>{product.displayName}</Typography>
              <SelectList
                list={product.permissions}
                selected={rolePerms}
                render={p => p.displayName}
                onChange={perms => updateRolePerms(perms)}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Stack direction='row' justifyContent='flex-end' gap={1}>
          <HButton
            component={Link}
            to={organizationManagement.organization.roles.list}
            size='small'
            variant='outlined'
            color='inherit'
          >
            Cancel
          </HButton>
          <Tooltip title={!isValid() ? 'Select roles' : ''} placement='top' arrow>
            <span>
              <HButton
                color='primary'
                onClick={() => save({
                  ID: details.ID,
                  roleName,
                  roleDescription,
                  permissions: rolePerms,
                })}
                size='small'
                variant='contained'
                disabled={!isValid()}
              >
                Save
              </HButton>
            </span>
          </Tooltip>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default OrganizationRoleDetailsForm;
