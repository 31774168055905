import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';
import { FormSkeleton } from 'BaseComponents/LoadingScreens';
import { HButton } from 'BaseComponents';


const GenerateCredentialsModal = ({ open, onClose, credentials }) => (
  <Dialog
    open={open}
    aria-labelledby='modal-dialog-title'
    aria-describedby='modal-dialog-description'
  >
    <DialogTitle>
      To uphold customer privacy, we don’t store your secret keys.
      This is the only chance you will get to copy your secret keys
    </DialogTitle>
    {credentials
      ? (
        <DialogContent>
          {credentials.map(o => (
            <DialogContentText key={o.ID}>
              {o.name.concat(' : ', o.value)}
            </DialogContentText>
          ))}
          <DialogActions>
            <HButton
              onClick={onClose}
              autoFocus
              variant='contained'
            >
              Close
            </HButton>
          </DialogActions>
        </DialogContent>
      ) : <FormSkeleton />}
  </Dialog>
);

export default GenerateCredentialsModal;
