import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import NoData from 'components/Common/NoData';
import React from 'react';
import Toast from 'components/Common/Toast';
import { Skeleton } from '@mui/material';


const ListViewTable = ({
  response, list, children, skeletonHeight, noDataText = 'No Data Available',
}) => {
  const isSyntheticReduxLoading = response && response.isLoading;
  const syntheticReduxError = response && response.error;
  if (!list || list.loading || isSyntheticReduxLoading) {
    return (
      <Grid container wrap='wrap' data-testid='list-view-loading'>
        <Skeleton height={35} width='100%' />
        <Box width='100%' my={1}>
          <Skeleton variant='rectangular' height={skeletonHeight || 400} />
        </Box>
      </Grid>
    );
  }
  else if (list.error || syntheticReduxError) {
    return (
      <>
        <NoData />
        <Toast message={list.error || syntheticReduxError} type='error' />
      </>
    );
  }
  else if (list.length === 0) { return <NoData text={noDataText} />; }

  return children;
};

export default ListViewTable;
