import { styled } from '@mui/material/styles';


export const StyledImg = styled('img')({
  display: 'block',
  marginLeft: 'auto',
  marginRight: 'auto',
});

export const StyledH2 = styled('h2')(({ theme }) => ({
  fontSize: '15px',
  textAlign: 'left',
  fontWeight: 'bolder',
  color: theme.palette.link.main,
  '&:hover': {
    color: theme.palette.link.main,
    textDecoration: 'underline',
  },
}));
