import DeleteIcon from '@mui/icons-material/Delete';
import Grid from '@mui/material/Grid';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import IconButton from '@mui/material/IconButton';
import React from 'react';
import SectionHeader from 'components/IAM/Admin/SectionHeader';
import Tooltip from '@mui/material/Tooltip';
import { HButton } from 'BaseComponents';
import { QuestionSelectField } from 'components/InternalAdmin/QuestionConfigure/QuestionRenderer';
import { v4 as uuidGen } from 'uuid';


const isLastRowValid = (list) => {
  if (!list || !Array.isArray(list) || list.length === 0) { return false; }
  const lastRow = list[list.length - 1];
  if (!lastRow.ID) { return false; }
  else if (!lastRow.importDataType) { return false; }
  else { return true; }
};

const QuestionDataTypes = ({ allDataTypes, dataTypes, onPropertyUpdate }) => {
  const [config, setConfig] = React.useState(dataTypes);

  const onImportDataTypeChange = i => (value) => {
    const item = allDataTypes.find(d => d.ID === value);
    setConfig(prevState => ([
      ...prevState.slice(0, i),
      Object.assign({}, prevState[i], {
        importDataType: {
          ID: item.value,
          displayName: item.name,
          name: item.displayName,
        },
      }),
      ...prevState.slice(i + 1),
    ]));
  };

  const onAddItem = React.useCallback(() => {
    setConfig(prevState => ([
      ...prevState,
      { ID: uuidGen(), optional: false, importDataType: { } },
    ]));
  }, [setConfig]);

  const onDeleteItem = React.useCallback((i) => {
    setConfig(prevState => [
      ...prevState.slice(0, i),
      ...prevState.slice(i + 1),
    ].map((item, newIndex) => ({ ...item, index: newIndex + 1 })));
  }, [setConfig]);

  React.useEffect(() => {
    onPropertyUpdate('dataTypes', config);
  }, [config, onPropertyUpdate]);


  return (
    <>
      <Grid container alignItems='flex-start'>
        <Grid item>
          <SectionHeader title='Data Types' />
        </Grid>
        <Grid item>
          <Tooltip title='Think quantities. Y-axis of chart. Eg. unique user counts'>
            <HelpOutlineIcon fontSize='small' style={{ marginLeft: '5px' }} />
          </Tooltip>
        </Grid>
      </Grid>
      {config.map((dataType, index) => (
        <Grid container spacing={2} key={`data-type-${String(index)}-${dataType.ID}`} alignItems='center'>
          <Grid item xs={10} lg={6}>
            <QuestionSelectField
              value={dataType.importDataType.ID}
              fieldLabel='Data Type'
              list={allDataTypes}
              onChange={onImportDataTypeChange(index)}
            />
          </Grid>
          {index !== 0 && (
          <Grid item xs={2}>
            <IconButton aria-label='delete' onClick={() => { onDeleteItem(index); }} size='large'>
              <DeleteIcon />
            </IconButton>
          </Grid>
          )}
        </Grid>
      ))}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <HButton
            color='primary'
            disabled={!isLastRowValid(config)}
            onClick={onAddItem}
            variant={isLastRowValid(config) ? 'contained' : 'outlined'}
          >
            Add Another
          </HButton>
        </Grid>
      </Grid>
    </>
  );
};

export default QuestionDataTypes;
