import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import React from 'react';
import Typography from '@mui/material/Typography';
import { BreadcrumbsNavigation } from 'components/Common/BreadcrumbsNavigation';
import { HIconButton } from 'BaseComponents';
import { styled } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';


const StyledHIconButton = styled(HIconButton)(({ theme }) => ({ marginRight: theme.spacing(2) }));

const PageHeader = (
  {
    title, subtitle, breadcrumbs = [],
    additionInfo = null, icon, backLink = null, hideBackIcon = false,
  },
) => {
  const history = useHistory();

  const handleBack = () => history.goBack();

  let backButtonLink = backLink;
  if (breadcrumbs.length > 0) {
    backButtonLink = breadcrumbs[breadcrumbs.length - 1].href;
  }
  return (
    <Grid container spacing={2} alignItems='center'>
      <Grid item xs={12} md={12} lg={12}>
        {breadcrumbs && breadcrumbs.length ? (
          <BreadcrumbsNavigation items={[...breadcrumbs, { label: title }]} />
        ) : null}
      </Grid>
      <Grid item xs={12} md={6} lg={8}>
        <Box display='flex'>
          {backButtonLink && !hideBackIcon && (
          <StyledHIconButton onClick={handleBack}>
            <ArrowBackIcon fontSize='medium' color='primary' />
          </StyledHIconButton>
          )}
          <Typography variant='h2' display='flex' alignItems='center'>
            {icon}
            {title}
          </Typography>
          {additionInfo}
        </Box>
      </Grid>
      {subtitle && (
        <Grid item xs={12}>
          <Typography variant='h6'>
            {subtitle}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12} />
    </Grid>
  );
};

export default PageHeader;
