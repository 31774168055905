import AppBar from '@mui/material/AppBar';
import Drawer from '@mui/material/Drawer';
import FlowMacroLayout from 'pages/Flow/FlowMacro/FlowMacroLayout';
import QuestionChainingEdgeLayout
  from 'pages/Flow/FlowSidePanel/QuestionChainingEdgeLayout';
import QuestionSidePanelLayout from 'pages/Flow/FlowSidePanel/QuestionSidePanelLayout';
import React, { useContext } from 'react';
import theme, { habuColors } from 'Theme';
import { FLOW_DRAWER_TYPE } from 'pages/Flow/contacts';
import { FlowBuilderContext } from 'pages/Flow/FlowBuilder/FlowBuilderContextProvider';
import {
  FlowMacroContextProvider,
} from 'pages/Flow/FlowMacro/MacroAssignmentTab/FlowMacroContextProvider';
import { FlowSidePanelContext } from 'pages/Flow/FlowSidePanel/FlowSidePanelProvider';
import {
  NodesEdgeConnectionProvider,
} from 'pages/Flow/QuestionNodesEdgeConnectionDialog/NodesEdgeConnectionProvider';
import { closeSidePanel } from 'redux/actions/FlowAction';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from '@mui/material';


const FlowSidePanelDrawer = () => {
  const { drawerInfo } = useContext(FlowSidePanelContext);
  const { flowId, crId } = useContext(FlowBuilderContext);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();


  const handleOnClose = () => {
    dispatch(closeSidePanel());
  };

  return (
    <Drawer
      anchor='right'
      keepMounted
      open={drawerInfo.open}
      onClose={handleOnClose}
      sx={{
        '& .MuiDrawer-paper': {
          minWidth: isSmallScreen ? '100%' : '35%',
          maxWidth: '35%',
        },
        // eslint-disable-next-line import/no-named-as-default-member
        zIndex: theme.zIndex.drawer + 1,
      }}
    >
      <AppBar
        position='static'
        elevation={0}
        sx={{
          color: habuColors.neutral[90],
          borderTop: `4px solid ${habuColors.primary[80]}`,
        }}
      >
        {drawerInfo.type === 'NODE' && (
          <QuestionSidePanelLayout onClose={handleOnClose} drawerInfo={drawerInfo} />)}
        {drawerInfo.type === 'QUESTION_TO_QUESTION' && (
          <NodesEdgeConnectionProvider flowId={flowId} crId={crId}>
            <QuestionChainingEdgeLayout onClose={handleOnClose} drawerInfo={drawerInfo} />
          </NodesEdgeConnectionProvider>
        )}
        {drawerInfo.type === FLOW_DRAWER_TYPE.PARAMETERS && (
          <FlowMacroContextProvider>
            <FlowMacroLayout />
          </FlowMacroContextProvider>
        )}
      </AppBar>
    </Drawer>
  );
};

export default FlowSidePanelDrawer;
