import React, { memo, useContext, useState } from 'react';
import {
  Alert,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { NodesEdgeConnectionContext } from 'pages/Flow/QuestionNodesEdgeConnectionDialog/NodesEdgeConnectionProvider';
import { QUESTION_OUTPUT_TYPES } from 'utils/constants/question.constant';


export const DataSetDropDown = memo(({ type, index, row }) => {
  const { onMacroChange, parentOutput: dropDownOptions } = useContext(NodesEdgeConnectionContext);
  const [currentValue, setCurrentValue] = useState(row.outputFieldID);

  if (dropDownOptions.length === 0) {
    return (
      <Alert severity='error'>
        No outputs available to map to macros.
      </Alert>
    );
  }
  const filterDropDownOptions = dropDownOptions.filter(option => option.type === type);

  if (filterDropDownOptions.length === 0) {
    return (
      <Alert severity='error'>
        No outputs available to map to macros.
      </Alert>
    );
  }

  const handleChange = (e) => {
    const selectedOption = filterDropDownOptions.find(option => option.ID === e.target.value);
    onMacroChange(e, selectedOption, index);
    setCurrentValue(selectedOption.ID);
  };

  return (
    <FormControl sx={{ m: 1, minWidth: '100%' }} size='small' required>
      <InputLabel id='macro-field-select-label' required>Field</InputLabel>
      <Select
        data-testid='select-data-set-dropdown-text-field'
        labelId='macro-field-select-label'
        id='macro-field-select'
        value={currentValue}
        label='Field'
        required
        onChange={handleChange}
      >
        {filterDropDownOptions.map(i => (
          <MenuItem key={i.ID} value={i.ID} data-testid={`menu-item-${i.name}`}>
            {i.questionOutputType === QUESTION_OUTPUT_TYPES.USER_LIST ? i.name : i.displayName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
});

DataSetDropDown.displayName = 'DataSetDropDown';
