import { QUESTION_BUILDER } from 'redux/actions/Types';
import { cleanRoom } from 'utils/urlConstants';


export const reset = () => ({
  type: QUESTION_BUILDER.RESET,
});

export const setActiveStep = (payload) => ({
  type: QUESTION_BUILDER.SET_ACTIVE_STEP,
  payload,
});

export const setWizardMode = (payload) => ({
  type: QUESTION_BUILDER.SET_WIZARD_MODE,
  payload,
});

export const setQuestionType = (payload) => ({
  type: QUESTION_BUILDER.SET_QUESTION_TYPE,
  payload,
});

export const updateQuestion = (payload) => ({
  type: QUESTION_BUILDER.UPDATE_QUESTION,
  payload,
});

export const updateQuestionDetails = (payload) => ({
  type: QUESTION_BUILDER.UPDATE_QUESTION_DETAILS,
  payload,
});

export const updateQuestionQuery = (payload) => ({
  type: QUESTION_BUILDER.UPDATE_QUESTION_QUERY,
  payload,
});

export const setIsDiff = (payload) => ({
  type: QUESTION_BUILDER.SET_IS_DIFF,
  payload,
});

export const setWalledGardenCleanRooms = (payload) => ({
  type: QUESTION_BUILDER.SET_WALLED_GARDEN_CLEAN_ROOMS,
  payload,
});

export const updateFormState = (payload) => ({
  type: QUESTION_BUILDER.UPDATE_FORM_STATE,
  payload,
});

export const setDarkMode = (payload) => ({
  type: QUESTION_BUILDER.SET_DARK_MODE,
  payload,
});

export const setFullScreen = (payload) => ({
  type: QUESTION_BUILDER.SET_FULL_SCREEN,
  payload,
});

export const setFocusedCleanRoom = (payload) => ({
  type: QUESTION_BUILDER.SET_FOCUSED_CLEAN_ROOM,
  payload,
});

export const updateEditorTabs = (payload) => ({
  type: QUESTION_BUILDER.UPDATE_EDITOR_TABS,
  payload,
});

export const fetchCrQuestionPermissions = (orgId, crId, cleanRoomQuestionID) => ({
  type: QUESTION_BUILDER.FETCH_CR_QUESTION_PERMISSIONS,
  url: cleanRoom(orgId).getCleanRoomQuestionPermissions(crId, cleanRoomQuestionID),
});

export const fetchCrQuestionPermissionsError = payload => ({
  type: QUESTION_BUILDER.FETCH_CR_QUESTION_PERMISSIONS_ERROR,
  payload,
});

export const fetchCrQuestionPermissionsSuccess = (payload) => ({
  type: QUESTION_BUILDER.FETCH_CR_QUESTION_PERMISSIONS_SUCCESS,
  payload,
});

export const updateCrQuestionPermissions = (payload) => ({
  type: QUESTION_BUILDER.UPDATE_CR_QUESTION_PERMISSIONS,
  payload,
});

export const updateCrQuestionUdf = (payload) => ({
  type: QUESTION_BUILDER.UPDATE_CR_QUESTION_UDF,
  payload,
});
