import InternalAdminAccountRouter from 'pages/InternalAdmin/account/Router';
import InternalAdminApplicationRouter from 'pages/InternalAdmin/application/Router';
import InternalAdminLanding from 'pages/InternalAdmin/pages/landing/InternalAdminLanding';
import InternalAdminOrganizationRouter from 'pages/InternalAdmin/organization/Router';
import NavbarLayout from 'components/Layouts/NavbarLayout';
import PrivateRoute from 'components/Common/PrivateRoute';
import React from 'react';
import { Switch } from 'react-router-dom';
import { internalAdmin } from 'utils/spaUrls';


const InternalAdminRouter = () => (
  <Switch>
    <PrivateRoute
      path={internalAdmin.landing}
      component={InternalAdminLanding}
      exact
    />
    <PrivateRoute path={`${internalAdmin.account.landing}`} component={InternalAdminAccountRouter} />
    <PrivateRoute path={`${internalAdmin.application.landing}`} component={InternalAdminApplicationRouter} />
    <PrivateRoute path={`${internalAdmin.organization.landing}`} component={InternalAdminOrganizationRouter} />
  </Switch>
);

export { InternalAdminRouter };

const InternalAdminRouterWithNavbar = NavbarLayout(InternalAdminRouter);

export default InternalAdminRouterWithNavbar;
