import { ITEM_PER_PAGE_OPTIONS } from 'utils/appConstants';
import { LOCAL_STORAGE_KEYS } from 'BaseComponents/HDataTable/constants';
import { useCallback, useEffect, useState } from 'react';


export const useItemsPerPage = () => {
  const key = LOCAL_STORAGE_KEYS.TABLE_ITEMS_PER_PAGE;
  const [itemsPerPage, updateItemsPerPage] = useState(ITEM_PER_PAGE_OPTIONS[0]);

  const getItemsPerPage = useCallback(() => localStorage.getItem(key), [key]);

  const setItemsPerPage = (value) => {
    updateItemsPerPage(value);
    localStorage.setItem(key, value);
  };

  useEffect(() => {
    const cachedNum = getItemsPerPage();
    const num = cachedNum ? Number(cachedNum) : ITEM_PER_PAGE_OPTIONS[0];
    updateItemsPerPage(num);
  }, [getItemsPerPage]);


  return {
    itemsPerPage,
    getItemsPerPage,
    setItemsPerPage,
  };
};
