import AlertsRequestTable from 'pages/InternalAdmin/organization/pages/AlertsRequest/AlertsRequestTable';
import ChatView from 'pages/InternalAdmin/organization/pages/AlertsRequest/ChatView';
import React, { useState } from 'react';
import { AlertRequestContext } from 'pages/InternalAdmin/organization/pages/AlertsRequest/AlertsRequestContext';


const AlertsRequestLayout = () => {
  const [chatID, setChatID] = useState('');

  const handleChatViewOpen = (cID) => {
    setChatID(cID);
  };

  const handleChatViewClose = () => {
    setChatID('');
  };

  return (
    <AlertRequestContext.Provider value={{ handleChatViewOpen }}>
      <AlertsRequestTable />
      <ChatView
        open={Boolean(chatID)}
        onClose={handleChatViewClose}
        chatID={chatID}
      />
    </AlertRequestContext.Provider>
  );
};

export default AlertsRequestLayout;
