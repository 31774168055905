import AlertsRequestLayout from 'pages/InternalAdmin/organization/pages/AlertsRequest/AlertsRequestLayout';
import Box from '@mui/material/Box';
import PageHeader from 'components/Common/PageHeader';
import React from 'react';
import { AlertsRequestPageContext } from 'pages/InternalAdmin/organization/pages/AlertsRequest/AlertsRequestContext';
import { CLEAN_ROOM_STATUS } from 'components/CleanRooms/constants';
import { HSpinner } from 'BaseComponents';
import { activeOrgSelector } from 'redux/reducer/AuthenticationReducer';
import { crumbs } from 'components/Common/BreadcrumbsNavigation';
import { internalAdmin } from 'utils/spaUrls';
import { useFetchCleanRoomList } from 'hooks/cleanRoomAPIs';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';


const AlertsRequest = () => {
  const history = useHistory();
  const { crId } = useParams();
  const { ID: orgId } = useSelector(activeOrgSelector);

  const {
    isFetching: fetchingCRList,
    isError: isCrListError,
    data: crList,
  } = useFetchCleanRoomList(orgId, { filters: [] });

  const onCleanRoomChange = (cleanRoomID) => {
    history.replace(internalAdmin.organization.alertsRequest(cleanRoomID));
  };

  React.useEffect(() => {
    if (!crId && !fetchingCRList && crList.length > 0) {
      history.replace(internalAdmin.organization.alertsRequest(crList[0].ID));
    }
  }, [crId, crList, fetchingCRList, history]);

  const selectedCr = crList.find(cr => cr.ID === crId);
  const currentCrStatus = selectedCr ? selectedCr.status : null;

  const isCurrentCrExpired = currentCrStatus === CLEAN_ROOM_STATUS.EXPIRED.key;

  return (
    <>
      <PageHeader title='Alerts Request' breadcrumbs={[crumbs.home, crumbs.internalAdmin]} />
      {
        crId ? (
          <AlertsRequestPageContext.Provider value={{
            crId,
            onCleanRoomChange,
            crListQuery: {
              fetchingCRList,
              isCrListError,
              crList,
            },
            isCurrentCrExpired,
          }}
          >
            <AlertsRequestLayout />
          </AlertsRequestPageContext.Provider>
        ) : (
          <Box>
            <HSpinner />
          </Box>
        )
      }
    </>
  );
};

export default AlertsRequest;
