export const QUESTION_BUILDER = {
  RESET: 'RESET',
  SET_ACTIVE_STEP: 'SET_ACTIVE_STEP',
  SET_WIZARD_MODE: 'SET_WIZARD_MODE',
  SET_QUESTION_TYPE: 'SET_QUESTION_TYPE',
  UPDATE_QUESTION: 'UPDATE_QUESTION',
  UPDATE_QUESTION_DETAILS: 'UPDATE_QUESTION_DETAILS',
  UPDATE_QUESTION_QUERY: 'UPDATE_QUESTION_QUERY',
  SET_IS_DIFF: 'SET_IS_DIFF',
  SET_WALLED_GARDEN_CLEAN_ROOMS: 'SET_WALLED_GARDEN_CLEAN_ROOMS',
  UPDATE_FORM_STATE: 'UPDATE_FORM_STATE',
  SET_DARK_MODE: 'SET_DARK_MODE',
  SET_FULL_SCREEN: 'SET_FULL_SCREEN',
  SET_FOCUSED_CLEAN_ROOM: 'SET_FOCUSED_CLEAN_ROOM',
  UPDATE_EDITOR_TABS: 'UPDATE_EDITOR_TABS',
  FETCH_CR_QUESTION_PERMISSIONS: 'FETCH_CR_QUESTION_PERMISSIONS',
  FETCH_CR_QUESTION_PERMISSIONS_SUCCESS: 'FETCH_CR_QUESTION_PERMISSIONS_SUCCESS',
  FETCH_CR_QUESTION_PERMISSIONS_ERROR: 'FETCH_CR_QUESTION_PERMISSIONS_ERROR',
  UPDATE_CR_QUESTION_PERMISSIONS: 'UPDATE_CR_QUESTION_PERMISSIONS',
  UPDATE_CR_QUESTION_UDF: 'UPDATE_CR_QUESTION_UDF',
};
