import React from 'react';


const usePreviousProps = (value) => {
  const ref = React.useRef({});
  React.useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export default usePreviousProps;
