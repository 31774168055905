import { CLEAN_ROOM_ADMIN } from 'redux/actions/Types';
import { cleanRoom, dataIn } from 'utils/urlConstants';


export const fetchCrAllQuestions = () => ({
  type: CLEAN_ROOM_ADMIN.FETCH_ALL_QUESTIONS,
  url: cleanRoom().listAllQuestions,
});

export const fetchCrAllQuestionsError = payload => ({
  type: CLEAN_ROOM_ADMIN.FETCH_ALL_QUESTIONS_ERROR,
  payload,
});

export const fetchCrAllQuestionsSuccess = payload => ({
  type: CLEAN_ROOM_ADMIN.FETCH_ALL_QUESTIONS_SUCCESS,
  payload,
});

export const clearCrQuestion = () => ({
  type: CLEAN_ROOM_ADMIN.CLEAR_CR_QUESTION,
});

export const createCrQuestion = payload => ({
  type: CLEAN_ROOM_ADMIN.CREATE_CR_QUESTION,
  url: cleanRoom().createQuestion,
  payload,
});

export const createCrQuestionError = payload => ({
  type: CLEAN_ROOM_ADMIN.CREATE_CR_QUESTION_ERROR,
  payload,
});

export const createCrQuestionSuccess = payload => ({
  type: CLEAN_ROOM_ADMIN.CREATE_CR_QUESTION_SUCCESS,
  payload,
});

export const fetchAllImportDataTypes = () => ({
  type: CLEAN_ROOM_ADMIN.FETCH_ALL_IMPORT_DATA_TYPES,
  url: dataIn().importDataTypeList(),
});

export const fetchAllImportDataTypesError = payload => ({
  type: CLEAN_ROOM_ADMIN.FETCH_ALL_IMPORT_DATA_TYPES_ERROR,
  payload,
});

export const fetchAllImportDataTypesSuccess = payload => ({
  type: CLEAN_ROOM_ADMIN.FETCH_ALL_IMPORT_DATA_TYPES_SUCCESS,
  payload,
});

export const fetchAllStorageProviders = () => ({
  type: CLEAN_ROOM_ADMIN.FETCH_ALL_STORAGE_PROVIDERS,
  url: cleanRoom().allStorageProviders,
});

export const fetchAllStorageProvidersError = payload => ({
  type: CLEAN_ROOM_ADMIN.FETCH_ALL_STORAGE_PROVIDERS_ERROR,
  payload,
});

export const fetchAllStorageProvidersSuccess = payload => ({
  type: CLEAN_ROOM_ADMIN.FETCH_ALL_STORAGE_PROVIDERS_SUCCESS,
  payload,
});

export const fetchQuestion = questionId => ({
  type: CLEAN_ROOM_ADMIN.FETCH_QUESTION,
  url: cleanRoom().getQuestion(questionId),
});

export const fetchQuestionError = payload => ({
  type: CLEAN_ROOM_ADMIN.FETCH_QUESTION_ERROR,
  payload,
});

export const fetchQuestionSuccess = payload => ({
  type: CLEAN_ROOM_ADMIN.FETCH_QUESTION_SUCCESS,
  payload,
});

export const updateCrQuestion = (questionId, payload) => ({
  type: CLEAN_ROOM_ADMIN.UPDATE_CR_QUESTION,
  url: cleanRoom().updateQuestion(questionId),
  payload,
});

export const updateCrQuestionError = payload => ({
  type: CLEAN_ROOM_ADMIN.UPDATE_CR_QUESTION_ERROR,
  payload,
});

export const updateCrQuestionSuccess = payload => ({
  type: CLEAN_ROOM_ADMIN.UPDATE_CR_QUESTION_SUCCESS,
  payload,
});
