import Box from '@mui/material/Box';
import FlowPreviewMacroTable from 'pages/Flow/FlowMacro/PreviewMacroTab/FlowPreviewMacroTable';
import Grid from '@mui/material/Grid';
import React, { useContext } from 'react';
import Typography from '@mui/material/Typography';
import { FlowMacroContext } from 'pages/Flow/FlowMacro/MacroAssignmentTab/FlowMacroContextProvider';
import { FlowParameterDescription } from 'pages/Flow/FlowMacro/FlowParametersCommonComponents';
import { HButton, HSpinner } from 'BaseComponents';


const FlowMacroPreview = () => {
  const {
    flowMacroMapping,
    handleBack,
    isSubmitFlowMacroMappingLoading,
    setFlowMacroMapping,
    submitFlowMacroMappingMutate,
  } = useContext(FlowMacroContext);

  const handleSubmit = () => {
    submitFlowMacroMappingMutate({ macroWithMappings: flowMacroMapping });
  };

  const onFlowMacroUpdate = ({ macro, description }) => {
    // Find the index of the flowMacro by name
    const index = flowMacroMapping.findIndex(mapping => mapping.macro.name
      === macro.name);

    if (index !== -1) {
      // Update the description of the matching flowMacro
      const updatedMapping = {
        ...flowMacroMapping[index],
        macro: {
          ...flowMacroMapping[index].macro,
          description,
        },
      };

      // Replace the old mapping with the updated one
      setFlowMacroMapping([
        ...flowMacroMapping.slice(0, index),
        updatedMapping,
        ...flowMacroMapping.slice(index + 1),
      ]);
    }
  };

  return (
    <Grid container spacing={0} sx={{ paddingX: 3, paddingBottom: 2 }}>
      <FlowParameterDescription text='These are the parameters your partners will need to fill out before creating a run.' />
      <Grid item xs={12}>
        {flowMacroMapping?.length > 0 ? (
          <FlowPreviewMacroTable
            flowMacros={flowMacroMapping}
            onFlowMacroUpdate={onFlowMacroUpdate}
          />
        ) : (
          <Typography>No parameters available</Typography>
        )}
      </Grid>
      <Box sx={{
        position: 'sticky',
        bottom: 0,
        zIndex: 1,
        backgroundColor: 'white',
        padding: 2,
        marginTop: '10px',
        width: '100%',
      }}
      >
        <Grid container item spacing={2} justifyContent='flex-end'>
          <Grid item>
            <HButton variant='outlined' onClick={handleBack}>
              Back
            </HButton>
          </Grid>
          <Grid item>
            <HButton
              type='submit'
              variant='contained'
              color='primary'
              endIcon={isSubmitFlowMacroMappingLoading ? <HSpinner isButton /> : null}
              disabled={isSubmitFlowMacroMappingLoading}
              onClick={handleSubmit}
            >
              Save
            </HButton>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default FlowMacroPreview;
