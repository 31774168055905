export const DATA_CREDENTIAL = {
  CREATE_DATA_CREDENTIAL_KEYS: 'CREATE_DATA_CREDENTIAL_KEYS',
  GENERATE_DATA_CREDENTIAL_KEYS_SUCCESS: 'GENERATE_DATA_CREDENTIAL_KEYS_SUCCESS',
  GENERATE_DATA_CREDENTIAL_KEYS_ERROR: 'GENERATE_DATA_CREDENTIAL_KEYS_ERROR',
  FETCH_ORG_DATA_CREDENTIAL_LIST: 'FETCH_ORG_DATA_CREDENTIAL_LIST',
  RECEIVE_ORG_DATA_CREDENTIAL_LIST_SUCCESS: 'RECEIVE_ORG_DATA_CREDENTIAL_LIST_SUCCESS',
  RECEIVE_ORG_DATA_CREDENTIAL_LIST_ERROR: 'RECEIVE_ORG_DATA_CREDENTIAL_LIST_ERROR',
  FETCH_ORG_CREDENTIAL: 'FETCH_ORG_CREDENTIAL',
  RECEIVE_ORG_CREDENTIAL_SUCCESS: 'RECEIVE_ORG_CREDENTIAL_SUCCESS',
  RECEIVE_ORG_CREDENTIAL_ERROR: 'RECEIVE_ORG_CREDENTIAL_ERROR',
  CREATE_ORG_CREDENTIAL_SOURCE: 'CREATE_ORG_CREDENTIAL_SOURCE',
  UPDATE_ORG_CREDENTIAL_SOURCE: 'UPDATE_ORG_CREDENTIAL_SOURCE',
  SUBMIT_ORG_CREDENTIAL_SOURCE_SUCCESS: 'SUBMIT_ORG_CREDENTIAL_SOURCE_SUCCESS',
  SUBMIT_ORG_CREDENTIAL_SOURCE_ERROR: 'SUBMIT_ORG_CREDENTIAL_SOURCE_ERROR',
  DELETE_ORG_CREDENTIAL_SOURCE: 'DELETE_ORG_CREDENTIAL_SOURCE',
  DELETE_ORG_CREDENTIAL_SOURCE_SUCCESS: 'DELETE_ORG_CREDENTIAL_SOURCE_SUCCESS',
  DELETE_ORG_CREDENTIAL_SOURCE_ERROR: 'DELETE_ORG_CREDENTIAL_SOURCE_ERROR',
  FETCH_CREDENTIAL_SOURCES_LIST: 'FETCH_CREDENTIAL_SOURCES_LIST',
  RECEIVE_CREDENTIAL_SOURCES_LIST_SUCCESS: 'RECEIVE_CREDENTIAL_SOURCES_LIST_SUCCESS',
  RECEIVE_CREDENTIAL_SOURCES_LIST_ERROR: 'RECEIVE_CREDENTIAL_SOURCES_LIST_ERROR',
  FETCH_CREDENTIAL_SOURCE: 'FETCH_CREDENTIAL_SOURCE',
  RECEIVE_CREDENTIAL_SOURCE_SUCCESS: 'RECEIVE_CREDENTIAL_SOURCE_SUCCESS',
  RECEIVE_CREDENTIAL_SOURCE_ERROR: 'RECEIVE_CREDENTIAL_SOURCE_ERROR',
  DELETE_CREDENTIAL_SOURCE: 'DELETE_CREDENTIAL_SOURCE',
  DELETE_CREDENTIAL_SOURCE_SUCCESS: 'DELETE_CREDENTIAL_SOURCE_SUCCESS',
  DELETE_CREDENTIAL_SOURCE_ERROR: 'DELETE_CREDENTIAL_SOURCE_ERROR',
  CREATE_CREDENTIAL_SOURCE: 'CREATE_CREDENTIAL_SOURCE',
  UPDATE_CREDENTIAL_SOURCE: 'UPDATE_CREDENTIAL_SOURCE',
  SUBMIT_CREDENTIAL_SOURCE_SUCCESS: 'CREATE_CREDENTIAL_SOURCE_SUCCESS',
  SUBMIT_CREDENTIAL_SOURCE_ERROR: 'CREATE_CREDENTIAL_SOURCE_ERROR',
};
