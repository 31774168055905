import * as actionGenerators from 'redux/actions/UserListsActions';
import Logger from 'components/Common/Logger';
import http from 'utils/http';
import { USER_LISTS } from 'redux/actions/Types';
import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import { fetchUserList, fetchUserListDetail, reprocessUserList } from 'pages/UserLists/redux';
import { habuSupport } from 'utils/appConstants';
import { showAlert } from 'redux/actions/AlertActions';


function* changeUserListStatus(action) {
  const { userList, error } = yield call(http.post, action.url, action.payload);
  if (error) {
    Logger.error(`Error Changing User List Status: ${action.url}. Error: ${error}`,
      'User List: Change Status');
    return yield put(actionGenerators.changeUserListStatusError(
      `Couldn't update status of UserList ${action.meta.name}. ${habuSupport.message}`,
    ));
  }
  return yield put(actionGenerators.changeUserListStatusSuccess(userList));
}

function* deleteUserList(action) {
  const { success, error } = yield call(http.delete, action.url);
  if (error) {
    Logger.error(`Could not delete UserList. Url: ${action.url}. Error: ${error}`,
      'User List: delete userList');
    yield put(showAlert({ message: error, type: 'error' }));
    return yield put(actionGenerators.deleteUserListError(error));
  }
  yield put(showAlert({ message: 'User List Deleted', type: 'success' }));
  return yield put(actionGenerators.deleteUserListSuccess(success));
}

function* fetchUserListFilters(action) {
  const { filters, sorts, error } = yield call(http.get, action.url);
  if (error) {
    yield put(showAlert({
      type: 'error',
      message: `Error fetching User List Filters. ${habuSupport.message}`,
    }));
    Logger.error(`Could not fetch UserList Filters. Url: ${action.url}. Error: ${error}`,
      'User List: Fetch User List Filters');
    return yield put(actionGenerators.fetchUserListFiltersError(error));
  }
  return yield put(actionGenerators.fetchUserListFiltersSuccess({ filters, sorts }));
}

function* fetchUserListDetailRuns(action) {
  const { userListTrend, error } = yield call(http.get, action.url);
  if (error) {
    Logger.error(`Could not fetch UserList Runs. Url: ${action.url}. Error: ${error}`,
      'User List: Fetch userListRuns');
    return yield put(actionGenerators.fetchUserListDetailRunsError(error));
  }
  return yield put(actionGenerators.fetchUserListDetailRunsSuccess(userListTrend));
}

function* fetchUserListSummary(action) {
  const { summaries, error } = yield call(http.get, action.url);
  if (error) {
    Logger.error(`Could not fetch UserList Summary. Url: ${action.url}. Error: ${error}`,
      'User List: Fetch userListSummary');
    return yield put(actionGenerators.fetchUserListSummaryError(error));
  }
  return yield put(actionGenerators.fetchUserListSummarySuccess(summaries));
}

function* saveUserList(action) {
  const isNew = !(action.payload.userList.ID);
  const { userList, error } = (isNew) ? yield call(http.post, action.url, action.payload)
    : yield call(http.put, action.url, action.payload);
  if (error) {
    Logger.error(`Could not save UserList. Url: ${action.url}. Error: ${error}`,
      'User List: save userList');
    yield put(showAlert({ message: error, type: 'error' }));
    return yield put(actionGenerators.saveUserListError(error));
  }
  yield put(showAlert({ message: 'User List saved', type: 'success' }));
  return yield put(actionGenerators.saveUserListSuccess(userList));
}

export default function* root() {
  yield all([
    fetchUserList.sagaEffect,
    fetchUserListDetail.sagaEffect,
    reprocessUserList.sagaEffect,
    takeLatest(USER_LISTS.CHANGE_STATUS, changeUserListStatus),
    takeLatest(USER_LISTS.DELETE_USER_LIST, deleteUserList),
    takeLatest(USER_LISTS.FETCH_UL_FILTERS, fetchUserListFilters),
    takeLatest(USER_LISTS.FETCH_LIST_DETAIL_RUNS, fetchUserListDetailRuns),
    takeLatest(USER_LISTS.FETCH_LIST_SUMMARY, fetchUserListSummary),
    takeLatest(USER_LISTS.SAVE_USER_LIST, saveUserList),
  ]);
}

export {
  changeUserListStatus,
  fetchUserListDetailRuns,
  fetchUserListSummary,
  saveUserList,
};
