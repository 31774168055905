import { activeOrgSelector } from 'redux/reducer/AuthenticationReducer';
import { axiosInstance } from 'utils/http';
import { organizationIdentity } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';


export const useGetIdentity = (id, enabled) => {
  const dispatch = useDispatch();
  const { ID: orgId } = useSelector(activeOrgSelector);

  return useQuery(
    [orgId, 'identity'],
    async () => {
      const { data } = await axiosInstance.get(organizationIdentity(orgId).one(id));
      return data;
    },
    {
      enabled,
      onError: (err) => {
        const errorMessage = err.response?.data?.message || 'Failed to fetch identity';
        dispatch(showAlert({
          message: errorMessage,
          type: 'error',
        }));
      },
    },
  );
};
