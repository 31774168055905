import CleanRoomTypesTable from './CleanRoomTypesTable';
import ListViewTable from 'components/Common/ListViewTable';
import PageHeader from 'components/Common/PageHeader';
import React from 'react';
import { Card, CardContent, Grid } from '@mui/material';
import { HButton } from 'BaseComponents';
import { StyledGrid } from 'components/IAM/Admin/StyledComponents';
import { crumbs } from 'components/Common/BreadcrumbsNavigation';
import { internalAdmin } from 'utils/spaUrls';
import { isEmpty } from 'lodash';
import { useFetchCleanRoomTypes, useUpdateCleanRoomTypes } from 'hooks/cleanRoomAPIs';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';


const CleanRoomTypes = () => {
  const history = useHistory();
  const orgID = useSelector(state => state.session.user.primaryOrganization.organization.ID);
  const orgName = useSelector(state => state.session.user.primaryOrganization.organization.name);
  const [cleanRoomTypes, setCleanRoomType] = React.useState([]);
  const { data: cleanRoomTypesAll } = useFetchCleanRoomTypes();
  const { data: cleanRoomTypesOrg } = useFetchCleanRoomTypes(orgID);
  const updateCleanRoomTypes = useUpdateCleanRoomTypes();

  React.useEffect(() => {
    if (isEmpty(cleanRoomTypesOrg)) {
      return;
    }
    setCleanRoomType(cleanRoomTypesOrg.map(i => i.ID));
  }, [cleanRoomTypesOrg]);

  const onSubmit = () => {
    const selectedTypes = [];
    cleanRoomTypes.map(cleanRoomType => selectedTypes.push({ ID: cleanRoomType }));
    const payload = { cleanRoomTypes: selectedTypes };
    updateCleanRoomTypes.mutate({ orgID, payload });
  };

  React.useEffect(() => {
    if (updateCleanRoomTypes.isSuccess || updateCleanRoomTypes.isError) {
      updateCleanRoomTypes.reset();
      history.push(internalAdmin.landing);
    }
  }, [updateCleanRoomTypes, history]);

  return (
    <>
      <PageHeader title={`${orgName}: Manage Supported Clean Room Types`} breadcrumbs={[crumbs.home, crumbs.internalAdmin]} />
      <Grid container spacing={0} direction='column'>
        <Grid item>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ListViewTable list={cleanRoomTypesAll}>
                    <CleanRoomTypesTable
                      list={cleanRoomTypesAll}
                      cleanRoomTypes={cleanRoomTypes}
                      setCleanRoomType={setCleanRoomType}
                    />
                  </ListViewTable>
                </Grid>
                <StyledGrid item xs={12}>
                  <HButton
                    color='primary'
                    size='small'
                    variant='contained'
                    onClick={() => onSubmit()}
                  >
                    Update Clean Room Types
                  </HButton>
                </StyledGrid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default CleanRoomTypes;
