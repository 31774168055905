import Bouncer from 'components/Common/Bouncer';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import HSearchComponent from 'BaseComponents/HSearchComponent';
import IconButton from '@mui/material/IconButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListViewTable from 'components/Common/ListViewTable';
import React, { useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { CLEAN_ROOM_STATUS } from 'components/CleanRooms/constants';
import { FlowNode, FlowNodeBody } from 'pages/Flow/Nodes/FlowNode';
import { HButton } from 'BaseComponents';
import { Link, useParams } from 'react-router-dom';
import { cleanRoomPermissions } from 'utils/appConstants';
import { question as questionURL } from 'utils/spaUrls';
import {
  useFetchAvailableQuestionFlow,
} from 'hooks/questionAPIs';
import { useSelector } from 'react-redux';


const QuestionSetUpNode = ({ onQuestionAdd, selected }) => {
  const { crId } = useParams();
  const [qSelected, setQSelected] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const crDetail = useSelector(state => state.cleanRooms.detail);
  const isExpired = crDetail?.status === CLEAN_ROOM_STATUS.EXPIRED.key;
  const isTemplate = crDetail?.isTemplate === true;

  const {
    isSuccess: questionListFetched,
    data: { questions: questionsData = [] } = {},
  } = useFetchAvailableQuestionFlow(crId);

  const questions = questionsData.filter(question => question.title.toLowerCase()
    .includes(searchTerm.toLowerCase()));

  const handleListItemClick = (event, question) => {
    setQSelected(question.ID);
  };

  const onSearch = (search) => {
    setSearchTerm(search.trim());
  };

  return (
    <FlowNode selected={selected} customNodeSize='MD'>
      <FlowNodeBody container>
        <Grid item xs={12} container>
          <Grid item xs={6}>
            <Typography variant='subtitle1'>
              Questions
            </Typography>
          </Grid>
          <Grid item xs={6} textAlign='end'>
            <Bouncer allowList={[cleanRoomPermissions.questionBuilder]}>
              <>
                {(!isExpired && !isTemplate) && (
                <HButton
                  color='link'
                  component={Link}
                  to={questionURL.builder.wizard.flow('', crId, '')}
                >
                  + Create Question
                </HButton>
                )}
              </>
            </Bouncer>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <HSearchComponent
            onSearch={onSearch}
          />
          <ListViewTable list={questions}>
            <List
              className='nowheel'
              sx={{
                overflow: 'auto',
                maxHeight: 300,
              }}
            >
              {questionListFetched && questions.map((question) => (
                <ListItem key={question.ID}>
                  <ListItemButton
                    selected={qSelected === question.ID}
                    onClick={(event) => handleListItemClick(event, question)}
                  >
                    <ListItemIcon>
                      <Checkbox checked={qSelected === question.ID} />
                    </ListItemIcon>
                    <ListItemText primary={`${question.title}`} />
                    <Tooltip title={question.title} placement='top'>
                      <IconButton edge='end' aria-label='comments'>
                        <InfoOutlinedIcon fontSize='small' />
                      </IconButton>
                    </Tooltip>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
            <HButton
              color='primary'
              variant='contained'
              onClick={() => onQuestionAdd(qSelected)}
              disabled={qSelected === null}
            >
              Add
            </HButton>
          </ListViewTable>
        </Grid>
      </FlowNodeBody>
    </FlowNode>
  );
};

export default QuestionSetUpNode;
