import { NODES_TYPE } from 'pages/Flow/contacts';
import {
  addTemporaryNodeToFlow,
} from 'redux/actions/FlowAction';
import {
  useCallback, useRef, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { v4 as uuidGen } from 'uuid';


const getId = (type) => `${type.charAt(0).toUpperCase()}_${uuidGen()}`;

export const useFlow = () => {
  const dispatch = useDispatch();
  const reactFlowWrapper = useRef(null);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);

  const onDrop = useCallback((event) => {
    event.preventDefault();

    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
    const type = event.dataTransfer.getData('application/reactflow');

    // check if the dropped element is valid
    if (!type) {
      return;
    }

    const position = reactFlowInstance.project({
      x: event.clientX - reactFlowBounds.left,
      y: event.clientY - reactFlowBounds.top,
    });

    let newNode = {
      id: getId(type),
      type,
      position,
      data: { label: `${type}` },
    };

    if (type === NODES_TYPE.question) {
      newNode = {
        ...newNode,
        data: { ...newNode.data, referenceID: '' },
      };
    }

    dispatch(addTemporaryNodeToFlow(newNode));
  }, [dispatch, reactFlowInstance]);

  return {
    reactFlowWrapper,
    setReactFlowInstance,
    onDrop,
  };
};
