import { axiosInstance } from 'utils/http';
import { dataCredential } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation, useQuery } from 'react-query';
import { useState } from 'react';


const useCreateDataKeys = () => {
  const dispatch = useDispatch();
  const orgId = useSelector(state => state.session.user.primaryOrganization.organization.ID);
  const [data, setData] = useState();
  const {
    isLoading, isError, isSuccess, mutate,
  } = useMutation(
    async ({ credentialID }) => axiosInstance.post(
      dataCredential(orgId).generateSecret(credentialID),
    ),
    {
      onSuccess: (res) => {
        setData(res.data);
        dispatch(showAlert({
          message: 'Success',
          type: 'success',
        }));
      },
      onError: (err) => dispatch(showAlert({
        message: err.response.data.message,
        type: 'error',
      })),
    },
  );
  return {
    data,
    isError,
    isLoading,
    isSuccess,
    mutate,
  };
};

/**
 * Fetch credentials belongs to given organization
 * @param {string} credentialSourceName The name of the credential source.
 * @returns Object of type react-query.
 */
const useFetchOrgCredentials = (credentialSourceName) => {
  const dispatch = useDispatch();
  const orgId = useSelector(state => state.session.user.primaryOrganization.organization.ID);
  const {
    data, isLoading, isFetching, isError, isSuccess,
  } = useQuery(
    ['OrgCredentials', credentialSourceName],
    async () => axiosInstance.get(dataCredential(orgId).listOrgCredentials({
      credentialSourceName,
      includeCredential: true,
    })),
    {
      enabled: Boolean(orgId && credentialSourceName),
      refetchOnWindowFocus: false,
      retry: false,
      onError: (err) => dispatch(showAlert({
        message: err.response.data.message,
        type: 'error',
      })),
    },
  );

  return {
    data: data?.data?.credentials || [],
    isLoading,
    isFetching,
    isError,
    isSuccess,
  };
};


export { useCreateDataKeys, useFetchOrgCredentials };
