import { CLEAN_ROOM } from 'redux/actions/Types';


const initialState = {
  summaries: [],
  summariesLoading: false,
};
const CleanRoomsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAN_ROOM.ADD_CR_USER_OPEN_MODAL:
      return { ...state, addUser: undefined };
    case CLEAN_ROOM.ADD_CR_USER:
      return { ...state, addUser: { adding: true } };
    case CLEAN_ROOM.ADD_CR_USER_ERROR:
      return { ...state, addUser: { error: action.payload } };
    case CLEAN_ROOM.ADD_CR_USER_SUCCESS:
      return { ...state, addUser: { added: true } };
    case CLEAN_ROOM.ADD_QUESTION_ERROR:
      return { ...state, addQuestion: { error: action.payload } };
    case CLEAN_ROOM.ADD_QUESTION_SUCCESS:
      return { ...state, addQuestion: { success: true } };
    case CLEAN_ROOM.CREATE_CR:
      return { ...state, detail: undefined, isSaved: false };
    case CLEAN_ROOM.CREATE_CR_DATASET:
      return { ...state, meta: { loading: true } };
    case CLEAN_ROOM.CREATE_CR_DATASET_SUCCESS:
      return { ...state, meta: { redirect: true } };
    case CLEAN_ROOM.CREATE_CR_DATASET_ERROR:
      return { ...state, meta: { redirect: false, loading: false } };
    case CLEAN_ROOM.CREATE_NEW_RUN:
      return { ...state, crRun: { loading: true } };
    case CLEAN_ROOM.CREATE_NEW_RUN_ERROR:
      return { ...state, crRun: { error: action.payload } };
    case CLEAN_ROOM.CREATE_NEW_RUN_RESET:
      return { ...state, crRun: undefined };
    case CLEAN_ROOM.CREATE_NEW_RUN_SUCCESS:
      return { ...state, crRun: action.payload };
    case CLEAN_ROOM.DELETE_CR_QUESTION:
      return { ...state, meta: { deleting: true, deleted: false } };
    case CLEAN_ROOM.DELETE_CR_QUESTION_SUCCESS:
      return { ...state, meta: { deleting: false, deleted: true } };
    case CLEAN_ROOM.DELETE_CR_QUESTION_ERROR:
      return { ...state, meta: { deleteError: action.payload, deleting: false } };
    case CLEAN_ROOM.DELETE_CR_QUESTION_RUN:
      return { ...state, meta: { deleting: true, deleted: false } };
    case CLEAN_ROOM.DELETE_CR_QUESTION_RUN_SUCCESS:
      return { ...state, meta: { deleting: false, deleted: true } };
    case CLEAN_ROOM.DELETE_CR_QUESTION_RUN_ERROR:
      return { ...state, meta: { deleteError: action.payload, deleting: false } };
    case CLEAN_ROOM.UPDATE_CR_DATASET:
      return { ...state, meta: { loading: true } };
    case CLEAN_ROOM.UPDATE_CR_DATASET_SUCCESS:
      return { ...state, meta: { redirect: true } };
    case CLEAN_ROOM.UPDATE_CR_DATASET_ERROR:
      return { ...state, meta: { redirect: false, loading: false } };
    case CLEAN_ROOM.UPDATE_CR_QUESTION_DESCRIPTION_ERROR:
      return { ...state, crqDescription: null };
    case CLEAN_ROOM.UPDATE_CR_QUESTION_DESCRIPTION_SUCCESS:
      return { ...state, crqDescription: action.payload, meta: { update: true } };
    case CLEAN_ROOM.FETCH_AND_CACHE_CR_DATASET_SUCCESS:
      return {
        ...state,
        cachedDataset: {
          ...state.cachedDataset,
          [action.payload.ID]: action.payload,
        },
      };
    case CLEAN_ROOM.FETCH_CR_DATASET_SUCCESS:
      return { ...state, dataset: action.payload, meta: {} };
    case CLEAN_ROOM.RESET_CR_DATASET_STATE:
      return { ...state, dataset: action.payload };
    case CLEAN_ROOM.FETCH_CR_QUESTION:
      return { ...state, crQuestion: undefined, meta: {} };
    case CLEAN_ROOM.FETCH_CR_QUESTION_V2:
      return { ...state, crQuestion: undefined, meta: {} };
    case CLEAN_ROOM.FETCH_CR_QUESTION_SUCCESS:
      return { ...state, crQuestion: action.payload, meta: {} };
    case CLEAN_ROOM.FETCH_CR_QUESTION_ERROR:
      return { ...state, crQuestion: { error: action.payload } };
    case CLEAN_ROOM.FETCH_CR:
      return {
        ...state,
        detail: undefined,
        userPerm: undefined,
        isSaved: false,
        detailMeta: { loading: true },
      };
    case CLEAN_ROOM.FETCH_CR_ERROR:
      return { ...state, detail: { error: action.payload }, detailMeta: {} };
    case CLEAN_ROOM.FETCH_CR_SUCCESS:
      return {
        ...state,
        detail: action.payload.cleanRoom,
        userDetail: action.payload.cleanRoomUser,
        type: action.payload.cleanRoomType,
        detailMeta: {},
      };
    case CLEAN_ROOM.FETCH_CR_RUN_DETAILS:
      return { ...state, crRunDetail: undefined };
    case CLEAN_ROOM.FETCH_CR_RUN_DETAILS_SUCCESS:
      return { ...state, crRunDetail: action.payload };
    case CLEAN_ROOM.FETCH_CR_RUN_DETAILS_ERROR:
      return { ...state, crRunDetail: { error: action.payload } };
    case CLEAN_ROOM.FETCH_CR_LIST_ERROR:
      return { ...state, list: { error: action.payload } };
    case CLEAN_ROOM.FETCH_CR_LIST_SUCCESS:
      return { ...state, list: action.payload };
    case CLEAN_ROOM.FETCH_CR_ORG_USER_LIST_ERROR:
      return { ...state, orgUsersList: { error: action.payload } };
    case CLEAN_ROOM.FETCH_CR_ORG_USER_LIST_SUCCESS:
      return { ...state, orgUsersList: action.payload };
    case CLEAN_ROOM.FETCH_CR_LIST_FILTERS:
      return { ...state, listFilters: undefined };
    case CLEAN_ROOM.FETCH_CR_LIST_FILTERS_ERROR:
      return { ...state, listFilters: { error: action.payload } };
    case CLEAN_ROOM.FETCH_CR_LIST_FILTERS_SUCCESS:
      return { ...state, listFilters: action.payload };
    case CLEAN_ROOM.FETCH_CR_ALL_QUESTIONS_ERROR:
      return { ...state, allQuestions: { error: action.payload } };
    case CLEAN_ROOM.FETCH_CR_ALL_QUESTIONS_SUCCESS:
      return { ...state, allQuestions: action.payload, meta: {} };
    case CLEAN_ROOM.FETCH_CR_CURRENT_QUESTIONS:
      return { ...state, currentQuestions: { loading: true } };
    case CLEAN_ROOM.FETCH_CR_CURRENT_QUESTIONS_ERROR:
      return { ...state, currentQuestions: { error: action.payload } };
    case CLEAN_ROOM.FETCH_CR_CURRENT_QUESTIONS_SUCCESS:
      return { ...state, currentQuestions: action.payload };
    case CLEAN_ROOM.FETCH_CR_DATASET_GROUPS_ERROR:
      return { ...state, datasetGroups: { error: action.payload } };
    case CLEAN_ROOM.FETCH_CR_DATASET_GROUPS_SUCCESS:
      return { ...state, datasetGroups: action.payload, meta: {} };
    case CLEAN_ROOM.FETCH_CR_QUESTION_DATASETS_ERROR:
      return { ...state, questionDatasets: { error: action.payload } };
    case CLEAN_ROOM.FETCH_CR_QUESTION_DATASETS_SUCCESS:
      return { ...state, questionDatasets: action.payload };
    case CLEAN_ROOM.RESET_CR_QUESTION_DATASETS_STATE:
      return { ...state, questionDatasets: [] };
    case CLEAN_ROOM.FETCH_CR_QUESTION_DATA_OPTIONS_SUCCESS:
      return { ...state, dataOptions: action.payload };
    case CLEAN_ROOM.FETCH_CR_USERS_ERROR:
      return { ...state, users: { error: action.payload } };
    case CLEAN_ROOM.FETCH_CR_USERS_SUCCESS:
      return { ...state, users: action.payload };
    case CLEAN_ROOM.FETCH_CR_REPORT_FILTERS:
      return { ...state, reportFilters: { loading: true } };
    case CLEAN_ROOM.FETCH_CR_REPORT_FILTERS_ERROR:
      return { ...state, reportFilters: { error: action.payload } };
    case CLEAN_ROOM.FETCH_CR_REPORT_FILTERS_SUCCESS:
      return { ...state, reportFilters: action.payload };
    case CLEAN_ROOM.GET_CR_RUN_REPORT:
      return { ...state, crRunReport: { loading: true } };
    case CLEAN_ROOM.GET_CR_RUN_REPORT_ERROR:
      return { ...state, crRunReport: { error: action.payload } };
    case CLEAN_ROOM.GET_CR_RUN_REPORT_SUCCESS:
      return { ...state, crRunReport: action.payload };
    case CLEAN_ROOM.GET_CR_RUN_TABLE_REPORT:
      return { ...state, crFullReport: { loading: true } };
    case CLEAN_ROOM.GET_CR_RUN_TABLE_REPORT_ERROR:
      return { ...state, crFullReport: { error: action.payload } };
    case CLEAN_ROOM.GET_CR_RUN_TABLE_REPORT_SUCCESS:
      return { ...state, crFullReport: action.payload };
    case CLEAN_ROOM.GET_DP_BUDGET_AND_QUERIES:
      return { ...state };
    case CLEAN_ROOM.GET_DP_BUDGET_AND_QUERIES_SUCCESS:
      return {
        ...state,
        budgetUnitsFromApi: action.payload.budget,
        numQueriesFromApi: action.payload.numQueries,
      };
    case CLEAN_ROOM.GET_DP_BUDGET_AND_QUERIES_ERROR:
      return { ...state, budgetUnitsFromApi: { error: action.payload } };
    case CLEAN_ROOM.FETCH_QUESTION_RUN:
      return { ...state, crQuestionRun: { loading: true } };
    case CLEAN_ROOM.FETCH_QUESTION_RUN_ERROR:
      return { ...state, crQuestionRun: { error: action.payload } };
    case CLEAN_ROOM.FETCH_QUESTION_RUN_SUCCESS:
      return { ...state, crQuestionRun: action.payload };
    case CLEAN_ROOM.FETCH_SCHEDULED_RUN:
      return { ...state, scheduledRun: undefined, scheduledRunMeta: { loading: true } };
    case CLEAN_ROOM.FETCH_SCHEDULED_RUN_SUCCESS:
      return { ...state, scheduledRun: action.payload, scheduledRunMeta: {} };
    case CLEAN_ROOM.FETCH_SCHEDULED_RUN_ERROR:
      return { ...state, scheduledRun: { error: action.payload }, scheduledRunMeta: {} };
    case CLEAN_ROOM.FETCH_SCHEDULED_RUNS:
      return { ...state, scheduledRuns: undefined };
    case CLEAN_ROOM.FETCH_SCHEDULED_RUNS_SUCCESS:
      return { ...state, scheduledRuns: action.payload };
    case CLEAN_ROOM.FETCH_SCHEDULED_RUNS_ERROR:
      return { ...state, scheduledRuns: { error: action.payload } };
    case CLEAN_ROOM.GET_CR_RUN_FILES:
      return { ...state, crRunFiles: { loading: true } };
    case CLEAN_ROOM.GET_CR_RUN_FILES_ERROR:
      return { ...state, crRunFiles: { error: action.payload } };
    case CLEAN_ROOM.GET_CR_RUN_FILES_SUCCESS:
      return { ...state, crRunFiles: action.payload };
    case CLEAN_ROOM.GET_CR_RUN_FILE_PREVIEW:
      return { ...state, crRunFile: undefined };
    case CLEAN_ROOM.GET_CR_RUN_FILE_PREVIEW_ERROR:
      return { ...state, crRunFile: { error: action.payload } };
    case CLEAN_ROOM.GET_CR_RUN_FILE_PREVIEW_SUCCESS:
      return { ...state, crRunFile: action.payload };
    case CLEAN_ROOM.GET_CR_RUNS:
      return { ...state, crRunList: { isLoading: true }, meta: {} };
    case CLEAN_ROOM.GET_CR_RUNS_ERROR:
      return { ...state, crRunList: { error: action.payload } };
    case CLEAN_ROOM.GET_CR_RUNS_SUCCESS:
      return { ...state, crRunList: action.payload };
    case CLEAN_ROOM.FETCH_CR_RUNS_FILTERS_ERROR:
      return { ...state, crRunListFilters: { error: action.payload } };
    case CLEAN_ROOM.FETCH_CR_RUNS_FILTERS_SUCCESS:
      return { ...state, crRunListFilters: action.payload };
    case CLEAN_ROOM.REMOVE_CR_USER:
      return { ...state, meta: { removing: true } };
    case CLEAN_ROOM.REMOVE_CR_USER_ERROR:
      return { ...state, meta: { removeError: action.payload, removing: false } };
    case CLEAN_ROOM.REMOVE_CR_USER_SUCCESS:
      return { ...state, meta: { removed: true } };
    case CLEAN_ROOM.SAVE_CR:
      return {
        ...state, isSaved: false, isSaveInProgress: true, detail: {},
      };
    case CLEAN_ROOM.SAVE_CR_ERROR:
      return { ...state, isSaveInProgress: false };
    case CLEAN_ROOM.SAVE_CR_SUCCESS:
      return {
        ...state, detail: action.payload, isSaved: true, isSaveInProgress: false,
      };
    case CLEAN_ROOM.RESET_CR:
      return {
        ...state, detail: {}, isSaved: false, isSaveInProgress: false,
      };
    case CLEAN_ROOM.UPDATE_CR_STATUS:
      return { ...state, crStatus: undefined };
    case CLEAN_ROOM.UPDATE_CR_STATUS_ERROR:
      return { ...state, crStatus: action.payload };
    case CLEAN_ROOM.UPDATE_CR_USER:
      return { ...state, meta: { updating: true } };
    case CLEAN_ROOM.UPDATE_CR_USER_ERROR:
      return { ...state, meta: { updateError: action.payload, updating: false } };
    case CLEAN_ROOM.UPDATE_CR_USER_SUCCESS:
      return { ...state, meta: { updated: true } };
    case CLEAN_ROOM.UPDATE_CR_QUESTION_DATASETS:
      if (state?.meta?.saving) {
        return state;
      }

      return { ...state, meta: { saving: true } };
    case CLEAN_ROOM.UPDATE_CR_QUESTION_DATASETS_SUCCESS:
      return { ...state, questionDatasets: action.payload, meta: { saved: true } };
    case CLEAN_ROOM.UPDATE_CR_QUESTION_DATASETS_ERROR:
      return { ...state, meta: { error: action.payload } };
    case CLEAN_ROOM.FETCH_CR_SUMMARY:
      return {
        ...state,
        summaries: [],
        summariesLoading: true,
      };
    case CLEAN_ROOM.FETCH_CR_SUMMARY_SUCCESS:
      return {
        ...state,
        summaries: action.payload,
        summariesLoading: false,
      };
    case CLEAN_ROOM.FETCH_CR_SUMMARY_ERROR:
      return { ...state, error: action.payload, summariesLoading: false };
    case CLEAN_ROOM.FETCH_QUESTION_DETAILS:
      return { ...state, questionDetails: { loading: true } };
    case CLEAN_ROOM.FETCH_QUESTION_DETAILS_ERROR:
      return { ...state, questionDetails: { error: action.payload } };
    case CLEAN_ROOM.FETCH_QUESTION_DETAILS_SUCCESS:
      return { ...state, questionDetails: action.payload };
    case CLEAN_ROOM.RESET_REPORTS:
      return {
        ...state, questionDetails: undefined, crRunReport: undefined, reportFilters: undefined,
      };
    case CLEAN_ROOM.VERIFY_PARTNER_EMAIL:
      return { ...state, partnerEmail: { loading: true } };
    case CLEAN_ROOM.VERIFY_PARTNER_EMAIL_SUCCESS:
      return { ...state, partnerEmail: action.payload };
    case CLEAN_ROOM.VERIFY_PARTNER_EMAIL_ERROR:
      return { ...state, partnerEmail: { error: action.payload } };
    case CLEAN_ROOM.VERIFY_PARTNER_KEY:
      return { ...state, partnerOrganization: { loading: true } };
    case CLEAN_ROOM.VERIFY_PARTNER_KEY_SUCCESS:
      return { ...state, partnerOrganization: action.payload };
    case CLEAN_ROOM.VERIFY_PARTNER_KEY_ERROR:
      return { ...state, partnerOrganization: { error: action.payload } };
    default:
      return state;
  }
};

export default CleanRoomsReducer;
