import AppHeader from 'components/Layouts/Header/AppHeader';
import Box from '@mui/material/Box';
import React from 'react';
import SideNavBar from 'components/Layouts/SideNav/SideNavBar';
import Theme from 'Theme';
import { CACHE } from 'utils/appConstants';
import { StyledEngineProvider } from '@mui/styled-engine';
import { ThemeProvider } from '@mui/material/styles';


// TODO: Rework on SideNavBar and CrSideNavBar so they don't depend on the theme as parameter
const NavbarLayout = (
  BaseComponent,
  Header = AppHeader,
  CustomTheme = Theme,
  SideNavigationBar = SideNavBar,
  showAlphaBanner = false,
) => props => {
  const impersonator = localStorage.getItem(CACHE.impersonation.impersonator);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={CustomTheme}>
        <Box display='flex'>
          <Header {...props} />
          <SideNavigationBar {...props} />
          <Box
            component='main'
            sx={{
              flexGrow: 1,
              minHeight: '100vh',
              overflowX: 'auto',
              px: '1rem',
              pt: impersonator ? '7rem' : '6rem',
              pb: showAlphaBanner ? '4rem' : '3rem',
            }}
          >
            <BaseComponent {...props} />
          </Box>
        </Box>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default NavbarLayout;
