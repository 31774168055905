import http from 'utils/http';
import { QUESTION_MANAGEMENT } from 'redux/actions/Types';
import { SyntheticRedux } from 'synthetic-redux';
import { all } from 'redux-saga/effects';
import { combineReducers } from 'redux';
import { questionManagement } from 'utils/urlConstants';


const fetchOrgAvailableQuestions = new SyntheticRedux(
  { type: QUESTION_MANAGEMENT.FETCH_ORG_AVAILABLE_QUESTIONS }, 'list',
);

fetchOrgAvailableQuestions.ignite = (orgId, accId, payload) => fetchOrgAvailableQuestions
  .actions.ignite({
    errorMeta: 'Question Management : Fetch Org Available List',
    method: http.post,
    payload,
    url: questionManagement.orgAvailableQuestions(accId, orgId),
  });

const fetchOrgListFilters = new SyntheticRedux(
  { type: QUESTION_MANAGEMENT.FETCH_ORG_LIST_FILTERS }, 'list',
);

fetchOrgListFilters.ignite = (accId, orgId) => fetchOrgListFilters.actions.ignite({
  errorMeta: 'Question Management : Fetch Org Filters List',
  method: http.get,
  url: questionManagement.orgListFilters(accId, orgId),
});

const fetchQuestionDetail = new SyntheticRedux(
  { type: QUESTION_MANAGEMENT.FETCH_QUESTION_DETAILS }, 'response',
);

fetchQuestionDetail.ignite = (orgId, qId) => fetchQuestionDetail.actions.ignite(
  {
    errorMeta: 'Question Management : Fetch Question Details',
    method: http.get,
    url: questionManagement.questionDetails(orgId, qId),
  },
);

const fetchOrgProvisionedQuestions = new SyntheticRedux(
  { type: QUESTION_MANAGEMENT.FETCH_ORG_PROVISIONED_QUESTIONS }, 'list',
);

fetchOrgProvisionedQuestions.ignite = (orgId, accId, payload) => fetchOrgProvisionedQuestions
  .actions.ignite({
    errorMeta: 'Question Management : Fetch Org Provisioned List',
    method: http.post,
    payload,
    url: questionManagement.orgProvisionedQuestions(accId, orgId),
  });

const fetchProvisionedQuestionAccAndOrg = new SyntheticRedux(
  { type: QUESTION_MANAGEMENT.FETCH_PROVISIONED_QUESTION_ACC_AND_ORG }, 'list',
);

fetchProvisionedQuestionAccAndOrg.ignite = (qId) => fetchProvisionedQuestionAccAndOrg
  .actions.ignite({
    errorMeta: 'Question Management : Fetch Provisioned Question Account & Organization List',
    method: http.get,
    url: questionManagement.provisionedQuestionAccAndOrg(qId),
  });

const provisionOrgQuestions = new SyntheticRedux(
  { type: QUESTION_MANAGEMENT.PROVISION_ORG_QUESTIONS }, 'response',
);

provisionOrgQuestions.ignite = (accId, orgId, payload) => provisionOrgQuestions
  .actions.ignite({
    errorMeta: 'Question Management : Provision Organization Question',
    method: http.post,
    payload,
    url: questionManagement.provisionOrgQuestions(accId, orgId),
  });

const provisionAccountQuestions = new SyntheticRedux(
  { type: QUESTION_MANAGEMENT.PROVISION_PLATFORM_QUESTIONS }, 'response',
);

provisionAccountQuestions.ignite = (accId, payload) => provisionAccountQuestions
  .actions.ignite({
    errorMeta: 'Question Management : Provision to Account Question',
    method: http.post,
    payload,
    url: questionManagement.provisionAccQuestions(accId),
  });

const deprovisionQuestion = new SyntheticRedux(
  { type: QUESTION_MANAGEMENT.DEPROVISION_QUESTION }, 'response',
);

deprovisionQuestion.ignite = (entityType, enityId, questionID) => deprovisionQuestion
  .actions.ignite({
    errorMeta: `Question Management : Deprovision Question ${questionID}`,
    method: http.put,
    url: questionManagement.deprovisionQuestion(entityType, enityId, questionID),
  });


const synQuestionManagementReducer = combineReducers({
  deprovisionQuestion: deprovisionQuestion.reducer,
  orgAvailableQList: fetchOrgAvailableQuestions.reducer,
  orgProvisionedQList: fetchOrgProvisionedQuestions.reducer,
  orgListFilters: fetchOrgListFilters.reducer,
  provisionedQuestionAccAndOrg: fetchProvisionedQuestionAccAndOrg.reducer,
  provisionOrgQuestions: provisionOrgQuestions.reducer,
  provisionAccountQuestions: provisionAccountQuestions.reducer,
  questionDetail: fetchQuestionDetail.reducer,
});

function* questionManagementSaga() {
  yield all([
    deprovisionQuestion.sagaEffect,
    fetchOrgAvailableQuestions.sagaEffect,
    fetchOrgListFilters.sagaEffect,
    fetchOrgProvisionedQuestions.sagaEffect,
    fetchProvisionedQuestionAccAndOrg.sagaEffect,
    fetchQuestionDetail.sagaEffect,
    provisionAccountQuestions.sagaEffect,
    provisionOrgQuestions.sagaEffect,
  ]);
}

export {
  deprovisionQuestion,
  fetchOrgAvailableQuestions,
  fetchOrgListFilters,
  fetchOrgProvisionedQuestions,
  fetchProvisionedQuestionAccAndOrg,
  fetchQuestionDetail,
  provisionAccountQuestions,
  provisionOrgQuestions,
  questionManagementSaga,
  synQuestionManagementReducer,
};

