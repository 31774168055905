import React from 'react';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import {
  InputAdornment, TextField,
} from '@mui/material';


const HSearchComponent = ({
  placeholder = 'Search',
  variant = 'outlined',
  onSearch,
  sx = {},
  ...restProps
}) => (
  <TextField
    {...restProps}
    size='small'
    fullWidth
    placeholder={placeholder}
    autoComplete='off'
    InputProps={{
      startAdornment: (
        <InputAdornment position='start'>
          <SearchRoundedIcon fontSize='medium' />
        </InputAdornment>
      ),
    }}
    onChange={onSearch ? e => onSearch(e.target.value) : undefined}
    variant={variant}
    sx={{
      alignSelf: 'flex-end',
      ...sx,
    }}
  />
);

export default HSearchComponent;
