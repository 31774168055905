import { activeOrgSelector } from 'redux/reducer/AuthenticationReducer';
import { axiosInstance } from 'utils/http';
import { dataCredential } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation, useQueryClient } from 'react-query';


export const useUpdateIdentityCredential = (credId) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { ID: orgId } = useSelector(activeOrgSelector);

  return useMutation(
    (payload) => {
      if (!credId) {
        return Promise.reject(new Error('Credential ID is required'));
      }
      return axiosInstance.put(dataCredential(orgId).updateOrgCredSource(credId), payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['identityCredentials', orgId]);
        dispatch(showAlert({
          message: 'Identity credential updated successfully',
          type: 'success',
        }));
      },
      onError: () => {
        dispatch(showAlert({
          message: 'Failed to update identity credential',
          type: 'error',
        }));
      },
    },
  );
};
