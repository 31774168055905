import Box from '@mui/material/Box';
import React from 'react';
import { NODES_OBJECT } from 'pages/Flow/contacts';
import { flowPalette } from 'pages/Flow/HelpFlow/contacts';
import { styled } from '@mui/material/styles';



const StyledIconContainer = styled(Box)(({ theme, type }) => ({
  alignItems: 'center',
  background: type ? flowPalette(theme, type).backgroundColor : theme.palette.grey[400],
  border: type ? `0.8px solid ${flowPalette(theme, type).borderColor}`
    : `0.8px solid ${theme.palette.grey[400]}`,
  borderRadius: '1.6px',
  display: 'flex',
  gap: 8,
  height: 45,
  justifyContent: 'center',
  padding: 8,
  width: 45,
}));
const FlowNodeIconSmall = ({ type }) => (
  <StyledIconContainer type={type}>
    {NODES_OBJECT[type].Icon}
  </StyledIconContainer>
);

export default FlowNodeIconSmall;
