import Checkbox from '@mui/material/Checkbox';
import React, {
  memo,
  useContext,
} from 'react';
import { DataContext } from 'BaseComponents/HDataTable/DataContextProvider';


export const HSelectBodyCell = memo(({ row }) => {
  const {
    handleOnSelectRow,
    selectedList,
    multiSelectField,
    multiSelect,
  } = useContext(DataContext);

  if (!multiSelect) return null;

  return (
    <td>
      <Checkbox
        checked={Boolean(selectedList[row[multiSelectField]])}
        onChange={e => handleOnSelectRow(e.target.checked, [row], 'SINGLE_ROW')}
      />
    </td>
  );
});

HSelectBodyCell.displayName = 'HSelectBodyCell';
