import { CACHE } from './appConstants';


const oneHr = 3600000;

const store = {
  clear: () => localStorage.clear(),
  delete: key => localStorage.removeItem(key),
  get: key => localStorage.getItem(key),
  getAsJson: (key) => {
    const value = store.get(key);
    try {
      return JSON.parse(value);
    }
    catch (e) {
      return null;
    }
  },
  isExpired: lastUpdatedAt => ((Date.now() - lastUpdatedAt) / oneHr) > CACHE.expiry,
  isTokenExpired: (valueMs) => Date.now() - valueMs > 0,
  set: (key, value) => {
    try { localStorage.setItem(key, value); }
    catch (e) {
      /*
        This usually occurs when the storage is full
        Reduce the risk of serving stale data --> ClearAll!
      */
      this.clear();
    }
  },
};

export default store;
