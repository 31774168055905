import pythonIcon from 'assets/query-languages/python.png';
import sqlIcon from 'assets/query-languages/sql.png';
import {
  ChartSubTypeMap, CleanRoomTypes,
  InputTypesMap,
  StorageProvidersMap,
} from 'components/CleanRooms/constants';
import { snakeToDisplayString } from 'utils/jsUtils';


export const AGGREGATIONS = {
  SUM: { key: 'SUM', value: 'Sum' },
  AVG: { key: 'AVG', value: 'Avg' },
};

export const ATTRIBUTE_TYPES = {
  DATE: { key: 'DATE', value: 'Date' },
  DECIMAL: { key: 'DECIMAL', value: 'Decimal' },
  DOUBLE: { key: 'DOUBLE', value: 'Double' },
  INTEGER: { key: 'INTEGER', value: 'Integer' },
  LONG: { key: 'LONG', value: 'Long' },
  STRING: { key: 'STRING', value: 'String' },
  TIMESTAMP: { key: 'TIMESTAMP', value: 'Timestamp' },
};

export const ATTRIBUTE_FIELDS = {
  name: '', displayName: '', type: '', value: '',
};

export const OUTPUT_FIELDS = {
  ...ATTRIBUTE_FIELDS, isEncrypt: false,
};

export const CHART_TYPES = {
  AREA: { key: 'AREA', value: 'Area' },
  BAR: { key: 'BAR', value: 'Bar' },
  // COLUMN: { key: 'COLUMN', value: 'Column' },
  LINE: { key: 'LINE', value: 'Line' },
};

export const DATASET_TYPE_FIELDS = { ID: '', importDataType: {}, optional: false };

export const START_DATE_PARAMETER = { name: 'START_DATE', displayName: '', type: 'DATE' };

export const END_DATE_PARAMETER = { name: 'END_DATE', displayName: '', type: 'DATE' };

export const DIMENSION_FIELDS = {
  name: '',
  displayName: '',
  filterable: false,
  filterType: null,
  plottable: false,
  type: '',
};

export const VISUALIZATION_TYPES = {
  BAR_LINE_CHART: { key: 'BAR_LINE_CHART', value: 'Line Bar Chart' },
  HORIZONTAL_BAR_CHART: { key: 'HORIZONTAL_BAR_CHART', value: 'Grouped Bar Chart(Horizontal)' },
  NO_CHART: { key: 'NO_CHART', value: 'No Chart' },
  NO_DIMENSION_BAR_CHART: { key: 'NO_DIMENSION_BAR_CHART', value: 'No Dimension Bar Chart' },
  TREEMAP_CHART: { key: 'TREEMAP_CHART', value: 'Tree Map Chart' },
  VERTICAL_BAR_CHART: { key: 'VERTICAL_BAR_CHART', value: 'Grouped Bar Chart' },
  VERTICAL_BAR_LINE: { key: 'VERTICAL_BAR_LINE', value: 'One Bar Chart with one Line' },
  VERTICAL_GROUPED_BAR_LINE_CHART: { key: 'VERTICAL_GROUPED_BAR_LINE_CHART', value: 'Grouped Bar with one Line' },
};

export const EMPTY_QUESTION = {
  category: '',
  dataTypes: [],
  description: '',
  dimensions: [],
  hasDateFilter: false,
  hasDateParameters: false,
  isAggregate: false,
  isUserListQuestion: false,
  parameters: [],
  outputFormat: 'REPORT',
  queryTemplates: [],
  metrics: [],
  storageProvider: '',
  tags: [],
  title: '',
  visualization: VISUALIZATION_TYPES.BAR_LINE_CHART.key,
  postRunQueries: [],
  queryLanguageID: '',
};

export const DESCRIPTION_TYPES = {
  BUSINESS: { key: 'BUSINESS', label: 'Business' },
  DATA_SCIENCE: { key: 'DATA_SCIENCE', label: 'Data Science' },
  TECHNICAL: { key: 'TECHNICAL', label: 'Technical' },
};

export const OUTPUT_FORMAT = {
  REPORT: 'REPORT',
  OUTPUT: 'CODE_OUTPUT',
  DATA_LOCATION: 'DATA_LOCATION',
};

export const getOutputFormatOptions = Object.keys(OUTPUT_FORMAT)
  .map(k => ({ name: snakeToDisplayString(k), value: OUTPUT_FORMAT[k] }));

export const COMPUTE_CAPACITY = {
  DEFAULT: {
    name: 'Default',
    value: 'DEFAULT',
    subtitle: 'Default processing capacity (recommended)',
    description: 'Default processing capacity is appropriate for the vast majority of queries and datasets.',
  },
  MEDIUM: {
    name: 'Medium',
    value: 'MEDIUM',
    subtitle: 'Medium processing capacity',
    description: 'Medium capacity is only required for Questions that are not satisfied by the default setting or for complex queries.',
  },
  LARGE: {
    name:
      'Large',
    value: 'LARGE',
    subtitle: 'Large processing capacity',
    description: 'Large capacity is only required for Questions that are not satisfied by the default setting / exceptionally complex queries and large record counts (1+ billion).',
  },
  XLARGE: {
    name: 'Extra Large',
    value: 'XLARGE',
    subtitle: 'Extra Large processing capacity',
    description: 'Extra large capacity is only required for Questions that are not satisfied by the Large setting / exceptionally complex queries and very large record counts (10+ billion).',
  },
};

export const CLEAN_ROOMS_WITH_EXTENDED_CAPACITY = [
  CleanRoomTypes.HYBRID_V2_CLEAN_ROOM,
  CleanRoomTypes.HABU_CONFIDENTIAL_COMPUTING_CLEAN_ROOM,
  CleanRoomTypes.LINKEDIN_CLEAN_ROOM,
];

export const DEFAULT_QUESTION = {
  title: '',
  category: '',
  hasDateFilter: false,
  visualization: VISUALIZATION_TYPES.BAR_LINE_CHART.key,
  parameters: [],
  dataTypes: [],
  dimensions: [],
  metrics: [],
  queryTemplates: [],
  storageProvider: '',
  outputFormat: OUTPUT_FORMAT.REPORT,
  descriptions: [{
    type: DESCRIPTION_TYPES.BUSINESS.key, description: '',
  }, {
    type: DESCRIPTION_TYPES.DATA_SCIENCE.key, description: '',
  }, {
    type: DESCRIPTION_TYPES.TECHNICAL.key, description: '',
  }],
  isAggregate: false,
  hasDateParameters: false,
  isUserListQuestion: false,
  postRunQueries: [],
  queryLanguageID: '',
  queryPackages: [],
  cleanRoomTypes: [],
  queryDetails: [],
  tags: [],
  purpose: [],
  userListOutputs: [],
  computeCapacity: COMPUTE_CAPACITY.DEFAULT.value,
  hasMultipleOutputs: false,
};

export const FILTER_TYPES = {
  MULTI_SELECT: { key: 'MULTI_SELECT', value: 'Multi Select' },
  SINGLE_SELECT: { key: 'SINGLE_SELECT', value: 'Single Select' },
};

export const MEASURE_FIELDS = {
  name: '',
  displayName: '',
  computed: false,
  type: '',
  value: '',
  metricConfig: {
    yAxisPosition: null,
    aggregateOperator: null,
    valueFormatting: '',
    tabular: false,
    isIdentity: false,
    calculated: false,
    calculationFormula: '',
    chartType: null,
    noise: false,
  },
};

export const Q_PROVISION_LEVEL = {
  PLATFORM: 'PLATFORM',
  ACC: 'ACCOUNT',
  ORG: 'ORGANIZATION',
  CR: 'CLEAN_ROOM',
  FLOW: 'FLOW',
};

export const QUESTION_CATEGORIES = {
  ANALYTICS: { key: 'ANALYTICS', value: 'Analytical', description: 'Aggregated data and result in reports & visualizations.' },
  USER_LIST: { key: 'USER_LIST', value: 'List', description: 'Record-level data and cannot be seen in the platform.' },
};

export const QUESTION_TYPES = {
  BIGQUERY: { key: 'BIGQUERY', value: 'Google ADH' },
  FACEBOOK_AA: { key: 'FACEBOOK_AA', value: 'Facebook Advanced Analytics' },
  S3_STANDARD: { key: 'S3_STANDARD', value: 'Amazon Marketing Cloud' },
  HYBRID_CR: { key: 'SNOWFLAKE', value: 'Hybrid Clean Room' },
  SNOWFLAKE_CR: { key: 'SNOWFLAKE_CR', value: 'Snowflake Clean Room' },
  LINKEDIN_AZURE: { key: 'LINKEDIN_AZURE', value: 'LinkedIn Marketing Solution' },
  DATABRICKS_CR: { key: 'DATABRICKS_CR', value: 'Databricks Clean Room' },
  BIGQUERY_CR: { key: 'BIGQUERY_CR', value: 'Google Big Query Clean Room' },
};

export const QUESTION_STATUS = {
  STAGE: { key: 'IS_DRAFT', value: 'Stage' },
  PUBLISHED: { key: 'IS_PUBLISHED', value: 'Published' },
};

export const DISPLAY_Q_AGGREGATOR_OPERATOR = {
  ...AGGREGATIONS,
  AGGREGATE_OPERATOR_UNKNOWN: { key: 'AGGREGATE_OPERATOR_UNKNOWN', label: '-' },
  NONE: { key: 'NONE', label: 'None' },
};

export const DISPLAY_Q_CHART_TYPE = {
  ...ChartSubTypeMap,
  CHART_UNKNOWN: { key: 'CHART_UNKNOWN', label: '-' },
};

export const DISPLAY_Q_INPUT_TYPES = {
  ...InputTypesMap,
  BIGINT: { key: 'BIGINT', label: 'Big Int' },
  BOOLEAN: { key: 'BOOLEAN', label: 'Boolean' },
  DECIMAL: { key: 'DECIMAL', label: 'Decimal' },
  LONG: { key: 'LONG', label: 'Long' },
  FIELD_TYPE_UNKNOWN: { key: 'FIELD_TYPE_UNKNOWN', label: '-' },
};

export const DISPLAY_FILTER_TYPES = {
  ...FILTER_TYPES,
  FILTER_TYPE_UNKNOWN: { key: 'FILTER_TYPE_UNKNOWN', value: '-' },
};


export const DISPLAY_Q_STORAGE_PROVIDER = {
  ...StorageProvidersMap,
  STORAGE_PROVIDER_UNKNOWN: { key: 'STORAGE_PROVIDER_UNKNOWN', label: '-' },
};

export const DISPLAY_Q_OUTPUT_FORMAT = {
  ...Object.values(OUTPUT_FORMAT).reduce(
    (obj, item) => Object.assign(obj, { [item]: { key: item, label: item } }), {},
  ),
  OUTPUT_FORMAT_UNKNOWN: { key: 'OUTPUT_FORMAT_UNKNOWN', label: '-' },
};

export const DISPLAY_Q_STATUS = {
  IS_DRAFT: { key: 'IS_DRAFT', label: 'Stage' },
  IS_PUBLISHED: { key: 'IS_PUBLISHED', label: 'Published' },
};

export const DISPLAY_Q_VISUALIZATION_TYPES = {
  ...VISUALIZATION_TYPES,
  FUNNEL: { key: 'FUNNEL', label: 'Funnel' },
  GROUPED_BAR_SYNCED_AXIS: { key: 'GROUPED_BAR_SYNCED_AXIS', label: 'Grouped Bar Synced Axis' },
  GROUPED_LINE_BAR_SYNCED_AXIS: { key: 'GROUPED_LINE_BAR_SYNCED_AXIS', label: 'Grouped Line Bar Synced Axis' },
  GROUPED_LINE_SYNCED_AXIS: { key: 'GROUPED_LINE_SYNCED_AXIS', label: 'Grouped Line Synced Axis' },
  HEATMAP: { key: 'HEATMAP', label: 'Heatmap' },
  HORIZONTAL_GROUPED_BAR_CHART: { key: 'HORIZONTAL_GROUPED_BAR_CHART', label: 'Horizontal Grouped Bar Chart' },
  VERTICAL_GROUPED_LINE_BAR_CHART: { key: 'VERTICAL_GROUPED_LINE_BAR_CHART', label: 'Vertical Grouped Line Bar Chart' },
  VISUALIZATION_TYPE_UNKNOWN: { key: 'VISUALIZATION_TYPE_UNKNOWN', label: '-' },
};

export const YAXIS_POSITION = {
  Y0: { key: 'Y0', value: 'Default Left Y-Axis (y0)' },
  Y1: { key: 'Y1', value: 'Default Right Y-Axis (y1)' },
  YLEFT: { key: 'YLEFT', value: 'Independent Left Y-Axis' },
  YRIGHT: { key: 'YRIGHT', value: 'Independent Right Y-Axis' },
};

export const VALIDATOR = {
  error: false,
  message: null,
};

/**
 * Editor reserved keywords
 */
export const EDITOR_RESERVED_KEYWORDS = {
  editableStart: '#region',
  editableEnd: '#endregion',
};

/**
 * Query Languages config
 */
export const QUERY_LANGUAGE = {
  sql: {
    icon: sqlIcon,
  },
  python: {
    icon: pythonIcon,
  },
};

/**
 * Set of standard keywords associated with the languages, along with the details.
 * These keywords will be displayed as auto suggestions while writing a query in question builder
 */
export const EDITOR_KEYWORDS = {
  sql: [
    { name: 'AND', details: 'Only includes rows where both conditions is true' },
    { name: 'ANY', details: 'Returns true if any of the subquery values meet the condition' },
    { name: 'AS', details: 'Renames a column or table with an alias' },
    { name: 'ASC', details: 'Sorts the result set in ascending order' },
    { name: 'BETWEEN', details: 'Selects values within a given range' },
    { name: 'CASE', details: 'Creates different outputs based on conditions' },
    { name: 'COLUMN', details: 'Changes the data type of a column or deletes a column in a table' },
    { name: 'DEFAULT', details: 'A constraint that provides a default value for a column' },
    { name: 'DESC', details: 'Sorts the result set in descending order' },
    { name: 'DISTINCT', details: 'Selects only distinct (different) values' },
    { name: 'EXISTS', details: 'Tests for the existence of any record in a subquery' },
    { name: 'FROM', details: 'Specifies which table to select or delete data from' },
    { name: 'GROUP BY', details: 'Groups the result set (used with aggregate functions: COUNT, MAX, MIN, SUM, AVG)' },
    { name: 'HAVING', details: 'Used instead of WHERE with aggregate functions' },
    { name: 'IN', details: 'Allows you to specify multiple values in a WHERE clause' },
    { name: 'INNER JOIN', details: 'Returns rows that have matching values in both tables' },
    { name: 'INSERT INTO', details: 'Inserts new rows in a table' },
    { name: 'INSERT INTO SELECT', details: 'Copies data from one table into another table' },
    { name: 'IS NULL', details: 'Tests for empty values' },
    { name: 'IS NOT NULL', details: 'Tests for non-empty values' },
    { name: 'JOIN', details: 'Joins tables' },
    { name: 'LEFT JOIN', details: 'Returns all rows from the left table, and the matching rows from the right table' },
    { name: 'LIKE', details: 'Searches for a specified pattern in a column' },
    { name: 'LIMIT', details: 'Specifies the number of records to return in the result set' },
    { name: 'NOT', details: 'Only includes rows where a condition is not true' },
    { name: 'NOT NULL', details: 'A constraint that enforces a column to not accept NULL values' },
    { name: 'NULL', details: 'A missing or unknown value' },
    { name: 'OR', details: 'Includes rows where either condition is true' },
    { name: 'ORDER BY', details: 'Sorts the result set in ascending or descending order' },
    { name: 'OUTER JOIN', details: 'Returns all rows when there is a match in either left table or right table' },
    { name: 'RIGHT JOIN', details: 'Returns all rows from the right table, and the matching rows from the left table' },
    { name: 'ROWNUM', details: 'Specifies the number of records to return in the result set' },
    { name: 'SELECT', details: 'Selects data from a database' },
    { name: 'SELECT DISTINCT', details: 'Selects only distinct (different) values' },
    { name: 'SELECT TOP', details: 'Specifies the number of records to return in the result set' },
    { name: 'TABLE', details: 'Creates a table, or adds, deletes, or modifies columns in a table, or deletes a table or data inside a table' },
    { name: 'THEN', details: 'Creates different outputs based on conditions' },
    { name: 'UNION', details: 'Combines the result set of two or more SELECT statements (only distinct values)' },
    { name: 'UNION ALL', details: 'Combines the result set of two or more SELECT statements (allows duplicate values)' },
    { name: 'UNIQUE', details: 'A constraint that ensures that all values in a column are unique' },
    { name: 'WHEN', details: 'Creates different outputs based on conditions' },
    { name: 'WHERE', details: 'Filters a result set to include only records that fulfill a specified condition' },
  ],
  python: [
    { name: 'and', details: 'A logical operator' },
    { name: 'as', details: 'To create an alias' },
    { name: 'assert', details: 'For debugging' },
    { name: 'break', details: 'To break out of a loop' },
    { name: 'class', details: 'To define a class' },
    { name: 'continue', details: 'To continue to the next iteration of a loop' },
    { name: 'def', details: 'To define a function' },
    { name: 'del', details: 'To delete an object' },
    { name: 'elif', details: 'Used in conditional statements, same as else if' },
    { name: 'else', details: 'Used in conditional statements' },
    { name: 'except', details: 'Used with exceptions, what to do when an exception occurs' },
    { name: 'False', details: 'Boolean value, result of comparison operations' },
    { name: 'finally', details: 'Used with exceptions, a block of code that will be executed no matter if there is an exception or not' },
    { name: 'for', details: 'To create a for loop' },
    { name: 'from', details: 'To import specific parts of a module' },
    { name: 'global', details: 'To declare a global variable' },
    { name: 'if', details: 'To make a conditional statement' },
    { name: 'import', details: 'To import a module' },
    { name: 'in', details: 'To check if a value is present in a list, tuple, etc.' },
    { name: 'is', details: 'To test if two variables are equal' },
    { name: 'lambda', details: 'To create an anonymous function' },
    { name: 'None', details: 'Represents a null value' },
    { name: 'nonlocal', details: 'To declare a non-local variable' },
    { name: 'not', details: 'A logical operator' },
    { name: 'or', details: 'A logical operator' },
    { name: 'pass', details: 'A null statement, a statement that will do nothing' },
    { name: 'raise', details: 'To raise an exception' },
    { name: 'return', details: 'To exit a function and return a value' },
    { name: 'True', details: 'Boolean value, result of comparison operations' },
    { name: 'try', details: 'To make a try...except statement' },
    { name: 'while', details: 'To create a while loop' },
    { name: 'with', details: 'Used to simplify exception handling' },
    { name: 'yield', details: 'To end a function, returns a generator' },
  ],
};

export const QUESTION_OUTPUT_TYPES = {
  MEASURE: 'MEASURE',
  DIMENSION: 'DIMENSION',
  USER_LIST: 'USER_LIST',
};

export const WIZARD_MODE = {
  CREATE: { key: 'CREATE', label: 'Create' },
  EDIT: { key: 'EDIT', label: 'Update' },
  CLONE: { key: 'CLONE', label: 'Clone' },
};

export const QUESTION_PURPOSES = [
  'Behavioral Advertising',
  'Personalization & Profiling',
  'Measurement & Analytics',
  'Product Development & Modeling',
  'Data Sales',
  'User ID Linking',
  'Use of Geolocation Data',
];

export const LOG_TYPES = {
  INFO: { key: 'INFO', label: 'Info' },
  ERROR: { key: 'ERROR', label: 'Error' },
  WARNING: { key: 'WARNING', label: 'Warning' },
};

export const DEFAULT_AMC_PARAMETERS = [
  {
    name: 'Audience_Name',
    displayName: 'Audience_Name',
    type: ATTRIBUTE_TYPES.STRING.key,
    value: ATTRIBUTE_TYPES.STRING.value,
  },
  {
    name: 'Audience_Description',
    displayName: 'Audience_Description',
    type: ATTRIBUTE_TYPES.STRING.key,
    value: ATTRIBUTE_TYPES.STRING.value,
  },
  {
    name: 'Advertiser_ID',
    displayName: 'Advertiser_ID',
    type: ATTRIBUTE_TYPES.STRING.key,
    value: ATTRIBUTE_TYPES.STRING.value,
  },
  {
    name: 'Refresh_Rate_Days',
    displayName: 'Refresh_Rate_Days',
    type: ATTRIBUTE_TYPES.INTEGER.key,
    value: ATTRIBUTE_TYPES.INTEGER.value,
  },
  {
    name: 'Relative_Time_Window',
    displayName: 'Relative_Time_Window',
    type: ATTRIBUTE_TYPES.STRING.key,
    value: ATTRIBUTE_TYPES.STRING.value,
  },
];

export const CLEAN_COMPUTE_ON_SPARK_DATATYPES = [
  'WHEEL',
  'JAR',
];

export const FALLBACK_QUESTION_VERSION = 0;
