/* Standardizing around this */
import localStorage from 'utils/localStorage';
import { CACHE } from 'utils/appConstants';
import { fulcrum } from 'utils/urlConstants';


const types = {
  INFO: 'Info',
  WARN: 'Warn',
  ERROR: 'Error',
};

const version = process.env.REACT_APP_VERSION;

const getLogObject = (type, obj, action) => {
  const organizationID = localStorage.get(CACHE.user.primaryOrg);
  const userID = localStorage.get(CACHE.user.id);
  let logObject = {
    pageUrl: `${window.location.hostname}${window.location.pathname}`,
    type,
    userID,
    organizationID,
    version,
    action,
  };

  if (obj instanceof Array) {
    logObject.msg = obj.join('; ');
  }
  else if (typeof obj !== 'object') {
    logObject.msg = obj;
  }
  else {
    logObject = { ...logObject, ...obj };
  }
  return logObject;
};

const handleLogging = (logObject) => {
  if (process.env.REACT_APP_ENV === 'test') { return; }
  // eslint-disable-next-line no-console
  console[logObject.type.toLowerCase()](logObject);

  if (process.env.REACT_APP_ENV === 'production') {
    const serializedMessage = Object.keys(logObject).map(
      key => `${encodeURIComponent(key)}=${encodeURIComponent(logObject[key])}`,
    ).join('&');
    fetch(fulcrum.logs, {
      method: 'POST',
      mode: 'no-cors',
      credentials: 'include',
      body: serializedMessage,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      // eslint-disable-next-line no-console
    }).catch(console.error);
  }
};

const log = type => (obj, action) => {
  const logObject = getLogObject(type, obj, action);
  handleLogging(logObject);
};

const Logger = {
  error: log(types.ERROR),
  info: log(types.INFO),
  warn: log(types.WARN),
};

export default Logger;
