import { IAM } from './Types';
import {
  iamAccounts, iamOrganizations, iamProduct, iamRoles, iamUsers,
} from 'utils/urlConstants';


export const createAccount = payload => ({
  type: IAM.CREATE_ACCOUNT,
  payload,
  url: iamAccounts.create,
});

export const accountCreationSuccess = payload => ({
  type: IAM.CONFIRM_ACCOUNT_CREATION_SUCCESS,
  payload,
});

export const accountCreationFailure = payload => ({
  type: IAM.CONFIRM_ACCOUNT_CREATION_FAILURE,
  payload,
});

export const createAccountAdministrator = (accountId, payload) => ({
  type: IAM.CREATE_ACCOUNT_ADMINISTRATOR,
  payload,
  url: iamAccounts.createAdministrator(accountId),
});

export const createAccountAdministratorSuccess = payload => ({
  type: IAM.CREATE_ACCOUNT_ADMINISTRATOR_SUCCESS,
  payload,
});

export const createAccountAdministratorFailure = payload => ({
  type: IAM.CREATE_ACCOUNT_ADMINISTRATOR_FAILURE,
  payload,
});

export const fetchAccountList = () => ({
  type: IAM.FETCH_ACCOUNT_LIST,
  url: iamAccounts.list,
});

export const fetchDemoAccountList = () => ({
  type: IAM.FETCH_DEMO_ACCOUNT_LIST,
  url: iamAccounts.listDemo,
});

export const receiveDemoAccountListSuccess = payload => ({
  type: IAM.RECEIVE_DEMO_ACCOUNT_LIST_SUCCESS,
  payload,
});

export const receiveDemoAccountListError = payload => ({
  type: IAM.RECEIVE_DEMO_ACCOUNT_LIST_ERROR,
  payload,
});

export const receiveAccountListSuccess = payload => ({
  type: IAM.RECEIVE_ACCOUNT_LIST_SUCCESS,
  payload,
});

export const receiveAccountListError = payload => ({
  type: IAM.RECEIVE_ACCOUNT_LIST_ERROR,
  payload,
});

export const fetchAccount = accountId => ({
  type: IAM.FETCH_ACCOUNT,
  url: iamAccounts.get(accountId),
});

export const fetchAccountSuccess = payload => ({
  type: IAM.FETCH_ACCOUNT_SUCCESS,
  payload,
});

export const fetchAccountFailure = payload => ({
  type: IAM.FETCH_ACCOUNT_FAILURE,
  payload,
});

export const updateAccount = (accountId, payload) => ({
  type: IAM.UPDATE_ACCOUNT,
  payload,
  url: iamAccounts.update(accountId),
});

export const updateAccountSuccess = payload => ({
  type: IAM.UPDATE_ACCOUNT_SUCCESS,
  payload,
});

export const updateAccountFailure = payload => ({
  type: IAM.UPDATE_ACCOUNT_FAILURE,
  payload,
});

export const fetchOrganizationList = accountId => ({
  type: IAM.FETCH_ORGANIZATION_LIST,
  url: iamOrganizations(accountId).listOrganizations,
});

export const receiveOrganizationListSuccess = payload => ({
  type: IAM.RECEIVE_ORGANIZATION_LIST_SUCCESS,
  payload,
});

export const receiveOrganizationListFailure = payload => ({
  type: IAM.RECEIVE_ORGANIZATION_LIST_FAILURE,
  payload,
});

export const fetchOrganization = (accountId, id) => ({
  type: IAM.FETCH_ORGANIZATION,
  url: iamOrganizations(accountId).get(id),
});

export const fetchOrganizationSuccess = payload => ({
  type: IAM.FETCH_ORGANIZATION_SUCCESS,
  payload,
});

export const fetchOrganizationFailure = payload => ({
  type: IAM.FETCH_ORGANIZATION_FAILURE,
  payload,
});

export const createOrganization = (accountId, payload) => ({
  type: IAM.CREATE_ORGANIZATION,
  payload,
  url: iamOrganizations(accountId).createOrganization,
});

export const organizationCreationSuccess = payload => ({
  type: IAM.CONFIRM_ORGANIZATION_CREATION_SUCCESS,
  payload,
});

export const organizationCreationFailure = payload => ({
  type: IAM.CONFIRM_ORGANIZATION_CREATION_FAILURE,
  payload,
});

export const updateOrganization = (accountId, organizationId, payload) => ({
  type: IAM.UPDATE_ORGANIZATION,
  payload,
  url: iamOrganizations(accountId).update(organizationId),
});

export const updateOrganizationSuccess = payload => ({
  type: IAM.UPDATE_ORGANIZATION_SUCCESS,
  payload,
});

export const updateOrganizationFailure = payload => ({
  type: IAM.UPDATE_ORGANIZATION_FAILURE,
  payload,
});

export const fetchRoleList = orgUid => ({
  type: IAM.FETCH_ROLE_LIST,
  url: iamRoles(orgUid).list,
});

export const receiveRoleListSuccess = payload => ({
  type: IAM.RECEIVE_ROLE_LIST_SUCCESS,
  payload,
});

export const receiveRoleListError = payload => ({
  type: IAM.RECEIVE_ROLE_LIST_ERROR,
  payload,
});

export const fetchRole = (orgUid, id) => ({
  type: IAM.FETCH_ROLE,
  url: `${iamRoles(orgUid).get}/${id}`,
});

export const receiveRoleSuccess = payload => ({
  type: IAM.RECEIVE_ROLE_SUCCESS,
  payload,
});

export const receiveRoleError = payload => ({
  type: IAM.RECEIVE_ROLE_ERROR,
  payload,
});

export const createRole = (orgUid, payload) => ({
  type: IAM.CREATE_ROLE,
  payload,
  url: iamRoles(orgUid).create,
});

export const roleCreationSuccess = payload => ({
  type: IAM.CONFIRM_ROLE_CREATION_SUCCESS,
  payload,
});

export const roleCreationFailure = payload => ({
  type: IAM.CONFIRM_ROLE_CREATION_FAILURE,
  payload,
});

export const updateRole = (orgUid, id, payload) => ({
  type: IAM.UPDATE_ROLE,
  payload,
  url: `${iamRoles(orgUid).update}/${id}`,
});

export const roleUpdationSuccess = payload => ({
  type: IAM.CONFIRM_ROLE_UPDATION_SUCCESS,
  payload,
});

export const roleUpdationFailure = payload => ({
  type: IAM.CONFIRM_ROLE_UPDATION_FAILURE,
  payload,
});

export const fetchUserList = (orgUid, payload) => ({
  type: IAM.FETCH_USER_LIST,
  url: iamUsers(orgUid).list,
  payload,
});

export const receiveUserListSuccess = payload => ({
  type: IAM.RECEIVE_USER_LIST_SUCCESS,
  payload,
});

export const receiveUserListError = payload => ({
  type: IAM.RECEIVE_USER_LIST_ERROR,
  payload,
});

export const fetchUser = (orgUid, id) => ({
  type: IAM.FETCH_USER,
  url: `${iamUsers(orgUid).get}/${id}`,
});

export const fetchUserFailure = payload => ({
  type: IAM.FETCH_USER_FAILURE,
  payload,
});

export const receiveUser = payload => ({
  type: IAM.RECEIVE_USER,
  payload,
});

export const fetchAdmin = (accountId, id) => ({
  type: IAM.FETCH_ADMIN,
  url: `${iamOrganizations(accountId).fetchAdmin(id)}`,
});

export const fetchAdminError = payload => ({
  type: IAM.FETCH_ADMIN_ERROR,
  payload,
});

export const receiveAdmin = payload => ({
  type: IAM.RECEIVE_ADMIN,
  payload,
});

export const createUser = (orgUid, payload) => ({
  type: IAM.CREATE_USER,
  payload,
  url: iamUsers(orgUid).create,
});

export const sendEmail = (orgUid, email, accountId) => ({
  type: IAM.SEND_WELCOME_EMAIL,
  url: accountId ? `${iamUsers().accountWelcomeEmail(accountId)}` : `${iamUsers(orgUid).welcomeEmail}`,
  email,
});

export const sendEmailSuccess = payload => ({
  type: IAM.SEND_WELCOME_EMAIL_SUCCESS,
  payload,
});

export const sendEmailFailure = payload => ({
  type: IAM.SEND_WELCOME_EMAIL_FAILURE,
  payload,
});

export const userCreationSuccess = payload => ({
  type: IAM.CONFIRM_USER_CREATION_SUCCESS,
  payload,
});

export const resetUser = () => ({
  type: IAM.RESET_USER,
});

export const userCreationFailure = payload => ({
  type: IAM.CONFIRM_USER_CREATION_FAILURE,
  payload,
});

export const updateUser = (orgUid, id, payload) => ({
  type: IAM.UPDATE_USER,
  payload,
  url: `${iamUsers(orgUid).update(id)}`,
});

export const userUpdationSuccess = payload => ({
  type: IAM.CONFIRM_USER_UPDATION_SUCCESS,
  payload,
});

export const userUpdationFailure = payload => ({
  type: IAM.CONFIRM_USER_UPDATION_FAILURE,
  payload,
});

export const updateAdmin = (accountId, userId, payload) => ({
  type: IAM.UPDATE_ADMIN,
  payload,
  url: `${iamUsers().updateAdmin(accountId, userId)}`,
});

export const adminUpdationSuccess = payload => ({
  type: IAM.CONFIRM_ADMIN_UPDATION_SUCCESS,
  payload,
});

export const adminUpdationError = payload => ({
  type: IAM.CONFIRM_ADMIN_UPDATION_ERROR,
  payload,
});

export const deleteUser = (orgId, id) => ({
  type: IAM.DELETE_USER,
  url: `${iamUsers(orgId).deleteUser(id)}`,
});

export const deleteUserSuccess = payload => ({
  type: IAM.DELETE_USER_SUCCESS,
  payload,
});

export const deleteUserError = payload => ({
  type: IAM.DELETE_USER_ERROR,
  payload,
});

export const deleteUserReset = () => ({
  type: IAM.DELETE_USER_RESET,
});

export const fetchProductList = () => ({
  type: IAM.FETCH_PRODUCT_LIST,
  url: iamProduct.productList,
});

export const receiveProductListSuccess = payload => ({
  type: IAM.RECEIVE_PRODUCT_LIST_SUCCESS,
  payload,
});

export const receiveProductListError = payload => ({
  type: IAM.RECEIVE_PRODUCT_LIST_FAILURE,
  payload,
});

export const fetchAccountProducts = accountID => ({
  type: IAM.FETCH_ACCOUNT_PRODUCTS,
  url: iamProduct.accountProducts(accountID),
});

export const receiveAccountProductsSuccess = payload => ({
  type: IAM.RECEIVE_ACCOUNT_PRODUCTS_SUCCESS,
  payload,
});

export const receiveAccountProductsError = payload => ({
  type: IAM.RECEIVE_ACCOUNT_PRODUCTS_FAILURE,
  payload,
});

export const submitAccountProducts = (accountID, data) => ({
  type: IAM.UPDATE_ACCOUNT_PRODUCTS,
  url: iamProduct.updateAccProducts(accountID),
  payload: data,
});

export const updateAccountProductsSuccess = payload => ({
  type: IAM.UPDATE_ACCOUNT_PRODUCTS_SUCCESS,
  payload,
});

export const updateAccountProductsError = payload => ({
  type: IAM.UPDATE_ACCOUNT_FAILURE,
  payload,
});

export const updateAccountProductReset = () => ({
  type: IAM.UPDATE_ACCOUNT_PRODUCTS_RESET,
});

export const fetchOrganizationProducts = orgID => ({
  type: IAM.FETCH_ORGANIZATION_PRODUCTS,
  url: iamProduct.organizationProducts(orgID),
});

export const receiveOrganizationProductsSuccess = payload => ({
  type: IAM.RECEIVE_ORGANIZATION_PRODUCTS_SUCCESS,
  payload,
});

export const receiveOrganizationProductsError = payload => ({
  type: IAM.RECEIVE_ORGANIZATION_PRODUCTS_ERROR,
  payload,
});

export const submitOrganizationProducts = (orgID, data) => ({
  type: IAM.UPDATE_ORGANIZATION_PRODUCTS,
  url: iamProduct.updateOrgProducts(orgID),
  payload: { productIDs: data },
});

export const updateOrganizationProductsSuccess = payload => ({
  type: IAM.UPDATE_ORGANIZATION_PRODUCTS_SUCCESS,
  payload,
});

export const updateOrganizationProductsError = payload => ({
  type: IAM.UPDATE_ORGANIZATION_PRODUCTS_ERROR,
  payload,
});

export const fetchAccountAdminList = accountId => ({
  type: IAM.FETCH_ACCOUNT_ADMIN_LIST,
  url: iamAccounts.accountAdminList(accountId),
});

export const fetchAccountAdminListError = payload => ({
  type: IAM.FETCH_ACCOUNT_ADMIN_LIST_ERROR,
  payload,
});

export const fetchAccountAdminListSuccess = payload => ({
  type: IAM.FETCH_ACCOUNT_ADMIN_LIST_SUCCESS,
  payload,
});
