import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import OutlinedInput from '@mui/material/OutlinedInput';
import React, { useContext, useEffect, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { Box, Typography } from '@mui/material';
import { FlowContext } from 'pages/Flow/FlowContextProvider';
import { HDialog, HDialogContent } from 'BaseComponents';
import { getDeltaString } from 'pages/Flow/flowUtils';
import { useFetchPrivacyBudgetOverride } from 'api/cleanrooms/useFetchPrivacyBudgetOverride';
import { usePostPrivacyBudgetOverride } from 'api/cleanrooms/usePostPrivacyBudgetOverride';


const AdvancedFlowSettingsDialog = () => {
  const [crPrivacyBudgetOverride, setCrPrivacyBudgetOverride] = useState({
    budgetOverride: '',
    deltaOverride: '',
    maxContributionsOverride: '',
  });

  const {
    advancedSetting,
    handleAdvancedSettingClose,
  } = useContext(FlowContext);

  const {
    data,
    isSuccess,
  } = useFetchPrivacyBudgetOverride(advancedSetting?.crID, advancedSetting?.cleanRoomFlowID);
  const {
    isLoading: isSubmitting,
    mutate: submitPrivacyBudgetOverride,
  } = usePostPrivacyBudgetOverride(
    advancedSetting?.crID,
    advancedSetting?.cleanRoomFlowID,
    advancedSetting?.name,
  );

  useEffect(() => {
    if (isSuccess) {
      setCrPrivacyBudgetOverride({
        budgetOverride: data.budgetOverride,
        deltaOverride: data.deltaOverride,
        maxContributionsOverride: data.maxContributionsOverride,
      });
    }
  }, [data, isSuccess]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCrPrivacyBudgetOverride(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleDeltaBlur = () => {
    const deltaString = getDeltaString(crPrivacyBudgetOverride.deltaOverride);
    setCrPrivacyBudgetOverride(prevState => ({
      ...prevState,
      deltaOverride: deltaString,
    }));
  };

  const handleSubmit = () => {
    submitPrivacyBudgetOverride({
      cleanRoomEntityPrivacyBudgetOverride: {
        entityType: 'FLOW',
        ...crPrivacyBudgetOverride,
      },
    },
    { onSuccess: () => handleAdvancedSettingClose() },
    );
  };

  return (
    <HDialog
      fullWidth
      open={Boolean(advancedSetting)}
      title={(
        <Typography variant='h3'>
          Advanced Flow Settings
        </Typography>
      )}
      loading={isSubmitting}
      onSubmit={handleSubmit}
      onClose={handleAdvancedSettingClose}
    >
      <HDialogContent>
        <Typography variant='h6' marginBottom='22px'>
          Privacy parameters
        </Typography>
        <Typography variant='body2' marginBottom='22px'>
          Privacy parameters entered here will override any settings in a question.
        </Typography>
        <Typography variant='body2' marginBottom='22px'>
          Creating overrides may affect the privacy guarantee or budget consumed.
        </Typography>
        <Box mb={2}>
          <Typography variant='subtitle2' mb={1}>
            Epsilon (Budget)
            <Box component='span' ml={1} sx={{ verticalAlign: 'sub' }}>
              <Tooltip title='The quantitative privacy guarantee' placement='right'>
                <InfoOutlinedIcon color='info' fontSize='small' />
              </Tooltip>
            </Box>
          </Typography>
          <OutlinedInput
            size='small'
            type='number'
            inputProps={{ min: 0.05 }}
            name='budgetOverride'
            value={crPrivacyBudgetOverride.budgetOverride}
            onChange={handleInputChange}
          />
        </Box>
        <Box mb={2}>
          <Typography variant='subtitle2' mb={1}>
            Delta
            <Box component='span' ml={1} sx={{ verticalAlign: 'sub' }}>
              <Tooltip
                title='The probability of information accidentally being leaked'
                placement='right'
              >
                <InfoOutlinedIcon color='info' fontSize='small' />
              </Tooltip>
            </Box>
          </Typography>
          <OutlinedInput
            size='small'
            name='deltaOverride'
            value={crPrivacyBudgetOverride.deltaOverride}
            onChange={handleInputChange}
            onBlur={handleDeltaBlur}
          />
        </Box>
        <Box mb={2}>
          <Typography variant='subtitle2' mb={1}>
            Max Contribution
            <Box component='span' ml={1} sx={{ verticalAlign: 'sub' }}>
              <Tooltip
                title='Maximum number of rows an individual can contribute to a dataset in the clean room'
                placement='right'
              >
                <InfoOutlinedIcon color='info' fontSize='small' />
              </Tooltip>
            </Box>
          </Typography>
          <OutlinedInput
            size='small'
            type='number'
            inputProps={{ min: 1 }}
            name='maxContributionsOverride'
            value={crPrivacyBudgetOverride.maxContributionsOverride}
            onChange={handleInputChange}
          />
        </Box>
      </HDialogContent>
    </HDialog>
  );
};

export default AdvancedFlowSettingsDialog;
