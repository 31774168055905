import React, { useMemo } from 'react';
import {
  HButton, HDialog, HDialogActions, HDialogContent,
} from 'BaseComponents';
import {
  Skeleton, Stack, TextField, Typography,
} from '@mui/material';
import { toDisplayString } from 'utils/jsUtils';
import {
  useFetchOrgCredentials,
  useFetchUdfTypes,
  useFetchUserDefinedFunction,
} from 'api/userDefinedFunctions';


const LoadingSkeleton = () => Array.from({ length: 5 },
  (_, i) => <Skeleton variant='rectangular' key={i} height={32} />);

const ViewUdfDialog = ({ udfID, viewUdfOpen, handleViewUdfClose }) => {
  const {
    isLoading: isLoadingUdf,
    data: { udfJob } = {},
  } = useFetchUserDefinedFunction(udfID);

  const {
    isLoading: isLoadingUdfTypes,
    data: { udfTypes = [] } = {},
  } = useFetchUdfTypes();

  const udfTypeName = useMemo(() => {
    const selectedType = udfTypes.find(type => type.ID === udfJob?.typeID);
    return selectedType?.displayName ?? '';
  }, [udfJob?.typeID, udfTypes]);

  const {
    isLoading: isLoadingCredentials,
    isIdle: isIdleCredentials,
    data: { credentials = [] } = {},
  } = useFetchOrgCredentials(udfTypeName);

  const isLoading = isLoadingUdf || isLoadingUdfTypes || isLoadingCredentials || isIdleCredentials;
  return (
    <HDialog
      fullWidth
      open={viewUdfOpen}
      maxWidth='sm'
      title='View User-Defined Function'
      onClose={handleViewUdfClose}
    >
      <HDialogContent>
        {
          isLoading
            ? <LoadingSkeleton />
            : (
              <Stack rowGap={2}>
                <TextField
                  label='Function Name'
                  value={udfJob.name}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <TextField
                  label='User-Defined Function Type'
                  value={udfTypes.find(t => t.ID === udfJob?.typeID)?.displayName}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <TextField
                  label='Credentials'
                  value={credentials.find(c => c.ID === udfJob?.credentialID)?.name}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <Typography variant='body1' fontWeight='fontWeightMedium'>
                  Parameters
                </Typography>
                {udfJob.udfParameters.map((parameter) => (
                  <TextField
                    key={parameter.ID}
                    label={toDisplayString(parameter.name.replace(/^DB_/, ''))}
                    value={parameter.value}
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                ))}
              </Stack>
            )
        }
      </HDialogContent>
      <HDialogActions>
        <HButton variant='outlined' onClick={handleViewUdfClose}>Close</HButton>
      </HDialogActions>
    </HDialog>
  );
};

export default ViewUdfDialog;
