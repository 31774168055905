import PermissionService from 'components/Common/PermissionService';
import { DeleteNodeContext } from 'pages/Flow/FlowBuilder/DeleteNodeContextProvider';
import { FlowBuilderContext } from 'pages/Flow/FlowBuilder/FlowBuilderContextProvider';
import { question as questionURL } from 'utils/spaUrls';
import { useContext } from 'react';
import { userPermissions } from 'utils/appConstants';


export const useActionMenuItems = () => {
  const actionItems = [];
  const { isViewMode } = useContext(FlowBuilderContext);
  const isQuestionBuilderEnabled = PermissionService
    .isAuthorized([userPermissions.questionBuilder]);
  const { setNodeDescription } = useContext(DeleteNodeContext);

  return (nodeQuestion, crId) => {
    if (!nodeQuestion || !crId) {
      return [];
    }

    const handleOnDelete = () => {
      setNodeDescription({
        nodeId: nodeQuestion.nodeId,
        title: nodeQuestion.title,
      });
    };

    if (isQuestionBuilderEnabled) {
      actionItems.push({
        label: 'Edit Question',
        link: true,
        action: undefined,
        to: questionURL.builder.wizard.flow(nodeQuestion.ID, crId),
      });
      actionItems.push({
        label: 'Question Query',
        link: true,
        action: undefined,
        to: questionURL.builder.wizard.cleanRoom(nodeQuestion.ID, crId, ''),
      });
    }

    if (!isViewMode) {
      actionItems.push({
        label: 'Delete',
        link: false,
        action: handleOnDelete,
      });
    }

    return actionItems;
  };
};
