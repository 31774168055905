import Logger from 'components/Common/Logger';
import http from 'utils/http';
import {
  END_DATE_PARAMETER,
  START_DATE_PARAMETER,
  isDateParametersAdded,
} from 'components/InternalAdmin/QuestionConfigure/QuestionParameters';
import {
  call, put,
} from 'redux-saga/effects';
import { fetchQuestionDetailError, fetchQuestionDetailSuccess } from 'redux/actions/FlowAction';
import { showAlert } from 'redux/actions/AlertActions';


const addDateParameters = (question) => {
  if (question && question.parameters && question.hasDateParameters
    && !isDateParametersAdded(question.parameters)) {
    return {
      ...question,
      parameters: [
        { ...START_DATE_PARAMETER },
        { ...END_DATE_PARAMETER },
        ...question.parameters,
      ],
    };
  }
  return question;
};

export function* fetchQuestionDetail({ url, payload }) {
  const { qId, nodeId } = payload;
  const { error, question } = yield call(http.get, url);

  if (error) {
    Logger.error(error, `Could not fetch question node details. Url: ${url}. Error: ${error}`);
    yield put(showAlert({ message: error, type: 'error' }));
    return yield put(fetchQuestionDetailError(payload));
  }
  const questionBuffer = addDateParameters(question);

  yield put(showAlert({ message: 'Fetch question node details successfully', type: 'success' }));
  return yield put(fetchQuestionDetailSuccess(
    { data: { ...questionBuffer, referenceID: qId, nodeId }, qId, nodeId },
  ));
}
