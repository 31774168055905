import * as actions from 'pages/InternalAdmin/organization/CleanRoom/redux/actions';
import Logger from 'components/Common/Logger';
import http from 'utils/http';
import { CLEAN_ROOM_ADMIN } from 'redux/actions/Types';
import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import { showAlert } from 'redux/actions/AlertActions';


function* fetchOrgCrQuestions(action) {
  const { questions, error } = yield call(http.get, action.url);
  if (error) {
    Logger.error(error, `Could not fetch org fva questions. Url: ${action.url}. Error: ${error}`);
    return yield put(actions.fetchOrgCRQuestionsError(error));
  }
  else {
    return yield put(actions.fetchOrgCRQuestionsSuccess(questions));
  }
}


function* updateOrgFvaQuestions(action) {
  const { questions, error } = yield call(http.post, action.url, action.payload);
  if (error) {
    Logger.error(error, `Could not update org fva questions. Url: ${action.url}. Error: ${error}`);
    yield put(showAlert({ message: error, type: 'error' }));
    return yield put(actions.updateOrgCRQuestionsError(error));
  }
  else {
    yield put(showAlert({ message: 'Organization Questions updated successfully', type: 'success' }));
    return yield put(actions.updateOrgCRQuestionsSuccess(questions));
  }
}

export default function* root() {
  yield all([
    takeLatest(CLEAN_ROOM_ADMIN.FETCH_ORG_CR_QUESTIONS, fetchOrgCrQuestions),
    takeLatest(CLEAN_ROOM_ADMIN.UPDATE_ORG_CR_QUESTIONS, updateOrgFvaQuestions),
  ]);
}

export {
  fetchOrgCrQuestions,
  updateOrgFvaQuestions,
};
