import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Divider from '@mui/material/Divider';
import React from 'react';
import Typography from '@mui/material/Typography';
import { HButton } from 'BaseComponents';
import { requestForgotPasswordLink } from 'redux/actions/AuthenticationActions';
import { styled } from '@mui/material/styles';
import { useDispatch } from 'react-redux';


const StyledDivider = styled(Divider)(({ theme }) => ({
  height: '1.5px',
  backgroundColor: theme.palette.primary.main,
  marginBottom: theme.spacing(2),
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  marginTop: theme.spacing(1),
}));
const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: '1.25rem',
  paddingBottom: '.5rem',
  color: theme.palette.primary.main,
}));


const saveAndClose = (dispatch, setOpen, email) => {
  dispatch(requestForgotPasswordLink({ email }));
  setOpen(false);
};

const ResetPasswordDialog = ({ email }) => {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();

  return (
    <>
      <HButton color='primary' onClick={() => setOpen(true)} size='large'>
        Reset Password
      </HButton>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby='change-password-dialog-title'
        aria-describedby='change-password-dialog-description'
      >
        <StyledDialogContent>
          <StyledTypography>Confirm Reset Password</StyledTypography>
          <StyledDivider />
          <DialogContentText id='change-password-dialog-description'>
            {`Confirm to receive reset password link on: ${email}.`}
          </DialogContentText>
        </StyledDialogContent>
        <DialogActions>
          <HButton variant='contained' size='small' onClick={() => setOpen(false)}>
            Cancel
          </HButton>
          <HButton
            autoFocus
            color='primary'
            size='small'
            onClick={() => { saveAndClose(dispatch, setOpen, email); }}
            variant='contained'
          >
            Confirm
          </HButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ResetPasswordDialog;
