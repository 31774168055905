import { DEFAULT_QUESTION, WIZARD_MODE } from 'utils/constants/question.constant';
import { QUESTION_BUILDER } from 'redux/actions/Types';
import { cloneDeep } from 'lodash';


const crqPermsDefault = [
  {
    permission: 'VIEW_CODE',
    access: 'QUESTION_OWNER_ONLY',
  },
  {
    permission: 'CLONE',
    access: 'QUESTION_OWNER_ONLY',
  },
  {
    permission: 'RUN',
    access: 'ALL_CLEAN_ROOM_PARTNERS',
  },
  {
    permission: 'VIEW_RESULTS',
    access: 'ALL_CLEAN_ROOM_PARTNERS',
  },
  {
    permission: 'EDIT',
    access: 'QUESTION_OWNER_ONLY',
  },
];

const initialState = {
  activeStep: 0,
  wizardMode: WIZARD_MODE.CREATE.key,
  isCleanComputeQuestion: false,
  isSparkComputeQuestion: false,
  question: DEFAULT_QUESTION,
  isDiff: false,
  isCRPrivate: false,
  wallGardenCleanRooms: [],
  formState: {
    errors: {},
  },
  editorConfig: {
    isDarkMode: true,
    isFullScreen: false,
    focusedCleanRoom: null,
  },
  editorTabs: [],
  permissions: crqPermsDefault,
  cleanRoomQuestionUDFs: [],
};

const questionBuilderReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case QUESTION_BUILDER.RESET: {
      return cloneDeep(initialState);
    }
    case QUESTION_BUILDER.SET_ACTIVE_STEP: {
      return {
        ...state,
        activeStep: payload,
      };
    }
    case QUESTION_BUILDER.SET_WIZARD_MODE: {
      return {
        ...state,
        wizardMode: payload,
      };
    }
    case QUESTION_BUILDER.SET_QUESTION_TYPE: {
      return {
        ...state,
        isCleanComputeQuestion: payload.isCleanComputeQuestion,
        isSparkComputeQuestion: payload.isSparkComputeQuestion,
      };
    }
    case QUESTION_BUILDER.UPDATE_QUESTION_QUERY: {
      const queryDetails = [...state.question.queryDetails];
      queryDetails[payload.index].query = payload.value;
      return {
        ...state,
        question: {
          ...state.question,
          queryDetails,
        },
        editorTabs: state.editorTabs.map((et, index) => ({
          ...et,
          isTouched: true,
          value: index === payload.index ? payload.value : et.value,
        })),
      };
    }
    case QUESTION_BUILDER.UPDATE_QUESTION_DETAILS: {
      return {
        ...state,
        question: { ...state.question, ...payload },
      };
    }
    case QUESTION_BUILDER.UPDATE_QUESTION: {
      const newQuestion = payload?.queryDetails ?? state.question?.queryDetails;
      const editorTabs = payload?.editorTabs || newQuestion.map((qd, index) => ({
        id: qd?.cleanRoomTypes[0],
        label: qd?.cleanRoomTypes[0],
        value: qd?.query || '',
        isValid: state.editorTabs[index]?.isValid || false,
        isValidated: state.editorTabs[index]?.isValidated || false,
        isTouched: state.editorTabs[index]?.isTouched || false,
        validationLogs: state.editorTabs[index]?.validationLogs || [],
      }));
      return {
        ...state,
        question: { ...state.question, ...payload },
        editorTabs,
      };
    }
    case QUESTION_BUILDER.SET_IS_DIFF: {
      return {
        ...state,
        isDiff: payload,
      };
    }
    case QUESTION_BUILDER.SET_WALLED_GARDEN_CLEAN_ROOMS: {
      return {
        ...state,
        wallGardenCleanRooms: payload,
      };
    }
    case QUESTION_BUILDER.UPDATE_FORM_STATE: {
      return {
        ...state,
        formState: payload,
      };
    }
    case QUESTION_BUILDER.SET_DARK_MODE: {
      return {
        ...state,
        editorConfig: { ...state.editorConfig, isDarkMode: payload },
      };
    }
    case QUESTION_BUILDER.SET_FULL_SCREEN: {
      return {
        ...state,
        editorConfig: { ...state.editorConfig, isFullScreen: payload },
      };
    }
    case QUESTION_BUILDER.SET_FOCUSED_CLEAN_ROOM: {
      return {
        ...state,
        editorConfig: { ...state.editorConfig, focusedCleanRoom: payload },
      };
    }
    case QUESTION_BUILDER.UPDATE_EDITOR_TABS: {
      return {
        ...state,
        editorTabs: [...payload],
      };
    }
    case QUESTION_BUILDER.FETCH_CR_QUESTION_PERMISSIONS_SUCCESS:
      return {
        ...state,
        permissions: payload.cleanRoomQuestionPermissions,
      };
    case QUESTION_BUILDER.FETCH_CR_QUESTION_PERMISSIONS_ERROR:
      return {
        ...state,
        permissions: crqPermsDefault,
      };
    case QUESTION_BUILDER.UPDATE_CR_QUESTION_PERMISSIONS:
      return {
        ...state,
        permissions: payload,
      };
    case QUESTION_BUILDER.UPDATE_CR_QUESTION_UDF: {
      return {
        ...state,
        cleanRoomQuestionUDFs: payload,
      };
    }
    default: {
      return state;
    }
  }
};

// Selectors
export const selectCleanRoomQuestionUDFs = (state) => state.questionBuilder.cleanRoomQuestionUDFs;

export default questionBuilderReducer;
