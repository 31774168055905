import http from 'utils/http';
import { DATA_CONNECTIONS } from 'redux/actions/Types';
import { SyntheticRedux } from 'synthetic-redux';
import { all } from 'redux-saga/effects';
import { combineReducers } from 'redux';
import { dataIn } from 'utils/urlConstants';
import { demoSagaEffect } from 'utils/reduxUtils';


const uploadDataInFile = new SyntheticRedux({ type: DATA_CONNECTIONS.UPLOAD_DATA_IN_FILE }, 'status');
uploadDataInFile.ignite = (payload) => uploadDataInFile.actions.ignite({
  errorMeta: 'Upload data in',
  method: http.postFile,
  url: dataIn().upload,
  payload,
});

const fetchDataConnections = new SyntheticRedux({ type: DATA_CONNECTIONS.FETCH_DATA_CONNECTIONS }, 'list');
fetchDataConnections.demoSagaEffect = demoSagaEffect(fetchDataConnections.actions);

const fetchFilters = new SyntheticRedux({ type: DATA_CONNECTIONS.FETCH_FILTERS }, 'filters');
fetchFilters.demoSagaEffect = demoSagaEffect(fetchFilters.actions);

export { fetchDataConnections, fetchFilters, uploadDataInFile };

export function* DataConnectionsSaga() {
  yield all([
    fetchDataConnections.demoSagaEffect,
    fetchFilters.demoSagaEffect,
    uploadDataInFile.sagaEffect,
  ]);
}

export const DataConnectionsReducer = combineReducers({
  dataConnections: fetchDataConnections.reducer,
  filtersList: fetchFilters.reducer,
  upload: uploadDataInFile.reducer,
});
