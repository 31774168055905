import { activeOrgSelector } from 'redux/reducer/AuthenticationReducer';
import { axiosInstance } from 'utils/http';
import { cleanRoom } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';


export const useFetchPrivacyBudgetOverride = (crID, cleanRoomFlowID) => {
  const dispatch = useDispatch();
  const { ID: orgID } = useSelector(activeOrgSelector);

  return useQuery(
    ['FetchPrivacyBudgetOverride', crID, cleanRoomFlowID],
    () => axiosInstance.get(cleanRoom(orgID).privacyBudgetOverride(crID, cleanRoomFlowID)),
    {
      enabled: !!crID && !!cleanRoomFlowID,
      select: (res) => res.data.cleanRoomEntityPrivacyBudgetOverride,
      onError: () => {
        dispatch(showAlert({
          message: 'Error fetching privacy parameters',
          type: 'error',
        }));
      },
    },

  );
};

