import Button from '@mui/material/Button';
import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import { ContentCopy } from '@mui/icons-material';
import { CopyToClipboard } from 'react-copy-to-clipboard/lib/Component';
import { IconButton } from '@mui/material';
import { rowsPerPageOptions } from 'utils/appConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch } from 'react-redux';


const OrganizationListTable = ({
  list, onSwitch, orgId,
}) => {
  const dispatch = useDispatch();
  const [rowsPerPage, setRowsPerPage] = React.useState(rowsPerPageOptions[0]);
  const [page, setPage] = React.useState(0);
  const handleCopy = () => {
    dispatch(showAlert({ message: 'Organization UUID Copied', type: 'success' }));
  };

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align='left'>Name</TableCell>
            <TableCell align='right' />
            <TableCell align='center'>Organization UUID</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {list
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row) => {
              const isCurrentOrg = row.organization.ID === orgId;

              return (
                <TableRow key={row.organization.ID}>
                  <TableCell align='left' sx={{ fontWeight: isCurrentOrg ? 'bold' : 'initial' }}>{row.organization.name}</TableCell>
                  <TableCell>
                    <Button
                      disabled={isCurrentOrg}
                      color='primary'
                      size='small'
                      onClick={() => onSwitch(row)}
                      variant='contained'
                    >
                      Switch Organization
                    </Button>
                  </TableCell>
                  <TableCell>
                    {row.organization.ID}
                  </TableCell>
                  <TableCell>
                    <Tooltip title='Copy UUID'>
                      <IconButton onClick={handleCopy}>
                        <CopyToClipboard text={row.organization.ID}>
                          <ContentCopy />
                        </CopyToClipboard>
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component='div'
        count={list.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
        }}
        onPageChange={(e, newPage) => setPage(newPage)}
        onRowsPerPageChange={e => setRowsPerPage(e.target.value)}
      />
    </>
  );
};

export default OrganizationListTable;
