export const trustLevelMap = {
  0: '1',
  25: '2',
  50: '3',
  75: '4',
  100: '5',
};

export const trustLevelMapRev = {
  1: '0',
  2: '25',
  3: '50',
  4: '75',
  5: '100',
};

export const riskToleranceMap = {
  0: '0',
  33: '10',
  66: '20',
  99: '30',
};

export const riskToleranceMapRev = {
  0: 0,
  10: 33,
  20: 66,
  30: 99,
};

export const customDPText = 'Audit View of Privacy Budget usage for Clean Room Runs. Detailed outline of all the executed runs.\n';

export const trustLevelTextMap = {
  1: {
    level: 'Minimal',
    text: 'This is the most strict setting and should be used if the datasets included in the clean room are highly sensitive or if you believe partners may be tempted to attempt to understand which individuals are included in datasets. This setting values preserving privacy over getting maximum value out of the data.\n',
  },
  2: {
    level: 'Low',
    text: 'This setting is appropriate if datasets in the clean room are highly sensitive, and you want to prevent accidental privacy leakage to partners. This setting preserves privacy, but still allows for greater value extraction from data than the minimum setting.\n',
  },
  3: {
    level: 'Neutral',
    text: 'This setting is most appropriate for a new partnership or data evaluation period where the data is not considered highly sensitive, but partners may not have complete trust in one another. It allows for flexibility in terms of number of questions run, while mitigating the risk of privacy leakage.\n',
  },
  4: {
    level: 'Moderate',
    text: 'This setting is most appropriate for use cases where data is not considered highly sensitive, and partners are familiar working with one another. This allows for greater ability to query data, while mitigating the risk of accidental privacy leakage.\n',
  },
  5: {
    level: 'Strong',
    text: 'This is the most flexible setting and is most appropriate for use cases where data is not considered highly sensitive, but you still want to mitigate the risk of privacy leakage. This setting allows for the maximum number of questions to be run, and assumes partners need to get the most value out of data possible.\n',
  },
};

export const marksTrustLevel = [
  {
    value: 0,
    label: 'Minimal',
  },
  {
    value: 25,
    label: 'Low',
  },
  {
    value: 50,
    label: 'Neutral',
  },
  {
    value: 75,
    label: 'Moderate',
  },
  {
    value: 100,
    label: 'Strong',
  },
];

export const marksRiskTolerance = [{
  value: 0,
  label: '0',
}, {
  value: 33,
  label: '10',
}, {
  value: 66,
  label: '20',
}, {
  value: 99,
  label: '30',
}];

export const infotext = {
  riskToleranceToolTip: 'Indicates your tradeoff between privacy protection and required accuracy of question results for your use case.',
  budgetUnitsToolTip: 'Representation of the privacy-accuracy tradeoff. The lower the budget, the higher the privacy protection and the lower the accuracy of results is likely to be depending on budget used per question.',
  numQueriesToolTip: 'Approximate number of question runs one can execute before depleting the budget.',
  maxPrivacyLossToolTip: 'Number representing the tolerable privacy loss over the specified time horizon. This number is used to infer the amount of epsilon to consume with each question run and is reset at the end of each time horizon.',
  maxQuestionRunsToolTip: 'Number of question runs which can be executed in this clean room over the specified time horizon. This number is used to infer the amount of epsilon to consume with each question run and is reset at the end of each time horizon.',
  timeHorizonToolTip: 'Time period over which the budget can be consumed. At the end of this time horizon, the budget and question run numbers are reset.',
  runsRemainingToolTip: 'Approximate number of question runs left, based on the default privacy parameter,  before runs are paused for this clean room.',
  budgetRemainingToolTip: 'The percentage of this clean room’s privacy budget remaining.',
  cleanRoomPrivacyBudgetToolTip: 'Current Privacy Budget set for the clean room.',
  deltaToolTip: 'Delta allows for a small, controlled probability (δ) of an exception to the privacy guarantee',
  maxContributionsToolTip: 'Maximum number of rows an individual can contribute to a dataset in the clean room',
};

export const crPrivacyType = {
  NO_DIFFERENTIAL_PRIVACY: 'NO_DIFFERENTIAL_PRIVACY',
  HABU_DIFFERENTIAL_PRIVACY: 'HABU_DIFFERENTIAL_PRIVACY',
  CUSTOM_DIFFERENTIAL_PRIVACY: 'CUSTOM_DIFFERENTIAL_PRIVACY',
};
