import GearPopover from 'components/Common/GearPopover';
import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { rowsPerPageOptions } from 'utils/appConstants';


const PropertiesConfigurationList = ({ list = [], actions = [] }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(rowsPerPageOptions[0]);
  return (
    <>
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Domain</TableCell>
            <TableCell>Configured</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {list
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map(property => (
              <TableRow key={property.ID}>
                <TableCell>{property.name}</TableCell>
                <TableCell>{property.url}</TableCell>
                <TableCell>{property.configuration ? 'Yes' : 'No'}</TableCell>
                <TableCell align='right'>
                  <GearPopover actions={actions(property)} />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component='div'
        count={list.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
        }}
        onPageChange={(e, newPage) => {
          setPage(newPage);
        }}
        onRowsPerPageChange={(e) => {
          setRowsPerPage(e.target.value);
        }}
      />
    </>
  );
};

export default PropertiesConfigurationList;
