import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import { DialogTitle, IconButton } from '@mui/material';


export const HDialogTitle = ({ onClose, children, ...restProps }) => {
  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <DialogTitle component='div' variant='h5' {...restProps}>
      <Box
        display='flex'
        direction='row'
        alignItems='center'
        position='relative'
      >
        {children}
        {onClose && (
          <IconButton onClick={handleClose} sx={{ position: 'absolute', right: -8 }}>
            <CloseIcon color='primary' />
          </IconButton>
        )}
      </Box>
    </DialogTitle>
  );
};
