import ConfirmationDialog from 'components/Common/ConfirmationDialog';
import React, {
  createContext, useContext, useMemo, useState,
} from 'react';
import { FlowContext } from 'pages/Flow/FlowContextProvider';
import { useDeleteFlow } from 'api/flows';


export const DeleteFlowListContext = createContext({});

export const DeleteFlowListContextProvider = ({ children }) => {
  const { mutateAsync } = useDeleteFlow();
  const {
    dataContextRef,
  } = useContext(FlowContext);

  const [deleteFlowDescription, setDeleteFlowDescription] = useState(null);

  const handleOnCancel = () => {
    setDeleteFlowDescription(null);
  };

  const handleOnConfirm = async () => {
    await mutateAsync({ flowId: deleteFlowDescription.ID, crId: deleteFlowDescription.crID });
    dataContextRef.current.refreshListData();
    handleOnCancel();
  };

  const deleteFlowListContextValue = {
    setDeleteFlowDescription,
  };
  const flowName = useMemo(() => (
    deleteFlowDescription?.name
      ? `${deleteFlowDescription.name} (${deleteFlowDescription.displayID})`
      : deleteFlowDescription?.displayID) ?? '',
  [deleteFlowDescription]);

  return (
    <DeleteFlowListContext.Provider value={deleteFlowListContextValue}>
      {children}
      {deleteFlowDescription && (
        <ConfirmationDialog
          open={Boolean(deleteFlowDescription)}
          title='Delete Flow?'
          message={`Are you sure you want to delete "${flowName}"?`}
          confirmActionLabel='Delete'
          onConfirm={handleOnConfirm}
          onCancel={handleOnCancel}
        />
      )}
    </DeleteFlowListContext.Provider>
  );
};
