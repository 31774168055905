export const MAX_FILE_SIZE = 52428800; // 50MB

export const DATASOURCE_CATEGORIES = [
  'Cloud Data Storage',
  'Warehouse/Lakehouse',
  'Code Container',
  'Partner Integration',
  'Data Catalog',
  'LiveRamp-Hosted',
  'Local Upload',
];

export const DISPLAY_NAME_REBRAND = {
  'Habu AWS S3': 'LiveRamp-Hosted AWS S3',
  'Habu Google Cloud Storage': 'LiveRamp-Hosted Google Cloud Storage',
  'Habu Snowflake': 'LiveRamp-Hosted Snowflake',
  'Habu Amazon Marketing Cloud': 'LiveRamp-Hosted Amazon Marketing Cloud',
  'Habu Databricks': 'LiveRamp-Hosted Databricks',
  'Habu Managed Snowflake Account': 'LiveRamp-Managed Snowflake Account',
};

export const ICEBERG_CATALOG_DATA_CONNECTION = 'ICEBERG_CATALOG';

// Synthetic Data Sources

const ADVERTISER_SYNTHETIC_DATASET_LIBRARY = 'ADVERTISER_SYNTHETIC_DATASET_LIBRARY';
const PUBLISHER_SYNTHETIC_DATASET_LIBRARY = 'PUBLISHER_SYNTHETIC_DATASET_LIBRARY';

const SYNTHETIC_DATA_SOURCES = {
  [ADVERTISER_SYNTHETIC_DATASET_LIBRARY]: 'Advertiser Synthetic Dataset Library',
  [PUBLISHER_SYNTHETIC_DATASET_LIBRARY]: 'Publisher Synthetic Dataset Library',
};

export const SYNTHETIC_DATA_SOURCES_NAMES = Object.keys(SYNTHETIC_DATA_SOURCES);

export const SYNTHETIC_DATA_SOURCES_DISPLAY_NAMES = Object.values(SYNTHETIC_DATA_SOURCES);

