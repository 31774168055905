import { EDGES_BASE_STYLE, NODES_CONNECTION_TYPE } from 'pages/Flow/contacts';
import { QUESTION_OUTPUT_TYPES as QUESTION_OUTPUT_TYPE } from 'utils/constants/question.constant';

// Unified function to handle payload mutation
const createOrUpdateEdgePayload = (payload, crID = '', questionMapping, isUpdate = false) => {
  const basePayload = {
    parentNodeID: payload.sourceNodeID,
    childNodeID: payload.targetNodeID,
    questionChainingEdge: {
      childQuestionDataType: { ID: payload.dataSetTypeID },
      questionChainingEdgeMappings: questionMapping,
    },
  };

  const edgePayload = isUpdate ? {
    ...basePayload,
    ID: payload.ID,
    childNodeLabel: payload.childNodeLabel,
    parentNodeLabel: payload.parentNodeLabel,
    edgeType: payload.edgeType,
    questionChainingEdge: {
      ...basePayload.questionChainingEdge,
      ID: payload.questionChainingEdgeID,
      edgeID: payload.ID,
    },
  } : basePayload;

  return {
    edge: edgePayload,
    cleanRoomID: crID, // Optional outside CR
  };
};

const questionChainingTemplate = (macro) => ({
  questionOutputType: macro.questionOutputType, // This is the output of the question
  questionDimension: {},
  questionMetric: {},
  questionUserListOutput: {}, // Only for user list outputs
  questionDataTypeField: {
    ID: macro.dataSourceField.ID, // This is the ID of the dataset type field
  },
});

const mutateQuestionMacros = (macroPayload) => {
  const macroList = [];
  if (!macroPayload) return macroList;
  if (macroPayload.length === 0) return macroList;

  macroPayload.forEach((macro) => {
    const m = questionChainingTemplate(macro);

    if (macro.questionOutputType === QUESTION_OUTPUT_TYPE.DIMENSION) {
      m.questionDimension = { ID: macro.outputFieldID };
    }
    if (macro.questionOutputType === QUESTION_OUTPUT_TYPE.MEASURE) {
      m.questionMetric = { ID: macro.outputFieldID };
    }
    if (macro.questionOutputType === QUESTION_OUTPUT_TYPE.USER_LIST) {
      m.questionUserListOutput = { ID: macro.outputFieldID };
    }
    macroList.push(m);
  });

  return macroList;
};


export const mutateEdgePayload = (edgePayload, edgeType, crId, isUpdate = false) => {
  if (edgeType === NODES_CONNECTION_TYPE.QUESTION_TO_QUESTION) {
    const questionChainingEdgeMappings = mutateQuestionMacros(edgePayload.macros);
    return createOrUpdateEdgePayload(edgePayload, crId, questionChainingEdgeMappings, isUpdate);
  }

  return null;
};

// structure of the edge for react flow
export const mutateSingleEdge = (edge) => ({
  id: edge.ID,
  source: edge.parentNodeID,
  target: edge.childNodeID,
  label: edge.label,
  data: edge.questionChainingEdge,
  ...EDGES_BASE_STYLE,
  selected: false,
});
