import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import React from 'react';
import SectionHeader from 'components/IAM/Admin/SectionHeader';
import TextField from '@mui/material/TextField';
import { AttributeDataTypes } from 'components/DataIngestion/constants';
import { HButton } from 'BaseComponents';
import { HighlightTypes } from 'components/InternalAdmin/PropertyConfiguration/service';
import { snakeToDisplayString } from 'utils/jsUtils';


const emptyBowline = {
  match: null,
  highlights: [
    {},
  ],
};

const PropertyConfigurationBowlineForm = ({ details, updateBowline }) => {
  const [bowline, setBowline] = React.useState(details);

  React.useEffect(() => {
    setBowline(details);
  }, [details]);

  const updateHighlight = (value, index, field) => {
    const highlights = [...bowline.highlights];
    highlights[index][field] = value;
    updateBowline({ ...bowline, highlights });
  };

  const addHighlight = () => {
    const highlights = [...bowline.highlights, {}];
    updateBowline({ ...bowline, highlights });
  };

  const updateMatch = (match) => {
    updateBowline({ ...bowline, match });
  };

  return (
    <Grid container spacing={2} direction='column'>
      <Grid item sm={6}>
        <FormControlLabel
          label='Bowline'
          labelPlacement='end'
          control={(
            <Checkbox
              checked={!!bowline}
              onChange={(e) => {
                const newBowline = e.target.checked ? emptyBowline : null;
                setBowline(newBowline);
                updateBowline(newBowline);
              }}
              value='primary'
              inputProps={{ 'aria-label': 'Bowline' }}
            />
            )}
        />
      </Grid>
      {bowline && (
        <>
          <Grid item sm={6}>
            <TextField
              label='Data Layer Name'
              required
              onChange={e => updateMatch(e.target.value)}
              fullWidth
              value={bowline.match || ''}
              variant='outlined'
            />
          </Grid>
          {(bowline.highlights || []).map((h, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Grid item sm={12} key={index}>
              <Grid container spacing={2} direction='row'>
                <Grid item sm={12}>
                  <SectionHeader title={`Highlight ${index + 1}`} />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <FormControlLabel
                    label='isPii'
                    labelPlacement='end'
                    control={
                      (
                        <Checkbox
                          checked={!!h.isPii}
                          onChange={(e) => {
                            updateHighlight(e.target.checked, index, 'isPii');
                          }}
                          inputProps={{ 'aria-label': 'isPii' }}
                        />
                      )
}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    fullWidth
                    label='Key'
                    onChange={e => updateHighlight(e.target.value, index, 'key')}
                    placeholder='What is the key to be scraped?'
                    required
                    value={h.key || ''}
                    variant='outlined'
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    fullWidth
                    label='Match'
                    onChange={e => updateHighlight(e.target.value, index, 'match')}
                    placeholder='Use this if the key is nested into the object: eg: [0].foo.bar'
                    value={h.match || ''}
                    variant='outlined'
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    fullWidth
                    label='Type'
                    onChange={e => updateHighlight(e.target.value, index, 'type')}
                    required
                    select
                    value={h.type || ''}
                    variant='outlined'
                  >
                    {Object.keys(HighlightTypes).map(
                      t => <MenuItem key={t} value={t}>{t}</MenuItem>,
                    )}
                  </TextField>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    fullWidth
                    label='Data Type'
                    onChange={e => updateHighlight(e.target.value, index, 'dataType')}
                    required
                    select
                    value={h.dataType || ''}
                    variant='outlined'
                  >
                    {Object.keys(AttributeDataTypes).map(t => (
                      <MenuItem key={t} value={t}>
                        {snakeToDisplayString(AttributeDataTypes[t])}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    multiline
                    fullWidth
                    label='Delimiter'
                    onChange={e => updateHighlight(e.target.value, index, 'delimiter')}
                    placeholder='Does this contain multiple values? If so enter the delimiter to separate those values'
                    value={h.delimiter || ''}
                    variant='outlined'
                  />
                </Grid>
              </Grid>
            </Grid>
          ))}
          <Grid item sm={6}>
            <HButton onClick={addHighlight} variant='outlined'>Add Highlight</HButton>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default PropertyConfigurationBowlineForm;
