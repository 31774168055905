import React from 'react';
import { Handle, Position } from 'reactflow';
import { styled } from '@mui/material/styles';


const handlePosition = (position) => [Position.Left, Position.Right].includes(position);

const StyledHandle = styled(Handle)(({ theme, position }) => ({
  // Note: This double ampersand increases specificity of these styles for overriding reactflow
  '&&': {
    background: theme.palette.background.dark,
    border: 'none',
    width: handlePosition(position) ? '2px' : '30px',
    height: handlePosition(position) ? '30px' : '2px',
    borderRadius: 2,
  },
}));

export const HHandle = (props) => (
  <StyledHandle
    {...props}
  />
);
