import Accordion from '@mui/material/Accordion';
import AddIcon from '@mui/icons-material/Add';
import FlowActionButtons from 'components/Flow/FlowActionButtons';
import FlowMetadataEditModal from 'components/Flow/FlowMetadataEditModal';
import Grid from '@mui/material/Grid';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import { HButton } from 'BaseComponents';
import { habuColors } from 'Theme';
import { styled } from '@mui/material/styles';

// TODO - We can make this a generic component and use it in other places
const StyledTypography = styled(Typography)({
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: habuColors.secondary[10],
  },
});

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary {...props} />
))(() => ({
  background: habuColors.neutral.white,
  border: `1px solid ${habuColors.neutral[20]}`,
}));

const FlowBuilderHeader = ({ flow }) => {
  const [editingField, setEditingField] = useState();
  const [metadataEditModalOpen, setMetadataEditModalOpen] = useState(false);

  const handleOpen = (event, val) => {
    event.stopPropagation();
    setEditingField(val);
    setMetadataEditModalOpen(true);
  };

  const handleClose = () => {
    setMetadataEditModalOpen(false);
  };

  return (
    <>
      <Accordion elevation={0} expanded>
        <AccordionSummary
          aria-controls='flow-builder-header-content'
          id='flow-builder-header'
        >
          <Grid container>
            <Grid item xs={12}>
              {/* TODO - we should make this change for all subtitle 3 or add a new variant */}
              <Typography variant='subtitle3' fontWeight={500}>
                {flow.displayID}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {flow.name.length > 0 ? (
                <StyledTypography
                  variant='h4'
                  fontWeight='bolder'
                  onClick={(e) => handleOpen(e, 'name')}
                  data-testid='flow-name'
                >
                  {flow.name}
                </StyledTypography>
              ) : (
                <HButton
                  variant='outlined'
                  startIcon={<AddIcon />}
                  size='small'
                  sx={{ width: 150, borderRadius: 0 }}
                  onClick={(e) => handleOpen(e, 'name')}
                >
                  Add Title
                </HButton>
              )}
            </Grid>
            <Grid item xs={8}>
              {flow.description.length > 0 ? (
                <StyledTypography
                  variant='body1'
                  onClick={(e) => handleOpen(e, 'description')}
                >
                  {flow.description}
                </StyledTypography>
              ) : (
                <HButton
                  variant='outlined'
                  startIcon={<AddIcon />}
                  size='small'
                  sx={{ width: 150, borderRadius: 0 }}
                  onClick={(e) => handleOpen(e, 'description')}
                >
                  Add Description
                </HButton>
              ) }
            </Grid>
            <Grid item xs={4}>
              <FlowActionButtons />
            </Grid>
          </Grid>
        </AccordionSummary>
      </Accordion>
      {
        metadataEditModalOpen
        && (
          <FlowMetadataEditModal
            open={metadataEditModalOpen}
            field={editingField}
            onClose={handleClose}
          />
        )
      }
    </>
  );
};

export default FlowBuilderHeader;
