
export const passwordRules = [
  { id: '74E71845-4BCB-4C02-B7CA-EB6BFA71A205', title: '1 English letter', test: str => /^.*[A-Z a-z].*/.test(str) },
  { id: 'FD1BD4B6-B14B-4C1A-9F38-2E64AEC989C0', title: '1 Special character', test: str => /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/g.test(str) },
  { id: '1B2665A1-5AC9-4FF6-9522-0E97359E9C6F', title: '1 Digit', test: str => /\d/.test(str) },
  { id: '39ECF9FF-5D47-4875-AF73-3F8F14BACD9D', title: '12 or more characters', test: str => str.length > 11 },
];

export const isValidPassword = str => passwordRules.every(rule => rule.test(str));

export const generateWarning = (password) => {
  const missingRequirements = passwordRules
    .filter(rule => !rule.test(password))
    .map(rule => rule.title);

  if (missingRequirements.length === 0) {
    return null;
  }

  const warningMessage = missingRequirements.join(', ');

  if (missingRequirements.length === passwordRules.length) {
    return `Please make sure your password contains: ${warningMessage}.`;
  }

  return `Password must have ${warningMessage}.`;
};

