export const ORGANIZATION_PARAMETERS = {
  CREATE_ORG_PARAMETERS: 'CREATE_ORG_PARAMETERS',
  CREATE_ORG_PARAMETERS_SUCCESS: 'CREATE_ORG_PARAMETERS_SUCCESS',
  CREATE_ORG_PARAMETERS_ERROR: 'CREATE_ORG_PARAMETERS_ERROR',
  FETCH_ORG_PARAMETERS: 'FETCH_ORG_PARAMETERS',
  FETCH_ORG_PARAMETERS_SUCCESS: 'FETCH_ORG_PARAMETERS_SUCCESS',
  FETCH_ORG_PARAMETERS_ERROR: 'FETCH_ORG_PARAMETERS_ERROR',
  UPDATE_ORG_PARAMETER: 'UPDATE_ORG_PARAMETER',
  UPDATE_ORG_PARAMETER_SUCCESS: 'UPDATE_ORG_PARAMETER_SUCCESS',
  UPDATE_ORG_PARAMETER_ERROR: 'UPDATE_ORG_PARAMETER_ERROR',
  DELETE_ORG_PARAMETER: 'DELETE_ORG_PARAMETER',
  DELETE_ORG_PARAMETER_SUCCESS: 'DELETE_ORG_PARAMETER_SUCCESS',
  DELETE_ORG_PARAMETER_ERROR: 'DELETE_ORG_PARAMETER_ERROR',
};
