import FormControlLabel from '@mui/material/FormControlLabel';
import MaterialSwitch from '@mui/material/Switch';
import React from 'react';
import Typography from '@mui/material/Typography';


const Switch = ({ label, testID, ...rest }) => (
  <FormControlLabel
    control={<MaterialSwitch data-testid={testID} color='success' {...rest} />}
    label={<Typography variant='caption'>{label}</Typography>}
    checked={rest.checked}
  />
);

export default Switch;
