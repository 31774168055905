import React, { memo } from 'react';
import { Typography } from '@mui/material';
import { cellTypesEnum } from 'BaseComponents/HDataTable/constants';
import { styled } from '@mui/material/styles';


const StyledTypography = styled(Typography, {
  shouldForwardProp: (props) => props !== 'type',
})(({ type }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: {
    [cellTypesEnum.LINE_2]: '2',
    [cellTypesEnum.LINE_3]: '3',
    [cellTypesEnum.LINE_4]: '4',
    [cellTypesEnum.LINE_5]: '5',
  }[type] || 1,
}));

export const CellText = memo(({
  type,
  children,
}) => (
  <StyledTypography type={type}>
    {children}
  </StyledTypography>
));

CellText.displayName = 'CellText';
