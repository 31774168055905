import http from 'utils/http';
import { CLEAN_ROOM_PARTNERS } from 'redux/actions/Types';
import { SyntheticRedux } from 'synthetic-redux';
import { all } from 'redux-saga/effects';
import { combineReducers } from 'redux';
import { crPartners } from 'utils/urlConstants';


const fetchPartnersList = new SyntheticRedux(
  { type: CLEAN_ROOM_PARTNERS.FETCH_CR_PARTNERS }, 'list',
);
fetchPartnersList.ignite = (orgId, crId, filter) => fetchPartnersList.actions.ignite({
  errorMeta: 'CR Partner List: Fetch Partner List',
  method: http.post,
  payload: filter,
  url: crPartners(orgId, crId).list,
});

const inviteCrPartner = new SyntheticRedux(
  { type: CLEAN_ROOM_PARTNERS.INVITE_PARTNER }, 'response',
);
inviteCrPartner.ignite = (orgId, crId, payload) => inviteCrPartner.actions.ignite({
  errorMeta: 'CR Partner: Invite Partner',
  method: http.post,
  payload,
  url: crPartners(orgId, crId).invitePartner,
});

const addPartner = new SyntheticRedux(
  { type: CLEAN_ROOM_PARTNERS.ADD_PARTNER }, 'response',
);
addPartner.ignite = (orgId, crId, payload) => addPartner.actions.ignite({
  errorMeta: 'CR Partner: Add Partner',
  method: http.post,
  payload,
  url: crPartners(orgId, crId).add,
});

const fetchCrPartnerInvites = new SyntheticRedux(
  { type: CLEAN_ROOM_PARTNERS.FETCH_CR_INVITATIONS }, 'response',
);
fetchCrPartnerInvites.ignite = (orgId) => fetchCrPartnerInvites.actions.ignite({
  errorMeta: 'CR Partner: Fetch Partner Invitations',
  method: http.get,
  url: crPartners(orgId).fetchInvitations,
});

const linkCrPartners = new SyntheticRedux(
  { type: CLEAN_ROOM_PARTNERS.LINK_CR_PARTNERS }, 'response',
);
linkCrPartners.ignite = (orgId, crId, payload) => linkCrPartners.actions.ignite({
  errorMeta: 'CR Partner: Linking Clean Room Partners',
  method: http.post,
  payload,
  url: crPartners(orgId, crId).linkPartner,
});


const removePartner = new SyntheticRedux(
  { type: CLEAN_ROOM_PARTNERS.REMOVE_PARTNER }, 'response',
);
removePartner.ignite = (orgId, crId, partnerId) => removePartner.actions.ignite({
  errorMeta: 'CR Partner: Remove Partner',
  method: http.delete,
  url: crPartners(orgId, crId).detail(partnerId),
});

const fetchCrPartnerInvitationList = new SyntheticRedux(
  { type: CLEAN_ROOM_PARTNERS.FETCH_CR_PARTNER_INVITATIONS }, 'response',
);

fetchCrPartnerInvitationList.ignite = (
  orgId = null, crId = null, acId = null,
) => fetchCrPartnerInvitationList.actions.ignite({
  errorMeta: 'CR Partner Invitation List: Fetch Clean Room Partner Invitation List',
  method: http.get,
  url: acId ? crPartners().invitationListByAccount(acId) : crPartners(orgId, crId).invitationList,
});

const fetchCrPartnerInvitations = new SyntheticRedux(
  { type: CLEAN_ROOM_PARTNERS.FETCH_CR_RECEIVED_INVITATIONS }, 'list',
);

fetchCrPartnerInvitations.ignite = (orgId) => fetchCrPartnerInvitations.actions.ignite({
  errorMeta: 'CR Partner Received Invitation List: Fetch Clean Room Partner Received Invitation List',
  method: http.get,
  url: crPartners(orgId).fetchPartnerInvitations,
});

const submitCrInvitation = new SyntheticRedux(
  { type: CLEAN_ROOM_PARTNERS.ACCEPT_INVITATION }, 'response',
);

submitCrInvitation.ignite = (orgId, crId, invitationId, acceptInvitation,
  payload) => submitCrInvitation.actions.ignite({
  errorMeta: 'CR Partner Accept: Accept Partner Invitation',
  method: http.post,
  payload,
  url: acceptInvitation ? crPartners(orgId, crId).acceptInvite(invitationId)
    : crPartners(orgId, crId).rejectInvite(invitationId),
});

const crPartnersReducer = combineReducers({
  addPartner: addPartner.reducer,
  submitCrInvitation: submitCrInvitation.reducer,
  crPartnerInvitationList: fetchCrPartnerInvitationList.reducer,
  crReceivedInvitationList: fetchCrPartnerInvitations.reducer,
  fetchCrPartnerInvites: fetchCrPartnerInvites.reducer,
  invitePartner: inviteCrPartner.reducer,
  linkCrPartners: linkCrPartners.reducer,
  partners: fetchPartnersList.reducer,
  removePartner: removePartner.reducer,
});

function* crPartnersSaga() {
  yield all([
    addPartner.sagaEffect,
    submitCrInvitation.sagaEffect,
    inviteCrPartner.sagaEffect,
    fetchCrPartnerInvites.sagaEffect,
    fetchCrPartnerInvitationList.sagaEffect,
    fetchCrPartnerInvitations.sagaEffect,
    fetchPartnersList.sagaEffect,
    linkCrPartners.sagaEffect,
    removePartner.sagaEffect,
  ]);
}

export {
  addPartner,
  submitCrInvitation,
  crPartnersReducer,
  crPartnersSaga,
  fetchCrPartnerInvitationList,
  fetchCrPartnerInvitations,
  inviteCrPartner,
  fetchCrPartnerInvites,
  fetchPartnersList,
  linkCrPartners,
  removePartner,
};
