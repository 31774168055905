import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import { HButton } from 'BaseComponents';


const dataType = [
  { id: 'username', display: 'Username' },
  { id: 'email', display: 'Email' },
  { id: 'password', display: 'Password' },
  { id: 'secret', display: 'Secret' },
];

const DataCredentialConfiguration = ({ configurations = [], updateConfigurations }) => {
  const updateRow = (value, row, key) => {
    const updateArr = configurations.map(el => (el.index === row.index
      ? ({ ...el, [key]: value }) : el
    ));
    updateConfigurations(updateArr);
  };
  // todo need to update all of the index of object in array
  const removeConfiguration = (row) => {
    updateConfigurations(configurations.filter(i => i.id !== row.id));
  };

  const getEmptyConfiguration = () => ({
    name: '',
    index: configurations.length + 1,
    type: '',
    encrypted: false,
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {configurations.map(row => (
              <TableRow hover key={row.index}>
                <TableCell>
                  <TextField
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    label='CREDENTIAL NAME'
                    variant='outlined'
                    margin='normal'
                    value={row.name}
                    required
                    onChange={e => updateRow(e.target.value, row, 'name')}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    label='TYPE'
                    margin='normal'
                    onChange={e => updateRow(e.target.value, row, 'type')}
                    select
                    required
                    value={row.type}
                    variant='outlined'
                  >
                    {dataType.map(o => (
                      <MenuItem key={o.id} value={o.id}>
                        {o.display}
                      </MenuItem>
                    ))}
                  </TextField>
                </TableCell>
                <TableCell>
                  <TextField
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    label='ENCRYPTED'
                    margin='normal'
                    onChange={e => updateRow(e.target.value, row, 'encrypted')}
                    select
                    required
                    value={row.encrypted}
                    variant='outlined'
                  >
                    <MenuItem key='F' value={false}>
                      False
                    </MenuItem>
                    <MenuItem key='True' value>
                      True
                    </MenuItem>
                  </TextField>

                </TableCell>
                <TableCell>
                  <HButton
                    color='primary'
                    size='small'
                    variant='contained'
                    onClick={() => removeConfiguration(row)}
                  >
                    Delete
                  </HButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
      <Grid item>
        <HButton
          size='small'
          variant='contained'
          onClick={() => updateConfigurations([...configurations, getEmptyConfiguration()])}
        >
          Add Another Credential
        </HButton>
      </Grid>
    </Grid>
  );
};

export default DataCredentialConfiguration;
