import Checkbox from '@mui/material/Checkbox';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import React, { useContext } from 'react';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { ExpandMore } from '@mui/icons-material';
import { FlowBuilderContext } from 'pages/Flow/FlowBuilder/FlowBuilderContextProvider';
import { HButton } from 'BaseComponents';
import { IconButton } from '@mui/material';
import { NODE_ACCESS_TYPE } from 'utils/ignoramusEnums';
import { useGetFlowNodeAccess, useUpdateFlowNodeAccess } from 'api/flows';


const QuestionSidePanelPrivacy = ({ nodeId }) => {
  const { crFlowId, crId } = useContext(FlowBuilderContext);
  const [listOfOrgs, setListOfOrgs] = React.useState([]);
  const [orgsEditable, setOrgsEditable] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const countOfOrgsSelected = listOfOrgs
    .filter((org) => org.accessType === NODE_ACCESS_TYPE.FULL_ACCESS).length;

  const {
    isLoading, isError, isSuccess, data,
  } = useGetFlowNodeAccess({ crId, crFlowId, nodeId });
  const { mutate: updateNodeAccess } = useUpdateFlowNodeAccess({ crId, crFlowId, nodeId });


  const handleSubmit = () => {
    updateNodeAccess({ nodeAccess: listOfOrgs });
  };

  React.useEffect(() => {
    if (isSuccess) {
      setListOfOrgs(data?.data.nodeAccess);
      setOrgsEditable(data?.data.editable);
    }
  }, [data?.data.nodeAccess, data?.data.editable, isSuccess]);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleOrgAccessChange = (orgId, accessType) => {
    const updatedList = listOfOrgs.map((org) => {
      if (org.organizationID === orgId) {
        return {
          ...org,
          accessType: accessType === NODE_ACCESS_TYPE.NO_ACCESS
            ? NODE_ACCESS_TYPE.FULL_ACCESS
            : NODE_ACCESS_TYPE.NO_ACCESS,
        };
      }
      return org;
    });
    setListOfOrgs(updatedList);
  };

  if (isLoading || isError) {
    return (
      <Grid container spacing={0} sx={{ paddingTop: 3 }}>
        <Grid item xs={12}>
          <Skeleton height={300} />
        </Grid>
      </Grid>
    );
  }


  return (
    <Grid container spacing={1} sx={{ paddingTop: 3 }}>
      <Grid item xs={12}>
        <Typography variant='subtitle1'>
          <IconButton
            onClick={handleExpandClick}
            size='small'
          >
            {expanded ? <ExpandMore /> : <ChevronRightIcon />}
          </IconButton>
          Report access
          <IconButton size='small'>
            <InfoOutlinedIcon />
          </IconButton>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='body3'>
          Only selected partner organizations can view the output of this node.
        </Typography>
      </Grid>
      <Grid item xs={12} container alignItems='center'>
        <Grid item>
          <Typography variant='subtitle2'>
            {`${countOfOrgsSelected} of ${listOfOrgs.length} partners selected`}
          </Typography>
        </Grid>
        <Grid item>
          {!expanded && (
            orgsEditable && (
            <HButton color='link' onClick={handleExpandClick}>Edit</HButton>
            )
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Collapse in={expanded} timeout='auto' unmountOnExit>
          <FormGroup>
            {listOfOrgs.map((org) => (
              <FormControlLabel
                key={org.organizationID}
                control={<Checkbox checked={org.accessType === NODE_ACCESS_TYPE.FULL_ACCESS} />}
                onChange={() => handleOrgAccessChange(org.organizationID, org.accessType)}
                label={org.organizationName}
                disabled={orgsEditable === false}
              />
            ))}
          </FormGroup>
        </Collapse>
      </Grid>
      <Grid item xs={12} sx={{ pb: 1 }}>
        <Divider />
      </Grid>
      <Grid item xs={12} container justifyContent='flex-end'>
        <HButton variant='contained' color='primary' onClick={handleSubmit} disabled={orgsEditable === false}>
          Submit
        </HButton>
      </Grid>
    </Grid>
  );
};

export default QuestionSidePanelPrivacy;
