import { FlowDatasetsManagementCell } from 'components/Flow/FlowDatasetsManagementCell';
import { FlowStageCell } from 'components/Flow/FlowStageCell';
import { cellTypesEnum } from 'BaseComponents';



export const getColumns = () => ([{
  field: 'displayID',
  name: 'Display ID',
  width: 130,
  valueGetter: ({ row }) => (row?.flow?.displayID),
}, {
  field: 'Name',
  name: 'Name',
  width: 276,
  type: cellTypesEnum.LINE_3,
  valueGetter: ({ row }) => (row?.flow?.name),
}, {
  field: 'stage',
  name: 'Stage',
  width: 70,
  component: FlowStageCell,
}, {
  field: 'description',
  name: 'Description',
  width: 276,
  type: cellTypesEnum.LINE_5,
  valueGetter: ({ row }) => (row?.flow?.description),
}, {
  field: 'Created By',
  name: 'Created By',
  width: 140,
  valueGetter: ({ row }) => (row?.flow?.userAudit.createdByUser.name),
}, {
  field: 'Created On',
  name: 'Created On',
  width: 140,
  type: cellTypesEnum.US_DATE_FORMAT,
  valueGetter: ({ row }) => (row.timeAudit.createdAt),
},
{
  field: 'Datasets',
  name: 'Datasets',
  width: 225,
  component: FlowDatasetsManagementCell,
},
{
  field: 'Runs',
  name: 'Runs',
  width: 70,
  type: cellTypesEnum.LINE_2,
  valueGetter: ({ row }) => (row?.runCount),
},
]);
