const init = () => {
  const o = {
    version: process.env.REACT_APP_VERSION,
    node_env: process.env.NODE_ENV,
    server_env: process.env.REACT_APP_ENV,
  };
  // eslint-disable-next-line no-underscore-dangle
  window.__HABU = o;
};

export {
  init,
};
