import http from 'utils/http';
import { SYNTHETIC_DATASETS } from 'redux/actions/Types';
import { SyntheticRedux } from 'synthetic-redux';
import { all } from 'redux-saga/effects';
import { combineReducers } from 'redux';
import { syntheticDatasets } from 'utils/urlConstants';


const fetchSyntheticDatasetsList = new SyntheticRedux(
  { type: SYNTHETIC_DATASETS.FETCH_SYNTHETIC_DATASETS }, 'list',
);

fetchSyntheticDatasetsList.ignite = (orgId) => fetchSyntheticDatasetsList.actions.ignite({
  errorMeta: `Internal Admin : Fetch Synthetic Datasets for ${orgId}`,
  method: http.post,
  url: syntheticDatasets(orgId).list,
});

const createSyntheticDataset = new SyntheticRedux(
  { type: SYNTHETIC_DATASETS.CREATE_SYNTHETIC_DATASETS }, 'response',
);

createSyntheticDataset.ignite = (orgId, payload) => createSyntheticDataset.ignite({
  errorMeta: `Internal Admin : Create Synthetic Datasets for ${orgId}`,
  method: http.post,
  payload,
  url: syntheticDatasets(orgId).create,
});

const syntheticDatasetsReducer = combineReducers({
  datasets: fetchSyntheticDatasetsList.reducer,
  create: createSyntheticDataset.reducer,
});


function* syntheticDatasetsSaga() {
  yield all([
    fetchSyntheticDatasetsList.sagaEffect,
    createSyntheticDataset.sagaEffect,
  ]);
}

export {
  syntheticDatasetsReducer,
  syntheticDatasetsSaga,
  fetchSyntheticDatasetsList,
  createSyntheticDataset,
};
