import * as actions from 'pages/InternalAdmin/organization/pages/OrganizationParameters/redux/actions';
import Logger from 'components/Common/Logger';
import http from 'utils/http';
import { ORGANIZATION_PARAMETERS } from 'redux/actions/Types';
import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';


function* fetchOrgParams(action) {
  const { parameters, error } = yield call(http.get, action.url);
  if (error) {
    Logger.error(error, `Could not fetch org paramaeters Url: ${action.url}. Error: ${error}`);
    return yield put(actions.fetchOrgParametersError(error));
  }
  else {
    return yield put(actions.fetchOrgParametersSuccess(parameters));
  }
}

function* createOrgParams(action) {
  const { parameters, error } = yield call(http.post, action.url, action.payload);
  if (error) {
    Logger.error(error, `Could not create org paramaeters Url: ${action.url}. Error: ${error}`);
    return yield put(actions.createOrgParametersError(error));
  }
  else {
    return yield put(actions.createOrgParametersSuccess(parameters));
  }
}

function* updateOrgParam(action) {
  const { parameter, error } = yield call(http.put, action.url, action.payload);
  if (error) {
    Logger.error(error, `Could not update org paramaeters Url: ${action.url}. Error: ${error}`);
    return yield put(actions.updateOrgParameterError(error));
  }
  else {
    return yield put(actions.updateOrgParameterSuccess(parameter));
  }
}

function* deleteOrgParam(action) {
  const { parameter, error } = yield call(http.delete, action.url);
  if (error) {
    Logger.error(error, `Could not delete org paramaeters Url: ${action.url}. Error: ${error}`);
    return yield put(actions.deleteOrgParametersError(error));
  }
  else {
    return yield put(actions.deleteOrgParametersSuccess(parameter));
  }
}

export default function* root() {
  yield all([
    takeLatest(ORGANIZATION_PARAMETERS.FETCH_ORG_PARAMETERS, fetchOrgParams),
    takeLatest(ORGANIZATION_PARAMETERS.CREATE_ORG_PARAMETERS, createOrgParams),
    takeLatest(ORGANIZATION_PARAMETERS.UPDATE_ORG_PARAMETER, updateOrgParam),
    takeLatest(ORGANIZATION_PARAMETERS.DELETE_ORG_PARAMETER, deleteOrgParam),
  ]);
}

export {
  fetchOrgParams,
};
