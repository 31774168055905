import React, { memo } from 'react';
import { CellDateFormat } from 'BaseComponents/HDataTable/CellTypes/CellDateFormat';
import { CellDateRange } from 'BaseComponents/HDataTable/CellTypes/CellDateRange';
import { CellText } from 'BaseComponents/HDataTable/CellTypes/CellText';
import { cellTypesEnum } from 'BaseComponents/HDataTable/constants';


export const CellTypes = memo(({
  field,
  type,
  row,
  valueGetter,
  children,
}) => {
  const text = (valueGetter && valueGetter({ row })) ?? (row[field] ?? '-');

  if (children) {
    return children;
  }

  switch (type) {
    case cellTypesEnum.US_DATE_FORMAT:
    case cellTypesEnum.UI_DATE_FORMAT:
    case cellTypesEnum.US_DATE_TIME_FORMAT:
    case cellTypesEnum.UI_DISPLAY_DATE_TIME_FORMAT:
    case cellTypesEnum.UI_DATE_TIME_FORMAT:
    case cellTypesEnum.UI_SHORT_DATE:
      return (
        <CellDateFormat type={type}>
          {text}
        </CellDateFormat>
      );
    case cellTypesEnum.DATE_RANGE_FORMAT:
      return (
        <CellDateRange type={type} row={row} />
      );
    case cellTypesEnum.LINE_2:
    case cellTypesEnum.LINE_3:
    case cellTypesEnum.LINE_4:
    case cellTypesEnum.LINE_5:
    default:
      return (
        <CellText type={type}>
          {text}
        </CellText>
      );
  }
});

CellTypes.displayName = 'CellTypes';
