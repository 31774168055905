export const QUESTION_MANAGEMENT = {
  DEPROVISION_QUESTION: 'DEPROVISION_QUESTION',
  FETCH_ACC_AVAILABLE_QUESTIONS: 'QM/FETCH_ACC_AVAILABLE_QUESTIONS',
  FETCH_ACC_LIST_FILTERS: 'QM/FETCH_ACC_LIST_FILTERS',
  FETCH_QUESTION_DETAILS: 'QM/FETCH_QUESTION_DETAILS',
  FETCH_PROVISIONED_QUESTION_ACC_AND_ORG: 'QM/FETCH_PROVISIONED_QUESTION_ACC_AND_ORG',
  FETCH_PROVISIONED_QUESTION_ORG: 'QM/FETCH_PROVISIONED_QUESTION_ORG',
  FETCH_ORG_AVAILABLE_QUESTIONS: 'QM/FETCH_ORG_AVAILABLE_QUESTIONS',
  FETCH_ORG_LIST_FILTERS: 'QM/FETCH_ORG_LIST_FILTERS',
  FETCH_CR_PROVISIONED_QUESTION_LIST_FILTERS: 'FETCH_CR_PROVISIONED_QUESTION_LIST_FILTERS',
  FETCH_CR_PROVISIONED_QUESTIONS: 'FETCH_CR_PROVISIONED_QUESTIONS',
  FETCH_ORG_PROVISIONED_QUESTIONS: 'QM/FETCH_ORG_PROVISIONED_QUESTIONS',
  FETCH_PLATFORM_LIST_FILTERS: 'QM/FETCH_PLATFORM_LIST_FILTERS',
  FETCH_QUESTION_OVERVIEW: 'FETCH_QUESTION_OVERVIEW',
  PROVISION_ORG_QUESTIONS: 'QM/PROVISION_ORG_QUESTIONS ',
  PROVISION_PLATFORM_QUESTIONS: 'QM/PROVISION_PLATFORM_QUESTIONS',
};
