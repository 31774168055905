import {
  UI_DATE_FORMAT,
  UI_DATE_TIME_FORMAT,
  UI_DISPLAY_DATE_TIME_FORMAT,
  UI_SHORT_DATE,
  US_DATE_FORMAT,
  US_DATE_TIME_FORMAT,
} from 'utils/appConstants';
import { cellTypesEnum } from 'BaseComponents/HDataTable/constants';


export const getDateFormat = (format) => ({
  [cellTypesEnum.US_DATE_FORMAT]: US_DATE_FORMAT,
  [cellTypesEnum.UI_DATE_FORMAT]: UI_DATE_FORMAT,
  [cellTypesEnum.US_DATE_TIME_FORMAT]: US_DATE_TIME_FORMAT,
  [cellTypesEnum.UI_DISPLAY_DATE_TIME_FORMAT]: UI_DISPLAY_DATE_TIME_FORMAT,
  [cellTypesEnum.UI_DATE_TIME_FORMAT]: UI_DATE_TIME_FORMAT,
  [cellTypesEnum.UI_SHORT_DATE]: UI_SHORT_DATE,
}[format] || US_DATE_FORMAT);
