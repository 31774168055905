import CredentialSourceDetails
  from 'pages/InternalAdmin/application/pages/CredentialSourceDetails/CredentialSourceDetails';
import OrganizationCredentials
  from 'pages/OrganizationManagement/organization/pages/OrganizationCredentails/list';
import OrganizationCustomization
  from 'pages/OrganizationManagement/organization/pages/OrganizationCustomization/OrganizationCustomization';
import OrganizationCustomizationEdit
  from 'pages/OrganizationManagement/organization/pages/OrganizationCustomization/OrganizationCustomizationEdit';
import OrganizationDataSourceLocations
  from 'pages/OrganizationManagement/organization/pages/OrganizationDataSourceLocations/OrganizationDataSourceLocations';
import OrganizationRole
  from 'pages/OrganizationManagement/organization/pages/OrganizationRoles/OrganizationRole';
import OrganizationRoles
  from 'pages/OrganizationManagement/organization/pages/OrganizationRoles/list';
import OrganizationUser
  from 'pages/OrganizationManagement/organization/pages/OrganizationUsers/OrganizationUser';
import OrganizationUsers
  from 'pages/OrganizationManagement/organization/pages/OrganizationUsers/list';
import PrivateRoute from 'components/Common/PrivateRoute';
import React from 'react';
import { Switch } from 'react-router-dom';
import { organizationManagement } from 'utils/spaUrls';
import { userPermissions } from 'utils/appConstants';


const IAMOrganizationRouter = () => (
  <Switch>
    <PrivateRoute
      path={organizationManagement.organization.credentials.list}
      component={OrganizationCredentials}
      allowList={[userPermissions.orgAdmin, userPermissions.dataImportJobsAdmin]}
      exact
    />
    <PrivateRoute
      path={`${organizationManagement.organization.credentialSourceDetail}/:id?`}
      component={CredentialSourceDetails}
      exact
    />
    <PrivateRoute
      path={organizationManagement.organization.roles.list}
      component={OrganizationRoles}
      exact
    />
    <PrivateRoute
      path={organizationManagement.organization.roles.create}
      component={OrganizationRole}
      exact
    />
    <PrivateRoute
      path={organizationManagement.organization.roles.edit()}
      component={OrganizationRole}
      exact
    />
    <PrivateRoute
      path={organizationManagement.organization.dataSourceLocations}
      component={OrganizationDataSourceLocations}
      allowList={[userPermissions.orgAdmin, userPermissions.dataImportJobsAdmin]}
      exact
    />
    <PrivateRoute
      path={organizationManagement.organization.users.list}
      component={OrganizationUsers}
      exact
    />
    <PrivateRoute
      path={organizationManagement.organization.users.create}
      component={OrganizationUser}
      exact
    />
    <PrivateRoute
      path={organizationManagement.organization.users.edit()}
      component={OrganizationUser}
      exact
    />
    <PrivateRoute
      path={organizationManagement.organization.customization.landing}
      component={OrganizationCustomization}
      exact
    />
    <PrivateRoute
      path={organizationManagement.organization.customization.edit}
      component={OrganizationCustomizationEdit}
      exact
    />
  </Switch>
);

export default IAMOrganizationRouter;
