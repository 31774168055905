import React from 'react';
import { Dialog } from '@mui/material';
import { HButton, HSpinner } from 'BaseComponents';
import { HDialogActions } from 'BaseComponents/HDialog/HDialogActions';
import { HDialogTitle } from 'BaseComponents/HDialog/HDialogTitle';


export const HDialog = ({
  open, onClose, title, cancelText = 'Cancel', onSubmit, submitText = 'Submit', children, loading = false, disabled = false, ...restProps
}) => {
  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (onSubmit) {
      onSubmit();
    }
  };

  return (
    <Dialog onClose={handleClose} open={open} {...restProps}>
      {title && (
        <HDialogTitle onClose={onClose}>
          {title}
        </HDialogTitle>
      )}
      {children}
      {onSubmit && (
        <HDialogActions>
          <HButton variant='outlined' onClick={handleClose}>{cancelText}</HButton>
          <HButton
            variant='contained'
            onClick={handleSubmit}
            disabled={loading || disabled}
            endIcon={loading ? <HSpinner isButton /> : null}
          >
            {submitText}
          </HButton>
        </HDialogActions>
      )}
    </Dialog>
  );
};
