import React from 'react';
import { HChip } from 'BaseComponents';
import {
  Stack,
} from '@mui/material';

/**
 * @param {Array<String>} values
 */
export const HTagsList = ({
  values = [],
  maxVisible = 1,
  id = 'tags-list',
  ...restProps
}) => {
  const dataTestid = restProps['data-testid'] ?? 'HTagsList';
  const visibleTags = values.slice(0, maxVisible);
  const collapsedTags = values.slice(maxVisible);

  return (
    <Stack
      direction='row'
      spacing={1}
      id={id}
      data-testid={dataTestid}
    >
      {visibleTags.map(vt => (
        <HChip
          key={vt}
          size='small'
          label={vt}
        />
      ))}
      {collapsedTags.length > 0 && (
        <HChip
          label={collapsedTags.length}
          tooltipTitle={collapsedTags.join()}
        />
      )}
    </Stack>
  );
};
