import Grid from '@mui/material/Grid';
import PageHeader from 'components/Common/PageHeader';
import React from 'react';
import { Card } from '@mui/material';
import { ListControls } from 'components/Common/ListControls';
import {
  OrganizationRoleTable,
} from 'pages/OrganizationManagement/organization/pages/OrganizationRoles/OrganizationRoleTable/OrganizationRoleTable';
import { crumbs } from 'components/Common/BreadcrumbsNavigation';
import { fetchRoleList } from 'redux/actions/IamActions';
import { organizationManagement } from 'utils/spaUrls';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';


const OrganizationRoles = () => {
  const dispatch = useDispatch();
  const roles = useSelector(state => state.iam.roleList);
  const orgId = useSelector(state => state.session.user.primaryOrganization.organization.ID);
  const history = useHistory();

  React.useEffect(() => {
    dispatch(fetchRoleList(orgId));
  }, [dispatch, orgId]);

  return (
    <>
      <PageHeader
        title='Organization Roles'
        hideBackIcon
        breadcrumbs={[crumbs.home]}
      />
      <Grid container spacing={0} direction='column'>
        <Grid item>
          <Card>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ListControls
                  createButton={{
                    onClick: () => history.push(organizationManagement.organization.roles.create),
                    text: 'New Role',
                  }}
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item>
          <OrganizationRoleTable
            list={roles}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default OrganizationRoles;
