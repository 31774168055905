import React from 'react';
import { ReactComponent as PartitionIconSVG } from 'assets/partition.svg';
import { Stack, Tooltip, Typography } from '@mui/material';


const PartitionIcon = () => (
  <Tooltip
    title={(
      <Stack p={1}>
        <Typography variant='h3'>Partitionable Data</Typography>
        <Typography variant='body2' mt={1}>
          This data can be used as a filter to save resources when running reports.
        </Typography>
      </Stack>
    )}
    placement='top-end'
  >
    <PartitionIconSVG style={{ marginLeft: '8px' }} />
  </Tooltip>
);

export default PartitionIcon;
