import { CLEAN_ROOM_ADMIN } from 'redux/actions/Types';


const cleanRoomOrgAdminReducer = (state = {}, action) => {
  switch (action.type) {
    case CLEAN_ROOM_ADMIN.FETCH_ALL_CR_QUESTIONS:
      return { ...state, allCRQuestions: undefined };
    case CLEAN_ROOM_ADMIN.FETCH_ALL_CR_QUESTIONS_SUCCESS:
      return { ...state, allCRQuestions: action.payload };
    case CLEAN_ROOM_ADMIN.FETCH_ALL_CR_QUESTIONS_ERROR:
      return { ...state, allCRQuestions: { error: action.payload } };
    case CLEAN_ROOM_ADMIN.FETCH_ORG_CR_QUESTIONS:
      return { ...state, orgCRQuestions: undefined };
    case CLEAN_ROOM_ADMIN.FETCH_ORG_CR_QUESTIONS_SUCCESS:
      return { ...state, orgCRQuestions: action.payload };
    case CLEAN_ROOM_ADMIN.FETCH_ORG_CR_QUESTIONS_ERROR:
      return { ...state, orgCRQuestions: { error: action.payload } };
    case CLEAN_ROOM_ADMIN.UPDATE_ORG_CR_QUESTIONS:
      return { ...state, updateOrgQuestions: { loading: true } };
    case CLEAN_ROOM_ADMIN.UPDATE_ORG_CR_QUESTIONS_SUCCESS:
      return { ...state, updateOrgQuestions: action.payload };
    case CLEAN_ROOM_ADMIN.UPDATE_ORG_CR_QUESTIONS_ERROR:
      return { ...state, updateOrgQuestions: { error: action.payload } };
    default:
      return state;
  }
};

export default cleanRoomOrgAdminReducer;
