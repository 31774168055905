import React from 'react';
import { axiosInstance } from 'utils/http';
import { cleanRoom } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch } from 'react-redux';
import { useMutation, useQuery } from 'react-query';


const useCreateAlert = () => {
  const dispatch = useDispatch();
  const {
    isLoading, isError, isSuccess, mutate, reset,
  } = useMutation(
    async ({
      orgId, crId, crQuestionId, payload,
    }) => axiosInstance
      .post(cleanRoom(orgId).createAlert(crId, crQuestionId), payload),
    {
      onSuccess: () => dispatch(showAlert({ message: 'Alert has been created.', type: 'success' })),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
      retry: false,
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    mutate,
    reset,
  };
};

const useFetchAlert = (orgId, crId, alertID) => {
  const dispatch = useDispatch();
  const [data, setData] = React.useState([]);
  const {
    isLoading, isError, isSuccess, refetch, isFetching,
  } = useQuery(
    ['FetchAlert', orgId, crId, alertID],
    async () => axiosInstance.get(cleanRoom(orgId).alert(crId, alertID)),
    {
      enabled: !!alertID,
      refetchOnWindowFocus: false,
      onSuccess: (res) => setData(res.data.alertDetails),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    data,
    refetch,
    isFetching,
  };
};

const useFetchAlertList = (orgId, crId) => {
  const dispatch = useDispatch();
  const {
    isLoading, isError, isSuccess, refetch, isFetching, data,
  } = useQuery(
    ['ListAlerts', orgId, crId],
    async () => axiosInstance.get(cleanRoom(orgId).listAlerts(crId)),
    {
      enabled: !!crId,
      refetchOnWindowFocus: false,
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    data,
    refetch,
    isFetching,
  };
};

const useUpdateAlert = () => {
  const dispatch = useDispatch();
  const {
    isLoading, isError, isSuccess, mutate, reset,
  } = useMutation(
    async ({
      orgId, crId, alertID, payload,
    }) => axiosInstance.put(
      cleanRoom(orgId).alert(crId, alertID), payload,
    ),
    {
      onSuccess: () => dispatch(showAlert({ message: 'Alert has been updated.', type: 'success' })),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    mutate,
    reset,
  };
};

const useUpdateAlertStatus = () => {
  const dispatch = useDispatch();
  const {
    isLoading, isError, isSuccess, mutate, reset,
  } = useMutation(
    async ({
      orgId, crId, alertID, payload,
    }) => axiosInstance.patch(
      cleanRoom(orgId).updateAlertStatus(crId, alertID), payload,
    ),
    {
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    mutate,
    reset,
  };
};

const useDeleteAlert = () => {
  const dispatch = useDispatch();
  const {
    isLoading, isError, isSuccess, mutate, reset,
  } = useMutation(
    async ({
      orgId, crId, alertID,
    }) => axiosInstance
      .delete(cleanRoom(orgId).alert(crId, alertID)),
    {
      onSuccess: () => dispatch(showAlert({ message: 'Alert has been deleted.', type: 'success' })),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    mutate,
    reset,
  };
};

const useFetchAlertNotifications = (orgId, crId) => {
  const dispatch = useDispatch();
  const [data, setData] = React.useState([]);

  const {
    isLoading, isError, isSuccess, mutate,
  } = useMutation(
    (payload) => axiosInstance.post(cleanRoom(orgId).getAlertNotifications(crId), payload),
    {
      onSuccess: (res) => setData(res.data),
      onError: (err) => {
        dispatch(showAlert({ message: err.response.data.message, type: 'error' }));
      },
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    data,
    mutate,
  };
};

const useFetchAlertResults = (orgId, crId, alertID, timestamp) => {
  const dispatch = useDispatch();
  const [data, setData] = React.useState([]);
  const {
    isLoading, isError, isSuccess, refetch, isFetching,
  } = useQuery(
    ['AlertResults', orgId, crId, alertID, timestamp],
    async () => axiosInstance.get(cleanRoom(orgId).getAlertResults(crId, alertID, timestamp)),
    {
      enabled: !!crId && !!alertID && !!timestamp,
      refetchOnWindowFocus: false,
      onSuccess: (res) => setData(res.data.alertResults),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    data,
    refetch,
    isFetching,
  };
};

const useFetchAlertNotificationsFilters = (orgId, crId) => {
  const dispatch = useDispatch();

  const {
    isLoading, data,
  } = useQuery(
    ['AlertNotificationsFilters', orgId, crId],
    async () => {
      const { data: responseData } = await axiosInstance.get(cleanRoom(orgId)
        .getAlertsNotificationsFilters(crId));
      return responseData;
    },
    {
      refetchOnWindowFocus: false,
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading, data,
  };
};

export {
  useCreateAlert,
  useFetchAlert,
  useFetchAlertList,
  useUpdateAlert,
  useUpdateAlertStatus,
  useDeleteAlert,
  useFetchAlertNotificationsFilters,
  useFetchAlertNotifications,
  useFetchAlertResults,
};
