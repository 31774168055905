import CleanRoomTypes from 'pages/InternalAdmin/account/pages/CleanRoomTypes/CleanRoomTypes';
import PrivateRoute from 'components/Common/PrivateRoute';
import ProductAccess from 'pages/InternalAdmin/account/pages/ProductAccess/ProductAccess';
import React from 'react';
import { Switch } from 'react-router-dom';
import { internalAdmin } from 'utils/spaUrls';


const InternalAdminAccountRouter = () => (
  <Switch>
    <PrivateRoute
      path={internalAdmin.account.accountProductAccess}
      component={ProductAccess}
      exact
    />
    <PrivateRoute
      path={internalAdmin.account.cleanRoomTypes}
      component={CleanRoomTypes}
    />
  </Switch>
);

export default InternalAdminAccountRouter;
