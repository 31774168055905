import ConfirmationDialog from 'components/Common/ConfirmationDialog';
import IdentityConfigDialog from 'pages/InternalAdmin/organization/pages/IdentityConfiguration/IdentityConfigDialog';
import PageHeader from 'components/Common/PageHeader';
import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import { Add as AddIcon } from '@mui/icons-material';
import {
  Button,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { crumbs } from 'components/Common/BreadcrumbsNavigation';
import { useDeleteIdentityCredential, useFetchIdentityCredentials } from 'api/OrganizationIdentity';


const IdentityConfiguration = () => {
  const [configDialogOpen, setConfigDialogOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [deleteRowId, setDeleteRowId] = useState('');

  const {
    isLoading,
    data,
  } = useFetchIdentityCredentials();
  const {
    isLoading: isDeletingCredential,
    mutate: deleteCredentials,
  } = useDeleteIdentityCredential();

  const handleConfigDialogOpen = (row = null) => {
    setSelectedRow(row);
    setConfigDialogOpen(true);
  };

  const handleConfigDialogClose = () => {
    setConfigDialogOpen(false);
    setSelectedRow(null);
  };

  const handleDeleteModalOpen = (row) => {
    setDeleteRowId(row.ID);
  };

  const handleDeleteModalClose = () => {
    setDeleteRowId('');
  };

  const handleDeleteConfirm = () => {
    if (deleteRowId) {
      deleteCredentials(deleteRowId);
      handleDeleteModalClose();
    }
  };

  return (
    <>
      <PageHeader
        title='Identity Resolution Configuration'
        hideBackIcon
        breadcrumbs={[crumbs.home]}
      />
      <Paper
        elevation={0}
        sx={{
          p: 2,
          mb: 2,
          textAlign: 'right',
        }}
      >
        <Button
          variant='contained'
          color='secondary'
          startIcon={<AddIcon />}
          size='small'
          onClick={() => handleConfigDialogOpen()}
          disabled={data?.length > 0}
        >
          Add New
        </Button>
      </Paper>
      <Paper>
        <TableContainer>
          {isLoading ? (
            <Skeleton variant='rectangular' width='100%' height={320} />
          ) : (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Parameter Name</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.length > 0 ? (
                  data.map((row) => (
                    <TableRow key={row.name}>
                      <TableCell>{row.name}</TableCell>
                      <TableCell align='right'>
                        <Stack direction='row' gap={1} justifyContent='flex-end'>
                          <Button
                            variant='outlined'
                            color='secondary'
                            size='small'
                            onClick={() => handleConfigDialogOpen(row)}
                          >
                            Edit
                          </Button>
                          <Button
                            variant='outlined'
                            color='error'
                            size='small'
                            onClick={() => handleDeleteModalOpen(row)}
                          >
                            Delete
                          </Button>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={3} align='center'>
                      <Typography>No data available</Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          )}
        </TableContainer>
      </Paper>
      {configDialogOpen && (
        <IdentityConfigDialog
          open={configDialogOpen}
          handleClose={handleConfigDialogClose}
          selectedRow={selectedRow}
        />
      )}
      <ConfirmationDialog
        open={Boolean(deleteRowId)}
        title='Delete?'
        message='Are you sure you want to delete the identity credentials?'
        confirmActionLabel='Delete'
        loading={isDeletingCredential}
        onConfirm={handleDeleteConfirm}
        onCancel={handleDeleteModalClose}
      />
    </>
  );
};

export default IdentityConfiguration;
