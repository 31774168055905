import localStorage from 'utils/localStorage';
import { CACHE } from 'utils/appConstants';
import { habuHelp } from 'utils/urlConstants';
import { useHistory, useLocation } from 'react-router-dom';


const HabuHelp = () => {
  const history = useHistory();
  const location = useLocation();
  const key = location.state?.key ?? '';
  const returnTo = location.state?.returnTo || false;
  const token = localStorage.get(CACHE.user.jwtAccessToken);

  if (returnTo) {
    const url = new URL(returnTo);
    url.searchParams.append('token', token);
    document.location.href = url;
  }
  else {
    const params = {
      token,
      key,
      return_to: `https://habu.zendesk.com/hc/en-us/${key}`,
    };
    const url = habuHelp.url(params);
    window.open(url);
    history.goBack();
  }

  return null;
};

export default HabuHelp;
