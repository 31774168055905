import AddCircleIcon from '@mui/icons-material/AddCircle';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import React, { useContext } from 'react';
import TextField from '@mui/material/TextField';
import { FlowMacroContext } from 'pages/Flow/FlowMacro/MacroAssignmentTab/FlowMacroContextProvider';


const filter = createFilterOptions();

const MacroAssignmentAutoComplete = ({ nodeID, runParameter, onCloseTextField }) => {
  const {
    disableAutoComplete,
    flowMacros,
    onCreateFlowMacro,
    handleMacroMapping,
  } = useContext(FlowMacroContext);

  const [value, setValue] = React.useState(runParameter?.macro);

  const getMacrosByType = () => {
    if (!Array.isArray(flowMacros)) return [];

    const { type } = runParameter;
    return flowMacros.filter(flowMacro => flowMacro.type === type);
  };


  return (
    <Autocomplete
      value={value}
      disabled={disableAutoComplete}
      onChange={(event, newValue) => {
        if (typeof newValue === 'string') {
          setValue({
            name: newValue,
          });
        }
        else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          setValue({
            name: newValue.inputValue,
          });
          onCreateFlowMacro({ newMacro: newValue, runParameter, nodeID });
        }
        else {
          setValue(newValue);
          handleMacroMapping({
            macro: newValue, runParameter, prevMacro: value, nodeID,
          });
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some(
          (option) => inputValue === option.name,
        );
        if (inputValue !== '' && !isExisting) {
          filtered.unshift({
            inputValue,
            name: `Create "${inputValue}" as new parameter`,
          });
        }

        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      options={getMacrosByType()}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.name;
      }}
      renderOption={(props, option) => {
        const { key, ...optionProps } = props;
        // Determine whether to show the icon based on adding a new value
        const shouldShowIcon = !option.name.startsWith('Create ');
        return (
          <li key={option.ID} {...optionProps}>
            {shouldShowIcon ? (
              <AlternateEmailIcon fontSize='small' style={{ marginRight: '8px' }} />
            ) : (
              <AddCircleIcon fontSize='small' style={{ marginRight: '8px' }} />
            )}
            {option.name}
          </li>
        );
      }}
      freeSolo
      renderInput={(params) => <TextField {...params} />}
      onClose={onCloseTextField}
    />
  );
};

export default MacroAssignmentAutoComplete;
