import PageHeader from 'components/Common/PageHeader';
import React from 'react';
import { OrganizationUsersTable } from 'pages/OrganizationManagement/organization/pages/OrganizationUsers/OrganizationUsersTable';
import { crumbs } from 'components/Common/BreadcrumbsNavigation';
import {
  deleteUser, sendEmail,
} from 'redux/actions/IamActions';
import { useDispatch, useSelector } from 'react-redux';
import { userTypes } from 'utils/appConstants';


const OrganizationUsers = () => {
  const dispatch = useDispatch();
  const orgId = useSelector(state => state.session.user.primaryOrganization.organization.ID);
  const users = useSelector(state => state.iam.userList);

  return (
    <>
      <PageHeader
        title='Organization Users'
        hideBackIcon
        breadcrumbs={[crumbs.home]}
      />
      <OrganizationUsersTable
        list={users?.filter(x => x.roleName !== userTypes.accountAdmin)}
        sendEmail={email => dispatch(sendEmail(orgId, email))}
        deleteUser={id => dispatch(deleteUser(orgId, id))}
        currentOrgId={orgId}
      />
    </>
  );
};

export default OrganizationUsers;
