import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import QuickreplyOutlinedIcon from '@mui/icons-material/QuickreplyOutlined';
import React, { useContext } from 'react';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { ContentCopy } from '@mui/icons-material';
import { CopyToClipboard } from 'react-copy-to-clipboard/lib/Component';
import { HButton } from 'BaseComponents';
import {
  NodesEdgeConnectionContext,
} from 'pages/Flow/QuestionNodesEdgeConnectionDialog/NodesEdgeConnectionProvider';
import {
  QuestionMacroMappingTable,
} from 'pages/Flow/QuestionNodesEdgeConnectionDialog/QuestionMacroMappingTable';
import {
  closecheckFlowEdgesConnectionDailog,
  connectNodesWithEdge,
} from 'redux/actions/FlowAction';
import { habuColors } from 'Theme';
import { mutateEdgePayload } from 'redux/reducer/FlowReducer/utils/mutateEdge';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';
import { useUpdateEdge } from 'api/flows/useUpdateEdge';


const QuestionChainingEdgeLayout = ({ onClose, drawerInfo }) => {
  const orgId = useSelector(state => state.session.user.primaryOrganization.organization.ID);
  const {
    edgePayload, crId, flowId, edgeId, sourceNode, targetNode, currentEdge,
  } = useContext(NodesEdgeConnectionContext);
  const dispatch = useDispatch();
  const editMode = edgeId;
  const { mutate } = useUpdateEdge({ flowId, edgeId });

  const handleOnClose = () => {
    dispatch(closecheckFlowEdgesConnectionDailog());
  };

  const isDisabled = (macros, dataSetTypeID) => !(dataSetTypeID
    && macros.every(macro => macro.outputFieldID && macro.questionOutputType));

  const handleOnSubmit = () => {
    const payload = mutateEdgePayload(edgePayload, drawerInfo.type, crId);
    dispatch(connectNodesWithEdge({ orgId, flowId, payload }));
  };

  const handleOnUpdate = () => {
    const updatedPayload = {
      ...currentEdge,
      ...edgePayload,
    };

    const payload = mutateEdgePayload(updatedPayload, drawerInfo.type, crId, editMode);
    mutate(payload);
  };

  const handleCopy = () => {
    dispatch(showAlert({ message: 'Edge ID Copied', type: 'success' }));
  };

  return (
    <Box sx={{
      display: 'flex', flexDirection: 'column', width: '100%',
    }}
    >
      {/* Top Bar */}
      <Toolbar sx={{
        position: 'sticky', top: 0, zIndex: 1, backgroundColor: 'white',
      }}
      >
        <Grid container spacing={0} alignItems='center'>
          <Grid item xs={1}>
            <Box sx={{
              display: 'flex', justifyContent: 'center', alignItems: 'center', paddingRight: 1,
            }}
            >
              <QuickreplyOutlinedIcon height={50} width={50} />
            </Box>
          </Grid>
          <Grid item xs={10}>
            <Typography variant='h3' noWrap>
              Connection Configuration
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton aria-label='close' onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
      <Grid container spacing={0} sx={{ paddingX: 3, paddingBottom: 2 }}>
        {editMode && (
        <Grid item xs={12}>
          <Typography variant='subtitle3' sx={{ color: habuColors.neutral[60] }}>
            {`Edge ID: ${edgeId}`}
          </Typography>
          <Tooltip title='Copy Edge Id'>
            <IconButton onClick={handleCopy}>
              <CopyToClipboard text={edgeId}>
                <ContentCopy />
              </CopyToClipboard>
            </IconButton>
          </Tooltip>
        </Grid>
        )}
        <Grid item xs={12}>
          <Typography variant='subtitle1'>
            {`${editMode ? 'Edit' : ''} Field Mapping`}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={0} sx={{ paddingX: 3, paddingBottom: 2 }}>
        <Grid item xs={5}>
          <Typography variant='body3'>{sourceNode.data.title}</Typography>
          <br />
          <Typography variant='subtitle3'>Output Fields</Typography>
        </Grid>
        <Grid item xs={2}>
          <ArrowForwardIcon />
        </Grid>
        <Grid item xs={5}>
          <Typography variant='body3'>{targetNode.data.title}</Typography>
          <br />
          <Typography variant='subtitle3'>Dataset Field Macros</Typography>
        </Grid>
      </Grid>
      {/* Scrollable Content */}
      <Box sx={{ flex: 1, overflowY: 'auto' }}>
        <Grid container spacing={0} sx={{ paddingX: 3 }}>
          <Typography variant='subtitle3'>Select dataset</Typography>
          <QuestionMacroMappingTable />
        </Grid>
      </Box>
      {/* Bottom Bar */}
      <Box sx={{
        position: 'sticky', bottom: 0, zIndex: 1, backgroundColor: 'white', padding: 2,
      }}
      >
        <Grid container spacing={2} justifyContent='flex-end'>
          <Grid item>
            <HButton variant='outlined' onClick={handleOnClose}>
              Cancel
            </HButton>
          </Grid>
          <Grid item>
            <HButton
              type='submit'
              variant='contained'
              color='primary'
              disabled={isDisabled(edgePayload.macros, edgePayload.dataSetTypeID)}
              onClick={editMode ? handleOnUpdate : handleOnSubmit}
            >
              {editMode ? 'Update' : 'Save'}
            </HButton>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default QuestionChainingEdgeLayout;
