import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import React from 'react';
import ResetPasswordDialog from 'components/IAM/Admin/ResetPasswordDialog';
import Switch from 'components/Common/Switch';
import TextField from '@mui/material/TextField';
import { CACHE, orgTypes } from 'utils/appConstants';
import { HButton } from 'BaseComponents';
import { Link } from 'react-router-dom';
import { PasswordFields } from 'BaseComponents/PasswordFields';
import { StyledGrid, StyledHButton } from 'components/IAM/Admin/StyledComponents';
import { Tooltip } from '@mui/material';
import { isValidPassword } from 'utils/passwordServices';
import { organizationManagement } from 'utils/spaUrls';


const OrganizationUserForm = ({
  save, details, allRoles, sessionUserId, orgType,
}) => {
  const [name, updateName] = React.useState();
  const [nickName, updateNickName] = React.useState();
  const [email, updateEmail] = React.useState('');
  const [role, updateRole] = React.useState();
  const [sendWelcomeEmail, setSendWelcomeEmail] = React.useState(false);
  const [customPassword, setCustomPassword] = React.useState(false);
  const [password, updatePassword] = React.useState('');
  const [confirmPassword, updateConfirmPassword] = React.useState('');
  const [isDarkMode, toggleIsDarkMode] = React.useState(
    localStorage.getItem(CACHE.themeType) === 'dark',
  );
  const isDisabled = () => (customPassword
    ? !isValidPassword(password) || password !== confirmPassword : customPassword);

  React.useEffect(() => {
    updateName(details.name || '');
    updateNickName(details.nickName || '');
    updateEmail(details.email || '');
    updateRole(details.role || '');
  }, [details]);

  const handleSubmit = (e) => {
    const themeType = isDarkMode ? 'dark' : 'light';
    localStorage.setItem(CACHE.themeType, themeType);
    save({
      user: {
        ID: details.ID,
        name,
        email: email.trim(),
        userType: 'ConsoleUser',
        nickName,
        password,
        confirmPassword,
      },
      roleName: role,
      sendWelcomeEmail,
    });
    e.preventDefault();
  };

  return (
    <Card>
      <CardContent>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item md={10} sm={12}>
              <TextField
                fullWidth
                label='Full Name'
                onChange={({ target }) => updateName(target.value)}
                required
                value={name || ''}
                variant='outlined'
              />
            </Grid>
            <Grid item md={10} sm={12}>
              <TextField
                fullWidth
                label='Nick Name'
                onChange={({ target }) => updateNickName(target.value)}
                value={nickName || ''}
                variant='outlined'
              />
            </Grid>
            <Grid item md={10} sm={12}>
              <TextField
                fullWidth
                label='User Email'
                onChange={({ target }) => updateEmail(target.value)}
                required
                type='email'
                value={email || ''}
                variant='outlined'
              />
            </Grid>
            <Grid item md={10} sm={12}>
              <TextField
                fullWidth
                label='Select Role'
                onChange={e => updateRole(e.target.value)}
                required
                select
                value={role || ''}
                variant='outlined'
              >
                {
                  allRoles.map(({ roleName }) => (
                    <MenuItem key={roleName} value={roleName}>{roleName}</MenuItem>))
                }
              </TextField>
            </Grid>
            {!details.ID ? (
              <>
                {orgType === orgTypes.Standard
                  ? (
                    <Grid item md={8} sm={12}>
                      <Switch
                        checked={customPassword}
                        label='Set Custom Password'
                        value={customPassword}
                        onChange={event => setCustomPassword(event.target.checked)}
                      />
                    </Grid>
                  ) : null}
                {customPassword && (
                  <>
                    <PasswordFields
                      password={password}
                      confirmPassword={confirmPassword}
                      updatePassword={updatePassword}
                      updateConfirmPassword={updateConfirmPassword}
                    />
                  </>
                )}
                <Grid item md={10} sm={12}>
                  <Switch
                    checked={sendWelcomeEmail}
                    label='Send Welcome Email'
                    value={sendWelcomeEmail}
                    onChange={event => setSendWelcomeEmail(event.target.checked)}
                  />
                </Grid>
              </>
            ) : null}
            { sessionUserId === details.ID ? (
              <Grid item md={10} xs={12}>
                <ResetPasswordDialog email={details.email} />
              </Grid>
            ) : null }
            { sessionUserId === details.ID ? (
              <Tooltip title='Enables dark mode for this browser (requires manual page refresh)'>
                <Grid item md={10} xs={12}>
                  <Switch
                    checked={isDarkMode}
                    label='Enable Dark Mode'
                    value={isDarkMode}
                    onChange={e => toggleIsDarkMode(e.target.checked)}
                  />
                </Grid>
              </Tooltip>
            ) : null}
            <StyledGrid item md={10} xs={12}>
              <HButton
                component={Link}
                to={organizationManagement.organization.users.list}
                variant='contained'
                color='inherit'
                size='small'
              >
                Cancel
              </HButton>
              <StyledHButton
                color='primary'
                size='small'
                variant={(!details.ID && isDisabled()) ? 'text' : 'contained'}
                type='submit'
                disabled={(!details.ID && isDisabled())}
              >
                {details.ID ? 'Update User' : 'Create User'}
              </StyledHButton>
            </StyledGrid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
};

export default OrganizationUserForm;
