import { activeOrgSelector } from 'redux/reducer/AuthenticationReducer';
import { axiosInstance } from 'utils/http';
import { flow } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'react-query';


export const useUpdateFlowNodeAccess = ({ crId, crFlowId, nodeId }) => {
  const dispatch = useDispatch();
  const { ID: orgId } = useSelector(activeOrgSelector);

  const {
    isLoading, isSuccess, isError, data, mutate, mutateAsync,
  } = useMutation(
    ['Update-Flow-Node-Access', orgId],
    (payload) => axiosInstance.post(flow(orgId).nodeAccess(crId, crFlowId, nodeId), payload),
    {
      onSuccess: () => {
        dispatch(showAlert({ message: 'Node privacy has been updated.', type: 'success' }));
      },
      onError: (error) => dispatch(showAlert({ message: error.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading, isError, isSuccess, data, mutate, mutateAsync,
  };
};
