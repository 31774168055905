import { activeOrgSelector } from 'redux/reducer/AuthenticationReducer';
import { axiosInstance } from 'utils/http';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { userDefinedFunctions } from 'utils/urlConstants';


export const useFetchUserDefinedFunctions = (page, rowsPerPage, searchTerm) => {
  const { ID: orgId } = useSelector(activeOrgSelector);
  const dispatch = useDispatch();

  const payload = {
    limit: rowsPerPage,
    offset: page * rowsPerPage,
    search: searchTerm,
  };

  return useQuery(
    ['userDefinedFunctions', payload],
    async () => {
      const { data } = await axiosInstance.post(userDefinedFunctions(orgId).list, payload);
      return data;
    },
    {
      keepPreviousData: true,
      onError: (err) => {
        dispatch(showAlert({
          message: err.response?.data?.message ?? err.response?.statusText ?? 'Error fetching user-defined functions',
          type: 'error',
        }));
      },
    },
  );
};
