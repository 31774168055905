import React, { useContext, useMemo } from 'react';
import { DatasetMappingTable } from 'pages/Flow/QuestionNodesEdgeConnectionDialog/DatasetMappingTable';
import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { NodesEdgeConnectionContext } from 'pages/Flow/QuestionNodesEdgeConnectionDialog/NodesEdgeConnectionProvider';


export const QuestionMacroMappingTable = () => {
  const {
    childSourceDataset,
    onDatasetSelect,
    datasetId,
    setDatasetId,
  } = useContext(NodesEdgeConnectionContext);

  const handleOnChange = (dtID) => {
    setDatasetId(dtID);
    onDatasetSelect(dtID);
  };

  const filterOutWheelDatasets = (datasets) => {
    if (!Array.isArray(datasets) || datasets.length === 0) return [];
    return datasets.filter(dataset => dataset.importDataType?.name !== 'WHEEL');
  };

  const filteredDatasets = useMemo(() => filterOutWheelDatasets(childSourceDataset),
    [childSourceDataset]);


  return (
    <FormControl required fullWidth>
      <RadioGroup defaultValue='outlined' name='radio-buttons-group'>
        {filteredDatasets.map((dt) => (
          <Grid item container key={dt.ID}>
            <Grid item xs={12}>
              <FormControlLabel
                id={dt.ID}
                control={(
                  <Radio
                    color='primary'
                    onChange={() => handleOnChange(dt.ID)}
                    required
                  />
                )}
                label={(<Typography variant='subtitle3'>{`@${dt.macro}`}</Typography>)}
                labelPlacement='end'
                checked={datasetId === dt.ID}
              />
            </Grid>
            {datasetId === dt.ID && (
            <Grid item xs={12} sx={{ pl: 3 }}>
              <DatasetMappingTable
                dataSetTypeFieldMap={dt.dataSetTypeFields}
              />
            </Grid>
            )}
          </Grid>
        ))}
      </RadioGroup>
    </FormControl>
  );
};

