import { EXPERIMENTS } from 'redux/actions/Types';
import { experiment } from 'utils/urlConstants';


export const fetchExp = (orgId, id) => ({
  type: EXPERIMENTS.FETCH_EXP,
  url: experiment(orgId).details(id),
});

export const fetchExpError = payload => ({
  type: EXPERIMENTS.FETCH_EXP_ERROR,
  payload,
});

export const fetchExpSuccess = payload => ({
  type: EXPERIMENTS.FETCH_EXP_SUCCESS,
  payload,
});

export const fetchExpList = (orgId, filter) => ({
  type: EXPERIMENTS.FETCH_EXP_LIST,
  url: experiment(orgId).list,
  payload: filter,
});

export const fetchExpListError = payload => ({
  type: EXPERIMENTS.FETCH_EXP_LIST_ERROR,
  payload,
});

export const fetchExpListSuccess = payload => ({
  type: EXPERIMENTS.FETCH_EXP_LIST_SUCCESS,
  payload,
});

export const fetchAudiences = orgId => ({
  type: EXPERIMENTS.FETCH_AUDIENCES,
  url: experiment(orgId).audiences,
});

export const fetchAudiencesError = payload => ({
  type: EXPERIMENTS.FETCH_AUDIENCES_ERROR,
  payload,
});

export const fetchAudiencesSuccess = payload => ({
  type: EXPERIMENTS.FETCH_AUDIENCES_SUCCESS,
  payload,
});

export const fetchExpPartnerConnections = orgId => ({
  type: EXPERIMENTS.FETCH_EXP_PARTNER_CONNECTIONS,
  url: experiment(orgId).partnerConnections,
});

export const fetchExpPartnerConnectionsError = payload => ({
  type: EXPERIMENTS.FETCH_EXP_PARTNER_CONNECTIONS_ERROR,
  payload,
});

export const fetchExpPartnerConnectionsSuccess = payload => ({
  type: EXPERIMENTS.FETCH_EXP_PARTNER_CONNECTIONS_SUCCESS,
  payload,
});

export const fetchExperimentsSummary = orgId => ({
  type: EXPERIMENTS.FETCH_EXP_SUMMARIES,
  url: experiment(orgId).summary,
});

export const fetchExperimentsSummaryError = payload => ({
  type: EXPERIMENTS.FETCH_EXP_SUMMARIES_ERROR,
  payload,
});

export const fetchExperimentsSummarySuccess = payload => ({
  type: EXPERIMENTS.FETCH_EXP_SUMMARIES_SUCCESS,
  payload,
});

export const saveExp = (orgId, payload, id) => ({
  type: EXPERIMENTS.SAVE_EXP,
  url: experiment(orgId).save(id ? `/${id}` : ''),
  payload,
  id,
});

export const saveExpError = payload => ({
  type: EXPERIMENTS.SAVE_EXP_ERROR,
  payload,
});

export const saveExpSuccess = payload => ({
  type: EXPERIMENTS.SAVE_EXP_SUCCESS,
  payload,
});

export const updateExpStatus = (orgId, experimentId, status) => ({
  type: EXPERIMENTS.UPDATE_EXP_STATUS,
  url: experiment(orgId).updateExperimentStatus(experimentId),
  payload: { status },
});

export const updateExpStatusError = payload => ({
  type: EXPERIMENTS.UPDATE_EXP_STATUS_ERROR,
  payload,
});

export const updateExpStatusSuccess = payload => ({
  type: EXPERIMENTS.UPDATE_EXP_STATUS_SUCCESS,
  payload,
});

export const fetchExperimentFilters = orgId => ({
  type: EXPERIMENTS.FETCH_EXP_FILTERS,
  url: experiment(orgId).experimentFilters,
});

export const fetchExperimentFiltersError = payload => ({
  type: EXPERIMENTS.FETCH_EXP_FILTERS_ERROR,
  payload,
});

export const fetchExperimentFiltersSuccess = payload => ({
  type: EXPERIMENTS.FETCH_EXP_FILTERS_SUCCESS,
  payload,
});

export const endExperiment = (orgId, id) => ({
  type: EXPERIMENTS.END_EXP,
  url: experiment(orgId).endExp(id),
});

export const endExperimentError = payload => ({
  type: EXPERIMENTS.END_EXP_ERROR,
  payload,
});

export const endExperimentSuccess = payload => ({
  type: EXPERIMENTS.END_EXP_SUCCESS,
  payload,
});
