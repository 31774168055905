import { activeOrgSelector } from 'redux/reducer/AuthenticationReducer';
import { axiosInstance } from 'utils/http';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { userDefinedFunctions } from 'utils/urlConstants';


export const useFetchCrUdfList = (crId) => {
  const { ID: orgId } = useSelector(activeOrgSelector);
  const dispatch = useDispatch();

  return useQuery(
    ['crUdfList', { orgId, crId }],
    async () => {
      const { data } = await axiosInstance.get(userDefinedFunctions(orgId).crUdfList(crId));
      return data;
    },
    {
      enabled: !!crId,
      onError: (err) => {
        dispatch(showAlert({
          message: err.response?.data?.message ?? 'Error fetching cleanroom user-defined functions',
          type: 'error',
        }));
      },
    },
  );
};
