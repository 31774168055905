import { PROPERTIES } from 'redux/actions/Types';


const initialState = {
  archived: [],
  list: [],
  meta: {},
};

const PropertiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case PROPERTIES.ARCHIVE_PROPERTY:
      return { ...state, meta: {} };
    case PROPERTIES.ARCHIVE_PROPERTY_FAILURE:
      return { ...state, meta: { message: action.payload, refresh: false } };
    case PROPERTIES.ARCHIVE_PROPERTY_SUCCESS:
      return { ...state, meta: { message: action.payload, refresh: true } };
    case PROPERTIES.CLEAR_PROPERTY:
      return { ...state, details: undefined };
    case PROPERTIES.CREATE_CONFIGURATION:
      return { ...state, meta: {} };
    case PROPERTIES.CREATE_CONFIGURATION_FAILURE:
      return { ...state, error: action.payload };
    case PROPERTIES.CREATE_CONFIGURATION_SUCCESS:
      return { ...state, meta: { redirect: true } };
    case PROPERTIES.CREATE_PROPERTY:
      return { ...state, meta: {} };
    case PROPERTIES.CREATE_TAG_JOB:
      return { ...state, meta: {} };
    case PROPERTIES.CREATE_TAG_JOB_SUCCESS:
      return { ...state, meta: { redirect: true } };
    case PROPERTIES.CREATE_PROPERTY_FAILURE:
      return { ...state, error: action.payload };
    case PROPERTIES.CREATE_PROPERTY_SUCCESS:
      return { ...state, meta: { created: true }, list: action.payload };
    case PROPERTIES.FETCH_ARCHIVED_PROPERTIES:
      return { ...state, meta: {} };
    case PROPERTIES.FETCH_ARCHIVED_PROPERTIES_FAILURE:
      return { ...state };
    case PROPERTIES.FETCH_ARCHIVED_PROPERTIES_SUCCESS:
      return { ...state, archived: action.payload };
    case PROPERTIES.FETCH_PROPERTIES:
      return { ...state, meta: {} };
    case PROPERTIES.FETCH_PROPERTIES_ERROR:
      return { ...state };
    case PROPERTIES.FETCH_PROPERTIES_SUCCESS:
      return { ...state, list: action.payload };
    case PROPERTIES.FETCH_PROPERTY:
      return { ...state, meta: {} };
    case PROPERTIES.FETCH_PROPERTY_CONFIGURATIONS:
      return { ...state, meta: {} };
    case PROPERTIES.FETCH_PROPERTY_CONFIGURATIONS_FAILURE:
      return { ...state };
    case PROPERTIES.FETCH_PROPERTY_CONFIGURATIONS_SUCCESS:
      return { ...state, configurations: action.payload };
    case PROPERTIES.FETCH_PROPERTY_CONFIGURATION_DETAIL:
      return { ...state, meta: {} };
    case PROPERTIES.FETCH_PROPERTY_CONFIGURATION_DETAIL_FAILURE:
      return { ...state, error: action.payload };
    case PROPERTIES.FETCH_PROPERTY_CONFIGURATION_DETAIL_SUCCESS:
      return { ...state, configuration: action.payload };
    case PROPERTIES.FETCH_FOREBITT_CONFIGURATION_DETAIL_FAILURE:
      return { ...state, error: action.payload };
    case PROPERTIES.FETCH_FOREBITT_CONFIGURATION_DETAIL_SUCCESS:
      return { ...state, fieldConfigurations: action.payload };
    case PROPERTIES.UPDATE_QUARTERDECK_CONFIGURATION_SUCCESS:
      return { ...state, meta: { redirect: true } };
    case PROPERTIES.FETCH_PROPERTY_ERROR:
      return { ...state };
    case PROPERTIES.FETCH_PROPERTY_SUCCESS:
      return { ...state, details: action.payload };
    case PROPERTIES.UPDATE_PROPERTY:
      return { ...state, meta: {} };
    case PROPERTIES.UPDATE_PROPERTY_FAILURE:
      return { ...state, error: action.payload };
    case PROPERTIES.UPDATE_PROPERTY_SUCCESS:
      return { ...state, meta: { redirect: true } };
    default:
      return state;
  }
};

export default PropertiesReducer;
