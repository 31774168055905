import CrQuestionFormBuilder from 'pages/InternalAdmin/application/pages/CleanRoomQuestion/CrQuestionFormBuilder';
import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import { Card, CardContent } from '@mui/material';
import {
  clearCrQuestion,
  fetchQuestion,
} from 'pages/InternalAdmin/application/CleanRoom/redux/actions';
import { internalAdmin } from 'utils/spaUrls';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { v4 as uuidGen } from 'uuid';


const initialQuestionData = {
  dimensions: [{ ID: uuidGen(), filterable: false, plottable: false }],
  metrics: [{ ID: uuidGen(), computed: false }],
  parameters: [{ ID: uuidGen(), index: 0 }],
  dataTypes: [{
    ID: uuidGen(),
    optional: false,
    importDataType: {
    },
  }],
  queryTemplates: [''],
  isAggregate: true,
  isUserListQuestion: false,
};

const CRQuestionSkeleton = () => (
  <>
    <Card>
      <CardContent>
        <Skeleton width='100%' height={40} />
        <Skeleton width='100%' height={500} />
      </CardContent>
    </Card>

  </>
);


const CleanRoomQuestion = (props) => {
  const { questionId } = props.match.params;
  const history = useHistory();
  const dispatch = useDispatch();
  const questionData = useSelector(state => state.internalAdmin
    .application.cleanRoomAdmin.question);
  const [question, setQuestion] = React.useState();

  React.useEffect(() => {
    if (questionId) {
      dispatch(fetchQuestion(questionId));
    }
  }, [dispatch, questionId]);

  React.useEffect(() => () => {
    dispatch(clearCrQuestion());
  }, [dispatch]);

  React.useEffect(() => {
    if (questionData && !questionData.error) {
      if (questionData.created) {
        history.push(internalAdmin.application.cleanRoomQuestion(questionData.ID));
      }
      else {
        setQuestion({
          ...questionData,
          queryTemplates: questionData.queryTemplates.length === 0 ? [''] : questionData.queryTemplates,
        });
      }
    }
  }, [questionData, history]);

  const onBack = React.useCallback(() => {
    history.push(internalAdmin.application.cleanRoomQuestions);
  }, [history]);

  return (
    <>
      {questionId && (
        <>
          {!question && <CRQuestionSkeleton />}
          {question && !question.error && (
            <CrQuestionFormBuilder questionId={questionId} data={question} onBack={onBack} />
          )}
        </>
      )}
      {!questionId && (
        <CrQuestionFormBuilder
          questionId={questionId}
          data={initialQuestionData}
          onBack={onBack}
        />
      )}
    </>
  );
};


export default CleanRoomQuestion;
