import AppAlert from 'components/Common/AppAlerts';
import CssBaseline from '@mui/material/CssBaseline';
import React from 'react';
import Router from 'Router';
import SessionTimeoutDialog from './components/Authentication/SessionTimeoutDialog';
import Store from 'Store';
import Theme from 'Theme';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
// import { ReactQueryDevtools } from 'react-query/devtools';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { Provider as RollbarProvider } from '@rollbar/react';
import { ThemeProvider } from '@mui/material/styles';


const getRollbarConfig = (env) => ({
  accessToken: process.env.REACT_APP_ROLLBAR_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: Boolean(env === 'production'),
  payload: {
    environment: env,
  },
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => (
  <BrowserRouter>
    <RollbarProvider config={getRollbarConfig(process.env.REACT_APP_ENV)}>
      <Provider store={Store}>
        <QueryClientProvider client={queryClient}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <ThemeProvider theme={Theme}>
              <CssBaseline />
              <AppAlert />
              <Router />
              <SessionTimeoutDialog />
            </ThemeProvider>
          </LocalizationProvider>
          {/*
          TODO: Don't remove, commenting ReactQueryDevtools for now.
          We will use it later after this prod release
          */}
          {/* <ReactQueryDevtools initialIsOpen={false} /> */}
        </QueryClientProvider>
      </Provider>
    </RollbarProvider>
  </BrowserRouter>
);

export default App;
