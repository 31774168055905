import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import FlowDatasetsManagement from 'components/Flow/FlowDatasetsManagement';
import PermissionService from 'components/Common/PermissionService';
import React from 'react';
import { DropdownMenu } from 'BaseComponents';
import { FLOW_STATUS } from 'utils/ignoramusEnums';
import { FlowRunContext } from 'pages/Flow/FlowRun/FlowRunProvider';
import { cleanRoom } from 'utils/spaUrls';
import { cleanRoomPermissions } from 'utils/appConstants';
import { useHistory, useParams } from 'react-router-dom';


const getMenuItems = ({
  crFlowID, runCount, openModal, history, crId,
}) => {
  const itemsMenu = [];
  const canViewReports = PermissionService.isAuthorized([
    cleanRoomPermissions.viewReports,
    cleanRoomPermissions.manageReports,
  ]);

  const onHandleCreateFlowRun = () => {
    openModal({ ID: crFlowID });
  };

  const onViewFlowReport = () => {
    history.push(cleanRoom.flowRunList(crId, crFlowID));
  };

  const createFlowRun = {
    label: 'Create Run',
    action: () => onHandleCreateFlowRun(),
  };

  const viewFlowReportRun = {
    label: 'View Run Reports',
    action: () => onViewFlowReport(),
  };

  itemsMenu.push(createFlowRun);

  if (runCount > 0 && canViewReports) {
    itemsMenu.push(viewFlowReportRun);
  }

  return itemsMenu;
};

export const FlowReportRunButton = ({ flowDetails }) => {
  const history = useHistory();
  const { crId } = useParams();
  const { openModal } = React.useContext(FlowRunContext);
  const itemsMenu = getMenuItems({
    crFlowID: flowDetails.ID,
    runCount: flowDetails.runCount,
    openModal,
    history,
    crId,
  });

  return (
    <>
      {flowDetails?.stage === FLOW_STATUS.READY.key && (
      <DropdownMenu
        color='secondary'
        icon={<ArrowDropDownIcon />}
        label='Run & Reports'
        menuItems={itemsMenu}
        size='small'
        variant='contained'
      />
      )}
    </>
  );
};

export const FlowCardActionButtons = ({ flowDetails }) => (
  <>
    <FlowDatasetsManagement flowDetails={flowDetails} />
    <FlowReportRunButton flowDetails={flowDetails} />
  </>
);
