import * as spaUrls from 'utils/spaUrls';
import GearPopover from 'components/Common/GearPopover';
import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { rowsPerPageOptions } from 'utils/appConstants';
import { useHistory } from 'react-router-dom';


const ProductPermissionsTable = ({ list }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(rowsPerPageOptions[0]);
  const history = useHistory();

  return (
    <>
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell>Product Name</TableCell>
            <TableCell>Product Group</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {list
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map(row => (
              <TableRow key={row.ID}>
                <TableCell>{row.displayName}</TableCell>
                <TableCell>{row.productGroup}</TableCell>
                <TableCell align='right'>
                  <GearPopover
                    actions={[
                      {
                        name: 'Edit Product Permission',
                        action: () => history.push(
                          `${spaUrls.internalAdmin.application.productPermissionDetails(row.ID)}`,
                        ),
                      },
                    ]}
                  />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component='div'
        count={list.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
        }}
        onPageChange={(e, newPage) => setPage(newPage)}
        onRowsPerPageChange={e => setRowsPerPage(e.target.value)}
      />
    </>
  );
};

export default ProductPermissionsTable;
