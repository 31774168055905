import { axiosInstance } from 'utils/http';
import { flow } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'react-query';


export const useDeleteFlow = () => {
  const dispatch = useDispatch();
  const orgId = useSelector(state => state.session.user.primaryOrganization.organization.ID);
  const {
    isLoading, isError, isSuccess, mutate, mutateAsync,
  } = useMutation(
    ({ flowId, crId }) => axiosInstance.delete(flow(orgId).delete(flowId, crId)),
    {
      onSuccess: () => {
        dispatch(showAlert({ message: 'Flow has been deleted.', type: 'success' }));
      },
      onError: (error) => dispatch(showAlert({ message: error.response.data.message, type: 'error' })),
    },
  );
  return {
    isLoading, isError, isSuccess, mutate, mutateAsync,
  };
};
