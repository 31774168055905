import DataTypeAccessTable from 'pages/InternalAdmin/organization/pages/DataIngestion/DataTypeAccessTable';
import ListViewTable from 'components/Common/ListViewTable';
import PageHeader from 'components/Common/PageHeader';
import React from 'react';
import { Card, CardContent, Grid } from '@mui/material';
import { HButton } from 'BaseComponents';
import { PRODUCT } from 'utils/appConstants';
import { StyledGrid } from 'components/IAM/Admin/StyledComponents';
import { crumbs } from 'components/Common/BreadcrumbsNavigation';
import {
  fetchAllImportDataTypes,
  fetchOrgImportDataTypes,
  updateOrgImportDataTypes,
} from 'pages/InternalAdmin/organization/DataIn/redux/actions';
import { useDispatch, useSelector } from 'react-redux';


const DataTypeAccess = () => {
  const dispatch = useDispatch();
  const orgID = useSelector(state => state.session.user.primaryOrganization.organization.ID);
  const [dataTypes, updateDataTypes] = React.useState([]);
  const list = useSelector(state => state.internalAdmin
    .organization.dataInAdmin.allImportDataTypes);
  const selectedList = useSelector(state => state.internalAdmin
    .organization.dataInAdmin.orgImportDataTypes);

  React.useEffect(() => {
    dispatch(fetchAllImportDataTypes());
    dispatch(fetchOrgImportDataTypes(orgID));
  }, [dispatch, orgID]);

  React.useEffect(() => {
    if (Array.isArray(selectedList)) updateDataTypes(selectedList.map(i => i.id));
  }, [selectedList]);

  const onSubmit = () => {
    const payload = {
      dataTypeIDs: dataTypes,
    };
    dispatch(updateOrgImportDataTypes(orgID, payload));
  };

  return (
    <>
      <PageHeader
        title={`${PRODUCT.DATA_CONNECTION.name}s: Manage Import Dataset Types Supported`}
        breadcrumbs={[crumbs.helium, crumbs.internalAdmin]}
      />
      <Grid container spacing={0} direction='column'>
        <Grid item>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ListViewTable list={list}>
                    <DataTypeAccessTable
                      list={list}
                      dataTypes={dataTypes}
                      updateDataTypes={updateDataTypes}
                    />
                  </ListViewTable>
                </Grid>
                <StyledGrid item xs={12}>
                  <HButton
                    color='primary'
                    size='small'
                    variant='contained'
                    onClick={() => onSubmit()}
                  >
                    Update Import Data types
                  </HButton>
                </StyledGrid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default DataTypeAccess;
