import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CredentialSourcesTable
  from 'pages/InternalAdmin/application/pages/CredentialSources/CredentialSourcesTable';
import Grid from '@mui/material/Grid';
import ListViewTable from 'components/Common/ListViewTable';
import PageHeader from 'components/Common/PageHeader';
import React from 'react';
import { ListControls } from 'components/Common/ListControls';
import { crumbs } from 'components/Common/BreadcrumbsNavigation';
import {
  fetchCredentialSourcesList,
} from 'redux/actions/DataCredentialManagementAction';
import { internalAdmin } from 'utils/spaUrls';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';


const CredentialSources = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const sourcesList = useSelector(state => state.dataCredential.credentialSources);
  const meta = useSelector(state => state.dataCredential.meta);

  React.useEffect(() => {
    dispatch(fetchCredentialSourcesList());
  }, [dispatch, meta.refresh]);


  return (
    <>
      <PageHeader title='Credential Sources' breadcrumbs={[crumbs.helium, crumbs.internalAdmin]} />
      <Grid container spacing={0} direction='column' overflow='auto'>
        <Grid item>
          <Card>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ListControls
                  createButton={{
                    onClick:
                      () => history.push(internalAdmin.application.credentialSourceDetail),
                    text: 'Add New Credential Source',
                  }}
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item width='100%'>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ListViewTable list={sourcesList}>
                    <CredentialSourcesTable sourcesList={sourcesList} />
                  </ListViewTable>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default CredentialSources;
