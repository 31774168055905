import InfoIcon from '@mui/icons-material/Info';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment/moment';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { DropdownMenu } from 'BaseComponents';
import { UI_DATE_FORMAT, rowsPerPageOptions } from 'utils/appConstants';
import { stage } from 'utils/ignoramusEnums';


const UdfListTable = ({
  udfList,
  udfTypeMap,
  page,
  rowsPerPage,
  totalCount,
  onPageChange,
  onRowsPerPageChange,
  handleViewUdfOpen,
  handleDeleteUdf,
}) => (
  <Paper>
    <TableContainer>
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Configuration Status</TableCell>
            <TableCell>Date Modified</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            udfList.length === 0 ? (
              <TableRow>
                <TableCell colSpan={5} align='center'>
                  No functions added yet
                </TableCell>
              </TableRow>
            )
              : udfList.map((udf) => (
                <TableRow key={udf.ID}>
                  <TableCell>{udf.name}</TableCell>
                  <TableCell>{udfTypeMap[udf.typeID]}</TableCell>
                  <TableCell sx={{ color: stage[udf.stage]?.color }}>
                    {stage[udf.stage]?.label}
                    {
                      udf.stage === stage.CONFIGURATION_FAILED.key ? (
                        <Tooltip title={udf.errorParams || 'Unknown Error'} placement='top'>
                          <Box
                            component='span'
                            display='inline-block'
                            marginLeft={1}
                            sx={{ verticalAlign: 'inherit' }}
                          >
                            <InfoIcon color='error' fontSize='small' />
                          </Box>
                        </Tooltip>
                      ) : null
                    }
                  </TableCell>
                  <TableCell>
                    {moment(udf.timeAudit.updatedAt).format(UI_DATE_FORMAT)}
                  </TableCell>
                  <TableCell>
                    <DropdownMenu
                      icon={<MoreHorizIcon />}
                      menuItems={[
                        {
                          label: 'View Details',
                          action: () => handleViewUdfOpen(udf.ID),
                        },
                        ...([stage.CONFIGURATION_FAILED.key, stage.CONFIGURATION_COMPLETE.key]
                          .includes(udf.stage) ? [{
                            label: 'Delete',
                            action: () => handleDeleteUdf(udf.ID),
                          }] : []),
                      ]}
                    />
                  </TableCell>
                </TableRow>
              ))
          }
        </TableBody>
      </Table>
    </TableContainer>
    <TablePagination
      rowsPerPageOptions={rowsPerPageOptions}
      component='div'
      count={totalCount}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={onPageChange}
      onRowsPerPageChange={onRowsPerPageChange}
    />
  </Paper>
);

export default UdfListTable;
