import * as actions from 'redux/actions/DataOutActions';
import Logger from 'components/Common/Logger';
import http from 'utils/http';
import { DATA_OUT } from 'redux/actions/Types';
import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import {
  createExportJob,
  fetchDataOutJobs,
  getPartnerAccount,
  updatePartnerAccount,
} from 'pages/DataOut/redux';
import { habuSupport } from 'utils/appConstants';
import { showAlert } from 'redux/actions/AlertActions';


function* createActivation(action) {
  const { success, error } = yield call(http.post, action.url, action.payload);
  if (error) {
    Logger.error(`Could not create activation. Url: ${action.url}. Error: ${error}`,
      'Data Exports: Create activation');
    yield put(showAlert({ message: `Error creating an activation. ${habuSupport.message}`, type: 'error' }));
    return yield put(actions.createActivationError(error));
  }
  yield put(showAlert({ message: 'Activation created', type: 'success' }));
  return yield put(actions.createActivationSuccess(success));
}

function* createPartnerAccount(action) {
  const { success, error } = yield call(http.post, action.url, action.payload);
  if (error) {
    Logger.error(`Could not create partner account. Url: ${action.url}. Error: ${error}`,
      'Data Exports: Create partner account');
    yield put(showAlert({ message: `Error creating a partner account. ${habuSupport.message}`, type: 'error' }));
    return yield put(actions.createPartnerAccountError(error));
  }
  yield put(showAlert({ message: 'Partner account created', type: 'success' }));
  return yield put(actions.createPartnerAccountSuccess(success));
}

function* deleteActivation(action) {
  const { success, error } = yield call(http.delete, action.url);
  if (error) {
    Logger.error(`Could not delete activation. Url: ${action.url}. Error: ${error}`,
      'Data Exports: Delete activation');
    yield put(showAlert({ message: `Error deleting an activation. ${habuSupport.message}`, type: 'error' }));
    return yield put(actions.deleteActivationError(error));
  }
  yield put(showAlert({ message: 'Activation deleted', type: 'success' }));
  return yield put(actions.deleteActivationSuccess(success));
}

function* deleteActivationRun(action) {
  const { success, error } = yield call(http.delete, action.url);
  if (error) {
    Logger.error(`Could not delete activation run. Url: ${action.url}. Error: ${error}`,
      'Data Exports: Delete activation run');
    yield put(showAlert({ message: `Error deleting an activation run. ${habuSupport.message}`, type: 'error' }));
    return yield put(actions.deleteActivationRunError(error));
  }
  yield put(showAlert({ message: 'Activation run deleted', type: 'success' }));
  return yield put(actions.deleteActivationRunSuccess(success));
}

function* fetchActivation(action) {
  const { job, error } = yield call(http.get, action.url);
  if (error) {
    Logger.error(`Could not fetch activation. Url: ${action.url}. Error: ${error}`,
      'Data Exports: Fetch activation details');
    yield put(showAlert({ message: `Error fetching activation. ${habuSupport.message}`, type: 'error' }));
    return yield put(actions.fetchActivationError(error));
  }
  return yield put(actions.fetchActivationSuccess(job));
}

function* fetchActivationNames(action) {
  const { jobDescriptions, error } = yield call(http.get, action.url);
  if (error) {
    Logger.error(`Could not fetch activation job names. Url: ${action.url}. Error: ${error}`,
      'Data Exports: Fetch activation job names');
    return yield put(actions.fetchActivationNamesError(error));
  }
  return yield put(actions.fetchActivationNamesSuccess(jobDescriptions));
}

function* fetchActivationFilters(action) {
  const activationFilters = yield call(http.get, action.url);
  const { error } = activationFilters;
  if (error) {
    Logger.error(`Could not fetch activation filters. Url: ${action.url}. Error: ${error}`,
      'Data Exports: Fetch activation filters');
    yield put(showAlert({ message: `Error fetching activation filters. ${habuSupport.message}`, type: 'error' }));
    return yield put(actions.fetchActivationFiltersError(error));
  }
  return yield put(actions.fetchActivationFiltersSuccess(activationFilters));
}

function* fetchActivationReport(action) {
  const { jobRuns, error } = yield call(http.get, action.url);
  if (error) {
    Logger.error(`Could not fetch activation report. Url: ${action.url}. Error: ${error}`,
      'Data Exports: Fetch activation report');
    yield put(showAlert({ message: `Error fetching activation report. ${habuSupport.message}`, type: 'error' }));
    return yield put(actions.fetchActivationReportError(error));
  }
  return yield put(actions.fetchActivationReportSuccess(jobRuns));
}

function* fetchPartnerAccounts(action) {
  const { partnerAccounts, error } = yield call(http.post, action.url, action.payload);
  if (error) {
    Logger.error(`Could not fetch partner accounts. Url: ${action.url}. Error: ${error}`,
      'Data Exports: Fetch partner account list');
    yield put(showAlert({ message: `Error fetching partner accounts. ${habuSupport.message}`, type: 'error' }));
    return yield put(actions.fetchPartnerAccountsError(error));
  }
  return yield put(actions.fetchPartnerAccountsSuccess(partnerAccounts));
}

function* fetchPartnerAccountFilters(action) {
  const partnerAccountFilters = yield call(http.get, action.url);
  const { error } = partnerAccountFilters;
  if (error) {
    Logger.error(`Could not fetch partner account filters. Url: ${action.url}. Error: ${error}`,
      'Data Exports: Fetch partner account filters');
    yield put(showAlert({ message: `Error fetching partner account filters. ${habuSupport.message}`, type: 'error' }));
    return yield put(actions.fetchPartnerAccountFiltersError(error));
  }
  return yield put(actions.fetchPartnerAccountFiltersSuccess(partnerAccountFilters));
}

function* fetchPartnerAccountJobs(action) {
  const { error, details } = yield call(http.post, action.url, action.payload);
  if (error) {
    Logger.error(`Could not fetch partner account job status. Url: ${action.url}. Error: ${error}`,
      'Data Exports: Fetch partner account jobs');
    yield put(showAlert({ message: 'Error fetching partner account jobs.', type: 'error' }));
    return yield put(actions.fetchPartnerJobsVerificationError(error));
  }
  return yield put(actions.fetchPartnerJobsVerificationSuccess(details));
}

function* verifyJobPartners(action) {
  const { error, details } = yield call(http.post, action.url, action.payload);
  if (error) {
    Logger.error(`Could not verify job partners. Url: ${action.url}. Error: ${error}`,
      'Activations: verify activation job partners');
    yield put(showAlert({ message: 'Error verifying job partners.', type: 'error' }));
    return yield put(actions.verifyJobPartnersError(error));
  }
  return yield put(actions.verifyJobPartnersSuccess(details));
}

function* fetchPartners(action) {
  const { partners, error } = yield call(http.get, action.url);
  if (error) {
    Logger.error(`Could not fetch partners. Url: ${action.url}. Error: ${error}`,
      'Data Exports: Fetch partner list');
    yield put(showAlert({ message: `Error fetching partners. ${habuSupport.message}`, type: 'error' }));
    return yield put(actions.fetchPartnersError(error));
  }
  return yield put(actions.fetchPartnersSuccess(partners));
}

function* fetchDataOutSummary(action) {
  const { summaries, error } = yield call(http.get, action.url);
  if (error) {
    Logger.error(`Could not fetch Data Exports Summary. Url: ${action.url}. Error: ${error}`,
      'Data Exports: Fetch DataOutSummary');
    return yield put(actions.fetchDataOutSummaryError(error));
  }
  return yield put(actions.fetchDataOutSummarySuccess(summaries));
}

function* updateActivationStatus(action) {
  const { success, error } = yield call(http.post, action.url, action.payload);
  if (error) {
    Logger.error(`Could not update activation status. Url: ${action.url}. Error: ${error}`,
      'Data Exports: Update activation status');
    yield put(showAlert({ message: `Error updating activation status. ${habuSupport.message}`, type: 'error' }));
    return yield put(actions.updateActivationStatusError(error));
  }
  return yield put(actions.updateActivationStatusSuccess(success));
}

export default function* root() {
  yield all([
    createExportJob.sagaEffect,
    fetchDataOutJobs.sagaEffect,
    getPartnerAccount.sagaEffect,
    updatePartnerAccount.sagaEffect,
    takeLatest(DATA_OUT.CREATE_ACTIVATION, createActivation),
    takeLatest(DATA_OUT.CREATE_PARTNER_ACCOUNT, createPartnerAccount),
    takeLatest(DATA_OUT.DELETE_ACTIVATION, deleteActivation),
    takeLatest(DATA_OUT.DELETE_ACTIVATION_RUN, deleteActivationRun),
    takeLatest(DATA_OUT.FETCH_ACTIVATION, fetchActivation),
    takeLatest(DATA_OUT.FETCH_ACTIVATION_NAMES, fetchActivationNames),
    takeLatest(DATA_OUT.FETCH_ACTIVATION_FILTERS, fetchActivationFilters),
    takeLatest(DATA_OUT.FETCH_ACTIVATION_REPORT, fetchActivationReport),
    takeLatest(DATA_OUT.FETCH_PARTNER_ACCOUNTS, fetchPartnerAccounts),
    takeLatest(DATA_OUT.FETCH_PARTNER_ACCOUNT_FILTERS, fetchPartnerAccountFilters),
    takeLatest(DATA_OUT.FETCH_PARTNER_JOBS_VERIFICATION, fetchPartnerAccountJobs),
    takeLatest(DATA_OUT.VERIFY_JOB_PARTNERS, verifyJobPartners),
    takeLatest(DATA_OUT.FETCH_PARTNERS, fetchPartners),
    takeLatest(DATA_OUT.FETCH_DATA_OUT_SUMMARY, fetchDataOutSummary),
    takeLatest(DATA_OUT.UPDATE_ACTIVATION_STATUS, updateActivationStatus),
  ]);
}
