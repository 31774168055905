import { getNodesConnection, getNodesTypeConnect } from 'redux/reducer/FlowReducer/utils';


export const editQuestionEdgeDialog = (payload, nodes) => {
  const {
    data: {
      source, target, id, data,
    },
  } = payload;
  const nodesData = getNodesConnection(nodes, source, target);
  const connectionType = getNodesTypeConnect(nodesData[0].type, nodesData[1].type);

  return {
    open: true,
    type: connectionType,
    data: {
      ...data,
      source,
      target,
    },
    nodesData,
    edgeId: id,
  };
};
