import { UsersStatusCell } from 'pages/OrganizationManagement/organization/pages/OrganizationUsers/OrganizationUsersTable/ComponentsCell/UsersStatusCell';


export const orgUserColumns = [{
  field: 'Name',
  name: 'Name',
  valueGetter: (params) => params.row.user.name,
}, {
  field: 'Email',
  name: 'Email',
  valueGetter: (params) => params.row.user.email,
}, {
  field: 'RoleName',
  name: 'Role Name',
  valueGetter: (params) => params.row.roleName,
}, {
  field: 'UserStatus',
  name: '',
  width: 200,
  component: UsersStatusCell,

}];
