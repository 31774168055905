import React, { useContext } from 'react';
import { HDataTable, HTableControlPanel } from 'BaseComponents';
import { OrganizationUsersContext } from 'pages/OrganizationManagement/organization/pages/OrganizationUsers/OrganizationUsersTable/OrganizationUsersContextProvider';
import { iamUsers } from 'utils/urlConstants';
import {
  orgUserColumns,
} from 'pages/OrganizationManagement/organization/pages/OrganizationUsers/OrganizationUsersTable/OrganizationUsersTableLayout/orgUserColumns';
import { organizationManagement } from 'utils/spaUrls';
import { resetUser } from 'redux/actions/IamActions';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';


export const OrganizationUsersTableLayout = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    orgId,
    getActions,
    refsObject,
  } = useContext(OrganizationUsersContext);

  dispatch(resetUser());

  return (
    <HDataTable
      columns={orgUserColumns}
      dataBaseField='users'
      tableName='organization-users'
      filtersURL={iamUsers(orgId).userFilters}
      dataURL={iamUsers(orgId).list}
      actionMenu={getActions}
      controlPanel={(
        <HTableControlPanel
          createButton={{
            text: 'New User',
            onClick: () => history.push(organizationManagement.organization.users.create),
          }}
          searchAttribute
          dataContextRef={refsObject}
        />
        )}
    />
  );
};
