import Checkbox from '@mui/material/Checkbox';
import React from 'react';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { FormControlLabel, Table } from '@mui/material';


const SelectList = (
  {
    list = [],
    selected = [],
    render = item => item,
    onChange = i => i,
    compare = item => item.ID,
  },
) => (
  <Table size='small'>
    <TableHead>
      <TableRow>
        <TableCell />
      </TableRow>
    </TableHead>
    <TableBody>
      {list.map(item => (
        <TableRow hover key={item.ID}>
          <TableCell>
            <FormControlLabel
              label={render(item)}
              control={(
                <Checkbox
                  checked={selected.map(compare).indexOf(compare(item)) >= 0}
                  onChange={({ target }) => (
                    target.checked
                      ? onChange([...selected, item])
                      : onChange([...selected].filter(i => compare(i) !== compare(item)))
                  )}
                />
              )}
            />
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

export default SelectList;
