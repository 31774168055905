import { DATA_OUT } from '../actions/Types';


const initialState = {
  activationFilters: {},
  meta: {},
  partners: [],
  partnerFilters: {},
};

const DataOutReducer = (state = initialState, action) => {
  switch (action.type) {
    case DATA_OUT.CREATE_ACTIVATION_SUCCESS:
      return { ...state, meta: { created: true } };
    case DATA_OUT.CREATE_PARTNER_ACCOUNT_SUCCESS:
      return { ...state, meta: { created: true } };
    case DATA_OUT.DELETE_ACTIVATION_SUCCESS:
    case DATA_OUT.DELETE_ACTIVATION_RUN_SUCCESS:
      return { ...state, meta: { deleted: true } };
    case DATA_OUT.FETCH_ACTIVATION_SUCCESS:
      return { ...state, activation: action.payload, meta: initialState.meta };
    case DATA_OUT.FETCH_ACTIVATION_NAMES:
      return { ...state, activationNames: { loading: true } };
    case DATA_OUT.FETCH_ACTIVATION_NAMES_ERROR:
      return { ...state, activationNames: { error: action.payload } };
    case DATA_OUT.FETCH_ACTIVATION_NAMES_SUCCESS:
      return { ...state, activationNames: action.payload };
    case DATA_OUT.FETCH_ACTIVATION_FILTERS_SUCCESS:
      return { ...state, activationFilters: action.payload };
    case DATA_OUT.FETCH_ACTIVATION_FILTERS_ERROR:
      return { ...state, activationFilters: { error: action.payload } };
    case DATA_OUT.FETCH_ACTIVATION_REPORT_SUCCESS:
      return { ...state, activationReport: action.payload };
    case DATA_OUT.FETCH_PARTNERS:
      return { ...state, partners: initialState.partners, meta: initialState.meta };
    case DATA_OUT.FETCH_PARTNERS_ERROR:
      return { ...state, partners: [], meta: { error: action.payload } };
    case DATA_OUT.FETCH_PARTNERS_SUCCESS:
      return { ...state, partners: action.payload, meta: initialState.meta };
    case DATA_OUT.FETCH_PARTNER_ACCOUNTS_SUCCESS:
      return { ...state, partnerAccounts: action.payload, meta: initialState.meta };
    case DATA_OUT.FETCH_PARTNER_ACCOUNT_FILTERS_SUCCESS:
      return { ...state, partnerFilters: action.payload };
    case DATA_OUT.FETCH_PARTNER_ACCOUNT_FILTERS_ERROR:
      return { ...state, partnerFilters: { error: action.payload } };
    case DATA_OUT.FETCH_PARTNER_JOBS_VERIFICATION:
      return { ...state, partnerAccountJobs: { loading: true } };
    case DATA_OUT.FETCH_PARTNER_JOBS_VERIFICATION_SUCCESS:
      return { ...state, partnerAccountJobs: action.payload };
    case DATA_OUT.FETCH_PARTNER_JOBS_VERIFICATION_ERROR:
      return { ...state, partnerAccountJobs: { error: action.payload } };
    case DATA_OUT.VERIFY_JOB_PARTNERS:
      return { ...state, jobPartnersVerification: { loading: true } };
    case DATA_OUT.VERIFY_JOB_PARTNERS_SUCCESS:
      return { ...state, jobPartnersVerification: action.payload };
    case DATA_OUT.VERIFY_JOB_PARTNERS_ERROR:
      return { ...state, jobPartnersVerification: { error: action.payload } };
    case DATA_OUT.FETCH_DATA_OUT_SUMMARY_ERROR:
      return { ...state, summaries: { error: action.payload } };
    case DATA_OUT.FETCH_DATA_OUT_SUMMARY_SUCCESS:
      return { ...state, summaries: action.payload };
    default:
      return state;
  }
};

export default DataOutReducer;
