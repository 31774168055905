import { DATA_IN_ADMIN } from 'redux/actions/Types';


const initialState = {
  accountDetail: {
    meta: {},
    account: {},
  },
  accountList: [],
  demoAccountList: [],
  organizationList: [],
  accountAdmins: [],
  organizationDetail: {
    meta: {},
    organization: {},
  },
  organizationProducts: [],
  roleDetail: { meta: {} },
  userDetail: { meta: {}, user: {} },
};

const dataInOrgAdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case DATA_IN_ADMIN.FETCH_ALL_IMPORT_SOURCES:
      return { ...state, allImportDataSources: undefined };
    case DATA_IN_ADMIN.FETCH_ALL_IMPORT_SOURCES_SUCCESS:
      return { ...state, allImportDataSources: action.payload };
    case DATA_IN_ADMIN.FETCH_ALL_IMPORT_SOURCES_ERROR:
      return { ...state, allImportDataSources: { error: action.payload } };
    case DATA_IN_ADMIN.FETCH_ORG_IMPORT_SOURCES:
      return { ...state, orgImportDataSources: undefined };
    case DATA_IN_ADMIN.FETCH_ORG_IMPORT_SOURCES_SUCCESS:
      return { ...state, orgImportDataSources: action.payload };
    case DATA_IN_ADMIN.FETCH_ORG_IMPORT_SOURCES_ERROR:
      return { ...state, orgImportDataSources: { error: action.payload } };
    case DATA_IN_ADMIN.FETCH_ALL_IMPORT_DATA_TYPES:
      return { ...state, allImportDataTypes: undefined };
    case DATA_IN_ADMIN.FETCH_ALL_IMPORT_DATA_TYPES_SUCCESS:
      return { ...state, allImportDataTypes: action.payload };
    case DATA_IN_ADMIN.FETCH_ALL_IMPORT_DATA_TYPES_ERROR:
      return { ...state, allImportDataTypes: { error: action.payload } };
    case DATA_IN_ADMIN.FETCH_ORG_IMPORT_DATA_TYPES:
      return { ...state, orgImportDataTypes: undefined };
    case DATA_IN_ADMIN.FETCH_ORG_IMPORT_DATA_TYPES_SUCCESS:
      return { ...state, orgImportDataTypes: action.payload };
    case DATA_IN_ADMIN.FETCH_ORG_IMPORT_DATA_TYPES_ERROR:
      return { ...state, orgImportDataTypes: { error: action.payload } };
    default:
      return state;
  }
};

export default dataInOrgAdminReducer;
