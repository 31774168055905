import { buildQueryString } from 'utils/jsUtils';
import { filterToPlainObject } from 'utils/filterUtils';
import { useHistory } from 'react-router-dom';


export const useSearchUrl = (activeQuery) => {
  const history = useHistory();

  return (filtersObject) => activeQuery && history.replace({
    search: buildQueryString({ ...filterToPlainObject(filtersObject) }),
  });
};
