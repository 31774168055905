export const DATA_IN_ATTRS = {
  FETCH_DATASET_ATTRIBUTE_LIST: 'FETCH_DATASET_ATTRIBUTE_LIST',
  FETCH_DATASET_ATTRIBUTE_LIST_ERROR: 'FETCH_DATASET_ATTRIBUTE_LIST_ERROR',
  FETCH_DATASET_ATTRIBUTE_LIST_SUCCESS: 'FETCH_DATASET_ATTRIBUTE_LIST_SUCCESS',
  FETCH_DATASET_ATTRIBUTE_NAME_LIST: 'FETCH_DATASET_ATTRIBUTE_NAME_LIST',
  FETCH_DATASET_ATTRIBUTE_NAME_LIST_ERROR: 'FETCH_DATASET_ATTRIBUTE_NAME_LIST_ERROR',
  FETCH_DATASET_ATTRIBUTE_NAME_LIST_SUCCESS: 'FETCH_DATASET_ATTRIBUTE_NAME_LIST_SUCCESS',
  FETCH_DATASET_ATTRIBUTE_VALUE_LIST: 'FETCH_DATASET_ATTRIBUTE_VALUE_LIST',
  FETCH_DATASET_ATTRIBUTE_VALUE_LIST_ERROR: 'FETCH_DATASET_ATTRIBUTE_VALUE_LIST_ERROR',
  FETCH_DATASET_ATTRIBUTE_VALUE_LIST_SUCCESS: 'FETCH_DATASET_ATTRIBUTE_VALUE_LIST_SUCCESS',
  RESET_DATASET_ATTRIBUTE_LIST: 'RESET_DATASET_ATTRIBUTE_LIST',
};
