import React from 'react';
import { List, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { trademark } from 'utils/appConstants';


const StyledList = styled(List, {
  shouldForwardProp: (prop) => prop !== 'isCleanRoomTrademark' && prop !== 'drawerWidth',
})(({ theme, drawerWidth, isCleanRoomTrademark }) => ({
  width: '100%',
  maxWidth: drawerWidth,
  paddingLeft: theme.spacing(isCleanRoomTrademark ? 1 : 2),
  backgroundColor:
    isCleanRoomTrademark ? theme.palette.sideNavigation.main
      : theme.palette.sideNavigation.backgroundColor,
  position: 'fixed',
  left: 0,
  bottom: 0,
  zIndex: 1,
}));


const StyledTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isCleanRoomTrademark',
})(({ theme, isCleanRoomTrademark }) => ({
  color:
    !isCleanRoomTrademark && theme.palette.sideNavigation.button.color,
  fontSize: '13px',
  fontWeight: 600,
  opacity: '0.7',
}));

export const TrademarkItem = ({ isCleanRoomTrademark = false, drawerWidth }) => (
  <StyledList isCleanRoomTrademark={isCleanRoomTrademark} drawerWidth={drawerWidth}>
    <StyledTypography isCleanRoomTrademark={isCleanRoomTrademark}>
      {trademark}
    </StyledTypography>
  </StyledList>
);

