import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { HButton } from 'BaseComponents';
import { Link } from 'react-router-dom';
import { habuColors } from 'Theme';


export const DropdownMenu = ({
  label,
  variant,
  color = 'primary',
  icon,
  placement,
  menuItems,
  hideEmpty = false,
  size = 'small',
  tooltipTitle = '',
  infoTooltip = '',
  className = '',
}) => {
  const menuLabel = label || null;
  const menuVariant = variant || 'text';
  const endIcon = icon || <KeyboardArrowDownIcon />;
  const popperPlacement = placement || 'bottom-start';
  const [open, setOpen] = React.useState(false);
  const disabled = menuItems.length === 0;
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
    else if (event.key === 'Escape') {
      setOpen(false);
    }
  };

  const handleItemOnClick = (item) => {
    setOpen(false);
    if (!item.link) {
      item.action();
    }
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  if (hideEmpty && (!menuItems || !menuItems.length)) {
    return null;
  }

  const btn = menuLabel ? (
    <HButton
      id='dropdown-menu-button'
      data-testid='dropdown-menu-button'
      aria-controls={open ? 'dropdown-menu-list' : undefined}
      aria-expanded={open ? 'true' : undefined}
      aria-haspopup='true'
      variant={menuVariant}
      onClick={handleToggle}
      endIcon={endIcon}
      ref={anchorRef}
      disabled={disabled}
      size={size}
      color={color}
    >
      {menuLabel}
    </HButton>
  ) : (
    <IconButton
      id='dropdown-menu-button'
      data-testid='dropdown-menu-button'
      aria-controls={open ? 'dropdown-menu-list' : undefined}
      aria-expanded={open ? 'true' : undefined}
      aria-haspopup='true'
      onClick={handleToggle}
      ref={anchorRef}
      disabled={disabled}
      size={size}
      disableTouchRipple
      sx={{
        backgroundColor: open ? habuColors.neutral[10] : 'inherit',
      }}
    >
      {endIcon}
    </IconButton>
  );

  return (
    <>
      <Tooltip title={(disabled && Boolean(tooltipTitle)) ? tooltipTitle : (infoTooltip || '')}>
        <Box component='span' className={className}>{btn}</Box>
      </Tooltip>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement={popperPlacement}
        transition
        sx={{ zIndex: '1' }}
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id='dropdown-menu-list'
                  aria-labelledby='dropdown-menu-list'
                  onKeyDown={handleListKeyDown}
                >
                  {menuItems.map(item => (
                    <Tooltip
                      title={item.disabled && Boolean(item.tooltipTitle) ? item.tooltipTitle : (item.infoTooltip || '')}
                      key={item.label}
                      placement='left'
                    >
                      <Box component='span'>
                        {
                        item.link ? (
                          <MenuItem
                            data-testid={`menu-item-${item.label.toLowerCase()
                              .replace(/\s+/g, '-')}`}
                            sx={{ width: '100%' }}
                            onClick={() => handleItemOnClick(item)}
                            disabled={item.disabled}
                            component={Link}
                            to={item.to}
                          >
                            {item.label}
                          </MenuItem>
                        ) : (
                          <MenuItem
                            data-testid={`menu-item-${item.label.toLowerCase()
                              .replace(/\s+/g, '-')}`}
                            onClick={() => handleItemOnClick(item)}
                            sx={{ width: '100%' }}
                            disabled={item.disabled}
                          >
                            {item.label}
                          </MenuItem>
                        )
                      }
                      </Box>
                    </Tooltip>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
