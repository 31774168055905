import { useMemo, useState } from 'react';


export const useSearchFlag = (productList) => {
  const [search, setSearch] = useState('');
  const productListResult = useMemo(() => {
    if (search.length > 0) {
      const searchText = search.toLowerCase();

      return productList.filter(({ name, displayName }) => (
        displayName.toLowerCase().includes(searchText)
        || name.toLowerCase().includes(searchText)
      ));
    }
    return productList;
  }, [productList, search]);

  return {
    search,
    setSearch,
    productListResult,
  };
};
