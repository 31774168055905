import React from 'react';
import Skeleton from '@mui/material/Skeleton';


const FormSkeleton = ({ rows = 5 }) => (
  <>
    {Array(rows).fill(1)
      // eslint-disable-next-line react/no-array-index-key
      .map((_, i) => <Skeleton height={50} key={i} />)}
  </>
);

export default FormSkeleton;
