import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';
import { HButton, HSpinner } from 'BaseComponents';


export const MODE_ACTIVATE = 'activate';

export const MODE_REGENERATE = 'regenerate';

const instruction = {
  activate: {
    title: 'Please Note',
    modalText: 'We are about to issue your credentials. It is important that you store your credentials in a secure location. We do not have access to your credentials once they are issued and will not be able to retrieve them. If you lose your credentials you will need to regenerate them which can result you having to reconfigure any existing connections you have that utilize those credentials. Please hit the "Activate Credentials" button to continue the process. ',
    buttonText: 'Activate Credentials',
  },
  regenerate: {
    title: 'Please Note',
    modalText: 'By selecting "Regenerate" we are going to issue new credentials. This will result in the removal of your existing credentials. Any jobs that utilize the existing credentials will need to be updated with your new credentials. As always, it is important that you store your credentials in a secure location. We do not have access to your credentials once they are issued and will not be able to retrieve them. If you lose your credentials you will need to regenerate them which can result you having to reconfigure any existing connections you have that utilize those credentials. Please hit the "Regenerate Credentials" button to continue the process.',
    buttonText: 'Regenerate Credentials',
  },
};

const GenerateCredentialsModal = ({
  open, onClose, onSubmit, isLoading, mode = MODE_ACTIVATE,
}) => (
  <Dialog
    open={open}
    aria-labelledby='modal-dialog-title'
    aria-describedby='modal-dialog-description'
  >
    <DialogTitle>{instruction[mode].title}</DialogTitle>
    <DialogContent>
      <DialogContentText>{instruction[mode].modalText}</DialogContentText>
      <DialogActions>
        <HButton
          onClick={onClose}
          variant='contained'
        >
          Cancel
        </HButton>
        <HButton
          onClick={onSubmit}
          color='primary'
          autoFocus
          variant='contained'
          disabled={isLoading}
          endIcon={isLoading ? <HSpinner isButton /> : null}
        >
          {instruction[mode].buttonText}
        </HButton>
      </DialogActions>
    </DialogContent>
  </Dialog>
);

export default GenerateCredentialsModal;
