import React from 'react';
import { DeleteNodeContextProvider } from 'pages/Flow/FlowBuilder/DeleteNodeContextProvider';
import { FlowBuilderContextProvider } from 'pages/Flow/FlowBuilder/FlowBuilderContextProvider';
import { FlowBuilderLayout } from 'pages/Flow/FlowBuilder/FlowBuilderLayout';


export const FlowBuilder = () => (
  <FlowBuilderContextProvider>
    <DeleteNodeContextProvider>
      <FlowBuilderLayout />
    </DeleteNodeContextProvider>
  </FlowBuilderContextProvider>
);
