import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import React from 'react';
import SectionHeader from 'components/IAM/Admin/SectionHeader';
import Tooltip from '@mui/material/Tooltip';
import {
  FormControlLabel, Grid, Radio, RadioGroup,
} from '@mui/material';


const UserListQuestionSelect = ({
  question, onPropertyUpdate, cannotEdit,
}) => {
  const [selected, setSelected] = React.useState(question.isUserListQuestion ? 'userlist' : 'analytics');
  return (
    <>
      <Grid container alignItems='flex-start' data-testid='userlist-question-container'>
        <Grid item>
          <SectionHeader title='Question Type' />
          <RadioGroup
            aria-labelledby='radio-buttons-group-label'
            name='radio-buttons-group'
            value={selected}
            onChange={(e) => {
              const selection = e.target.value;
              setSelected(selection);
              // onSelect(selection);
              onPropertyUpdate('isUserListQuestion', selection === 'userlist');
            }}
          >
            <FormControlLabel
              disabled={cannotEdit}
              value='analytics'
              control={<Radio data-testid='analytics-radio' color='primary' />}
              label='Analytics'
            />
            <Grid item>
              <Grid container alignItems='center'>
                <FormControlLabel
                  disabled={cannotEdit}
                  value='userlist'
                  control={<Radio data-testid='userlist-radio' color='primary' />}
                  label='User List'
                />
                <Grid item>
                  <SectionHeader subtitle='(For Snowflake Clean Rooms only)' />
                </Grid>
                <Tooltip
                  title='Userlist Questions differ from Analytic Questions in that the console will display the number of users the list contains, vs the list data itself. Users can access the list itself via their Snowflake instance.'
                  p={0}
                >
                  <HelpOutlineIcon fontSize='small' style={{ marginLeft: '5px' }} />
                </Tooltip>
              </Grid>
            </Grid>
          </RadioGroup>
        </Grid>
      </Grid>
    </>
  );
};

export default UserListQuestionSelect;
