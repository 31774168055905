import * as Enums from 'utils/ignoramusEnums';
import Box from '@mui/material/Box';
import React, { memo, useContext, useState } from 'react';
import Switch from '@mui/material/Switch';
import { AlertsContext } from 'pages/InternalAdmin/organization/pages/Alerts/AlertsListLayout';
import { AlertsPageContext } from 'pages/InternalAdmin/organization/pages/Alerts/Alerts';
import { DataContext } from 'BaseComponents/HDataTable/DataContextProvider';
import { Tooltip } from '@mui/material';


export const StatusCell = memo(({ row }) => {
  const { updateStatus } = useContext(AlertsContext);
  const { refreshListData } = useContext(DataContext);
  const { isCurrentCrExpired } = useContext(AlertsPageContext);

  const [currentStatus, setCurrentStatus] = useState(row.status);

  const changeStatus = (e) => {
    if (isCurrentCrExpired) return;

    const newStatus = e.target.checked ? Enums.status.ACTIVE.key : Enums.status.PAUSED.key;
    setCurrentStatus(newStatus);

    const payload = { status: newStatus };
    updateStatus(row, payload);
    refreshListData();
  };


  return (
    <Box>
      <Tooltip
        title={isCurrentCrExpired ? 'Clean Room Expired. Unable to utilize alert.' : ''}
        placement='bottom'
      >
        <span>
          <Switch
            size='small'
            color='success'
            onChange={changeStatus}
            checked={isCurrentCrExpired ? false : currentStatus === Enums.status.ACTIVE.key}
            disabled={isCurrentCrExpired}
          />
        </span>
      </Tooltip>
    </Box>
  );
});

StatusCell.displayName = 'StatusCell';
