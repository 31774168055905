import { activeOrgSelector } from 'redux/reducer/AuthenticationReducer';
import { axiosInstance } from 'utils/http';
import { flow } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'react-query';


export const useGetCleanRoomFlowRunReport = ({ crId, runId }) => {
  const dispatch = useDispatch();
  const { ID: orgId } = useSelector(activeOrgSelector);

  const {
    isLoading, isError, isSuccess, data, mutate,
  } = useMutation(
    ['Flow-Node-Report', crId, orgId],
    async ({ payload, nodeID }) => axiosInstance.post(
      flow(orgId).crNodeReportOutput(crId, runId, nodeID), payload,
    ),
    {
      onError: (error) => dispatch(showAlert({ message: error.response.data.message, type: 'error' })),
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  return {
    isLoading, isError, isSuccess, data, mutate,
  };
};
