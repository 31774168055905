import { DATA_IN } from 'redux/actions/Types';
import { dataIn } from 'utils/urlConstants';


export const resetDataInJob = () => ({ type: DATA_IN.RESET_DATA_IN_JOB });

export const jobSave = ({
  consentDetails, job, jobId, orgId, parameters, setConsent,
}) => ({
  type: DATA_IN.JOB_SAVE,
  consentPayload: consentDetails,
  jobId,
  jobPayload: job,
  jobUrl: jobId ? dataIn(orgId).updateJob(jobId) : dataIn(orgId).createJob,
  orgId,
  parameters,
  setConsent,
});

export const jobSaveSuccess = payload => ({
  type: DATA_IN.JOB_SAVE_SUCCESS,
  payload,
});

export const jobSaveFailure = payload => ({
  type: DATA_IN.JOB_SAVE_FAILURE,
  payload,
});

export const fetchDataInJobs = (orgId, filter) => ({
  payload: filter,
  type: DATA_IN.FETCH_DATA_IN_JOBS,
  url: dataIn(orgId).jobList,
});

export const fetchDataInJobsSuccess = payload => ({
  type: DATA_IN.FETCH_DATA_IN_JOBS_SUCCESS,
  payload,
});

export const fetchDataInJobsError = payload => ({
  type: DATA_IN.FETCH_DATA_IN_JOBS_ERROR,
  payload,
});

export const changeJobStatus = (orgId, jobId, status, meta) => ({
  meta,
  payload: { status },
  type: DATA_IN.DATA_IN_CHANGE_JOB_STATUS,
  url: dataIn(orgId).changeStatus(jobId),
});

export const changeJobStatusError = payload => ({
  type: DATA_IN.DATA_IN_CHANGE_JOB_STATUS_ERROR,
  payload,
});

export const fetchDataInDetails = (orgId, id) => ({
  type: DATA_IN.FETCH_DATA_IN_DETAILS,
  url: `${dataIn(orgId).dataInJobDetails(id)}`,
});

export const fetchDataInDetailsError = payload => ({
  type: DATA_IN.FETCH_DATA_IN_DETAILS_ERROR,
  payload,
});

export const fetchDataInDetailsSuccess = payload => ({
  type: DATA_IN.FETCH_DATA_IN_DETAILS_SUCCESS,
  payload,
});

export const fetchDataInJobSummary = (orgId, id) => ({
  type: DATA_IN.FETCH_DATA_IN_JOB_SUMMARY,
  url: `${dataIn(orgId).dataInJobOverview(id)}`,
});

export const fetchDataInJobSummaryError = payload => ({
  type: DATA_IN.FETCH_DATA_IN_JOB_SUMMARY_ERROR,
  payload,
});

export const fetchDataInJobSummarySuccess = payload => ({
  type: DATA_IN.FETCH_DATA_IN_JOB_SUMMARY_SUCCESS,
  payload,
});

export const fetchDataSources = orgId => ({
  type: DATA_IN.FETCH_DATA_SOURCES,
  url: dataIn(orgId).listOrgDataSources,
});

export const receiveDataSourcesSuccess = data => ({
  type: DATA_IN.RECEIVE_DATA_SOURCES_SUCCESS,
  payload: data,
});

export const receiveDataSourcesError = data => ({
  type: DATA_IN.RECEIVE_DATA_SOURCES_ERROR,
  payload: data,
});

export const fetchDataSourceJobs = orgId => ({
  type: DATA_IN.FETCH_DATA_SOURCE_JOBS,
  url: dataIn(orgId).dataSourceJobs,
});

export const fetchDataSourceJobsSuccess = data => ({
  type: DATA_IN.FETCH_DATA_SOURCE_JOBS_SUCCESS,
  payload: data,
});

export const fetchDataSourceJobsError = data => ({
  type: DATA_IN.FETCH_DATA_SOURCE_JOBS_ERROR,
  payload: data,
});

export const fetchDataSource = id => ({
  type: DATA_IN.FETCH_DATA_SOURCE,
  url: dataIn().dataSource(id),
});

export const fetchDataSourceSuccess = data => ({
  type: DATA_IN.FETCH_DATA_SOURCE_SUCCESS,
  payload: data,
});

export const fetchDataSourceFailure = data => ({
  type: DATA_IN.FETCH_DATA_SOURCE_FAILURE,
  payload: data,
});

export const fetchDataSourceParameters = (dataSourceId, orgId, importDataTypeID, isCDP) => ({
  type: DATA_IN.FETCH_DATA_SOURCE_PARAMETERS,
  url: dataIn(orgId).dataSourceParameters(dataSourceId, importDataTypeID, isCDP),
});

export const fetchDataSourceParametersSuccess = payload => ({
  type: DATA_IN.FETCH_DATA_SOURCE_PARAMETERS_SUCCESS,
  payload,
});

export const fetchDataSourceParametersFailure = payload => ({
  type: DATA_IN.FETCH_DATA_SOURCE_PARAMETERS_FAILURE,
  payload,
});

export const fetchDataSourceDependentParameters = (orgId, dataSourceId, parameterId, value) => ({
  type: DATA_IN.FETCH_DATA_SOURCE_DEPENDENT_PARAMETERS,
  url: dataIn(orgId).dataSourceDependentParameters(dataSourceId, parameterId, { value }),
  id: parameterId,
  value,
  orgId,
});

export const fetchDataSourceDependentParametersSuccess = payload => ({
  type: DATA_IN.FETCH_DATA_SOURCE_DEPENDENT_PARAMETERS_SUCCESS,
  payload,
});

export const fetchDataSourceDependentParametersFailure = payload => ({
  type: DATA_IN.FETCH_DATA_SOURCE_DEPENDENT_PARAMETERS_FAILURE,
  payload,
});

export const fetchJobParameters = (orgId, dataSourceId, jobId, paramName) => ({
  type: DATA_IN.FETCH_JOB_PARAMETERS,
  url: dataIn(orgId).jobParameters(dataSourceId, jobId, paramName),
});

export const fetchJobParametersSuccess = payload => ({
  type: DATA_IN.FETCH_JOB_PARAMETERS_SUCCESS,
  payload,
});

export const fetchJobParametersFailure = payload => ({
  type: DATA_IN.FETCH_JOB_PARAMETERS_ERROR,
  payload,
});

export const fetchDataSetTypeList = (orgId, dataSourceId) => ({
  type: DATA_IN.FETCH_DATA_SET_TYPE_LIST,
  url: dataIn(orgId).importDataTypeList(dataSourceId),
});

export const receiveDataSetTypeList = data => ({
  type: DATA_IN.RECEIVE_DATA_SET_TYPE_LIST,
  payload: data,
});

export const fetchDeviceTypeList = () => ({
  type: DATA_IN.FETCH_DEVICE_TYPE_LIST,
  url: dataIn().deviceTypeList,
});

export const receiveDeviceTypeList = data => ({
  type: DATA_IN.RECEIVE_DEVICE_TYPE_LIST,
  payload: data,
});

export const fetchAttributeCategoryList = () => ({
  type: DATA_IN.FETCH_ATTRIBUTE_CATEGORY_LIST,
  url: dataIn().attributeCategoryList,
});

export const receiveAttributeCategoryList = data => ({
  type: DATA_IN.RECEIVE_ATTRIBUTE_CATEGORY_LIST,
  payload: data,
});

export const updateJobParameters = (data, orgId, dataSourceId, jobId) => ({
  type: DATA_IN.UPDATE_JOB_PARAMETERS,
  payload: data,
  url: dataIn(orgId).updateJobParameters(dataSourceId, jobId),
});

export const updateJobParametersSuccess = payload => ({
  type: DATA_IN.UPDATE_JOB_PARAMETERS_SUCCESS,
  payload,
});

export const updateJobParametersFailure = payload => ({
  type: DATA_IN.UPDATE_JOB_PARAMETERS_FAILURE,
  payload,
});

export const submitDataInFieldMapping = (data, orgId, jobId) => ({
  type: DATA_IN.SUBMIT_DATA_IN_FIELD_MAPPING,
  payload: data,
  url: dataIn(orgId).submitFieldConfigurations(jobId),
});

export const submitDataInJobFieldMappingSuccess = payload => ({
  type: DATA_IN.SUBMIT_DATA_IN_FIELD_MAPPING_SUCCESS,
  payload,
});

export const submitDataInJobFieldMappingError = payload => ({
  type: DATA_IN.SUBMIT_DATA_IN_FIELD_MAPPING_ERROR,
  payload,
});

export const fetchFieldMappingList = (orgId, jobId, includedOnly = false) => ({
  type: DATA_IN.FETCH_FIELD_MAPPING_LIST,
  url: dataIn(orgId).fieldConfigList(jobId, includedOnly),
});

export const fetchFieldMappingListSuccess = data => ({
  type: DATA_IN.FETCH_FIELD_MAPPING_LIST_SUCCESS,
  payload: data,
});

export const fetchFieldMappingListFailure = data => ({
  type: DATA_IN.FETCH_FIELD_MAPPING_LIST_FAILURE,
  payload: data,
});

export const fetchDataInJobConsent = (orgId, jobId) => ({
  type: DATA_IN.FETCH_DATA_IN_JOB_CONSENT,
  url: dataIn(orgId).jobConsentList(jobId),
});

export const receiveDataInJobConsent = data => ({
  type: DATA_IN.RECEIVE_DATA_IN_JOB_CONSENT,
  payload: data,
});

export const fetchDataInJobTrend = (orgId, jobId, startDate, domain) => ({
  type: DATA_IN.FETCH_DATA_IN_JOB_TREND,
  url: dataIn(orgId).jobTrend(jobId, startDate, domain),
});

export const fetchDataInJobTrendSuccess = payload => ({
  type: DATA_IN.FETCH_DATA_IN_JOB_TREND_SUCCESS,
  payload,
});

export const fetchDataInJobTrendError = payload => ({
  type: DATA_IN.FETCH_DATA_IN_JOB_TREND_ERROR,
  payload,
});

export const fetchDataInPeopleHouseholds = (orgId, jobId, startDate, domain) => ({
  type: DATA_IN.FETCH_DATA_IN_PEOPLE_HOUSEHOLDS,
  url: dataIn(orgId).peopleHouseholdTrend(jobId, startDate, domain),
});

export const fetchDataInPeopleHouseholdsSuccess = payload => ({
  type: DATA_IN.FETCH_DATA_IN_PEOPLE_HOUSEHOLDS_SUCCESS,
  payload,
});

export const fetchDataInPeopleHouseholdsError = payload => ({
  type: DATA_IN.FETCH_DATA_IN_PEOPLE_HOUSEHOLDS_ERROR,
  payload,
});

export const createDataInJobConsent = (data, orgId, jobId) => ({
  type: DATA_IN.CREATE_DATA_IN_JOB_CONSENT,
  payload: data,
  url: dataIn(orgId).createJobConsent(jobId),
});

export const submitDataInJobConsentSuccess = payload => ({
  type: DATA_IN.SUBMIT_DATA_IN_JOB_CONSENT_SUCCESS,
  payload,
});

export const submitDataInJobConsentError = payload => ({
  type: DATA_IN.SUBMIT_DATA_IN_JOB_CONSENT_ERROR,
  payload,
});

export const updateJobStatus = (data, orgId, jobId) => ({
  type: DATA_IN.UPDATE_JOB_STATUS,
  payload: data,
  url: dataIn(orgId).updateJobStatus(jobId),
});


export const submitJobStatusSuccess = payload => ({
  type: DATA_IN.SUBMIT_JOB_STATUS_SUCCESS,
  payload,
});

export const submitJobStatusError = payload => ({
  type: DATA_IN.SUBMIT_JOB_STATUS_ERROR,
  payload,
});

export const changeJobStage = (orgId, jobId, stage, meta) => ({
  type: DATA_IN.CHANGE_JOB_STAGE,
  payload: { stage },
  meta,
  url: dataIn(orgId).changeJobStage(jobId),
});

export const changeJobStageSuccess = payload => ({
  type: DATA_IN.CHANGE_JOB_STAGE_SUCCESS,
  payload,
});

export const changeJobStageError = payload => ({
  type: DATA_IN.CHANGE_JOB_STAGE_ERROR,
  payload,
});

export const fetchDataInJobsSummary = orgId => ({
  type: DATA_IN.FETCH_DATA_IN_JOBS_SUMMARY,
  url: dataIn(orgId).jobsSummary,
});

export const fetchDataInJobsSummarySuccess = payload => ({
  type: DATA_IN.FETCH_DATA_IN_JOBS_SUMMARY_SUCCESS,
  payload,
});

export const fetchDataInJobsSummaryError = payload => ({
  type: DATA_IN.FETCH_DATA_IN_JOBS_SUMMARY_ERROR,
  payload,
});


export const fetchDataInJobStats = (orgId, importJobRunId) => ({
  type: DATA_IN.FETCH_DATA_IN_JOB_STATS,
  url: dataIn(orgId).jobStats(importJobRunId),
});

export const fetchDataInJobStatsSuccess = payload => ({
  type: DATA_IN.FETCH_DATA_IN_JOB_STATS_SUCCESS,
  payload,
});

export const fetchDataInJobStatsError = payload => ({
  type: DATA_IN.FETCH_DATA_IN_JOB_STATS_ERROR,
  payload,
});

export const fetchDataInFilters = orgId => ({
  type: DATA_IN.FETCH_DATA_IN_FILTERS,
  url: dataIn(orgId).filters,
});

export const fetchDataInFiltersSuccess = payload => ({
  type: DATA_IN.FETCH_DATA_IN_FILTERS_SUCCESS,
  payload,
});

export const fetchDataInFiltersError = payload => ({
  type: DATA_IN.FETCH_DATA_IN_FILTERS_ERROR,
  payload,
});
