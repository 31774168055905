import Grid from '@mui/material/Grid';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import React, { useEffect, useMemo } from 'react';
import SmallQuestionNode from 'pages/Flow/Nodes/QuestionNode/SmallQuesionNode';
import Typography from '@mui/material/Typography';
import {
  DISPLAY_Q_INPUT_TYPES,
} from 'utils/constants/question.constant';
import {
  FlowNode, FlowNodeBody, FlowNodeHeader, NodeError, NodeSkeleton,
} from 'pages/Flow/Nodes/FlowNode';
import { HHandle } from 'pages/Flow/Nodes/HHandle';
import { IconButton } from '@mui/material';
import { NODES_OBJECT, NODES_TYPE } from 'pages/Flow/contacts';
import { Position } from 'reactflow';
import { habuColors } from 'Theme';
import { openSidePanel, uptadeFlowNodesQuestionAction } from 'redux/actions/FlowAction';
import { useActionMenuItems } from 'pages/Flow/Nodes/QuestionNode/useActionMenuItems';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';


const QuestionHeading = ({ text = '' }) => (
  <Typography variant='subtitle3' sx={{ color: habuColors.neutral['60'] }}>
    {text}
  </Typography>
);



const QuestionNodeDetails = ({
  qId, selected, nodeId,
}) => {
  const { crId } = useParams();
  const nodeSize = useSelector(state => state.flow.nodeSize);
  const dispatch = useDispatch();
  const qNode = useSelector(state => (
    state.flow.nodes.filter(i => i.id === nodeId)[0]
  ));
  const { data: question } = qNode;
  const isListQuestion = question.isUserListQuestion;
  const questionType = isListQuestion ? 'List Question' : 'Analytical Question';


  const questionNodeAction = useActionMenuItems();
  const actionMenuItems = useMemo(() => (
    questionNodeAction(question, crId)
  ), [questionNodeAction, question, crId]);

  const onInfoClick = () => {
    dispatch(openSidePanel({ type: 'NODE', data: qNode }));
  };

  useEffect(() => {
    dispatch(uptadeFlowNodesQuestionAction(
      { qId, nodeId },
    ));
  }, [dispatch, nodeId, qId]);

  if (!question.title) {
    return (
      <NodeSkeleton type={NODES_TYPE.question} selected={selected} />
    );
  }

  if (!question.title) {
    return (
      <NodeError type={NODES_TYPE.question} selected={selected} />
    );
  }

  const MediumQuestionNode = (
    <FlowNode type={NODES_TYPE.question} selected={selected}>
      <HHandle type='target' position={Position.Left} />
      <FlowNodeHeader
        Icon={NODES_OBJECT[NODES_TYPE.question].Icon}
        menuItems={actionMenuItems}
      >
        <Grid container alignItems='baseline'>
          <Grid item xs zeroMinWidth>
            <Typography noWrap pl={1}>
              {question.title}
            </Typography>
          </Grid>
          <Grid item xs='auto'>
            <IconButton edge='end' aria-label='comments' onClick={onInfoClick}>
              <InfoOutlinedIcon fontSize='small' />
            </IconButton>
          </Grid>
        </Grid>
      </FlowNodeHeader>
      <FlowNodeBody>
        <Grid container spacing={2} sx={{ pl: 4 }}>
          <Grid item xs={12}>
            <QuestionHeading text='QUESTION TYPE' />
            <Typography>{questionType}</Typography>
          </Grid>
          <Grid item xs={12} container>
            <Grid item xs={12}>
              <QuestionHeading text='DATASET' />
            </Grid>
            {question.dataTypes && question.dataTypes.map((dt) => (
              <Grid item xs={12} key={dt.ID}>
                <Typography>
                  {`@${dt.macro}(${dt.importDataType.displayName})`}
                </Typography>
              </Grid>
            ))}
          </Grid>
          <Grid item xs={12} container>
            <Grid item xs={12}>
              <QuestionHeading text='RUN TIME PARAMETERS' />
            </Grid>
            {question.parameters && question.parameters.map((param) => (
              <Grid item xs={12} key={param.displayName}>
                <Typography>
                  {`${param.displayName} (${DISPLAY_Q_INPUT_TYPES[param.type].label})`}
                </Typography>
              </Grid>
            ))}
          </Grid>
          <Grid item xs={12} container>
            <QuestionHeading text='OUTPUT' />
            {isListQuestion ? (
              <>
                {question.userListOutputs && question.userListOutputs.map((metric) => (
                  <Grid item xs={12} key={metric.ID}>
                    <Typography>
                      {`${metric.name} (${DISPLAY_Q_INPUT_TYPES[metric.type].label})`}
                    </Typography>
                  </Grid>
                ))}
              </>
            ) : (
              <>
                {question.dimensions && question.dimensions.map((dimension) => (
                  <Grid item xs={12} key={dimension.ID}>
                    <Typography>
                      {`${dimension.displayName} (${DISPLAY_Q_INPUT_TYPES[dimension.type].label})`}
                    </Typography>
                  </Grid>
                ))}
                {question.metrics && question.metrics.map((metric) => (
                  <Grid item xs={12} key={metric.ID}>
                    <Typography>
                      {`${metric.displayName} (${DISPLAY_Q_INPUT_TYPES[metric.type].label})`}
                    </Typography>
                  </Grid>
                ))}
              </>
            ) }
          </Grid>
        </Grid>
      </FlowNodeBody>
      <HHandle type='source' position={Position.Right} />
    </FlowNode>
  );

  return nodeSize === 'SM' ? SmallQuestionNode({ question, selected, questionType }) : MediumQuestionNode;
};

export default QuestionNodeDetails;
