import http from 'utils/http';
import { DATA_OUT } from 'redux/actions/Types';
import { SyntheticRedux } from 'synthetic-redux';
import { combineReducers } from 'redux';
import { dataOut } from 'utils/urlConstants';


const createExportJob = new SyntheticRedux({ type: DATA_OUT.CREATE_EXPORT }, 'createResponse');

createExportJob.ignite = (orgId, payload) => createExportJob.actions.ignite({
  errorMeta: 'Data Exports: Create Export',
  method: http.post,
  url: dataOut(orgId).createExport,
  payload,
});


const fetchDataOutJobs = new SyntheticRedux({ type: DATA_OUT.FETCH_DATA_OUT_JOBS }, 'listResponse');

fetchDataOutJobs.ignite = (orgId, payload) => fetchDataOutJobs.actions.ignite({
  errorMeta: `Data Exports: Fetching ${payload.jobType} Jobs`,
  method: http.post,
  url: dataOut(orgId).activations,
  payload,
});

const getPartnerAccount = new SyntheticRedux({ type: DATA_OUT.GET_PARTNER_ACCOUNT }, 'detailResponse');

getPartnerAccount.ignite = (orgId, partnerAccountId) => getPartnerAccount.actions.ignite({
  errorMeta: 'Data Exports: Get Partner Account',
  method: http.get,
  url: dataOut(orgId).partnerAccount(partnerAccountId),
});

const updatePartnerAccount = new SyntheticRedux({ type: DATA_OUT.UPDATE_PARTNER_ACCOUNT }, 'detailResponse');

updatePartnerAccount.ignite = (
  orgId, partnerAccountId, payload,
) => updatePartnerAccount.actions.ignite({
  errorMeta: 'Data Exports: Update Partner Account',
  method: http.put,
  url: dataOut(orgId).partnerAccount(partnerAccountId),
  payload,
});

const synDataOutReducer = combineReducers({
  createExportJob: createExportJob.reducer,
  fetchDataOutJobs: fetchDataOutJobs.reducer,
  getPartnerAccount: getPartnerAccount.reducer,
  updatePartnerAccount: updatePartnerAccount.reducer,
});

export {
  createExportJob, fetchDataOutJobs, getPartnerAccount, updatePartnerAccount, synDataOutReducer,
};
