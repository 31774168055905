import { activeOrgSelector } from 'redux/reducer/AuthenticationReducer';
import { axiosInstance } from 'utils/http';
import { organizationIdentity } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'react-query';


export const useUpdateIdentity = () => {
  const dispatch = useDispatch();
  const { ID: orgId } = useSelector(activeOrgSelector);

  return useMutation(
    (payload) => axiosInstance.put(organizationIdentity(orgId).update, payload),
    {
      onSuccess: () => {
        dispatch(showAlert({
          message: 'Identity updated successfully!',
          type: 'success',
        }));
      },
      onError: (err) => {
        const errorMessage = err.response?.data?.message || 'Failed to update identity';
        dispatch(showAlert({
          message: errorMessage,
          type: 'error',
        }));
      },
    },
  );
};
