import React, { useContext } from 'react';
import { HSpinner } from 'BaseComponents';
import { OrganizationUsersContext } from 'pages/OrganizationManagement/organization/pages/OrganizationUsers/OrganizationUsersTable/OrganizationUsersContextProvider';


export const UsersStatusCell = () => {
  const {
    isDeleting,
  } = useContext(OrganizationUsersContext);

  return (
    <div>
      {isDeleting && (
        <HSpinner isButton />
      )}
    </div>
  );
};
