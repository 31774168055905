import MenuItem from '@mui/material/MenuItem';
import NoData from 'components/Common/NoData';
import PermissionService from 'components/Common/PermissionService';
import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { HButton, HSpinner } from 'BaseComponents';
import { updateDisplayName } from 'components/DataConnection/service';
import { userPermissions } from 'utils/appConstants';


const HABU_MANAGED_SNOWFLAKE = 'HABU_MANAGED_SNOWFLAKE';

const DataSourceLocationTable = ({
  list,
  generateNew,
  isSaving,
  savingDataSource,
}) => {
  const [selectedRegions, setSelectedRegions] = React.useState({});

  const isHabuManagedSnowflakeEnabled = PermissionService.isProductEnabled([
    userPermissions.habuManagedSnowflakeAccount]);

  if (!list) {
    return <Skeleton width={250} height={50} />;
  }
  else if (list.error) {
    return <NoData />;
  }

  const handleRegionChange = (dataSourceName, event) => {
    const selectedRegion = event.target.value;
    setSelectedRegions(prevSelectedRegions => ({
      ...prevSelectedRegions,
      [dataSourceName]: selectedRegion,
    }));
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Location</TableCell>
            {
              isHabuManagedSnowflakeEnabled && (
                <TableCell>Region</TableCell>
              )
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((row, rowIndex) => (
            <TableRow key={`${row.ID}-${String(rowIndex)}`}>
              <TableCell>
                <Typography variant='subtitle2'>
                  {updateDisplayName(row.dataSourceDisplayName)}
                </Typography>
              </TableCell>
              <TableCell>
                <LocationCell
                  row={row}
                  isSaving={isSaving}
                  savingDataSource={savingDataSource}
                  selectedRegion={selectedRegions[row.dataSourceName]}
                  generateNew={generateNew}
                />
              </TableCell>
              <TableCell>
                {
                  isHabuManagedSnowflakeEnabled && (
                    <RegionCell
                      row={row}
                      isSaving={isSaving}
                      savingDataSource={savingDataSource}
                      selectedRegion={selectedRegions[row.dataSourceName]}
                      handleRegionChange={handleRegionChange}
                    />
                  )
                }
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const LocationCell = ({
  row,
  isSaving,
  savingDataSource,
  selectedRegion,
  generateNew,
}) => {
  const isHabuManagedSnowflake = row.dataSourceName === HABU_MANAGED_SNOWFLAKE;
  const isSavingCall = savingDataSource === row.dataSourceName && isSaving;

  const isButtonDisabled = (
    isSavingCall || (isHabuManagedSnowflake && !selectedRegion)
  );

  if (row.location) {
    return <Typography variant='body2'>{row.location}</Typography>;
  }

  return (
    <HButton
      color='primary'
      disabled={isButtonDisabled}
      endIcon={isSavingCall && <HSpinner isButton />}
      onClick={() => {
        generateNew(row.dataSourceName, isHabuManagedSnowflake ? selectedRegion : null);
      }}
      type='submit'
      variant='contained'
    >
      Generate Location
    </HButton>
  );
};


const RegionCell = ({
  row,
  selectedRegion,
  handleRegionChange,
  savingDataSource,
  isSaving,
}) => {
  const isHabuManagedSnowflake = row.dataSourceName === HABU_MANAGED_SNOWFLAKE;
  const isSavingCall = savingDataSource === row.dataSourceName && isSaving;

  if (isHabuManagedSnowflake) {
    if (!row.location) {
      return (
        <TextField
          select
          fullWidth
          size='small'
          placeholder='Select Region'
          label='Select Region'
          sx={{ width: 300 }}
          disabled={isSavingCall}
          value={selectedRegion ?? ''}
          onChange={event => handleRegionChange(row.dataSourceName, event)}
        >
          {row.credentialConfigListValues.map((credentialConfig) => (
            <MenuItem key={credentialConfig.ID} value={credentialConfig}>
              {credentialConfig.displayName}
            </MenuItem>
          ))}
        </TextField>
      );
    }
    else {
      return (
        <Tooltip title={row.credentialConfigListValues[0]?.displayName || ''}>
          <TextField
            disabled
            fullWidth
            hiddenLabel
            size='small'
            defaultValue={row.credentialConfigListValues[0]?.displayName || ''}
            variant='filled'
            sx={{
              width: 300,
              '& .MuiInputBase-input': {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              },
            }}
          />
        </Tooltip>
      );
    }
  }

  return '-';
};

export default DataSourceLocationTable;
