import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import ListViewTable from 'components/Common/ListViewTable';
import PageHeader from 'components/Common/PageHeader';
import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortIconLabel from 'components/Common/TableSortIconLabel';
import useSortableTable from 'hooks/useSortableTable';
import { HButton } from 'BaseComponents';
import { Link, useHistory } from 'react-router-dom';
import { ListControls } from 'components/Common/ListControls';
import { buildQueryString, searchBy, useQuery } from 'utils/jsUtils';
import { crumbs } from 'components/Common/BreadcrumbsNavigation';
import { fetchCrAllQuestions } from 'pages/InternalAdmin/application/CleanRoom/redux/actions';
import { internalAdmin } from 'utils/spaUrls';
import { rowsPerPageOptions } from 'utils/appConstants';
import { useDispatch, useSelector } from 'react-redux';


const CleanRoomQuestions = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();

  const allCRQuestions = useSelector(state => state.internalAdmin
    .application.cleanRoomAdmin.allCRQuestions);

  const [search, updateSearch] = React.useState(query.get('search'));
  const [questions, updateQuestions] = React.useState(allCRQuestions);

  const tableOptions = useSortableTable(questions, { rowsPerPage: rowsPerPageOptions[0] });

  React.useEffect(() => {
    dispatch(fetchCrAllQuestions());
  }, [dispatch]);

  React.useEffect(() => {
    if (allCRQuestions) {
      updateQuestions(allCRQuestions);
    }
  }, [allCRQuestions]);

  const onSearch = React.useCallback((field, text) => {
    updateSearch(text);
    if (allCRQuestions) {
      updateQuestions(searchBy(allCRQuestions, field, text));
    }
    history.push({
      search: buildQueryString({ search: text }),
    });
  }, [allCRQuestions, history]);

  return (
    <>
      <PageHeader title='Clean Room Questions' breadcrumbs={[crumbs.helium, crumbs.internalAdmin]} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <ListControls
              allFilters={{ filters: [] }}
              onSearch={onSearch}
              searchAttribute='title'
              searchText={search}
              createButton={{
                onClick: () => history.push(internalAdmin.application.cleanRoomQuestion('')),
                text: 'Add New Question',
              }}
            />
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ListViewTable list={allCRQuestions}>
                    <Table size='small'>
                      <TableHead>
                        <TableRow>
                          <TableCell align='left'>
                            <TableSortIconLabel
                              active={tableOptions.orderBy}
                              direction={tableOptions.orderDirection}
                              field='title'
                              onClick={tableOptions.setOrderBy}
                              title='Question'
                            />
                          </TableCell>
                          <TableCell align='left'>
                            <TableSortIconLabel
                              active={tableOptions.orderBy}
                              direction={tableOptions.orderDirection}
                              field='category'
                              onClick={tableOptions.setOrderBy}
                              title='Use Case'
                            />
                          </TableCell>
                          <TableCell />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {tableOptions.pageList.map(item => (
                          <TableRow key={item.ID}>
                            <TableCell>
                              {item.title}
                            </TableCell>
                            <TableCell>
                              {item.category}
                            </TableCell>
                            <TableCell align='right'>
                              <HButton
                                variant='contained'
                                color='primary'
                                size='medium'
                                component={Link}
                                to={internalAdmin.application.cleanRoomQuestion(item.ID)}
                              >
                                Edit
                              </HButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    <TablePagination
                      backIconButtonProps={{ 'aria-label': 'Previous Page' }}
                      component='div'
                      count={tableOptions.count}
                      onPageChange={(e, newPage) => tableOptions.setPage(newPage)}
                      onRowsPerPageChange={e => tableOptions.setRowsPerPage(e.target.value)}
                      nextIconButtonProps={{ 'aria-label': 'Next Page' }}
                      page={tableOptions.page}
                      rowsPerPage={tableOptions.rowsPerPage}
                      rowsPerPageOptions={rowsPerPageOptions}
                    />
                  </ListViewTable>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default CleanRoomQuestions;
