import React, { useContext, useState } from 'react';
import TextFieldInput from 'components/Common/TextFieldInput';
import Typography from '@mui/material/Typography';
import { FlowContext } from 'pages/Flow/FlowContextProvider';
import { HDialog, HDialogContent } from 'BaseComponents';
import { InputLabel } from '@mui/material';
import { cleanRoom } from 'utils/spaUrls';
import { useHistory, useParams } from 'react-router-dom';


const initialState = {
  errorMessage: undefined,
  flow: {
    name: '',
    description: '',
  },
};

export const CreateFlowDialog = () => {
  const history = useHistory();
  const { crId } = useParams();
  const [flowDetails, setFlowDetails] = useState({ ...initialState });
  const {
    createNewFlowDialog,
    setCreateNewFlowDialog,
    isLoadingCreateFlow,
    mutateAsyncCreatFlow,
  } = useContext(FlowContext);

  const handleSubmit = async () => {
    if (flowDetails.flow.name.length === 0) {
      setFlowDetails((state) => ({
        ...state,
        errorMessage: 'Name Required',
      }));
      return;
    }
    const newFlow = await mutateAsyncCreatFlow({
      cleanRoomID: crId ?? '',
      flow: flowDetails.flow,
    });
    const flowID = newFlow?.data?.flow.ID;
    const crFlowID = newFlow?.data?.cleanRoomFlow.ID;

    if (flowID) {
      history.push(cleanRoom.flowBuilder(crId, crFlowID, flowID));
    }
  };

  const handleOnFocus = () => {
    if (flowDetails.errorMessage) {
      setFlowDetails((state) => ({
        ...state,
        errorMessage: undefined,
      }));
    }
  };

  const handleOnBlurField = (v, fieldName) => {
    setFlowDetails((state) => ({
      ...state,
      flow: {
        ...state.flow,
        [fieldName]: v,
      },
    }));
  };

  const handleCancel = () => {
    setCreateNewFlowDialog(false);
    setFlowDetails({ ...initialState });
  };

  return (
    <HDialog
      onClose={handleCancel}
      onSubmit={handleSubmit}
      open={createNewFlowDialog}
      title={(
        <Typography variant='h4'>
          Create new Flow
        </Typography>
    )}
      loading={isLoadingCreateFlow}
      cancelText='Cancel'
      submitText='Create Flow'
      maxWidth='md'
    >
      <HDialogContent dividers>
        <InputLabel shrink htmlFor='title' required>
          Flow Name
        </InputLabel>
        <TextFieldInput
          value={flowDetails.flow.name}
          placeholder='Flow Name'
          onBlur={(v) => handleOnBlurField(v, 'name')}
          onFocus={handleOnFocus}
          required
          error={Boolean(flowDetails.errorMessage)}
          helperText={flowDetails.errorMessage}
          disabled={isLoadingCreateFlow}
        />
        <TextFieldInput
          value={flowDetails.flow.description}
          placeholder='Description'
          onBlur={(v) => handleOnBlurField(v, 'description')}
          multiline
          rows={4}
          disabled={isLoadingCreateFlow}
        />
      </HDialogContent>
    </HDialog>
  );
};
