import React from 'react';
import { styled } from '@mui/material/styles';


const Loader = React.forwardRef((
  { size = 'default', ...props },
  ref,
) => (
  <LoaderContainerStyled ref={ref}>
    {size === 'small' ? (
      <svg
        className='Loader_small'
        width='16px'
        height='16px'
        viewBox='0 0 200 200'
        {...props}
      >
        <g className='ellipseBox small'>
          <circle cx='100' cy='100' r='90' className='baseLine' />
          <circle cx='100' cy='100' r='90' className='ellipse small' />
        </g>
      </svg>
    ) : (
      <svg
        className='Loader_default'
        width='160px'
        height='160px'
        viewBox='0 0 200 200'
        {...props}
      >
        <g className='ellipseBox'>
          <circle cx='100' cy='100' r='98' className='ellipse' />
        </g>
        <polygon
          className='vectorStroke'
          stroke='#000000'
          strokeWidth='2'
          fill='transparent'
          points='
          30.17 140.73
          75.17 50.73
          95.17 50.73
          50.17 140.73
          '
        />
        <polygon
          className='vector'
          fill='#000000'
          points='
          30.17 139.73
          74.17 51.73
          94.17 51.73
          50.17 139.73
          '
        />
        <polygon
          className='vectorStroke'
          stroke='#000000'
          strokeWidth='2'
          fill='transparent'
          points='
          120.17 125.73
          120.17 50.73
          100.17 50.73
          100.17 139.73
          165.17 139.73
          165.17 125.73
          '
        />
        <polygon
          className='vector'
          fill='#000000'
          points='
          120.17 125.73
          120.17 50.73
          100.17 50.73
          100.17 139.73
          165.17 139.73
          165.17 125.73
          '
        />
      </svg>
    )}
  </LoaderContainerStyled>
));

const LoaderContainerStyled = styled('div', {
  name: 'Loader',
  slot: 'root',
})(({ theme }) => ({
  '@keyframes route': {
    '0%': {
      opacity: 0,
    },
    '25%': {
      opacity: 0,
    },
    '50%': {
      opacity: 1,
    },
    '75%': {
      opacity: 0,
    },
    '100%': {
      opacity: 0,
    },
  },
  '@keyframes reveal': {
    '0%': {
      strokeDashoffset: 600,
    },
    '50%': {
      strokeDashoffset: 0,
    },
    '100%': {
      trokeDashoffset: 600,
    },
  },
  '@keyframes revolve': {
    '0%': {
      strokeDashoffset: '-628.3',
    },
    '100%': {
      strokeDashoffset: '628.3',
    },
  },
  '@keyframes reverseRevolve': {
    '0%': {
      strokeDashoffset: '628.3',
    },
    '100%': {
      strokeDashoffset: '-628.3',
    },
  },
  '@keyframes rolling': {
    '0%': {
      transform: 'rotate(-90deg)',
    },
    '25%': {
      transform: 'rotate(0deg)',
    },
    '50%': {
      transform: 'rotate(90deg)',
    },
    '75%': {
      transform: 'rotate(180deg)',
    },
    '100%': {
      transform: 'rotate(270deg)',
    },
  },
  '& > svg > g': {
    '&.ellipseBox': {
      fill: 'none',
      transform: 'rotate(-90deg)',
      transformOrigin: 'center',
      '&.small': {
        animation: 'rolling 2s linear infinite',
      },
    },
  },
  '& > svg circle': {
    '&.ellipse': {
      transformOrigin: 'center',
      fill: 'transparent',
      stroke: '#000000',
      strokeWidth: 2,
      strokeDasharray: '628.3',
      animation: 'revolve 2s linear infinite',
    },
    '&.small': {
      strokeWidth: '10%',
      stroke: `${theme.palette.primary.main}`,
      animation: 'reverseRevolve 2s linear infinite',
    },
    '&.baseLine': {
      strokeWidth: '10%',
      stroke: `${theme.palette.primary.light}`,
    },
  },
  '& > svg > polygon': {
    '&.vector': {
      animation: 'route 4.5s infinite',
    },
    '&.vectorStroke': {
      strokeDasharray: 600,
      strokeDashoffset: 600,
      animation: 'reveal 4.5s infinite',
    },
  },
}));

export default Loader;
