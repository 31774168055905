import { activeOrgSelector } from 'redux/reducer/AuthenticationReducer';
import { axiosInstance } from 'utils/http';
import { flow } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';


export const useFetchFlowMacros = ({ crFlowId, crId }) => {
  const dispatch = useDispatch();
  const { ID: orgId } = useSelector(activeOrgSelector);

  const {
    isLoading, isError, isSuccess, data, refetch, isRefetching,
  } = useQuery(
    ['Flow-Macros', orgId, crId, crFlowId],
    async () => {
      const response = await axiosInstance.get(flow(orgId).macros(crId, crFlowId));
      return response.data;
    },
    {
      enabled: !!crFlowId, // Enable the query only if crFlowId is truthy
      onSuccess: () => dispatch(showAlert({ message: 'Flow Parameters fetched successfully.', type: 'success' })),
      onError: (error) => dispatch(showAlert({ message: error.response?.data?.message || 'Error fetching flow parameters', type: 'error' })),
    },
  );
  return {
    isLoading, isError, isSuccess, data, refetch, isRefetching,
  };
};
