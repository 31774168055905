import FlowDatasetsManagement from 'components/Flow/FlowDatasetsManagement';
import React, { memo } from 'react';


export const FlowDatasetsManagementCell = memo(({ row }) => (
  <FlowDatasetsManagement flowDetails={row} />
));

FlowDatasetsManagementCell.displayName = 'FlowDatasetsManagementCell';


