import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { DialogTitle } from '@mui/material';
import { Info } from '@mui/icons-material';
import { ourColors } from 'Theme';
import { styled } from '@mui/material/styles';


const StyledInfo = styled(Info)({
  color: ourColors.primaryColor,
  display: 'inline-flex',
  verticalAlign: 'middle',
  paddingBottom: '0.5rem',
  fontSize: 35,
});

const StyledTypography = styled(Divider)({
  marginBottom: '0px',
  fontWeight: '400',
});

const StyledDivider = styled(Divider)(({ theme }) => ({
  height: '2px',
  backgroundColor: theme.palette.primary.main,
}));

// this is a common modal to show run details/ error details
const DetailModal = ({
  modalHeading, modalText, onOpen, onClose, children,
}) => {
  const LoadingSkeleton = () => (<Skeleton height={150} />);

  return (
    <Dialog
      open={onOpen}
      onClose={onClose}
      fullWidth
      maxWidth='md'
    >
      <DialogTitle>
        <StyledInfo />
        &nbsp;
        <StyledTypography component='span' variant='h2'>{modalHeading}</StyledTypography>
        <StyledDivider />
      </DialogTitle>
      <DialogContent>
        {modalText ? (
          <>
            {modalText.map(x => (
              <Typography key={x.displayLabel} paragraph variant='subtitle1'>
                <b>
                  {x.displayLabel}
                  &nbsp;:&nbsp;
                </b>
                {x.message}
              </Typography>
            ))}
          </>
        ) : children || <LoadingSkeleton />}
      </DialogContent>
    </Dialog>
  );
};

export default DetailModal;
