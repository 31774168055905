import Box from '@mui/material/Box';
import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import {
  ORGANIZATION_CUSTOMIZATION_PARAMETER_OPTIONS as parameterOptions,
} from 'pages/OrganizationManagement/constants';
import { styled } from '@mui/material/styles';


const paletteBlockStyle = {
  borderRadius: 3,
  height: 40,
  width: 40,
  border: '1px solid rgba(0, 0, 0, 0.23)',
};

const getParameterMapStyles = ({ rowName, styleProps }) => {
  if (rowName === 'NAV_BAR_COLOR') {
    return {
      ...paletteBlockStyle,
      backgroundColor: styleProps.NAV_BAR_COLOR,
    };
  }
  if (rowName === 'NAV_BAR_BG_COLOR') {
    return {
      ...paletteBlockStyle,
      backgroundColor: styleProps.NAV_BAR_BG_COLOR,
    };
  }
  if (rowName === 'NAV_BAR_BG_COLOR_HOVER') {
    return {
      ...paletteBlockStyle,
      backgroundColor: styleProps.NAV_BAR_BG_COLOR_HOVER,
    };
  }
  if (rowName === 'PRE_SIGNED_LOGO_URL') {
    return {
      borderRadius: 3,
      padding: '8px 20px',
      maxHeight: '60px',
      maxWidth: '100px',
      backgroundColor: styleProps.NAV_BAR_BG_COLOR,
    };
  }
  return {};
};
const excludeStyleProps = (prop) => !['rowName', 'styleProps'].includes(prop);

// Style rule names must hard coded and match the value in
// constant ORGANIZATION_CUSTOMIZATION_PARAMETER_NAME_MAP
const StyledBox = styled(Box, {
  shouldForwardProp: excludeStyleProps,
})((props) => getParameterMapStyles(props));

const StyledImg = styled('img', {
  shouldForwardProp: excludeStyleProps,
})((props) => getParameterMapStyles(props));

const OrganizationCustomizationTable = ({ list }) => {
  const styleProps = list.reduce((acc, item) => {
    if (item.name !== parameterOptions.LOGO_URL.key || item.name !== parameterOptions.FONT.key) {
      acc[item.name] = item.value;
    }
    return acc;
  }, {});


  const getRow = (row) => {
    if (row.name === parameterOptions.LOGO_URL.key) {
      return null;
    }
    let valueCells = (
      <>
        <TableCell>
          <StyledBox rowName={row.name} styleProps={styleProps} />
          <Typography variant='h6'>{row.value}</Typography>
        </TableCell>
      </>
    );
    if (row.name === parameterOptions.PRE_SIGNED_LOGO_URL.key) {
      valueCells = (
        <TableCell>
          <StyledImg
            rowName={row.name}
            styleProps={styleProps}
            src={row.value}
            alt={parameterOptions[row.name].name}
            crossOrigin=''
          />
        </TableCell>
      );
    }
    return (
      <TableRow key={`customization_${row.name}`}>
        <TableCell>{parameterOptions[row.name].name}</TableCell>
        {valueCells}
      </TableRow>
    );
  };

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Value</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {list.map((row) => (
          getRow(row)
        ))}
      </TableBody>
    </Table>
  );
};

export default OrganizationCustomizationTable;
