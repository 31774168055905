import Bouncer from 'components/Common/Bouncer';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import React, { memo } from 'react';
import { HButton } from 'BaseComponents';
import { Link } from 'react-router-dom';


export const HCreateButton = memo(({
  createPermissions,
  createButton,
  isVisible = true,
}) => (
  <>
    {createButton && isVisible && (
    <Bouncer allowList={createPermissions}>
      <HButton
        data-testid='create-button'
        component={createButton.link ? Link : undefined}
        size='small'
        to={createButton.link ? createButton.link : '#'}
        onClick={createButton.onClick ? createButton.onClick : null}
        variant='contained'
        disabled={createButton.disabled}
      >
        <ControlPointIcon fontSize='small' style={{ marginRight: '5px' }} />
        {createButton.text}
      </HButton>
    </Bouncer>
    )}
  </>
));

HCreateButton.displayName = 'HCreateButton';
