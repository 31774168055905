import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import React, { useState } from 'react';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import {
  ContentCopy, Info, Visibility, VisibilityOff,
} from '@mui/icons-material';
import { CopyToClipboard } from 'react-copy-to-clipboard/lib/Component';
import {
  DialogTitle, IconButton, Paper, TextField,
} from '@mui/material';
import {
  fetchCredentialSourcesList,
  fetchOrgCredential,
} from 'redux/actions/DataCredentialManagementAction';
import { ourColors } from 'Theme';
import { showAlert } from 'redux/actions/AlertActions';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';


const StyledInfo = styled(Info)({
  color: ourColors.primaryColor,
  display: 'inline-flex',
  verticalAlign: 'middle',
  paddingBottom: '0.5rem',
  fontSize: 35,
});

const StyledTypography = styled(Typography)({
  marginBottom: '0px',
  fontWeight: '400',
});

const StyledDivider = styled(Divider)(({ theme }) => ({
  height: '2px',
  backgroundColor: theme.palette.primary.main,
}));

const BoldTableCell = ({ cellText }) => (<TableCell><Typography variant='subtitle1'>{cellText}</Typography></TableCell>);

const MaskTextField = ({ value }) => {
  const [showText, setShowText] = useState(false);
  const maskedText = value ? value.replace(/./g, '*') : '';
  return (
    <TextField
      value={showText ? value : maskedText}
      type={showText ? 'text' : 'password'}
      variant='outlined'
      fullWidth
      disabled
      multiline
      InputProps={{
        endAdornment: (
          <Tooltip title={showText ? 'Hide' : 'Show'}>
            <IconButton onClick={() => setShowText(!showText)}>
              {showText ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </Tooltip>
        ),
      }}
    />
  );
};

const OrgCredentialModal = ({
  crID = '', onOpen, onClose, modalHeading,
}) => {
  const dispatch = useDispatch();
  const orgId = useSelector(state => state.session.user.primaryOrganization.organization.ID);
  const credential = useSelector(state => state.dataCredential.credential);
  const credentialSources = useSelector(state => state.dataCredential.credentialSources);
  const [credDetails, setCredDetails] = useState();

  const handleCopy = () => {
    dispatch(showAlert({ message: 'Copied', type: 'success' }));
  };

  React.useEffect(() => {
    if (crID) {
      dispatch(fetchOrgCredential(orgId, crID));
      dispatch(fetchCredentialSourcesList());
    }
  }, [dispatch, crID, orgId]);

  React.useEffect(() => {
    setCredDetails(credential);
  }, [credential]);

  React.useEffect(() => {
    if (credDetails && credentialSources) {
      const credConfig = credentialSources.find(
        x => x.ID === credDetails.credentialSource.ID,
      );
      if (credConfig?.config && credConfig) {
        const temp = credDetails;
        temp.credentials = temp.credentials.map(x => {
          const cred = credConfig.config.find(y => y.name === x.name);
          return { ...x, internal: cred.internal, type: cred.type };
        });

        setCredDetails(temp);
      }
    }
  }, [credentialSources, credDetails]);


  return (
    <Dialog
      open={onOpen}
      onClose={onClose}
      fullWidth
      maxWidth='lg'
    >
      <DialogTitle>
        <StyledInfo />
        &nbsp;
        <StyledTypography component='span' variant='h2'>{modalHeading}</StyledTypography>
        <StyledDivider />
      </DialogTitle>
      <DialogContent>
        {credDetails ? (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width='20%' />
                  <TableCell />
                  <TableCell width='5%' />
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <BoldTableCell cellText='Name' />
                  <TableCell>{credDetails.name}</TableCell>
                </TableRow>
                <TableRow>
                  <BoldTableCell cellText='Credential Source' />
                  <TableCell>{credDetails.credentialSource.name}</TableCell>
                </TableRow>
                <TableRow>
                  <BoldTableCell cellText='Provider' />
                  <TableCell>{credDetails.credentialSource.provider}</TableCell>
                </TableRow>
                {credDetails.credentials.map(x => (
                  <TableRow key={x.ID}>
                    <BoldTableCell cellText={x.name} />
                    <TableCell>
                      {x.type === 'password' || x.type === 'secret'
                        ? (<MaskTextField value={x.value} />)
                        : (x.value)}
                    </TableCell>
                    <TableCell>
                      <Tooltip title='Copy'>
                        <IconButton onClick={handleCopy}>
                          <CopyToClipboard text={x.value}>
                            <ContentCopy />
                          </CopyToClipboard>
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : <Skeleton height={150} />}
      </DialogContent>

    </Dialog>
  );
};

export default OrgCredentialModal;
