import React, {
  createContext, useCallback, useContext, useEffect, useState,
} from 'react';
import useSubmitFlowMacroMapping from 'api/flows/useSubmitFlowMacroMapping';
import { END_DATE_PARAMETER, START_DATE_PARAMETER } from 'components/InternalAdmin/QuestionConfigure/QuestionParameters';
import { FLOW_MACRO_TAB_VALUE } from 'pages/Flow/FlowMacro/FlowMacroLayout';
import { FlowBuilderContext } from 'pages/Flow/FlowBuilder/FlowBuilderContextProvider';
import { closeSidePanel } from 'redux/actions/FlowAction';
import { useDispatch } from 'react-redux';
import { useFetchFlowMacros } from 'api/flows/useFetchFlowMacros';
import { useGetCRFlowRunParametersAndMappings } from 'api/flows/useGetCRFlowRunParametersAndMappings';


export const FlowMacroContext = createContext({});

export const FlowMacroContextProvider = ({ children }) => {
  const { crId, crFlowId } = useContext(FlowBuilderContext);
  const dispatch = useDispatch();
  const [tabValue, updateTab] = useState(FLOW_MACRO_TAB_VALUE.MACRO_ASSIGNMENT);
  const [flowMacros, setFlowMacros] = useState([]);
  const [flowRunParameters, setFlowRunParameters] = useState([]);
  const [flowMacroMapping, setFlowMacroMapping] = useState([]);

  const {
    isLoading: isFlowMacrosLoading,
    isError: isFlowMacrosError,
    data: fetchedFlowMacros,
    isSuccess: isFlowMacrosSuccess,
    isRefetching,
  } = useFetchFlowMacros({ crFlowId, crId });

  const {
    isError: isFlowRunParamMappingError,
    isSuccess: isFlowRunParamMappingSuccess,
    isLoading: isFlowRunParamMappingLoading,
    data,
  } = useGetCRFlowRunParametersAndMappings({ crFlowId, crId });

  const {
    isLoading: isSubmitFlowMacroMappingLoading,
    isSuccess: isSubmitFlowMacroMappingSuccess,
    mutate: submitFlowMacroMappingMutate,
  } = useSubmitFlowMacroMapping({ crFlowId, crId });

  // Event Handlers

  const handleOnClose = () => {
    dispatch(closeSidePanel());
    setFlowMacros([]);
    setFlowRunParameters([]);
    setFlowMacroMapping([]);
  };

  const handleBack = () => {
    updateTab(FLOW_MACRO_TAB_VALUE.MACRO_ASSIGNMENT);
  };

  const updateNodeFlowMacro = useCallback((nodeID, runParameter, flowMacro) => {
    const nodeIndex = flowRunParameters.findIndex((n) => n.nodeID === nodeID);
    if (nodeIndex !== -1) {
      const updatedNode = flowRunParameters[nodeIndex];
      const parameterIndex = updatedNode.nodeRunParameters.findIndex(
        (param) => param.name === runParameter.name,
      );

      if (parameterIndex !== -1) {
        updatedNode.nodeRunParameters[parameterIndex].macro = flowMacro;
        const updatedFlowRunParameters = [
          ...flowRunParameters.slice(0, nodeIndex),
          updatedNode,
          ...flowRunParameters.slice(nodeIndex + 1),
        ];
        setFlowRunParameters(updatedFlowRunParameters);
      }
    }
  }, [flowRunParameters]);



  const handleMacroMapping = useCallback(({
    macro, prevMacro, runParameter, nodeID,
  }) => {
    // Check if the parameter is a date (START_DATE or END_DATE)
    const isDateParameter = [START_DATE_PARAMETER, END_DATE_PARAMETER].some(
      (param) => runParameter.index === param.index && runParameter.name === param.name,
    );

    // Create a unique identifier for the run parameter
    const uniqueRunParameterID = isDateParameter
      ? `${runParameter.name}`
      : `${runParameter.ID}`;



    // Create an object for the runtime parameter
    const runtimeParameterObject = {
      nodeID,
      runParameterID: uniqueRunParameterID,
    };

    setFlowMacroMapping((prevMappings) => {
      // Determine the name to use for the index search
      const nameToFind = macro ? macro?.name : prevMacro.name;

      // Find the index of the mapping with the matching name
      const index = prevMappings.findIndex(
        (mapping) => mapping.macro.name === nameToFind,
      );

      if (!macro) {
        // If flowMacro is null, remove the runtimeParameterObject from the mapping
        if (index !== -1) {
          const updatedMapping = {
            ...prevMappings[index],
            runtimeParams: prevMappings[index]
              .runtimeParams.filter(
                (param) => !(param.nodeID === runtimeParameterObject.nodeID
                  && param.runParameterID === runtimeParameterObject.runParameterID),
              ),
          };

          // If no runTimeParameters are left, remove the mapping entirely
          if (updatedMapping.runtimeParams.length === 0) {
            return [
              ...prevMappings.slice(0, index),
              ...prevMappings.slice(index + 1),
            ];
          }

          // Otherwise, update the mapping
          return [
            ...prevMappings.slice(0, index),
            updatedMapping,
            ...prevMappings.slice(index + 1),
          ];
        }
        return prevMappings;
      }

      // If flowMacro is not null, either update the existing mapping or add a new one
      if (index !== -1) {
        // Update the existing mapping
        const updatedMapping = {
          ...prevMappings[index],
          runtimeParams: [
            ...prevMappings[index].runtimeParams,
            runtimeParameterObject,
          ],
        };

        return [
          ...prevMappings.slice(0, index),
          updatedMapping,
          ...prevMappings.slice(index + 1),
        ];
      }

      // If no matching flowMacro found, add a new mapping
      return [
        ...prevMappings,
        {
          macro,
          runtimeParams: [runtimeParameterObject],
        },
      ];
    });

    // Always update the node's flow macro regardless of the operation
    updateNodeFlowMacro(nodeID, runParameter, macro);
  }, [updateNodeFlowMacro]);


  // TODO - Create filter function to add macro in alpha order
  const onCreateFlowMacro = ({ newMacro, runParameter, nodeID }) => {
    // Check if the parameter is a date (START_DATE or END_DATE)
    const isDateParameter = [START_DATE_PARAMETER, END_DATE_PARAMETER].some(
      (param) => runParameter.index === param.index && runParameter.name === param.name,
    );

    // Create a unique identifier for the run parameter
    const uniqueRunParameterID = isDateParameter
      ? `${runParameter.ID}${runParameter.name}`
      : `${runParameter.ID}`;

    // Create a new flow macro object
    const newFlowMacro = {
      ID: '',
      name: newMacro.inputValue,
      description: '',
      type: runParameter.type,
    };

    // Update the state with the new flow macro and its mapping
    // Ensure flowMacros is an array before spreading
    setFlowMacros((prevFlowMacros) => [newFlowMacro, ...(Array.isArray(prevFlowMacros)
      ? prevFlowMacros : [])]);

    setFlowMacroMapping((prev) => [
      {
        macro: { ...newFlowMacro },
        runtimeParams: [{
          nodeID,
          runParameterID: uniqueRunParameterID,
        }],
      },
      ...prev,
    ]);

    // Update the node with the new flow macro
    updateNodeFlowMacro(nodeID, runParameter, newFlowMacro);
  };

  // Effects
  useEffect(() => {
    if (isFlowRunParamMappingSuccess) {
      setFlowRunParameters(data?.data?.flowRunParameters);
    }
  }, [data, isFlowRunParamMappingSuccess]);


  useEffect(() => {
    if (isFlowMacrosSuccess) {
      setFlowMacros(fetchedFlowMacros?.flowMacros);
    }
  }, [fetchedFlowMacros?.flowParameters, isFlowMacrosSuccess, fetchedFlowMacros]);

  useEffect(() => {
    if (isFlowRunParamMappingSuccess && isFlowMacrosSuccess) {
      const updatedFlowMacroMappings = [];
      flowRunParameters.forEach((node) => {
        node.nodeRunParameters.forEach((runParameter) => {
          if (runParameter.macro) {
            const macroExist = updatedFlowMacroMappings.find(
              (m) => m.macro.name === runParameter.macro.name,
            );

            const isDateParameter = [START_DATE_PARAMETER, END_DATE_PARAMETER].some(
              (param) => runParameter.index === param.index && runParameter.name === param.name,
            );

            // Create a unique identifier for the run parameter
            const uniqueRunParameterID = isDateParameter
              ? `${runParameter.ID}${runParameter.name}`
              : `${runParameter.ID}`;

            if (macroExist) {
              const index = updatedFlowMacroMappings.findIndex(
                (m) => m.macro.name === runParameter.macro.name,
              );
              updatedFlowMacroMappings[index].runtimeParams.push({
                nodeID: node.nodeID,
                runParameterID: uniqueRunParameterID,
              });
            }
            else {
              updatedFlowMacroMappings.push({
                macro: runParameter.macro,
                runtimeParams: [
                  {
                    nodeID: node.nodeID,
                    runParameterID: uniqueRunParameterID,
                  },
                ],
              });
            }
          }
        });
      });
      setFlowMacroMapping(updatedFlowMacroMappings);
    }
  }, [flowRunParameters, isFlowMacrosSuccess, isFlowRunParamMappingSuccess]);



  useEffect(() => {
    if (isSubmitFlowMacroMappingSuccess) {
      dispatch(closeSidePanel());
      setFlowMacros([]);
      setFlowRunParameters([]);
      setFlowMacroMapping([]);
    }
  }, [dispatch, isSubmitFlowMacroMappingSuccess]);


  const FlowMacroContextValue = {
    disableAutoComplete: isFlowMacrosError || isFlowMacrosLoading
       || isRefetching,
    flowMacroMapping,
    flowMacros,
    flowRunParameters,
    handleBack,
    handleMacroMapping,
    handleOnClose,
    isFlowMacrosError,
    isFlowMacrosLoading,
    isFlowMacrosSuccess,
    isFlowRunParamMappingError,
    isFlowRunParamMappingLoading,
    isSubmitFlowMacroMappingLoading,
    onCreateFlowMacro,
    setFlowMacroMapping,
    setFlowMacros,
    setFlowRunParameters,
    submitFlowMacroMappingMutate,
    tabValue,
    updateNodeFlowMacro,
    updateTab,
  };

  return (
    <FlowMacroContext.Provider value={FlowMacroContextValue}>
      {children}
    </FlowMacroContext.Provider>
  );
};
