import * as actionCreators from 'redux/actions/LookupActions';
import http from 'utils/http';
import { LOOKUPS } from 'redux/actions/Types';
import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';


function* fetchCategories(action) {
  const data = yield call(http.get, action.url);
  yield put(actionCreators.receiveContentVertical(data.contentVerticals));
}

function* fetchContentTypes(action) {
  const data = yield call(http.get, action.url);
  yield put(actionCreators.receiveContentCategories(data.contentCategories));
}

function* fetchDomains(action) {
  const { properties } = yield call(http.get, action.url);
  const domainNames = properties ? properties.map(property => property.name) : [];
  yield put(actionCreators.receiveDomains(domainNames));
}

function* fetchPermissions(action) {
  const { permissions } = yield call(http.get, action.url);
  yield put(actionCreators.receivePermissions(permissions));
}

function* fetchQuestionTypes(action) {
  const data = yield call(http.get, action.url);
  yield put(actionCreators.receiveQuestionTypes(data.questionTypes));
}

function* fetchPropertyTypes(action) {
  const { propertyTypes, error } = yield call(http.get, action.url);
  if (error) {
    return yield put(actionCreators.fetchPropertyTypesFailure(error));
  }
  return yield put(actionCreators.fetchPropertyTypesSuccess(propertyTypes));
}

export default function* root() {
  yield all([
    takeLatest(LOOKUPS.FETCH_CONTENT_VERTICALS, fetchCategories),
    takeLatest(LOOKUPS.FETCH_CONTENT_CATEGORIES, fetchContentTypes),
    takeLatest(LOOKUPS.FETCH_DOMAINS, fetchDomains),
    takeLatest(LOOKUPS.FETCH_PERMISSIONS, fetchPermissions),
    takeLatest(LOOKUPS.FETCH_QUESTION_TYPES, fetchQuestionTypes),
    takeLatest(LOOKUPS.FETCH_PROPERTY_TYPES, fetchPropertyTypes),
  ]);
}

export {
  fetchCategories,
  fetchContentTypes,
  fetchDomains,
  fetchPermissions,
  fetchQuestionTypes,
  fetchPropertyTypes,
};
