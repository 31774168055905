import * as authActionCreators from 'redux/actions/AuthenticationActions';
import Logger from 'components/Common/Logger';
import PermissionService from 'components/Common/PermissionService';
import http from 'utils/http';
import localStorage from 'utils/localStorage';
import { CACHE } from 'utils/appConstants';
import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import { auth } from 'utils/spaUrls';
import { AUTHENTICATION as authActions } from 'redux/actions/Types';
import { showAlert } from 'redux/actions/AlertActions';


const updatePrimaryAccount = (account, reset = true) => {
  const accId = reset ? account.ID : (localStorage.get(CACHE.user.primaryAccount) || account.ID);
  localStorage.set(CACHE.user.primaryAccount, accId);
  localStorage.set(CACHE.user.isDemoAccount, account.isDemoAccount);
  return accId;
};

const updatePrimaryOrg = (organization, userPermissions, reset = true) => {
  const orgId = reset
    ? organization.ID : (localStorage.get(CACHE.user.primaryOrg) || organization.ID);
  const { userType } = JSON.parse(localStorage.get(CACHE.user.userDetails));
  localStorage.set(CACHE.user.primaryOrg, orgId);
  PermissionService.init([...userPermissions, userType]);
  return orgId;
};

const updateTokens = (data) => {
  localStorage.set(CACHE.user.jwtAccessToken, data.authToken);
  localStorage.set(CACHE.user.jwtRefreshToken, data.refreshToken);
};

const warmLocalStorage = (data) => {
  /* This should go in Localstorage irrespective of the cache used */
  updateTokens(data);

  localStorage.set(CACHE.user.userDetails, JSON.stringify(data.user));
  localStorage.set(CACHE.user.id, JSON.stringify(data.user.ID));
  localStorage.set(CACHE.user.primaryAccount, data.primaryAccount.ID);
  localStorage.set(CACHE.user.primaryOrg, data.primaryOrganization.organization.ID);
  localStorage.set(CACHE.user.isDemoAccount, data.primaryAccount.isDemoAccount);
  if (window.FS) {
    window.FS.setUserVars({
      accountID_str: data.primaryAccount.ID,
      accountName_str: data.primaryAccount.name,
      displayName: data.user.name,
      email: data.user.email,
      organizationID_str: data.primaryOrganization.organization.ID,
      organizationName_str: data.primaryOrganization.organization.name,
      userID_str: data.user.ID,
      userType_str: data.user.userType,
    });
  }
};

const clearUser = () => {
  Object.keys(CACHE.user).forEach(i => localStorage.delete(i));
  Object.keys(CACHE.impersonation).forEach(i => localStorage.delete(i));
  PermissionService.clear();
};

const clearImpersonation = () => {
  localStorage.delete(CACHE.impersonation.impersonator);
  localStorage.delete(CACHE.impersonation.refreshToken);
};

function* sendLogout(action) {
  yield all([
    call(http.post, action.url),
  ]);
  clearUser();
  clearImpersonation();
}

function* sendLogin(action) {
  const response = yield call(http.post, action.url, action.payload);
  clearImpersonation();

  if (response.status === 401) {
    clearUser();
    window.location.replace(auth.logout);
  }
  else if (response.error) {
    yield put(authActionCreators.receiveLoginError(response.error));
  }
  else {
    warmLocalStorage(response);
    PermissionService.init([...response.userPermissions, response.user.userType]);
    PermissionService.initUser(response.user);
    yield put(authActionCreators.receiveLoginSuccess(response));
  }
}

function* refreshToken(action) {
  const response = yield call(http.refreshJwt, action.url, action.payload);

  if (response.status === 401) {
    sendLogout();
    window.location.replace(auth.logout);
  }
  else if (response.error) {
    // The session has expired. Let's reset the permService
    PermissionService.clear();
    yield put(authActionCreators.receiveLoginError(response.error));
  }
  else {
    updateTokens(response);
    const accountId = localStorage.get(CACHE.user.primaryAccount);
    const orgId = localStorage.get(CACHE.user.primaryOrg);
    const [primaryAccount] = response.accounts.filter(a => a.ID === accountId);
    const [primaryOrganization] = response.organizations.filter(o => o.organization.ID === orgId);
    const dataWithStickyAccountOrg = { ...response, primaryAccount, primaryOrganization };
    PermissionService.init([...response.userPermissions, response.user.userType]);
    PermissionService.initUser(response.user);
    yield put(authActionCreators.receiveLoginSuccess(dataWithStickyAccountOrg));
  }
  if (typeof action.callback === 'function') {
    yield call(action.callback, response);
  }
}

function* authUserCallBack(action) {
  const response = yield call(http.get, action.url);
  if (response.status === 401) {
    clearUser();
    window.location.replace(auth.logout);
  }
  else if (response.error) {
    yield put(authActionCreators.receiveLoginError(response.error));
  }
  else {
    warmLocalStorage(response);
    PermissionService.init([...response.userPermissions, response.user.userType]);
    PermissionService.initUser(response.user);
    yield put(authActionCreators.receiveLoginSuccess(response));
  }
}

function* updateAccountCache(action) {
  const { organizations } = yield call(http.get, action.url);
  if (organizations.length > 0) {
    const defaultPrimaryOrg = organizations[0];
    updatePrimaryAccount(action.payload);
    yield put(authActionCreators.switchOrganization(defaultPrimaryOrg));
    yield put(showAlert({
      message: `Successfully switched to: ${action.payload.name} - ${defaultPrimaryOrg.organization.name}`,
      type: 'success',
    }));
    return yield put(authActionCreators.switchAccountSuccess(action.payload));
  }
  else {
    // There should always be a HQ org
    Logger.error(`Account id ${action.payload.ID} has no Organizations`, 'Switch Accounts');
    yield put(showAlert({ message: 'Account not setup. Please contact us', type: 'error' }));
    return yield put(authActionCreators.switchAccountFailure());
  }
}

function* updateOrganizationCache(action) {
  const response = yield call(http.get, action.url);
  if (response.error) {
    yield call(updatePrimaryOrg, action.payload.organization, action.payload.permissions);
    yield put(showAlert({ message: response.error, type: 'error' }));
    return yield put(authActionCreators.switchOrganizationFailure(action.payload));
  }
  else {
    updateTokens(response);
    localStorage.set(CACHE.user.userDetails, JSON.stringify(response.user));
    localStorage.set(CACHE.user.id, JSON.stringify(response.user.ID));
    localStorage.set(CACHE.user.primaryOrg, response.organization.organization.ID);
    PermissionService.init([...response.userPermissions, response.user.userType]);
    PermissionService.initUser(response.user);
    window.location.reload();
    yield put(showAlert({ message: 'Successfully switched organization', type: 'success' }));
    return yield put(authActionCreators.switchOrganizationSuccess(response));
  }
}

function* resetPassword(action) {
  const { success, error } = yield call(http.post, action.url, action.payload);
  if (error) {
    yield put(showAlert({ message: error, type: 'error' }));
    return yield put(authActionCreators.resetPasswordFailure(error));
  }
  yield put(showAlert({ message: 'Password changed', type: 'success' }));
  return yield put(authActionCreators.resetPasswordSuccess(success));
}

function* requestForgotPasswordLink(action) {
  const {
    success,
    error,
  } = yield call(http.post, action.url, action.payload);
  if (error) {
    yield put(showAlert({ message: error, type: 'error' }));
    return yield put(authActionCreators.requestForgotPasswordLinkFailure(error));
  }
  yield put(showAlert({ message: 'Email sent', type: 'success' }));
  return yield put(authActionCreators.requestForgotPasswordLinkSuccess(success));
}

function* fetchUserAuthType(action) {
  const { authType, redirectTo, error } = yield call(http.post, action.url, action.payload);
  if (error) {
    yield put(showAlert({ message: error, type: 'error' }));
    Logger.error(error, 'Authentication: Fetching user auth type');
    return yield put(authActionCreators.getUserAuthTypeFailure(error));
  }
  return yield put(authActionCreators.getUserAuthTypeSuccess({ authType, redirectTo }));
}

export default function* root() {
  yield all([
    takeLatest(authActions.GET_USER_AUTH_TYPE, fetchUserAuthType),
    takeLatest(authActions.AUTH_CALL_BACK, authUserCallBack),
    takeLatest(authActions.SEND_LOGIN, sendLogin),
    takeLatest(authActions.REFRESH_TOKEN, refreshToken),
    takeLatest(authActions.REQUEST_LOGOUT, sendLogout),
    takeLatest(authActions.SWITCH_ACCOUNT, updateAccountCache),
    takeLatest(authActions.SWITCH_ORGANIZATION, updateOrganizationCache),
    takeLatest(authActions.CHANGE_PASSWORD, resetPassword),
    takeLatest(authActions.REQUEST_FORGOT_PASSWORD_LINK, requestForgotPasswordLink),
  ]);
}

export {
  authUserCallBack,
  fetchUserAuthType,
  refreshToken,
  resetPassword,
  sendLogin,
  sendLogout,
  updateAccountCache,
  updateOrganizationCache,
  updateTokens,
  clearUser,
  warmLocalStorage,
};
