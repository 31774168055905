import { CLEAN_ROOM_ADMIN } from 'redux/actions/Types';
import { cleanRoom } from 'utils/urlConstants';


export const fetchOrgCRQuestions = orgId => ({
  type: CLEAN_ROOM_ADMIN.FETCH_ORG_CR_QUESTIONS,
  url: cleanRoom(orgId).orgCRQuestions,
});

export const fetchOrgCRQuestionsError = payload => ({
  type: CLEAN_ROOM_ADMIN.FETCH_ORG_CR_QUESTIONS_ERROR,
  payload,
});

export const fetchOrgCRQuestionsSuccess = payload => ({
  type: CLEAN_ROOM_ADMIN.FETCH_ORG_CR_QUESTIONS_SUCCESS,
  payload,
});

export const updateOrgCRQuestions = (orgId, payload) => ({
  type: CLEAN_ROOM_ADMIN.UPDATE_ORG_CR_QUESTIONS,
  url: cleanRoom(orgId).addOrgCRQuestions,
  payload,
});

export const updateOrgCRQuestionsError = payload => ({
  type: CLEAN_ROOM_ADMIN.UPDATE_ORG_CR_QUESTIONS_ERROR,
  payload,
});

export const updateOrgCRQuestionsSuccess = payload => ({
  type: CLEAN_ROOM_ADMIN.UPDATE_ORG_CR_QUESTIONS_SUCCESS,
  payload,
});
