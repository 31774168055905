import React from 'react';
import { DeleteFlowListContextProvider } from 'pages/Flow/DeleteFlowListContextProvider';
import { FlowContextProvider } from 'pages/Flow/FlowContextProvider';
import { FlowListViewLayout } from 'pages/Flow/FlowListViewLayout';


const FlowListView = () => (
  <FlowContextProvider>
    <DeleteFlowListContextProvider>
      <FlowListViewLayout />
    </DeleteFlowListContextProvider>
  </FlowContextProvider>
);

export default FlowListView;
