import { mutateSingleNode } from 'redux/reducer/FlowReducer/utils';


export const updateCreateNodeSuccess = (payload, oldNodes) => {
  const {
    node,
    oldNodeId,
  } = payload;

  return oldNodes.map(n => {
    if (n.id === oldNodeId) {
      return mutateSingleNode(node);
    }
    return n;
  });
};
