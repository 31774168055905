import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import NodeIDHandler from 'pages/Flow/NodeIDHandler';
import QuestionSidePanelDetails from 'pages/Flow/FlowSidePanel/QuestionSidePanelDetails';
import QuestionSidePanelPrivacy from 'pages/Flow/FlowSidePanel/QuestionSidePanelPrivacy';
import QuickreplyOutlinedIcon from '@mui/icons-material/QuickreplyOutlined';
import React from 'react';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { habuColors } from 'Theme';


const QuestionSidePanelLayout = ({ onClose, drawerInfo }) => {
  const { data: node } = drawerInfo;
  const [tabValue, updateTab] = React.useState('question-details');
  const question = node?.data;

  if (!node.data) {
    return null;
  }


  return (
    <>
      <Toolbar>
        <Grid container spacing={0} alignItems='center'>
          <Grid xs={1} item>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <QuickreplyOutlinedIcon height={50} width={50} />
            </Box>
          </Grid>
          <Grid xs={10} item>
            <Typography variant='h3' noWrap>
              Question Configuration
            </Typography>
          </Grid>
          <Grid xs={1} item>
            <IconButton aria-label='close' onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
      <Grid container spacing={0} sx={{ paddingX: 3 }}>
        <Grid xs={12} item>
          <NodeIDHandler nodeID={node.id} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h4'>{question.title}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='subtitle3' sx={{ color: habuColors.neutral[60] }}>
            {question.displayID}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <Tabs
          indicatorColor='primary'
          onChange={(e, newValue) => updateTab(newValue)}
          value={tabValue}
          sx={{ paddingLeft: 3 }}
        >
          <Tab label='Details' value='question-details' data-testid='question-details-tab' />
          <Tab label='Settings' value='question-settings' data-testid='question-privacy-tab' />
        </Tabs>
      </Grid>
      <Grid container spacing={0} sx={{ paddingX: 3 }}>
        {tabValue === 'question-details' && (<QuestionSidePanelDetails question={question} />)}
        {tabValue === 'question-settings' && (<QuestionSidePanelPrivacy nodeId={node.id} />)}
      </Grid>
    </>
  );
};

export default QuestionSidePanelLayout;
