import * as dataCredentialActions from 'redux/actions/DataCredentialManagementAction';
import Logger from 'components/Common/Logger';
import http from 'utils/http';
import localStorage from 'utils/localStorage';
import { CACHE, habuSupport } from 'utils/appConstants';
import { DATA_CREDENTIAL } from 'redux/actions/Types';
import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import { showAlert } from 'redux/actions/AlertActions';


function* createDataCredentialKeys(action) {
  const { credential, error } = yield call(http.post, action.url);
  if (error) {
    Logger.error({
      comment: `Could not generate keys for  ${localStorage.get(CACHE.user.primaryOrg)}`,
      error,
      url: action.url,
      userId: localStorage.get(CACHE.user.id),
    }, 'create data credential keys');
    yield put(dataCredentialActions.generateDataCredentialKeysError(
      `Could not generate credentials. Please contact us ${habuSupport.email}`,
    ));
  }
  else {
    yield put(dataCredentialActions.generateDataCredentialKeysSuccess(credential));
  }
}

function* fetchOrgDataCredentialList(action) {
  const { credentials, error } = yield call(http.get, action.url);
  if (error) {
    Logger.error(`Could not fetch organization credential list . Url: ${action.url}. Error: ${error}`,
      'Organization Credential: Fetch organization credential list');
    return yield put(dataCredentialActions.receiveOrgDataCredentialListError(error));
  }
  else {
    return yield put(dataCredentialActions.receiveOrgDataCredentialListSuccess(credentials));
  }
}

function* fetchOrgCredential(action) {
  const { credential, error } = yield call(http.get, action.url);
  if (error) {
    yield put(showAlert({ message: error, type: 'error' }));
    yield put(dataCredentialActions.receiveOrgCredentialError(error));
  }
  else {
    yield put(dataCredentialActions.receiveOrgCredentialSuccess(credential));
  }
}

function* fetchCredentialSourcesList(action) {
  const { sources, error } = yield call(http.get, action.url);
  if (error) {
    yield put(showAlert({ message: error, type: 'error' }));
    yield put(dataCredentialActions.receiveCredentialSourcesListError(error));
  }
  else {
    yield put(dataCredentialActions.receiveCredentialSourcesListSuccess(sources));
  }
}

function* fetchCredentialSource(action) {
  const { source, error } = yield call(http.get, action.url);
  if (error) {
    yield put(showAlert({ message: error, type: 'error' }));
    yield put(dataCredentialActions.receiveCredentialSourceError(error));
  }
  else {
    yield put(showAlert({ message: 'Credential source fetched success', type: 'success' }));
    yield put(dataCredentialActions.receiveCredentialSourceSuccess(source));
  }
}

function* deleteCredentialSource(action) {
  const { success, error } = yield call(http.delete, action.url);
  if (error) {
    yield put(showAlert({ message: error, type: 'error' }));
    yield put(dataCredentialActions.deleteCredentialSourceError(error));
  }
  else {
    yield put(showAlert({ message: 'Credential source deleted', type: 'success' }));
    yield put(dataCredentialActions.deleteCredentialSourceSuccess(success));
  }
}

function* submitOrgCredentialSource(action) {
  const { credential, error } = yield call(http.post, action.url, action.payload);
  if (error) {
    Logger.error(error, 'Could not create credential source');
    yield put(showAlert({ message: error, type: 'error' }));
    yield put(dataCredentialActions.submitOrgCredentialSourceError(error));
  }
  else {
    yield put(showAlert({ message: 'Organization credential source submitted successful', type: 'success' }));
    yield put(dataCredentialActions.submitOrgCredentialSourceSuccess(credential));
  }
}

function* updateOrgCredentialSource(action) {
  const { credential, error } = yield call(http.put, action.url, action.payload);
  if (error) {
    yield put(showAlert({ message: error, type: 'error' }));
    yield put(dataCredentialActions.submitOrgCredentialSourceError(error));
  }
  else {
    yield put(showAlert({ message: 'Organization credential source submitted successful', type: 'success' }));
    yield put(dataCredentialActions.submitOrgCredentialSourceSuccess(credential));
  }
}

function* deleteOrgCredentialSource(action) {
  const { success, error } = yield call(http.delete, action.url, action.payload);
  if (error) {
    yield put(showAlert({ message: error, type: 'error' }));
    yield put(dataCredentialActions.deleteOrgCredentialSourceError(error));
  }
  else {
    yield put(showAlert({ message: 'Organization credential successfully deleted', type: 'success' }));
    yield put(dataCredentialActions.deleteOrgCredentialSourceSuccess(success));
  }
}

function* submitCredentialSource(action) {
  const { success, error } = yield call(http.post, action.url, action.payload);
  if (error) {
    yield put(showAlert({ message: error, type: 'error' }));
    yield put(dataCredentialActions.submitCredentialSourceError(error));
  }
  else {
    yield put(showAlert({ message: 'Credential source created', type: 'success' }));
    yield put(dataCredentialActions.submitCredentialSourceSuccess(success));
  }
}
// todo this route needs to be updated on primage.
//  Response is incorrect when returning configurations
function* updateCredentialSource(action) {
  const { success, error } = yield call(http.put, action.url, action.payload);
  if (error) {
    yield put(showAlert({ message: error, type: 'error' }));
    yield put(dataCredentialActions.submitCredentialSourceError(error));
  }
  else {
    yield put(showAlert({ message: 'Credential source updated', type: 'success' }));
    yield put(dataCredentialActions.submitCredentialSourceSuccess(success));
  }
}


export default function* root() {
  yield all([
    takeLatest(DATA_CREDENTIAL.FETCH_ORG_DATA_CREDENTIAL_LIST, fetchOrgDataCredentialList),
    takeLatest(DATA_CREDENTIAL.FETCH_ORG_CREDENTIAL, fetchOrgCredential),
    takeLatest(DATA_CREDENTIAL.FETCH_CREDENTIAL_SOURCES_LIST, fetchCredentialSourcesList),
    takeLatest(DATA_CREDENTIAL.FETCH_CREDENTIAL_SOURCE, fetchCredentialSource),
    takeLatest(DATA_CREDENTIAL.CREATE_CREDENTIAL_SOURCE, submitCredentialSource),
    takeLatest(DATA_CREDENTIAL.CREATE_ORG_CREDENTIAL_SOURCE, submitOrgCredentialSource),
    takeLatest(DATA_CREDENTIAL.UPDATE_ORG_CREDENTIAL_SOURCE, updateOrgCredentialSource),
    takeLatest(DATA_CREDENTIAL.UPDATE_CREDENTIAL_SOURCE, updateCredentialSource),
    takeLatest(DATA_CREDENTIAL.DELETE_CREDENTIAL_SOURCE, deleteCredentialSource),
    takeLatest(DATA_CREDENTIAL.DELETE_ORG_CREDENTIAL_SOURCE, deleteOrgCredentialSource),
    takeLatest(DATA_CREDENTIAL.CREATE_DATA_CREDENTIAL_KEYS, createDataCredentialKeys),
  ]);
}

export {
  createDataCredentialKeys,
  deleteCredentialSource,
  deleteOrgCredentialSource,
  fetchCredentialSource,
  fetchCredentialSourcesList,
  fetchOrgCredential,
  fetchOrgDataCredentialList,
  submitCredentialSource,
  updateCredentialSource,
  updateOrgCredentialSource,
  submitOrgCredentialSource,
};
