import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import React from 'react';
import TextField from '@mui/material/TextField';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { generateWarning } from 'utils/passwordServices';


export const PasswordFields = ({
  password,
  confirmPassword,
  updatePassword,
  updateConfirmPassword,
}) => {
  const [passwordMatch, updatePasswordMatch] = React.useState(true);
  const [showPassword, toggleShowPassword] = React.useState(false);
  const warning = generateWarning(password);

  return (
    <>
      <Grid item md={10} sm={12}>
        <TextField
          autoComplete='new-password'
          fullWidth
          error={!!warning && password.length > 0}
          helperText={warning}
          label='Password'
          onBlur={() => (confirmPassword.length > 0
            ? updatePasswordMatch(password === confirmPassword) : null)}
          onChange={({ target }) => { updatePassword(target.value); }}
          required
          type={showPassword ? 'text' : 'password'}
          value={password || ''}
          variant='outlined'
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  aria-label='Toggle password visibility'
                  onClick={() => toggleShowPassword(!showPassword)}
                  size='large'
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item md={10} sm={12}>
        <TextField
          autoComplete='new-password'
          error={!passwordMatch}
          fullWidth
          helperText={(password === confirmPassword) ? null : 'Passwords do not match'}
          label='Confirm Password'
          onBlur={() => updatePasswordMatch(password === confirmPassword)}
          onChange={({ target }) => updateConfirmPassword(target.value)}
          required
          type={showPassword ? 'text' : 'password'}
          value={confirmPassword || ''}
          variant='outlined'
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  aria-label='Toggle password visibility'
                  onClick={() => toggleShowPassword(!showPassword)}
                  size='large'
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </>
  );
};
