import React, { createContext, useEffect } from 'react';
import { FLOW_DRAWER_TYPE, FLOW_VIEW_URL } from 'pages/Flow/contacts';
import { activeOrgSelector } from 'redux/reducer/AuthenticationReducer';
import { cleanRoom } from 'utils/spaUrls';
import { crumbs } from 'components/Common/BreadcrumbsNavigation';
import { hasNodeListBeenModified } from 'pages/Flow/flowUtils';
import { mutateNodeForAPI } from 'redux/reducer/FlowReducer/utils';
import {
  openSidePanel,
  setFlowDetails,
  updateSelectedFlow,
  updateSelectedFlowInformation,
} from 'redux/actions/FlowAction';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { useSubmitFlow, useUpdateFlow } from 'hooks/Flow/flowAPI';
import { useUpdateNode } from 'api/flows';


export const FlowBuilderContext = createContext({});

export const FlowBuilderContextProvider = ({ children }) => {
  const { crFlowId, flowId, crId } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();

  const editMode = !!crFlowId;
  const isViewMode = location.pathname.includes('/view/');
  const breadCrumbs = crId ? [crumbs.crFlow(crId)] : [crumbs.flow];
  const flow = useSelector(state => state.flow.flow);
  const { ID: orgId } = useSelector(activeOrgSelector);
  const currentFlowNodes = useSelector(state => state.flow.nodes);

  useEffect(() => {
    dispatch(updateSelectedFlow({ flowID: flowId ?? '' }));
  }, [dispatch, flowId]);

  useEffect(() => {
    dispatch(setFlowDetails({ crFlowId, crId, orgId }));
  }, [dispatch, crId, orgId, crFlowId]);

  const { pathname } = useLocation();
  const isDisabled = pathname.includes(FLOW_VIEW_URL);

  const { isSuccess: flowCreationSuccess } = useSubmitFlow({ crId });
  const { mutate: updateFlow, isSuccess: flowUpdateSuccess } = useUpdateFlow(flowId);
  const { mutate: updateNode } = useUpdateNode(flowId);

  const handleFlowSubmit = (e) => {
    e.preventDefault();

    const modifiedNodeList = hasNodeListBeenModified(flow.nodes, currentFlowNodes);

    updateFlow({ flow: { ...flow, cleanRoomID: crId } });

    if (editMode) {
      if (modifiedNodeList.length > 0) {
        modifiedNodeList.forEach((node) => {
          updateNode({ payload: mutateNodeForAPI(node, flowId), nodeID: node.id });
        });
      }
    }
  };

  const handleAPIGeneration = () => {
  //   logic for generating API
  };

  const handleNameInput = (name) => {
    dispatch(updateSelectedFlowInformation({ type: 'name', value: name }));
  };

  const handleDescriptionInput = (description) => {
    dispatch(updateSelectedFlowInformation({ type: 'description', value: description }));
  };

  const handleParametersDrawerOpen = () => {
    dispatch(openSidePanel({ type: FLOW_DRAWER_TYPE.PARAMETERS, data: { } }));
  };

  const cancelUrl = cleanRoom.flowLanding(crId);

  const flowBuilderContextValue = {
    flowId,
    crFlowId,
    crId,
    flow,
    editMode,
    breadCrumbs,
    isDisabled,
    isViewMode,
    flowUpdateSuccess,
    flowCreationSuccess,
    handleFlowSubmit,
    handleAPIGeneration,
    handleNameInput,
    handleDescriptionInput,
    handParametersDrawerOpen: handleParametersDrawerOpen,
    cancelUrl,
  };

  return (
    <FlowBuilderContext.Provider value={flowBuilderContextValue}>
      {children}
    </FlowBuilderContext.Provider>
  );
};
