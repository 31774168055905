import { USER_LISTS } from 'redux/actions/Types';
import { userLists } from 'utils/urlConstants';


export const changeUserListStatus = (orgId, id, status, meta) => ({
  type: USER_LISTS.CHANGE_STATUS,
  payload: { status },
  url: userLists(orgId).changeStatus(id),
  meta,
});

export const changeUserListStatusError = payload => ({
  type: USER_LISTS.CHANGE_STATUS_ERROR,
  payload,
});

export const changeUserListStatusSuccess = payload => ({
  type: USER_LISTS.CHANGE_STATUS_SUCCESS,
  payload,
});

export const deleteUserList = (orgId, id) => ({
  type: USER_LISTS.DELETE_USER_LIST,
  url: userLists(orgId).delete(id),
});

export const deleteUserListError = payload => ({
  type: USER_LISTS.DELETE_USER_LIST_ERROR,
  payload,
});

export const deleteUserListSuccess = payload => ({
  type: USER_LISTS.DELETE_USER_LIST_SUCCESS,
  payload,
});

export const fetchUserListFilters = orgId => ({
  type: USER_LISTS.FETCH_UL_FILTERS,
  url: userLists(orgId).userListFilters,
});

export const fetchUserListFiltersSuccess = payload => ({
  type: USER_LISTS.FETCH_UL_FILTERS_SUCCESS,
  payload,
});

export const fetchUserListFiltersError = payload => ({
  type: USER_LISTS.FETCH_UL_FILTERS_ERROR,
  payload,
});

export const createUserList = () => ({ type: USER_LISTS.CREATE_USER_LIST });

export const fetchUserListDetailRuns = (orgId, id, startDate) => ({
  type: USER_LISTS.FETCH_LIST_DETAIL_RUNS,
  url: userLists(orgId).detailRuns(id, startDate),
});

export const fetchUserListDetailRunsError = payload => ({
  type: USER_LISTS.FETCH_LIST_DETAIL_RUNS_ERROR,
  payload,
});

export const fetchUserListDetailRunsSuccess = payload => ({
  type: USER_LISTS.FETCH_LIST_DETAIL_RUNS_SUCCESS,
  payload,
});

export const fetchUserListSummary = orgId => ({
  type: USER_LISTS.FETCH_LIST_SUMMARY,
  url: userLists(orgId).summary,
});

export const fetchUserListSummaryError = payload => ({
  type: USER_LISTS.FETCH_LIST_SUMMARY_ERROR,
  payload,
});

export const fetchUserListSummarySuccess = payload => ({
  type: USER_LISTS.FETCH_LIST_SUMMARY_SUCCESS,
  payload,
});

export const saveUserList = (orgId, payload) => ({
  type: USER_LISTS.SAVE_USER_LIST,
  url: (payload.userList.ID) ? `${userLists(orgId).save}/${payload.userList.ID}` : userLists(orgId).save,
  payload,
});

export const saveUserListError = payload => ({
  type: USER_LISTS.SAVE_USER_LIST_ERROR,
  payload,
});

export const saveUserListSuccess = payload => ({
  type: USER_LISTS.SAVE_USER_LIST_SUCCESS,
  payload,
});
