import React, { createContext, useState } from 'react';


export const FlowRunContext = createContext({});

const FlowRunProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalDetails, setModalDetails] = useState(null);
  const openModal = (flowDetails) => {
    setIsOpen(true);
    setModalDetails(flowDetails);
  };
  const closeModal = () => {
    setIsOpen(false);
    setModalDetails(null);
  };

  const value = {
    isOpen,
    modalDetails,
    setModalDetails,
    openModal,
    closeModal,
  };

  return (
    <FlowRunContext.Provider value={value}>
      {children}
    </FlowRunContext.Provider>
  );
};

export default FlowRunProvider;
