import Grid from '@mui/material/Grid';
import NoData from 'components/Common/NoData';
import PageHeader from 'components/Common/PageHeader';
import PropertyConfigurationForm from 'components/InternalAdmin/PropertyConfiguration/PropertyConfigurationForm';
import React, { useState } from 'react';
import Toast from 'components/Common/Toast';
import { HSpinner } from 'BaseComponents';
import { HighlightTypes } from 'components/InternalAdmin/PropertyConfiguration/service';
import { Redirect } from 'react-router-dom';
import {
  createConfiguration,
  fetchForebittConfigurationDetail,
  fetchProperty,
  fetchPropertyConfigurationDetail,
  updateForebittConfiguration,
  updateQuarterdeckConfiguration,
} from 'redux/actions/PropertiesActions';
import { crumbs } from 'components/Common/BreadcrumbsNavigation';
import { habuSupport } from 'utils/appConstants';
import { internalAdmin } from 'utils/spaUrls';
import { useDispatch, useSelector } from 'react-redux';


const OrganizationPropertyConfiguration = (props) => {
  const { propertyId, configurationId } = props.match.params;
  const dispatch = useDispatch();
  const redirect = useSelector(state => state.properties.meta.redirect);
  const orgId = useSelector(state => state.session.user.primaryOrganization.organization.ID);
  const property = useSelector(state => state.properties.details);
  const quarterdeckConfig = useSelector(state => state.properties.configuration);
  const forebittConfig = useSelector(state => state.properties.fieldConfigurations);
  const [serializedConfig, updateSerializedConfig] = useState();

  React.useEffect(() => {
    if (!forebittConfig || !quarterdeckConfig) { return; }
    const { highlights } = quarterdeckConfig.details.bowline;
    const c = { ...quarterdeckConfig };
    c.details.bowline.highlights = highlights.map(
      (q, index) => ({ ...q, ...forebittConfig[index] }),
    );
    updateSerializedConfig(c);
  }, [forebittConfig, quarterdeckConfig]);

  React.useEffect(() => {
    if (propertyId) {
      dispatch(fetchProperty(orgId, propertyId));
      dispatch(fetchForebittConfigurationDetail(orgId, propertyId));
    }
    if (configurationId) {
      dispatch(fetchPropertyConfigurationDetail(orgId, propertyId, configurationId));
    }
  }, [dispatch, orgId, propertyId, configurationId]);

  const updateForebitt = (config) => {
    const fieldConfigurations = config.details.bowline.highlights.map((highlight, index) => {
      const {
        key, type, match, ...rest
      } = highlight;
      rest.fieldName = highlight.key;
      rest.fieldIndex = index + 1;
      rest.isUserIdField = (highlight.type === HighlightTypes.userIdentifier);
      return rest;
    });
    const forebittConfigs = { propertyID: propertyId, fieldConfigurations };
    dispatch(updateForebittConfiguration(orgId, forebittConfigs));
  };

  const updateQuarterdeck = (config) => {
    if (configurationId) {
      dispatch(updateQuarterdeckConfiguration(orgId, propertyId, configurationId, config));
    }
    else {
      dispatch(createConfiguration(orgId, propertyId, config));
    }
  };

  const onSubmit = (config) => {
    updateQuarterdeck(config);
    if (config.details.bowline?.highlights?.length > 0) { updateForebitt(config); }
  };

  if (redirect) {
    return <Redirect to={internalAdmin.organization.organizationProperties} />;
  }

  if (!property) return <HSpinner />;
  else if (property.error) {
    const message = `Error fetching property. ${habuSupport.message}`;
    return (
      <>
        <NoData />
        <Toast type='error' message={message} />
      </>
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <PageHeader title='Property Configuration' breadcrumbs={[crumbs.helium, crumbs.internalAdmin]} />
      </Grid>
      <Grid item xl={12} sm={12}>
        <PropertyConfigurationForm
          configuration={serializedConfig}
          property={property}
          onSubmit={onSubmit}
        />
      </Grid>
    </Grid>
  );
};

export default OrganizationPropertyConfiguration;
