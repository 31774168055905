import React from 'react';
import { CLIENT_GPG_PUBLIC_KEY, HABU_GPG_PUBLIC_KEY } from 'pages/GPGKeyManagement/constant';
import { axiosInstance } from 'utils/http';
import { gpgKey } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch } from 'react-redux';
import { useMutation, useQuery } from 'react-query';

/** Fetch the GPG keys */
const useFetchGPGKey = (orgId) => {
  const [data, setData] = React.useState({
    habuGPGKey: [],
    clientGPGKey: [],
  });
  const dispatch = useDispatch();
  const {
    isLoading, isError, isSuccess, refetch,
  } = useQuery(
    ['GPGKey', orgId],
    () => axiosInstance.get(gpgKey(orgId).list),
    {
      retry: 1,
      refetchOnWindowFocus: false,
      onSuccess: (res) => {
        const keys = Array.isArray(res.data.keys) ? res.data.keys : [];
        setData({
          habuGPGKey: keys.filter(key => key.source === HABU_GPG_PUBLIC_KEY.source),
          clientGPGKey: keys.filter(key => key.source === CLIENT_GPG_PUBLIC_KEY.source),
        });
      },
      onError: (err) => {
        dispatch(showAlert({
          message: err.response.data.message ?? err.response.statusText,
          type: 'error',
        }));
      },
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    data,
    refetch,
  };
};

/** Generate the Habu decryption key */
const useGenerateGPGKey = (orgId) => {
  const [data, setData] = React.useState();
  const dispatch = useDispatch();

  // payload: { organizationID : string, secretID?: string}
  const {
    isLoading, isError, isSuccess, mutate,
  } = useMutation(
    (payload) => axiosInstance.post(gpgKey(orgId).generateGPGKey, payload),
    {
      onSuccess: (res) => {
        setData(res.data);
      },
      onError: (err) => {
        dispatch(showAlert({
          message: err.response.data.message ?? err.response.statusText,
          type: 'error',
        }));
      },
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    data,
    mutate,
  };
};

/** upload client GPG Encryption key */
const useUploadGPGKey = (orgId) => {
  const [data, setData] = React.useState();
  const dispatch = useDispatch();

  // payload: { organizationID : string, secretID: string, clientPublicKey: string}
  const {
    isLoading, isError, isSuccess, mutate,
  } = useMutation(
    (payload) => axiosInstance.post(gpgKey(orgId).uploadGPGKey, payload),
    {
      onSuccess: (res) => {
        setData(res.data);
      },
      onError: (err) => {
        dispatch(showAlert({
          message: err.response.data.message ?? err.response.statusText,
          type: 'error',
        }));
      },
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    data,
    mutate,
  };
};

/** download GPG key */
const useDownloadGPGKey = (orgId) => {
  const [data, setData] = React.useState();
  const dispatch = useDispatch();
  const {
    isLoading, isError, isSuccess, mutate,
  } = useMutation(
    (payload) => axiosInstance.get(gpgKey(orgId).downloadGPGKey(payload.secretID, payload.name)),
    {
      mutationKey: ['DownloadGPGKey', orgId],
      onSuccess: (res) => {
        setData(res.data.key);
      },
      onError: (err) => {
        dispatch(showAlert({
          message: err.response.data.message ?? err.response.statusText,
          type: 'error',
        }));
      },
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    data,
    mutate,
  };
};

export {
  useFetchGPGKey, useGenerateGPGKey, useUploadGPGKey, useDownloadGPGKey,
};
