import * as spaUrls from 'utils/spaUrls';
import DataCredentialConfiguration from 'pages/InternalAdmin/application/pages/CredentialSourceDetails/DataCredentialConfiguration';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import React from 'react';
import Switch from 'components/Common/Switch';
import TextField from '@mui/material/TextField';
import { HButton } from 'BaseComponents';
import { Link } from 'react-router-dom';
import { StyledGrid, StyledHButton, StyledPaper } from 'components/IAM/Admin/StyledComponents';


const dataSourceProvider = [
  { id: 'aws', display: 'AWS' },
  { id: 'gcp', display: 'GCP' },
  { id: 'hubspot', display: 'HubSpot' },
];

const DataCredentialForm = ({ source, submitCredentialSource }) => {
  const [dataSourceName, updateDataSourceName] = React.useState('');
  const [provider, updateProvider] = React.useState('');
  const [managedCredential, updateMangedCredential] = React.useState(false);
  const [configurations, updateConfigurations] = React.useState([]);

  React.useEffect(() => {
    updateDataSourceName(source.name);
    updateProvider(source.provider);
    updateMangedCredential(source.managedCredential);
    updateConfigurations(source.config);
  }, [source]);

  const o = () => {
    const tempObj = {
      source: {
        name: dataSourceName,
        provider,
        managedCredential,
        config: configurations,
      },
    };
    submitCredentialSource(tempObj, source.ID);
  };


  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <StyledPaper>
          <Grid container spacing={2}>
            <Grid item md={8} xs={12}>
              <TextField
                fullWidth
                InputLabelProps={{ shrink: true }}
                label='CREDENTIAL SOURCE NAME'
                variant='outlined'
                value={dataSourceName}
                required
                onChange={e => updateDataSourceName(e.target.value)}
              />
            </Grid>
            <Grid item md={8} xs={12}>
              <TextField
                fullWidth
                InputLabelProps={{ shrink: true }}
                label='PROVIDER'
                variant='outlined'
                value={provider}
                required
                select
                onChange={e => updateProvider(e.target.value)}
              >
                {dataSourceProvider.map(
                  el => <MenuItem key={el.id} value={el.id}>{el.display}</MenuItem>,
                )}
              </TextField>
            </Grid>
            <Grid item md={8} xs={12}>
              <Switch
                checked={managedCredential}
                onChange={e => updateMangedCredential(e.target.checked)}
                label='Managed Credential'
              />
            </Grid>
            <Grid item md={8} xs={12}>
              <DataCredentialConfiguration
                configurations={configurations}
                updateConfigurations={updateConfigurations}
              />
            </Grid>
            <Divider />
            <StyledGrid item md={8} sm={12}>
              <HButton
                component={Link}
                to={spaUrls.internalAdmin.application.credentialSources}
                variant='contained'
              >
                Cancel
              </HButton>
              <StyledHButton
                color='primary'
                onClick={o}
                variant='contained'
              >
                {source.ID ? 'Update Credential Source' : 'Add Data Credential Source' }
              </StyledHButton>
            </StyledGrid>
          </Grid>
        </StyledPaper>
      </Grid>
    </Grid>
  );
};

export default DataCredentialForm;
