import React, { memo } from 'react';
import {
  FormControlLabel,
  FormGroup,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import { HButton } from 'BaseComponents';


export const HAutoRefreshButton = memo(({
  autoRefreshConfig,
  isAutoRefresh,
  handleAutoRefresh,
}) => (
  <HButton size='small' variant='outlined'>
    <Tooltip
      title={autoRefreshConfig.showTooltip
        ? `Enabling this will auto refresh data after every ${autoRefreshConfig.interval / 1000} seconds`
        : ''}
      arrow
      placement='top'
    >
      <FormGroup>
        <FormControlLabel
          sx={{ m: 0 }}
          control={(
            <Switch
              size='small'
              checked={isAutoRefresh}
              onChange={handleAutoRefresh}
              data-testid='auto-refresh-toggle'
            />
          )}
          label={<Typography sx={{ fontSize: 'inherit' }}>Auto Refresh</Typography>}
        />
      </FormGroup>
    </Tooltip>
  </HButton>
));

HAutoRefreshButton.displayName = 'HAutoRefreshButton';
