import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MenuItem from '@mui/material/MenuItem';
import PermissionService from 'components/Common/PermissionService';
import Popover from '@mui/material/Popover';
import React from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import { HButton, LinkComponent } from 'BaseComponents';
import { styled } from '@mui/material/styles';


const StyledSettingsIcon = styled(SettingsIcon)({
  marginRight: '10px',
  fontSize: 12,
});

const GearPopover = (
  {
    actions = [],
    label = 'Actions',
    hideIcon = false,
    ...props
  },
) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const authorizedActions = actions
    .filter(action => PermissionService.isAuthorized(action.allowList));

  return (
    <>
      <HButton variant='contained' color='inherit' onClick={handleClick} disabled={props.disabled || authorizedActions.length === 0}>
        {!hideIcon && <StyledSettingsIcon />}
        {label}
        <ArrowDropDownIcon fontSize='small' />
      </HButton>
      <Popover
        id='actions-menu'
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {authorizedActions.map((actionItem) => {
          if (actionItem.action) {
            return (
              <MenuItem
                onClick={() => {
                  handleClose();
                  actionItem.action();
                }}
                key={actionItem.name}
              >
                {actionItem.name}
              </MenuItem>
            );
          }
          return (
            <MenuItem
              onClick={() => {
                handleClose();
              }}
              key={actionItem.name}
              component={LinkComponent}
              to={actionItem.to}
            >
              {actionItem.name}
            </MenuItem>
          );
        })}
      </Popover>
    </>
  );
};

export default GearPopover;
