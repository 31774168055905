import Grid from '@mui/material/Grid';
import React from 'react';
import { HButton } from 'BaseComponents';


const isLastRowValid = (list, stringRecord = false) => {
  if (!list || !Array.isArray(list)) { return false; }
  // Valid if it's an empty array
  if (list.length === 0) { return true; }
  if (stringRecord) {
    const lastRow = list[list.length - 1];
    if (!lastRow || lastRow.trim().length === 0) { return false; }
    else {
      return true;
    }
  }
  else {
    const lastRow = list[list.length - 1];
    if (!lastRow.name || lastRow.name.trim().length === 0) { return false; }
    else if (!lastRow.displayName || lastRow.displayName.trim().length === 0) { return false; }
    else { return true; }
  }
};

const AddNewSectionButton = ({
  list, stringRecord, onClick, disabled,
}) => (
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <HButton
        color='primary'
        disabled={!isLastRowValid(list, stringRecord) || disabled}
        onClick={onClick}
        variant={isLastRowValid(list, stringRecord) ? 'contained' : 'outlined'}
      >
        Add Another
      </HButton>
    </Grid>
  </Grid>
);

export default AddNewSectionButton;
