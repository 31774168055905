import { activeOrgSelector } from 'redux/reducer/AuthenticationReducer';
import { axiosInstance } from 'utils/http';
import { flow } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'react-query';



export const useGetCleanRoomFlowRunTable = ({ crId, runId }) => {
  const dispatch = useDispatch();
  const { ID: orgId } = useSelector(activeOrgSelector);

  const {
    isLoading, mutate, isSuccess, isError, data,
  } = useMutation(
    ['FLow-Node-Output-Table', crId, runId],
    async ({ payload, nodeID }) => axiosInstance.post(flow(orgId)
      .crNodeTableOutput(crId, runId, nodeID), payload),
    {
      refetchOnWindowFocus: false,
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isError,
    isLoading,
    isSuccess,
    mutate,
    data,
  };
};
