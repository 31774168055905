const { ORGANIZATION_PARAMETERS } = require('redux/actions/Types');


const OrganizationParamentersReducer = (state = {}, action) => {
  switch (action.type) {
    case ORGANIZATION_PARAMETERS.FETCH_ORG_PARAMETERS:
      return { ...state, orgParameters: undefined };
    case ORGANIZATION_PARAMETERS.FETCH_ORG_PARAMETERS_SUCCESS:
      return { ...state, orgParameters: action.payload };
    default:
      return state;
  }
};


export default OrganizationParamentersReducer;
