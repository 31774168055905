import { getValueByFieldPath } from 'utils/jsUtils';
import { useEffect } from 'react';
import { useListData } from 'BaseComponents/HDataTable/utils/useListData';
import { useListFilters } from 'BaseComponents/HDataTable/utils/useListFilters';


export const useTableAPI = (filtersURL, dataURL, tableName, dataBaseField, initOption) => {
  const {
    data: dataFilters,
    isLoading: isFilterLoading,
    isError: isFilterError,
    isSuccess: isFilterSuccess,
    isFetching: isFilterFetching,
  } = useListFilters(filtersURL, tableName);

  const {
    data: dataList,
    isLoading: isListLoading,
    isError: isListError,
    isSuccess: isListSuccess,
    mutate: mutateList,
    reset: resetList,
  } = useListData(dataURL, tableName);

  useEffect(() => {
    mutateList(initOption);
    // adding this line for the useEffect to run only once on the load of the page
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading: isListLoading && isFilterLoading,
    isError: isListError && isFilterError,
    isSuccess: isListSuccess && isFilterSuccess,
    isFetching: isFilterFetching,
    mutate: mutateList,
    reset: resetList,
    filtersOptions: dataFilters?.data,
    count: dataList?.count,
    data: getValueByFieldPath(dataList, dataBaseField),
  };
};
