import ListFilters from 'components/Common/ListFilters';
import React, {
  memo,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import {
  Box,
  ButtonGroup,
  Card,
  Grid,
  Paper,
  Stack,
  paperClasses,
} from '@mui/material';
import { DEFAULT_AUTO_REFRESH_INTERVAL } from 'utils/appConstants';
import { DataContext } from 'BaseComponents/HDataTable/DataContextProvider';
import { HAutoRefreshButton } from 'BaseComponents/HTableControlPanel/HAutoRefreshButton';
import { HCreateButton } from 'BaseComponents/HTableControlPanel/HCreateButton';
import { HFiltersButton } from 'BaseComponents/HTableControlPanel/HFiltersButton';
import { HMultipleCreateButton } from 'BaseComponents/HTableControlPanel/HMultipleCreateButton';
import { HOnRefreshButton } from 'BaseComponents/HTableControlPanel/HOnRefreshButton';
import { LayoutButton } from 'BaseComponents/HTableControlPanel/LayoutButton';
import { LayoutStyles } from 'components/Common/ListControls';
import { styled } from '@mui/material/styles';


const StyledPaper = styled(Paper)(({ theme }) => ({
  [`&.${paperClasses.root}`]: {
    padding: theme.spacing(2),
  },
}));

const StyledCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

export const HTableControlPanel = memo(({
  createButton,
  isCreateButtonVisible,
  createPermissions = [],
  helpLinkComp,
  multipleCreateButton,
  onRefresh,
  searchAttribute,
  autoRefresh,
  dataContextRef = useRef,
  showFiltersButton = true,
  onSelectionChange = () => {},
}) => {
  const {
    layout: defaultLayout,
    handleLayoutToggle,
    cardElement,
    fetchData,
    refreshListData,
    reset,
    filters,
    filtersOptions,
    handleSearchChange,
    handleFilterChange,
    selectedList,
  } = useContext(DataContext);
  const layout = defaultLayout === LayoutStyles.card.text ? LayoutStyles.list : LayoutStyles.card;

  const [showFilters, toggleShowFilters] = React.useState(false);
  const [isAutoRefresh, setIsAutoRefresh] = React.useState(autoRefresh?.enabled ?? false);

  const autoRefreshConfig = useMemo(() => ({
    allowed: false,
    enabled: false,
    interval: DEFAULT_AUTO_REFRESH_INTERVAL,
    showTooltip: true,
    ...autoRefresh,
  }), [autoRefresh]);

  const handleAutoRefresh = (event) => {
    setIsAutoRefresh(event.target.checked);
  };

  useEffect(() => {
    // eslint-disable-next-line no-param-reassign
    dataContextRef.current = {
      refreshListData, reset,
    };
  });

  useEffect(() => {
    onSelectionChange(selectedList);
  }, [onSelectionChange, selectedList]);

  useEffect(() => {
    const autoRefreshIntervalId = setInterval(() => {
      if (isAutoRefresh) {
        onRefresh({ fetchData, filters });
      }
      else {
        clearInterval(autoRefreshIntervalId);
      }
    }, autoRefreshConfig.interval);

    return () => clearInterval(autoRefreshIntervalId);
  }, [isAutoRefresh, onRefresh, autoRefreshConfig, fetchData, filters, refreshListData, reset]);

  if (!filtersOptions && !cardElement && !autoRefresh && !helpLinkComp
      && !createButton && !multipleCreateButton && !onRefresh) {
    return null;
  }

  const handleOnRefreash = () => onRefresh({ fetchData, filters });

  return (
    <StyledCard>
      <StyledPaper elevation={0}>
        <Grid container spacing={2} justifyContent='space-between'>
          <Grid item xs={12} md={6}>
            <Box display='flex'>
              <ButtonGroup size='small' variant='outlined'>
                {cardElement && (
                  <LayoutButton layout={layout} layoutOnToggle={handleLayoutToggle} />
                )}

                {showFiltersButton && filtersOptions && (
                  <HFiltersButton
                    toggleShowFilters={toggleShowFilters}
                    searchAttribute={searchAttribute}
                    showFilters={showFilters}
                  />
                )}

                {autoRefreshConfig?.allowed && (
                  <HAutoRefreshButton
                    autoRefreshConfig={autoRefreshConfig}
                    isAutoRefresh={isAutoRefresh}
                    handleAutoRefresh={handleAutoRefresh}
                  />
                )}
              </ButtonGroup>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box display='flex' justifyContent='flex-end'>
              <Stack direction='row' spacing={2} alignItems='center'>
                {helpLinkComp}

                <HCreateButton
                  createPermissions={createPermissions}
                  createButton={createButton}
                  isVisible={isCreateButtonVisible}
                />

                <HMultipleCreateButton multipleCreateButton={multipleCreateButton} />

                <HOnRefreshButton onRefresh={onRefresh && handleOnRefreash} />
              </Stack>
            </Box>
          </Grid>

          {showFilters && (
            <Grid item xs={12}>
              <ListFilters
                allFilters={filtersOptions}
                appliedFilters={filters}
                onChange={handleFilterChange}
                searchAttribute={searchAttribute}
                onSearch={handleSearchChange}
                searchText={filters.search}
              />
            </Grid>
          )}
        </Grid>
      </StyledPaper>
    </StyledCard>
  );
});

HTableControlPanel.displayName = 'HTableControlPanel';
