import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { HDialog, HDialogContent } from 'BaseComponents';
import {
  IconButton, InputAdornment, MenuItem, Skeleton, TextField,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  useGetIdentityCredentialSource, useSaveIdentityCredential, useUpdateIdentityCredential,
} from 'api/OrganizationIdentity';


const LoadingSkeleton = () => Array.from({ length: 3 }, (_, i) => (
  <Skeleton
    variant='rectangular'
    key={i}
    height={32}
  />
));

const IdentityConfigDialog = ({ open, handleClose, selectedRow }) => {
  const [showPassword, setShowPassword] = useState({});

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  const {
    isLoading,
    data: identityCredentials,
  } = useGetIdentityCredentialSource();
  const {
    isLoading: isSaving,
    mutate: saveCredentials,
  } = useSaveIdentityCredential();
  const {
    isLoading: isUpdating,
    mutate: updateCredentials,
  } = useUpdateIdentityCredential(selectedRow?.ID);

  useEffect(() => {
    if (selectedRow) {
      setValue('name', selectedRow.name);
      selectedRow.credentials.forEach(credential => {
        setValue(credential.name, credential.value);
      });
    }
  }, [selectedRow, setValue]);

  const handleClickShowPassword = useCallback((field) => {
    setShowPassword(prevState => ({
      ...prevState,
      [field.name]: !prevState[field.name],
    }));
  }, []);

  const onDialogClose = () => {
    reset();
    handleClose();
  };

  const onSubmit = (data) => {
    const payload = {
      name: data.name,
      credentialSourceID: identityCredentials.ID,
      credentials: Object.keys(data)
        .filter(key => key !== 'name')
        .map((key) => ({
          name: key,
          value: data[key],
        })),
    };
    if (selectedRow) {
      updateCredentials(payload, {
        onSuccess: onDialogClose,
      });
    }
    else {
      saveCredentials(payload, {
        onSuccess: onDialogClose,
      });
    }
  };

  return (
    <HDialog
      fullWidth
      open={open}
      maxWidth='sm'
      title={selectedRow ? 'Edit Identity Configuration' : 'Add Identity Configuration'}
      submitText='Save'
      onClose={onDialogClose}
      loading={isSaving || isUpdating}
      onSubmit={handleSubmit(onSubmit)}
      closeAfterTransition={false}
    >
      <HDialogContent>
        {isLoading ? <LoadingSkeleton /> : (
          <>
            <Controller
              name='name'
              control={control}
              defaultValue=''
              rules={{ required: 'Name is required' }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label='Name'
                  fullWidth
                  error={!!errors.name}
                  helperText={errors.name?.message ?? ' '}
                />
              )}
            />
            {identityCredentials?.config?.map((field) => (
              <Controller
                key={field.ID}
                name={field.name}
                control={control}
                defaultValue=''
                rules={{ required: `${field.name} is required` }}
                render={({ field: controllerField }) => (
                  <TextField
                    {...controllerField}
                    label={field.name}
                    autoComplete={field.type === 'password' ? 'new-password' : 'off'}
                    type={field.type === 'password' && !showPassword[field.name] ? 'password' : 'text'}
                    select={field.type === 'config_list'}
                    fullWidth
                    error={!!errors[field.name]}
                    helperText={errors[field.name]?.message ?? ' '}
                    InputProps={field.type === 'password' ? {
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={() => handleClickShowPassword(field)}
                            onMouseDown={e => e.preventDefault()}
                            edge='end'
                          >
                            {showPassword[field.name] ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    } : {}}
                  >
                    {field.type === 'config_list' && field.credentialConfigListValues.map(option => (
                      <MenuItem key={option.ID} value={option.value}>
                        {option.displayName}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            ))}
          </>
        )}
      </HDialogContent>
    </HDialog>
  );
};

export default IdentityConfigDialog;
