import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { HTableSkeleton } from 'BaseComponents/HDataTable/HTableSkeleton';
import { RUN_PARAMETER_TYPES } from 'pages/Flow/contacts';
import { habuColors } from 'Theme';


const FlowRunMacroTable = ({
  listOfFlowMacros, onUpdateMacroValue, loading, error,
}) => {
  if (loading) {
    return <HTableSkeleton />;
  }

  if (error) {
    return (
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        gap={1}
        p={1}
      >
        <InfoOutlinedIcon color='error' />
        <Typography variant='subtitle3' color={habuColors.neutral[60]}>
          Error loading flow parameters.
        </Typography>
      </Box>
    );
  }



  if (listOfFlowMacros.length === 0) {
    return (
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        gap={1}
        p={1}
      >
        <InfoOutlinedIcon color='info' />
        <Typography variant='subtitle3' color={habuColors.neutral[60]}>
          No Flow paramaters available. Hit next to fill out the rest of the run parameters.
        </Typography>
      </Box>
    );
  }



  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width='20%'>
              <Typography variant='subtitle'>
                Flow Parameter
              </Typography>
            </TableCell>
            <TableCell width='30%'>
              <Typography variant='subtitle'>
                Value
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant='subtitle'>
                Description
              </Typography>
            </TableCell>
            <TableCell width='10%' />
          </TableRow>
        </TableHead>
        <TableBody>
          {listOfFlowMacros.map((macro) => (
            <TableRow key={macro.ID}>
              <TableCell>
                <Box display='flex' alignItems='center'>
                  <Tooltip
                    title={RUN_PARAMETER_TYPES[macro.type]?.displayName || 'Unknown'}
                    placement='top'
                  >
                    <Box sx={{ pr: 1 }}>
                      {RUN_PARAMETER_TYPES[macro.type]?.Icon || 'Unknown'}
                    </Box>
                  </Tooltip>
                  <Chip
                    icon={<AlternateEmailIcon fontSize='small' style={{ marginRight: '8px' }} />}
                    label={macro.name}

                  />
                </Box>
              </TableCell>
              <TableCell>
                <TextField
                  fullWidth
                  margin='dense'
                  required
                  type={macro.type}
                  value={macro.value}
                  variant='outlined'
                  onChange={(e) => onUpdateMacroValue(macro.ID, e.target.value)}
                />
              </TableCell>
              <TableCell>{macro.description}</TableCell>
              <TableCell>
                <Box display='flex' alignItems='center'>
                  <Typography variant='body2' sx={{ marginRight: 1 }}>
                    {macro.runParamMapping.length}
                  </Typography>
                  <InsertLinkIcon />
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FlowRunMacroTable;
