import Button from '@mui/material/Button';
import React from 'react';
import TableCell from '@mui/material/TableCell';
import TextField from '@mui/material/TextField';
import { HButton } from 'BaseComponents';
import { Stack } from '@mui/material';
import { habuColors } from 'Theme';


const MacroDescriptionCell = ({ macro, description, onFlowMacroUpdate }) => {
  const [showTextField, setShowTextField] = React.useState(false);
  const [currentDescription, setCurrentDescription] = React.useState(description);

  const onCancel = () => { setShowTextField(false); };
  const onSave = (e) => {
    e.stopPropagation();
    onFlowMacroUpdate({
      macro,
      description: currentDescription,
    });
    setShowTextField(false);
  };

  const onEdit = () => {
    setShowTextField(true);
  };

  if (showTextField) {
    return (
      <>
        <TableCell>
          <TextField
            id='outlined-multiline-static'
            label='Description'
            multiline
            fullWidth
            rows={4}
            value={currentDescription}
            onChange={(e) => setCurrentDescription(e.target.value)}
            variant='outlined'
          />
          <Stack
            direction='row'
            spacing={1}
            justifyContent='center'
            alignItems='center'
            paddingTop={1}
          >
            <HButton
              variant='outlined'
              onClick={onCancel}
              size='small'
            >
              Cancel
            </HButton>
            <HButton
              color='primary'
              variant='contained'
              type='submit'
              size='small'
              onClick={(e) => onSave(e)}
            >
              Save
            </HButton>
          </Stack>
        </TableCell>
        <TableCell />
      </>
    );
  }

  if (description.length > 0) {
    return (
      <TableCell
        onClick={onEdit}
        sx={{
          fontWeight: 'bold',
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: habuColors.secondary[10],
          },
        }}
      >
        {description}
      </TableCell>
    );
  }

  return (
    <TableCell>
      <Button variant='text' onClick={() => setShowTextField(true)}>
        + Description
      </Button>
    </TableCell>
  );
};

export default MacroDescriptionCell;
