import React from 'react';
import {
  CheckCircle, Error, Info, Warning,
} from '@mui/icons-material';
import {
  alertClasses,
  autocompleteClasses,
  buttonBaseClasses,
  buttonClasses,
  checkboxClasses,
  iconButtonClasses,
  inputAdornmentClasses,
  inputBaseClasses,
  inputClasses,
  inputLabelClasses,
  listItemIconClasses,
  listItemTextClasses,
  menuClasses,
  outlinedInputClasses,
  paperClasses,
  stepClasses,
  stepIconClasses,
  stepLabelClasses,
  stepperClasses,
  svgIconClasses,
  switchClasses,
  textFieldClasses,
  toggleButtonClasses,
  toggleButtonGroupClasses,
} from '@mui/material';
import { motifDesignToken } from 'designToken';


const SNACKBAR_VARIANTS = {
  DEFAULT: 'default',
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
  PRIVACY: 'privacy',
};

const baseSize = 16;

export const motifLineHeight = 1.4;

const openSans = '"Open Sans", sans-serif';

const liveRampSans = '"Liveramp Sans", sans-serif';

const ibmPlexMono = '"IBM Plex Mono", monospace';

export const pxToRem = (size) => `${size / baseSize}rem`;

export const motifBaseTheme = {
  palette: {
    mode: 'light',
    common: {
      black: motifDesignToken.color.black,
      white: motifDesignToken.color.white,
      primary: motifDesignToken.color.green[500],
      success: motifDesignToken.color.green[500],
      secondary: motifDesignToken.color.grey[500],
      error: motifDesignToken.color.red[500],
      info: motifDesignToken.color.blue[500],
      pending: motifDesignToken.color.orange[500],
      warning: motifDesignToken.color.yellow[500],
      privacy: motifDesignToken.color.purple[500],
    },
    primary: {
      hue: motifDesignToken.color.green,
      light: motifDesignToken.color.green[100],
      main: motifDesignToken.color.green[500],
      dark: motifDesignToken.color.green[600],
      hover: 'rgba(182,249,220,0.24)',
      active: 'rgba(50,219,134,0.28)',
    },
    // same as primary
    success: {
      hue: motifDesignToken.color.green,
      'x-light': motifDesignToken.color.green[50],
      light: motifDesignToken.color.green[100],
      main: motifDesignToken.color.green[500],
      dark: motifDesignToken.color.green[600],
      hover: 'rgba(50,219,134,0.15)',
      active: 'rgba(50,219,134,0.28)',
    },
    secondary: {
      hue: motifDesignToken.color.grey,
      light: motifDesignToken.color.grey[200],
      main: motifDesignToken.color.grey[500],
      dark: motifDesignToken.color.grey[700],
      hover: 'rgba(128,128,128,0.15)',
      active: 'rgba(128,128,128,0.28)',
    },
    error: {
      hue: motifDesignToken.color.red,
      'x-light': motifDesignToken.color.red[50],
      light: motifDesignToken.color.red[100],
      main: motifDesignToken.color.red[500],
      dark: motifDesignToken.color.red[700],
      hover: 'rgba(242,29,68,0.15)',
      active: 'rgba(242,29,68,0.28)',
    },
    info: {
      hue: motifDesignToken.color.blue,
      'x-light': motifDesignToken.color.blue[50],
      light: motifDesignToken.color.blue[100],
      main: motifDesignToken.color.blue[500],
      dark: motifDesignToken.color.blue[600],
      'x-dark': motifDesignToken.color.blue[800],
      hover: 'rgba(38,181,237,0.1)',
      active: 'rgba(38,181,237,0.28)',
    },
    pending: {
      hue: motifDesignToken.color.orange,
      light: motifDesignToken.color.orange[100],
      main: motifDesignToken.color.orange[500],
      dark: motifDesignToken.color.orange[600],
      hover: 'rgba(255,98,36,0.15)',
      active: 'rgba(255,98,36,0.28)',
    },
    warning: {
      hue: motifDesignToken.color.yellow,
      'x-light': motifDesignToken.color.yellow[50],
      light: motifDesignToken.color.yellow[100],
      main: motifDesignToken.color.yellow[500],
      dark: motifDesignToken.color.yellow[700],
      hover: 'rgba(253,215,3,0.15)',
      active: 'rgba(253,215,3,0.28)',
    },
    privacy: {
      hue: motifDesignToken.color.purple,
      'x-light': motifDesignToken.color.purple[50],
      light: motifDesignToken.color.purple[100],
      main: motifDesignToken.color.purple[500],
      dark: motifDesignToken.color.purple[700],
      hover: 'rgba(115,93,208,0.15)',
      active: 'rgba(115,93,208,0.28)',
    },
    light: {
      main: motifDesignToken.color.white,
      active: motifDesignToken.color.white,
    },
    disabled: {
      hue: motifDesignToken.color.grey,
      light: motifDesignToken.color.grey[100],
      main: motifDesignToken.color.grey[400],
      dark: motifDesignToken.color.grey[600],
    },
    text: {
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)',
      primary: motifDesignToken.color.grey[800],
      secondary: motifDesignToken.color.grey[500],
      link: motifDesignToken.color.blue[700],
      hoverLink: motifDesignToken.color.blue[800],
      visitedLink: motifDesignToken.color.purple[500],
    },
    background: {
      hue: motifDesignToken.color.grey,
      light: motifDesignToken.color.grey[50],
      main: motifDesignToken.color.white,
      dark: motifDesignToken.color.grey[900],
    },
  },
  typography: {
    fontFamily: openSans,
    fontFamilyHeader: liveRampSans,
    fontSize: baseSize,
    htmlFontSize: baseSize,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 550,
    fontWeightBold: 600,
    h1: {
      fontFamily: liveRampSans,
      fontSize: pxToRem(36),
      fontWeight: 400,
      lineHeight: motifLineHeight,
    },
    jumbo: {
      fontSize: pxToRem(32),
      fontWeight: 400,
      lineHeight: motifLineHeight,
    },
    h2: {
      fontSize: pxToRem(24),
      fontWeight: 400,
      lineHeight: motifLineHeight,
    },
    h2Branding: {
      fontFamily: liveRampSans,
      fontSize: pxToRem(24),
      fontWeight: 400,
      lineHeight: motifLineHeight,
    },
    h3: {
      fontSize: pxToRem(20),
      fontWeight: 400,
      lineHeight: motifLineHeight,
    },
    h3Branding: {
      fontFamily: liveRampSans,
      fontSize: pxToRem(20),
      fontWeight: 400,
      lineHeight: motifLineHeight,
    },
    h4: {
      fontFamily: openSans,
      fontSize: pxToRem(18),
      fontWeight: 400,
      lineHeight: motifLineHeight,
    },
    h4Branding: {
      fontFamily: liveRampSans,
      fontSize: pxToRem(18),
      fontWeight: 400,
      lineHeight: motifLineHeight,
    },
    subtitle1: {
      fontFamily: openSans,
      fontSize: pxToRem(16),
      fontWeight: 600,
      lineHeight: motifLineHeight,
    },
    subtitle2: {
      fontFamily: openSans,
      fontSize: pxToRem(14),
      fontWeight: 600,
      lineHeight: motifLineHeight,
    },
    subtitle3: {
      fontFamily: openSans,
      fontSize: pxToRem(12),
      fontWeight: 600,
      lineHeight: motifLineHeight,
    },
    body1: {
      fontFamily: openSans,
      fontSize: pxToRem(16),
      fontWeight: 400,
      lineHeight: motifLineHeight,
    },
    body2: {
      fontFamily: openSans,
      fontSize: pxToRem(14),
      fontWeight: 400,
      lineHeight: motifLineHeight,
    },
    body3: {
      fontFamily: openSans,
      fontSize: pxToRem(12),
      fontWeight: 400,
      lineHeight: motifLineHeight,
    },
    body4: {
      fontFamily: openSans,
      fontSize: pxToRem(10),
      fontWeight: 400,
      lineHeight: motifLineHeight,
    },
    caption: {
      fontFamily: openSans,
      fontSize: pxToRem(10),
      fontWeight: 400,
      lineHeight: motifLineHeight,
    },
    code1: {
      fontFamily: ibmPlexMono,
      fontSize: pxToRem(12),
      fontWeight: 400,
      lineHeight: motifLineHeight,
    },
    code2: {
      fontFamily: ibmPlexMono,
      fontSize: pxToRem(10),
      fontWeight: 400,
      lineHeight: motifLineHeight,
    },
    overline: {
      fontFamily: openSans,
      fontSize: pxToRem(12),
      lineHeight: motifLineHeight,
    },
  },
  elevation1: {
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.16)',
  },
  elevation2: {
    boxShadow: '0px 1px 4px 2px rgba(0, 0, 0, 0.12)',
  },
  elevation3: {
    boxShadow: '0px 1px 6px 4px rgba(0, 0, 0, 0.08)',
  },
  shadows: [
    'none',
    '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
    '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
    '0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)',
    '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',
    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
    '0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)',
    '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
    '0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)',
    '0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)',
    '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
    '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
    '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
    '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
    '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
    '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
    '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',
    '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',
    '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',
    '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)',
  ],
  transitions: {
    common: {
      enteringScreen: '225ms cubic-bezier(0.4, 0, 0.2, 1)',
      short: '250ms cubic-bezier(0.4, 0, 0.2, 1)',
      leavingScreen: '195ms cubic-bezier(0.0, 0, 0.2, 1)',
    },
  },
};

const STEP_ICON_SIZE = '32px';

// IMPORTANT: The target classes are very Habu specific. This function differs from the Motif one
const getAlertStyle = (theme, variant) => ({
  backgroundColor: theme.palette[variant].hue[50],
  [`&.${alertClasses.root} .${alertClasses.icon}`]: {
    [`& .${svgIconClasses.root}`]: {
      color: theme.palette[variant].dark,
    },
  },
});

const SnackbarOverride = {
  defaultProps: {
    iconMapping: {
      error: <Error />,
      info: <Info />,
      success: <CheckCircle />,
      warning: <Warning />,
    },
  },
  styleOverrides: {
    root: {
      [`&.${paperClasses.root}`]: {
        flexGrow: '100',
      },
      flexWrap: 'nowrap',
      margin: `${pxToRem(16)}`,
      padding: `${pxToRem(9)} ${pxToRem(18)}`,
      ...motifBaseTheme.typography.body1,
      color: motifBaseTheme.palette.text.primary,
      ...motifBaseTheme.elevation2,
      [`&.${paperClasses.root}.${alertClasses.root}, &.MuiSnackBarContent-inline`]: {
        boxShadow: 'unset',
      },
      alignItems: 'center',
    },
    message: {
      display: 'grid',
      gridAutoFlow: 'column',
      gap: `${pxToRem(18)}`,
      padding: `${pxToRem(8)} 0`,
      [`& .${svgIconClasses.root}`]: {
        fontSize: `${pxToRem(24)}`,
      },
    },
    action: {
      flexShrink: 1,
      marginRight: `-${pxToRem(6)}`,
      padding: `${pxToRem(2)} 0 ${pxToRem(2)} ${pxToRem(16)}`,
      [`& .${buttonClasses.textSecondary}`]: {
        fontSize: `${pxToRem(14)}`,
      },
      [`& .${iconButtonClasses.colorSecondary}`]: {
        marginLeft: `${pxToRem(18)}`,
        color: motifBaseTheme.palette.text.primary,
      },
    },
    icon: {
      '& svg': {
        width: '20px',
        height: '20px',
      },
    },
    standardError: {
      ...getAlertStyle(motifBaseTheme, SNACKBAR_VARIANTS.ERROR),
    },
    filledError: {
      ...getAlertStyle(motifBaseTheme, SNACKBAR_VARIANTS.ERROR),
    },
    outlinedError: {
      ...getAlertStyle(motifBaseTheme, SNACKBAR_VARIANTS.ERROR),
    },
    standardInfo: {
      ...getAlertStyle(motifBaseTheme, SNACKBAR_VARIANTS.INFO),
    },
    filledInfo: {
      ...getAlertStyle(motifBaseTheme, SNACKBAR_VARIANTS.INFO),
    },
    outlinedInfo: {
      ...getAlertStyle(motifBaseTheme, SNACKBAR_VARIANTS.INFO),
    },
    standardSuccess: {
      ...getAlertStyle(motifBaseTheme, SNACKBAR_VARIANTS.SUCCESS),
    },
    filledSuccess: {
      ...getAlertStyle(motifBaseTheme, SNACKBAR_VARIANTS.SUCCESS),
    },
    outlinedSuccess: {
      ...getAlertStyle(motifBaseTheme, SNACKBAR_VARIANTS.SUCCESS),
    },
    standardWarning: {
      ...getAlertStyle(motifBaseTheme, SNACKBAR_VARIANTS.WARNING),
    },
    filledWarning: {
      ...getAlertStyle(motifBaseTheme, SNACKBAR_VARIANTS.WARNING),
    },
    outlinedWarning: {
      ...getAlertStyle(motifBaseTheme, SNACKBAR_VARIANTS.WARNING),
    },
  },
};

export const motifComponentsOverride = {
  MuiAlert: {
    ...SnackbarOverride,
  },
  MuiAutocomplete: {
    styleOverrides: {
      root: {
        [`& .${textFieldClasses.root}`]: {
          width: '100%',
        },
        '&.MuiAutocomplete-multiple': {
          [`& .${outlinedInputClasses.root}, & .${inputClasses.root}`]: {
            minWidth: pxToRem(250),
            width: 'unset',
            flexWrap: 'wrap',
          },
        },
        [`& .${autocompleteClasses.tag}`]: {
          margin: pxToRem(2),
        },
        // Outlined
        [`& .${outlinedInputClasses.root}`]: {
          padding: 'unset',
          [`& .${autocompleteClasses.input}`]: {
            padding: pxToRem(8),
            width: 0,
            minWidth: pxToRem(30),
            paddingRight: pxToRem(24),
          },
          [`& .${autocompleteClasses.endAdornment}`]: {
            right: pxToRem(8),
          },
          [`& .${autocompleteClasses.tag}:first-of-type`]: {
            marginLeft: pxToRem(4),
          },
        },
        [`&.${autocompleteClasses.hasPopupIcon} .${outlinedInputClasses.root}`]: {
          paddingRight: pxToRem(32),
        },
        [`&.${autocompleteClasses.hasPopupIcon}.${autocompleteClasses.hasClearIcon} .${outlinedInputClasses.root}`]:
          {
            paddingRight: pxToRem(56),
          },
        // Underlined
        [`& .${inputClasses.root}`]: {
          [`& .${autocompleteClasses.input}`]: {
            padding: `${pxToRem(6)} 0`,
            width: 0,
            minWidth: pxToRem(30),
            paddingRight: pxToRem(24),
          },
          [`& .${autocompleteClasses.tag}:first-of-type`]: {
            marginLeft: '0',
          },
        },
      },
      listbox: {
        [`& .${autocompleteClasses.option}`]: {
          padding: `${pxToRem(2)} ${pxToRem(8)}`,
        },
      },
    },
  },
  MuiButtonBase: {
    defaultProps: {
      centerRipple: true,
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        borderRadius: pxToRem(4),
        boxShadow: 'none',
        textTransform: 'none',
        padding: `${pxToRem(7)} ${pxToRem(15)}`,
        color: motifBaseTheme.palette.text.primary,
        fontWeight: motifBaseTheme.typography.fontWeightBold,
        lineHeight: pxToRem(20),
        '&:hover': {
          boxShadow: 'none',
          color: motifBaseTheme.palette.text.primary,
        },
      },
      contained: {
        color: motifBaseTheme.palette.text.primary,
        borderColor: motifBaseTheme.palette.primary.main,
        borderWidth: 1,
        borderStyle: 'solid',
        '&:hover, &.Mui-focusVisible': {
          backgroundColor: motifBaseTheme.palette.primary.dark,
          borderColor: motifBaseTheme.palette.primary.dark,
        },

        '&:active, &.Mui-active': {
          backgroundColor: motifBaseTheme.palette.primary.dark,
          borderColor: motifBaseTheme.palette.primary.dark,
        },

        [`&:disabled, &.${buttonClasses.disabled}`]: {
          color: motifBaseTheme.palette.disabled.main,
          backgroundColor: motifBaseTheme.palette.disabled.light,
          borderColor: motifBaseTheme.palette.disabled.light,
        },
      },
      outlined: {
        color: motifBaseTheme.palette.text.primary,
        borderColor: motifBaseTheme.palette.common.black,
        '&:hover, &.Mui-focusVisible': {
          backgroundColor: motifBaseTheme.palette.secondary.light,
          borderColor: motifBaseTheme.palette.common.black,
        },
        '&:active, &.Mui-active': {
          backgroundColor: motifBaseTheme.palette.secondary.light,
          borderColor: motifBaseTheme.palette.common.black,
        },
        [`&:disabled, &.${buttonClasses.disabled}`]: {
          color: motifBaseTheme.palette.disabled.main,
          borderColor: motifBaseTheme.palette.disabled.light,
        },
      },
      destructive: {
        color: motifBaseTheme.palette.common.white,
        backgroundColor: motifBaseTheme.palette.error.dark,
        borderColor: motifBaseTheme.palette.error.dark,
        borderWidth: 1,
        borderStyle: 'solid',
        '&:hover, &.Mui-focusVisible': {
          color: motifBaseTheme.palette.common.white,
          backgroundColor: motifBaseTheme.palette.error.main,
          borderColor: motifBaseTheme.palette.error.main,
        },
        '&:active, &.Mui-active': {
          backgroundColor: motifBaseTheme.palette.error.main,
          color: motifBaseTheme.palette.common.white,
          borderColor: motifBaseTheme.palette.error.main,
        },
        [`&:disabled, &.${buttonClasses.disabled}`]: {
          color: motifBaseTheme.palette.disabled.main,
          backgroundColor: motifBaseTheme.palette.disabled.light,
          borderColor: motifBaseTheme.palette.disabled.light,
        },
      },
      text: {
        color: motifBaseTheme.palette.text.primary,
        borderColor: 'transparent',
        borderWidth: 1,
        borderStyle: 'solid',
        '&:hover, &.Mui-focusVisible': {
          backgroundColor: motifBaseTheme.palette.secondary.light,
          borderColor: motifBaseTheme.palette.secondary.light,
        },
        '&:active, &.Mui-active': {
          backgroundColor: motifBaseTheme.palette.secondary.light,
          borderColor: motifBaseTheme.palette.secondary.light,
        },
        [`&:disabled, &.${buttonClasses.disabled}`]: {
          color: motifBaseTheme.palette.disabled.main,
          borderColor: motifBaseTheme.palette.disabled.main,
        },
      },
      sizeMedium: {
        ...motifBaseTheme.typography.subtitle2,
      },
      sizeSmall: {
        ...motifBaseTheme.typography.subtitle3,
      },
    },
  },
  MuiBreadcrumbs: {
    styleOverrides: {
      li: {
        '& *, & *:visited': {
          ...motifBaseTheme.typography.body3,
          display: 'flex',
          alignItems: 'center',
          color: motifBaseTheme.palette.text.secondary,
          textDecoration: 'none',
        },
        '& *:hover': {
          cursor: 'pointer',
        },
        '&:last-child *': {
          color: motifBaseTheme.palette.secondary.dark,

          '&:hover': {
            cursor: 'auto',
          },
        },
        '&:hover:not(:last-child) *': {
          color: motifBaseTheme.palette.text.hoverLink,
          textDecoration: 'underline',
        },
      },
      separator: {
        ...motifBaseTheme.typography.body3,
        color: motifBaseTheme.palette.text.secondary,
      },
    },
  },
  MuiCheckbox: {
    defaultProps: {
      color: 'default',
    },
    styleOverrides: {
      root: {
        padding: `${pxToRem(6)}`,
        color: motifBaseTheme.palette.secondary.main,
        transition: motifBaseTheme.transitions.common.enteringScreen,
        '&:hover': {
          backgroundColor: motifBaseTheme.palette.primary.hue[50],
        },
        [`&.Mui-checked, &.Mui-checked.${checkboxClasses.indeterminate}`]: {
          color: motifBaseTheme.palette.primary.dark,
        },
        '&.Mui-disabled': {
          color: motifBaseTheme.palette.disabled.main,
        },
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        ...motifBaseTheme.typography.body2,
        borderRadius: pxToRem(4),
        padding: `0 ${pxToRem(8)}`,
        gap: pxToRem(6),
        minHeight: pxToRem(32),
        height: 'unset',
        color: motifBaseTheme.palette.text.primary,
      },
      icon: {
        margin: 0,
      },
      label: {
        padding: 0,
      },
      deleteIcon: {
        margin: 0,
        fontSize: pxToRem(24),
      },
      sizeSmall: {
        ...motifBaseTheme.typography.body3,
        minHeight: pxToRem(24),
      },
      deleteIconSmall: {
        fontSize: pxToRem(16),
      },
      colorPrimary: {
        backgroundColor: motifBaseTheme.palette.info.main,
        color: motifBaseTheme.palette.common.white,
      },
      deletableColorPrimary: {
        '&:focus': {
          backgroundColor: motifBaseTheme.palette.info.hue[400],
        },
      },
      deleteIconColorPrimary: {
        fill: motifBaseTheme.palette.info.hue[100],
        '&:hover, &:active': {
          fill: motifBaseTheme.palette.common.white,
        },
      },
      colorSecondary: {
        backgroundColor: motifBaseTheme.palette.secondary.light,
        color: motifBaseTheme.palette.text.primary,
      },
      // variant
      cta: {
        backgroundColor: motifBaseTheme.palette.info['x-dark'],
        '&, & svg': {
          color: `${motifBaseTheme.palette.common.white} !important`,
        },
      },
      increase: {
        backgroundColor: motifBaseTheme.palette.success['x-light'],
        '&, & svg': {
          color: `${motifBaseTheme.palette.success.dark} !important`,
        },
      },
      decrease: {
        backgroundColor: motifBaseTheme.palette.error['x-light'],
        '&, & svg': {
          color: `${motifBaseTheme.palette.error.dark} !important`,
        },
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        ...motifBaseTheme.typography.body2,
        lineHeight: pxToRem(20),
        fontSize: pxToRem(14),
        '&.FullScreenDialongContent-divider': {
          borderRight: `${pxToRem(1)} solid ${motifBaseTheme.palette.secondary.light}`,
        },
      },
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        ...motifBaseTheme.typography.h3Branding,
        color: motifBaseTheme.palette.text.primary,
        flex: 1,
        lineHeight: pxToRem(30),
      },
    },
  },
  MuiIconButton: {
    defaultProps: {
      color: 'secondary',
    },
    styleOverrides: {
      root: {
        padding: '6px',
        borderRadius: '4px',
        [`&.${iconButtonClasses.colorSecondary}`]: {
          color: motifBaseTheme.palette.text.primary,
        },
        [`&.${iconButtonClasses.colorSecondary}.Mui-disabled`]: {
          color: motifBaseTheme.palette.disabled.main,
        },
        '&:hover': {
          backgroundColor: motifBaseTheme.palette.secondary.light,
        },
      },
      colorLight: {
        '&:hover': {
          backgroundColor: 'rgb(77, 77, 77)',
        },
        '&.Mui-disabled': {
          color: motifBaseTheme.palette.disabled.main,
        },
      },
      sizeSmall: {
        [`& .${svgIconClasses.root}`]: {
          fontSize: '18px',
        },
      },
    },
  },
  // Known as UnderlinedInput in Motif
  MuiInput: {
    styleOverrides: {
      root: {
        [`&:not(.${inputClasses.error}, .${inputClasses.disabled}):before`]: {
          borderBottom: `${pxToRem(1)} solid ${motifBaseTheme.palette.secondary.main}`,
        },
        [`&.${inputClasses.disabled}:before`]: {
          borderBottomColor: motifBaseTheme.palette.disabled.main,
        },
        [`&:after, &:hover:not(.${inputClasses.error}, .${inputClasses.disabled}):before`]: {
          borderBottom: `${pxToRem(1)} solid ${motifBaseTheme.palette.primary.dark}`,
        },
        [`&.${inputClasses.error}:not(.${inputClasses.disabled}):before, &.${inputClasses.error}:not(.${inputClasses.disabled}):after`]:
          {
            borderBottom: `${pxToRem(1)} solid ${motifBaseTheme.palette.error.dark}`,
          },
        [`&.${inputClasses.focused}:not(.${inputClasses.error}):before, &.${inputClasses.focused}:not(.${inputClasses.error}):after`]:
          {
            borderBottom: `${pxToRem(1)} solid ${motifBaseTheme.palette.primary.main}`,
          },
      },
      input: {
        height: pxToRem(20),
        padding: `${pxToRem(8)} 0`,
        [`&.${inputClasses.disabled}`]: {
          TextFillColor: motifBaseTheme.palette.disabled.main,
          WebkitTextFillColor: motifBaseTheme.palette.disabled.main,
        },
      },
    },
  },
  MuiInputAdornment: {
    styleOverrides: {
      root: {
        margin: 'unset',
        padding: 'unset',
        '& svg': {
          fill: motifBaseTheme.palette.secondary.dark,
        },
        '& p': {
          color: motifBaseTheme.palette.secondary.dark,
        },
      },
      outlined: {
        [`&.${inputAdornmentClasses.positionStart}`]: {
          '& svg': { paddingLeft: pxToRem(4) },
          '& p': { paddingLeft: pxToRem(8) },
        },
        [`&.${inputAdornmentClasses.positionEnd}
        `]: {
          '& svg': { paddingRight: pxToRem(4) },
          '& p': { paddingRight: pxToRem(8) },
        },
      },
      standard: {
        [`&.${inputAdornmentClasses.positionStart}`]: {
          '& svg': { paddingRight: pxToRem(4) },
          '& p': { paddingRight: pxToRem(8) },
        },
        [`&.${inputAdornmentClasses.positionEnd}`]: {
          '& svg': { paddingLeft: pxToRem(4) },
          '& p': { paddingLeft: pxToRem(8) },
        },
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        width: pxToRem(250),
        ...motifBaseTheme.typography.body2,
        [`&, &.${inputBaseClasses.error}:not(.${inputBaseClasses.disabled})`]: {
          color: motifBaseTheme.palette.text.primary,
        },
        [`&.${inputBaseClasses.disabled}`]: {
          '&, & p': {
            color: motifBaseTheme.palette.disabled.main,
          },
          '& svg': {
            fill: motifBaseTheme.palette.disabled.main,
          },
        },
      },
      fullWidth: {
        width: 'unset',
        minWidth: 'auto',
      },
      multiline: {
        [`&.${inputBaseClasses.root}`]: {
          padding: 'unset',
        },
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        ...motifBaseTheme.typography.body2,
        lineHeight: pxToRem(20),
        [`&.${inputLabelClasses.disabled}:not(.${inputLabelClasses.error})`]: {
          color: motifBaseTheme.palette.disabled.main,
        },
        [`&.${inputLabelClasses.error}`]: {
          color: motifBaseTheme.palette.error.dark,
        },
        [`&, &.${inputLabelClasses.focused}:not(.${inputLabelClasses.error}):not(.${inputLabelClasses.disabled})`]:
          {
            color: motifBaseTheme.palette.secondary.main,
          },
      },
      outlined: {
        transform: `translate(${pxToRem(8)}, ${pxToRem(24)})`,
        [`&.${inputLabelClasses.shrink}`]: {
          transform: `translate(${pxToRem(8)}, -${pxToRem(4)})`,
        },
      },
      standard: {
        transform: `translate(0, ${pxToRem(24)})`,
        [`&.${inputLabelClasses.shrink}`]: {
          transform: 'translate(0)',
        },
      },
    },
  },
  MuiLink: {
    defaultProps: {
      underline: 'always',
    },
    styleOverrides: {
      root: {
        color: motifBaseTheme.palette.text.link,
        textDecorationColor: 'inherit',
        '&:hover': {
          color: motifBaseTheme.palette.text.hoverLink,
        },
        '&:visited': {
          color: motifBaseTheme.palette.text.visitedLink,
        },
      },
    },
  },
  MuiMenu: {
    defaultProps: {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
    },
    styleOverrides: {
      root: {
        [`& .${menuClasses.paper}`]: {
          ...motifBaseTheme.elevation1,
          [`& .${menuClasses.list}`]: {
            paddingTop: 0,
            paddingBottom: 0,
          },
        },
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        display: 'flex',
        gap: `${pxToRem(11)}`,
        color: motifBaseTheme.palette.text.primary,
        ...motifBaseTheme.typography.body2,
        paddingTop: `${pxToRem(10)}`,
        paddingBottom: `${pxToRem(10)}`,
        '&:hover, &.Mui-focusVisible, &.Mui-focusVisible:hover': {
          backgroundColor: motifBaseTheme.palette.secondary.light,
        },
        '&.Mui-selected': {
          backgroundColor: motifBaseTheme.palette.success['x-light'],
          [`& .${listItemIconClasses.root}`]: {
            color: motifBaseTheme.palette.success.dark,
          },
        },
        '&.Mui-selected:hover': {
          backgroundColor: motifBaseTheme.palette.success.light,
          [`& .${listItemIconClasses.root}`]: {
            color: motifBaseTheme.palette.success.main,
          },
        },
        '&.Mui-disabled': {
          color: motifBaseTheme.palette.disabled.main,
          [`& .${listItemIconClasses.root}`]: {
            color: motifBaseTheme.palette.disabled.main,
          },
        },
        [`& .${listItemIconClasses.root}`]: {
          minWidth: 'unset',
          color: motifBaseTheme.palette.secondary.main,
        },
        [`& .${listItemTextClasses.primary}, & .${listItemTextClasses.inset}, & .${listItemTextClasses.dense}, & .${listItemTextClasses.multiline}`]:
          {
            ...motifBaseTheme.typography.body2,
          },
      },
    },
  },
  MuiSnackbarContent: {
    ...SnackbarOverride,
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        padding: 'unset',
        'label + &': {
          marginTop: pxToRem(16),
        },
      },
      input: {
        height: pxToRem(20),
        padding: `${pxToRem(8)} ${pxToRem(8)}`,
        [`&.${outlinedInputClasses.disabled}`]: {
          TextFillColor: motifBaseTheme.palette.disabled.main,
          WebkitTextFillColor: motifBaseTheme.palette.disabled.main,
        },
      },
      notchedOutline: {
        borderColor: motifBaseTheme.palette.secondary.main,
        [`.${inputBaseClasses.root}.${outlinedInputClasses.root}.${outlinedInputClasses.disabled} > &`]:
          {
            borderColor: motifBaseTheme.palette.disabled.main,
          },
        [`.${inputBaseClasses.root}.${outlinedInputClasses.root}.${outlinedInputClasses.error}:not(.${outlinedInputClasses.disabled}) > &`]:
          {
            borderColor: motifBaseTheme.palette.error.dark,
          },
        [`.${inputBaseClasses.root}.${outlinedInputClasses.root}:not(.${outlinedInputClasses.error}):not(.${outlinedInputClasses.disabled}):not(.${outlinedInputClasses.focused}):hover > &`]:
          {
            borderColor: motifBaseTheme.palette.primary.dark,
          },
        [`.${inputBaseClasses.root}.${outlinedInputClasses.root}.${outlinedInputClasses.focused} &`]:
          {
            borderColor: motifBaseTheme.palette.primary.main,
            borderWidth: pxToRem(1),
          },
      },
    },
  },
  MuiRadio: {
    defaultProps: {
      color: 'default',
    },
    styleOverrides: {
      root: {
        padding: `${pxToRem(6)}`,
        color: motifBaseTheme.palette.secondary.main,
        transition: motifBaseTheme.transitions.common.enteringScreen,
        '&:hover': {
          backgroundColor: motifBaseTheme.palette.primary.hue[50],
        },
        '&.Mui-checked': {
          color: motifBaseTheme.palette.primary.dark,
        },
        '&.Mui-disabled': {
          color: motifBaseTheme.palette.disabled.main,
        },
      },
    },
  },
  MuiStep: {
    styleOverrides: {
      horizontal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
  },
  MuiStepper: {
    styleOverrides: {
      root: {
        padding: '24px 16px',
        [`&.${stepperClasses.vertical}`]: {
          [`& .${stepClasses.vertical}:not(:last-child) .${stepLabelClasses.vertical} .${stepLabelClasses.label} .${stepLabelClasses.labelContainer}:before,
          & .${stepClasses.vertical}:not(:last-child) > .${stepLabelClasses.vertical} > .${stepLabelClasses.labelContainer}:before`]:
            {
              content: '""',
              borderLeft: `${pxToRem(1)} solid ${motifBaseTheme.palette.disabled.main}`,
              height: '100%',
              position: 'absolute',
              left: `-${pxToRem(32)}`,
              top: `${pxToRem(40)}`,
            },
        },
        // there is no access to `.MuiStepLabel-optional` from `stepLabelClasses` :(
        [`& .${stepLabelClasses.label} + .MuiStepLabel-optional`]: {
          ...motifBaseTheme.typography.body3,
        },
      },
      vertical: {
        boxSizing: 'border-box',
      },
    },
  },
  MuiStepLabel: {
    styleOverrides: {
      root: {
        [`&.${stepLabelClasses.vertical}`]: {
          alignItems: 'flex-start',
          [`& .${stepLabelClasses.label}`]: {
            marginTop: '6px',
          },
          [`& .${stepLabelClasses.labelContainer}`]: {
            position: 'relative',
          },
        },
        [`& .${stepIconClasses.root}`]: {
          '&.Mui-active': {
            color: motifBaseTheme.palette.common.white,
            borderColor: motifBaseTheme.palette.primary.main,
            backgroundColor: motifBaseTheme.palette.primary.main,
            '&.Mui-error': {
              borderColor: motifBaseTheme.palette.error.main,
              backgroundColor: motifBaseTheme.palette.error.main,
            },
            '&.Mui-warning': {
              borderColor: motifBaseTheme.palette.pending.main,
              backgroundColor: motifBaseTheme.palette.pending.main,
            },
            '&.Mui-canceled': {
              borderColor: motifBaseTheme.palette.disabled.main,
              backgroundColor: motifBaseTheme.palette.disabled.main,
            },
          },
          '&.Mui-completed': {
            backgroundColor: motifBaseTheme.palette.common.white,
            borderColor: motifBaseTheme.palette.primary.main,
            color: motifBaseTheme.palette.primary.main,
            fill: motifBaseTheme.palette.primary.main,
            '&.Mui-error': {
              borderColor: motifBaseTheme.palette.error.main,
              color: motifBaseTheme.palette.error.main,
              fill: motifBaseTheme.palette.error.main,
            },
            '&.Mui-warning': {
              borderColor: motifBaseTheme.palette.pending.main,
              color: motifBaseTheme.palette.pending.main,
              fill: motifBaseTheme.palette.pending.main,
            },
            '&.Mui-canceled': {
              borderColor: motifBaseTheme.palette.disabled.main,
              color: motifBaseTheme.palette.disabled.main,
              fill: motifBaseTheme.palette.disabled.main,
            },
          },
        },
      },
      iconContainer: {
        [`& .${stepIconClasses.root}`]: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: STEP_ICON_SIZE,
          width: STEP_ICON_SIZE,
          border: `2px solid ${motifBaseTheme.palette.secondary.main}`,
          borderRadius: '50%',
          color: motifBaseTheme.palette.secondary.main,
          backgroundColor: 'transparent',
          fontSize: `${pxToRem(14)}`,
          fontWeight: 600,
          padding: `${pxToRem(2)}`,
          boxSizing: 'border-box',
        },
      },
      labelContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        [`& .${stepLabelClasses.label}`]: {
          color: motifBaseTheme.palette.text.primary,
        },
        '& .MuiStepLabel-optional': {
          color: motifBaseTheme.palette.secondary.dark,
        },
      },
      label: {
        '&.Mui-active': {
          fontWeight: motifBaseTheme.typography.fontWeightBold,
        },
        '&.Mui-error, &.Mui-completed': {
          color: motifBaseTheme.palette.text.primary,
        },
      },
      vertical: {
        [`& .${stepLabelClasses.iconContainer}`]: {
          paddingRight: `calc(${STEP_ICON_SIZE} / 2)`,
        },
        [`& .${stepLabelClasses.labelContainer}`]: {
          alignItems: 'flex-start',
        },
      },
    },
  },
  MuiStepConnector: {
    styleOverrides: {
      alternativeLabel: {
        top: `calc(${STEP_ICON_SIZE} / 2)`,
        left: `calc(-50% + ${STEP_ICON_SIZE})`,
        right: `calc(50% + ${STEP_ICON_SIZE})`,
        color: `${motifBaseTheme.palette.secondary.main}`,
      },
      vertical: {
        marginLeft: `calc(${STEP_ICON_SIZE} / 2)`,
      },
      lineVertical: {
        minHeight: '32px',
      },
      line: {
        borderLeftWidth: 0,
      },
    },
  },
  MuiSwitch: {
    defaultProps: {
      color: 'default',
    },
    styleOverrides: {
      sizeMedium: {
        height: `${pxToRem(36)}`,
        width: `${pxToRem(56)}`,
        padding: `${pxToRem(10)}`,
        paddingTop: `${pxToRem(11)}`,
        [`& .${buttonBaseClasses.root}.${switchClasses.switchBase}`]: {
          padding: `${pxToRem(6)}`,
        },
        [`& .${switchClasses.thumb}`]: {
          margin: `${pxToRem(2)}`,
        },
      },
      switchBase: {
        [`&.${buttonBaseClasses.root}`]: {
          color: motifBaseTheme.palette.secondary.light,
          '&:hover': {
            backgroundColor: motifBaseTheme.palette.primary.hover,
          },
          // checked styles
          '&.Mui-checked': {
            [`& .${switchClasses.thumb}`]: {
              backgroundColor: motifBaseTheme.palette.primary.dark,
            },
            [`& + .${switchClasses.track}`]: {
              opacity: 1,
              backgroundColor: motifBaseTheme.palette.primary.light,
            },
          },
          // disabled styles
          '&.Mui-disabled': {
            [`& .${switchClasses.thumb}`]: {
              backgroundColor: motifBaseTheme.palette.disabled.light,
            },
            [`& + .${switchClasses.track}`]: {
              opacity: 1,
              backgroundColor: motifBaseTheme.palette.disabled.main,
            },
          },
        },
      },
      thumb: {
        boxShadow: motifBaseTheme.shadows[2],
      },
      track: {
        opacity: 1,
        backgroundColor: motifBaseTheme.palette.secondary.main,
        borderRadius: `${pxToRem(10)}`,
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        ...motifBaseTheme.typography.body2,
        color: motifBaseTheme.palette.secondary.main,
        textTransform: 'none',
        transition: motifBaseTheme.transitions.common.enteringScreen,
        padding: `${pxToRem(14)} ${pxToRem(16)}`,
        '&:hover, &.Mui-focus, &.Mui-focusVisible': {
          backgroundColor: motifBaseTheme.palette.success['x-light'],
        },
        '&.Mui-selected': {
          color: motifBaseTheme.palette.text.primary,
        },
        '&.Mui-disabled': {
          color: motifBaseTheme.palette.disabled.main,
        },
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      root: {
        alignItems: 'center',
      },
      indicator: {
        backgroundColor: motifBaseTheme.palette.primary.main,
      },
    },
  },
  MuiToggleButtonGroup: {
    defaultProps: {
      color: 'primary',
    },
    styleOverrides: {
      root: {
        [`& .${toggleButtonGroupClasses.grouped}:not(:first-of-type):not(.${toggleButtonGroupClasses.disabled})`]:
          {
            borderLeft: `${pxToRem(1)} solid ${motifBaseTheme.palette.common.black}`,
          },
      },
    },
  },
  MuiToggleButton: {
    defaultProps: {
      color: 'primary',
    },
    styleOverrides: {
      root: {
        ...motifBaseTheme.typography.subtitle2,
        textTransform: 'capitalize',
        minHeight: pxToRem(36),
        padding: `${pxToRem(5)} ${pxToRem(16)} ${pxToRem(4)}`,
        '&:hover': {
          backgroundColor: motifBaseTheme.palette.secondary.light,
        },
        '&:active': {
          backgroundColor: motifBaseTheme.palette.secondary.active,
        },
        [`&, &.${toggleButtonClasses.selected}`]: {
          color: motifBaseTheme.palette.text.primary,
          borderColor: motifBaseTheme.palette.common.black,
        },
        [`&.${toggleButtonClasses.disabled}`]: {
          color: motifBaseTheme.palette.disabled.main,
          borderColor: motifBaseTheme.palette.disabled.light,
        },
        [`&.${toggleButtonClasses.primary}.${toggleButtonClasses.selected}`]: {
          backgroundColor: motifBaseTheme.palette.primary.light,
          '&:hover': {
            backgroundColor: motifBaseTheme.palette.primary.main,
          },
          '&:active': {
            backgroundColor: motifBaseTheme.palette.primary.active,
          },
        },
      },
    },
  },
  MuiTooltip: {
    defaultProps: {
      placement: 'top',
      size: 'small',
      arrow: true,
    },
    styleOverrides: {
      tooltip: ({ ownerState }) => ({
        ...motifBaseTheme.typography.body2,
        backgroundColor: motifBaseTheme.palette.common.black,
        overflowWrap: 'break-word',
        maxWidth: `${pxToRem(340)}`,
        borderRadius: 'unset',
        padding: ownerState.size === 'medium' ? `${pxToRem(16)}` : `${pxToRem(8)} ${pxToRem(16)}`,
      }),
      arrow: {
        color: motifBaseTheme.palette.common.black,
      },
    },
  },
};
