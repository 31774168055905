import { IAM } from 'redux/actions/Types';


const initialState = {
  accountDetail: {
    meta: {},
    account: {},
  },
  accountList: [],
  demoAccountList: [],
  organizationList: [],
  accountAdmins: [],
  organizationDetail: {
    meta: {},
    organization: {},
  },
  organizationProducts: [],
  roleDetail: { meta: {} },
  userDetail: { meta: {}, user: {} },
  userList: [],
};

const iamReducer = (state = initialState, action) => {
  switch (action.type) {
    case IAM.CONFIRM_ACCOUNT_CREATION_SUCCESS:
      return {
        ...state,
        accountDetail: {
          account: action.payload,
          meta: { redirect: true },
        },
      };

    case IAM.CONFIRM_ACCOUNT_CREATION_FAILURE:
      return {
        ...state,
        accountDetail: {
          ...state.accountDetail,
          meta: { redirect: false },
        },
      };

    case IAM.CREATE_ACCOUNT_ADMINISTRATOR_SUCCESS:
      return {
        ...state,
        accountDetail: {
          ...state.accountDetail,
        },
        meta: { administratorCreated: true },
      };

    case IAM.RECEIVE_DEMO_ACCOUNT_LIST_SUCCESS:
      return {
        ...state,
        demoAccountList: action.payload,
        accountDetail: { meta: {}, account: {} },
      };

    case IAM.FETCH_ACCOUNT_SUCCESS:
      return { ...state, accountDetail: { meta: {}, account: action.payload } };
    case IAM.RECEIVE_ACCOUNT_LIST_SUCCESS:
      return {
        ...state,
        accountList: action.payload,
        accountDetail: { meta: {}, account: {} },
      };

    case IAM.FETCH_ORGANIZATION_SUCCESS:
      return {
        ...state,
        organizationDetail: { meta: {}, organization: action.payload },
      };

    case IAM.FETCH_ORGANIZATION_LIST:
      return {
        ...state,
        organizationList: [],
        organizationDetail: { meta: {}, organization: {} },
        orgListMeta: { loading: true },
      };

    case IAM.RECEIVE_ORGANIZATION_LIST_SUCCESS:
      return {
        ...state,
        organizationList: action.payload,
        organizationDetail: { meta: {}, organization: {} },
        orgListMeta: {},
      };

    case IAM.RECEIVE_ORGANIZATION_LIST_FAILURE:
      return {
        ...state,
        organizationList: { error: action.payload },
        organizationDetail: { meta: {}, organization: {} },
        orgListMeta: {},
      };

    case IAM.CONFIRM_ORGANIZATION_CREATION_SUCCESS:
      return {
        ...state,
        organizationDetail: {
          ...action.payload,
          meta: { message: `Organization: ${action.payload.name} Created`, redirect: true },
        },
      };

    case IAM.CONFIRM_ORGANIZATION_CREATION_FAILURE:
      return {
        ...state,
        organizationDetail: {
          ...state.organizationDetail,
          meta: { message: action.payload.error, redirect: false },
        },
      };

    case IAM.RECEIVE_ROLE_LIST_SUCCESS:
      return { ...state, roleList: action.payload };

    case IAM.RECEIVE_ROLE_LIST_ERROR:
      return { ...state, roleList: { error: action.payload } };

    case IAM.RECEIVE_ROLE_SUCCESS:
      return { ...state, roleDetail: { ...action.payload, meta: {} } };

    case IAM.CONFIRM_ROLE_CREATION_SUCCESS:
      return {
        ...state,
        roleDetail: {
          ...action.payload,
          meta: { redirect: true },
        },
      };

    case IAM.CONFIRM_ROLE_CREATION_FAILURE:
      return {
        ...state,
        roleDetail: {
          ...state.roleDetail,
          meta: { redirect: false },
        },
      };

    case IAM.CONFIRM_ROLE_UPDATION_SUCCESS:
      return {
        ...state,
        roleDetail: {
          ...action.payload,
          meta: { redirect: true },
        },
      };

    case IAM.CONFIRM_ROLE_UPDATION_FAILURE:
      return {
        ...state,
        roleDetail: {
          ...state.roleDetail,
          meta: { redirect: false },
        },
      };

    case IAM.FETCH_USER_LIST:
      return { ...state, meta: { } };

    case IAM.FETCH_USER:
      return { ...state, meta: { } };

    case IAM.RECEIVE_USER_LIST_SUCCESS:
      return { ...state, userList: action.payload };

    case IAM.RECEIVE_USER_LIST_ERROR:
      return { ...state, userList: { error: action.payload } };

    case IAM.RECEIVE_USER:
      return { ...state, userDetail: { ...action.payload, meta: {} } };

    case IAM.FETCH_ADMIN:
      return { ...state, meta: { } };

    case IAM.RECEIVE_ADMIN:
      return { ...state, userDetail: { ...action.payload, meta: {} } };

    case IAM.RESET_USER:
      return { ...state, userDetail: { ...action.payload, meta: {} } };

    case IAM.CONFIRM_USER_CREATION_SUCCESS:
      return {
        ...state,
        userDetail: {
          ...action.payload,
          meta: { redirect: true },
        },
      };

    case IAM.CONFIRM_USER_CREATION_FAILURE:
      return {
        ...state,
        userDetail: {
          ...state.userDetail,
          meta: { redirect: false },
        },
      };

    case IAM.CONFIRM_USER_UPDATION_SUCCESS:
      return {
        ...state,
        userDetail: {
          ...action.payload,
          meta: { redirect: true },
        },
      };

    case IAM.CONFIRM_USER_UPDATION_FAILURE:
      return {
        ...state,
        userDetail: {
          ...state.userDetail,
          meta: { redirect: false },
        },
      };

    case IAM.CONFIRM_ADMIN_UPDATION_SUCCESS:
      return {
        ...state,
        userDetail: {
          ...action.payload,
        },
        meta: { redirect: true },
      };

    case IAM.CONFIRM_ADMIN_UPDATION_ERROR:
      return {
        ...state,
        userDetail: {
          ...state.userDetail,
        },
        meta: { redirect: false },
      };

    case IAM.DELETE_USER:
      return { ...state, meta: { deleteUserLoading: true } };

    case IAM.DELETE_USER_SUCCESS:
      return { ...state, meta: { deleted: true, deleteUserLoading: false } };

    case IAM.DELETE_USER_RESET:
      return { ...state, meta: { } };

    case IAM.UPDATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        accountDetail: {
          account: { ...action.payload },
          meta: { redirect: true },
        },
      };

    case IAM.UPDATE_ORGANIZATION_SUCCESS:
      return {
        ...state,
        organizationDetail: {
          ...action.payload,
          meta: { redirect: true },
        },
      };

    case IAM.RECEIVE_PRODUCT_LIST_SUCCESS:
      return { ...state, productList: action.payload };

    case IAM.RECEIVE_ACCOUNT_PRODUCTS_SUCCESS:
      return { ...state, accountProducts: action.payload };

    case IAM.RECEIVE_ORGANIZATION_PRODUCTS_SUCCESS:
      return { ...state, organizationProducts: action.payload };

    case IAM.UPDATE_ACCOUNT_PRODUCTS_RESET:
      return { ...state, accountProductsUpdated: false };

    case IAM.UPDATE_ACCOUNT_PRODUCTS_SUCCESS:
      return { ...state, accountProducts: action.payload, accountProductsUpdated: true };

    case IAM.FETCH_ACCOUNT_ADMIN_LIST:
      return { ...state, meta: { } };

    case IAM.FETCH_ACCOUNT_ADMIN_LIST_SUCCESS:
      return { ...state, accountAdmins: action.payload };
    default:
      return state;
  }
};

export default iamReducer;
