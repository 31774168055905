import { styled } from '@mui/material/styles';


export const StyledAside = styled('aside')(() => ({
  borderRight: '1px solid #eee',
  padding: '15px 10px',
  fontSize: '12px',
  background: '#fcfcfc',
  position: 'relative',
}));
