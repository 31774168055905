import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';


export const LINKEDIN_AUTH_CHANNEL = 'linkedinAuthChannel';

const LinkedInAuth = () => {
  const location = useLocation();
  const query = useMemo(() => new URLSearchParams(location.search),
    [location.search]);
  const code = query.get('code');
  const state = query.get('state');
  const data = { code, state };

  const channel = new BroadcastChannel(LINKEDIN_AUTH_CHANNEL);
  channel.postMessage(data);
  window.close();
};

export default LinkedInAuth;
