import React from 'react';
import Skeleton from '@mui/material/Skeleton';


const PageSkeleton = () => (
  <>
    <Skeleton width={850} height={50} />
    <Skeleton width={750} height={50} />
    <Skeleton width={650} height={50} />
  </>
);

export default PageSkeleton;
