import React, { memo, useContext } from 'react';
import { DataContext } from 'BaseComponents/HDataTable/DataContextProvider';
import { HBodyRow } from 'BaseComponents/HDataTable/HBodyRow';
import { HHeadRow } from 'BaseComponents/HDataTable/HHeadRow';
import { ResizableContext } from 'BaseComponents/HDataTable/ResizableContextProvider';
import { habuColors } from 'Theme';
import { styled } from '@mui/material/styles';
import { v4 as uuidGen } from 'uuid';


export const CellShadowStyles = {
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  boxShadow: '-5px 0px 10px 0px rgba(0, 0, 0, 0.09)',
};

const StyledDiv = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(3),
  borderRadius: theme.spacing(0.75),
  background: '#fff',
  width: '100%',

  '& table': {
    width: 'max-content',
    minWidth: '100%',
    borderCollapse: 'collapse',
  },

  '& th': {
    position: 'relative',
    textAlign: 'left',
    padding: theme.spacing(1),
  },

  '& td': {
    textAlign: 'left',
    padding: theme.spacing(1),
    fontSize: theme.typography.body2.fontSize,
    lineHeight: '20px',

    '& .MuiButtonBase-root': {
      height: '28px',
    },

    '& .MuiButton-root': {
      minWidth: 'unset',
      paddingLeft: '5px',
      paddingRight: '5px',
    },

    '& .MuiIconButton-root': {
      width: '28px',
    },

    '& .MuiTypography-root': {
      fontSize: theme.typography.body2.fontSize,
      lineHeight: '20px',
    },

    '& .MuiSwitch-sizeSmall  .MuiButtonBase-root': {
      height: 24,
    },

    '& img': {
      height: '28px',
    },
  },

  '& tbody th': {
    borderTop: '1px solid #ccc',
    backgroundColor: '#fff',
  },

  '& tr': {
    borderTop: '1px solid #ccc',
    borderBottom: '1px solid #ccc',
  },

  '& tbody > tr:hover': {
    backgroundColor: 'rgb(245 245 245)',
  },

  '& thead tr': {
    borderTopWidth: 0,

    '& th': {
      fontSize: theme.typography.h6.fontSize,
      fontWeight: theme.typography.fontWeightBold,
      lineHeight: '20px',

      '& .text': {
        color: habuColors.neutral[60],
      },
    },
  },

  '&::-webkit-scrollbar': {
    WebkitAppearance: 'none',
    width: 7,
  },

  '&::-webkit-scrollbar-thumb': {
    borderRadius: 7,
    backgroundColor: 'rgba(0,0,0,.25)',
    WebkitBoxShadow: '0 0 1px rgba(255,255,255,.25)',
    boxSizing: 'border-box',
    borderTopWidth: 4,
    borderBottomWidth: 4,
    borderStyle: 'solid',
    borderColor: '#FFFFFF',
  },

  '& .shadow': {
    ...CellShadowStyles,
  },
}));

export const HTable = memo(() => {
  const { rows } = useContext(DataContext);
  const { tableElement } = useContext(ResizableContext);

  return (
    <StyledDiv>
      <table className='resizeable-table' ref={tableElement}>
        <thead>
          <HHeadRow />
        </thead>

        <tbody>
          {(rows ?? []).map((row) => <HBodyRow row={row} key={`body-row-${row.ID ?? uuidGen()}`} />)}
        </tbody>
      </table>
    </StyledDiv>
  );
});

HTable.displayName = 'HTable';
