import * as actions from 'redux/actions/PermissionActions';
import PermissionWizardForm from 'pages/InternalAdmin/application/pages/PermissionWizard/PermissionWizardForm';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';


const PermissionWizard = () => {
  const dispatch = useDispatch();
  const permissionDetails = useSelector(state => state.permission.permissionDetail);
  const submitPermission = permission => dispatch(actions.createPermission(permission));


  return (
    <PermissionWizardForm
      permissionDetails={permissionDetails}
      handlers={{ submitPermission }}
    />
  );
};

export default PermissionWizard;
