import { activeOrgSelector } from 'redux/reducer/AuthenticationReducer';
import { axiosInstance } from 'utils/http';
import { dataCredential } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';


export const useFetchIdentityCredentials = () => {
  const dispatch = useDispatch();
  const { ID: orgId } = useSelector(activeOrgSelector);

  return useQuery(
    ['identityCredentials', orgId],
    () => axiosInstance.get(dataCredential(orgId)
      .listOrgCredentials({
        credentialSourceName: 'Identity Resolution RampID',
        includeCredential: true,
      })),
    {
      select: (res) => res?.data?.credentials ?? {},
      onError: () => {
        dispatch(showAlert({
          message: 'Failed to fetch identity credentials',
          type: 'error',
        }));
      },
    },
  );
};
