export const DATA_IN = {
  // data source
  FETCH_DATA_IN_JOBS: 'FETCH_DATA_IN_JOBS',
  FETCH_DATA_IN_JOBS_ERROR: 'FETCH_DATA_IN_JOBS_ERROR',
  FETCH_DATA_IN_JOBS_SUCCESS: 'FETCH_DATA_IN_JOBS_SUCCESS',
  FETCH_DATA_SOURCE: 'FETCH_DATA_SOURCE',
  FETCH_DATA_SOURCE_SUCCESS: 'FETCH_DATA_SOURCE_SUCCESS',
  FETCH_DATA_SOURCE_FAILURE: 'FETCH_DATA_SOURCE_FAILURE',
  RESET_DATA_IN_JOB: 'RESET_DATA_IN_JOB',
  JOB_SAVE: 'JOB_SAVE',
  JOB_SAVE_SUCCESS: 'JOB_SAVE_SUCCESS',
  JOB_SAVE_FAILURE: 'JOB_SAVE_FAILURE',
  DATA_IN_CHANGE_JOB_STATUS: 'DATA_IN_CHANGE_JOB_STATUS',
  DATA_IN_CHANGE_JOB_STATUS_ERROR: 'DATA_IN_CHANGE_JOB_STATUS_ERROR',
  // data source - job
  FETCH_DATA_IN_DASHBOARD: 'FETCH_DATA_IN_DASHBOARD',
  RECEIVE_DATA_IN_DASHBOARD: 'RECEIVE_DATA_IN_DASHBOARD',
  FETCH_DATA_IN_DETAILS: 'FETCH_DATA_IN_DETAILS',
  FETCH_DATA_IN_DETAILS_SUCCESS: 'FETCH_DATA_IN_DETAILS_SUCCESS',
  FETCH_DATA_IN_DETAILS_ERROR: 'FETCH_DATA_IN_DETAILS_ERROR',
  FETCH_DATA_IN_FILTERS: 'FETCH_DATA_IN_FILTERS',
  FETCH_DATA_IN_FILTERS_SUCCESS: 'FETCH_DATA_IN_FILTERS_SUCCESS',
  FETCH_DATA_IN_FILTERS_ERROR: 'FETCH_DATA_IN_FILTERS_ERROR',
  FETCH_DATA_IN_JOB_SUMMARY: 'FETCH_DATA_IN_JOB_SUMMARY',
  FETCH_DATA_IN_JOB_SUMMARY_SUCCESS: 'FETCH_DATA_IN_JOB_SUMMARY_SUCCESS',
  FETCH_DATA_IN_JOB_SUMMARY_ERROR: 'FETCH_DATA_IN_JOB_SUMMARY_ERROR',
  FETCH_DATA_SOURCES: 'FETCH_DATA_SOURCES',
  RECEIVE_DATA_SOURCES_SUCCESS: 'RECEIVE_DATA_SOURCES',
  RECEIVE_DATA_SOURCES_ERROR: 'RECEIVE_DATA_SOURCES_ERROR',
  FETCH_DATA_SOURCE_JOBS: 'FETCH_DATA_SOURCE_JOBS',
  FETCH_DATA_SOURCE_JOBS_SUCCESS: 'FETCH_DATA_SOURCE_JOBS_SUCCESS',
  FETCH_DATA_SOURCE_JOBS_ERROR: 'FETCH_DATA_SOURCE_JOBS_ERROR',
  FETCH_DATA_SOURCE_PARAMETERS: 'FETCH_DATA_SOURCE_PARAMETERS',
  FETCH_DATA_SOURCE_PARAMETERS_SUCCESS: 'FETCH_DATA_SOURCE_PARAMETERS_SUCCESS',
  FETCH_DATA_SOURCE_PARAMETERS_FAILURE: 'FETCH_DATA_SOURCE_PARAMETERS_FAILURE',
  FETCH_DATA_SOURCE_DEPENDENT_PARAMETERS: 'FETCH_DATA_SOURCE_DEPENDENT_PARAMETERS',
  FETCH_DATA_SOURCE_DEPENDENT_PARAMETERS_SUCCESS: 'FETCH_DATA_SOURCE_DEPENDENT_PARAMETERS_SUCCESS',
  FETCH_DATA_SOURCE_DEPENDENT_PARAMETERS_FAILURE: 'FETCH_DATA_SOURCE_DEPENDENT_PARAMETERS_FAILURE',
  FETCH_JOB_PARAMETERS: 'FETCH_JOB_PARAMETERS',
  FETCH_JOB_PARAMETERS_SUCCESS: 'FETCH_JOB_PARAMETERS_SUCCESS',
  FETCH_JOB_PARAMETERS_ERROR: 'FETCH_JOB_PARAMETERS_ERROR',
  FETCH_DATA_SET_TYPE_LIST: 'FETCH_DATA_SET_TYPE_LIST',
  RECEIVE_DATA_SET_TYPE_LIST: 'RECEIVE_DATA_SET_TYPE_LIST',
  RECEIVE_IDENTIFIER_TYPE_LIST: 'RECEIVE_IDENTIFIER_TYPE_LIST',
  FETCH_ID_KEY_TYPE_LIST: 'FETCH_ID_KEY_TYPE_LIST',
  RECEIVE_ID_KEY_TYPE_LIST: 'RECEIVE_ID_KEY_TYPE_LIST',
  FETCH_DEVICE_TYPE_LIST: 'FETCH_DEVICE_TYPE_LIST',
  RECEIVE_DEVICE_TYPE_LIST: 'RECEIVE_DEVICE_TYPE_LIST',
  UPDATE_JOB_PARAMETERS: 'UPDATE_JOB_PARAMETERS',
  UPDATE_JOB_PARAMETERS_SUCCESS: 'UPDATE_JOB_PARAMETERS_SUCCESS',
  UPDATE_JOB_PARAMETERS_FAILURE: 'UPDATE_JOB_PARAMETERS_FAILURE',
  SUBMIT_DATA_IN_FIELD_MAPPING: 'SUBMIT_DATA_IN_FIELD_MAPPING',
  SUBMIT_DATA_IN_FIELD_MAPPING_SUCCESS: 'SUBMIT_DATA_IN_FIELD_MAPPING_SUCCESS',
  SUBMIT_DATA_IN_FIELD_MAPPING_FAILURE: 'SUBMIT_DATA_IN_FIELD_MAPPING_FAILURE',
  SUBMIT_DATA_IN_FIELD_MAPPING_ERROR: 'SUBMIT_DATA_IN_FIELD_MAPPING_ERROR',
  FETCH_FIELD_MAPPING_LIST: 'FETCH_FIELD_MAPPING_LIST',
  FETCH_FIELD_MAPPING_LIST_SUCCESS: 'FETCH_FIELD_MAPPING_LIST_SUCCESS',
  FETCH_FIELD_MAPPING_LIST_FAILURE: 'FETCH_FIELD_MAPPING_LIST_FAILURE',
  FETCH_ATTRIBUTE_CATEGORY_LIST: 'FETCH_ATTRIBUTE_CATEGORY_LIST',
  RECEIVE_ATTRIBUTE_CATEGORY_LIST: 'RECEIVE_ATTRIBUTE_CATEGORY_LIST',
  FETCH_DATA_IN_JOB_CONSENT: 'FETCH_DATA_IN_JOB_CONSENT',
  RECEIVE_DATA_IN_JOB_CONSENT: 'RECEIVE_DATA_IN_JOB_CONSENT',
  CREATE_DATA_IN_JOB_CONSENT: 'CREATE_DATA_IN_JOB_CONSENT',
  SUBMIT_DATA_IN_JOB_CONSENT_SUCCESS: 'SUBMIT_DATA_IN_JOB_CONSENT_SUCCESS',
  SUBMIT_DATA_IN_JOB_CONSENT_ERROR: 'SUBMIT_DATA_IN_JOB_CONSENT_ERROR',
  UPDATE_JOB_STATUS: 'UPDATE_JOB_STATUS',
  CHANGE_JOB_STAGE: 'CHANGE_JOB_STAGE',
  CHANGE_JOB_STAGE_SUCCESS: 'CHANGE_JOB_STAGE_SUCCESS',
  CHANGE_JOB_STAGE_ERROR: 'CHANGE_JOB_STAGE_ERROR',
  SUBMIT_JOB_STATUS_SUCCESS: 'SUBMIT_JOB_STATUS_SUCCESS',
  SUBMIT_JOB_STATUS_ERROR: 'SUBMIT_JOB_STATUS_ERROR',
  // Data Connections Job Summary
  FETCH_DATA_IN_JOBS_SUMMARY: 'FETCH_DATA_IN_JOBS_SUMMARY',
  FETCH_DATA_IN_JOBS_SUMMARY_SUCCESS: 'FETCH_DATA_IN_JOBS_SUMMARY_SUCCESS',
  FETCH_DATA_IN_JOBS_SUMMARY_ERROR: 'FETCH_DATA_IN_JOBS_SUMMARY_ERROR',

  // Data Connections Job Stats
  FETCH_DATA_IN_JOB_STATS: 'FETCH_DATA_IN_JOB_STATS',
  FETCH_DATA_IN_JOB_STATS_SUCCESS: 'FETCH_DATA_IN_JOB_STATS_SUCCESS',
  FETCH_DATA_IN_JOB_STATS_ERROR: 'FETCH_DATA_IN_JOB_STATS_ERROR',

  // Charts
  FETCH_DATA_IN_JOB_TREND: 'FETCH_DATA_IN_JOB_TREND',
  FETCH_DATA_IN_JOB_TREND_SUCCESS: 'FETCH_DATA_IN_JOB_TREND_SUCCESS',
  FETCH_DATA_IN_JOB_TREND_ERROR: 'FETCH_DATA_IN_JOB_TREND_ERROR',
  // People/Households trend chart
  FETCH_DATA_IN_PEOPLE_HOUSEHOLDS: 'FETCH_DATA_IN_PEOPLE_HOUSEHOLDS',
  FETCH_DATA_IN_PEOPLE_HOUSEHOLDS_SUCCESS: 'FETCH_DATA_IN_PEOPLE_HOUSEHOLDS_SUCCESS',
  FETCH_DATA_IN_PEOPLE_HOUSEHOLDS_ERROR: 'FETCH_DATA_IN_PEOPLE_HOUSEHOLDS_ERROR',
};
