import React, { createContext, useRef, useState } from 'react';
import { useCreateFlow } from 'api/flows';


export const FlowContext = createContext({});

export const FlowContextProvider = ({ children }) => {
  const dataContextRef = useRef();
  const [open, setOpen] = useState(false);
  const [advancedSetting, setAdvancedSetting] = useState(null);


  const {
    isLoading: isLoadingCreateFlow,
    mutateAsync: mutateAsyncCreatFlow,
  } = useCreateFlow();

  const handleCreateFlow = async () => {
    setOpen(true);
  };

  const handleAdvancedSettingOpen = (flowEntity) => {
    setAdvancedSetting(flowEntity);
  };

  const handleAdvancedSettingClose = () => {
    setAdvancedSetting(null);
  };

  const FlowContextValue = {
    createNewFlowDialog: open,
    setCreateNewFlowDialog: setOpen,
    dataContextRef,
    handleCreateFlow,
    isLoadingCreateFlow,
    mutateAsyncCreatFlow,
    advancedSetting,
    handleAdvancedSettingOpen,
    handleAdvancedSettingClose,
  };
  return (
    <FlowContext.Provider value={FlowContextValue}>
      {children}
    </FlowContext.Provider>
  );
};
