import * as actions from 'pages/InternalAdmin/application/CleanRoom/redux/actions';
import Logger from 'components/Common/Logger';
import http from 'utils/http';
import { CLEAN_ROOM_ADMIN } from 'redux/actions/Types';
import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import { showAlert } from 'redux/actions/AlertActions';


function* createCrQuestion(action) {
  const { question, error } = yield call(http.post, action.url, action.payload);
  if (error) {
    Logger.error(error, `Could not create fva question. Url: ${action.url}. Error: ${error}`);
    yield put(showAlert({ message: error, type: 'error' }));
    return yield put(actions.createCrQuestionError(error));
  }
  yield put(showAlert({ message: 'Question created successfully', type: 'success' }));
  return yield put(actions.createCrQuestionSuccess(question));
}

function* fetchCrAllQuestions(action) {
  const { questions, error } = yield call(http.get, action.url);
  if (error) {
    Logger.error(error, `Could not fetch all fva questions. Url: ${action.url}. Error: ${error}`);
    return yield put(actions.fetchCrAllQuestionsError(error));
  }
  else {
    return yield put(actions.fetchCrAllQuestionsSuccess(questions));
  }
}

function* fetchAllImportDataTypes(action) {
  const { dataTypes, error } = yield call(http.get, action.url);
  if (error) {
    Logger.error(error, `Could not fetch all import data types. Url: ${action.url}. Error: ${error}`);
    return yield put(actions.fetchAllImportDataTypesError(error));
  }
  else {
    return yield put(actions.fetchAllImportDataTypesSuccess(dataTypes));
  }
}

function* fetchAllStorageProviders(action) {
  const { storageProviders, error } = yield call(http.get, action.url);
  if (error) {
    Logger.error(error, `Could not fetch all storage providers. Url: ${action.url}. Error: ${error}`);
    return yield put(actions.fetchAllStorageProvidersError(error));
  }
  else {
    return yield put(actions.fetchAllStorageProvidersSuccess(storageProviders));
  }
}

function* fetchQuestion(action) {
  const { question, error } = yield call(http.get, action.url);
  if (error) {
    Logger.error(error, `Could not fetch question. Url: ${action.url}. Error: ${error}`);
    yield put(showAlert({ message: error, type: 'error' }));
    return yield put(actions.fetchQuestionError(error));
  }
  return yield put(actions.fetchQuestionSuccess(question));
}

function* updateCRQuestion(action) {
  const { question, error } = yield call(http.put, action.url, action.payload);
  if (error) {
    Logger.error(error, `Could not update fva question. Url: ${action.url}. Error: ${error}`);
    yield put(showAlert({ message: error, type: 'error' }));
    return yield put(actions.updateCrQuestionSuccess(error));
  }
  yield put(showAlert({ message: 'Question updated successfully', type: 'success' }));
  return yield put(actions.updateCrQuestionSuccess(question));
}

export default function* root() {
  yield all([
    takeLatest(CLEAN_ROOM_ADMIN.CREATE_CR_QUESTION, createCrQuestion),
    takeLatest(CLEAN_ROOM_ADMIN.FETCH_ALL_QUESTIONS, fetchCrAllQuestions),
    takeLatest(CLEAN_ROOM_ADMIN.FETCH_ALL_IMPORT_DATA_TYPES, fetchAllImportDataTypes),
    takeLatest(CLEAN_ROOM_ADMIN.FETCH_ALL_STORAGE_PROVIDERS, fetchAllStorageProviders),
    takeLatest(CLEAN_ROOM_ADMIN.FETCH_QUESTION, fetchQuestion),
    takeLatest(CLEAN_ROOM_ADMIN.UPDATE_CR_QUESTION, updateCRQuestion),
  ]);
}

export {
  createCrQuestion,
  fetchAllImportDataTypes,
  fetchAllStorageProviders,
  fetchCrAllQuestions,
  fetchQuestion,
  updateCRQuestion,
};
