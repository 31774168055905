import { Chip } from '@mui/material';
import { habuColors } from 'Theme';
import { styled } from '@mui/material/styles';


export const HStatusChip = styled(Chip)({
  background: habuColors.secondary[10],
  color: habuColors.secondary[80],
  borderColor: habuColors.secondary[20],
});
