import React, { memo } from 'react';
import { CellText } from 'BaseComponents/HDataTable/CellTypes/CellText';
import { stringifyDateRange } from 'utils/dateUtils';


export const CellDateRange = memo(({ row, type }) => {
  const dateRangeFormat = stringifyDateRange(row);

  return (
    <CellText type={type}>
      {dateRangeFormat}
    </CellText>
  );
});

CellDateRange.displayName = 'CellDateRange';
