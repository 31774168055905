import React from 'react';
import { activeOrgSelector } from 'redux/reducer/AuthenticationReducer';
import { axiosInstance } from 'utils/http';
import { organizationParameters } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation, useQueryClient } from 'react-query';


export const useCreateOrganizationParameters = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { ID: orgId } = useSelector(activeOrgSelector);
  const [data, setData] = React.useState([]);
  const {
    isLoading, isError, isSuccess, mutate, reset,
  } = useMutation(
    async ({ payload }) => axiosInstance.post(organizationParameters(orgId).create, payload),
    {
      onSuccess: (res) => {
        setData(res.data);
        queryClient.invalidateQueries({ queryKey: ['CreateOrganizationParametersWithCategory', orgId] });
        dispatch(showAlert({ message: 'Organization parameters created successfully.', type: 'success' }));
      },
      onError: (err) => {
        dispatch(showAlert({
          message: err.response.data.message,
          type: 'error',
        }));
      },
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    data,
    mutate,
    reset,
  };
};
