import React from 'react';
import { Redirect } from 'react-router-dom';
import { homepage } from 'utils/spaUrls';
import { useSelector } from 'react-redux';
import { useUpgradeUserType } from 'api/supportMode';


const SupportModeHandler = () => {
  const user = useSelector(state => state.session.user.user);
  const upgradeUserType = useUpgradeUserType();

  upgradeUserType.mutate({
    userID: user.ID,
  });

  return (<Redirect to={homepage} />);
};

export default SupportModeHandler;
