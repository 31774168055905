import MenuItem from '@mui/material/MenuItem';
import React from 'react';
import Switch from 'components/Common/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import { API_DATE_FORMAT, UI_DATE_TIME_FORMAT } from 'utils/appConstants';


export const QuestionTextField = React.memo(({
  value, fieldLabel, onChange, multiline, required = true, rows, ...rest
}) => (
  <TextField
    size='small'
    variant='outlined'
    label={fieldLabel}
    required={required}
    placeholder={fieldLabel}
    multiline={multiline}
    rows={rows}
    fullWidth
    // defaultValue={defaultValue}
    value={value}
    onChange={e => onChange(e.currentTarget.value)}
    margin='dense'
    {...rest}
  />
));

export const QuestionIntegerField = React.memo(({
  value, fieldLabel, onChange, multiline, rows,
}) => {
  const [defaultValue] = React.useState(value);
  return (
    <TextField
      size='small'
      variant='outlined'
      label={fieldLabel}
      required
      placeholder={fieldLabel}
      multiline={multiline}
      rows={rows}
      fullWidth
      type='number'
      defaultValue={defaultValue}
      onChange={e => onChange(e.currentTarget.value)}
      margin='dense'
    />
  );
});

export const QuestionSelectField = React.memo(({
  fieldLabel, list, onChange, notRequired, value, testID, ...rest
}) => (
  <TextField
    data-testid={testID}
    value={value || ''}
    fullWidth
    label={fieldLabel}
    margin='dense'
    onChange={(e) => { onChange(e.target.value); }}
    required={!notRequired}
    select
    size='small'
    variant='outlined'
    {...rest}
  >
    {list.map(o => <MenuItem key={o.name} value={o.value}>{o.name}</MenuItem>)}
  </TextField>
));

export const QuestionBooleanField = React.memo(({
  value, fieldLabel, onChange, disabled, testID, size = 'medium',
}) => (
  <Switch
    data-testid={testID}
    label={<Typography variant='caption'>{fieldLabel}</Typography>}
    onChange={e => onChange(e.currentTarget.checked)}
    checked={Boolean(value)}
    disabled={disabled}
    size={size}
  />
));

export const QuestionDateField = React.memo(({
  value, fieldLabel, onChange,
}) => {
  const [v, setV] = React.useState(value);
  return (
    <TextField
      fullWidth
      label={fieldLabel}
      margin='dense'
      onChange={(e) => {
        setV(e.target.value);
        onChange(e.target.value);
      }}
      required
      type='date'
      InputLabelProps={{
        shrink: true,
      }}
      value={v ? moment(v).format(API_DATE_FORMAT)
        : ''}
      variant='outlined'
    />
  );
});

export const QuestionDateTimeField = React.memo(({
  value, fieldLabel, onChange,
}) => {
  const [v, setV] = React.useState(value);
  return (
    <TextField
      fullWidth
      label={fieldLabel}
      margin='dense'
      onChange={(e) => {
        setV(e.target.value);
        onChange(e.target.value);
      }}
      required
      type='datetime-local'
      InputLabelProps={{
        shrink: true,
      }}
      value={v ? moment(v).format(UI_DATE_TIME_FORMAT)
        : ''}
      variant='outlined'
    />
  );
});
