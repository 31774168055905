import http from 'utils/http';
import { CUSTOM_JOIN } from 'redux/actions/Types';
import { SyntheticRedux } from 'synthetic-redux';
import { all } from 'redux-saga/effects';
import { demoSagaEffect } from 'utils/reduxUtils';


const fetchCustomJoinSynthetic = new SyntheticRedux({ type: CUSTOM_JOIN.FETCH_JOIN }, 'join');
fetchCustomJoinSynthetic.demoSagaEffect = demoSagaEffect(fetchCustomJoinSynthetic.actions);
fetchCustomJoinSynthetic.fetch = (id, stub) => fetchCustomJoinSynthetic.actions.ignite({
  errorMeta: 'Custom Join: Fetch Join',
  method: http.get,
  url: `/api/joined-dataset/${id}`,
  stub,
});

const saveCustomJoinSynthetic = new SyntheticRedux({ type: CUSTOM_JOIN.SAVE_JOIN }, 'join');
saveCustomJoinSynthetic.demoSagaEffect = demoSagaEffect(saveCustomJoinSynthetic.actions);
saveCustomJoinSynthetic.save = (payload, stub) => saveCustomJoinSynthetic.actions.ignite({
  errorMeta: 'Custom Join: Save Join',
  method: http.post,
  payload,
  url: '/api/joined-dataset/create-draft',
  stub,
});

export const fetchCustomJoinReducer = fetchCustomJoinSynthetic.reducer;

export const saveCustomJoinReducer = saveCustomJoinSynthetic.reducer;

export const resetCustomJoinReducer = (state, action) => {
  switch (action.type) {
    case CUSTOM_JOIN.RESET:
      return {};
    default:
      return state;
  }
};

export const CustomJoinSaga = function* CustomJoinSaga() {
  yield all([
    fetchCustomJoinSynthetic.demoSagaEffect,
    saveCustomJoinSynthetic.demoSagaEffect,
  ]);
};
