export const LOCAL_STORAGE_KEYS = {
  TABLE_ITEMS_PER_PAGE: 'Table_Items_Per_Page',
};

export const actionCellWidth = 60;

export const tableHeaderSkeletonHeight = 35;

export const tableBodySkeletonHeight = 400;

export const minimumCellWidth = 70;

export const cellTypesEnum = {
  LINE_2: 'LINE_2',
  LINE_3: 'LINE_3',
  LINE_4: 'LINE_4',
  LINE_5: 'LINE_5',
  US_DATE_FORMAT: 'US_DATE_FORMAT',
  UI_DATE_FORMAT: 'UI_DATE_FORMAT',
  US_DATE_TIME_FORMAT: 'US_DATE_TIME_FORMAT',
  UI_DISPLAY_DATE_TIME_FORMAT: 'UI_DISPLAY_DATE_TIME_FORMAT',
  UI_DATE_TIME_FORMAT: 'UI_DATE_TIME_FORMAT',
  UI_SHORT_DATE: 'UI_SHORT_DATE',
  DATE_RANGE_FORMAT: 'DATE_RANGE_FORMAT',
};
