import GearPopover from 'components/Common/GearPopover';
import NoWrapCell from 'components/Common/NoWrapCell';
import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { cardsPerPageOptions } from 'utils/appConstants';


const OrganizationCredentialsTable = ({ list, getActions }) => {
  const [rowsPerPage, setRowsPerPage] = React.useState(cardsPerPageOptions[0]);
  const [page, setPage] = React.useState(0);

  return (
    <>
      <TableContainer>
        <Table size='small'>
          <TableHead>
            <TableRow />
            <TableRow>
              <TableCell align='left'>Name</TableCell>
              <TableCell align='left'>Credential Source</TableCell>
              <TableCell align='left'>Provider</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {list
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <TableRow hover key={`${row.ID} + ${index}`}>
                  <NoWrapCell cellProps={{ align: 'left' }} str={row.name} />
                  <TableCell>{row.credentialSource.name}</TableCell>
                  <TableCell>{row.credentialSource.provider}</TableCell>
                  <TableCell align='right'>
                    {!row.credentialSource?.private && (
                      <GearPopover
                        actions={getActions(row)}
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={cardsPerPageOptions}
        component='div'
        count={list.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
        }}
        onPageChange={(e, newPage) => setPage(newPage)}
        onRowsPerPageChange={e => setRowsPerPage(e.target.value)}
      />
    </>
  );
};

export default OrganizationCredentialsTable;
