import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import React from 'react';
import TextField from '@mui/material/TextField';


const MultiSelect = ({
  hideDefaultCheckbox,
  label,
  menuOptions = [],
  selectAllLabel,
  selectedValues,
  updateSelectedValues,
  ...props
}) => {
  const handleChange = ({ target }) => {
    if (target.value.includes(selectAllLabel)) {
      updateSelectedValues([selectAllLabel]);
    }
    else {
      updateSelectedValues([...target.value]);
    }
  };
  return (
    <TextField
      fullWidth
      label={label}
      margin='normal'
      select
      variant='outlined'
      SelectProps={{
        placeholder: selectAllLabel,
        multiple: true,
        onChange: handleChange,
        renderValue: selected => selected.join(', '),
      }}
      value={selectedValues}
      {...props}
    >
      {(selectAllLabel) ? (
        <MenuItem key={selectAllLabel} value={selectAllLabel}>
          {hideDefaultCheckbox ? null
            : (<Checkbox checked={selectedValues.indexOf(selectAllLabel) > -1} />)}
          <ListItemText primary={selectAllLabel} />
        </MenuItem>
      ) : null}

      {menuOptions.map(o => (
        <MenuItem
          key={o}
          value={o}
          disabled={selectedValues.indexOf(selectAllLabel) > -1}
        >
          <Checkbox
            checked={selectedValues.indexOf(o) > -1 || selectedValues.indexOf(selectAllLabel) > -1}
          />
          <ListItemText primary={o} />
        </MenuItem>
      ))}
    </TextField>
  );
};

export default MultiSelect;
