import { axiosInstance } from 'utils/http';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch } from 'react-redux';
import { useMutation } from 'react-query';
import { useState } from 'react';


export const useListData = (url, tableName) => {
  const dispatch = useDispatch();
  const [data, setData] = useState({});

  const method = url.includes('taxonomy') ? 'get' : 'post';

  const mutateOption = useMutation(
    `${tableName}-GetListData`,
    async (payload) => axiosInstance[method](url, payload),
    {
      onSuccess: (res) => setData(res?.data ?? {}),
      refetchOnWindowFocus: false,
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );


  return {
    ...mutateOption,
    data,
  };
};
