import PageHeader from 'components/Common/PageHeader';
import React, { useState } from 'react';
import { Add as AddIcon, Settings as SettingsIcon } from '@mui/icons-material';
import {
  Button,
  Paper,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { HOnRefreshButton } from 'BaseComponents/HTableControlPanel/HOnRefreshButton';
import { Link } from 'react-router-dom';
import { crumbs } from 'components/Common/BreadcrumbsNavigation';
import { organizationManagement } from 'utils/spaUrls';
import { useGetIdentityList } from 'api/OrganizationIdentity';


const OrganizationIdentity = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const {
    isLoading: isIdentityLoading,
    isRefetching: isIdentityLoadingRefetching,
    data: { domainIdentifiers = [] } = {},
    refetch,
  } = useGetIdentityList();

  const isIdentityExist = domainIdentifiers.length > 0;

  const handleRefetchIdentity = () => refetch();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  return (
    <>
      <PageHeader
        title='Identity'
        hideBackIcon
        breadcrumbs={[crumbs.home]}
      />
      <Paper
        elevation={0}
        sx={{
          p: 2,
          mb: 2,
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Stack direction='row' spacing={1}>
          <Button
            variant='contained'
            color='secondary'
            startIcon={<AddIcon />}
            size='small'
            component={Link}
            to={organizationManagement.organization.identity.add}
            disabled={isIdentityExist}
          >
            Add New Identity
          </Button>
          <HOnRefreshButton
            disabled={isIdentityLoadingRefetching}
            onRefresh={handleRefetchIdentity}
          />
        </Stack>
      </Paper>
      {
        isIdentityLoading ? (
          <Skeleton variant='rectangular' width='100%' height={122} />
        ) : (
          <Paper>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Identity Type</TableCell>
                    <TableCell>Provider</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    domainIdentifiers.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={4} align='center'>
                          No Identity Found
                        </TableCell>
                      </TableRow>
                    )
                      : (domainIdentifiers
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map(row => (
                          <TableRow key={row.ID}>
                            <TableCell>{row.displayName}</TableCell>
                            <TableCell>RampID</TableCell>
                            <TableCell>LiveRamp</TableCell>
                            <TableCell align='right'>
                              <Button
                                variant='contained'
                                color='secondary'
                                size='small'
                                startIcon={<SettingsIcon />}
                                component={Link}
                                to={organizationManagement.organization.identity.edit(row.ID)}
                              >
                                Edit
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))
                      )
                  }
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component='div'
              count={domainIdentifiers.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        )
      }
    </>
  );
};

export default OrganizationIdentity;
