import { EDGES_BASE_STYLE } from 'pages/Flow/contacts';
import { mutateSingleEdge } from 'redux/reducer/FlowReducer/utils/mutateEdge';


export const addFlowEdgesCheck = (updateEdges, oldEdges) => {
  const newEdge = mutateSingleEdge(updateEdges);
  return ([
    ...oldEdges, {
      ...newEdge,
      ...EDGES_BASE_STYLE,
      selected: false,
    }]);
};
