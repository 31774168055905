import React from 'react';
import { apiKey } from 'utils/urlConstants';
import { axiosInstance } from 'utils/http';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch } from 'react-redux';
import { useMutation, useQuery, useQueryClient } from 'react-query';


export const FETCH_API_KEY_PREFIX = 'APIKey';

const useFetchAPIKey = (orgId) => {
  const dispatch = useDispatch();
  const [data, setData] = React.useState([]);
  const {
    isLoading, isError, isSuccess, refetch,
  } = useQuery(
    [FETCH_API_KEY_PREFIX, orgId],
    async () => axiosInstance.get(apiKey.list(orgId)),
    {
      enabled: !!orgId,
      onSuccess: (res) => {
        setData(res.data.OrgApiKeys);
      },
      onError: (err) => {
        dispatch(showAlert({ message: err.response.data.message, type: 'error' }));
      },
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    refetch,
    data,
  };
};

const useCreateAPIKey = (orgId) => {
  const dispatch = useDispatch();
  const [data, setData] = React.useState([]);
  const {
    isLoading, isError, isSuccess, mutate,
  } = useMutation(
    async ({ payload }) => axiosInstance.post(apiKey.create(orgId), payload),
    {
      enabled: !!orgId,
      onSuccess: (res) => {
        setData(res.data.apiKey);
      },
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    data,
    mutate,
  };
};

const useDeleteAPIKey = (orgId) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const {
    isLoading, isError, isSuccess, mutate,
  } = useMutation(
    async (clientId) => axiosInstance.delete(apiKey.delete(orgId, clientId)),
    {
      onSuccess: () => {
        dispatch(showAlert({ message: 'API Key deleted!', type: 'success' }));
        queryClient.invalidateQueries([FETCH_API_KEY_PREFIX, orgId]);
      },
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    mutate,
  };
};

const useRotateAPISecret = (orgId) => {
  const dispatch = useDispatch();
  const [data, setData] = React.useState([]);
  const {
    isLoading, isError, isSuccess, mutate,
  } = useMutation(
    async ({ payload }) => axiosInstance.post(apiKey.rotate(orgId), payload),
    {
      enabled: !!orgId,
      onSuccess: (res) => {
        setData(res.data.apiKey);
      },
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    data,
    mutate,
  };
};

export {
  useCreateAPIKey, useDeleteAPIKey, useFetchAPIKey, useRotateAPISecret,
};
