import { v4 as uuid } from 'uuid';


export const updateSelectedFlowInformation = (flow, type, value) => {
  if (type === 'tags') {
    return { ...flow, tags: [...flow.tags, { ID: uuid(), value }] };
  }

  return {
    ...flow,
    [type]: value,
  };
};
