import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import ListViewTable from 'components/Common/ListViewTable';
import PageHeader from 'components/Common/PageHeader';
import ProductPermissionsTable
  from 'pages/InternalAdmin/application/pages/ProductPermissions/ProductPermissionsTable';
import React from 'react';
import { ListControls } from 'components/Common/ListControls';
import { crumbs } from 'components/Common/BreadcrumbsNavigation';
import { fetchPermissions } from 'redux/actions/LookupActions';
import { fetchProductList } from 'redux/actions/IamActions';
import { internalAdmin } from 'utils/spaUrls';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';


const ProductPermissions = () => {
  const dispatch = useDispatch();
  const productList = useSelector(state => state.iam.productList);
  const history = useHistory();

  React.useEffect(() => {
    dispatch(fetchPermissions());
    dispatch(fetchProductList());
  }, [dispatch]);


  return (
    <>
      <PageHeader title='Product Permissions' breadcrumbs={[crumbs.helium, crumbs.internalAdmin]} />
      <Grid container spacing={0} direction='column'>
        <Grid item>
          <Card>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ListControls
                  createButton={{
                    onClick:
                      () => history.push(internalAdmin.application.permissionWizard('')),
                    text: 'Add New Product Permission',
                  }}
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ListViewTable list={productList}>
                    <ProductPermissionsTable list={productList} />
                  </ListViewTable>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default ProductPermissions;
