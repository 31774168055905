import FlowSidePanelDrawer from 'pages/Flow/FlowSidePanel/FlowSidePanelDrawer';
import FlowSidePanelProvider from 'pages/Flow/FlowSidePanel/FlowSidePanelProvider';
import React from 'react';


const FlowSidePanel = () => (
  <FlowSidePanelProvider>
    <FlowSidePanelDrawer />
  </FlowSidePanelProvider>

);

export default FlowSidePanel;
