import React, { memo } from 'react';
import localStorage from 'utils/localStorage';
import { CACHE } from 'utils/appConstants';
import { HButton } from 'BaseComponents';
import { LayoutStyles } from 'components/Common/ListControls';


export const LayoutButton = memo(({ layoutOnToggle, layout }) => {
  const onToggleLayout = () => {
    const newLayout = (layout.text === LayoutStyles.card.text)
      ? LayoutStyles.card : LayoutStyles.list;
    layoutOnToggle(newLayout);
    localStorage.set(CACHE.defaultLayout, newLayout.text);
  };

  return (
    <HButton onClick={onToggleLayout} size='small' variant='outlined'>
      <layout.icon fontSize='small' style={{ marginRight: '5px' }} />
      {layout.text}
    </HButton>
  );
});

LayoutButton.displayName = 'LayoutButton';
