import CleanRoomQuestion
  from 'pages/InternalAdmin/application/pages/CleanRoomQuestion/CleanRoomQuestion';
import CleanRoomQuestions
  from 'pages/InternalAdmin/application/pages/CleanRoomQuestions/CleanRoomQuestions';
import CredentialSourceDetails
  from 'pages/InternalAdmin/application/pages/CredentialSourceDetails/CredentialSourceDetails';
import CredentialSources
  from 'pages/InternalAdmin/application/pages/CredentialSources/CredentialSources';
import PermissionWizard
  from 'pages/InternalAdmin/application/pages/PermissionWizard/PermissionWizard';
import PrivateRoute from 'components/Common/PrivateRoute';
import ProductPermissionDetails
  from 'pages/InternalAdmin/application/pages/ProductPermissionDetails/ProductPermissionDetails';
import ProductPermissions
  from 'pages/InternalAdmin/application/pages/ProductPermissions/ProductPermissions';
import React from 'react';
import SnowflakeCliDashboard
  from 'pages/InternalAdmin/application/pages/SnowflakeCliDashboard/SnowflakeCliDashboard';
import { Switch } from 'react-router-dom';
import { internalAdmin } from 'utils/spaUrls';


const InternalAdminApplicationRouter = () => (
  <Switch>
    <PrivateRoute
      path={internalAdmin.application.cleanRoomQuestions}
      component={CleanRoomQuestions}
      exact
    />
    <PrivateRoute
      path={internalAdmin.application.cleanRoomQuestion()}
      component={CleanRoomQuestion}
      exact
    />
    <PrivateRoute
      path={internalAdmin.application.credentialSources}
      component={CredentialSources}
      exact
    />
    <PrivateRoute
      path={`${internalAdmin.application.credentialSourceDetail}/:id?`}
      component={CredentialSourceDetails}
      exact
    />
    <PrivateRoute
      path={internalAdmin.application.permissionWizard()}
      component={PermissionWizard}
      exact
    />
    <PrivateRoute
      path={internalAdmin.application.productPermissions}
      component={ProductPermissions}
      exact
    />
    <PrivateRoute
      path={internalAdmin.application.productPermissionDetails()}
      component={ProductPermissionDetails}
      exact
    />
    <PrivateRoute
      path={internalAdmin.application.snowflakeCliDashboard}
      component={SnowflakeCliDashboard}
      exact
    />
  </Switch>
);

export default InternalAdminApplicationRouter;
