import { DATA_LOCATION } from 'redux/actions/Types';
import { dataSourceLocation } from 'utils/urlConstants';


export const fetchDataLocations = orgId => ({
  type: DATA_LOCATION.FETCH_DATA_LOCATIONS,
  url: dataSourceLocation(orgId).list,
});

export const fetchDataLocationsSuccess = payload => ({
  type: DATA_LOCATION.FETCH_DATA_LOCATIONS_SUCCESS,
  payload,
});

export const fetchDataLocationsError = payload => ({
  type: DATA_LOCATION.FETCH_DATA_LOCATIONS_ERROR,
  payload,
});

export const createDataLocation = payload => ({
  type: DATA_LOCATION.CREATE_DATA_LOCATION,
  url: dataSourceLocation().create,
  payload,
});

export const createDataLocationSuccess = payload => ({
  type: DATA_LOCATION.CREATE_DATA_LOCATION_SUCCESS,
  payload,
});

export const createDataLocationError = payload => ({
  type: DATA_LOCATION.CREATE_DATA_LOCATION_ERROR,
  payload,
});

export const createDataLocationReset = () => ({
  type: DATA_LOCATION.CREATE_DATA_LOCATION_RESET,
});
