export const useActionMenuItems = () => {
  const actionItems = [
    {
      label: 'Delete',
      link: false,
      action: undefined,
    },
  ];

  return actionItems;
};
