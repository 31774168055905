import InputLabel from '@mui/material/InputLabel';
import React from 'react';
import TextField from '@mui/material/TextField';



const FlowMetadataField = ({ label = '', value, onChange }) => (
  <>
    <InputLabel shrink htmlFor='description' required>
      {label}
    </InputLabel>
    <TextField
      id='label'
      fullWidth
      label=''
      maxRows={4}
      multiline
      onChange={e => onChange(e.target.value)}
      required
      size='small'
      value={value}
    />
  </>
);

export default FlowMetadataField;
