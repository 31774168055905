export const USER_LISTS = {
  CHANGE_STATUS: 'CHANGE_STATUS',
  CHANGE_STATUS_ERROR: 'CHANGE_STATUS_ERROR',
  CHANGE_STATUS_SUCCESS: 'CHANGE_STATUS_SUCCESS',
  DELETE_USER_LIST: 'DELETE_USER_LIST',
  DELETE_USER_LIST_ERROR: 'DELETE_USER_LIST_ERROR',
  DELETE_USER_LIST_SUCCESS: 'DELETE_USER_LIST_SUCCESS',
  FETCH_UL_FILTERS: 'FETCH_UL_FILTERS',
  FETCH_UL_FILTERS_ERROR: 'FETCH_UL_FILTERS_ERROR',
  FETCH_UL_FILTERS_SUCCESS: 'FETCH_UL_FILTERS_SUCCESS',
  FETCH_LIST: 'FETCH_LIST',
  CREATE_USER_LIST: 'CREATE_USER_LIST',
  FETCH_LIST_DETAIL: 'FETCH_LIST_DETAIL',
  FETCH_LIST_DETAIL_RUNS: 'FETCH_LIST_DETAIL_RUNS',
  FETCH_LIST_DETAIL_RUNS_ERROR: 'FETCH_LIST_DETAIL_RUNS_ERROR',
  FETCH_LIST_DETAIL_RUNS_SUCCESS: 'FETCH_LIST_DETAIL_RUNS_SUCCESS',
  FETCH_LIST_SUMMARY: 'FETCH_LIST_SUMMARY',
  FETCH_LIST_SUMMARY_ERROR: 'FETCH_LIST_SUMMARY_ERROR',
  FETCH_LIST_SUMMARY_SUCCESS: 'FETCH_LIST_SUMMARY_SUCCESS',
  REPROCESS_USER_LIST: 'REPROCESS_USER_LIST',
  SAVE_USER_LIST: 'SAVE_USER_LIST',
  SAVE_USER_LIST_ERROR: 'SAVE_USER_LIST_ERROR',
  SAVE_USER_LIST_SUCCESS: 'UPDATE_USER_LIST_SUCCESS',
};
