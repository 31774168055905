import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import {
  CSS_PARAMETER_NAMES,
  DEFAULT_CSS_PARAMETERS,
  DEFAULT_FONT_PARAMETER,
  ORGANIZATION_CUSTOMIZATION_PARAMETER_OPTIONS as parameterOptions,
} from 'pages/OrganizationManagement/constants';
import { HButton } from 'BaseComponents';
import { cleanRoomDesignToken } from 'designToken';
import { compareByField } from 'utils/sortUtils';
import { formatBytes } from 'utils/jsUtils';
import { isEmpty, unionBy } from 'lodash';
import { organizationManagement } from 'utils/spaUrls';
import { useCreateCustomization, useFetchCustomization, useUploadLogo } from 'hooks/organizationAPI';
import { useHistory } from 'react-router-dom';


const OrganizationCustomizationEdit = () => {
  const history = useHistory();
  const [cSSParameters, setCSSParameters] = useState(DEFAULT_CSS_PARAMETERS);
  const [fontParameters, setFontParameters] = useState(DEFAULT_FONT_PARAMETER);
  const [logoParameters, setLogoParameters] = useState([]);
  const [logoSrc, setLogoSrc] = useState();
  const [logoFile, setLogoFile] = useState([]);
  const [logoBgColor, setLogoBgColor] = useState('#ffffff');
  const { data: existingParams } = useFetchCustomization();
  const {
    isSuccess: isCreated,
    mutate: createCustomization,
  } = useCreateCustomization();

  const {
    isSuccess: isUploaded,
    mutate: uploadLogo,
  } = useUploadLogo();

  useEffect(() => {
    if (!isEmpty(existingParams)) {
      const existingCSSParams = existingParams.filter(p => CSS_PARAMETER_NAMES.includes(p.name));
      const cssParams = unionBy(existingCSSParams, DEFAULT_CSS_PARAMETERS, 'name');
      cssParams.sort(compareByField('name'));
      setCSSParameters(cssParams);

      const bgColor = existingParams.find(p => p.name === parameterOptions.NAV_BAR_BG_COLOR.key);
      setLogoBgColor(bgColor.value);

      const existingFontParams = existingParams.filter(p => p.name === parameterOptions.FONT.key);
      const fontParam = unionBy(existingFontParams, DEFAULT_FONT_PARAMETER, 'name');
      setFontParameters(fontParam);

      const existingLogoParams = existingParams
        .filter(p => p.name === parameterOptions.LOGO_URL.key);
      if (!isEmpty(existingLogoParams)) {
        setLogoParameters(existingLogoParams);
        const src = existingParams.find(p => p.name === parameterOptions.PRE_SIGNED_LOGO_URL.key);
        setLogoSrc(src.value);
      }
    }
  }, [existingParams]);

  const disableButton = () => {
    const cSSParamsFilled = cSSParameters.every(p => p.value !== null && p.value !== '');
    return (!cSSParamsFilled);
  };

  const onCancel = () => {
    history.goBack();
  };

  const onUpdate = () => {
    if (!isEmpty(logoFile)) {
      uploadLogo(logoFile);
    }
    let newParams = [
      ...fontParameters,
      ...cSSParameters,
    ];
    if (!isEmpty(logoParameters)) {
      newParams = [
        ...newParams,
        ...logoParameters,
      ];
    }
    const payload = { parameters: newParams };
    createCustomization({ payload });
  };

  const onLogoUpload = (e) => {
    setLogoFile(e.target.files);
  };

  const updateCSSParameter = (key, value) => {
    const idx = cSSParameters.findIndex(p => p.name === key);
    const newParams = [...cSSParameters];
    const updatedParams = {
      ...newParams[idx],
      value,
    };
    newParams[idx] = updatedParams;
    setCSSParameters(newParams);
    if (key === parameterOptions.NAV_BAR_BG_COLOR.key) {
      setLogoBgColor(value);
    }
  };

  const updateFontParameter = (value) => {
    const newParam = {
      ...fontParameters[0],
      value,
    };
    setFontParameters([newParam]);
  };

  if (!isEmpty(logoFile)) {
    if (isCreated && isUploaded) {
      history.push(organizationManagement.organization.customization.landing);
    }
  }
  else if (isCreated) {
    history.push(organizationManagement.organization.customization.landing);
  }

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <TextField
              fullWidth
              label={fontParameters[0].name}
              onChange={e => updateFontParameter(e.target.value)}
              value={fontParameters[0].value}
              variant='outlined'
              select
              required
            >
              {
                cleanRoomDesignToken.fontOptions.map((font) => (
                  <MenuItem key={`font_option_${font}`} value={font}>{font}</MenuItem>))
              }
            </TextField>
          </Grid>
          {cSSParameters.map(param => (
            <React.Fragment key={`css_param_${param.name}`}>
              <Grid item xs={8}>
                <TextField
                  fullWidth
                  label={parameterOptions[param.name].name}
                  onChange={e => updateCSSParameter(param.name, e.currentTarget.value)}
                  value={param.value}
                  variant='outlined'
                  required
                />
              </Grid>
              <Grid item xs={4} />
            </React.Fragment>
          ))}
          {logoSrc && (
          <Grid item xs={8}>
            <Card variant='outlined' sx={{ p: 1 }}>
              <Grid container justifyContent='space-between'>
                <Grid item py={1}>
                  <Typography variant='subtitle1'>Uploaded Logo</Typography>
                </Grid>
                <Grid item bgcolor={logoBgColor} py={1} px={10}>
                  <img
                    alt=' '
                    src={logoSrc}
                    style={{ maxHeight: '40px', maxWidth: '80px' }}
                    crossOrigin=''
                  />
                </Grid>
              </Grid>
            </Card>
          </Grid>
          )}
          <Grid item xs={8} justifyContent='flex-start'>
            <input
              id='logo-uploader'
              accept='.jpg, .jpeg, .png'
              type='file'
              onChange={onLogoUpload}
              style={{ display: 'none' }}
            />
            <label htmlFor='logo-uploader'>
              <HButton
                variant='contained'
                component='span'
                size='medium'
                color='secondary'
                startIcon={<CloudUploadIcon />}
              >
                Upload Your Own Logo
              </HButton>
            </label>
          </Grid>
          {(logoFile && logoFile.length > 0) && (
          <Grid item xs={8}>
            <Card variant='outlined' sx={{ p: 1 }}>
              <Grid container justifyContent='space-between'>
                <Grid item>
                  <Typography variant='subtitle1'>{`File name: ${logoFile[0].name}, file size: ${formatBytes(logoFile[0].size)}`}</Typography>
                </Grid>
                <Grid item bgcolor={logoBgColor} py={1} px={10}>
                  <img
                    alt=' '
                    src={URL.createObjectURL(logoFile[0])}
                    style={{ maxHeight: '40px', maxWidth: '80px' }}
                  />
                </Grid>
              </Grid>
            </Card>
          </Grid>
          )}
          <Grid item xs={4} />
          <Grid item xs={8}>
            <Box display='flex' justifyContent='flex-end'>
              <CardActions>
                <HButton variant='outlined' onClick={onCancel}>Cancel</HButton>
                <HButton
                  variant='contained'
                  color='primary'
                  onClick={onUpdate}
                  disabled={disableButton()}
                >
                  Update
                </HButton>
              </CardActions>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default OrganizationCustomizationEdit;
