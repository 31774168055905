import * as actionGenerators from 'redux/actions/QuestionBuilderActions';
import Logger from 'components/Common/Logger';
import http from 'utils/http';
import { QUESTION_BUILDER } from 'redux/actions/Types';
import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import { showAlert } from 'redux/actions/AlertActions';


function* fetchCleanRoomQuestionPermissions(action) {
  const {
    cleanRoomQuestionPermissions,
    cleanRoomQuestion,
    error,
  } = yield call(http.get, action.url);
  let payload;
  if (error) {
    Logger.error(error, 'Question Builder: Failed to fetch clean-room question permission');
    yield put(showAlert({ message: error, type: 'error' }));
    return yield put(actionGenerators.fetchCrQuestionPermissionsError(error));
  }
  else {
    payload = {
      cleanRoomQuestionPermissions,
      cleanRoomQuestion,
    };

    return yield put(
      actionGenerators
        .fetchCrQuestionPermissionsSuccess(payload),
    );
  }
}


export default function* root() {
  yield all([
    takeLatest(QUESTION_BUILDER.FETCH_CR_QUESTION_PERMISSIONS, fetchCleanRoomQuestionPermissions),
  ]);
}

export {
  fetchCleanRoomQuestionPermissions,
};
