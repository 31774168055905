import AddRoundedIcon from '@mui/icons-material/AddRounded';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import HSearchComponent from 'BaseComponents/HSearchComponent';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import React, { memo, useContext, useEffect } from 'react';
import Select from '@mui/material/Select';
import Skeleton from '@mui/material/Skeleton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import usePreviousProps from 'hooks/usePreviousProps';
import { AlertsContext } from 'pages/InternalAdmin/organization/pages/Alerts/AlertsListLayout';
import { AlertsPageContext } from 'pages/InternalAdmin/organization/pages/Alerts/Alerts';
import { DataContext } from 'BaseComponents/HDataTable/DataContextProvider';
import { HButton } from 'BaseComponents';
import { Tooltip } from '@mui/material';
import { internalAdmin } from 'utils/spaUrls';
import { isEqual } from 'lodash';
import { toDisplayString } from 'utils/jsUtils';
import { useHistory } from 'react-router-dom';


const ALL_FILTER = '-';

const FiltersLoadingSkeleton = () => Array.from({ length: 5 }, (_, i) => (
  <Grid item xs={2} key={i} alignItems='flex-end'>
    <Skeleton variant='text' height={20} width='60%' />
    <Skeleton variant='rectangular' sx={{ fontSize: '1.5rem' }} />
  </Grid>
));


const AlertsControlPanel = memo(() => {
  const history = useHistory();

  const {
    refreshListData,
    filters,
    filtersOptions,
    handleSearchChange,
    handleFilterChange,
    isLoading,
  } = useContext(DataContext);

  const {
    crId,
    onCleanRoomChange,
    crListQuery: {
      fetchingCRList,
      isCrListError,
      crList,
    },
    isCurrentCrExpired,
  } = useContext(AlertsPageContext);

  const { dataContextRef } = useContext(AlertsContext);

  const prevCrId = usePreviousProps(crId);

  const updateFilter = (attribute, value) => {
    const updatedFilters = filters.filters.filter(f => f.attribute !== attribute);
    if (value !== ALL_FILTER) {
      updatedFilters.push({
        attribute,
        values: [value],
      });
    }
    handleFilterChange({
      ...filters,
      offset: 0,
      filters: updatedFilters,
    });
  };

  useEffect(() => {
    dataContextRef.current = {
      refreshListData,
    };
  }, [dataContextRef, refreshListData]);

  useEffect(() => {
    if (!isEqual(crId, prevCrId)) {
      refreshListData();
    }
  }, [crId, prevCrId, refreshListData]);

  return (
    <Grid
      container
      mb={2}
      spacing={2}
      columns={{
        xs: 2, sm: 6, md: 8, lg: 14,
      }}
    >
      <Grid item xs={2}>
        {
          (fetchingCRList || isCrListError)
            ? <Skeleton variant='rectangular' sx={{ fontSize: '2rem' }} />
            : (
              <FormControl size='small' fullWidth>
                <InputLabel id='clean-room-option'>Clean Room</InputLabel>
                <Select
                  labelId='clean-room-option'
                  id='clean-room-select'
                  value={crId || ''}
                  label='Clean Room'
                  placeholder='Select Clean Room'
                  fullWidth
                  onChange={(e) => {
                    onCleanRoomChange(e.target.value);
                  }}
                >
                  {crList?.map((option) => (
                    <MenuItem key={option.ID} value={option.ID}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )
        }
      </Grid>
      {
        (isLoading || !filtersOptions) ? <FiltersLoadingSkeleton /> : (
          <>
            <Grid item xs={2}>
              <HSearchComponent
                placeholder='Search'
                onSearch={(value) => handleSearchChange('', value)}
                defaultValue={filters.search || ''}
                label='Search'
              />
            </Grid>
            {
              filtersOptions.filters.map(i => (
                <Grid item xs={2} key={i.attribute}>
                  <TextField
                    size='small'
                    fullWidth
                    label={`Filter by ${toDisplayString(i.attribute)}`}
                    InputLabelProps={{ sx: { maxWidth: '100%' } }}
                    onChange={e => updateFilter(i.attribute, e.target.value)}
                    select
                    value={filters.filters
                      .find(af => af.attribute === i.attribute)?.values[0] || ALL_FILTER}
                    variant='outlined'
                  >
                    <MenuItem value={ALL_FILTER}>All</MenuItem>
                    {i.options.map(f => (
                      <MenuItem key={f.name} value={f.name}>
                        {f.displayName}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              ))
            }
          </>
        )
      }
      <Grid item xs={2} display='flex' alignItems='flex-end'>
        <Tooltip title={isCurrentCrExpired ? 'Clean Room Expired. Unable to create an Alert' : ''}>
          <span>
            <HButton
              variant='contained'
              disableElevation
              startIcon={<AddRoundedIcon />}
              onClick={() => history.push(internalAdmin.organization.alertBuilder(crId))}
              disabled={isCurrentCrExpired}
            >
              <Typography variant='body2' fontWeight={600}>Create Alert</Typography>
            </HButton>
          </span>
        </Tooltip>
      </Grid>
    </Grid>
  );
});

AlertsControlPanel.displayName = 'AlertsControlPanel';

export default AlertsControlPanel;
