import AddNewSectionButton from 'components/InternalAdmin/QuestionConfigure/AddNewSectionButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Grid from '@mui/material/Grid';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import IconButton from '@mui/material/IconButton';
import React from 'react';
import SectionHeader from 'components/IAM/Admin/SectionHeader';
import { FilterTypes, InputTypesMap, UserListDefaultValues } from 'components/CleanRooms/constants';
import {
  QuestionBooleanField,
  QuestionSelectField,
  QuestionTextField,
} from 'components/InternalAdmin/QuestionConfigure/QuestionRenderer';
import { Tooltip, debounce } from '@mui/material';
import { v4 as uuidGen } from 'uuid';


const inputTypes = Object.values(InputTypesMap).map(i => ({ name: i.label, value: i.key }));
const filterTypes = Object.values(FilterTypes).map(i => ({ name: i.label, value: i.key }));

const DEBOUNCE_TIMER = 40;

const QuestionDimensions = ({ dimensions, onPropertyUpdate, isUserList }) => {
  const [config, setConfig] = React.useState(dimensions);

  const onPropertyArrayChange = React.useCallback((i, name) => debounce((value) => {
    setConfig(prevState => ([
      ...prevState.slice(0, i),
      Object.assign({}, prevState[i], {
        [name]: value,
      }),
      ...prevState.slice(i + 1),
    ]));
  }, DEBOUNCE_TIMER), []);

  const onAddItem = React.useCallback(() => {
    setConfig(prevState => ([
      ...prevState,
      { ID: uuidGen(), filterable: false, plottable: false },
    ]));
  }, [setConfig]);

  const onDeleteItem = React.useCallback((i) => {
    setConfig(prevState => [
      ...prevState.slice(0, i),
      ...prevState.slice(i + 1),
    ].map((item, newIndex) => ({ ...item, index: newIndex + 1 })));
  }, [setConfig]);

  const onIsFilterableChange = React.useCallback(i => (value) => {
    setConfig(prevState => ([
      ...prevState.slice(0, i),
      Object.assign({}, prevState[i], {
        filterable: value,
        filterType: !value ? undefined : prevState[i].filterType,
      }),
      ...prevState.slice(i + 1),
    ]));
  }, [setConfig]);

  React.useEffect(() => {
    onPropertyUpdate('dimensions', config);
  }, [config, onPropertyUpdate]);

  React.useEffect(() => {
    if (isUserList) {
      onPropertyArrayChange(0, 'name')(UserListDefaultValues.TABLE_NAME);
      onPropertyArrayChange(0, 'plottable')(true);
      onPropertyArrayChange(0, 'type')(UserListDefaultValues.STRING);
    }
  }, [isUserList, onPropertyArrayChange]);


  return (
    <>
      <Grid container alignItems='flex-start'>
        <Grid item>
          <SectionHeader title='Dimensions' />
        </Grid>
        <Grid item>
          <Tooltip title='Think x-axis of chart. Eg. frequency buckets'>
            <HelpOutlineIcon fontSize='small' style={{ marginLeft: '5px' }} />
          </Tooltip>
        </Grid>
      </Grid>
      {dimensions.map((dimension, index) => (
        <Grid container spacing={2} key={`dimensions--${dimension.ID}`} alignItems='center'>
          <Grid item xs={4} md={3} lg={2}>
            <QuestionTextField
              value={dimension.name || ''}
              fieldLabel='System Field Name'
              onChange={onPropertyArrayChange(index, 'name')}
              disabled={isUserList}
            />
          </Grid>
          <Grid item xs={4} md={3} lg={2}>
            <QuestionTextField
              value={dimension.displayName || ''}
              fieldLabel='Display Name'
              onChange={onPropertyArrayChange(index, 'displayName')}
            />
          </Grid>
          <Grid item xs={4} md={3} lg={2}>
            <QuestionSelectField
              value={dimension.type || ''}
              fieldLabel='Input Type'
              list={inputTypes}
              onChange={onPropertyArrayChange(index, 'type')}
              disabled={isUserList}
            />
          </Grid>
          <Grid item xs={4} md={2} lg={2}>
            <QuestionBooleanField
              value={dimension.plottable}
              fieldLabel='Plot'
              onChange={onPropertyArrayChange(index, 'plottable')}
              disabled={isUserList}
            />
          </Grid>
          <Grid item xs={4} md={4} lg={2}>
            <>
              <QuestionBooleanField
                value={dimension.filterable}
                fieldLabel='Filter'
                onChange={onIsFilterableChange(index)}
                disabled={isUserList}
              />
              {dimension.filterable === true && (
              <QuestionSelectField
                value={dimension.filterType}
                fieldLabel='Filter Type'
                list={filterTypes}
                onChange={onPropertyArrayChange(index, 'filterType')}
                disabled={isUserList}
              />
              )}
            </>
          </Grid>
          {index !== 0 && (
          <Grid item>
            <IconButton aria-label='delete' onClick={() => onDeleteItem(index)} size='large'>
              <DeleteIcon />
            </IconButton>
          </Grid>
          )}
        </Grid>
      ))}
      <AddNewSectionButton list={config} onClick={onAddItem} disabled={isUserList} />
    </>
  );
};

export default QuestionDimensions;
