export const PERMISSION = {
  CREATE_PERMISSION: 'CREATE_PERMISSION',
  CREATE_PERMISSION_SUCCESS: 'CREATE_PERMISSION_SUCCESS',
  CREATE_PERMISSION_ERROR: 'CREATE_PERMISSION_ERROR',
  UPDATE_PERMISSION: 'UPDATE_PERMISSION',

  FETCH_PRODUCT_PERMISSION: 'FETCH_PRODUCT_PERMISSION',
  FETCH_PRODUCT_PERMISSION_SUCCESS: 'FETCH_PRODUCT_PERMISSION_SUCCESS',
  FETCH_PRODUCT_PERMISSION_ERROR: 'FETCH_PRODUCT_PERMISSION_ERROR',

  UPDATE_PRODUCT_PERMISSIONS: 'UPDATE_PRODUCT_PERMISSIONS',
  UPDATE_PRODUCT_PERMISSIONS_SUCCESS: 'UPDATE_PRODUCT_PERMISSIONS_SUCCESS',
  UPDATE_PRODUCT_PERMISSIONS_ERROR: 'UPDATE_PRODUCT_PERMISSIONS_ERROR',
};
