import React from 'react';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';


const StyledLink = styled(Link)(({ theme }) => ({
  ...theme.components.MuiLink.styleOverrides.root,
  textDecoration: 'underline',
  ':not(li &)': {
    ...theme.typography.linkText,
  },
  'li &:visited, li &:hover': {
    color: theme.palette.text.primary,
  },
}));

export const LinkComponent = React.forwardRef(({ ...props }, ref) => (
  <StyledLink
    data-testid='link-component'
    innerRef={ref}
    {...props}
  />
));
