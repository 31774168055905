import http from 'utils/http';
import { AUTOMATED_JOIN } from 'redux/actions/Types';
import { SyntheticRedux } from 'synthetic-redux';
import { all } from 'redux-saga/effects';
import { cleanRoom } from 'utils/urlConstants';
import { demoSagaEffect } from 'utils/reduxUtils';


const fetchAutomatedJoinSynthetic = new SyntheticRedux({ type: AUTOMATED_JOIN.FETCH_JOIN }, 'join');
fetchAutomatedJoinSynthetic.demoSagaEffect = demoSagaEffect(fetchAutomatedJoinSynthetic.actions);
fetchAutomatedJoinSynthetic.fetch = (id, stub) => fetchAutomatedJoinSynthetic.actions.ignite({
  errorMeta: 'Custom Join: Fetch Join',
  method: http.get,
  url: cleanRoom().getCleanRoomJoinedDatasets(id),
  stub,
});

const saveAutomatedJoinSynthetic = new SyntheticRedux({ type: AUTOMATED_JOIN.SAVE_JOIN }, 'join');
saveAutomatedJoinSynthetic.demoSagaEffect = demoSagaEffect(saveAutomatedJoinSynthetic.actions);
saveAutomatedJoinSynthetic.save = (payload, stub) => saveAutomatedJoinSynthetic.actions.ignite({
  errorMeta: 'Custom Join: Save Join',
  method: http.post,
  payload,
  url: cleanRoom().createJoinedDataset,
  stub,
});

export const AutomatedJoinSaga = function* AutomatedJoinSaga() {
  yield all([
    fetchAutomatedJoinSynthetic.demoSagaEffect,
    saveAutomatedJoinSynthetic.demoSagaEffect,
  ]);
};
