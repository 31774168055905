import React from 'react';
import SortIcon from '@mui/icons-material/Sort';
import { SORT_DIRECTION } from 'utils/constants/partners.constant';
import { TableSortLabel, Typography } from '@mui/material';


const TableSortIconLabel = ({
  active,
  direction,
  field,
  title,
  onClick = () => {},
}) => {
  const getDirection = (dir) => {
    switch (dir) {
      case SORT_DIRECTION.asc: return 'asc';
      case SORT_DIRECTION.desc: return 'desc';
      default: return dir || 'asc';
    }
  };

  return (
    <TableSortLabel
      data-testid='table-sortable-label'
      active={active === field}
      direction={getDirection(direction)}
      onClick={() => {
        if (typeof onClick === 'function') {
          onClick(field);
        }
      }}
    >
      <Typography variant='h6'>{title}</Typography>
      <SortIcon fontSize='small' sx={{ ml: 1 }} />
    </TableSortLabel>
  );
};

export default TableSortIconLabel;
