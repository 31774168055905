import { AutomatedJoinSaga } from 'ProjectHelium/JoinedDataSet/redux/AutomatedJoin/AutomatedJoinSynthetic';
import { CustomJoinSaga } from 'ProjectHelium/JoinedDataSet/redux/CustomJoin/CustomJoinSynthetic';
import { all, fork } from 'redux-saga/effects';


const sagas = [AutomatedJoinSaga, CustomJoinSaga];

export default function* RootSaga() {
  yield all(Object.values(sagas).map(saga => fork(saga)));
}
