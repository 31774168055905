import InternalAdminAppCleanRoomSaga from 'pages/InternalAdmin/application/CleanRoom/redux/saga';
import InternalAdminOrgCleanRoomSaga from 'pages/InternalAdmin/organization/CleanRoom/redux/saga';
import InternalAdminOrgDataInSaga from 'pages/InternalAdmin/organization/DataIn/redux/saga';
import InternalAdminOrgParameters from 'pages/InternalAdmin/organization/pages/OrganizationParameters/redux/saga';
import { all, fork } from 'redux-saga/effects';


const sagas = [
  InternalAdminAppCleanRoomSaga,
  InternalAdminOrgCleanRoomSaga,
  InternalAdminOrgDataInSaga,
  InternalAdminOrgParameters,
];

export default function* RootSaga() {
  yield all(sagas.map(saga => fork(saga)));
}
