import React from 'react';
import {
  Chip,
  Tooltip,
} from '@mui/material';


export const HChip = ({
  tooltipTitle = '',
  maxLength = 25,
  id = 'h-chip',
  ...props
}) => {
  const dataTestid = props['data-testid'] ?? 'HChip';

  return (
    <Tooltip
      title={tooltipTitle ?? props.label}
      placement='top'
    >
      <Chip
        id={id}
        size='small'
        data-testid={dataTestid}
        sx={{
          maxWidth: `${maxLength}ch`,
        }}
        {...props}
      />
    </Tooltip>
  );
};
