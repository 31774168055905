import { activeOrgSelector } from 'redux/reducer/AuthenticationReducer';
import { axiosInstance } from 'utils/http';
import { flow } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'react-query';


const useSubmitFlowMacroMapping = ({ crId, crFlowId }) => {
  const dispatch = useDispatch();
  const { ID: orgId } = useSelector(activeOrgSelector);


  const {
    isLoading, isSuccess, isError, data, mutate,
  } = useMutation(
    ['save-flow-macro-mapping', orgId],
    async (payload) => axiosInstance
      .post(flow(orgId).macroMappings(crId, crFlowId), payload),
    {
      onSuccess: () => {
        dispatch(showAlert({ message: 'Flow macros have been assigned.', type: 'success' }));
      },
      onError: (error) => dispatch(showAlert({ message: error.response.data.message, type: 'error' })),
    },
  );
  return {
    isLoading, isError, isSuccess, data, mutate,
  };
};

export default useSubmitFlowMacroMapping;
