import React from 'react';
import {
  Alert, Box, Button, Stack,
} from '@mui/material';
import { useDowngradeUserType } from 'api/supportMode';
import { useSelector } from 'react-redux';


const SupportModeBar = () => {
  const user = useSelector(state => state.session.user.user);
  const downgradeUserType = useDowngradeUserType();

  const handleDisable = () => {
    downgradeUserType.mutate({
      userID: user.ID,
    });
  };
  return (
    <Stack direction='row' spacing={1} sx={{ marginTop: '5px' }}>
      <Box sx={{ width: '300px' }} />
      <Box sx={{ width: '100%' }}>
        <Alert
          variant='filled'
          severity='warning'
          action={(
            <Button
              color='primary'
              variant='contained'
              size='small'
              onClick={handleDisable}
            >
              Disable
            </Button>
        )}
        >
          YOU ARE ON SUPPORT MODE. DISABLE IT WHEN YOUR TASK IS DONE.
        </Alert>
      </Box>
    </Stack>
  );
};

export default SupportModeBar;
