import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import React from 'react';
import { MENU_ITEM_SIZE } from 'components/Layouts/SideNav/utils';
import { getRgba } from 'utils/jsUtils';
import { styled } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';


const getLinearGradient = (color) => `linear-gradient(90deg, ${getRgba(color, 0.27)},${getRgba(color, 0.08)})`;

const StyledListButton = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== 'isMainSideNav' && prop !== 'hasChildSelected',
})(({
  theme,
  isMainSideNav,
  hasChildSelected,
}) => (
  {
    color: hasChildSelected
      ? theme.palette.primary.main
      : theme.palette.sideNavigation.button.color,
    '&:before': isMainSideNav && hasChildSelected ? {
      content: '""',
      width: 4,
      backgroundColor: theme.palette.primary.main,
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
    } : {},
    '&.Mui-selected:before': isMainSideNav ? {} : {
      content: '""',
      width: 4,
      backgroundColor: theme.palette.sideNavigation.leftBorder,
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
    },
    '&.Mui-selected': {
      background: isMainSideNav
        ? theme.palette.primary.light
        : getLinearGradient(theme.palette.sideNavigation.button.backgroundHoverColor),
      color: isMainSideNav
        ? theme.palette.common.black
        : theme.palette.sideNavigation.button.hoverColor,
      '&:hover': {
        color: isMainSideNav
          ? theme.palette.common.black
          : theme.palette.sideNavigation.button.color,
        background: isMainSideNav
          ? theme.palette.success['x-light']
          : getLinearGradient(theme.palette.sideNavigation.button.backgroundHoverColor),
      },
    },
    '&:hover': {
      background: isMainSideNav
        ? theme.palette.secondary.main
        : getLinearGradient(theme.palette.sideNavigation.button.backgroundHoverColor),
      color: hasChildSelected
        ? theme.palette.primary.main
        : theme.palette.sideNavigation.button.hoverColor,
    },
  }
));

const StyledListItemIcon = styled(ListItemIcon)({
  display: 'flex',
  color: 'inherit',
});

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    color: theme.palette.common.black,
    backgroundColor: theme.palette.success.main,
    fontWeight: 700,
  },
}));

const smItemStyles = {
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
};

const StyledBox = styled(Box)(smItemStyles);


export const SingleMenuItem = ({
  menuItem,
  onClick,
  open,
  size,
  currPath,
  isMainSideNav = false,
  hasChildSelected = false,
}) => {
  const history = useHistory();
  const Icon = menuItem.icon;

  let itemSelected = false;
  if (menuItem?.paths) {
    itemSelected = menuItem?.paths.includes(currPath);
  }
  else {
    itemSelected = menuItem.path === currPath;
  }

  const handleClick = () => {
    if (onClick) {
      onClick();
      return;
    }

    history.push(menuItem.path);
  };

  return (
    <StyledListButton
      selected={itemSelected}
      onClick={handleClick}
      isMainSideNav={isMainSideNav}
      hasChildSelected={hasChildSelected}
    >
      <Grid
        container
        direction='row'
        alignItems='center'
      >
        {size === MENU_ITEM_SIZE.MEDIUM && (
          <>
            <Grid
              item
              xs={12}
            >
              {Icon && (
                <StyledBox>
                  <StyledBadge
                    badgeContent={menuItem?.alphaMessage}
                    variant='contained'
                  >
                    <StyledListItemIcon style={smItemStyles}>
                      <Icon />
                    </StyledListItemIcon>
                  </StyledBadge>
                </StyledBox>
              )}
            </Grid>
            <Grid item xs={12}>
              <StyledBox>
                <ListItemText
                  data-testid={`single-menu-item-${menuItem.title}`}
                  primary={menuItem.title}
                  primaryTypographyProps={{
                    fontSize: 14,
                    fontWeight: 600,
                  }}
                />
              </StyledBox>
            </Grid>
            {onClick && (
              <Grid item xs={12}>
                <StyledBox>
                  {open ? <ExpandLess /> : <ExpandMore />}
                </StyledBox>
              </Grid>
            )}
          </>
        )}

        {size === MENU_ITEM_SIZE.LARGE && (
          <>
            <Grid item xs={2}>
              {Icon && (
                <Box>
                  <StyledBadge
                    badgeContent={menuItem?.alphaMessage}
                    variant='contained'
                  >
                    <StyledListItemIcon>
                      <Icon />
                    </StyledListItemIcon>
                  </StyledBadge>
                </Box>
              )}
            </Grid>
            <Grid item xs={onClick ? 8 : 10}>
              <Box>
                <ListItemText
                  data-testid={`single-menu-item-${menuItem.title}`}
                  primary={menuItem.title}
                  primaryTypographyProps={{
                    fontSize: 14,
                    fontWeight: 600,
                  }}
                />
              </Box>
            </Grid>
            {onClick && (
              <Grid item xs>
                <StyledBox>
                  {open ? <ExpandLess /> : <ExpandMore />}
                </StyledBox>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </StyledListButton>
  );
};
