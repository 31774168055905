import IAMOrganizationRouter from 'pages/OrganizationManagement/organization/Router';
import IdentityForm
  from 'pages/OrganizationManagement/organization/pages/OrganizationIdentity/IdentityForm';
import NavbarLayout from 'components/Layouts/NavbarLayout';
import OrganizationIdentity
  from 'pages/OrganizationManagement/organization/pages/OrganizationIdentity/OrganizationIdentity';
import PrivateRoute from 'components/Common/PrivateRoute';
import React from 'react';
import { Switch } from 'react-router-dom';
import { organizationManagement } from 'utils/spaUrls';
import { productPerm } from 'utils/appConstants';


const IAMRouter = () => (
  <Switch>
    <PrivateRoute
      path={organizationManagement.organization.landing}
      component={IAMOrganizationRouter}
    />
    <PrivateRoute
      path={organizationManagement.organization.identity.list}
      component={OrganizationIdentity}
      accessList={[productPerm.Transcoding]}
      exact
    />
    <PrivateRoute
      path={organizationManagement.organization.identity.add}
      component={IdentityForm}
      accessList={[productPerm.Transcoding]}
      exact
    />
    <PrivateRoute
      path={organizationManagement.organization.identity.edit()}
      component={IdentityForm}
      accessList={[productPerm.Transcoding]}
      exact
    />
  </Switch>
);

export { IAMRouter };

const IAMRouterWithNavbar = NavbarLayout(IAMRouter);

export default IAMRouterWithNavbar;
