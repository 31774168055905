import AddNewSectionButton from 'components/InternalAdmin/QuestionConfigure/AddNewSectionButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Grid from '@mui/material/Grid';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import IconButton from '@mui/material/IconButton';
import React from 'react';
import SectionHeader from 'components/IAM/Admin/SectionHeader';
import Tooltip from '@mui/material/Tooltip';
import { QuestionTextField } from 'components/InternalAdmin/QuestionConfigure/QuestionRenderer';
import { debounce } from '@mui/material';


const DEBOUNCE_TIMER = 40;

const QuestionQueryTemplates = ({ queryTemplates, onPropertyUpdate }) => {
  const [config, setConfig] = React.useState(queryTemplates);
  const [queryTextBoxRow, setQueryTextBoxRow] = React.useState(50);
  const onRowChange = (num) => { setQueryTextBoxRow(num); };

  const onPropertyArrayChange = React.useCallback(i => debounce((value) => {
    setConfig(prevState => ([
      ...prevState.slice(0, i),
      value,
      ...prevState.slice(i + 1),
    ]));
  }, DEBOUNCE_TIMER), []);

  const onAddItem = React.useCallback(() => {
    setConfig(prevState => ([
      ...prevState,
      '',
    ]));
  }, [setConfig]);

  const onDeleteItem = React.useCallback((i) => {
    setConfig(prevState => [
      ...prevState.slice(0, i),
      ...prevState.slice(i + 1),
    ]);
  }, [setConfig]);

  React.useEffect(() => {
    onPropertyUpdate('queryTemplates', config);
  }, [config, onPropertyUpdate]);

  return (
    <>
      <Grid container alignItems='flex-start'>
        <Grid item>
          <SectionHeader title='Query Template' />
        </Grid>
        <Grid item>
          <Tooltip title='Templates used for query'>
            <HelpOutlineIcon fontSize='small' style={{ marginLeft: '5px' }} />
          </Tooltip>
        </Grid>
      </Grid>
      {config.map((queryTemplate, index) => (
        <Grid container spacing={2} key={`query-template-${String(index)}`}>
          <Grid item xs={2}>
            <QuestionTextField
              fieldLabel='Rows to display'
              onChange={onRowChange}
              value={queryTextBoxRow || 5}
            />
          </Grid>
          <Grid item xs={11}>
            <QuestionTextField
              value={queryTemplate}
              fieldLabel='Query Template'
              multiline
              rows={queryTextBoxRow}
              onChange={onPropertyArrayChange(index)}
            />
          </Grid>
          {index !== 0 && (
          <Grid item>
            <IconButton aria-label='delete' onClick={() => { onDeleteItem(index); }} size='large'>
              <DeleteIcon />
            </IconButton>
          </Grid>
          )}
        </Grid>
      ))}
      <AddNewSectionButton list={config} onClick={onAddItem} stringRecord />
    </>
  );
};

export default QuestionQueryTemplates;
