import AddFunctionDialog from 'pages/DataIngestion/UserDefinedFunctions/AddFunctionDialog';
import HSearchComponent from 'BaseComponents/HSearchComponent';
import PageHeader from 'components/Common/PageHeader';
import React, { useCallback, useState } from 'react';
import UdfListTable from 'pages/DataIngestion/UserDefinedFunctions/UdfListTable';
import ViewUdfDialog from 'pages/DataIngestion/UserDefinedFunctions/ViewUdfDialog';
import { Add as AddIcon } from '@mui/icons-material';
import {
  Box, Button, Paper, Skeleton, Stack,
} from '@mui/material';
import { HOnRefreshButton } from 'BaseComponents/HTableControlPanel/HOnRefreshButton';
import { crumbs } from 'components/Common/BreadcrumbsNavigation';
import { debounce } from 'lodash';
import { rowsPerPageOptions } from 'utils/appConstants';
import { useDeleteUserDefinedFunction, useFetchUserDefinedFunctions } from 'api/userDefinedFunctions';


const UserDefinedFunctions = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const [searchTerm, setSearchTerm] = useState('');
  const [addFuncOpen, setAddFuncOpen] = useState(false);
  const [viewUdfID, setViewUdfID] = useState(null);

  const {
    isLoading: isUdfLoading,
    refetch: reFetchUserDefinedFunctions,
    data: {
      udfJobs: udfList,
      udfTypeMap = {},
      count: totalCount = 0,
    } = {},
  } = useFetchUserDefinedFunctions(page, rowsPerPage, searchTerm);

  const {
    mutate: deleteUdf,
  } = useDeleteUserDefinedFunction();

  const handleRefreshUDF = () => {
    reFetchUserDefinedFunctions();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce((value) => {
      setSearchTerm(value);
      setPage(0);
    }, 300),
    [],
  );

  const handleSearch = (newSearchTerm) => {
    debouncedSearch(newSearchTerm);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleAddFuncOpen = () => {
    setAddFuncOpen(true);
  };

  const handleAddFuncClose = () => {
    setAddFuncOpen(false);
  };

  const handleViewUdfOpen = (udfID) => {
    setViewUdfID(udfID);
  };

  const handleViewUdfClose = () => {
    setViewUdfID(null);
  };

  const handleDeleteUdf = (udfID) => {
    deleteUdf({ udfID });
  };

  return (
    <>
      <PageHeader
        title='User-Defined Functions'
        hideBackIcon
        breadcrumbs={[crumbs.home]}
      />
      <Paper elevation={0} sx={{ p: 2, mb: 2 }}>
        <Stack direction='row' spacing={1} justifyContent='space-between'>
          <Box>
            <HSearchComponent
              defaultValue=''
              onSearch={handleSearch}
            />
          </Box>
          <Stack direction='row' spacing={1}>
            <HOnRefreshButton
              disabled={isUdfLoading}
              onRefresh={handleRefreshUDF}
            />
            <Button
              variant='contained'
              color='secondary'
              startIcon={<AddIcon />}
              size='small'
              onClick={handleAddFuncOpen}
            >
              Add Function
            </Button>
          </Stack>
        </Stack>
      </Paper>
      {
        isUdfLoading
          ? <Skeleton variant='rectangular' width='100%' height={400} />
          : (
            <UdfListTable
              udfList={udfList}
              udfTypeMap={udfTypeMap}
              page={page}
              rowsPerPage={rowsPerPage}
              totalCount={totalCount}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              handleViewUdfOpen={handleViewUdfOpen}
              handleDeleteUdf={handleDeleteUdf}
            />
          )
      }
      {
        addFuncOpen && (
          <AddFunctionDialog
            addFuncOpen={addFuncOpen}
            handleAddFuncClose={handleAddFuncClose}
            handleRefreshUDF={handleRefreshUDF}
          />
        )
      }
      {
        viewUdfID && (
          <ViewUdfDialog
            udfID={viewUdfID}
            viewUdfOpen={Boolean(viewUdfID)}
            handleViewUdfClose={handleViewUdfClose}
          />
        )
      }
    </>
  );
};

export default UserDefinedFunctions;
