import Bouncer from 'components/Common/Bouncer';
import Box from '@mui/material/Box';
import ConfirmationDialog from 'components/Common/ConfirmationDialog';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import NoData from 'components/Common/NoData';
import PageHeader from 'components/Common/PageHeader';
import Paper from '@mui/material/Paper';
import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import { ButtonGroup, Card, Skeleton } from '@mui/material';
import { CopyToClipboard } from 'react-copy-to-clipboard/lib/Component';
import { HButton } from 'BaseComponents';
import { ListControls } from 'components/Common/ListControls';
import {
  UI_DISPLAY_DATE_TIME_FORMAT, userPermissions, userTypes,
} from 'utils/appConstants';
import { crumbs } from 'components/Common/BreadcrumbsNavigation';
import { showAlert } from 'redux/actions/AlertActions';
import {
  useCreateAPIKey,
  useDeleteAPIKey,
  useFetchAPIKey,
  useRotateAPISecret,
} from 'hooks/APIKeyManagement/keyManagementAPIs';
import { useDispatch, useSelector } from 'react-redux';


const APIKeyManagement = () => {
  const dispatch = useDispatch();
  const [clientID, updateClientID] = React.useState('');
  const permissionList = [userTypes.superuser, userTypes.accountAdmin, userPermissions.orgAdmin];
  const [showAPISecretModal, setShowAPISecretModal] = React.useState(true);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = React.useState(false);
  const orgId = useSelector(state => state.session.user.primaryOrganization.organization.ID);

  const {
    data: apiKeys,
    isLoading: fetchingKeys,
    refetch: refetchKeys,
  } = useFetchAPIKey(orgId);

  const {
    data: rotatedApiKey,
    mutate: rotateSecret,
    isLoading: rotatingSecret,
    isSuccess: isRotated,
  } = useRotateAPISecret(orgId);

  const {
    data: createdApiKey,
    isSuccess: isCreated,
    isLoading: generatingKey,
    mutate: createAPIKey,
  } = useCreateAPIKey(orgId);

  const {
    isLoading: deletingKey,
    mutate: deleteAPIKey,
  } = useDeleteAPIKey(orgId);

  const isEmpty = !Array.isArray(apiKeys) || !apiKeys.length;

  const onGenerateKey = () => {
    createAPIKey({});
    setShowAPISecretModal(true);
  };

  const onDelete = (clientId) => {
    updateClientID(clientId);
    setShowDeleteConfirmationModal(true);
  };

  const onRotateSecret = (clientId) => {
    const payload = {
      clientID: clientId,
    };
    rotateSecret({ payload });
    setShowAPISecretModal(true);
  };

  const handleCopy = () => {
    setShowAPISecretModal(false);
    dispatch(showAlert({ message: 'API Secret Copied!', type: 'success' }));
    refetchKeys();
  };

  const renderSecrets = () => {
    let secret = '';
    if (isEmpty && isCreated && createdApiKey.OrganizationID === orgId) {
      secret = createdApiKey.ClientSecret;
    }
    else if (isRotated && rotatedApiKey.OrganizationID === orgId) {
      secret = rotatedApiKey.ClientSecret;
    }

    if (secret.length > 0 && showAPISecretModal) {
      return (
        <Dialog
          open={showAPISecretModal}
          fullWidth
          maxWidth='md'
        >
          <DialogTitle id='api-secret-key-dialog-title'> API Secret </DialogTitle>
          <DialogContent>
            <Typography gutterBottom>
              <p>
                Make sure to copy your API Secret now.
                You will not be able to see it again!&nbsp;
              </p>
            </Typography>
            <Typography gutterBottom><b>{secret}</b></Typography>
          </DialogContent>
          <DialogActions>
            <CopyToClipboard text={secret}>
              <HButton onClick={handleCopy} variant='contained' color='primary' autoFocus>
                Copy To Clipboard
              </HButton>
            </CopyToClipboard>
          </DialogActions>
        </Dialog>
      );
    }
    return null;
  };

  const renderList = () => {
    if (!isEmpty) {
      return (
        <Paper sx={{ padding: '2rem' }} elevation={0}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Client Id</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Updated At</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {apiKeys.map((row) => (
                <TableRow key={row.ID}>
                  <TableCell><b>{row.ClientID}</b></TableCell>
                  <TableCell>{moment(row.CreatedAt).format(UI_DISPLAY_DATE_TIME_FORMAT)}</TableCell>
                  <TableCell>{moment(row.UpdatedAt).format(UI_DISPLAY_DATE_TIME_FORMAT)}</TableCell>
                  <TableCell>
                    <Bouncer allowList={permissionList}>
                      <ButtonGroup>
                        <HButton
                          color='inherit'
                          size='small'
                          variant='contained'
                          disabled={rotatingSecret}
                          onClick={() => onRotateSecret(row.ClientID)}
                        >
                          Rotate API Secret
                        </HButton>
                        <HButton
                          color='primary'
                          size='small'
                          variant='contained'
                          disabled={rotatingSecret}
                          onClick={() => onDelete(row.ClientID)}
                        >
                          Delete
                        </HButton>
                      </ButtonGroup>
                    </Bouncer>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      );
    }
    else if (fetchingKeys || generatingKey) {
      return (
        <Grid container wrap='wrap'>
          <Skeleton height={35} width='100%' />
          <Box width='100%' my={1}>
            <Skeleton variant='rectangular' height={400} />
          </Box>
        </Grid>
      );
    }
    else { return <NoData />; }
  };

  const renderDeleteConfirmationDialog = () => (
    <ConfirmationDialog
      message='This action cannot be undone!'
      open={showDeleteConfirmationModal}
      loading={deletingKey}
      onCancel={() => {
        setShowDeleteConfirmationModal(false);
        updateClientID('');
      }}
      onConfirm={() => {
        deleteAPIKey(clientID);
        setShowDeleteConfirmationModal(false);
      }}
      title='Delete API Key?'
      confirmActionLabel='Delete'
    />
  );

  return (
    <>
      <PageHeader
        title='Manage API Key'
        hideBackIcon
        breadcrumbs={[crumbs.home]}
      />
      <Card>
        <ListControls
          createButton={{
            text: 'Generate New Key',
            onClick: onGenerateKey,
            disabled: !isEmpty || generatingKey,
          }}
          createPermissions={permissionList}
        />
      </Card>
      <Box mt={3}>
        { renderList() }
      </Box>
      {renderSecrets()}
      {renderDeleteConfirmationDialog()}
    </>
  );
};

export default APIKeyManagement;
