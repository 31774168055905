import React, { memo } from 'react';
import { DataContextProvider } from 'BaseComponents/HDataTable/DataContextProvider';
import { HListView } from 'BaseComponents/HDataTable/HListView';


export const HDataTable = memo(({
  height = 0,
  columns,
  dataBaseField = 'data',
  filtersURL,
  defaultFilter = [],
  dataURL,
  dataURLPayload = {},
  tableName = '',
  actionMenu,
  actionButton,
  activeQuery,
  cardElement,
  zeroState,
  multiSelect,
  multiSelectField,
  subRowsProp,
  controlPanel,
}) => (
  <DataContextProvider
    height={height}
    columns={columns}
    dataBaseField={dataBaseField}
    tableName={tableName}
    filtersURL={filtersURL}
    defaultFilter={defaultFilter}
    dataURL={dataURL}
    dataURLPayload={dataURLPayload}
    zeroState={zeroState}
    actionMenu={actionMenu}
    actionButton={actionButton}
    activeQuery={activeQuery}
    cardElement={cardElement}
    multiSelect={multiSelect}
    multiSelectField={multiSelectField}
    subRowsProp={subRowsProp}
  >
    <HListView
      controlPanelElement={controlPanel}
    />
  </DataContextProvider>
));

HDataTable.displayName = 'HDataTable';

