export const FVA = {
  CREATE_FVA: 'CREATE_FVA',
  CREATE_FVA_ERROR: 'CREATE_FVA_ERROR',
  CREATE_FVA_SUCCESS: 'CREATE_FVA_SUCCESS',
  CHANGE_FVA_STATUS: 'CHANGE_FVA_STATUS',
  CHANGE_FVA_STATUS_ERROR: 'CHANGE_FVA_STATUS_ERROR',
  CHANGE_FVA_STATUS_SUCCESS: 'CHANGE_FVA_STATUS_SUCCESS',
  FETCH_FVA_FILTERS: 'FETCH_FVA_FILTERS',
  FETCH_FVA_FILTERS_ERROR: 'FETCH_FVA_FILTERS_ERROR',
  FETCH_FVA_FILTERS_SUCCESS: 'FETCH_FVA_FILTER_SUCCESS',
  FETCH_FVA_QUESTIONS_FILTERS: 'FETCH_FVA_QUESTIONS_FILTERS',
  FETCH_FVA_QUESTIONS_FILTERS_ERROR: 'FETCH_FVA_QUESTIONS_FILTERS_ERROR',
  FETCH_FVA_QUESTIONS_FILTERS_SUCCESS: 'FETCH_FVA_QUESTIONS_FILTERS_SUCCESS',
  CHANGE_FVA_QUESTION_STATUS: 'CHANGE_QUESTION_STATUS',
  CHANGE_FVA_QUESTION_STATUS_ERROR: 'CHANGE_QUESTION_STATUS_ERROR',
  CHANGE_FVA_QUESTION_STATUS_SUCCESS: 'CHANGE_QUESTION_STATUS_SUCCESS',
  FETCH_FVA_LIST: 'FETCH_FVA_LIST',
  FETCH_FVA_LIST_ERROR: 'FETCH_FVA_LIST_ERROR',
  FETCH_FVA_LIST_SUCCESS: 'FETCH_FVA_LIST_SUCCESS',
  FETCH_FVA_PARTNERS: 'FETCH_FVA_PARTNERS',
  FETCH_FVA_PARTNERS_ERROR: 'FETCH_FVA_PARTNERS_ERROR',
  FETCH_FVA_PARTNERS_SUCCESS: 'FETCH_FVA_PARTNERS_SUCCESS',
  FETCH_FVA_PARTNER_CREDENTIAL_SOURCES: 'FETCH_FVA_PARTNER_CREDENTIAL_SOURCES',
  FETCH_FVA_PARTNER_CREDENTIAL_SOURCES_ERROR: 'FETCH_FVA_PARTNER_CREDENTIAL_SOURCES_ERROR',
  FETCH_FVA_PARTNER_CREDENTIAL_SOURCES_SUCCESS: 'FETCH_FVA_PARTNER_CREDENTIAL_SOURCES_SUCCESS',
  FETCH_FVA_QUESTIONS: 'FETCH_FVA_QUESTIONS',
  FETCH_FVA_QUESTIONS_ERROR: 'FETCH_FVA_QUESTIONS_ERROR',
  FETCH_FVA_QUESTIONS_SUCCESS: 'FETCH_FVA_QUESTIONS_SUCCESS',
  FETCH_FVA_QUESTION_DETAILS: 'FETCH_FVA_QUESTION_DETAILS',
  FETCH_FVA_QUESTION_DETAILS_ERROR: 'FETCH_FVA_QUESTION_DETAILS_ERROR',
  FETCH_FVA_QUESTION_DETAILS_SUCCESS: 'FETCH_FVA_QUESTION_DETAILS_SUCCESS',
  FETCH_FVA_REPORT: 'FETCH_FVA_REPORT',
  FETCH_FVA_REPORT_ERROR: 'FETCH_FVA_REPORT_ERROR',
  FETCH_FVA_REPORT_SUCCESS: 'FETCH_FVA_REPORT_SUCCESS',
  FETCH_FVA_REPORT_FILTERS: 'FETCH_FVA_REPORT_FILTERS',
  FETCH_FVA_REPORT_FILTERS_ERROR: 'FETCH_FVA_REPORT_FILTERS_ERROR',
  FETCH_FVA_REPORT_FILTERS_SUCCESS: 'FETCH_FVA_REPORT_FILTER_SUCCESS',
  FETCH_FVA_REPORT_FREQ: 'FETCH_FVA_REPORT_FREQ',
  FETCH_FVA_REPORT_FREQ_ERROR: 'FETCH_FVA_REPORT_FREQ_ERROR',
  FETCH_FVA_REPORT_FREQ_SUCCESS: 'FETCH_FVA_REPORT_FREQ_SUCCESS',
  FETCH_FVA_SUMMARY: 'FETCH_FVA_SUMMARY',
  FETCH_FVA_SUMMARY_ERROR: 'FETCH_FVA_SUMMARY_ERROR',
  FETCH_FVA_SUMMARY_SUCCESS: 'FETCH_FVA_SUMMARY_SUCCESS',
  FVA_REPORT_RESET: 'FVA_REPORT_RESET',
};
