import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import React from 'react';
import SectionHeader from 'components/IAM/Admin/SectionHeader';
import Typography from '@mui/material/Typography';
import { Card, CardActions, Dialog } from '@mui/material';
import { HButton, HSpinner } from 'BaseComponents';


const ConfirmModal = ({
  open, onClose, onConfirm, loading,
  confirmButtonText = 'Yes', denyButtonText = 'No',
  title, body,

}) => {
  const onSubmit = e => {
    e.preventDefault();
    onConfirm();
  };
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth='md'>
      <form onSubmit={e => onSubmit(e)}>
        <Card>
          <CardContent>
            <Grid container>
              <Grid item xs={12}><SectionHeader title={title} /></Grid>
              <Grid item xs={12}>
                <Typography>
                  {body}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
          <Box display='flex' justifyContent='flex-end'>
            <CardActions>
              <HButton variant='outlined' color='inherit' onClick={onClose} size='small'>
                {denyButtonText}
              </HButton>
              <HButton
                variant='contained'
                color='primary'
                size='small'
                type='submit'
                endIcon={loading ? <HSpinner isButton /> : null}
                disabled={loading}
              >
                {confirmButtonText}
              </HButton>
            </CardActions>
          </Box>
        </Card>
      </form>
    </Dialog>
  );
};

export default ConfirmModal;
