import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import ListViewTable from 'components/Common/ListViewTable';
import PageHeader from 'components/Common/PageHeader';
import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortIconLabel from 'components/Common/TableSortIconLabel';
import useSortableTable from 'hooks/useSortableTable';
import { crumbs } from 'components/Common/BreadcrumbsNavigation';
import { rowsPerPageOptions } from 'utils/appConstants';
import { useFetchSnowflakeCliVersionAll } from 'hooks/snowflakeCliAPI';


const SnowflakeCliDashboard = () => {
  const { data } = useFetchSnowflakeCliVersionAll();
  const tableOptions = useSortableTable(data.cliVersions, { rowsPerPage: rowsPerPageOptions[0] });

  return (
    <>
      <PageHeader title='Snowflake CLI Dashboard' breadcrumbs={[crumbs.helium, crumbs.internalAdmin]} />
      <Grid container spacing={2} overflow='auto'>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container justifyContent='flex-start' alignItems='center' spacing={2}>
                <Grid item xs={5} align='left'>
                  {`Latest CLI Version: ${data.latestVersion || ''}`}
                </Grid>
                <Grid item xs={5} align='left'>
                  {`Minimum CLI Version: ${data.minVersion || ''}`}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} width='100%'>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ListViewTable list={data.cliVersions}>
                    <TableContainer>
                      <Table size='small'>
                        <TableHead>
                          <TableRow>
                            <TableCell align='left'>
                              <TableSortIconLabel
                                active={tableOptions.orderBy}
                                direction={tableOptions.orderDirection}
                                field='accountName'
                                onClick={tableOptions.setOrderBy}
                                title='Account Name'
                              />
                            </TableCell>
                            <TableCell align='left'>
                              <TableSortIconLabel
                                active={tableOptions.orderBy}
                                direction={tableOptions.orderDirection}
                                field='organizationName'
                                onClick={tableOptions.setOrderBy}
                                title='Organization Name'
                              />
                            </TableCell>
                            <TableCell align='left'>
                              <TableSortIconLabel
                                active={tableOptions.orderBy}
                                direction={tableOptions.orderDirection}
                                field='sfAccountID'
                                onClick={tableOptions.setOrderBy}
                                title='Snowflake Account ID'
                              />
                            </TableCell>
                            <TableCell align='left'>
                              <TableSortIconLabel
                                active={tableOptions.orderBy}
                                direction={tableOptions.orderDirection}
                                field='sfAccountName'
                                onClick={tableOptions.setOrderBy}
                                title='Snowflake Account Name'
                              />
                            </TableCell>
                            <TableCell align='left'>
                              <TableSortIconLabel
                                active={tableOptions.orderBy}
                                direction={tableOptions.orderDirection}
                                field='sfOrgName'
                                onClick={tableOptions.setOrderBy}
                                title='Snowflake Organization Name'
                              />
                            </TableCell>
                            <TableCell align='left'>
                              <TableSortIconLabel
                                active={tableOptions.orderBy}
                                direction={tableOptions.orderDirection}
                                field='cliVersion'
                                onClick={tableOptions.setOrderBy}
                                title='CLI Version'
                              />
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {tableOptions.pageList.map(item => (
                            <TableRow key={item.organizationID}>
                              <TableCell>
                                {item.accountName}
                              </TableCell>
                              <TableCell>
                                {item.organizationName}
                              </TableCell>
                              <TableCell>
                                {item.sfAccountID}
                              </TableCell>
                              <TableCell>
                                {item.sfAccountName}
                              </TableCell>
                              <TableCell>
                                {item.sfOrgName}
                              </TableCell>
                              <TableCell>
                                {item.cliVersion}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      backIconButtonProps={{ 'aria-label': 'Previous Page' }}
                      component='div'
                      count={tableOptions.count}
                      onPageChange={(e, newPage) => tableOptions.setPage(newPage)}
                      onRowsPerPageChange={e => tableOptions.setRowsPerPage(e.target.value)}
                      nextIconButtonProps={{ 'aria-label': 'Next Page' }}
                      page={tableOptions.page}
                      rowsPerPage={tableOptions.rowsPerPage}
                      rowsPerPageOptions={rowsPerPageOptions}
                    />
                  </ListViewTable>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default SnowflakeCliDashboard;
