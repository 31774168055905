import { ORGANIZATION_PARAMETERS } from 'redux/actions/Types';
import { organizationParameters } from 'utils/urlConstants';


export const fetchOrgParameters = orgId => ({
  type: ORGANIZATION_PARAMETERS.FETCH_ORG_PARAMETERS,
  url: organizationParameters(orgId).list,
});

export const fetchOrgParametersSuccess = payload => ({
  type: ORGANIZATION_PARAMETERS.FETCH_ORG_PARAMETERS_SUCCESS,
  payload,
});

export const fetchOrgParametersError = payload => ({
  type: ORGANIZATION_PARAMETERS.FETCH_ORG_PARAMETERS_ERROR,
  payload,
});

export const createOrgParameters = (orgId, payload) => ({
  type: ORGANIZATION_PARAMETERS.CREATE_ORG_PARAMETERS,
  url: organizationParameters(orgId).create,
  payload,
});

export const createOrgParametersSuccess = payload => ({
  type: ORGANIZATION_PARAMETERS.CREATE_ORG_PARAMETERS_SUCCESS,
  payload,
});

export const createOrgParametersError = payload => ({
  type: ORGANIZATION_PARAMETERS.CREATE_ORG_PARAMETERS_ERROR,
  payload,
});

export const updateOrgParameter = (orgId, paramName, payload) => ({
  type: ORGANIZATION_PARAMETERS.UPDATE_ORG_PARAMETER,
  url: organizationParameters(orgId).update(paramName),
  payload,
});

export const updateOrgParameterSuccess = payload => ({
  type: ORGANIZATION_PARAMETERS.UPDATE_ORG_PARAMETER_SUCCESS,
  payload,
});

export const updateOrgParameterError = payload => ({
  type: ORGANIZATION_PARAMETERS.UPDATE_ORG_PARAMETER_ERROR,
  payload,
});

export const deleteOrgParameter = (orgId, paramName) => ({
  type: ORGANIZATION_PARAMETERS.DELETE_ORG_PARAMETER,
  url: organizationParameters(orgId).delete(paramName),
});

export const deleteOrgParametersSuccess = payload => ({
  type: ORGANIZATION_PARAMETERS.DELETE_ORG_PARAMETER_SUCCESS,
  payload,
});

export const deleteOrgParametersError = payload => ({
  type: ORGANIZATION_PARAMETERS.DELETE_ORG_PARAMETER_ERROR,
  payload,
});

