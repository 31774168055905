import { applyNodeChanges } from 'reactflow';


export const updateFlowNodesQuestion = (newNode, initNodesState) => {
  const { data } = newNode;
  const [currentNode = {}] = initNodesState.filter(node => node.id === data.nodeId);
  currentNode.data = data;

  return applyNodeChanges([currentNode], initNodesState);
};
