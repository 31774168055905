import React from 'react';
import TableCell from '@mui/material/TableCell';
import Tooltip from '@mui/material/Tooltip';
import { Typography } from '@mui/material';


const NoWrapCell = (
  { cellProps, str, children = <Typography noWrap variant='subtitle1'>{str}</Typography> },
) => (
  <TableCell {...cellProps}>
    <Tooltip title={str}>
      {children}
    </Tooltip>
  </TableCell>
);

export default NoWrapCell;
