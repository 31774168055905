import React from 'react';
import {
  Autocomplete,
  Chip,
  Stack,
  TextField,
} from '@mui/material';

/**
 * @param {Array<Object>} value {ID: string, value: string}
 */
export const HTagsTextField = ({
  value = [],
  options = [],
  disabled,
  onDelete,
  onDeleteAll,
  onChange,
  id = 'tags-field',
  label = '',
  placeholder = '',
  chipProps = {},
  ...restProps
}) => {
  const dataTestid = restProps['data-testid'] ?? 'HTagsTextField';

  const handleDeleted = (element, removeText, index) => {
    onDelete({
      element,
      removeText,
      index,
      deletedElement: value[index],
    });
  };

  const handleOnChange = (element, newArray, action, newOption) => {
    const text = newOption?.option.trim();

    if (text === '') {
      return;
    }

    if (action === 'clear') {
      onDeleteAll();
      return;
    }

    if (action === 'removeOption') {
      const index = value.findIndex((o) => o.value === text);
      onDelete({
        element,
        label: text,
        index,
        deletedElement: value[index],
      });
    }

    if (action === 'createOption' || action === 'selectOption') {
      onChange(text);
    }
  };

  return (
    <Stack direction='row' spacing={1}>
      <Autocomplete
        disabled={disabled}
        fullWidth
        multiple
        id={id}
        options={options}
        value={value.map((tag) => tag.value)}
        onChange={handleOnChange}
        freeSolo
        renderTags={(values, getTagProps) => values
          .map((option, index) => (
            <Chip
              variant='outlined'
              label={option}
              {...getTagProps({ index })}
              onDelete={(e) => handleDeleted(e, option, index)}
              {...chipProps}
            />
          ))}
        renderInput={(params) => (
          <TextField
            {...params}
            variant='outlined'
            label={label}
            placeholder={placeholder}
            data-testid={dataTestid}
            error={restProps.error}
          />
        )}
        {...restProps}
      />
    </Stack>
  );
};
