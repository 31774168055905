import CrOrgInvitationApiModal from 'components/CleanRooms/CrOrgInvitationApiModal';
import CrPartnerInvitationTable from 'components/CleanRooms/CrPartnerInvitationTable';
import ListViewTable from 'components/Common/ListViewTable';
import PageHeader from 'components/Common/PageHeader';
import React from 'react';
import { CATEGORY_NAMES } from 'synthetic-redux';
import { Fullscreen } from '@mui/icons-material';
import { crumbs } from 'components/Common/BreadcrumbsNavigation';
import { fetchCrPartnerInvites, linkCrPartners } from 'pages/CleanRooms/redux/CrPartners';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';


const CrSolutionInvitations = () => {
  const orgId = useSelector(state => state.session.user.primaryOrganization.organization.ID);
  const partnerInvites = useSelector(state => state.crTgPartners.fetchCrPartnerInvites?.response);
  const linkPartner = useSelector(state => state.crTgPartners.linkCrPartners?.response);
  const [addOrgApiKeyModal, setAddOrgApiKeyModal] = React.useState(false);
  const [invitationID, setInvitationID] = React.useState('');
  const [cleanRoomID, setCleanRoomID] = React.useState('');
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchCrPartnerInvites.ignite(orgId));
  }, [dispatch, orgId]);

  const onLinkOrg = (inviteID, crID) => {
    setInvitationID(inviteID);
    setCleanRoomID(crID);
    setAddOrgApiKeyModal(true);
  };

  const onClose = () => {
    setAddOrgApiKeyModal(false);
    dispatch(linkCrPartners.reset());
  };

  const onSave = (apiKey) => {
    const payload = { invitationID, partnerAPIKey: apiKey };
    dispatch(linkCrPartners.ignite(orgId, cleanRoomID, payload));
    onClose();
  };

  React.useEffect(() => {
    if (linkPartner) {
      if (linkPartner.success) {
        dispatch(showAlert({ message: 'API key entry success', type: 'success' }));
      }
      if (linkPartner.error) {
        dispatch(showAlert({ message: linkPartner.error, type: 'error' }));
      }
      dispatch(linkCrPartners.reset());
      dispatch(fetchCrPartnerInvites.ignite(orgId));
    }
  }, [linkPartner, dispatch, orgId]);



  return (
    <>
      <PageHeader
        Icon={Fullscreen}
        breadcrumbs={[crumbs.home]}
        title='Clean Room API Linking'
      />
      <ListViewTable list={partnerInvites?.invitations}>
        <CrPartnerInvitationTable
          list={partnerInvites?.invitations}
          onLinkOrg={onLinkOrg}
        />
      </ListViewTable>
      {addOrgApiKeyModal ? (
        <CrOrgInvitationApiModal
          open={addOrgApiKeyModal}
          onClose={onClose}
          loading={linkPartner && linkPartner[CATEGORY_NAMES.IGNITE]}
          onSave={data => onSave(data)}
          error={linkPartner?.error}
        />
      ) : null}
    </>
  );
};

export default CrSolutionInvitations;
