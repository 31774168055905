import PermissionService from 'components/Common/PermissionService';
import React, {
  createContext, useRef, useState,
} from 'react';
import { deleteUserReset } from 'redux/actions/IamActions';
import { organizationManagement } from 'utils/spaUrls';
import { useBeginImpersonation } from 'hooks/Impersonation/ImpersonationAPI';
import { useDeleteOrganizationUser } from 'hooks/organizationAPI';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';


export const OrganizationUsersContext = createContext({});

export const OrganizationUsersContextProvider = ({
  list, sendEmail, deleteUser, currentOrgId, children,
}) => {
  const [userList, setUserList] = React.useState(list);
  const [selectedUser, setSelectedUser] = React.useState({});
  const [showModal, setShowModal] = React.useState(false);
  const [toggleUserDelete, setToggleUserDelete] = React.useState('');
  const impersonationBegin = useBeginImpersonation();
  const currentUser = useSelector(state => state.session.user.user);
  const deletedUserResponse = useSelector(state => state.iam.meta);
  const [impersonateUser, setImpersonateUser] = useState();
  const history = useHistory();
  const dispatch = useDispatch();
  const orgId = useSelector(state => state.session.user.primaryOrganization.organization.ID);
  const refsObject = useRef();

  const {
    isLoading: isDeleting,
    isSuccess: isDeleted,
    mutateAsync: asyncDeleteOrgUser,
    reset: resetDeleteOrganizationUserState,
  } = useDeleteOrganizationUser();

  React.useEffect(() => {
    if (isDeleted) {
      const filteredUserList = userList?.filter(
        user => user.user.ID !== selectedUser.ID,
      );
      setUserList(filteredUserList);
      resetDeleteOrganizationUserState();
    }
  }, [isDeleted, resetDeleteOrganizationUserState, selectedUser, userList]);

  React.useEffect(() => {
    if (deletedUserResponse?.deleted) {
      const filteredUserList = userList?.filter(
        user => user.user.ID !== selectedUser.ID,
      );
      setUserList(filteredUserList);
      dispatch(deleteUserReset());
    }
  }, [deletedUserResponse, dispatch, selectedUser, userList]);

  React.useEffect(() => {
    setUserList(list);
  }, [list]);

  const onCloseModal = () => {
    setShowModal(false);
  };

  const clickedDeactivateUser = row => {
    setToggleUserDelete('USER');
    setSelectedUser(row.user);
    setShowModal(true);
  };

  const clickedDeleteOrgUser = row => {
    setToggleUserDelete('ORG_USER');
    setSelectedUser(row.user);
    setShowModal(true);
  };

  const deactivateUser = async () => {
    await deleteUser(selectedUser.ID);
    refsObject.current?.refreshListData();
    setShowModal(false);
  };

  const deleteOrgUser = async () => {
    await asyncDeleteOrgUser({ organizationID: currentOrgId, userID: selectedUser.ID });
    refsObject.current?.refreshListData();
    setShowModal(false);
  };

  const getActions = row => {
    const isAuthorizedSuperUser = PermissionService.isAuthorizedSuperUser();
    const actions = [
      {
        label: 'Edit User',
        name: 'Edit User',
        action: () => history.push(
          organizationManagement.organization.users.edit(row.user.ID),
        ),
      }, {
        label: 'Resend Invite',
        name: 'Resend Invite',
        action: () => sendEmail(row.user.email),
      }, {
        label: 'Deactivate User',
        name: 'Deactivate User',
        action: () => clickedDeactivateUser(row),
      }, {
        label: 'Remove from organization',
        name: 'Remove from organization',
        action: () => clickedDeleteOrgUser(row),
      },
    ];

    if (isAuthorizedSuperUser) {
      actions.push({
        label: 'View As',
        name: 'View As',
        action: () => setImpersonateUser(row),
      });
    }
    return actions;
  };

  const impersonate = (selectedUserArg, comments) => {
    impersonationBegin.mutate({
      ImpersonatorID: currentUser.ID,
      UserID: selectedUserArg.user.ID,
      comments,
    });
  };

  const providerObject = {
    refsObject,
    selectedUser,
    showModal,
    toggleUserDelete,
    impersonateUser,
    orgId,
    isDeleting,
    onCloseModal,
    deactivateUser,
    deleteOrgUser,
    getActions,
    impersonate,
    impersonationBegin,
    setImpersonateUser,
    deletedUserResponse,
  };

  return (
    <OrganizationUsersContext.Provider value={providerObject}>
      {children}
    </OrganizationUsersContext.Provider>
  );
};
