import { ACTIVATION_CHANNELS } from 'redux/actions/Types';
import { LAYOUTS } from 'utils/constants/partners.constant';


const initialState = {
  layout: LAYOUTS.list,
  selectedPlatform: null,
  selectedCred: null,
  searchText: '',
};

const ActivationChannelsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIVATION_CHANNELS.SET_LAYOUT:
      return { ...state, layout: action.payload };
    case ACTIVATION_CHANNELS.SET_SELECTED_PLATFORM:
      return { ...state, selectedPlatform: action.payload };
    case ACTIVATION_CHANNELS.SET_SELECTED_CRED:
      return { ...state, selectedCred: action.payload };
    case ACTIVATION_CHANNELS.SET_SEARCH_TEXT:
      return { ...state, searchText: action.payload };
    default:
      return state;
  }
};

export default ActivationChannelsReducer;
