import React, { memo } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { Chip, Typography } from '@mui/material';
import { FLOW_STAGE } from 'utils/ignoramusEnums';


export const FlowStageCell = memo(({ row }) => (
  <Tooltip title={FLOW_STAGE[row?.stage].label} placement='top'>
    <Chip
      label={<Typography variant='body2'>{FLOW_STAGE[row?.stage].label}</Typography>}
      sx={{
        backgroundColor: FLOW_STAGE[row?.stage].color,
        color: FLOW_STAGE[row?.stage].stageTextColor,
      }}
    />
  </Tooltip>
));

