import ClickAwayListener from '@mui/material/ClickAwayListener';
import React from 'react';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';


export const HtmlTooltip = styled(({ className, onClickAway, ...props }) => (
  <ClickAwayListener onClickAway={onClickAway}>
    <Tooltip {...props} classes={{ popper: className }} />
  </ClickAwayListener>
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    width: 300,
    padding: 10,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));
