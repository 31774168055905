import { LOOKUPS } from 'redux/actions/Types';


const initialState = {
  contentVerticals: [],
  contentCategories: [],
  domains: [],
  permissions: [],
  questionTypes: [],
  propertyTypes: [],
};

const lookupsReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOOKUPS.RECEIVE_CONTENT_VERTICALS:
      return { ...state, contentVerticals: action.payload || [] };
    case LOOKUPS.RECEIVE_CONTENT_CATEGORIES:
      return { ...state, contentCategories: action.payload || [] };
    case LOOKUPS.RECEIVE_DOMAINS:
      return { ...state, domains: action.payload || [] };
    case LOOKUPS.RECEIVE_PERMISSIONS:
      return { ...state, permissions: action.payload || [] };
    case LOOKUPS.RECEIVE_QUESTION_TYPES:
      return { ...state, questionTypes: action.payload || [] };
    case LOOKUPS.FETCH_PROPERTY_TYPES_SUCCESS:
      return { ...state, propertyTypes: action.payload || [] };
    default:
      return state;
  }
};

export default lookupsReducer;
