import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import MotifStepIcon from 'BaseComponents/MotifStepIcon';
import PageHeader from 'components/Common/PageHeader';
import Paper from '@mui/material/Paper';
import PermissionConfigurationForm from 'pages/InternalAdmin/application/pages/PermissionWizard/PermissionConfigurationForm';
import React from 'react';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { HButton } from 'BaseComponents';
import { Link, Redirect } from 'react-router-dom';
import { StepConnector } from '@mui/material';
import { crumbs } from 'components/Common/BreadcrumbsNavigation';
import { internalAdmin } from 'utils/spaUrls';


const PermissionWizardForm = ({ permissionDetails, handlers }) => {
  const [permission, updatePermission] = React.useState(permissionDetails);
  const steps = ['Create Permission'];
  const [activeStep, setActiveStep] = React.useState(0);

  const handleBack = () => setActiveStep(prevActiveStep => prevActiveStep - 1);
  const handleNext = (e) => {
    if (activeStep === 0) {
      handlers.submitPermission({
        permission: {
          name: permission.name,
          displayName: permission.displayName,
          isInternal: permission.isInternal,
        },
      });
    }
    setActiveStep(prevActiveStep => prevActiveStep + 1);
    e.preventDefault();
  };

  const getStepContent = (activeStepIdx) => {
    switch (activeStepIdx) {
      case 0:
        return (
          <PermissionConfigurationForm
            permissionDetails={permission}
            updatePermission={updatePermission}
          />
        );
      default:
        return <Redirect to={internalAdmin.application.productPermissions} />;
    }
  };

  return (
    <>
      <PageHeader title='Create Permission' breadcrumbs={[crumbs.helium, crumbs.internalAdmin]} />
      <form onSubmit={handleNext}>
        <Grid container spacing={3} direction='column'>
          <Grid item>
            <Stepper
              activeStep={activeStep}
              connector={<StepConnector />}
              alternativeLabel
            >
              {steps.map(label => (
                <Step key={label}>
                  <StepLabel
                    color='secondary'
                    StepIconComponent={MotifStepIcon}
                  >
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>
          <Grid item xs={12}>
            <Paper sx={{ padding: 2 }}>
              {getStepContent(activeStep)}
            </Paper>
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
          <Grid item>
            <Paper sx={{ padding: 2 }}>
              <Grid container justifyContent='flex-end' spacing={2}>
                <Grid item>
                  <HButton
                    to={internalAdmin.application.productPermissions}
                    href={internalAdmin.application.productPermissions}
                    variant='contained'
                    component={Link}
                    color='inherit'
                  >
                    Cancel
                  </HButton>
                </Grid>
                <Grid item>
                  <HButton
                    color='primary'
                    disabled={activeStep === 0}
                    variant={activeStep === 0 ? 'text' : 'contained'}
                    onClick={handleBack}
                  >
                    Previous
                  </HButton>
                </Grid>
                <Grid item>
                  <HButton
                    variant='contained'
                    color='primary'
                    type='submit'
                  >
                    {activeStep === steps.length - 1 ? 'Save' : 'Next'}
                  </HButton>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default PermissionWizardForm;
