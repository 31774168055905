export const DATA_CONNECTION = {
  RESET: 'DATA_CONNECTION/RESET',
  SET_ACTIVE_STEP: 'DATA_CONNECTION/SET_ACTIVE_STEP',
  UPDATE_JOB: 'DATA_CONNECTION/UPDATE_JOB',
  SET_PARAMETERS: 'DATA_CONNECTION/SET_PARAMETERS',
  MERGE_PARAMETERS: 'DATA_CONNECTION/MERGE_PARAMETERS',
  UPDATE_PARAMETER: 'DATA_CONNECTION/UPDATE_PARAMETER',
  UPDATE_UPLOAD_FILES: 'DATA_CONNECTION/UPDATE_UPLOAD_FILES',
  UPDATE_ERRORS: 'DATA_CONNECTION/UPDATE_ERRORS',
};

