import React, { memo, useCallback, useContext } from 'react';
import {
  AlertsRequestPageContext,
} from 'pages/InternalAdmin/organization/pages/AlertsRequest/AlertsRequestContext';
import { CHAT_STATUS } from 'pages/InternalAdmin/organization/pages/AlertsRequest/constant';
import { HButton } from 'BaseComponents/HButton';
import { Tooltip } from '@mui/material';
import { internalAdmin } from 'utils/spaUrls';
import { useHistory } from 'react-router-dom';


export const CreateAlertCell = memo(({ row }) => {
  const history = useHistory();
  const { crId, isCurrentCrExpired } = useContext(AlertsRequestPageContext);

  const handleOnCreateAlertClick = useCallback(() => {
    history.push({
      pathname: internalAdmin.organization.alertBuilder(crId),
      state: { chatID: row.chatID },
    });
  }, [crId, history, row.chatID]);

  return (CHAT_STATUS.COMPLETE.key === row.status) ? (
    <Tooltip title={isCurrentCrExpired ? 'Clean Room Expired. Unable to create alert.' : ''}>
      <span>
        <HButton
          variant='contained'
          onClick={handleOnCreateAlertClick}
          disabled={isCurrentCrExpired}
        >
          Create Alert
        </HButton>
      </span>
    </Tooltip>
  ) : 'Chat Request Processed';
});

CreateAlertCell.displayName = 'CreateAlertCell';
