import Logger from 'components/Common/Logger';
import localStorage from 'utils/localStorage';
import { CACHE } from 'utils/appConstants';
import { SUCCESS_SUFFIX } from 'synthetic-redux';
import { call, put, takeLatest } from 'redux-saga/effects';

// eslint-disable-next-line prefer-template
const importer = async url => import('ProjectHelium/stubs/' + url);

export const demoSagaEffect = (syntheticAction) => {
  const suffixActions = syntheticAction.suffixSyntheticActions;
  const successAction = suffixActions[`${syntheticAction.igniteName}${SUCCESS_SUFFIX}`];
  const igniteAction = syntheticAction.igniteName;
  const accId = localStorage.get(CACHE.user.primaryAccount);
  const orgId = localStorage.get(CACHE.user.primaryOrg);
  const accPrefix = `${accId}/${orgId}`;
  const defaultPrefix = 'DefaultAccount/DefaultOrg';
  const sagaFn = function* sagaFn(action) {
    let payload;
    const defaultUrl = `${defaultPrefix}${action.stub}`;
    const specificUrl = `${accPrefix}${action.stub}`;
    try {
      const stub = yield call(importer, specificUrl);
      payload = stub.default;
    }
    catch (err) {
      // Stubs for the demo account haven't been uploaded
      try {
        const stub = yield call(importer, defaultUrl);
        payload = stub.default;
      }
      catch (anotherErr) {
        // Stubs for default don't exist either!
        Logger.error(`Missing stubs for ${action.type}`, 'Demo data fetch');
        payload = {};
      }
    }
    return yield put(successAction.generator({ payload }));
  };
  return takeLatest(igniteAction, sagaFn);
};
