import Grid from '@mui/material/Grid';
import PageHeader from 'components/Common/PageHeader';
import QuestionDataTypes from 'components/InternalAdmin/QuestionConfigure/QuestionDataTypes';
import QuestionDimensions from 'components/InternalAdmin/QuestionConfigure/QuestionDimensions';
import QuestionMetrics from 'components/InternalAdmin/QuestionConfigure/QuestionMetrics';
import QuestionParameters from 'components/InternalAdmin/QuestionConfigure/QuestionParameters';
import QuestionQueryTemplates from 'components/InternalAdmin/QuestionConfigure/QuestionQueryTemplates';
import React from 'react';
import SectionHeader from 'components/IAM/Admin/SectionHeader';
import UserListQuestionSelect from 'components/InternalAdmin/QuestionConfigure/UserListQuestionSelect';
import {
  Card, CardContent, Dialog, Divider, Typography, debounce,
} from '@mui/material';
import { Group } from '@mui/icons-material';
import { HButton } from 'BaseComponents';
import { OUTPUT_FORMAT, getOutputFormatOptions } from 'utils/constants/question.constant';
import {
  QuestionSelectField,
  QuestionTextField,
} from 'components/InternalAdmin/QuestionConfigure/QuestionRenderer';
import { StorageProvidersMap, VisualizationTypesMap } from 'components/CleanRooms/constants';
import {
  createCrQuestion,
  fetchAllImportDataTypes,
  updateCrQuestion,
} from 'pages/InternalAdmin/application/CleanRoom/redux/actions';
import { crumbs } from 'components/Common/BreadcrumbsNavigation';
import { useDispatch, useSelector } from 'react-redux';
import { validateParameters } from 'components/CleanRooms/service';


const DEBOUNCE_TIMER = 40;

const storageProviders = Object.values(StorageProvidersMap)
  .map(i => ({ name: i.label, value: i.key }));

const visualizationTypes = Object.values(VisualizationTypesMap)
  .filter(v => v !== VisualizationTypesMap.HEATMAP)
  .map(i => ({ name: i.label, value: i.key }));

const convertDataTypesToSelectList = (dataTypes = []) => dataTypes.map((dataType) => {
  if (dataTypes.error) return [];
  return {
    ID: dataType.id,
    value: dataType.id,
    name: dataType.displayName,
    displayName: dataType.name,
  };
});

export const CrQuestionContext = React.createContext({});

// New context to separate each property of question so that update on any property won't trigger
// the re-render on every property component in the future.
export const CrQuestionPropertiesContext = React.createContext({});

const CrQuestionFormBuilder = ({ questionId, data, onBack }) => {
  const dispatch = useDispatch();
  const dataTypes = useSelector(state => convertDataTypesToSelectList(
    state.internalAdmin.application.cleanRoomAdmin.dataTypes || [],
  ));
  const [question, setQuestion] = React.useState(data);
  const [error, setError] = React.useState({ visible: false, title: '', description: '' });

  React.useEffect(() => {
    dispatch(fetchAllImportDataTypes());
  }, [dispatch]);

  const handleOnErrorClosed = () => {
    setError({ visible: false, title: '', description: '' });
  };

  // Validate all the run time parameter and set error if any parameter is invalid.
  React.useEffect(() => {
    const valid = validateParameters(question.parameters);
    if (!valid) {
      setError({
        visible: true,
        title: 'Duplicate Runtime Parameters',
        description: `Multiple runtime parameters with same name or display name encountered.
              Runtime parameters should be unique.`,
      });
    }
  }, [question.parameters, setError]);

  const onPropertyChange = React.useCallback(field => debounce((value) => {
    setQuestion(prevState => ({
      ...prevState,
      [field]: value,
    }));
  }, DEBOUNCE_TIMER), [setQuestion]);

  const onPropertyUpdate = React.useCallback((property, value) => {
    setQuestion(prevState => ({
      ...prevState,
      [property]: value,
    }));
  }, []);

  const onSave = React.useCallback(() => {
    const valid = validateParameters(question.parameters);
    if (!valid) {
      setError({
        visible: true,
        title: 'Duplicate Runtime Parameters',
        description: `Multiple runtime parameters with same name or display name encountered.
              Runtime parameters should be unique.`,
      });
      return;
    }

    const payload = {
      ...question,
      parameters: question.parameters.filter(param => param.index >= 0)
        .map((i, index) => ({ ...i, index })),
    };
    if (question.outputFormat !== OUTPUT_FORMAT.REPORT) {
      payload.dimensions = [];
      payload.metrics = [];
    }
    if (question.ID) {
      dispatch(updateCrQuestion(question.ID, { question: payload }));
    }
    else {
      dispatch(createCrQuestion({ question: payload }));
    }
    /* eslint-disable-next-line */
  }, [dispatch, question]);

  return (
    <>
      <PageHeader
        title={questionId ? 'Edit Question' : 'New Question'}
        Icon={Group}
        breadcrumbs={[crumbs.helium, crumbs.internalAdmin]}
      />
      <Dialog open={error.visible} onClose={handleOnErrorClosed} fullWidth maxWidth='sm'>
        <Card>
          <CardContent>
            <Typography variant='h6'>{`Error: ${error?.title}`}</Typography>
          </CardContent>
          <Divider />
          <CardContent>
            <Typography variant='caption'>{error?.description}</Typography>
          </CardContent>
          <Divider />
          <CardContent>
            <Grid container spacing={2}>
              <Grid container item xs={12} flexDirection='row-reverse'>
                <HButton variant='outlined' onClick={handleOnErrorClosed}>Close</HButton>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Dialog>
      <form onSubmit={(e) => {
        e.preventDefault();
        onSave();
      }}
      >
        <CrQuestionContext.Provider value={question}>
          <Card>
            <CardContent>
              <UserListQuestionSelect
                question={question}
                onPropertyUpdate={onPropertyUpdate}
                cannotEdit={!!questionId}
              />
            </CardContent>
            <Divider />
            <CardContent>
              <SectionHeader title='Question Metadata' />
              <Grid container spacing={2} alignItems='center'>
                <Grid item xs={12} md={8}>
                  <QuestionTextField
                    value={question.title}
                    fieldLabel='Question Text'
                    onChange={onPropertyChange('title')}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <QuestionTextField
                    value={question.category}
                    fieldLabel='Use Case/Category'
                    onChange={onPropertyChange('category')}
                  />
                </Grid>
                <Grid item xs={12} md={11}>
                  <QuestionTextField
                    value={question.description}
                    fieldLabel='Question Description'
                    onChange={onPropertyChange('description')}
                    multiline
                    rows={6}
                    required={false}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />

            <CardContent>
              <QuestionDataTypes
                allDataTypes={dataTypes}
                dataTypes={question.dataTypes}
                onPropertyUpdate={onPropertyUpdate}
              />
            </CardContent>
            <Divider />
            <CardContent>
              <Grid container spacing={2} alignItems='center'>
                <Grid item xs={12} md={2}>
                  <QuestionSelectField
                    value={question.outputFormat}
                    fieldLabel='Output Format'
                    list={getOutputFormatOptions}
                    onChange={onPropertyChange('outputFormat')}
                  />
                </Grid>
                {!question.outputFormat || question.outputFormat === OUTPUT_FORMAT.REPORT ? (
                  <>
                    <Grid item xs={12} md={2}>
                      <QuestionSelectField
                        value={question.visualization}
                        fieldLabel='Select Graph'
                        list={visualizationTypes}
                        onChange={onPropertyChange('visualization')}
                      />
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <QuestionSelectField
                        value={question.storageProvider}
                        fieldLabel='Storage Provider'
                        list={storageProviders}
                        onChange={onPropertyChange('storageProvider')}
                      />
                    </Grid>
                  </>
                ) : null}
              </Grid>
            </CardContent>
            <Divider />
            {!question.outputFormat || question.outputFormat === OUTPUT_FORMAT.REPORT ? (
              <>
                <CardContent>
                  <QuestionDimensions
                    dimensions={question.dimensions}
                    onPropertyUpdate={onPropertyUpdate}
                    isUserList={question.isUserListQuestion}
                  />
                </CardContent>
                <Divider />
                <CardContent>
                  <QuestionMetrics
                    metrics={question.metrics}
                    isAggregate={question.isAggregate}
                    onPropertyUpdate={onPropertyUpdate}
                    isUserList={question.isUserListQuestion}
                  />
                </CardContent>
                <Divider />
              </>
            ) : null}
            <CrQuestionPropertiesContext.Provider
              value={{
                hasDateParameters: question.hasDateParameters,
                parameters: question.parameters,
                onPropertyUpdate,
              }}
            >
              <CardContent>
                <QuestionParameters />
              </CardContent>
            </CrQuestionPropertiesContext.Provider>
            <Divider />
            {!question.outputFormat || question.outputFormat === OUTPUT_FORMAT.REPORT ? (
              <CardContent>
                <QuestionQueryTemplates
                  queryTemplates={question.queryTemplates}
                  onPropertyUpdate={onPropertyUpdate}
                />
              </CardContent>
            ) : null}
            <Divider />
            <CardContent>
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant='caption'> - * Indicates required field</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Grid container spacing={2} direction='row-reverse'>
                    <Grid item>
                      <HButton variant='contained' color='primary' type='submit'>{`${questionId ? 'Save Question' : 'Create Question'}`}</HButton>
                    </Grid>
                    <Grid item>
                      <HButton variant='outlined' color='primary' onClick={() => { onBack(); }}>Cancel</HButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </CrQuestionContext.Provider>
      </form>
    </>
  );
};

export default CrQuestionFormBuilder;
