import Button from '@mui/material/Button';
import React from 'react';
import { styled, tableCellClasses, tableClasses } from '@mui/material';


const StyledButton = styled(Button)(({ theme }) => ({
  [`.${tableClasses.root} .${tableCellClasses.root} &, table td &`]: {
    ...theme.components.MuiButton?.styleOverrides.outlined,
  },
}));

export const HButton = React.forwardRef((props, ref) => (
  <StyledButton
    ref={ref}
    {...props}
  />
));
