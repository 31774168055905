import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TableCell from '@mui/material/TableCell';
import { HButton } from 'BaseComponents';
import { styled } from '@mui/material/styles';


export const StyledPaper = styled(Paper)(({ theme }) => ({ padding: theme.spacing(5) }));

export const StyledGrid = styled(Grid)(({ theme }) => ({
  textAlign: 'right',
  marginTop: theme.spacing(2),
}));

export const StyledHButton = styled(HButton)({
  marginLeft: 10,
});

export const StyledTableCell = styled(TableCell, {
  shouldForwardProp: (props) => props !== 'currentAccount',
})(({ currentAccount }) => ({
  ...(currentAccount ? { fontWeight: 'bold' } : {}),
}));
