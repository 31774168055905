import React from 'react';
import Switch from 'components/Common/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { productStatusDisplayMap } from 'pages/InternalAdmin/constants';


const ProductAccessTable = ({
  accProducts = [], orgProducts = [], updateOrgProducts,
}) => {
  const onToggle = (product, isChecked) => {
    if (!isChecked) {
      updateOrgProducts(orgProducts.filter(i => i.ID !== product.ID));
    }
    else {
      updateOrgProducts([...orgProducts, product]);
    }
  };

  return (
    <TableContainer>
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell width='5%' />
            <TableCell align='left' width='20%'>Product</TableCell>
            <TableCell align='left' width='20%'>Description</TableCell>
            <TableCell align='left' width='20%'>Status</TableCell>
            <TableCell align='left' width='20%'>Product Group</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {accProducts.map(row => (
            <TableRow key={row.ID}>
              <TableCell>
                <Switch
                  checked={orgProducts.some(el => el.ID === row.ID)}
                  value={row.ID}
                  onChange={e => onToggle(row, e.target.checked)}
                />
              </TableCell>
              <TableCell>
                {row.displayName}
              </TableCell>
              <TableCell>
                {row.description}
              </TableCell>
              <TableCell>
                {productStatusDisplayMap[row.status]}
              </TableCell>
              <TableCell>
                {row.productGroup}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProductAccessTable;
