import { useMemo } from 'react';


export const useFiltersApply = (filters) => {
  const isFiltersApply = useMemo(() => {
    const isFilters = filters.filters.length !== 0;
    const isSearch = filters.search !== '';
    const isSortBy = Boolean(filters.sortBy.attribute);

    return isFilters || isSearch || isSortBy;
  }, [filters]);
  return isFiltersApply;
};
