import { activeOrgSelector } from 'redux/reducer/AuthenticationReducer';
import { axiosInstance } from 'utils/http';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { userDefinedFunctions } from 'utils/urlConstants';


export const useFetchOrgCredentials = (udfType) => {
  const dispatch = useDispatch();
  const { ID: orgId } = useSelector(activeOrgSelector);

  return useQuery(
    ['udfOrgCredentials', orgId, udfType],
    async () => {
      // fetch all udf supported credentials of the org
      const { data } = await axiosInstance.get(userDefinedFunctions(orgId)
        .listOrgCredentials(udfType));
      return data;
    },
    {
      enabled: !!udfType,
      retry: false,
      onError: (err) => {
        dispatch(showAlert({
          message: err.response?.data?.message ?? err.response?.statusText ?? '',
          type: 'error',
        }));
      },
    },
  );
};
