import { DATA_CREDENTIAL } from 'redux/actions/Types';


const initialState = {
  credentialSource: {
    name: '',
    provider: '',
    managedCredential: false,
    config: [],
  },
  credential: {
    name: '',
    credentialSource: { ID: '' },
    credentials: [],
  },
  orgCredentials: [],
  meta: {},
};

const dataCredentialReducer = (state = initialState, action) => {
  switch (action.type) {
    case DATA_CREDENTIAL.CREATE_DATA_CREDENTIAL_KEYS:
      return { ...state, meta: { showKeys: false } };
    case DATA_CREDENTIAL.GENERATE_DATA_CREDENTIAL_KEYS_SUCCESS:
      return {
        ...state,
        orgCredentials: action.payload,
        meta: { showKeys: true },
      };
    case DATA_CREDENTIAL.GENERATE_DATA_CREDENTIAL_KEYS_ERROR:
      return {
        ...state,
        meta: { message: action.payload, refresh: false, showKeys: false },
      };
    case DATA_CREDENTIAL.FETCH_ORG_DATA_CREDENTIAL_LIST:
      return { ...state, list: undefined, meta: { loading: true } };
    case DATA_CREDENTIAL.RECEIVE_ORG_DATA_CREDENTIAL_LIST_SUCCESS:
      return {
        ...state,
        list: action.payload,
        credential: initialState.credential,
        meta: initialState.meta,
      };
    case DATA_CREDENTIAL.RECEIVE_ORG_DATA_CREDENTIAL_LIST_ERROR:
      return {
        ...state,
        list: { error: action.payload },
        meta: { refresh: false },
      };
    case DATA_CREDENTIAL.RECEIVE_ORG_CREDENTIAL_SUCCESS:
      return {
        ...state,
        credential: action.payload,
        meta: initialState.meta,
      };
    case DATA_CREDENTIAL.RECEIVE_ORG_CREDENTIAL_ERROR:
      return { ...state, meta: { message: action.payload } };
    case DATA_CREDENTIAL.RECEIVE_CREDENTIAL_SOURCES_LIST_SUCCESS:
      return {
        ...state,
        credentialSources: action.payload,
        credentialSource: initialState.credentialSource,
        meta: initialState.meta,
      };
    case DATA_CREDENTIAL.RECEIVE_CREDENTIAL_SOURCES_LIST_ERROR:
      return {
        ...state,
        credentialSources: [],
        meta: { message: action.payload },
      };
    case DATA_CREDENTIAL.RECEIVE_CREDENTIAL_SOURCE_SUCCESS:
      return { ...state, credentialSource: action.payload, meta: { refresh: true } };
    case DATA_CREDENTIAL.RECEIVE_CREDENTIAL_SOURCE_ERROR:
      return {
        ...state,
        credentialSource: initialState.credentialSource,
        meta: { message: action.payload },
      };
    case DATA_CREDENTIAL.DELETE_CREDENTIAL_SOURCE_SUCCESS:
      return { ...state, meta: { refresh: true } };
    case DATA_CREDENTIAL.DELETE_CREDENTIAL_SOURCE_ERROR:
      return { ...state, meta: { message: action.payload } };
    case DATA_CREDENTIAL.SUBMIT_CREDENTIAL_SOURCE_SUCCESS:
      return { ...state, meta: { redirect: true } };
    case DATA_CREDENTIAL.SUBMIT_CREDENTIAL_SOURCE_ERROR:
      return { ...state, meta: { redirect: false } };
    case DATA_CREDENTIAL.SUBMIT_ORG_CREDENTIAL_SOURCE_SUCCESS:
      return { ...state, credential: action.payload, meta: { redirect: true, loading: false } };
    case DATA_CREDENTIAL.SUBMIT_ORG_CREDENTIAL_SOURCE_ERROR:
      return { ...state, meta: { redirect: false, loading: false } };
    case DATA_CREDENTIAL.DELETE_ORG_CREDENTIAL_SOURCE_SUCCESS:
      return { ...state, meta: { refresh: true } };
    case DATA_CREDENTIAL.DELETE_ORG_CREDENTIAL_SOURCE_ERROR:
      return { ...state, meta: { refresh: false } };
    default:
      return state;
  }
};

export default dataCredentialReducer;
