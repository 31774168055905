import React from 'react';
import { useSelector } from 'react-redux';


export const FlowSidePanelContext = React.createContext({});

const FlowSidePanelProvider = ({ children }) => {
  const drawerInfo = useSelector(state => state.flow.sidePanel);

  if (!drawerInfo || !drawerInfo.data) {
    return null;
  }

  return (
    <FlowSidePanelContext.Provider value={{ drawerInfo }}>
      {children}
    </FlowSidePanelContext.Provider>
  );
};

export default FlowSidePanelProvider;
