export const hasNodeListBeenModified = (initialNodeList, latestNodeList) => latestNodeList.filter(
  latestNode => {
    const currentNode = initialNodeList.find(node => node.ID === latestNode.id);

    if (!currentNode) {
      // Object is not present in the initialNodeList, so it was added
      return true;
    }

    const isPositionXModified = latestNode.position.x !== currentNode.positionX;
    const isPositionYModified = latestNode.position.y !== currentNode.positionY;
    const isReferenceIDModified = latestNode.data.referenceID !== currentNode.referenceID;
    const isTypeModified = latestNode.type !== currentNode.nodeType;
    const isLabelModified = latestNode.data.label !== currentNode.label;

    return isPositionXModified || isPositionYModified || isReferenceIDModified
    || isTypeModified || isLabelModified;
  },
);

export const getDeltaString = (param) => {
  const defaultValue = '1.4E-10';

  if (Number(param) === 0 || Number.isNaN(Number(param))) {
    return defaultValue;
  }

  const inputString = String(param);
  const pattern = /^(\d*\.?\d+)(?:[Ee]([-+])?(\d+))?$/;
  const match = inputString.match(pattern);

  if (match) {
    const baseNumber = match[1];
    return `${baseNumber}E-10`;
  }
  else {
    return defaultValue;
  }
};
