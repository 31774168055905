import AlertListTable from 'pages/InternalAdmin/organization/pages/Alerts/AlertsTable';
import ConfirmationDialog from 'components/Common/ConfirmationDialog';
import React, {
  createContext, useContext, useRef, useState,
} from 'react';
import ViewSQLModal from 'pages/InternalAdmin/organization/pages/Alerts/ViewSQLModal';
import { AlertsPageContext } from 'pages/InternalAdmin/organization/pages/Alerts/Alerts';
import { activeOrgSelector } from 'redux/reducer/AuthenticationReducer';
import { internalAdmin } from 'utils/spaUrls';
import { useDeleteAlert, useUpdateAlert, useUpdateAlertStatus } from 'hooks/alertAPI';
import { useFetchCleanRoomUsers } from 'hooks/cleanRoomAPIs';
import { useSelector } from 'react-redux';


export const AlertsContext = createContext({});

const AlertListLayout = () => {
  const { ID: orgId } = useSelector(activeOrgSelector);
  const { isCurrentCrExpired } = useContext(AlertsPageContext);

  const [selectedRow, setSelectedRow] = useState({});
  const [openView, setOpenView] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [editRow, setEditRow] = useState({});

  const dataContextRef = useRef(null);

  const { data: users } = useFetchCleanRoomUsers(orgId, editRow.cleanRoomID);
  const { mutate: updateAlertStatus } = useUpdateAlertStatus();
  const { mutate: updateAlert } = useUpdateAlert();
  const { mutate: deleteAlert } = useDeleteAlert();

  const handleViewSQL = (row) => {
    setSelectedRow(row);
    setOpenView(true);
  };

  const handleCloseViewSQL = () => {
    setOpenView(false);
  };

  const handleDelete = (row) => {
    setOpenDelete(true);
    setSelectedRow(row);
  };

  const handleClose = () => {
    setOpenDelete(false);
  };

  const handleEditRecipient = (row) => {
    setEditRow(row);
  };

  const updateStatus = (row, payload) => {
    updateAlertStatus({
      orgId,
      crId: row.cleanRoomID,
      alertID: row.alertID,
      payload,
    });
  };

  const updateRecipients = (row, payload) => {
    updateAlert({
      orgId,
      crId: row.cleanRoomID,
      alertID: row.alertID,
      payload,
    });

    dataContextRef.current.refreshListData();
    setEditRow({});
  };

  const deleteAlertOnConfirm = () => {
    deleteAlert({
      orgId,
      crId: selectedRow.cleanRoomID,
      alertID: selectedRow.alertID,
    });

    dataContextRef.current.refreshListData();
    handleClose();
  };
  const editTooltip = 'Clean Room Expired. Edit is not allowed.';
  const editRecipientsTooltip = 'Clean Room Expired. Edit Recipients is not allowed.';
  const deleteTooltip = 'Clean Room Expired. Delete is not allowed.';

  const actionMenu = (row) => [
    {
      label: 'Edit',
      link: true,
      to: internalAdmin.organization.alertBuilderEdit(row.cleanRoomID, row.alertID),
      disabled: isCurrentCrExpired,
      tooltipTitle: isCurrentCrExpired ? editTooltip : '',
    },
    {
      label: 'View SQL',
      link: false,
      action: () => handleViewSQL(row),
    },
    {
      label: 'Edit Recipients',
      link: false,
      action: () => handleEditRecipient(row),
      to: undefined,
      disabled: isCurrentCrExpired,
      tooltipTitle: isCurrentCrExpired ? editRecipientsTooltip : '',
    },
    {
      label: 'Delete',
      link: false,
      action: () => handleDelete(row),
      disabled: isCurrentCrExpired,
      tooltipTitle: isCurrentCrExpired ? deleteTooltip : '',
    },
  ];

  return (
    <AlertsContext.Provider value={{
      actionMenu,
      updateStatus,
      dataContextRef,
      editRow,
      users,
      updateRecipients,
    }}
    >
      <AlertListTable />
      <ViewSQLModal open={openView} onClose={handleCloseViewSQL} sql={selectedRow.alertPredicate} />
      <ConfirmationDialog
        open={openDelete}
        title='Delete?'
        message={`Are you sure you want to delete "${selectedRow.alertName}" alert?`}
        confirmActionLabel='Delete'
        onConfirm={deleteAlertOnConfirm}
        onCancel={handleClose}
      />
    </AlertsContext.Provider>
  );
};


export default AlertListLayout;
