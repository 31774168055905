import React from 'react';
import Switch from 'components/Common/Switch';
import {
  Table, TableBody, TableCell, TableHead, TableRow,
} from '@mui/material';


const DataTypeAccessTable = ({ list, dataTypes, updateDataTypes }) => {
  const isAllSelected = dataTypes.length === list.length;

  const handleToggle = (dataType, isChecked) => {
    if (!isChecked) {
      updateDataTypes(dataTypes.filter(id => id !== dataType.id));
    }
    else {
      updateDataTypes([...dataTypes, dataType.id]);
    }
  };

  const handleSelectAll = (isChecked) => {
    if (isChecked) {
      updateDataTypes(list.map(row => row.id));
    }
    else {
      updateDataTypes([]);
    }
  };

  return (
    <Table size='small'>
      <TableHead>
        <TableRow>
          <TableCell width='5%' sx={{ whiteSpace: 'nowrap' }}>
            <Switch
              checked={isAllSelected}
              label='Select All'
              onChange={e => handleSelectAll(e.target.checked)}
            />
          </TableCell>
          <TableCell align='left' width='95%'>Import Data Type</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {list.map(row => (
          <TableRow key={row.id}>
            <TableCell>
              <Switch
                checked={dataTypes.includes(row.id)}
                onChange={e => handleToggle(row, e.target.checked)}
              />
            </TableCell>
            <TableCell>
              {row.displayName}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default DataTypeAccessTable;
