import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import React from 'react';
import { Card, CardContent } from '@mui/material';
import { HButton } from 'BaseComponents';
import { Link, useHistory } from 'react-router-dom';
import { StyledH2 } from 'pages/ErrorPages/StyledComponents';
import { habuSupport } from 'utils/appConstants';
import { homepage, redirect } from 'utils/spaUrls';


const GoBackToPage = () => {
  const history = useHistory();

  // history.goBack() function does not let user go to the previous page on the first click,
  // user needs to click the button 2 times in order to be redirected to the previous page
  // so added the history.goBack() twice
  const goToPreviousPage = () => {
    history.goBack();
    history.goBack();
  };

  return (
    <StyledH2>
      Go Back To Previous Page
      {' '}
      <IconButton onClick={goToPreviousPage}>
        <ArrowForwardIcon fontSize='small' />
      </IconButton>
    </StyledH2>
  );
};

const PageNotFound = () => (
  <Grid container justifyContent='center' spacing={1}>
    <Grid item xs={6}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Card>
            <Grid item xs={12}>
              <h2 style={{ fontSize: '1.5em', marginLeft: 15 }}>Page Not Found</h2>
              <Divider />
            </Grid>
            <CardContent style={{ minHeight: 'auto' }}>
              <h2 style={{ fontSize: '1em', lineHeight: 'normal' }}>
                We&apos;re sorry, but this page does not exist. This could be due to the URL or
                the page may have been moved.
              </h2>
              <GoBackToPage />
              <StyledH2>
                Go Back To Home Page
                {' '}
                <IconButton component={Link} to={homepage}>
                  <ArrowForwardIcon fontSize='small' />
                </IconButton>
              </StyledH2>
              <StyledH2>
                Learn More in Our Help Center
                {' '}
                <IconButton component={Link} to={redirect.habuHelp}>
                  <ArrowForwardIcon fontSize='small' />
                </IconButton>
              </StyledH2>
              <HButton
                color='secondary'
                href={`mailto:${habuSupport.email}`}
                variant='contained'
              >
                Contact Us
              </HButton>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

export { PageNotFound, GoBackToPage };
