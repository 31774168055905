import React from 'react';
import { axiosInstance } from 'utils/http';
import { homepage } from 'utils/spaUrls';
import { showAlert } from 'redux/actions/AlertActions';
import { supportMode } from 'utils/urlConstants';
import { useDispatch } from 'react-redux';
import { useMutation } from 'react-query';


export const useUpgradeUserType = () => {
  const dispatch = useDispatch();
  const [data, setData] = React.useState([]);
  const {
    isLoading, isError, isSuccess, mutate,
  } = useMutation(
    async (payload) => axiosInstance.post(supportMode.upgrade, payload),
    {
      onSuccess: (res) => {
        setData(res.data);
        dispatch(showAlert({ message: 'Support Mode Enabled Successfully.', type: 'success' }));
        window.location.replace(homepage);
      },
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    data,
    mutate,
  };
};
