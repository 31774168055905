import Logger from 'components/Common/Logger';


let timeout = 300;

const componentLoader = (lazyComponent, attemptsLeft = 3) => new Promise((resolve, reject) => {
  lazyComponent()
    .then(resolve)
    .catch((error) => {
      setTimeout(() => {
        timeout *= 2;
        if (attemptsLeft === 1) {
          Logger.error('Lazy component loading failed', error);
          reject(error);
          return;
        }
        componentLoader(lazyComponent, attemptsLeft - 1).then(resolve, reject);
      }, timeout);
    });
});

export default componentLoader;
