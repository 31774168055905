import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import React from 'react';
import Typography from '@mui/material/Typography';
import { NODES_TYPE } from 'pages/Flow/contacts';
import { flowPalette } from './contacts';
import { styled } from '@mui/material/styles';


const StyledIconText = styled('div', {
  shouldForwardProp: (prop) => prop !== 'type',
})(({ theme, type }) => ({
  height: 32,
  minWidth: 32,
  padding: '0 6px 0 0',
  color: NODES_TYPE.disable !== type ? 'inherit' : theme.palette.text.disabled,
  border: `1px solid ${flowPalette(theme, type).borderColor}`,
  borderRadius: 2,
  marginBottom: 10,
  display: 'flex',
  fontWeight: 900,
  justifyContent: 'space-between',
  lineHeight: '30px',
}));

const StyledIcon = styled('div', {
  shouldForwardProp: (prop) => prop !== 'type',
})(({ theme, type }) => ({
  height: 30,
  minWidth: 30,
  padding: 4,
  marginRight: 4,
  backgroundColor: `${flowPalette(theme, type).backgroundColor}`,
  borderRight: `1px solid ${flowPalette(theme, type).borderColor}`,
}));

export const HelpItem = ({
  Icon, title, active, description, type,
}) => {
  const isDisable = !active;
  const styledType = active ? type : NODES_TYPE.disable;

  return (
    <ListItem divider>
      <Grid container>
        <Grid item container justifyContent='space-between'>
          <Grid>
            <StyledIconText type={styledType}>
              <StyledIcon type={styledType}>
                {Icon}
              </StyledIcon>
              <div>
                {title}
              </div>
            </StyledIconText>
          </Grid>
          <Grid flexGrow>
            <Typography variant='subtitle3' color='GrayText'>
              {isDisable && '(Coming Soon...)'}
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Typography variant='body2'>
            {description}
          </Typography>
        </Grid>
      </Grid>
    </ListItem>
  );
};
