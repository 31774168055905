import OrganizationParamentersReducer from '../organization/pages/OrganizationParameters/redux/reducer';
import cleanRoomAppAdminReducer from 'pages/InternalAdmin/application/CleanRoom/redux/reducer';
import cleanRoomOrgAdminReducer from 'pages/InternalAdmin/organization/CleanRoom/redux/reducer';
import dataInOrgAdminReducer from 'pages/InternalAdmin/organization/DataIn/redux/reducer';
import { combineReducers } from 'redux';


const internalAdminReducer = combineReducers({
  application: combineReducers({
    cleanRoomAdmin: cleanRoomAppAdminReducer,
  }),
  organization: combineReducers({
    cleanRoomAdmin: cleanRoomOrgAdminReducer,
    dataInAdmin: dataInOrgAdminReducer,
    orgParameters: OrganizationParamentersReducer,
  }),
});

export default internalAdminReducer;
