import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import TextField from '@mui/material/TextField';
import { Switch } from '@mui/material';
import { snakeToDisplayString } from 'utils/jsUtils';
import { styled } from '@mui/material/styles';


const StyledBox = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
  paddingTop: '10px',
});

const ALL_FILTER = '-';
const SORT_BY = 'Sort By';
const ASCENDING = 'ASCENDING';
const DESCENDING = 'DESCENDING';
const NONE_SORT = 'None';
const DEFAULT_FILTER = {
  filters: [],
  sortBy: {},
};

const FilterSkeleton = () => (
  <Box display='flex'>
    <Skeleton height={40} width={100} />
    <Box width={20} />
    <Skeleton height={40} width={100} />
    <Box width={20} />
    <Skeleton height={40} width={100} />
    <Box width={20} />
    <Skeleton height={40} width={100} />
    <Box width={20} />
    <Skeleton height={40} width={100} />
  </Box>
);

const ListFilters = ({
  allFilters = {}, appliedFilters = DEFAULT_FILTER, searchAttribute, onSearch, searchText, onChange,
}) => {
  if (!allFilters) return <FilterSkeleton />;
  const { filters, sorts } = allFilters;

  const updateFilter = (attribute, value) => {
    onChange({
      ...appliedFilters,
      filters: [
        ...appliedFilters.filters.filter(af => af.attribute !== attribute),
        value !== ALL_FILTER ? {
          attribute,
          values: [value],
        } : null,
      ].filter(f => f !== null),
    });
  };

  const handleSortChange = (e) => {
    const { value } = e.target;
    const sortBy = value === NONE_SORT ? {} : {
      attribute: value,
      direction: appliedFilters.sortBy.direction || DESCENDING,
    };
    onChange({
      ...appliedFilters,
      sortBy,
    });
  };

  const getOptionName = (option) => {
    if (option.count) {
      return `${snakeToDisplayString(option.displayName)} (${option.count})`;
    }
    return snakeToDisplayString(option.displayName);
  };

  return (
    <StyledBox>
      <Divider />
      <Box display='flex' width='100%'>
        {(searchAttribute && onSearch) && (
        <Box mr={1} width='100%' maxWidth='300px'>
          <TextField
            fullWidth
            label='Search'
            variant='outlined'
            value={searchText || ''}
            onChange={e => onSearch(searchAttribute, e.target.value)}
          />
        </Box>
        )}
        {sorts && (
          <Box width='100%' maxWidth='300px'>
            <TextField
              fullWidth
              label={SORT_BY}
              onChange={handleSortChange}
              select
              value={appliedFilters.sortBy?.attribute || ''}
              variant='outlined'
            >
              <MenuItem key={NONE_SORT} value={NONE_SORT}>
                {NONE_SORT}
              </MenuItem>
              {sorts.map(s => (
                <MenuItem key={s.attribute} value={s.attribute}>
                  {s.attribute}
                </MenuItem>
              ))}
            </TextField>
            <Box>
              Desc
              <Switch
                checked={appliedFilters.sortBy?.direction === ASCENDING}
                onChange={e => onChange({
                  ...appliedFilters,
                  sortBy: {
                    ...appliedFilters.sortBy,
                    direction: e.target.checked ? ASCENDING : DESCENDING,
                  },
                })}
                disabled={!appliedFilters.sortBy?.attribute}
              />
              Asc
            </Box>
          </Box>
        )}
        {filters.map(i => (
          <Box width='100%' ml={1} maxWidth='300px' key={`${i.attribute}`}>
            <TextField
              fullWidth
              label={`Filter by ${snakeToDisplayString(i.displayName || i.attribute)}`}
              onChange={e => updateFilter(i.attribute, e.target.value)}
              select
              value={appliedFilters
                .filters.find(af => af.attribute === i.attribute)?.values[0] || ALL_FILTER}
              variant='outlined'
            >
              <MenuItem value={ALL_FILTER}>All</MenuItem>
              {i.options.map(f => (
                <MenuItem key={f.name} value={f.name}>
                  {getOptionName(f)}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        ))}
      </Box>
    </StyledBox>
  );
};

export default ListFilters;
