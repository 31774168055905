import { useEffect, useState } from 'react';


const checkIfAllExpanded = (expRows) => Object.values(expRows).every((v) => v === true);
const checkIfAllCollapsed = (expRows) => Object.values(expRows).every((v) => v === false);
function setBulkExpState(expRows, value) {
  return Object.keys(expRows).reduce((state, ID) => ({ ...state, [ID]: value }), {});
}

const setHookState = ({ setState, rowID, value }) => {
  setState((prevState) => {
    const newExpRows = rowID
      ? { ...prevState.expandedRows, [rowID]: !prevState.expandedRows[rowID] }
      : setBulkExpState(prevState.expandedRows, value);

    return {
      allExpanded: checkIfAllExpanded(newExpRows),
      allCollapsed: checkIfAllCollapsed(newExpRows),
      expandedRows: newExpRows,
    };
  });
};

export const useExpandCollapse = (rows, subRowsProp) => {
  const [state, setState] = useState({
    allExpanded: false,
    allCollapsed: true,
    expandedRows: {},
  });

  // NOTE currently supporting only first level of nested rows
  useEffect(() => {
    if (subRowsProp && rows?.length) {
      const newExpandedRows = rows.reduce((newState, row) => {
        const rowID = row.ID || row.IDs?.[0];

        if (row[subRowsProp]?.length) {
          return { ...newState, [rowID]: false };
        }
        return newState;
      }, {});

      setState((prevState) => ({ ...prevState, expandedRows: newExpandedRows }));
    }
  }, [rows, rows?.length, subRowsProp]);

  const expandAll = () => setHookState({ setState, value: true });
  const collapseAll = () => setHookState({ setState, value: false });
  const toggleRow = (rowID) => setHookState({ setState, rowID });

  return {
    ...state,
    expandAll,
    collapseAll,
    toggleRow,
  };
};
