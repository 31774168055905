import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import Avatar from '@mui/material/Avatar';
import Bouncer from 'components/Common/Bouncer';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import ListIcon from '@mui/icons-material/List';
import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { FLOW_RUN_STATUS } from 'utils/ignoramusEnums';
import { LinkComponent } from 'BaseComponents';
import { SettingIconButton } from 'components/CleanRooms/Datasets/AnalysisRule/style';
import { habuColors } from 'Theme';
import { styled } from '@mui/material/styles';
import { tooltipClasses } from '@mui/material';


const StyledCardActions = styled(CardActions)({ justifyContent: 'flex-end' });

const StyledNameSection = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background[theme.palette.mode],
  borderBottom: `1px solid ${theme.palette.background.tabsBackground}`,
  borderTop: '1px solid #cfd8dc',
  padding: '15px 5px',
  overflowWrap: 'break-word',
}));

const StyledWelcomeNameSection = styled(Box)({
  padding: '15px 15px',
  overflowWrap: 'break-word',
});

const StyledAvatar = styled(Avatar)(({
  color: habuColors.neutral['90'],
  border: `2px solid ${habuColors.neutral['20']}`,
  backgroundColor: habuColors.neutral.white,
  width: 45,
  height: 45,
}));

const StyledListIcon = styled(ListIcon)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.link.inactive,
  marginLeft: theme.spacing(0.25),
}));

const StyledAutoGraphIcon = styled(AutoGraphIcon)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.link.inactive,
  marginLeft: theme.spacing(0.25),
}));

export const StyledRows = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(1),
}));

const RowName = styled(Typography)`
  font-size: 10px;
  font-weight: 600;
`;

const StyledLink = styled(LinkComponent)(({ theme, fontSize }) => ({
  color: theme.palette.link.main,
  fontSize: fontSize || '14px',
}));

const StyledGrid = styled(Grid, {
  shouldForwardProp: (props) => props !== 'titleAlignment' && props !== 'titlePadding',
})(({ theme, titleAlignment, titlePadding }) => ({
  marginTop: theme.spacing(1.9),
  ...(titleAlignment ? { marginLeft: theme.spacing(1.25) } : {}),
  ...(titlePadding ? { paddingLeft: theme.spacing(1.9) } : {}),
}));

const StyledHeader = styled(Grid)({ textTransform: 'uppercase' });

export const StyledEyebrow = styled(Grid)(({ theme }) => ({
  color: theme.palette.text.primary,
  opacity: 0.8,
  textAlign: 'end',
}));

export const StyledQuestionTitleRowDescription = styled(Typography)(({ theme }) => ({
  ...theme.typography.caption,
  color: theme.palette.link.inactive,
  marginLeft: theme.spacing(0.25),
}));

const getTypographyColor = (theme, color) => {
  const colors = {
    defaultColor: theme.palette.text?.primary,
    gray: theme.palette.secondary?.main,
    green: theme.palette.success?.main,
    orange: theme.palette.pending?.main,
    red: theme.palette.error?.main,
    disabledColor: habuColors.neutral[60],
  };

  return colors[color] || color;
};


const StyledTypography = styled(Typography, {
  shouldForwardProp: (props) => props !== 'tag'
    && props !== 'textOverflow'
    && props !== 'cardTitle'
    && props !== 'bold'
    && props !== 'color',
})(({
  theme, bold, tag, textOverflow, cardTitle, color,
}) => ({
  ...(tag ? { marginRight: '5px', verticalAlign: 'super' } : {}),
  ...(textOverflow ? { overflow: 'hidden' } : {}),
  ...(cardTitle ? { height: '2rem' } : {}),
  ...(bold ? { fontWeight: 600 } : {}),
  ...(color ? { color: getTypographyColor(theme, color) } : {}),
}));

export const BlankCard = ({
  children, header, headerColor, actionButtons, sxProps,
}) => (
  <Card data-testid='card' sx={{ ...(sxProps?.sx ? sxProps.sx : {}) }}>
    { header ? (
      <>
        <Box sx={{ backgroundColor: headerColor || '' }} p={1}>{header}</Box>
        <Divider />
      </>
    ) : null}
    <Box>{children}</Box>
    {actionButtons ? (
      <>
        <Divider />
        <StyledCardActions>
          {actionButtons}
        </StyledCardActions>
      </>
    ) : null}
  </Card>
);

export const WelcomeBlankCard = ({ header, children, actionButtons }) => (
  <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
    { header ? (
      <>
        <Box p={1}>{header}</Box>
      </>
    ) : null}
    <Box sx={{ flexGrow: 1 }}>{children}</Box>
    {actionButtons ? (
      <CardActions>
        <Divider />
        {actionButtons}
      </CardActions>
    ) : null}
  </Card>
);

export const HeaderRow = ({ tagTitle = '', Icon, children }) => (
  <StyledHeader direction='row' justifyContent='space-between' container>
    {children ? (
      <Grid item xs={6}>
        {children}
      </Grid>
    ) : null}
    <StyledEyebrow item xs={children ? 6 : 12}>
      <StyledTypography
        component='span'
        variant='caption'
        tag
      >
        {tagTitle}
      </StyledTypography>
      {(Icon) ? <Icon fontSize='small' title={tagTitle} /> : null}
    </StyledEyebrow>
  </StyledHeader>
);


export const WelcomeHeaderRow = ({
  Icon, IconRight, tooltipText, createURL, createPermissions,
}) => (
  <Grid container spacing={1} direction='row' justifyContent='space-between'>
    {Icon ? (
      <Grid item xs={6}>
        <Icon fontSize='large' />
      </Grid>
    ) : null}
    {IconRight ? (
      <Bouncer allowList={createPermissions}>
        <Grid item xs={6} sx={{ textAlign: 'end' }}>
          <Tooltip placement='top' title={tooltipText} arrow>
            <IconButton
              component={LinkComponent}
              to={createURL}
              sx={{ margin: '0', padding: '0', color: '#212022' }}
              size='medium'
            >
              <IconRight fontSize='large' sx={{ '&:hover': { color: (theme) => theme.palette.text.hoverLink } }} />
            </IconButton>
          </Tooltip>
        </Grid>
      </Bouncer>
    ) : null}
  </Grid>
);

export const CardHeaderStatus = ({
  textColor,
  value = '',
  onClick,
  stageDetails = null,
  additionalDetail = null,
}) => {
  const valueString = value || '-';
  return (
    <Grid container spacing={1} direction='row' justifyContent='space-between'>
      <Grid item xs={6}>
        {onClick ? (
          <StyledLink to='#' onClick={onClick}>{valueString}</StyledLink>
        ) : (
          <StyledTypography
            sx={{ color: textColor || '' }}
            noWrap
            variant='caption'
            data-testid='card-header-status'
            bold
          >
            {stageDetails}
            {valueString}
            {additionalDetail}
          </StyledTypography>
        )}
      </Grid>
    </Grid>
  );
};

export const CardKeyValueRow = (
  {
    color = 'defaultColor',
    title,
    value = '',
    onClick,
    stageFailureDetails = null,
    disabled = false,
    showTooltip = false,
    icon = null,
  },
) => {
  const valueStr = value === '' ? '-' : value;
  const renderValue = () => {
    if (stageFailureDetails) {
      return (
        <>
          {valueStr}
          {stageFailureDetails}
        </>
      );
    }
    return (
      <>
        {valueStr}
        {icon}
      </>
    );
  };

  if (!title || title === '') {
    return null;
  }

  const content = (
    <>
      {onClick && !disabled ? (
        <StyledLink to='#' fontSize='12px' onClick={onClick}>{valueStr}</StyledLink>
      ) : (
        <StyledTypography
          color={disabled ? 'disabledColor' : color}
          variant='caption'
        >
          {renderValue()}
        </StyledTypography>
      )}
    </>
  );

  return (
    <StyledRows>
      <Box
        alignSelf='center'
        textAlign='left'
        ml={1}
        minWidth='30%'
        sx={{
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
      >
        <StyledTypography color={color} variant='caption' bold>
          {title}
        </StyledTypography>
      </Box>
      <Box
        mr={1}
        justifyContent='flex-end'
        sx={{
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
      >
        {showTooltip ? (
          <Tooltip title={valueStr} placement='bottom'>
            <span>{content}</span>
          </Tooltip>
        ) : <span>{content}</span>}
      </Box>
    </StyledRows>
  );
};

export const AnalysisRuleKeyValueRow = ({
  title,
  analyticalCount,
  listCount,
  datasetID,
  configurationStatus,
  onAnalysisRuleOpen,
  tooltipTitle = 'View & Access Analysis Rules',
  color = 'defaultColor',
}) => {
  const valueStr = `${analyticalCount} Analytical, ${listCount} List`;
  const disabled = !datasetID && configurationStatus === 'CONFIGURATION_REQUIRED';
  const actualTooltipTitle = disabled ? 'Dataset not configured' : tooltipTitle;

  if (!title) return null;

  return (
    <>
      <Divider variant='middle' />
      <StyledRows>
        <Box
          alignSelf='center'
          textAlign='left'
          ml={1}
          minWidth='10%'
        >
          <StyledTypography color={color} variant='caption' bold>
            {title}
          </StyledTypography>
        </Box>
        <Box mr={1}>
          <Typography
            color={disabled ? 'disabledColor' : color}
            variant='caption'
          >
            {valueStr}
            <Tooltip title={actualTooltipTitle} placement='top' arrow>
              <span>
                <SettingIconButton
                  size='small'
                  disabled={disabled}
                  sx={{ ml: 1 }}
                  onClick={() => onAnalysisRuleOpen(datasetID)}
                />
              </span>
            </Tooltip>
          </Typography>
        </Box>
      </StyledRows>
    </>
  );
};

export const KeyValueRow = (
  {
    color = 'defaultColor',
    title,
    value = '',
    valueTooltip,
    divider = true,
    onClick,
    stageFailureDetails = null,
    testID,
    disableTooltip = false,
    noWrap = true,
  },
) => {
  const valueStr = (value === '') ? '-' : value;
  if (!title || title === '') { return null; }
  return (
    <>
      <StyledRows justifyContent='initial'>
        <Box
          mr={1}
          alignSelf='center'
          textAlign='left'
          minWidth='40%'
          maxWidth='40%'
        >
          <Tooltip placement='top-start' title={title} disableHoverListener={disableTooltip}>
            <RowName color='text'>
              {title}
            </RowName>
          </Tooltip>
        </Box>
        <Box width='60%'>
          <Tooltip placement='top-start' title={valueTooltip || valueStr} disableHoverListener={disableTooltip}>
            {onClick ? (
              <StyledLink to='#' onClick={onClick}>{valueStr}</StyledLink>
            ) : (
              <StyledTypography
                color={color}
                noWrap={noWrap}
                variant='caption'
                data-testid={testID}
              >
                {
                  stageFailureDetails ? (
                    <>
                      {valueStr}
                      {stageFailureDetails}
                    </>
                  ) : valueStr
                }
              </StyledTypography>
            )}
          </Tooltip>
        </Box>
      </StyledRows>
      {divider ? <Divider /> : null}
    </>
  );
};

export const WelcomeKeyValueRow = (
  {
    color = habuColors.neutral['90'],
    title,
    value = '',
    onClick,
    stageFailureDetails = null,
  },
) => {
  const valueStr = (value === '') ? '-' : value;

  if (!title || title === '') { return null; }
  return (
    <StyledRows>
      <Box
        alignSelf='center'
        textAlign='left'
        ml={1}
        minWidth='10%'
      >
        <Typography variant='subtitle2' sx={{ color: (theme) => theme.palette.text.primary }}>
          {title}
        </Typography>
      </Box>
      <Box mr={1}>
        {onClick ? (
          <StyledLink to='#' onClick={onClick}>{valueStr}</StyledLink>
        ) : (
          <StyledTypography
            color={color}
            bold
            noWrap
            variant='subtitle2'
          >
            {
              stageFailureDetails ? (
                <>
                  {valueStr}
                  {stageFailureDetails}
                </>
              ) : valueStr
            }
          </StyledTypography>
        )}
      </Box>
    </StyledRows>
  );
};

export const DatasetCardTitleRow = (
  {
    title = '',
    Icon,
  },
) => (
  <Grid container direction='row'>
    {Icon ? (
      <StyledGrid item xs={2} titleAlignment>
        <StyledAvatar>
          {Icon}
        </StyledAvatar>
      </StyledGrid>
    ) : null}
    <StyledGrid item xs={9} titlePadding>
      <CustomTooltip title={title}>
        <StyledTypography
          variant='subtitle1'
          noWrap
          bold
        >
          {title}
        </StyledTypography>
      </CustomTooltip>
    </StyledGrid>
  </Grid>
);

export const CardTitleRow = (
  {
    title = '',
    subtitle = '',
    Icon,
  },
) => {
  if (subtitle === '') { return null; }
  return (
    <Grid container direction='row'>
      {Icon ? (
        <Grid item xs={2} sx={{ marginLeft: '10px', marginTop: '15px' }}>
          <StyledAvatar>
            {Icon}
          </StyledAvatar>
        </Grid>
      ) : null}
      <Grid item xs={9} sx={{ paddingLeft: '15px', marginTop: '15px' }}>
        <Tooltip title={title} placement='top'>
          <StyledTypography
            color='text'
            variant='caption'
            bold
          >
            {title}
          </StyledTypography>
        </Tooltip>
        <Tooltip title={subtitle} placement='bottom-start'>
          <StyledTypography
            color='text'
            variant='subtitle1'
            noWrap
            bold
          >
            {subtitle}
          </StyledTypography>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export const TitleRow = ({ str = '' }) => {
  if (str === '') { return null; }
  return str === '' ? null : (
    <StyledNameSection>
      <Tooltip title={str}>
        <StyledTypography
          color='text'
          variant='h5'
          cardTitle
          bold
          textOverflow
        >
          {str}
        </StyledTypography>
      </Tooltip>
    </StyledNameSection>
  );
};

export const WelcomeTitleRow = ({
  str = '',
  testID,
}) => {
  if (str === '') { return null; }
  return (
    <StyledWelcomeNameSection>
      <StyledTypography
        data-testid={testID}
        variant='h5'
        color='text'
        cardTitle
        textOverflow
      >
        {str}
      </StyledTypography>
    </StyledWelcomeNameSection>
  );
};

export const QuestionTitleRowDescription = ({
  isListQuestion,
  displayID,
}) => {
  const icon = isListQuestion ? <StyledListIcon /> : <StyledAutoGraphIcon />;
  const iconText = isListQuestion ? 'List Question -' : 'Analytical Question -';
  return (
    <Box display='flex' alignItems='center' flexDirection='left' marginBottom='5px'>
      {icon}
      <StyledQuestionTitleRowDescription variant='caption'>
        {iconText}
      </StyledQuestionTitleRowDescription>
      <StyledQuestionTitleRowDescription variant='caption'>
        {displayID}
      </StyledQuestionTitleRowDescription>
    </Box>
  );
};

export const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 280,
    fontSize: '15px',
  },
});

export const FlowCustomTooltip = styled(({ className, status, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ status }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    border: status === FLOW_RUN_STATUS.FAILED.key ? '1px solid #e57373' : 'none',
    borderRadius: '5px',
    padding: '10px',
    marginBottom: '20px',
    margin: 'auto',
  },
}));

export const OverflowTooltip = ({
  text,
  placement,
  lines,
  showAllLines,
  typographyProps = {},
  regularHeight = '4.55rem',
}) => {
  const ref = React.useRef();
  const [isOverflow, setIsOverflow] = React.useState(undefined);
  const multiline = (typeof lines !== 'undefined' && lines !== 1);

  React.useLayoutEffect(() => {
    const { current } = ref;
    const trigger = () => {
      const hasOverflow = multiline
        ? (current.scrollHeight > current.clientHeight)
        : (current.scrollWidth > current.clientWidth);
      setIsOverflow(hasOverflow);
    };

    if (current) {
      trigger();
    }
  }, [multiline, ref]);

  return (
    <CustomTooltip
      title={text}
      placement={placement}
      disableHoverListener={!isOverflow}
    >
      <Typography
        sx={{
          ...(typographyProps?.sx ? typographyProps.sx : {}),
          ...(multiline && {
            display: '-webkit-box',
            overflow: 'hidden',
            WebkitLineClamp: showAllLines ? '3' : lines,
            WebkitBoxOrient: 'vertical',
          }),
          height: regularHeight,
        }}
        noWrap={!multiline}
        ref={ref}
      >
        {text}
      </Typography>
    </CustomTooltip>
  );
};
