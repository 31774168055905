import PageHeader from 'components/Common/PageHeader';
import PropertiesConfigurationList
  from 'components/InternalAdmin/PropertyConfiguration/PropertiesConfigurationList';
import React from 'react';
import { crumbs } from 'components/Common/BreadcrumbsNavigation';
import { fetchProperties, fetchPropertyConfigurations } from 'redux/actions/PropertiesActions';
import { internalAdmin } from 'utils/spaUrls';
import { mapPropertiesToConfigurations } from 'components/InternalAdmin/PropertyConfiguration/service';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';


const actions = history => ({ ID, configuration }) => {
  if (configuration) {
    return [
      { name: 'Edit Property', action: () => history.push(internalAdmin.organization.organizationPropertyDetails(ID)) },
      {
        name: 'Edit Configuration',
        action: () => history.push(
          internalAdmin.organization
            .organizationPropertyConfigurationDetails(ID, configuration.ID),
        ),
      },
    ];
  }
  return [
    {
      name: 'Edit Property', action: () => history.push(internalAdmin.organization.organizationPropertyDetails(ID)),
    },
    {
      name: 'Configure',
      action: () => history.push(internalAdmin.organization
        .organizationPropertyConfigurationDetails(ID, '')),
    },
  ];
};

const OrganizationProperties = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const properties = useSelector(state => state.properties.list);
  const configurations = useSelector(state => state.properties.configurations);
  const orgId = useSelector(state => state.session.user.primaryOrganization.organization.ID);
  const account = useSelector(state => state.session.user.primaryAccount);
  const mappedProperties = mapPropertiesToConfigurations(properties, configurations);

  React.useEffect(() => {
    dispatch(fetchProperties(orgId));
    dispatch(fetchPropertyConfigurations(orgId));
  }, [account.ID, dispatch, orgId]);

  return (
    <>
      <PageHeader title='Organization Properties' breadcrumbs={[crumbs.helium, crumbs.internalAdmin]} />
      <PropertiesConfigurationList list={mappedProperties} actions={actions(history)} />
    </>
  );
};

export default OrganizationProperties;
