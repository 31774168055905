import * as monaco from 'monaco-editor';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import Editor, { loader } from '@monaco-editor/react';
import LightModeIcon from '@mui/icons-material/LightMode';
import PageHeader from 'components/Common/PageHeader';
import React, { useCallback, useRef } from 'react';
import SectionHeader from 'components/IAM/Admin/SectionHeader';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import {
  Alert, Box, Card, CardActions, Checkbox, Divider, FormControl, Grid, InputLabel, ListItemText,
  MenuItem, OutlinedInput, Select, Skeleton, Stack, TextField, Typography, useTheme,
} from '@mui/material';
import { EDITOR_KEYWORDS } from 'utils/constants/question.constant';
import { HButton, HIconButton, HSpinner } from 'BaseComponents';
import { copyToClipboard } from 'utils/jsUtils';
import { crumbs } from 'components/Common/BreadcrumbsNavigation';
import { habuColors } from 'Theme';
import { internalAdmin } from 'utils/spaUrls';
import { showAlert } from 'redux/actions/AlertActions';
import {
  useCreateAlert, useFetchAlert, useUpdateAlert,
} from 'hooks/alertAPI';
import { useDispatch, useSelector } from 'react-redux';
import { useFetchCleanRoom, useFetchCleanRoomUsers } from 'hooks/cleanRoomAPIs';
import { useFetchCrCurrentQuestions, useFetchHISchemaInfoList } from 'hooks/questionAPIs';
import { useHistory, useLocation, useParams } from 'react-router-dom';


export default function AlertBuilder() {
  const { crId, alertId } = useParams();
  const location = useLocation();
  const chatID = location.state?.chatID;
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const orgId = useSelector(state => state.session.user.primaryOrganization.organization.ID);
  const [name, setName] = React.useState('');
  const [emailList, setEmailList] = React.useState([]);
  const [crqList, setCrqList] = React.useState([]);
  const [isDarkMode, setIsDarkMode] = React.useState(true);
  const [query, setQuery] = React.useState(null);

  const {
    data: cleanRoomDetails,
    isFetching: isFetchingCleanRoomDetails,
  } = useFetchCleanRoom(orgId, crId);
  const {
    data: questions,
    isFetching: isFetchingQuestions,
    isSuccess: isFetchQuestionsSuccess,
  } = useFetchCrCurrentQuestions(orgId, crId);
  const {
    data: users,
  } = useFetchCleanRoomUsers(orgId, crId);
  const {
    data: alertDetails,
    isFetching: isFetchingAlertDetails,
    isSuccess: isFetchAlertSuccess,
  } = useFetchAlert(orgId, crId, alertId);
  const {
    data: hiSchemaInfoList,
    refetch: refetchSchemaList,
    isFetching: isFetchingSchemaList,
  } = useFetchHISchemaInfoList(orgId, crId, { cleanRoomQuestionIDs: crqList?.map(crq => crq?.ID) });
  const createAlert = useCreateAlert();
  const updateAlert = useUpdateAlert();
  const runOnce = useRef(false);

  const handleFetchSchemaInfo = useCallback(() => {
    refetchSchemaList({ cleanRoomQuestionIDs: crqList?.map(crq => crq?.ID) });
  }, [refetchSchemaList, crqList]);

  React.useEffect(() => {
    if (crqList && crqList[0] && !runOnce.current && alertId) {
      runOnce.current = true;
      handleFetchSchemaInfo();
    }
  }, [alertId, crqList, handleFetchSchemaInfo, refetchSchemaList]);

  React.useEffect(() => {
    if (isFetchAlertSuccess && isFetchQuestionsSuccess) {
      setCrqList(
        alertDetails.cleanRoomQuestionIDs?.map(
          crqId => questions?.find(q => q?.ID === crqId),
        ),
      );
      setName(alertDetails.alertName);
      setQuery(alertDetails.alertPredicate);
      if (alertDetails.emailID) {
        setEmailList(alertDetails.emailID.split(','));
      }
      else {
        setEmailList([]);
      }
    }
  }, [alertDetails, crId, questions, isFetchAlertSuccess, isFetchQuestionsSuccess]);

  const onSubmit = () => {
    if (alertId) {
      const payload = {
        alertDetails: {
          ...alertDetails,
          alertName: name,
          emailID: emailList.join(','),
          alertPredicate: query,
          cleanRoomQuestionIDs: crqList.map(c => c?.ID),
          ...(chatID && { chatID }),
        },
      };
      updateAlert.mutate({
        orgId,
        crId,
        alertID: alertDetails.alertID,
        payload,
      });
    }
    else {
      const payload = {
        alertDetails:
        {
          alertName: name,
          emailID: emailList.join(','),
          alertPredicate: query,
          cleanRoomQuestionIDs: crqList.map(c => c?.ID),
          ...(chatID && { chatID }),
        },
      };
      createAlert.mutate({ orgId, crId, payload });
    }
  };

  React.useEffect(() => {
    if (createAlert.isSuccess) {
      history.push(internalAdmin.organization.alerts(crId));
      createAlert.reset();
    }
  }, [createAlert, history, crId]);

  React.useEffect(() => {
    if (updateAlert.isSuccess) {
      history.push(internalAdmin.organization.alerts(crId));
      updateAlert.reset();
    }
  }, [updateAlert, history, crId]);

  React.useEffect(() => {
    const { dispose } = monaco.languages.registerCompletionItemProvider('sql', {
      provideCompletionItems: (model) => {
        if (model.getValue().slice(-1) !== '@') {
          const standardKeywords = EDITOR_KEYWORDS.sql.map(keyword => (
            {
              label: keyword.name,
              insertText: keyword.name,
              kind: monaco.languages.CompletionItemKind.keyword,
              detail: `Keyword: ${keyword.details}`,
              documentation: keyword.details,
            }
          ));
          return { suggestions: [...standardKeywords] };
        }
        else {
          return [];
        }
      },
    });

    return (() => {
      dispose();
    });
  }, []);

  React.useEffect(() => {
    if (!questions.length && !isFetchingQuestions) {
      dispatch(showAlert({ message: 'No clean room questions found', type: 'error' }));
    }
  }, [dispatch, isFetchingQuestions, questions]);

  const handleCopy = async (val) => {
    const success = await copyToClipboard(val);
    if (success) {
      dispatch(showAlert({ message: 'Copied to clipboard', type: 'success' }));
    }
    else {
      dispatch(showAlert({ message: 'Failed to copy to clipboard', type: 'error' }));
    }
  };

  const handleListChange = (event, setList) => {
    const {
      target: { value },
    } = event;
    setList(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  loader.config({ monaco });

  if (alertId && isFetchingAlertDetails) {
    return (
      <Box height='100vh'>
        <HSpinner />
      </Box>
    );
  }

  return (
    <>
      <PageHeader title={`${(alertId) ? 'Edit' : 'Create'} Alert`} breadcrumbs={[crumbs.home, crumbs.internalAdmin, crumbs.alerts(crId)]} />
      <Card>
        <Grid container spacing={2} width='100%'>
          <Grid item xs={12} sm={3}>
            <Stack gap={2} paddingTop={2} paddingLeft={2}>
              <Box display='flex' flexDirection='column' gap={2}>
                <SectionHeader title='Alert Details' />
                <Box
                  display='flex'
                  alignItems='center'
                  gap={2}
                  sx={{
                    border: '1px solid grey',
                    padding: '14px 0 14px 8px',
                    background: habuColors.neutral[30],
                  }}
                >
                  <ViewInArIcon />
                  <Typography variant='h5'>
                    {
                      isFetchingCleanRoomDetails
                        ? <Skeleton width={200} height={28} />
                        : (
                          <>
                            {cleanRoomDetails.name}
                          </>
                        )
                      }
                  </Typography>
                </Box>
                <TextField
                  autoFocus
                  value={name || ''}
                  label='Alert Name'
                  onChange={(e) => setName(e.target.value)}
                  required
                />
                <FormControl required fullWidth>
                  <InputLabel id='crquestion-multiple-checkbox-label'>Base Questions</InputLabel>
                  <Select
                    labelId='crquestion-checkbox-label'
                    id='crquestion-checkbox'
                    multiple
                    value={crqList || []}
                    onChange={(e) => handleListChange(e, setCrqList)}
                    input={<OutlinedInput label='Base Questions' />}
                    renderValue={(selected) => selected.map(s => s?.displayID).join(', ')}
                    onClose={handleFetchSchemaInfo}
                  >
                    {questions.map((q) => (
                      <MenuItem key={q?.ID} value={q}>
                        <Checkbox checked={crqList?.map(c => c?.ID).indexOf(q?.ID) > -1} />
                        <ListItemText primary={q?.title} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Divider />
              <Box height={theme.spacing(42.5)} display='flex' flexDirection='column'>
                <SectionHeader title='HI Schema Tables' />
                <Box overflow='auto'>
                  {isFetchingSchemaList
                    && (
                    <Box display='flex' justifyContent='center' p={1}>
                      <HSpinner isButton />
                    </Box>
                    )}
                  {!hiSchemaInfoList.length && !isFetchingSchemaList
                  && (
                  <Alert
                    severity='info'
                  >
                    Please select base questions to get the related HI Schema Tables
                  </Alert>
                  )}
                  {hiSchemaInfoList.map(h => (
                    <Grid key={h.crqID} container spacing={2} mb={1}>
                      <Grid item sm={12}>
                        <Typography variant='h6'>
                          {crqList?.find(crq => crq.ID === h.crqID)?.title}
                        </Typography>
                      </Grid>
                      {crqList?.find(crq => crq.ID === h.crqID)
                        && (
                          <Stack direction='row' spacing={1} ml={2} mt={1}>
                            <HButton
                              variant='outlined'
                              onClick={() => handleCopy(h.runTableName)}
                              endIcon={<ContentCopyIcon />}
                            >
                              Run Data
                            </HButton>
                            <HButton
                              variant='outlined'
                              onClick={() => handleCopy(h.runMetadataTableName)}
                              endIcon={<ContentCopyIcon />}
                            >
                              Metadata
                            </HButton>
                          </Stack>
                        )}
                    </Grid>
                  ))}
                </Box>
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={9}>
            <Box display='flex' justifyContent='space-between' alignItems='start' paddingTop={2}>
              <SectionHeader title='Builder' />
              <FormControl sx={{ mb: 1.5, width: 300 }} size='small'>
                <InputLabel id='email-multiple-checkbox-label'>Recipients</InputLabel>
                <Select
                  labelId='email-multiple-checkbox-label'
                  id='email-multiple-checkbox'
                  multiple
                  value={emailList}
                  onChange={(e) => handleListChange(e, setEmailList)}
                  input={<OutlinedInput label='Recipients' />}
                  renderValue={(selected) => selected.join(', ')}
                >
                  {users.map((user) => (
                    <MenuItem key={user.ID} value={user.email}>
                      <Checkbox checked={emailList.indexOf(user.email) > -1} />
                      <ListItemText primary={`${user.name} (${user.email})`} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box position='relative' height={theme.spacing(72)}>
              <HIconButton
                sx={{
                  position: 'absolute',
                  top: '1rem',
                  right: '1rem',
                  zIndex: 1,
                  background: habuColors.neutral[50],
                }}
                aria-label='toggle-button'
                onClick={() => setIsDarkMode(prev => !prev)}
                color='secondary'
                size='small'
                disableRipple
              >
                {isDarkMode
                  ? <LightModeIcon />
                  : <DarkModeIcon />}
              </HIconButton>
              <Editor
                language='sql'
                theme={isDarkMode ? 'vs-dark' : ''}
                value={query}
                options={{
                  minimap: { enabled: false },
                  wordWrap: true,
                  fontSize: 18,
                  contextmenu: false,
                }}
                onChange={(value) => setQuery(value)}
              />
            </Box>
          </Grid>
        </Grid>
        <Box display='flex' justifyContent='flex-end' padding={1}>
          <CardActions data-testid='card-actions'>
            <HButton
              color='primary'
              size='small'
              variant='outlined'
              onClick={() => history.push(internalAdmin.organization.alerts(crId))}
            >
              Cancel
            </HButton>
            <HButton
              color='primary'
              size='small'
              variant='contained'
              onClick={() => onSubmit()}
              endIcon={(createAlert.isLoading || updateAlert.isLoading) && <HSpinner isButton />}
              disabled={!crqList?.length || !name || !query
                || createAlert.isLoading || updateAlert.isLoading}
            >
              Save
            </HButton>
          </CardActions>
        </Box>
      </Card>
    </>
  );
}
