import { mutateSingleEdge } from 'redux/reducer/FlowReducer/utils/mutateEdge';
import { mutateSingleNode } from 'redux/reducer/FlowReducer/utils';


export const mutateAPIFlowNodeResponse = (nodes) => {
  if (nodes.length === 0) return [];

  return nodes.map((node) => mutateSingleNode(node));
};

export const mutateAPIEdges = (edges) => {
  if (edges.length === 0) return [];
  return edges.map((edge) => mutateSingleEdge(edge));
};
