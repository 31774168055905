import React, {
  memo,
  useContext,
} from 'react';
import { DataContext } from 'BaseComponents/HDataTable/DataContextProvider';
import { Grid } from '@mui/material';


export const HCardsList = memo(() => {
  const {
    rows,
    cardElement: Card,
  } = useContext(DataContext);

  return (
    <Grid container spacing={2}>
      {rows.map(item => (
        <Grid key={item.ID} item xs={12} md={6} lg={4} xl={3}>
          <Card
            details={item}
          />
        </Grid>
      ))}
    </Grid>
  );
});

HCardsList.displayName = 'HCardsList';
