import ActivationChannelsReducer from 'redux/reducer/ActivationChannelsReducer';
import alertReducer from 'redux/reducer/AlertReducer';
import authenticationReducer from 'redux/reducer/AuthenticationReducer';
import cleanRoomsReducer from 'redux/reducer/CleanRoomsReducer';
import dataConnectionReducer from 'redux/reducer/DataConnectionReducer';
import dataCredentialReducer from 'redux/reducer/DataCredentialReducer';
import dataInAttrsReducer from 'redux/reducer/DataInAttrsReducer';
import dataInReducer from 'redux/reducer/DataInReducer';
import dataLocationReducer from 'redux/reducer/DataLocationReducer';
import dataOutReducer from 'redux/reducer/DataOutReducer';
import experimentReducer from 'redux/reducer/ExperimentReducer';
import fullViewAutomationReducer from 'redux/reducer/FullViewAutomationReducer';
import iamReducer from 'redux/reducer/IamReducer';
import internalAdminReducer from 'pages/InternalAdmin/redux/InternalAdminReducer';
import lookupsReducer from 'redux/reducer/LookupsReducer';
import permissionReducer from 'redux/reducer/PermissionReducer';
import propertiesReducer from 'redux/reducer/PropertiesReducer';
import questionBuilderReducer from 'redux/reducer/QuestionBuilderReducer';
import userListsReducer from 'redux/reducer/UserListsReducer';
import { DataConnectionsReducer } from 'ProjectHelium/DataConnections/redux';
import { FlowReducer } from 'redux/reducer/FlowReducer';
import { alertChatReducer, alertChatUIReducer } from 'redux/reducer/AlertChatReducer';
import { combineReducers } from 'redux';
import { crPartnersReducer } from 'pages/CleanRooms/redux/CrPartners';
import { crUserListReducer } from 'pages/CleanRooms/redux/CrUserList';
import { demoDataReducer } from 'pages/InternalAdmin/organization/DemoData/redux';
import { synDataOutReducer } from 'pages/DataOut/redux';
import { synQuestionManagementReducer } from 'pages/QuestionManagement/redux';
import { synUserListReducer } from 'pages/UserLists/redux';
import { syntheticDatasetsReducer } from 'pages/InternalAdmin/organization/redux/SyntheticDatasets';


const RootReducer = combineReducers({
  activationChannels: ActivationChannelsReducer,
  alerts: alertReducer,
  alertChat: alertChatReducer,
  alertChatUI: alertChatUIReducer,
  cleanRooms: cleanRoomsReducer,
  crTgPartners: crPartnersReducer,
  crUserList: crUserListReducer,
  dataConnection: dataConnectionReducer,
  dataConnections: DataConnectionsReducer,
  dataCredential: dataCredentialReducer,
  dataIn: dataInReducer,
  dataInAttributes: dataInAttrsReducer,
  dataLocation: dataLocationReducer,
  dataOut: dataOutReducer,
  demoData: demoDataReducer,
  experiments: experimentReducer,
  flow: FlowReducer,
  fullViewAutomation: fullViewAutomationReducer,
  iam: iamReducer,
  internalAdmin: internalAdminReducer,
  lookups: lookupsReducer,
  permission: permissionReducer,
  properties: propertiesReducer,
  questionBuilder: questionBuilderReducer,
  questionManagement: synQuestionManagementReducer,
  session: authenticationReducer,
  synDataOut: synDataOutReducer,
  synUserLists: synUserListReducer,
  syntheticDatasets: syntheticDatasetsReducer,
  userLists: userListsReducer,
});

export default RootReducer;
