import * as actionGenerators from 'redux/actions/PermissionActions';
import Logger from 'components/Common/Logger';
import http from 'utils/http';
import { PERMISSION } from 'redux/actions/Types';
import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import { showAlert } from 'redux/actions/AlertActions';


function* createPermission(action) {
  const { permission, error } = yield call(http.post, action.url, action.payload);
  if (error) {
    Logger.error(error, 'Permission: Creating permission');
    yield put(showAlert({ message: error, type: 'error' }));
    return yield put(actionGenerators.createPermissionError(error));
  }
  else {
    yield put(showAlert({ message: 'Your permission has been created', type: 'success' }));
    return yield put(actionGenerators.createPermissionSuccess(permission));
  }
}

function* fetchProductPermission(action) {
  const { permissions, error } = yield call(http.get, action.url);
  if (error) {
    Logger.error(error, 'Permission: Fetching product permissions');
    yield put(showAlert({ message: error, type: 'error' }));
    return yield put(actionGenerators.fetchProductPermissionError(error));
  }
  else {
    return yield put(actionGenerators.fetchProductPermissionSuccess(permissions));
  }
}

function* updateProductPermissions(action) {
  const { permissions, error } = yield call(http.post, action.url, action.payload);
  if (error) {
    Logger.error(error, 'Permission: Updating product permissions');
    yield put(showAlert({ message: error, type: 'error' }));
    return yield put(actionGenerators.updateProductPermissionsError(error));
  }
  else {
    yield put(showAlert({ message: 'Product permission has been updated', type: 'success' }));
    return yield put(actionGenerators.updateProductPermissionsSuccess(permissions));
  }
}


export default function* root() {
  yield all([
    takeLatest(PERMISSION.CREATE_PERMISSION, createPermission),
    takeLatest(PERMISSION.FETCH_PRODUCT_PERMISSION, fetchProductPermission),
    takeLatest(PERMISSION.UPDATE_PRODUCT_PERMISSIONS, updateProductPermissions),
  ]);
}

export {
  createPermission,
  fetchProductPermission,
  updateProductPermissions,
};
