import Box from '@mui/material/Box';
import React from 'react';
import Typography from '@mui/material/Typography';
import { HButton } from 'BaseComponents';
import { Link } from 'react-router-dom';


const SectionHeader = ({
  title, subtitle, addNewLabel, addNewLink, testID,
}) => (
  <Box display='flex' justifyContent='space-between' alignItems='center'>
    <Box mb={1}>
      <Typography variant='h5' data-testid={testID}>{title}</Typography>
      <Typography variant='body2'>{subtitle}</Typography>
    </Box>
    {addNewLabel && addNewLink && (
      <Box>
        <HButton
          variant='contained'
          size='small'
          color='primary'
          component={Link}
          to={addNewLink}
        >
          {addNewLabel}
        </HButton>
      </Box>
    )}
  </Box>
);

export default SectionHeader;
