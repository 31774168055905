import ConfirmationDialog from 'components/Common/ConfirmationDialog';
import EditParam from './EditParam';
import React, { useCallback, useState } from 'react';
import TableContainer from '@mui/material/TableContainer';
import {
  Box, Table,
  TableBody, TableCell, TableHead, TableRow,
} from '@mui/material';
import { HButton } from 'BaseComponents';
import { OrgParameterSelect, OrgParameterSelectByName, OrgParameterTextField } from './OrgParameterSelects';
import {
  PRESET_ORGANIZATION_CATEGORIES,
  PRESET_ORGANIZATION_PARAMETERS_NAMES,
  PRESET_ORGANIZATION_VALUES,
  isInputOrgParameter,
} from './constant';


const OrgParameterTable = ({
  orgParameters, cancel, update, deleteOrgParameters, updateOrgParameters,
}) => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [editingRow, setEditingRow] = useState({ name: '', value: '', category: '' });
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deletingRow, setDeletingRow] = useState(null);

  const onEditClose = () => {
    setEditingRow({ name: '', value: '', category: '' });
    setOpenEditModal(false);
  };

  const onEditOpen = (row) => {
    setEditingRow(row);
    setOpenEditModal(true);
  };

  const onDeleteModalOpen = (row) => {
    setOpenDeleteModal(true);
    setDeletingRow(row);
  };

  const onDeleteModalClose = () => {
    setOpenDeleteModal(false);
    setDeletingRow(null);
  };

  const deleteParameter = () => {
    deleteOrgParameters(deletingRow.name);
    onDeleteModalClose();
  };

  const hasParamNameError = useCallback((paramName) => {
    const doesParamAlreadyExist = orgParameters
      .some((param) => !isInputOrgParameter(param) && param.name === paramName);

    if (doesParamAlreadyExist) {
      return {
        error: true,
        helperText: 'The selected parameter name already exists',
      };
    }

    return {};
  }, [orgParameters]);

  return (
    <>
      <TableContainer>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell width='30%'>Parameter Name</TableCell>
              <TableCell align='left' width='30%'>Value</TableCell>
              <TableCell align='left' width='30%'>Category</TableCell>
              <TableCell align='left' width='10%' />
            </TableRow>
          </TableHead>
          <TableBody>
            {orgParameters?.map(row => (row.ID
              && (
                <TableRow key={row.ID}>
                  {
                    row.type === 'add'
                      ? (
                        <>
                          <TableCell>
                            <OrgParameterSelect
                              options={PRESET_ORGANIZATION_PARAMETERS_NAMES}
                              onChange={value => update(value, row, 'name')}
                              value={row.name}
                              renderInput={(params) => (
                                <OrgParameterTextField
                                  {...params}
                                  {...hasParamNameError(row.name)}
                                  label='Parameter Name'
                                />
                              )}
                            />
                          </TableCell>
                          <TableCell>
                            <OrgParameterSelectByName
                              nameParameterState={row.name}
                              options={PRESET_ORGANIZATION_VALUES[row?.name]?.options ?? []}
                              onChange={value => update(value, row, 'value')}
                              value={row.value}
                              renderInput={(params) => (
                                <OrgParameterTextField
                                  {...params}
                                  label='Value'
                                />
                              )}
                              {...(
                                PRESET_ORGANIZATION_VALUES[row?.name]?.autocompleteProps ?? {}
                              )}
                            />
                          </TableCell>
                          <TableCell>
                            <OrgParameterSelectByName
                              nameParameterState={row.name}
                              options={PRESET_ORGANIZATION_CATEGORIES[row?.name]?.options ?? []}
                              onChange={value => update(value, row, 'category')}
                              value={row.category}
                              renderInput={(params) => (
                                <OrgParameterTextField
                                  {...params}
                                  label='Category'
                                />
                              )}
                              {...(
                                PRESET_ORGANIZATION_CATEGORIES[row?.name]?.autocompleteProps ?? {}
                              )}
                            />
                          </TableCell>
                          <TableCell>
                            <HButton
                              color='primary'
                              size='small'
                              variant='contained'
                              onClick={() => cancel(row.ID)}
                            >
                              Cancel
                            </HButton>
                          </TableCell>
                        </>
                      )
                      : (
                        <>
                          <TableCell>
                            {row.name}
                          </TableCell>
                          <TableCell>
                            {row.value}
                          </TableCell>
                          <TableCell>
                            {row.category}
                          </TableCell>
                          <TableCell>
                            <Box
                              display='flex'
                              gap={1}
                            >
                              <HButton
                                size='small'
                                variant='contained'
                                onClick={() => onEditOpen(row)}
                              >
                                Edit
                              </HButton>
                              <HButton
                                size='small'
                                variant='outlined'
                                onClick={() => onDeleteModalOpen(row)}
                              >
                                Delete
                              </HButton>
                            </Box>
                          </TableCell>
                        </>
                      )
                  }
                </TableRow>
              )
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <EditParam
        onClose={onEditClose}
        open={openEditModal}
        row={editingRow}
        update={update}
        updateOrgParameters={updateOrgParameters}
      />
      <ConfirmationDialog
        open={openDeleteModal}
        title='Delete?'
        message='Are you sure you want to delete Organization Parameter?'
        confirmActionLabel='Delete'
        onConfirm={deleteParameter}
        onCancel={onDeleteModalClose}
      />
    </>
  );
};

export default OrgParameterTable;
