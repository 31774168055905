export const PROPERTIES = {
  ARCHIVE_PROPERTY: 'ARCHIVE_PROPERTY',
  ARCHIVE_PROPERTY_FAILURE: 'ARCHIVE_PROPERTY_FAILURE',
  ARCHIVE_PROPERTY_SUCCESS: 'ARCHIVE_PROPERTY_SUCCESS',
  CLEAR_PROPERTY: 'CLEAR_PROPERTY',
  CREATE_CONFIGURATION: 'CREATE_CONFIGURATION',
  CREATE_CONFIGURATION_FAILURE: 'CREATE_CONFIGURATION_FAILURE',
  CREATE_CONFIGURATION_SUCCESS: 'CREATE_CONFIGURATION_SUCCESS',
  CREATE_TAG_JOB: 'CREATE_TAG_JOB',
  CREATE_TAG_JOB_FAILURE: 'CREATE_TAG_JOB_FAILURE',
  CREATE_TAG_JOB_SUCCESS: 'CREATE_TAG_JOB_SUCCESS',
  CREATE_PROPERTY: 'CREATE_PROPERTY',
  CREATE_PROPERTY_FAILURE: 'CREATE_PROPERTY_FAILURE',
  CREATE_PROPERTY_SUCCESS: 'CREATE_PROPERTY_SUCCESS',
  FETCH_ARCHIVED_PROPERTIES: 'FETCH_ARCHIVED_PROPERTIES',
  FETCH_ARCHIVED_PROPERTIES_FAILURE: 'FETCH_ARCHIVED_PROPERTIES_FAILURE',
  FETCH_ARCHIVED_PROPERTIES_SUCCESS: 'FETCH_ARCHIVED_PROPERTIES_SUCCESS',
  FETCH_PROPERTIES: 'FETCH_PROPERTIES',
  FETCH_PROPERTIES_ERROR: 'FETCH_PROPERTIES_ERROR',
  FETCH_PROPERTIES_SUCCESS: 'FETCH_PROPERTIES_SUCCESS',
  FETCH_PROPERTY: 'FETCH_PROPERTY',
  FETCH_PROPERTY_CONFIGURATIONS: 'FETCH_PROPERTY_CONFIGURATIONS',
  FETCH_PROPERTY_CONFIGURATIONS_FAILURE: 'FETCH_PROPERTY_CONFIGURATIONS_FAILURE',
  FETCH_PROPERTY_CONFIGURATIONS_SUCCESS: 'FETCH_PROPERTY_CONFIGURATIONS_SUCCESS',
  FETCH_PROPERTY_CONFIGURATION_DETAIL: 'FETCH_PROPERTY_CONFIGURATION_DETAIL',
  FETCH_PROPERTY_CONFIGURATION_DETAIL_FAILURE: 'FETCH_PROPERTY_CONFIGURATION_DETAIL_FAILURE',
  FETCH_PROPERTY_CONFIGURATION_DETAIL_SUCCESS: 'FETCH_PROPERTY_CONFIGURATION_DETAIL_SUCCESS',
  FETCH_FOREBITT_CONFIGURATION_DETAIL: 'FETCH_FOREBITT_CONFIGURATION_DETAIL',
  FETCH_FOREBITT_CONFIGURATION_DETAIL_FAILURE: 'FETCH_FOREBITT_CONFIGURATION_DETAIL_FAILURE',
  FETCH_FOREBITT_CONFIGURATION_DETAIL_SUCCESS: 'FETCH_FOREBITT_CONFIGURATION_DETAIL_SUCCESS',
  FETCH_PROPERTY_ERROR: 'FETCH_PROPERTY_ERROR',
  FETCH_PROPERTY_SUCCESS: 'FETCH_PROPERTY_SUCCESS',
  UPDATE_QUARTERDECK_CONFIGURATION: 'UPDATE_QUARTERDECK_CONFIGURATION',
  UPDATE_QUARTERDECK_CONFIGURATION_ERROR: 'UPDATE_QUARTERDECK_CONFIGURATION_ERROR',
  UPDATE_QUARTERDECK_CONFIGURATION_SUCCESS: 'UPDATE_QUARTERDECK_CONFIGURATION_SUCCESS',
  UPDATE_FOREBITT_CONFIGURATION: 'UPDATE_FOREBITT_CONFIGURATION',
  UPDATE_FOREBITT_CONFIGURATION_ERROR: 'UPDATE_FOREBITT_CONFIGURATION_ERROR',
  UPDATE_FOREBITT_CONFIGURATION_SUCCESS: 'UPDATE_FOREBITT_CONFIGURATION_SUCCESS',
  UPDATE_PROPERTY: 'UPDATE_PROPERTY',
  UPDATE_PROPERTY_FAILURE: 'UPDATE_PROPERTY_FAILURE',
  UPDATE_PROPERTY_SUCCESS: 'UPDATE_PROPERTY_SUCCESS',
};
