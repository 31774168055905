import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import FlowMetadataField from 'components/Flow/FlowMetadataField';
import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { FlowBuilderContext } from 'pages/Flow/FlowBuilder/FlowBuilderContextProvider';
import { HButton } from 'BaseComponents';


const FlowMetadataEditModal = ({ open, field, onClose }) => {
  const { handleNameInput, handleDescriptionInput, flow } = useContext(FlowBuilderContext);
  const [currVal, setCurrVal] = useState();

  useEffect(() => {
    setCurrVal(flow[field]);
  }, [field, flow]);

  const component = useMemo(() => {
    switch (field) {
      case 'name':
        return ({
          title: 'Title',
          content: <FlowMetadataField
            label='Flow Title'
            value={currVal}
            onChange={val => setCurrVal(val)}
          />,
          onSave: handleNameInput,
        });
      case 'description':
        return ({
          title: 'Description',
          content: <FlowMetadataField
            label='Flow Description'
            value={currVal}
            onChange={val => setCurrVal(val)}
          />,
          onSave: handleDescriptionInput,
        });
      default:
        return null;
    }
  }, [currVal, field, handleDescriptionInput, handleNameInput]);

  const handleSave = (e) => {
    e.preventDefault();
    component.onSave(currVal);
    onClose();
  };

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth='md'
      data-testid='flow-metadata-edit-modal'
      onClose={onClose}
    >
      <DialogTitle variant='h3'>{component.title}</DialogTitle>
      <Divider />
      <DialogContent>
        {component.content}
      </DialogContent>
      <Divider />
      <DialogActions>
        <HButton variant='outlined' onClick={onClose}>Cancel</HButton>
        <HButton
          variant='contained'
          onClick={handleSave}
        >
          Save
        </HButton>
      </DialogActions>
    </Dialog>
  );
};

export default FlowMetadataEditModal;
