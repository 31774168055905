import AlertListLayout from 'pages/InternalAdmin/organization/pages/Alerts/AlertsListLayout';
import Box from '@mui/material/Box';
import PageHeader from 'components/Common/PageHeader';
import React, { createContext, useEffect } from 'react';
import { CLEAN_ROOM_STATUS } from 'components/CleanRooms/constants';
import { HSpinner } from 'BaseComponents';
import { activeOrgSelector } from 'redux/reducer/AuthenticationReducer';
import { crumbs } from 'components/Common/BreadcrumbsNavigation';
import { internalAdmin } from 'utils/spaUrls';
import { useFetchCleanRoomList } from 'hooks/cleanRoomAPIs';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';


export const AlertsPageContext = createContext({});

const Alerts = () => {
  const history = useHistory();

  const { crId } = useParams();
  const { ID: orgId } = useSelector(activeOrgSelector);

  const {
    isFetching: fetchingCRList,
    isError: isCrListError,
    data: crList,
  } = useFetchCleanRoomList(orgId, { filters: [] });

  const onCleanRoomChange = (cleanRoomID) => {
    history.replace(internalAdmin.organization.alerts(cleanRoomID));
  };

  useEffect(() => {
    if (!crId && !fetchingCRList && crList.length > 0) {
      history.replace(internalAdmin.organization.alerts(crList[0].ID));
    }
  }, [crId, crList, fetchingCRList, history]);

  const selectedCr = crList.find(cr => cr.ID === crId);
  const currentCrStatus = selectedCr ? selectedCr.status : null;

  const isCurrentCrExpired = currentCrStatus === CLEAN_ROOM_STATUS.EXPIRED.key;

  return (
    <>
      <PageHeader title='Alerts' breadcrumbs={[crumbs.home, crumbs.internalAdmin]} />
      {
        crId ? (
          <AlertsPageContext.Provider value={{
            crId,
            onCleanRoomChange,
            crListQuery: {
              fetchingCRList,
              isCrListError,
              crList,
            },
            isCurrentCrExpired,
          }}
          >
            <AlertListLayout />
          </AlertsPageContext.Provider>
        ) : <Box><HSpinner /></Box>
      }
    </>
  );
};

export default Alerts;
