import React from 'react';
import { axiosInstance } from 'utils/http';
import {
  cleanRoom, crPartners, customization, iamOrganizations,
} from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';


const useFetchCrPartnerInvitationListByUser = (orgId) => {
  const dispatch = useDispatch();
  const [data, setData] = React.useState([]);
  const {
    isLoading, isError, isSuccess, refetch,
  } = useQuery(
    ['InvitationByUser'],
    async () => axiosInstance.get(crPartners(orgId).fetchPartnerInvitationsByUser),
    {
      refetchOnWindowFocus: false,
      onSuccess: (res) => {
        setData(res.data.invitations);
      },
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );
  return {
    isLoading,
    isError,
    isSuccess,
    refetch,
    data,
  };
};

const useInviteCrPartnerV2 = (organizationID, cleanRoomID) => {
  const dispatch = useDispatch();
  const {
    isLoading, isError, isSuccess, mutate, reset, error,
  } = useMutation(
    ['InviteCleanRoomPartner'],
    async ({ payload }) => axiosInstance.post(
      crPartners(organizationID, cleanRoomID).invitePartnerV2, payload,
    ),
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onSuccess: () => dispatch(showAlert({ message: 'Successfully invited the partner', type: 'success' })),
      onError: err => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    mutate,
    reset,
    error,
  };
};

const useChangeInvitationStatus = (organizationID, cleanRoomID) => {
  const dispatch = useDispatch();
  const {
    isLoading, isError, isSuccess, mutate, reset,
  } = useMutation(
    async ({ invitationID, payload }) => axiosInstance.patch(
      crPartners(organizationID, cleanRoomID).changeInvitationDetailStatus(invitationID), payload,
    ),
    {
      onSuccess: () => {
        dispatch(showAlert({ message: 'Changed invitation status successfully', type: 'success' }));
      },
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    mutate,
    reset,
  };
};

const useFetchCleanRoom = (orgId, crId) => {
  const dispatch = useDispatch();
  const [data, setData] = React.useState({});
  const {
    isLoading, isError, isSuccess, isFetching, refetch,
  } = useQuery(
    ['CleanRoom', orgId, crId],
    async () => axiosInstance.get(cleanRoom(orgId).crDetail(crId)),
    {
      enabled: !!crId,
      refetchOnWindowFocus: false,
      onSuccess: (res) => setData(res.data.cleanRoom),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    isFetching,
    data,
    refetch,
  };
};

const useFetchCleanRoomDetails = (orgId, crId) => {
  const dispatch = useDispatch();
  const [data, setData] = React.useState({});
  const {
    isLoading, isError, isSuccess, isFetching, refetch,
  } = useQuery(
    ['CleanRoomDetails', orgId, crId],
    async () => axiosInstance.get(cleanRoom(orgId).details(crId)),
    {
      enabled: !!crId,
      refetchOnWindowFocus: false,
      onSuccess: (res) => setData(res.data.details),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    isFetching,
    data,
    refetch,
  };
};

const useFetchCleanRoomFilters = () => {
  const dispatch = useDispatch();
  const orgId = useSelector(state => state.session.user.primaryOrganization.organization.ID);
  const [data, setData] = React.useState([]);
  const {
    isError, isSuccess, isFetching,
  } = useQuery(
    ['CleanRoomFilters'],
    async () => axiosInstance.get(cleanRoom(orgId).listFilters),
    {
      refetchOnWindowFocus: false,
      onSuccess: (res) => setData(res.data),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isError,
    isSuccess,
    isFetching,
    data,
  };
};

const useFetchCleanRoomList = (orgID, payload) => {
  const dispatch = useDispatch();
  const [data, setData] = React.useState([]);
  const {
    isLoading, isError, isSuccess, isFetching,
  } = useQuery(
    ['CleanRoomList', orgID, payload],
    async () => axiosInstance.post(cleanRoom(orgID).list, payload),
    {
      enabled: !!orgID,
      refetchOnWindowFocus: false,
      onSuccess: (res) => setData(res.data.cleanRooms),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    isFetching,
    data,
  };
};

const useFetchCRDataset = (cleanRoomId, datasetId, enabledFlag = true) => {
  const dispatch = useDispatch();
  const orgId = useSelector(state => state.session.user.primaryOrganization.organization.ID);
  const [data, setData] = React.useState({});

  const {
    isLoading, isError, isSuccess, isFetching, refetch,
  } = useQuery(
    ['CleanRoomDataset', cleanRoomId, datasetId],
    async () => axiosInstance.get(cleanRoom(orgId).getDataset(cleanRoomId, datasetId)),
    {
      enabled: (!!cleanRoomId && !!datasetId) && enabledFlag,
      onSuccess: (res) => setData(res.data.dataSet),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
      retry: false,
    },
  );

  return {
    refetch,
    isFetching,
    isLoading,
    isError,
    isSuccess,
    data,
  };
};

const useUpdateCRDatasetDelegation = () => {
  const dispatch = useDispatch();
  const {
    isLoading, isError, isSuccess, mutate, refetch, reset,
  } = useMutation(
    ['UpdateDatasetDelegation'],
    async ({ orgID, cleanRoomID, payload }) => axiosInstance
      .post(cleanRoom(orgID).updateDatasetDelegation(cleanRoomID), payload),
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onSuccess: () => dispatch(showAlert({ message: 'Clean Room dataset permissions updated', type: 'success' })),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    mutate,
    refetch,
    reset,
  };
};


const useFetchCRDatasetMutation = (cleanRoomId) => {
  const dispatch = useDispatch();
  const orgId = useSelector(state => state.session.user.primaryOrganization.organization.ID);
  const [data, setData] = React.useState({});

  const {
    isLoading, isError, isSuccess, mutate, refetch, reset,
  } = useMutation(
    ['CleanRoomDataset'],
    async (datasetId) => axiosInstance
      .get(cleanRoom(orgId).getDataset(cleanRoomId, datasetId)),
    {
      onSuccess: (res) => setData(res.data.dataSet),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
      retry: false,
    },
  );

  return {
    data,
    isLoading,
    isError,
    isSuccess,
    mutate,
    refetch,
    reset,
  };
};

const useFetchCRAllDatasets = (cleanRoomId) => {
  const dispatch = useDispatch();
  const orgId = useSelector(state => state.session.user.primaryOrganization.organization.ID);
  const [data, setData] = React.useState([]);
  const {
    isLoading, isFetching, isError, isSuccess,
  } = useQuery(
    ['CleanRoomAllDatasets', cleanRoomId],
    async () => axiosInstance.get(cleanRoom(orgId).getAllDatasets(cleanRoomId)),
    {
      enabled: (!!cleanRoomId),
      onSuccess: (res) => setData(res?.data?.dataSets || []),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
      retry: false,
    },
  );

  return {
    isLoading,
    isFetching,
    isError,
    isSuccess,
    data,
  };
};

const useFetchDatasetTransformationFunctions = () => {
  const dispatch = useDispatch();
  const [data, setData] = React.useState([]);
  const { isLoading, isError, isSuccess } = useQuery(
    ['DatasetTransformationFunctions'],
    async () => axiosInstance.get(cleanRoom().getDatasetTransFunc),
    {
      onSuccess: (res) => setData(res.data.functions),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
      retry: false,
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    data,
  };
};

const useFetchCleanRoomTypes = (orgID = '', cleanRoomID, enabled = true) => {
  const dispatch = useDispatch();
  const {
    data, isLoading, isError, isSuccess, isFetching,
  } = useQuery(
    ['CleanRoomTypesList', orgID],
    async () => {
      if (cleanRoomID) {
        return axiosInstance.get(cleanRoom(orgID).cleanRoomTypesByCleanRoomID(cleanRoomID));
      }
      else {
        return axiosInstance.get(cleanRoom(orgID).cleanRoomTypes());
      }
    },
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(enabled),
      select: (res) => res.data.cleanRoomTypes,
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    data,
    isLoading,
    isError,
    isSuccess,
    isFetching,
  };
};

const useFetchAccountCleanRoomTypes = (accountID = '') => {
  const dispatch = useDispatch();

  const {
    data, isLoading, isError, isSuccess,
  } = useQuery(
    ['AccountCleanRoomTypesList'],
    async () => axiosInstance.get(iamOrganizations(accountID).getCleanRoomTypes),
    {
      select: (res) => res.data.cleanRoomTypes,
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    data,
    isLoading,
    isError,
    isSuccess,
  };
};

const useUpdateCleanRoomTypes = () => {
  const dispatch = useDispatch();
  const {
    isLoading, isError, isSuccess, mutate, refetch, reset,
  } = useMutation(
    ['UpdateCleanRoomTypes'],
    async ({ orgID, payload }) => axiosInstance
      .put(cleanRoom(orgID).updateCleanRoomTypes, payload),
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onSuccess: () => dispatch(showAlert({ message: 'Clean Room Types Updated', type: 'success' })),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    mutate,
    refetch,
    reset,
  };
};

const useUpdateAccountCleanRoomTypes = () => {
  const dispatch = useDispatch();
  const {
    isLoading, isError, isSuccess, mutate, refetch, reset,
  } = useMutation(
    ['UpdateAccountCleanRoomTypes'],
    async ({ accountID, payload }) => axiosInstance
      .put(iamOrganizations(accountID).getCleanRoomTypes, payload),
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onSuccess: () => dispatch(showAlert({ message: 'Account Clean Room Types Updated', type: 'success' })),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    mutate,
    refetch,
    reset,
  };
};

const useFetchCleanRoomUsers = (orgId, crId) => {
  const dispatch = useDispatch();
  const [data, setData] = React.useState([]);
  const {
    isLoading, isFetching, isError, isSuccess, refetch,
  } = useQuery(
    ['CleanRoomUsers', orgId, crId],
    async () => axiosInstance.get(cleanRoom(orgId).users(crId)),
    {
      onSuccess: (res) => setData(res.data.users),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
      enabled: !!crId,
      refetchOnWindowFocus: false,
      retry: false,
    },
  );

  return {
    isLoading: isLoading || isFetching,
    isError,
    isSuccess,
    data,
    refetch,
  };
};

const useFetchCleanRoomUserOrgName = (orgId, crId) => {
  const dispatch = useDispatch();
  const [data, setData] = React.useState([]);
  const {
    isLoading, isFetching, isError, isSuccess, refetch,
  } = useQuery(
    ['CleanRoomUserOrgName', orgId, crId],
    async () => axiosInstance.get(cleanRoom(orgId).userOrgName(crId)),
    {
      onSuccess: (res) => setData(res.data),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
      enabled: !!orgId && !!crId,
      refetchOnWindowFocus: false,
    },
  );

  return {
    isLoading: isLoading || isFetching,
    isError,
    isSuccess,
    data,
    refetch,
  };
};

const useFetchCleanRoomPartners = (orgId, crId) => {
  const dispatch = useDispatch();
  const [data, setData] = React.useState();
  const {
    isLoading, isError, isSuccess, refetch,
  } = useQuery(
    ['Partners'],
    async () => axiosInstance.get(crPartners(orgId, crId).partners),
    {
      onSuccess: (res) => setData(res.data.cleanRoomOrg),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
      retry: false,
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    data,
    refetch,
  };
};

const useFetchCleanRoomPartnerUsers = (orgId, crId, partnerId) => {
  const dispatch = useDispatch();
  const [data, setData] = React.useState([]);
  const {
    isLoading, isFetching, isError, isSuccess, refetch,
  } = useQuery(
    ['Partner Users', partnerId],
    async () => axiosInstance.get(cleanRoom(partnerId).users(crId)),
    {
      onSuccess: (res) => setData(res.data.users),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
      enabled: !!partnerId,
      refetchOnWindowFocus: false,
      retry: false,
    },
  );

  return {
    isLoading: isLoading || isFetching,
    isError,
    isSuccess,
    data,
    refetch,
  };
};

const useFetchCleanRoomPartnerRoles = (orgId, crId, partnerId) => {
  const dispatch = useDispatch();
  const [data, setData] = React.useState();
  const {
    isLoading, isError, isSuccess, refetch,
  } = useQuery(
    ['Partner Roles', partnerId],
    async () => axiosInstance.get(crPartners(orgId, crId).roles(partnerId)),
    {
      onSuccess: (res) => setData(res.data.cleanroomRoles),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
      enabled: !!partnerId,
      retry: false,
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    data,
    refetch,
  };
};

const useFetchCleanRoomPartnerRole = (orgId, crId, partnerId, roleId, enabled) => {
  const dispatch = useDispatch();
  const [data, setData] = React.useState();
  const {
    isLoading, isError, isSuccess,
  } = useQuery(
    ['Partner Role'],
    async () => axiosInstance.get(crPartners(orgId, crId).role(partnerId, roleId)),
    {
      onSuccess: (res) => setData(res.data),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
      enabled,
      retry: false,
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    data,
  };
};

const useCreateCleanRoomRole = () => {
  const dispatch = useDispatch();
  const {
    isLoading, isError, isSuccess, mutate, reset,
  } = useMutation(
    async ({
      crId, partnerId, payload,
    }) => axiosInstance
      .post(crPartners(null, crId).createRole(partnerId), payload),
    {
      onSuccess: () => dispatch(showAlert({ message: 'Clean Room Role has been created.', type: 'success' })),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
      retry: false,
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    mutate,
    reset,
  };
};

const useUpdateCleanRoomRole = () => {
  const dispatch = useDispatch();
  const {
    isLoading, isError, isSuccess, mutate, reset,
  } = useMutation(
    async ({
      crId, partnerId, roleId, payload,
    }) => axiosInstance
      .put(crPartners(null, crId).updateRole(partnerId, roleId), payload),
    {
      onSuccess: () => dispatch(showAlert({ message: 'Clean Room Role has been created.', type: 'success' })),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
      retry: false,
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    mutate,
    reset,
  };
};

const useDeleteCleanRoomRole = () => {
  const dispatch = useDispatch();
  const {
    isLoading, isError, isSuccess, mutate, reset,
  } = useMutation(
    async ({
      crId, partnerId, roleId,
    }) => axiosInstance
      .delete(crPartners(null, crId).deleteRole(partnerId, roleId)),
    {
      onSuccess: () => dispatch(showAlert({ message: 'Clean Room Role has been deleted.', type: 'success' })),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
      retry: false,
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    mutate,
    reset,
  };
};

const useFetchCleanRoomPartnerPermissions = (partnerId, crId) => {
  const dispatch = useDispatch();
  const [data, setData] = React.useState();
  const {
    isLoading, isError, isSuccess, refetch,
  } = useQuery(
    ['Partner Permissions'],
    async () => axiosInstance.get(crPartners(partnerId, crId).crPermissions),
    {
      onSuccess: (res) => setData(res.data.cleanroomPermissions),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
      retry: false,
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    data,
    refetch,
  };
};

const useFetchCleanRoomPermissions = (orgId, crId) => {
  const dispatch = useDispatch();
  const [data, setData] = React.useState();
  const {
    isLoading, isError, isSuccess, refetch,
  } = useQuery(
    ['Clean Room Permissions'],
    async () => axiosInstance.get(crPartners(orgId, crId).crPermissions),
    {
      onSuccess: (res) => setData(res.data.cleanroomPermissions),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
      retry: false,
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    data,
    refetch,
  };
};

const useSetPartnerPermissions = () => {
  const dispatch = useDispatch();
  const {
    isLoading, isError, isSuccess, mutate, reset,
  } = useMutation(
    async ({
      orgId, crId, partnerId, payload,
    }) => axiosInstance
      .post(crPartners(orgId, crId).setPartnerPermissions(partnerId), payload),
    {
      onSuccess: () => dispatch(showAlert({ message: 'Clean Room Permissions have been set.', type: 'success' })),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
      retry: false,
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    mutate,
    reset,
  };
};

const useUpdatePartnerUserRole = () => {
  const dispatch = useDispatch();
  const {
    isLoading, isError, isSuccess, mutate, reset, data,
  } = useMutation(
    async ({
      orgId, crId, partnerId, userId, roleId,
    }) => axiosInstance
      .put(crPartners(orgId, crId).updateUserRole(partnerId, userId, roleId)),
    {
      onSuccess: () => dispatch(showAlert({ message: 'User role has been updated.', type: 'success' })),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
      retry: false,
    },
  );

  return {
    data,
    isLoading,
    isError,
    isSuccess,
    mutate,
    reset,
  };
};

const useDeleteCleanRoomUser = () => {
  const dispatch = useDispatch();
  const {
    isLoading, isError, isSuccess, mutate, reset,
  } = useMutation(
    async ({
      crId, partnerId, userId,
    }) => axiosInstance
      .delete(crPartners(null, crId).deleteUser(partnerId, userId)),
    {
      onSuccess: () => dispatch(showAlert({ message: 'Clean Room User has been deleted.', type: 'success' })),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
      retry: false,
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    mutate,
    reset,
  };
};

const useDeleteCleanRoomPartner = () => {
  const dispatch = useDispatch();
  const {
    isLoading, isError, isSuccess, mutate, reset,
  } = useMutation(
    async ({
      orgId, crId, partnerId,
    }) => axiosInstance
      .delete(crPartners(orgId, crId).detail(partnerId)),
    {
      onSuccess: () => dispatch(showAlert({ message: 'Clean Room Partner has been removed.', type: 'success' })),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
      retry: false,
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    mutate,
    reset,
  };
};

const useFetchCleanRoomShareablePartners = (orgId, crId) => {
  const dispatch = useDispatch();
  const [data, setData] = React.useState([]);
  const {
    isLoading, isError, isSuccess, isFetching,
  } = useQuery(
    ['CleanRoomShareablePartners', orgId, crId],
    async () => axiosInstance.post(crPartners(orgId, crId).shareablePartners),
    {
      enabled: !!(orgId && crId),
      refetchOnWindowFocus: false,
      onSuccess: (res) => setData(res.data.partners),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    isFetching,
    data,
  };
};

const useGetCleanRoomList = () => {
  const dispatch = useDispatch();
  const orgID = useSelector(state => state.session.user.primaryOrganization.organization.ID);
  const [data, setData] = React.useState([]);
  const {
    isLoading, isError, isSuccess, mutate, reset,
  } = useMutation(
    'GetCleanRoomList',
    async (payload) => axiosInstance.post(cleanRoom(orgID).list, payload),
    {
      refetchOnWindowFocus: false,
      onSuccess: (res) => setData(res.data),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    mutate,
    data,
    reset,
  };
};

const useFetchInvitationsForAAccount = (accountID) => {
  const dispatch = useDispatch();
  const [data, setData] = React.useState({});
  const {
    isLoading, isError, isSuccess, refetch,
  } = useQuery(
    ['Invitations'],
    async () => axiosInstance.get(
      crPartners().invitationListByAccount(accountID),
    ),
    {
      refetchOnWindowFocus: false,
      onSuccess: res => {
        setData(res.data.invitations.reduce((obj, cur) => (
          { ...obj, [cur.ID]: cur }), {}));
      },
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading,
    isError,
    data,
    refetch,
    isSuccess,
  };
};

const useSubmitCrInvitationV2 = () => {
  const dispatch = useDispatch();
  const {
    isLoading, isError, isSuccess, mutate, reset, error,
  } = useMutation(
    ['SubmitCrInvitation'],
    async ({
      orgId, crId, invitationId, acId, acceptInvitation, payload,
    }) => axiosInstance
      .post(acceptInvitation ? crPartners(orgId, crId).acceptInviteV2(invitationId, acId)
        : crPartners(orgId, crId).rejectInviteV2(invitationId, acId), payload),
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    mutate,
    reset,
    error,
  };
};

const useSavePartnerCredential = () => {
  const dispatch = useDispatch();
  const { crId } = useParams();
  const {
    isLoading, isError, isSuccess, mutate, reset, error,
  } = useMutation(
    async ({
      orgId, credentialId,
    }) => axiosInstance.put(crPartners(orgId, crId).savePartnerCredential(credentialId)),
    {
      refetchOnWindowFocus: false,
      onSuccess: () => {
        dispatch(showAlert({ message: 'Partner credential saved to Clean Room.', type: 'success' }));
      },
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );
  return {
    isLoading,
    isError,
    isSuccess,
    mutate,
    reset,
    error,
  };
};

const useProvisionCleanRoom = () => {
  const dispatch = useDispatch();
  const orgId = useSelector(state => state.session.user.primaryOrganization.organization.ID);
  const {
    isLoading, isError, isSuccess, mutate, reset, mutateAsync,
  } = useMutation(
    async ({ crID }) => axiosInstance.post(cleanRoom(orgId).provisionCleanRoom(crID)),
    {
      onSuccess: () => dispatch(showAlert({ message: 'Clean Room successfully provisioned.', type: 'success' })),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    mutate,
    reset,
    mutateAsync,
  };
};

const useFetchCleanRoomCustomization = (organizationID, cleanRoomID) => {
  const dispatch = useDispatch();
  const currentOrgID = useSelector(state => state.session.user.primaryOrganization.organization.ID);
  const orgID = organizationID || currentOrgID;
  const {
    data, isError, isSuccess,
  } = useQuery(
    ['CleanRoomCustomization', orgID, cleanRoomID],
    async () => axiosInstance.get(customization(orgID).cleanRoom(cleanRoomID)),
    {
      enabled: !!orgID,
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isError,
    isSuccess,
    data: data?.data?.customizationParameters,
  };
};

const useFetchDataTypeOptions = ({
  orgId, crId, questionId, isPartnerAssignedDatasets, enabled = true,
}) => {
  const dispatch = useDispatch();

  return useQuery(
    ['DataTypeOptions', orgId, crId, questionId, String(isPartnerAssignedDatasets)],
    async () => axiosInstance.get(cleanRoom(orgId).questionDataOptions(
      crId, questionId, isPartnerAssignedDatasets,
    )),
    {
      enabled: Boolean(orgId && crId && questionId && enabled),
      select: (res) => res.data.dataTypes,
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );
};

const useFetchCleanRoomQuestionCountByDatasetID = ({
  orgId, crId, datasetId, enabled = true,
}) => {
  const dispatch = useDispatch();

  return useQuery(
    ['CleanRoomQuestionCountByDatasetID', orgId, crId, datasetId],
    async () => axiosInstance.get(cleanRoom(orgId).getCleanRoomQuestionCountByDatasetID(
      crId, datasetId,
    )),
    {
      enabled: Boolean(orgId && crId && datasetId && enabled),
      select: (res) => res.data.count,
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );
};

const useDeprovisionDataset = () => {
  const dispatch = useDispatch();
  const orgId = useSelector(state => state.session.user.primaryOrganization.organization.ID);
  return useMutation(
    ({ crId, datasetId }) => axiosInstance.delete(cleanRoom(orgId).deprovisionDataset(crId,
      datasetId)),
    {
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );
};

const useFetchCleanRoomDefaultQuestionPermissions = ({
  orgId, crId, enabled = true,
}) => {
  const dispatch = useDispatch();

  return useQuery(
    ['CleanRoomDefaultQuestionPermissions', orgId, crId],
    async () => axiosInstance.get(cleanRoom(orgId).crDefaultQuestionPermissions(crId)),
    {
      enabled: Boolean(orgId && crId && enabled),
      select: (res) => res?.data?.defaultQuestionPermissions || [],
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );
};

const useUpdateCleanRoomDefaultQuestionPermissions = () => {
  const dispatch = useDispatch();
  const orgId = useSelector(state => state.session.user.primaryOrganization.organization.ID);
  return useMutation(
    ({ crId, payload }) => axiosInstance.post(
      cleanRoom(orgId).crDefaultQuestionPermissions(crId),
      payload,
    ),
    {
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );
};

export {
  useFetchCleanRoom,
  useFetchCleanRoomFilters,
  useFetchCleanRoomList,
  useFetchCRDataset,
  useFetchCRAllDatasets,
  useFetchDatasetTransformationFunctions,
  useFetchCleanRoomTypes,
  useUpdateCleanRoomTypes,
  useFetchCleanRoomUsers,
  useFetchCleanRoomUserOrgName,
  useFetchCleanRoomPartners,
  useFetchCleanRoomPartnerUsers,
  useFetchCleanRoomPartnerRoles,
  useFetchCleanRoomPartnerRole,
  useCreateCleanRoomRole,
  useFetchCleanRoomDetails,
  useUpdateCleanRoomRole,
  useDeleteCleanRoomRole,
  useDeleteCleanRoomUser,
  useDeleteCleanRoomPartner,
  useFetchCleanRoomPartnerPermissions,
  useFetchCleanRoomPermissions,
  useSetPartnerPermissions,
  useSavePartnerCredential,
  useProvisionCleanRoom,
  useFetchCRDatasetMutation,
  useUpdatePartnerUserRole,
  useFetchCleanRoomShareablePartners,
  useGetCleanRoomList,
  useChangeInvitationStatus,
  useInviteCrPartnerV2,
  useFetchCrPartnerInvitationListByUser,
  useSubmitCrInvitationV2,
  useFetchInvitationsForAAccount,
  useFetchCleanRoomCustomization,
  useFetchAccountCleanRoomTypes,
  useUpdateAccountCleanRoomTypes,
  useUpdateCRDatasetDelegation,
  useFetchDataTypeOptions,
  useFetchCleanRoomQuestionCountByDatasetID,
  useDeprovisionDataset,
  useFetchCleanRoomDefaultQuestionPermissions,
  useUpdateCleanRoomDefaultQuestionPermissions,
};
