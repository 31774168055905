import Bouncer from 'components/Common/Bouncer';
import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { HButton } from 'BaseComponents';
import { rowsPerPageOptions, userTypes } from 'utils/appConstants';


const AccountListTable = ({
  accId, list, onSwitch, hide,
}) => {
  const [rowsPerPage, setRowsPerPage] = React.useState(rowsPerPageOptions[0]);
  const [page, setPage] = React.useState(0);

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align='left'>Name</TableCell>
            <TableCell align='right' />
            <Bouncer allowList={[userTypes.superuser]}>
              <TableCell align='center'>ID</TableCell>
            </Bouncer>
          </TableRow>
        </TableHead>
        <TableBody>
          {list
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row) => {
              const isCurrentAcc = row.ID === accId;

              return (
                <TableRow key={row.ID}>
                  <TableCell align='left' sx={{ fontWeight: isCurrentAcc ? 'bold' : 'inherit' }}>{hide ? '********' : row.displayName}</TableCell>
                  <TableCell>
                    <HButton
                      disabled={isCurrentAcc}
                      color='primary'
                      size='small'
                      onClick={() => onSwitch(row)}
                      variant='contained'
                    >
                      Switch Account
                    </HButton>
                  </TableCell>
                  <Bouncer allowList={[userTypes.superuser]}>
                    <TableCell>{row.ID}</TableCell>
                  </Bouncer>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component='div'
        count={list.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
        }}
        onPageChange={(e, newPage) => setPage(newPage)}
        onRowsPerPageChange={e => setRowsPerPage(e.target.value)}
      />
    </>
  );
};

export default AccountListTable;
