import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useEffect } from 'react';
import Skeleton from '@mui/material/Skeleton';
import fileDownload from 'js-file-download';
import { Typography } from '@mui/material';
import { activeOrgSelector } from 'redux/reducer/AuthenticationReducer';
import { useChatDownload } from 'hooks/alertsRequestAPI';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';


const LoadingSkeleton = () => Array.from({ length: 6 }, (_, i) => <Skeleton key={i} sx={{ fontSize: '1.5rem' }} />);

const ChatView = ({ chatID, open, onClose }) => {
  const { crId } = useParams();
  const { ID: orgId } = useSelector(activeOrgSelector);

  const {
    isLoading: isChatDataLoading,
    isError: isChatDataError,
    data: chatData,
    mutate: downloadChat,
  } = useChatDownload(orgId, crId);

  const handleDownloadFile = () => {
    fileDownload(chatData, `chat-${Date.now()}`);
  };

  useEffect(() => {
    if (chatID) {
      downloadChat(chatID);
    }
  }, [chatID, downloadChat]);

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth='md'
      onClose={onClose}
    >
      <DialogTitle>
        Chat Preview
      </DialogTitle>
      <DialogContent>
        {
          (isChatDataLoading || isChatDataError)
            ? <LoadingSkeleton />
            : (
              <Typography component='div' variant='subtitile1' whiteSpace='pre-line' align='justify'>
                {chatData}
              </Typography>
            )
        }
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={onClose}>Close</Button>
        <Button
          variant='contained'
          disabled={isChatDataLoading || isChatDataError}
          onClick={handleDownloadFile}
        >
          Download
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChatView;
