import AdvancedFlowSettingsDialog from 'pages/Flow/AdvancedFlowSettingsDialog';
import FlowRunDialog from 'pages/Flow/FlowRun/FlowRunDialog';
import FlowRunProvider from 'pages/Flow/FlowRun/FlowRunProvider';
import PageHeader from 'components/Common/PageHeader';
import React, { useContext, useEffect } from 'react';
import { CreateFlowDialog } from 'pages/Flow/CreateFlowDialog';
import { FlowCard } from 'pages/Flow/FlowCard';
import { FlowContext } from 'pages/Flow/FlowContextProvider';
import { FlowLandingPage } from 'pages/Flow/FlowLandingPage';
import {
  FlowReportRunButton,
} from 'pages/Flow/FlowCard/ActionButtons';
import { HDataTable, HTableControlPanel } from 'BaseComponents';
import { Toc } from '@mui/icons-material';
import { activeOrgSelector } from 'redux/reducer/AuthenticationReducer';
import { clearFlowStore } from 'redux/actions/FlowAction';
import { flow } from 'utils/urlConstants';
import { getColumns } from 'pages/Flow/getColumns';
import { useActionItems } from 'pages/Flow/FlowCard/useActionItems';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';


export const FlowListViewLayout = () => {
  const dispatch = useDispatch();
  const { crId } = useParams();
  const {
    handleCreateFlow,
    dataContextRef,
  } = useContext(FlowContext);

  useEffect(() => {
    dispatch(clearFlowStore());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { ID: orgId } = useSelector(activeOrgSelector);
  const actionMenu = useActionItems();

  const columns = getColumns();

  const dataURL = flow(orgId).list(crId);
  const filtersURL = flow(orgId).listFilters(crId);

  return (
    <FlowRunProvider>
      <PageHeader title='Flows' Icon={Toc} />
      <HDataTable
        dataURL={dataURL}
        filtersURL={filtersURL}
        tableName='clean-room-flow-list'
        dataBaseField='cleanRoomFlows'
        columns={columns}
        activeQuery={false}
        actionMenu={(row) => actionMenu({ details: row })}
        actionButton={(row) => (
          <FlowReportRunButton flowDetails={row.row} />
        )}
        cardElement={FlowCard}
        zeroState={<FlowLandingPage />}
        controlPanel={(
          <HTableControlPanel
            searchAttribute
            createButton={{
              text: 'New Flow',
              onClick: handleCreateFlow,
            }}
            dataContextRef={dataContextRef}
          />
        )}

      />
      <CreateFlowDialog />
      <FlowRunDialog />
      <AdvancedFlowSettingsDialog />
    </FlowRunProvider>
  );
};
