import * as Trees from 'components/Layouts/Header/NavigationTree';
import CollapseMenu from 'components/Layouts/SideNav/CollapseMenu';
import React, { useState } from 'react';
import liveRampLogoTextPrimary from 'assets/logo/liveRampLogoTextPrimary.svg';
import liveRampLogoTextWhite from 'assets/logo/liveRampLogoTextWhite.svg';
import localStorage from 'utils/localStorage';
import {
  Box, Divider, List, Drawer as MuiDrawer, useTheme,
} from '@mui/material';
import { CACHE } from 'utils/appConstants';
import { LinkComponent } from 'BaseComponents';
import { TrademarkItem } from 'components/Layouts/SideNav/TrademarkItem';
import { homepage } from 'utils/spaUrls';
import { navBarCollapsedWidth, navBarDrawerWidth } from 'Theme';
import { styled } from '@mui/material/styles';


const drawerWidth = navBarDrawerWidth;

const collapsedWidth = navBarCollapsedWidth;

const openedMixin = (theme) => ({
  width: drawerWidth,
  backgroundColor: theme.palette.sideNavigation.backgroundColor,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  backgroundColor: theme.palette.sideNavigation.backgroundColor,
  width: collapsedWidth,
  [theme.breakpoints.up('sm')]: {
    width: collapsedWidth,
  },
});
const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const StyledBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})(({ theme, isActive = false }) => ({
  display: 'flex',
  flexDirection: 'row-reverse',
  justifyContent: 'center',
  paddingTop: '1rem',
  ...(isActive ? {
    '&::before': {
      boxSizing: 'border-box',
      content: '""',
      width: '4px',
      backgroundColor: theme.palette.primary.main,
      position: 'absolute',
      left: 0,
      // top + bottom makes the pseudo-element be full height
      top: 0,
      bottom: 0,
    },
  } : {}),
}));

const LiveRampLogo = ({ isHomepage }) => {
  const liverampLogoSource = isHomepage ? liveRampLogoTextPrimary : liveRampLogoTextWhite;
  return (
    <img src={liverampLogoSource} alt='LiveRamp' />
  );
};

const SideNavBar = (props) => {
  const theme = useTheme();
  const urlPath = props.location?.pathname || '';
  const primaryNavMenu = Trees.getSideNavMenu();
  const bottomNavMenu = Trees.getSideUserMenu();
  const open = true;
  const defaultExpMenuList = JSON.parse(localStorage.get(CACHE.expandedMenuItems)) || {};
  const [expMenuList, setExpMenuList] = useState(defaultExpMenuList);
  const isHomepage = urlPath === homepage;

  return (
    <Drawer variant='permanent' open={open}>
      <List>
        <StyledBox isActive={isHomepage}>
          <LinkComponent to={homepage}>
            <LiveRampLogo isHomepage={isHomepage} />
          </LinkComponent>
        </StyledBox>
      </List>

      <List sx={{ width: '100%', maxWidth: drawerWidth }}>
        {primaryNavMenu.map(item => (
          <CollapseMenu
            list={expMenuList}
            setList={setExpMenuList}
            currPath={urlPath}
            key={item.title}
            menuItem={item}
            isMainSideNav
          />
        ))}
      </List>

      <Divider />

      <List sx={{ width: '100%', maxWidth: drawerWidth, paddingBottom: theme.spacing(5) }}>
        {bottomNavMenu.map(item => (
          <CollapseMenu
            list={expMenuList}
            setList={setExpMenuList}
            currPath={urlPath}
            key={item.title}
            menuItem={item}
            isMainSideNav
          />
        ))}
      </List>

      <TrademarkItem drawerWidth={drawerWidth} />
    </Drawer>
  );
};

export default SideNavBar;
