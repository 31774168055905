import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import PageHeader from 'components/Common/PageHeader';
import ProductAccessTable from 'pages/InternalAdmin/organization/pages/ProductAccess/ProductAccessTable';
import React from 'react';
import { HButton } from 'BaseComponents';
import { StyledGrid } from 'components/IAM/Admin/StyledComponents';
import { crumbs } from 'components/Common/BreadcrumbsNavigation';
import {
  fetchAccountProducts,
  fetchOrganizationProducts,
  submitOrganizationProducts,
} from 'redux/actions/IamActions';
import { useDispatch, useSelector } from 'react-redux';


const List = () => {
  const dispatch = useDispatch();
  const accountID = useSelector(state => state.session.user.primaryAccount.ID);
  const orgID = useSelector(state => state.session.user.primaryOrganization.organization.ID);
  const orgName = useSelector(state => state.session.user.primaryOrganization.organization.name);
  const accountProducts = useSelector(state => state.iam.accountProducts || []);
  const organizationProducts = useSelector(state => state.iam.organizationProducts || []);
  const [orgProducts, updateOrgProducts] = React.useState(organizationProducts);

  React.useEffect(() => {
    dispatch(fetchAccountProducts(accountID));
    dispatch(fetchOrganizationProducts(orgID));
  }, [accountID, dispatch, orgID]);

  React.useEffect(() => {
    if (organizationProducts.length !== 0) {
      updateOrgProducts(organizationProducts);
    }
    else {
      updateOrgProducts(accountProducts);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationProducts, updateOrgProducts]);

  const onSubmit = (o) => {
    const productIDs = o.map(perm => perm.ID);
    dispatch(submitOrganizationProducts(orgID, productIDs));
  };

  return (
    <>
      <PageHeader title={`${orgName}: Manage Product Access`} breadcrumbs={[crumbs.helium, crumbs.internalAdmin]} />
      <Grid container spacing={0} direction='column'>
        <Grid item>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ProductAccessTable
                    accProducts={accountProducts}
                    orgProducts={orgProducts}
                    updateOrgProducts={updateOrgProducts}
                  />
                </Grid>
                <StyledGrid item xs={12}>
                  <HButton
                    color='primary'
                    size='small'
                    variant='contained'
                    onClick={() => onSubmit(orgProducts)}
                  >
                    Update Product Settings
                  </HButton>
                </StyledGrid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default List;
