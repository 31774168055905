import Grid from '@mui/material/Grid';
import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { DISPLAY_Q_INPUT_TYPES } from 'utils/constants/question.constant';
import { habuColors } from 'Theme';
import { tableCellClasses } from '@mui/material';


const QuestionHeading = ({ text = '' }) => (
  <Typography variant='subtitle2' sx={{ color: habuColors.neutral['90'] }}>
    {text}
  </Typography>
);

const QuestionSidePanelDetails = ({ question }) => {
  const isListQuestion = question.isUserListQuestion;
  const iconText = isListQuestion ? 'List Question' : 'Analytical Question';

  return (
    <Grid container spacing={0}>
      <TableContainer>
        <Table
          sx={{
            [`& .${tableCellClasses.root}`]: {
              borderBottom: 'none',
            },
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell component='th' scope='row' />
              <TableCell component='th' scope='row' />
            </TableRow>
          </TableHead>
          <TableRow>
            <TableCell><QuestionHeading text='Question Type' /></TableCell>
            <TableCell><Typography>{iconText}</Typography></TableCell>
          </TableRow>
          <TableBody>
            <TableRow>
              <TableCell><QuestionHeading text='Dataset' /></TableCell>
              <TableCell>
                {question.dataTypes && question.dataTypes.map((dt) => (
                  <Grid item xs={12} key={dt.ID}>
                    <Typography>
                      {`@${dt.macro}(${dt.importDataType.displayName})`}
                    </Typography>
                  </Grid>
                ))}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell><QuestionHeading text='Runtime Parameters' /></TableCell>
              <TableCell>
                {question.parameters && question.parameters.map((param) => (
                  <Grid item xs={12} key={param.displayName}>
                    <Typography>
                      {`${param.displayName} (${DISPLAY_Q_INPUT_TYPES[param.type].label})`}
                    </Typography>
                  </Grid>
                ))}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell><QuestionHeading text='Output' /></TableCell>
              <TableCell>
                {isListQuestion ? (
                  <>
                    {question.userListOutputs && question.userListOutputs.map((metric) => (
                      <Grid item xs={12} key={metric.ID}>
                        <Typography>
                          {`${metric.name} (${DISPLAY_Q_INPUT_TYPES[metric.type].label})`}
                        </Typography>
                      </Grid>
                    ))}
                  </>
                ) : (
                  <>
                    {question.dimensions && question.dimensions.map((dimension) => (
                      <Grid item xs={12} key={dimension.ID}>
                        <Typography>
                          {`${dimension.displayName} (${DISPLAY_Q_INPUT_TYPES[dimension.type].label})`}
                        </Typography>
                      </Grid>
                    ))}
                    {question.metrics && question.metrics.map((metric) => (
                      <Grid item xs={12} key={metric.ID}>
                        <Typography>
                          {`${metric.displayName} (${DISPLAY_Q_INPUT_TYPES[metric.type].label})`}
                        </Typography>
                      </Grid>
                    ))}
                  </>
                ) }
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

export default QuestionSidePanelDetails;
