import React, { memo } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';


export const QuestionIdsCell = memo(({ row }) => {
  const content = row.cleanRoomQuestionDisplayIDs.join(', ');
  return (
    <Tooltip title={content}>
      <Typography component='span'>{content}</Typography>
    </Tooltip>
  );
});

QuestionIdsCell.displayName = 'QuestionIdsCell';
