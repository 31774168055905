import { activeOrgSelector } from 'redux/reducer/AuthenticationReducer';
import { axiosInstance } from 'utils/http';
import { organizationIdentity } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation, useQueryClient } from 'react-query';


export const useAddIdentity = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { ID: orgId } = useSelector(activeOrgSelector);

  return useMutation(
    (payload) => axiosInstance.post(organizationIdentity(orgId).add, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [orgId, 'identities'] });
        dispatch(showAlert({ message: 'Identity added successfully!', type: 'success' }));
      },
      onError: (err) => {
        const errorMessage = err.response?.data?.message || 'Failed to add identity';
        dispatch(showAlert({ message: errorMessage, type: 'error' }));
      },
    },
  );
};
