import React, {
  memo,
  useContext,
  useMemo,
} from 'react';
import SortIcon from '@mui/icons-material/Sort';
import { DataContext } from 'BaseComponents/HDataTable/DataContextProvider';
import { SORT_DIRECTION } from 'utils/appConstants';
import { TableSortLabel } from '@mui/material';
import { getDirection } from 'BaseComponents/HDataTable/utils';


export const HTableSortLabel = memo(({
  field,
  children,
}) => {
  const {
    filtersOptions,
    filters,
    handleOnClickSort,
  } = useContext(DataContext);

  const sorts = filtersOptions?.sorts;

  const activeSort = useMemo(() => (filters.sortBy.attribute), [filters.sortBy]);
  const sortDirection = useMemo(() => (filters.sortBy.direction), [filters.sortBy]);

  const handleOnClick = () => {
    const newDirection = sortDirection !== SORT_DIRECTION.ASCENDING
      ? SORT_DIRECTION.ASCENDING : SORT_DIRECTION.DESCENDING;

    handleOnClickSort(field, newDirection);
  };

  const isSortable = useMemo(() => sorts?.find(({ attribute }) => (
    [attribute, attribute.toLowerCase()].includes(field))),
  [field, sorts]);

  if (!isSortable) {
    return (
      <span>{children}</span>
    );
  }

  return (
    <TableSortLabel
      data-testid='table-sortable-label'
      active={activeSort === field}
      direction={getDirection(sortDirection)}
      onClick={handleOnClick}
    >
      {children}
      <SortIcon fontSize='small' sx={{ ml: 1 }} />
    </TableSortLabel>
  );
});

HTableSortLabel.displayName = 'HTableSortLabel';
