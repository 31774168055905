import { ALERTS } from 'redux/actions/Types';


export const showAlert = ({ message, type }) => ({
  type: ALERTS.SHOW_ALERT,
  payload: {
    message,
    type,
  },
});

export const hideAlert = id => ({
  type: ALERTS.HIDE_ALERT,
  payload: { id },
});
