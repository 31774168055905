export const LOOKUPS = {
  FETCH_CONTENT_VERTICALS: 'FETCH_CONTENT_VERTICALS',
  RECEIVE_CONTENT_VERTICALS: 'RECEIVE_CONTENT_VERTICALS',

  FETCH_CONTENT_CATEGORIES: 'FETCH_CONTENT_CATEGORIES',
  RECEIVE_CONTENT_CATEGORIES: 'RECEIVE_CONTENT_CATEGORIES',

  FETCH_DOMAINS: 'FETCH_DOMAINS',
  RECEIVE_DOMAINS: 'RECEIVE_DOMAINS',

  FETCH_PERMISSIONS: 'FETCH_PERMISSIONS',
  RECEIVE_PERMISSIONS: 'RECEIVE_PERMISSIONS',

  FETCH_QUESTION_TYPES: 'FETCH_QUESTION_TYPES',
  RECEIVE_QUESTION_TYPES: 'RECEIVE_QUESTION_TYPES',

  FETCH_PROPERTY_TYPES: 'FETCH_PROPERTY_TYPES',
  FETCH_PROPERTY_TYPES_SUCCESS: 'FETCH_PROPERTY_TYPES_SUCCESS',
  FETCH_PROPERTY_TYPES_FAILURE: 'FETCH_PROPERTY_TYPES_FAILURE',
};
