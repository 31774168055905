import { CLEAN_ROOM_ADMIN } from 'redux/actions/Types';


const cleanRoomAppAdminReducer = (state = {}, action) => {
  switch (action.type) {
    case CLEAN_ROOM_ADMIN.FETCH_ALL_IMPORT_DATA_TYPES:
      return { ...state, dataTypes: undefined };
    case CLEAN_ROOM_ADMIN.FETCH_ALL_IMPORT_DATA_TYPES_SUCCESS:
      return { ...state, dataTypes: action.payload };
    case CLEAN_ROOM_ADMIN.FETCH_ALL_IMPORT_DATA_TYPES_ERROR:
      return { ...state, dataTypes: { error: action.payload } };
    case CLEAN_ROOM_ADMIN.FETCH_ALL_STORAGE_PROVIDERS:
      return { ...state, storageProviders: undefined };
    case CLEAN_ROOM_ADMIN.FETCH_ALL_STORAGE_PROVIDERS_SUCCESS:
      return { ...state, storageProviders: action.payload };
    case CLEAN_ROOM_ADMIN.FETCH_ALL_STORAGE_PROVIDERS_ERROR:
      return { ...state, storageProviders: { error: action.payload } };
    case CLEAN_ROOM_ADMIN.FETCH_QUESTION:
      return { ...state, question: undefined };
    case CLEAN_ROOM_ADMIN.FETCH_QUESTION_SUCCESS:
      return { ...state, question: action.payload };
    case CLEAN_ROOM_ADMIN.FETCH_QUESTION_ERROR:
      return { ...state, question: { error: action.payload } };
    case CLEAN_ROOM_ADMIN.CLEAR_CR_QUESTION:
    case CLEAN_ROOM_ADMIN.CREATE_CR_QUESTION:
    case CLEAN_ROOM_ADMIN.UPDATE_CR_QUESTION:
      return { ...state, question: undefined };
    case CLEAN_ROOM_ADMIN.CREATE_CR_QUESTION_SUCCESS:
    case CLEAN_ROOM_ADMIN.UPDATE_CR_QUESTION_SUCCESS:
      return { ...state, question: { ...action.payload, created: true } };
    case CLEAN_ROOM_ADMIN.CREATE_CR_QUESTION_ERROR:
    case CLEAN_ROOM_ADMIN.UPDATE_CR_QUESTION_ERROR:
      return { ...state, question: { error: action.payload } };
    case CLEAN_ROOM_ADMIN.FETCH_ALL_QUESTIONS:
      return { ...state, allCRQuestions: undefined };
    case CLEAN_ROOM_ADMIN.FETCH_ALL_QUESTIONS_ERROR:
      return { ...state, allCRQuestions: { error: action.payload } };
    case CLEAN_ROOM_ADMIN.FETCH_ALL_QUESTIONS_SUCCESS:
      return { ...state, allCRQuestions: action.payload };
    default:
      return state;
  }
};

export default cleanRoomAppAdminReducer;
