import * as spaUrls from 'utils/spaUrls';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import PlaceIcon from '@mui/icons-material/Place';
import React from 'react';
import Typography from '@mui/material/Typography';
import { Link as DomLink } from 'react-router-dom';
import { PRODUCT } from 'utils/appConstants';
import { organizationManagement } from 'utils/spaUrls';
import { styled } from '@mui/material/styles';


const crumbs = {
  alerts: crId => ({ label: 'Alerts', href: spaUrls.internalAdmin.organization.alerts(crId) }),
  alertNotifications: (crId) => ({ label: 'Alert Notifications', href: spaUrls.cleanRoom.alertNotifications(crId) }),
  trustCenter: { label: 'Trust Center', href: spaUrls.trustCenter.landing },
  accountAdmin: { label: 'Account Admin', href: spaUrls.iam.accountAdmin },
  activationChannels: { label: 'Activation Channels', href: spaUrls.activationChannels.landing },
  channelName: name => ({ label: name, href: spaUrls.activationChannels.detail(name) }),
  cleanRooms: { label: 'Clean Rooms', href: spaUrls.cleanRoom.landing },
  orgCredentials: { label: 'Credentials', href: spaUrls.organizationManagement.organization.credentials.list },
  orgUserList: { label: 'Organization Users', href: spaUrls.organizationManagement.organization.users.list },
  crQuestions: crId => ({ label: 'Questions', href: spaUrls.cleanRoom.question(crId) }),
  crFlow: crId => ({ label: 'Flow', href: spaUrls.cleanRoom.flowLanding(crId) }),
  crFlowRunList: (crId, crFlowId) => ({ label: 'Flow list', href: `/clean-rooms/details/${crId}/flow/${crFlowId}/run/list` }),
  crUserManagement: crId => ({ label: 'Users', href: spaUrls.cleanRoom.userManagement(crId) }),
  crPartnerManagement: crId => ({ label: 'Partner', href: spaUrls.cleanRoom.partnerInvitations(crId) }),
  crAllQuestions: crId => ({ label: 'All Questions', href: spaUrls.cleanRoom.allQuestions(crId) }),
  crDatasets: crId => ({ label: 'Datasets', href: spaUrls.cleanRoom.manageDatasets(crId) }),
  crUserList: crId => ({ label: 'User Lists', href: spaUrls.crUserList.list(crId) }),
  crPartners: crId => ({ label: 'Partners', href: spaUrls.cleanRoom.partnerInvitations(crId) }),
  crPartnersAndUsers: crId => ({ label: 'Partners & Users', href: spaUrls.cleanRoom.partnersAndUsers(crId) }),
  crUserLists: crId => ({ label: 'User Lists', href: spaUrls.crUserList.list(crId) }),
  dataIn: { label: `${PRODUCT.DATA_CONNECTION.name}`, href: spaUrls.dataIn.landing },
  dataOut: { label: 'Data Exports', href: spaUrls.dataOut.userListsActivation },
  offlineConversions: { label: 'Data Exports', href: spaUrls.dataOut.offlineConversions },
  experiment: { label: 'Experiment', href: spaUrls.experiments.landing },
  helium: { label: 'Home', href: spaUrls.helium.primaryLanding },
  internalAdmin: { label: 'Internal Administration', href: spaUrls.internalAdmin.landing },
  crInvitations: { label: 'Invitations', href: spaUrls.cleanRoom.inboundInvitations },
  home: { label: 'Home', href: spaUrls.homepage },
  flow: { label: 'Flow', href: spaUrls.flow.landing },
  manageAccounts: { label: 'Manage Accounts', href: spaUrls.iam.accountList },
  manageOrgs: { label: 'Manage Organizations', href: spaUrls.iam.organizationList },
  userLists: { label: 'User Lists', href: spaUrls.userList.list },
  questionManagement: { label: 'Question Management', href: spaUrls.questionManagement.orgQuestions('available-questions') },
  qmOrgList: { label: 'Organization', href: spaUrls.questionManagement.orgQuestions('available-questions') },
  qmAccList: { label: 'Platform', href: spaUrls.questionManagement.orgQuestions('platform') },
  runList: (crId, questionId, crQId) => ({
    label: 'Question Runs',
    href: spaUrls.cleanRoom.runList(crId, questionId, crQId),
  }),
  orgRoleList: { label: 'Organization Roles', href: organizationManagement.organization.roles.list },
  orgIdentity: { label: 'Identity', href: organizationManagement.organization.identity.list },
};

const heliumCrumbs = {
  home: { label: 'Home', icon: <PlaceIcon fontSize='small' />, href: spaUrls.helium.primaryLanding },
  cleanRooms: { label: 'Clean Rooms', icon: <PlaceIcon fontSize='small' />, href: spaUrls.helium.cleanRoom.landing },
  joinedDatasets: { label: 'Joined Datasets', icon: <PlaceIcon fontSize='small' />, href: spaUrls.helium.cleanRoom.joinedDataSet.landing },
  manageDatasets: crId => ({ label: 'Manage Datasets', icon: <PlaceIcon fontSize='small' />, href: spaUrls.helium.cleanRoom.manageDatasets(crId) }),
};

const CurrentPage = styled(Typography)(({ theme }) => ({
  fontSize: '0.85rem',
  color: theme.palette.link.main,
}));

const BreadcrumbsNavigation = ({ items }) => (
  <Breadcrumbs separator='/'>
    {items.map((item, index) => {
      if (index < items.length - 1 || items.length === 1) {
        return (
          <Link
            underline='none'
            key={item.label}
            to={item.href}
            component={DomLink}
            data-testid={`${item.label}-bread-crumb`}
          >
            {item.label}
          </Link>
        );
      }
      return (
        <CurrentPage key={item.label}>{item.label}</CurrentPage>
      );
    })}
  </Breadcrumbs>
);

export { BreadcrumbsNavigation, crumbs, heliumCrumbs };
