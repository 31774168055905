import Button from '@mui/material/Button';
import React from 'react';
import { MenuDropdown } from 'BaseComponents';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';


const menuLink = {
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '16px',
  paddingRight: '16px',
  color: 'white',
  borderRadius: 0,
};

const StyledButton = styled(Button)(({ theme, active }) => (
  {
    ...menuLink,
    backgroundColor: active ? theme.palette.header.darkBackground : theme.palette.header.background,
    '&:hover': {
      backgroundColor: active ? theme.palette.header.darkBackground
        : theme.palette.header.lightBackground,
    },
  }
));

const HeaderMenu = ({ title, items = [] }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  return (
    <>
      <StyledButton
        active={open}
        onClick={e => setAnchorEl(e.currentTarget)}
      >
        <Typography variant='h6'>{title}</Typography>
      </StyledButton>
      <MenuDropdown
        handleClose={() => setAnchorEl(null)}
        anchor={anchorEl}
        open={open}
        items={items}
      />
    </>
  );
};

export default HeaderMenu;
