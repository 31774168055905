import { activeOrgSelector } from 'redux/reducer/AuthenticationReducer';
import { axiosInstance } from 'utils/http';
import { cleanRoom } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'react-query';


export const usePostPrivacyBudgetOverride = (crID, cleanRoomFlowID, flowName) => {
  const dispatch = useDispatch();
  const { ID: orgID } = useSelector(activeOrgSelector);

  return useMutation(
    (payload) => axiosInstance.post(
      cleanRoom(orgID).privacyBudgetOverride(crID, cleanRoomFlowID), payload),
    {
      onSuccess: () => {
        dispatch(showAlert({
          message: `Privacy parameters updated for all questions in ${flowName}`,
          type: 'success',
        }));
      },
      onError: () => {
        dispatch(showAlert({
          message: 'Error updating privacy parameters',
          type: 'error',
        }));
      },
    },
  );
};

