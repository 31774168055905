export const HighlightTypes = {
  userIdentifier: 'userIdentifier',
  pageAttribute: 'pageAttribute',
  userAttribute: 'userAttribute',
};

export const QuarterdeckConstants = {
  accelerator: 'data',
  collectPageViews: true,
  consent: [
    { name: 'None', value: {} },
    { name: 'Client Implements IAB', value: { iab: true } },
    { name: 'Client Implements Switchbit', value: { swb: true } },
    { name: 'Controller Consent with Switchbit', value: { controllerSwb: true } },
  ],
  partners: ['adh_asics', 'amazon_us', 'liveramp', 'throtle'],
  version: [
    { name: 'Steady', value: 'steady' },
    { name: 'Deliberate', value: 'deliberate' },
    { name: 'Prompt', value: 'prompt' },
  ],
};

export const mapPropertiesToConfigurations = (properties, configurations) => {
  if (!properties || !properties.length) {
    return [];
  }
  if (!configurations || !configurations.length) {
    return properties;
  }
  return properties.map((property) => {
    const configuration = configurations.find(c => c.propertyID === property.ID);
    return configuration ? { ...property, configuration } : property;
  });
};
