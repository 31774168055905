import PermissionService from 'components/Common/PermissionService';


const Bouncer = ({
  allowList = [], blockList = [], fallback = () => null, children,
}) => {
  if (PermissionService.isAuthorized(allowList, blockList)) {
    return { ...children };
  }
  else { return fallback(); }
};

export default Bouncer;
