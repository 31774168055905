import React, {
  memo, useCallback, useContext, useState,
} from 'react';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { AlertsContext } from 'pages/InternalAdmin/organization/pages/Alerts/AlertsListLayout';
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';


export const RecipientsCell = memo(({ row }) => {
  const [emailList, setEmailList] = useState(row.emailID ? row.emailID.split(',') : []);

  const { editRow, users, updateRecipients } = useContext(AlertsContext);

  const onRecipientChange = useCallback((event) => {
    const { target: { value } } = event;
    setEmailList(
      // On autofill, we get a stringifies value.
      typeof value === 'string' ? value.split(',') : value,
    );
  }, []);

  const handleUpdateRecipients = useCallback(() => {
    const payload = { alertDetails: { ...editRow, emailID: emailList.join(',') } };
    updateRecipients(row, payload);
  }, [editRow, emailList, row, updateRecipients]);

  return (
    editRow.alertID === row.alertID
      ? (
        <FormControl size='small' fullWidth>
          <InputLabel id='email-multiple-checkbox-label'>Recipients</InputLabel>
          <Select
            labelId='email-multiple-checkbox-label'
            id='email-multiple-checkbox'
            multiple
            value={emailList}
            onChange={onRecipientChange}
            input={<OutlinedInput label='Recipients' />}
            renderValue={(selected) => selected.join(', ')}
            onBlur={handleUpdateRecipients}
          >
            {users.map((user) => (
              <MenuItem key={user.ID} value={user.email}>
                <Checkbox checked={emailList.indexOf(user.email) > -1} />
                <ListItemText primary={`${user.name} (${user.email})`} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )
      : (
        <Tooltip title={row.emailID.split(',').join(', ')}>
          <Typography component='span'>{row.emailID.split(',').join(', ')}</Typography>
        </Tooltip>
      )
  );
});

RecipientsCell.displayName = 'RecipientsCell';
