import * as Enums from 'utils/ignoramusEnums';
import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import moment from 'moment';
import { HButton } from 'BaseComponents';
import { TableCell } from '@mui/material';
import { US_DATE_FORMAT, cardsPerPageOptions } from 'utils/appConstants';


const SyntheticDataTable = ({ list, openModal }) => {
  const [rowsPerPage, setRowsPerPage] = React.useState(cardsPerPageOptions[0]);
  const [page, setPage] = React.useState(0);
  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Stage</TableCell>
              <TableCell>Data Type</TableCell>
              <TableCell>Identifier Type</TableCell>
              <TableCell>Date Created</TableCell>
              <TableCell>Last Updated At</TableCell>
              <TableCell>Destinations</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(row => (
                <TableRow hover key={row.ID}>
                  <TableCell>{Enums.stage[row.stage].label}</TableCell>
                  <TableCell>{row.dataType.displayName}</TableCell>
                  <TableCell>{row.identifierType}</TableCell>
                  <TableCell>{moment(row.timeAudit.createdAt).format(US_DATE_FORMAT)}</TableCell>
                  <TableCell>{moment(row.timeAudit.updatedAt).format(US_DATE_FORMAT)}</TableCell>
                  <TableCell>
                    {row.stage === Enums.stage.COMPUTED.key ? (
                      <HButton
                        color='primary'
                        onClick={() => openModal(row.destinations)}
                        variant='contained'
                        disabled={row.destinations.length === 0}
                      >
                        View Destinations
                      </HButton>
                    ) : null}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={cardsPerPageOptions}
        component='div'
        count={list.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{ 'aria-label': 'Previous Page' }}
        nextIconButtonProps={{ 'aria-label': 'Next Page' }}
        onPageChange={(e, newPage) => setPage(newPage)}
        onRowsPerPageChange={e => setRowsPerPage(e.target.value)}
      />
    </>
  );
};

export default SyntheticDataTable;
