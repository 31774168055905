import React, { useMemo } from 'react';
import TablePagination from '@mui/material/TablePagination';
import { ITEM_PER_PAGE_OPTIONS } from 'utils/appConstants';
import { useItemsPerPage } from 'BaseComponents/HDataTable/utils';


export const HTablePagination = ({ onChange, filters, ...props }) => {
  const {
    itemsPerPage,
    setItemsPerPage,
  } = useItemsPerPage();
  const pagePosition = useMemo(() => filters.offset / itemsPerPage, [filters.offset, itemsPerPage]);

  const handleChangePage = (event, newPage) => {
    const newFilters = {
      ...filters,
      limit: itemsPerPage,
      offset: itemsPerPage * newPage,
    };
    onChange(newFilters);
  };

  const handleChangeRowsPerPage = (event) => {
    const itemPerPage = parseInt(event.target.value, 10);
    setItemsPerPage(itemPerPage);
    const newFilters = {
      ...filters,
      limit: itemPerPage,
      offset: 0,
    };
    onChange(newFilters);
  };

  return (
    <TablePagination
      component='div'
      rowsPerPageOptions={ITEM_PER_PAGE_OPTIONS}
      rowsPerPage={itemsPerPage}
      page={pagePosition}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      backIconButtonProps={{ 'aria-label': 'Previous Page' }}
      nextIconButtonProps={{ 'aria-label': 'Next Page' }}
      {...props}
    />
  );
};
