import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import Chip from '@mui/material/Chip';
import MacroAssignmentAutoComplete from 'pages/Flow/FlowMacro/MacroAssignmentTab/MacroAssignmentAutoComplete';
import React from 'react';
import TableCell from '@mui/material/TableCell';
import { habuColors } from 'Theme';


const MacroChip = ({ flowMacroName }) => (
  <Chip
    icon={<AlternateEmailIcon fontSize='small' style={{ marginRight: '8px' }} />}
    label={flowMacroName}
  />
);

const MacroMappingAssignmentWrapper = ({ nodeID, runParameter }) => {
  const [showTextField, setShowTextField] = React.useState(false);
  const onCloseTextField = () => setShowTextField(false);
  const showMacro = runParameter?.macro && Object.keys(runParameter.macro).length > 0;

  if (!showTextField) {
    return (
      <TableCell
        onClick={() => setShowTextField(true)}
        sx={{
          fontWeight: 'bold',
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: habuColors.secondary[10],
          },
        }}
      >
        {showMacro
          ? <MacroChip flowMacroName={runParameter.macro.name} />
          : '+ Value'}
      </TableCell>
    );
  }

  return (
    <TableCell>
      <MacroAssignmentAutoComplete
        nodeID={nodeID}
        runParameter={runParameter}
        onCloseTextField={onCloseTextField}
      />
    </TableCell>
  );
};

export default MacroMappingAssignmentWrapper;
