import AddNewSectionButton from 'components/InternalAdmin/QuestionConfigure/AddNewSectionButton';
import Box from '@mui/material/Box';
import DeleteIcon from '@mui/icons-material/Delete';
import Grid from '@mui/material/Grid';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import IconButton from '@mui/material/IconButton';
import Logger from 'components/Common/Logger';
import React from 'react';
import SectionHeader from 'components/IAM/Admin/SectionHeader';
import orderBy from 'lodash/orderBy';
import { CrQuestionPropertiesContext } from 'pages/InternalAdmin/application/pages/CleanRoomQuestion/CrQuestionFormBuilder';
import { InputTypesMap } from 'components/CleanRooms/constants';
import {
  QuestionBooleanField, QuestionSelectField, QuestionTextField,
} from 'components/InternalAdmin/QuestionConfigure/QuestionRenderer';
import { Tooltip } from '@mui/material';
import { v4 as uuidGen } from 'uuid';


export const START_DATE_PARAMETER = {
  name: 'START_DATE',
  displayName: 'Start Date',
  type: InputTypesMap.DATE.key,
  index: -2,
};

export const END_DATE_PARAMETER = {
  name: 'END_DATE',
  displayName: 'End Date',
  type: InputTypesMap.DATE.key,
  index: -1,
};

/**
 * Check if the start and end date parameters are added.
 * @param {[Object]} currentConfig - An array of parameters to be checked
 * @returns {boolean} True if it contains both start and end date parameters
 */
export const isDateParametersAdded = (currentParams) => {
  if (!currentParams || currentParams.length < 2) {
    return false;
  }
  let startDateParamExist = false;
  let endDateParamExist = false;
  currentParams.forEach((param) => {
    if (param.index === -2) {
      startDateParamExist = true;
    }
    if (param.index === -1) {
      endDateParamExist = true;
    }
  });
  return startDateParamExist && endDateParamExist;
};

const inputTypes = Object.values(InputTypesMap)
  .map(i => ({ name: i.label, value: i.key }));

/**
 * QuestionParameters component
 * @param {[Object]} parameters - An array of run time parameters of clean room question
 * @param {Object} onPropertyUpdate - The callback function to update the parameters property
 * @returns {void}
 */
const QuestionParameters = () => {
  const {
    hasDateParameters, parameters, onPropertyUpdate,
  } = React.useContext(CrQuestionPropertiesContext);

  const initializeParameters = (hasDateParams, currentParams) => {
    let params = currentParams.length > 0 ? currentParams : [{ ID: uuidGen(), index: 0 }];
    if (isDateParametersAdded(currentParams) && hasDateParams) {
      return params;
    }
    if (hasDateParams) {
      params = [
        {
          ...START_DATE_PARAMETER,
        },
        {
          ...END_DATE_PARAMETER,
        },
        ...params,
      ];
    }
    return params;
  };
  const initParameters = initializeParameters(hasDateParameters, parameters);

  const [config, setConfig] = React.useState(orderBy(initParameters, ['index'], ['asc']));
  const [enabledDateParameters, setEnabledDateParameters] = React.useState(hasDateParameters);

  /**
   * Event handler when user toggle the enable duration parameter option.
   * It adds or removes the start and end date parameters based on the option status,
   * and invoke the onPropertyUpdate callback function.
   * @param {boolean} enabled - The status of the input option.
   * @returns {void}
   */
  const toggleDurationParameters = React.useCallback((enabled) => {
    let newConfig;
    if (enabled) {
      if (enabledDateParameters) {
        Logger.error('Date parameters are already enabled.');
        return;
      }
      newConfig = [
        {
          ...START_DATE_PARAMETER,
        },
        {
          ...END_DATE_PARAMETER,
        },
        ...config,
      ];
    }
    else {
      if (!enabledDateParameters) {
        Logger.error('Date Parameters are already disabled.');
        return;
      }
      newConfig = config.slice(2);
    }
    setEnabledDateParameters(enabled);
    setConfig(newConfig);
    onPropertyUpdate('hasDateParameters', enabled);
  }, [config, enabledDateParameters, onPropertyUpdate, setConfig, setEnabledDateParameters]);

  /**
   * Event handler to update the name or display name or type of the parameter when user edit it.
   * @param {string} value - The value of name, display name or type of the parameter
   * @param {number} index - The index of the updated parameter
   * @param {string} fieldName - The field name. It can be 'name', 'displayName' or 'type'
   */
  const updateParameter = (value, index, fieldName) => {
    const newConfig = [...config];
    newConfig[index][fieldName] = value;
    setConfig(newConfig);
    onPropertyUpdate('parameters', newConfig);
  };

  /**
   * Event handler when user add a new run time parameter
   * @returns {void}
   */
  const onAddItem = React.useCallback(() => {
    const length = enabledDateParameters ? config.length - 2 : config.length;
    setConfig(prevState => ([
      ...prevState,
      { ID: uuidGen(), index: length },
    ]));
  }, [config, enabledDateParameters, setConfig]);

  /**
   * Event handler when user delete a run time parameter
   * @returns {void}
   */
  const onDeleteItem = React.useCallback((i) => {
    const offset = enabledDateParameters ? 2 : 0;
    const newConfig = [
      ...config.slice(0, i),
      ...config.slice(i + 1),
    ].map((item, newIndex) => ({ ...item, index: newIndex - offset }));
    setConfig(newConfig);
    onPropertyUpdate('parameters', newConfig);
  }, [config, enabledDateParameters, onPropertyUpdate, setConfig]);

  return (
    <>
      <Grid
        container
        alignItems='flex-start'
        data-testid='question-parameter-container'
      >
        <Grid item>
          <SectionHeader title='Run Time Parameters' />
        </Grid>
        <Grid item>
          <Tooltip title='Controls available to user on Report Creation'>
            <HelpOutlineIcon fontSize='small' style={{ marginLeft: '5px' }} />
          </Tooltip>
        </Grid>
        <Grid item>
          <Box pl={2}>
            <QuestionBooleanField
              value={enabledDateParameters}
              fieldLabel='Enable Date Parameters'
              onChange={value => toggleDurationParameters(value)}
            />
          </Box>
        </Grid>
        {config.map((item, index) => (
          <Grid
            container
            spacing={2}
            key={`parameters--${item.ID}-${String(index)}`}
            data-testid={`question-parameter--${String(index)}`}
          >
            <Grid item xs={4} md={3} lg={2}>
              <QuestionTextField
                disabled={item.index < 0}
                fieldLabel='Parameter Name'
                onChange={() => {}}
                onBlur={event => updateParameter(event.target.value, index, 'name')}
                value={item.name}
              />
            </Grid>
            <Grid item xs={4} md={3} lg={2}>
              <QuestionTextField
                disabled={item.index < 0}
                fieldLabel='Display Name'
                onChange={() => {}}
                onBlur={event => updateParameter(event.target.value, index, 'displayName')}
                value={item.displayName}
              />
            </Grid>
            <Grid item xs={4} md={3} lg={2}>
              <QuestionSelectField
                disabled={item.index < 0}
                fieldLabel='Input Type'
                list={inputTypes}
                onChange={event => updateParameter(event, index, 'type')}
                value={item.type}
              />
            </Grid>
            {item.index >= 0 && (
            <Grid item>
              <IconButton aria-label='delete' onClick={() => onDeleteItem(index)} size='large'>
                <DeleteIcon />
              </IconButton>
            </Grid>
            )}
          </Grid>
        ))}
        <AddNewSectionButton list={config} onClick={onAddItem} />
      </Grid>
    </>
  );
};

export default QuestionParameters;
