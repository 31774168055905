import React from 'react';
import { axiosInstance } from 'utils/http';
import {
  customization, iamOrganizations, iamRoles, iamUsers,
} from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation, useQuery, useQueryClient } from 'react-query';


const useFetchOrganization = (accountID, orgId) => {
  const dispatch = useDispatch();
  const [data, setData] = React.useState({});
  const {
    isLoading, isError, isSuccess, refetch, isFetching,
  } = useQuery(
    ['Organization', accountID, orgId],
    async () => axiosInstance.get(
      iamOrganizations(accountID).get(orgId),
    ),
    {
      onSuccess: res => setData(res.data.organization),
      onError: err => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
      enabled: false,
    },
  );

  return {
    refetch,
    isLoading,
    isError,
    data,
    isSuccess,
    isFetching,
  };
};

const useFetchOrganizations = accountID => {
  const dispatch = useDispatch();
  const [data, setData] = React.useState({});
  const {
    isLoading, isError, isSuccess, refetch,
  } = useQuery(
    ['Organizations'],
    async () => axiosInstance.get(
      iamOrganizations(accountID).listOrganizations,
    ),
    {
      onSuccess: res => {
        setData(res.data.organizations.reduce((obj, cur) => (
          { ...obj, [cur.organization.ID]: cur.organization }), {}));
      },
      onError: err => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
      enabled: !!accountID,
    },
  );

  return {
    refetch,
    isLoading,
    isError,
    data,
    isSuccess,
  };
};

const useDeleteOrganization = () => {
  const dispatch = useDispatch();
  const {
    isLoading, isError, isSuccess, mutate, reset,
  } = useMutation(
    async ({ selectedOrgId }) => axiosInstance.delete(iamOrganizations().delete(selectedOrgId)),
    {
      onSuccess: () => dispatch(showAlert({ message: 'Organization successfully deleted', type: 'success' })),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    mutate,
    reset,
  };
};

const useDeleteOrganizationUser = () => {
  const dispatch = useDispatch();
  const {
    isLoading, isError, isSuccess, mutate, reset, mutateAsync,
  } = useMutation(
    async ({ organizationID, userID }) => axiosInstance.delete(
      iamUsers(organizationID).deleteOrgUser(userID),
    ),
    {
      onSuccess: () => dispatch(showAlert({ message: 'Organization user successfully deleted', type: 'success' })),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    mutate,
    reset,
    mutateAsync,
  };
};

const useDeleteOrganizationRole = () => {
  const dispatch = useDispatch();
  const {
    isLoading, isError, isSuccess, mutateAsync, reset,
  } = useMutation(
    async ({ organizationID, roleID }) => axiosInstance.delete(
      iamRoles(organizationID).delete(roleID),
    ),
    {
      onSuccess: () => dispatch(showAlert({ message: 'Organization role successfully deleted', type: 'success' })),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    mutateAsync,
    reset,
  };
};

const useCreateCustomization = () => {
  const dispatch = useDispatch();
  const orgId = useSelector(state => state.session.user.primaryOrganization.organization.ID);
  const [data, setData] = React.useState([]);
  const {
    isLoading, isError, isSuccess, mutate,
  } = useMutation(
    async ({ payload }) => axiosInstance.post(customization(orgId).create, payload),
    {
      onSuccess: (res) => setData(res.data),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    data,
    mutate,
  };
};

const useFetchCustomization = (organizationID) => {
  const dispatch = useDispatch();
  const currentOrgID = useSelector(state => state.session.user.primaryOrganization.organization.ID);
  const orgID = organizationID || currentOrgID;
  const {
    data, isError, isSuccess,
  } = useQuery(
    ['OrganizationCustomization', orgID],
    async () => axiosInstance.get(customization(orgID).list),
    {
      enabled: !!orgID,
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isError,
    isSuccess,
    data: data?.data?.parameters,
  };
};

const useUploadLogo = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const orgID = useSelector(state => state.session.user.primaryOrganization.organization.ID);
  const {
    isLoading, isError, isSuccess, data, mutate,
  } = useMutation(
    async (files) => {
      const form = new FormData();
      form.append('file', files[0]);
      axiosInstance.post(customization().uploadLogo, form);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['OrganizationCustomization', orgID]);
      },
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    data,
    mutate,
  };
};

export {
  useFetchOrganization,
  useFetchOrganizations,
  useDeleteOrganization,
  useDeleteOrganizationUser,
  useCreateCustomization,
  useFetchCustomization,
  useUploadLogo,
  useDeleteOrganizationRole,
};
