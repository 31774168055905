import { DATA_CREDENTIAL } from 'redux/actions/Types';
import { dataCredential } from 'utils/urlConstants';


export const createDataKeys = (credentialID, orgId) => ({
  type: DATA_CREDENTIAL.CREATE_DATA_CREDENTIAL_KEYS,
  url: dataCredential(orgId).generateSecret(credentialID),
});

export const generateDataCredentialKeysSuccess = data => ({
  type: DATA_CREDENTIAL.GENERATE_DATA_CREDENTIAL_KEYS_SUCCESS,
  payload: data,
});

export const generateDataCredentialKeysError = data => ({
  type: DATA_CREDENTIAL.GENERATE_DATA_CREDENTIAL_KEYS_ERROR,
  payload: data,
});

export const fetchOrgDataCredentialList = (orgId, filter) => ({
  type: DATA_CREDENTIAL.FETCH_ORG_DATA_CREDENTIAL_LIST,
  url: dataCredential(orgId).listOrgCredentials(filter),
});

export const receiveOrgDataCredentialListSuccess = payload => ({
  type: DATA_CREDENTIAL.RECEIVE_ORG_DATA_CREDENTIAL_LIST_SUCCESS,
  payload,
});

export const receiveOrgDataCredentialListError = payload => ({
  type: DATA_CREDENTIAL.RECEIVE_ORG_DATA_CREDENTIAL_LIST_ERROR,
  payload,
});

export const fetchOrgCredential = (orgId, orgCredId) => ({
  type: DATA_CREDENTIAL.FETCH_ORG_CREDENTIAL,
  url: dataCredential(orgId).getOrgCredential(orgCredId),
});

export const receiveOrgCredentialSuccess = payload => ({
  type: DATA_CREDENTIAL.RECEIVE_ORG_CREDENTIAL_SUCCESS,
  payload,
});

export const receiveOrgCredentialError = payload => ({
  type: DATA_CREDENTIAL.RECEIVE_ORG_CREDENTIAL_ERROR,
  payload,
});

export const createOrgCredentialSource = (data, orgId, orgCredId) => ({
  type: orgCredId ? DATA_CREDENTIAL.UPDATE_ORG_CREDENTIAL_SOURCE
    : DATA_CREDENTIAL.CREATE_ORG_CREDENTIAL_SOURCE,
  url: orgCredId ? dataCredential(orgId).updateOrgCredSource(orgCredId)
    : dataCredential(orgId).createOrgCredSource,
  payload: data,
});

export const submitOrgCredentialSourceSuccess = payload => ({
  type: DATA_CREDENTIAL.SUBMIT_ORG_CREDENTIAL_SOURCE_SUCCESS,
  payload,
});

export const submitOrgCredentialSourceError = payload => ({
  type: DATA_CREDENTIAL.SUBMIT_ORG_CREDENTIAL_SOURCE_ERROR,
  payload,
});

export const deleteOrgCredentialSource = (orgId, orgCredId) => ({
  type: DATA_CREDENTIAL.DELETE_ORG_CREDENTIAL_SOURCE,
  url: dataCredential(orgId).deleteOrgCredSource(orgCredId),
});

export const deleteOrgCredentialSourceSuccess = payload => ({
  type: DATA_CREDENTIAL.DELETE_ORG_CREDENTIAL_SOURCE_SUCCESS,
  payload,
});

export const deleteOrgCredentialSourceError = payload => ({
  type: DATA_CREDENTIAL.DELETE_ORG_CREDENTIAL_SOURCE_ERROR,
  payload,
});

export const fetchCredentialSourcesList = () => ({
  type: DATA_CREDENTIAL.FETCH_CREDENTIAL_SOURCES_LIST,
  url: dataCredential().listCredentialSources,
});

export const receiveCredentialSourcesListSuccess = payload => ({
  type: DATA_CREDENTIAL.RECEIVE_CREDENTIAL_SOURCES_LIST_SUCCESS,
  payload,
});

export const receiveCredentialSourcesListError = payload => ({
  type: DATA_CREDENTIAL.RECEIVE_CREDENTIAL_SOURCES_LIST_ERROR,
  payload,
});

export const fetchCredentialSource = credSourceId => ({
  type: DATA_CREDENTIAL.FETCH_CREDENTIAL_SOURCE,
  url: dataCredential().getCredentialSource(credSourceId),
});

export const receiveCredentialSourceSuccess = payload => ({
  type: DATA_CREDENTIAL.RECEIVE_CREDENTIAL_SOURCE_SUCCESS,
  payload,
});

export const receiveCredentialSourceError = payload => ({
  type: DATA_CREDENTIAL.RECEIVE_CREDENTIAL_SOURCE_ERROR,
  payload,
});

export const deleteCredentialSource = credSourceID => ({
  type: DATA_CREDENTIAL.DELETE_CREDENTIAL_SOURCE,
  url: dataCredential().delete(credSourceID),
});

export const deleteCredentialSourceSuccess = payload => ({
  type: DATA_CREDENTIAL.DELETE_CREDENTIAL_SOURCE_SUCCESS,
  payload,
});

export const deleteCredentialSourceError = payload => ({
  type: DATA_CREDENTIAL.DELETE_CREDENTIAL_SOURCE_ERROR,
  payload,
});

export const createCredentialSource = (data, credSourceId) => ({
  type: credSourceId ? DATA_CREDENTIAL.UPDATE_CREDENTIAL_SOURCE
    : DATA_CREDENTIAL.CREATE_CREDENTIAL_SOURCE,
  url: credSourceId ? dataCredential().updateCredentialSource(credSourceId)
    : dataCredential().createCredentialSource,
  payload: data,
});

export const submitCredentialSourceSuccess = payload => ({
  type: DATA_CREDENTIAL.SUBMIT_CREDENTIAL_SOURCE_SUCCESS,
  payload,
});

export const submitCredentialSourceError = payload => ({
  type: DATA_CREDENTIAL.SUBMIT_CREDENTIAL_SOURCE_ERROR,
  payload,
});
