import { initialState } from 'redux/reducer/FlowReducer/contacts';
import { v4 as uuid } from 'uuid';


export const getSelectedFlow = (listOfFlows, flowID) => {
  const flow = listOfFlows.filter(i => i.ID === flowID)[0] ?? {
    ...initialState.flow,
    ID: uuid(),
  };

  return flow;
};
