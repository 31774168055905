import Grid from '@mui/material/Grid';
import PermissionService from 'components/Common/PermissionService';
import React, { useContext } from 'react';
import { Link as DomLink } from 'react-router-dom';
import { FlowBuilderContext } from 'pages/Flow/FlowBuilder/FlowBuilderContextProvider';
import { HButton } from 'BaseComponents';
import { HelpFlow } from 'pages/Flow/HelpFlow';
import { productPerm } from 'utils/appConstants';


const FlowActionButtons = () => {
  const {
    editMode,
    handParametersDrawerOpen,
    cancelUrl,
  } = useContext(FlowBuilderContext);
  const submitText = `${editMode ? 'Update' : 'Save'} Flow`;

  const hasFLowMacroPermission = PermissionService
    .isProductEnabled([productPerm.FlowMacros]);

  return (
    <Grid container justifyContent='flex-end'>
      <Grid item container justifyContent='flex-end' spacing={1}>
        <Grid item>
          <HelpFlow />
        </Grid>
        {hasFLowMacroPermission && (
          <Grid item>
            <HButton
              variant='outlined'
              onClick={handParametersDrawerOpen}
            >
              Define Parameters
            </HButton>
          </Grid>
        )}
        <Grid item>
          <HButton
            variant='outlined'
            to={cancelUrl}
            component={DomLink}
          >
            Cancel
          </HButton>
        </Grid>
        <Grid item>
          <HButton
            color='primary'
            variant='contained'
            disabled={false}
            type='submit'
          >
            {submitText}
          </HButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FlowActionButtons;
