import React, { memo, useContext } from 'react';
import { Card } from '@mui/material';
import { DataContext } from 'BaseComponents/HDataTable/DataContextProvider';
import { HLayoutView } from 'BaseComponents/HDataTable/HLayoutView';
import { HTablePagination } from 'BaseComponents/HTablePagination';
import { styled } from '@mui/material/styles';
import { useResizeTableHeight } from 'BaseComponents/HDataTable/useResizeTableHeight';


const StyledCard = styled(Card)(({ theme }) => ({ padding: `${theme.spacing(1)} 0` }));
const StyledDiv = styled('div')({
  overflow: 'auto', /* Allow scrolling the table */
  minHeight: '200px',
});

export const HListView = memo(({
  controlPanelElement,
}) => {
  const {
    height,
    rows,
    count,
    filters,
    isFiltersApply,
    zeroState: ZeroState,
    handlePaginationChange,
    layout,
  } = useContext(DataContext);

  const tableRef = useResizeTableHeight(height);
  const haveData = rows?.length > 0;

  if (rows && !haveData && ZeroState && !isFiltersApply) {
    return ZeroState;
  }

  return (
    <>
      {controlPanelElement}

      <StyledCard>
        <StyledDiv ref={tableRef}>
          <HLayoutView layout={layout} />
        </StyledDiv>

        {(haveData) && (
          <HTablePagination
            count={count}
            filters={filters}
            onChange={handlePaginationChange}
          />
        )}
      </StyledCard>
    </>
  );
});

HListView.displayName = 'HListView';

