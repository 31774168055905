import HelpIcon from '@mui/icons-material/Help';
import React from 'react';
import { HIconButton, MenuDropdown } from 'BaseComponents';
import { Tooltip } from '@mui/material';


export const HelpTools = ({ items }) => {
  const [anchorEl, setAnchorEl] = React.useState();
  const open = Boolean(anchorEl);

  return (
    <>
      <HIconButton data-testid='face-button-logout' onClick={e => setAnchorEl(e.currentTarget)}>
        <Tooltip title='Help' placement='bottom'>
          <HelpIcon />
        </Tooltip>
      </HIconButton>
      <MenuDropdown
        handleClose={() => setAnchorEl(null)}
        anchor={anchorEl}
        open={open}
        items={items}
      />
    </>
  );
};
