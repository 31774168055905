import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import React, { useMemo } from 'react';
import localStorage from 'utils/localStorage';
import { CACHE } from 'utils/appConstants';
import { SingleMenuItem } from 'components/Layouts/SideNav/SingleMenuItem';


export const ExpandableMenuItem = ({
  menuItem, list, setList, currPath, size, isMainSideNav = false,
}) => {
  const open = useMemo(
    () => list[menuItem.title] ?? false, [list, menuItem.title],
  );

  const handleClick = () => {
    const newList = {};
    if (!list[menuItem.title]) {
      newList[menuItem.title] = true;
    }

    setList(newList);
    localStorage.set(CACHE.expandedMenuItems, JSON.stringify(newList));
  };

  const subMenu = menuItem.items;
  const showExpandableMenu = subMenu.length >= 1;
  const childSelected = isMainSideNav
    && showExpandableMenu
    && (subMenu.reduce((result, curr) => [...result, ...(curr.paths ?? [curr.path])], [])
    ).includes(currPath);

  return (
    <>
      <SingleMenuItem
        data-testid={`single-menu-item-${menuItem.title}`}
        menuItem={menuItem}
        currPath={currPath}
        open={open}
        onClick={handleClick}
        size={size}
        isMainSideNav={isMainSideNav}
        hasChildSelected={childSelected}
      />
      {(showExpandableMenu) && (
        <Collapse in={open} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            {subMenu.map(s => (
              <SingleMenuItem
                data-testid={`single-menu-item-${s.title}`}
                key={s.title}
                menuItem={s}
                currPath={currPath}
                open={open}
                size={size}
                isMainSideNav={isMainSideNav}
              />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
};
