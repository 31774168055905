import cloneDeep from 'lodash/cloneDeep';


const getAllOverlaps = (report, emptyMatrix, yAxis, xAxis, fact) => {
  const o = report.stats.reduce((acc, s) => {
    const overlapCampaign = s.dimensions.find(d => d.key === yAxis)?.value;
    const key = s.dimensions.find(d => d.key === xAxis)?.value;
    const heatMapValue = s.records.find(r => r.key === fact)?.value;
    if (!overlapCampaign || !key || !heatMapValue) {
      return acc;
    }
    return { ...acc, [key]: { ...acc[key], [overlapCampaign]: heatMapValue } };
  }, emptyMatrix);

  return o;
};

const getMatrixOverlaps = (report, emptyMatrix, yAxis, xAxis, fact, campaignFilters) => {
  const o = report.stats.reduce((acc, s) => {
    const overlapCampaign = s.dimensions.find(d => d.key === yAxis)?.value;
    const key = s.dimensions.find(d => d.key === xAxis)?.value;
    const heatMapValue = s.records.find(r => r.key === fact)?.value;
    if (!overlapCampaign || !key || !heatMapValue) {
      return acc;
    }

    if (campaignFilters.values.indexOf(overlapCampaign) !== -1) {
      return { ...acc, [key]: { ...acc[key], [overlapCampaign]: heatMapValue } };
    }
    else {
      return acc;
    }
  }, emptyMatrix);
  return o;
};

const getAllCampaignNames = (report) => {
  const result = [];
  report.stats.forEach((i) => {
    const r = i.dimensions.find(k => k.key === 'CAMPAIGN_NAME');
    if (r) {
      if (result.indexOf(r.value) === -1) {
        result.push(r.value);
      }
    }
  });

  return result;
};

const optimus = (originalReport, filters = []) => {
  const report = cloneDeep(originalReport);
  if (report.visualization !== 'HEATMAP' && report.title === 'Campaign Overlap') { return report; }
  const campaignFilters = filters.find(i => i.attribute === 'CAMPAIGN_NAME') || { values: getAllCampaignNames(report) };
  if (!campaignFilters || !campaignFilters.values) { return report; }

  const xAxis = 'CAMPAIGN_NAME';
  const yAxis = 'OVERLAP_CAMPAIGN_NAME';
  const fact = 'OVERLAP_USERS';

  // Create object: { <campaign1> : 0, <campaign2>: 0, ... }
  const emptyRow = campaignFilters.values.reduce((acc, i) => ({ ...acc, [i]: 0 }), {});
  const showAllOverlaps = Object.keys(emptyRow).length > 1;
  // Create perfectly square object: {
  //  <campaign1> : { <campaign2>:0, <campaign3>: 0 ... } ,
  //  <campaign2>: { <campaign1>: 0, <campaign3: 0, ... },
  // }
  const emptyMatrix = campaignFilters.values.reduce((acc, i) => ({
    ...acc,
    [i]: {
      ...emptyRow,
      // Insert markers to optimize chart formatting
      [i]: 0,
      [xAxis]: i,
    },
  }), {});

  // Create { <campaignName> : { <overlapCampaign> : <fact>, <overlapCampaign2: <fact2>..} }
  const o = showAllOverlaps
    ? getMatrixOverlaps(report, emptyMatrix, yAxis, xAxis, fact, campaignFilters)
    : getAllOverlaps(report, emptyMatrix, yAxis, xAxis, fact);

  // Format for the chart
  return Object.values(o).map(i => ({
    name: i[xAxis],
    data: Object.keys(i)
      // Remove the temp key
      .filter(j => j !== xAxis && i[j] !== undefined)
      .map((d) => {
        if (d === i[xAxis]) {
          return { x: d, y: i[d], isSelf: true };
        }
        else {
          return { x: d, y: i[d] };
        }
      }),
  }));
};

export default optimus;
