import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';
import TextFieldCharCount from 'components/Common/TextFieldCharCount';
import Typography from '@mui/material/Typography';
import { HButton, HSpinner } from 'BaseComponents';


const ConfirmationDialog = (
  {
    open,
    title,
    message,
    loading,
    error,
    onCancel,
    onConfirm,
  },
) => {
  const [comment, setComment] = React.useState('');

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      fullWidth
      maxWidth='xs'
      aria-labelledby='confirmation-dialog'
      aria-describedby='confirmation-dialog'
    >
      <Card>
        <DialogTitle>
          <Typography variant='h5'>{title}</Typography>
        </DialogTitle>
        <DialogContent>
          <Box pb={3}>{message}</Box>
          <TextFieldCharCount
            label='Impersonation reason'
            value={comment}
            onBlur={value => setComment(value)}
            characterLimit={200}
            data-testid='impersonation-reason'
          />
        </DialogContent>
        <Box display='flex' justifyContent='flex-end'>
          <CardActions sx={{ padding: 2 }}>
            {onCancel ? (
              <HButton variant='outlined' onClick={onCancel} size='small' data-testid='cancel-button'>
                Cancel
              </HButton>
            ) : null}
            <HButton
              variant='contained'
              color='primary'
              size='small'
              onClick={() => onConfirm(comment)}
              endIcon={loading ? <HSpinner isButton /> : null}
              disabled={loading}
              data-testid='confirm-impersonation-button'
            >
              Impersonate
            </HButton>
          </CardActions>
        </Box>
        {error && (<Typography color='error'>{error}</Typography>)}
      </Card>
    </Dialog>
  );
};

export default ConfirmationDialog;
