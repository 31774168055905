import CachedIcon from '@mui/icons-material/Cached';
import React, { memo, useState } from 'react';
import { Box } from '@mui/material';
import { HButton } from 'BaseComponents';


export const HOnRefreshButton = memo(({
  onRefresh,
}) => {
  const [rotation, setRotation] = useState(0);

  const handleClick = () => {
    if (onRefresh) {
      onRefresh();
      setRotation(rotation - 180);
    }
  };

  return (
    <>
      {onRefresh && (
        <Box display='flex' justifyContent='flex-end'>
          <HButton size='small' onClick={handleClick}>
            <CachedIcon fontSize='small' sx={{ transform: `rotate(${rotation}deg)`, transition: 'transform 1s ease' }} />
          </HButton>
        </Box>
      )}
    </>
  );
});

HOnRefreshButton.displayName = 'HOnRefreshButton';
