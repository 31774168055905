import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import FlowMacroAssignment from 'pages/Flow/FlowMacro/MacroAssignmentTab/FlowMacroAssignment';
import FlowMacroPreview from 'pages/Flow/FlowMacro/PreviewMacroTab/FlowMacroPreview';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import React, { useContext } from 'react';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { FlowMacroContext } from 'pages/Flow/FlowMacro/MacroAssignmentTab/FlowMacroContextProvider';
import { ResourceNotFound } from 'pages/ErrorPages/ResourceNotFound';


export const FLOW_MACRO_TAB_VALUE = {
  MACRO_ASSIGNMENT: 'Assign_Parameters',
  MACRO_PREVIEW: 'Preview_Parameters',
};

const FlowMacroLayout = () => {
  const { tabValue, updateTab, handleOnClose } = useContext(FlowMacroContext);

  const renderTab = () => {
    switch (tabValue) {
      case FLOW_MACRO_TAB_VALUE.MACRO_ASSIGNMENT: return (
        <FlowMacroAssignment />
      );
      case FLOW_MACRO_TAB_VALUE.MACRO_PREVIEW: return (
        <FlowMacroPreview />
      );
      default: return <ResourceNotFound />;
    }
  };

  return (
    <Box sx={{
      display: 'flex', flexDirection: 'column', width: '100%',
    }}
    >
      <Toolbar sx={{
        position: 'sticky', top: 0, zIndex: 1, backgroundColor: 'white',
      }}
      >
        <Grid container spacing={0} alignItems='center'>
          <Grid item xs={11}>
            <Typography variant='h3' noWrap>
              Parameters
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton aria-label='close' onClick={handleOnClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
      <Grid container spacing={0} sx={{ paddingX: 3, paddingBottom: 2 }}>
        <Tabs
          indicatorColor='primary'
          onChange={(e, newValue) => updateTab(newValue)}
          value={tabValue}
        >
          <Tab label='Assign' value={FLOW_MACRO_TAB_VALUE.MACRO_ASSIGNMENT} />
          <Tab label='Preview' value={FLOW_MACRO_TAB_VALUE.MACRO_PREVIEW} />
        </Tabs>
      </Grid>
      {renderTab()}
    </Box>
  );
};

export default FlowMacroLayout;
