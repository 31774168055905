export const CLEAN_ROOM_ADMIN = {
  FETCH_ALL_QUESTIONS: 'CR/ADMIN/FETCH_ALL_QUESTIONS',
  FETCH_ALL_QUESTIONS_ERROR: 'CR/ADMIN/FETCH_ALL_QUESTIONS_ERROR',
  FETCH_ALL_QUESTIONS_SUCCESS: 'CR/ADMIN/FETCH_ALL_QUESTIONS_SUCCESS',

  FETCH_ALL_STORAGE_PROVIDERS: 'CR/ADMIN/FETCH_ALL_STORAGE_PROVIDERS',
  FETCH_ALL_STORAGE_PROVIDERS_SUCCESS: 'CR/ADMIN/FETCH_ALL_STORAGE_PROVIDERS_SUCCESS',
  FETCH_ALL_STORAGE_PROVIDERS_ERROR: 'CR/ADMIN/FETCH_ALL_STORAGE_PROVIDERS_ERROR',
  FETCH_ALL_IMPORT_DATA_TYPES: 'CR/ADMIN/FETCH_ALL_IMPORT_DATA_TYPES',
  FETCH_ALL_IMPORT_DATA_TYPES_SUCCESS: 'CR/ADMIN/FETCH_ALL_IMPORT_DATA_TYPES_SUCCESS',
  FETCH_ALL_IMPORT_DATA_TYPES_ERROR: 'CR/ADMIN/FETCH_ALL_IMPORT_DATA_TYPES_ERROR',

  FETCH_ORG_CR_QUESTIONS: 'CR/ADMIN/FETCH_ORG_CR_QUESTIONS',
  FETCH_ORG_CR_QUESTIONS_SUCCESS: 'CR/ADMIN/FETCH_ORG_CR_QUESTIONS_SUCCESS',
  FETCH_ORG_CR_QUESTIONS_ERROR: 'CR/ADMIN/FETCH_ORG_CR_QUESTIONS_ERROR',
  UPDATE_ORG_CR_QUESTIONS: 'CR/ADMIN/UPDATE_ORG_CR_QUESTIONS',
  UPDATE_ORG_CR_QUESTIONS_SUCCESS: 'CR/ADMIN/UPDATE_ORG_CR_QUESTIONS_SUCCESS',
  UPDATE_ORG_CR_QUESTIONS_ERROR: 'CR/ADMIN/UPDATE_ORG_CR_QUESTIONS_ERROR',

  FETCH_QUESTION: 'CR/ADMIN/FETCH_QUESTION',
  FETCH_QUESTION_SUCCESS: 'CR/ADMIN/FETCH_QUESTION_SUCCESS',
  FETCH_QUESTION_ERROR: 'CR/ADMIN/FETCH_QUESTION_ERROR',
  CLEAR_CR_QUESTION: 'CR/ADMIN/CLEAR_CR_QUESTION',
  CREATE_CR_QUESTION: 'CR/ADMIN/CREATE_CR_QUESTION',
  CREATE_CR_QUESTION_SUCCESS: 'CR/ADMIN/CREATE_CR_QUESTION_SUCCESS',
  CREATE_CR_QUESTION_ERROR: 'CR/ADMIN/CREATE_CR_QUESTION_ERROR',
  UPDATE_CR_QUESTION: 'CR/ADMIN/UPDATE_CR_QUESTION',
  UPDATE_CR_QUESTION_SUCCESS: 'CR/ADMIN/UPDATE_CR_QUESTION_SUCCESS',
  UPDATE_CR_QUESTION_ERROR: 'CR/ADMIN/UPDATE_CR_QUESTION_ERROR',

  FETCH_ALL_CR_QUESTIONS: 'CR/ADMIN/FETCH_ALL_CR_QUESTIONS',
  FETCH_ALL_CR_QUESTIONS_SUCCESS: 'CR/ADMIN/FETCH_ALL_CR_QUESTIONS_SUCCESS',
  FETCH_ALL_CR_QUESTIONS_ERROR: 'CR/ADMIN/FETCH_ALL_CR_QUESTIONS_ERROR',
};
