import { axiosInstance } from 'utils/http';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch } from 'react-redux';
import { useQuery } from 'react-query';


export const useListFilters = (url, tableName) => {
  const dispatch = useDispatch();

  const {
    data,
    isLoading,
    isError,
    isSuccess,
    isFetching,
  } = useQuery(
    [`${tableName}-GetListFilters`],
    async () => axiosInstance.get(url),
    {
      refetchOnWindowFocus: false,
      onError: (err) => dispatch(
        showAlert({ message: err.response.data.message, type: 'error' }),
      ),
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    isFetching,
    data,
  };
};
