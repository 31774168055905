import Grid from '@mui/material/Grid';
import LiveRampLoader from 'BaseComponents/LiveRampLoader';
import React from 'react';
import Typography from '@mui/material/Typography';
import { ResourceNotFound } from 'pages/ErrorPages/ResourceNotFound';


export const FlowParameterDescription = ({ text }) => (
  <Grid container sx={{ paddingX: 3, paddingBottom: 2, backgroundColor: '#FAFAFA' }}>
    <Typography variant='body2'>
      {text}
    </Typography>
  </Grid>
);

export const Loader = () => (
  <Grid container justifyContent='center' sx={{ paddingX: 3, paddingBottom: 2 }}>
    <LiveRampLoader />
  </Grid>
);

export const ErrorComponent = () => (
  <Grid container sx={{ paddingX: 3, paddingBottom: 2 }}>
    <ResourceNotFound />
  </Grid>
);
