import AlertsControlPanel from 'pages/InternalAdmin/organization/pages/Alerts/AlertsControlPanel';
import React, { useContext } from 'react';
import { AlertsContext } from 'pages/InternalAdmin/organization/pages/Alerts/AlertsListLayout';
import { AlertsPageContext } from 'pages/InternalAdmin/organization/pages/Alerts/Alerts';
import { HDataTable } from 'BaseComponents';
import { QuestionIdsCell, RecipientsCell, StatusCell } from 'pages/InternalAdmin/organization/pages/Alerts/TableCellComponents';
import { activeOrgSelector } from 'redux/reducer/AuthenticationReducer';
import { cleanRoom } from 'utils/urlConstants';
import { useSelector } from 'react-redux';


const columns = [
  {
    field: 'STATUS',
    name: 'Status',
    component: StatusCell,
  },
  {
    field: 'ALERT_DISPLAY_ID',
    name: 'Alert ID',
    width: 150,
    valueGetter: ({ row }) => row.displayID,
  },
  {
    field: 'ALERT_NAME',
    name: 'Alert Name',
    width: 150,
    valueGetter: ({ row }) => row.alertName,
  },
  {
    field: 'Question IDs',
    name: 'Question IDs',
    width: 200,
    component: QuestionIdsCell,
    valueGetter: ({ row }) => row.cleanRoomQuestionDisplayIDs.join(', '),
  },
  {
    field: 'Recipients',
    name: 'Recipients',
    width: 200,
    component: RecipientsCell,
  },
];


const AlertListTable = () => {
  const { crId } = useContext(AlertsPageContext);
  const { ID: orgId } = useSelector(activeOrgSelector);

  const dataURL = cleanRoom(orgId).listAlertsWithFilter(crId);
  const filtersURL = cleanRoom(orgId).listAlertsFilters(crId);

  const { actionMenu } = useContext(AlertsContext);

  return (
    <HDataTable
      columns={columns}
      dataURL={dataURL}
      filtersURL={filtersURL}
      actionMenu={actionMenu}
      tableName='alert-list-table'
      dataBaseField='alertDetails'
      controlPanel={<AlertsControlPanel />}
    />
  );
};

export default AlertListTable;
