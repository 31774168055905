import * as Trees from 'components/Layouts/Header/NavigationTree';
import AccountAndOrgSwitcher from 'components/PrimaryHeader/AccountAndOrgSwitcher';
import Header from 'components/Layouts/Header/Header';
import MobileUserTools from 'components/Layouts/Header/MobileUserTools';
import React from 'react';
import UserTools from 'components/Layouts/Header/UserTools';
import { HelpTools } from 'components/Layouts/Header/HelpTools';
import { Hidden } from '@mui/material';


const AppHeader = () => {
  const userMenu = Trees.getUserMenu();
  const helpMenu = Trees.getHelpMenuItems();

  return (
    <Header
      size='big'
      right={(
        <>
          <Hidden mdDown>
            <AccountAndOrgSwitcher />
            <HelpTools items={helpMenu} />
            <UserTools items={userMenu} />
          </Hidden>
          <Hidden mdUp>
            <MobileUserTools items={userMenu} helpItems={helpMenu} />
          </Hidden>
        </>
      )}
    />
  );
};

export default AppHeader;
