import DataCredentialForm from 'pages/InternalAdmin/application/pages/CredentialSourceDetails/DataCredentialForm';
import Grid from '@mui/material/Grid';
import PageHeader from 'components/Common/PageHeader';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { createCredentialSource, fetchCredentialSource } from 'redux/actions/DataCredentialManagementAction';
import { crumbs } from 'components/Common/BreadcrumbsNavigation';
import { internalAdmin } from 'utils/spaUrls';
import { useDispatch, useSelector } from 'react-redux';


const CredentialSourceDetails = (props) => {
  const { id } = props.match.params;
  const dispatch = useDispatch();
  const credentialSource = useSelector(state => state.dataCredential.credentialSource);
  const redirect = useSelector(state => state.dataCredential.meta.redirect);
  React.useEffect(() => {
    if (id) {
      dispatch(fetchCredentialSource(id));
    }
  }, [dispatch, id]);

  if (redirect) {
    return <Redirect to={internalAdmin.application.credentialSources} />;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <PageHeader
          title={`${id ? 'Edit' : 'New'} Data Credential Source`}
          breadcrumbs={[crumbs.helium, crumbs.internalAdmin]}
        />
      </Grid>
      <Grid item xs={12}>
        <DataCredentialForm
          source={credentialSource}
          submitCredentialSource={
            (data, credSourceId) => dispatch(createCredentialSource(data, credSourceId))
          }
        />
      </Grid>
    </Grid>
  );
};

export default CredentialSourceDetails;
