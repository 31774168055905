import Avatar, { avatarClasses } from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';
import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';


const StyledAvatarGroup = styled(AvatarGroup)({
  justifyContent: 'flex-end',
});

const StyledAvatar = styled(Avatar, {
  shouldForwardProp: (props) => props !== 'avatarSize',
})(({ avatarSize, theme }) => ({
  background: theme.palette.background.default,
  boxShadow: '0px 2px 2px 0px rgba(0,0,0,0.2)',
  height: avatarSize,
  width: avatarSize,
  [`& .${avatarClasses.img}`]: {
    height: avatarSize * 0.8,
    width: avatarSize * 0.8,
  },
}));

export const HAvatarGroup = ({
  list, max, avatarSize, ...restProps
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  // Set ref to the plus menu avatar element from the avatar group
  // which will open the more menu list
  const handleMenuClick = (event) => {
    if (list.length > max) {
      setAnchorEl(event.currentTarget.firstChild);
    }
  };

  // Close the menu list
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <StyledAvatarGroup
        max={max}
        onClick={handleMenuClick}
        data-testid='avatar-group-container'
        slotProps={{
          additionalAvatar: {
            sx: {
              cursor: 'pointer',
              background: (theme) => `${theme.palette.primary.main}`,
              color: (theme) => theme.palette.secondary.main,
              fontSize: '0.8rem',
              boxShadow: '0px 2px 2px 0px rgba(0,0,0,0.2)',
              height: avatarSize,
              width: avatarSize,
            },
          },
        }}
        {...restProps}
      >
        {list.map(({ src, label }) => (
          <Tooltip
            key={label}
            title={label}
            arrow
            placement='top'
            data-testid='avatar-tooltip'
          >
            <StyledAvatar
              src={src}
              alt={label || ''}
              data-testid='avatar-item'
              avatarSize={avatarSize}
            />
          </Tooltip>
        ))}
      </StyledAvatarGroup>

      {/* More avatars to be displayed as menu list */}
      <Menu
        id='avatar-group-menu'
        aria-labelledby='avatar-group-menu'
        data-testid='avatar-group-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {list.slice(max - 1).map(({ src, label }) => (
          <MenuItem key={label} disableRipple>
            <StyledAvatar
              data-testid='avatar-group-item'
              key={label}
              src={src}
              alt={label || ''}
            />
            <Typography ml={2}>{label}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

HAvatarGroup.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({
    src: PropTypes.string,
    label: PropTypes.string,
  })).isRequired,
  max: PropTypes.number,
  avatarSize: PropTypes.number,
};

HAvatarGroup.defaultProps = {
  max: 4,
  avatarSize: 24,
};
