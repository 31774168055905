import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import MacroAssignmentParameterTable from 'pages/Flow/FlowMacro/MacroAssignmentTab/MacroAssignmentParameterTable';
import React, { useContext } from 'react';
import Typography from '@mui/material/Typography';
import {
  ErrorComponent,
  FlowParameterDescription,
  Loader,
} from 'pages/Flow/FlowMacro/FlowParametersCommonComponents';
import { FLOW_MACRO_TAB_VALUE } from 'pages/Flow/FlowMacro/FlowMacroLayout';
import { FlowMacroContext } from 'pages/Flow/FlowMacro/MacroAssignmentTab/FlowMacroContextProvider';
import { HButton } from 'BaseComponents';


const FlowMacroAssignment = () => {
  const {
    flowRunParameters,
    handleOnClose,
    isFlowMacrosError,
    isFlowMacrosLoading,
    isFlowRunParamMappingError,
    isFlowRunParamMappingLoading,
    updateTab,
  } = useContext(FlowMacroContext);

  const handleNext = () => {
    updateTab(FLOW_MACRO_TAB_VALUE.MACRO_PREVIEW);
  };

  if (isFlowRunParamMappingError || isFlowMacrosError) {
    return (
      <ErrorComponent />
    );
  }

  if (isFlowRunParamMappingLoading || isFlowMacrosLoading) {
    return (
      <Loader />
    );
  }

  return (
    <Grid container spacing={0} sx={{ paddingX: 3, paddingBottom: 2 }}>
      <FlowParameterDescription
        text='Avoid redundant forms by assigning parameters across questions and flows.'
      />
      <Grid item xs={12}>
        {flowRunParameters?.length ? (
          <MacroAssignmentParameterTable />
        ) : (
          <Typography>No parameters available</Typography>
        )}
      </Grid>
      <Box sx={{
        position: 'sticky',
        bottom: 0,
        zIndex: 1,
        backgroundColor: 'white',
        padding: 2,
        marginTop: '10px',
        width: '100%',
      }}
      >
        <Grid container item spacing={2} justifyContent='flex-end'>
          <Grid item>
            <HButton variant='outlined' onClick={handleOnClose}>
              Cancel
            </HButton>
          </Grid>
          <Grid item>
            <HButton
              type='submit'
              variant='contained'
              color='primary'
              disabled={isFlowMacrosLoading}
              onClick={handleNext}
            >
              Next
            </HButton>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default FlowMacroAssignment;
