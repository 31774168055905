import React from 'react';
import { Box, styled } from '@mui/material';
import { defaultDesignToken } from 'designToken';
import { pxToRem } from 'MotifTheme';


const StyledBox = styled(Box)(({ theme }) => ({
  height: '40px',
  display: 'none',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.info['x-dark'],
  color: theme.palette.common.white,
  fontFamily: defaultDesignToken.fontFamily[0],
  fontWeight: 700,
  fontSize: pxToRem(14),
  width: 'calc(100% - 138px)',
  alignSelf: 'flex-end',
}));

const StyledAnchor = styled('a')(({ theme }) => ({
  color: theme.palette.common.white,
  textDecoration: 'none',
  marginLeft: theme.spacing(1),
}));

// NOTE: not removing, as banner is planned to be reused in the future
const LiveRampBanner = () => (
  <StyledBox>
    Habu is now part of LiveRamp.
    {' '}
    <StyledAnchor href='https://liveramp.com/blog/liveramp-habu-accelerate-data-collaboration' target='blank'>Learn more &rarr;</StyledAnchor>
  </StyledBox>
);

export default LiveRampBanner;
