export const AUTHENTICATION = {
  AUTH_TOKEN_EXPIRED: 'AUTH_TOKEN_EXPIRED',
  REFRESH_TOKEN_EXPIRED: 'REFRESH_TOKEN_EXPIRED',
  GET_USER_AUTH_TYPE: 'GET_USER_AUTH_TYPE',
  GET_USER_AUTH_TYPE_SUCCESS: 'GET_USER_AUTH_TYPE_SUCCESS',
  GET_USER_AUTH_TYPE_FAILURE: 'GET_USER_AUTH_TYPE_FAILURE',
  AUTH_CALL_BACK: 'AUTH_CALL_BACK',
  AUTH_CALL_BACK_SUCCESS: 'AUTH_CALL_BACK_SUCCESS',
  AUTH_CALL_BACK_ERROR: 'AUTH_CALL_BACK_ERROR',
  SEND_LOGIN: 'SEND_LOGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  PERMISSION_DENIED: 'PERMISSION_DENIED',
  REQUEST_LOGOUT: 'REQUEST_LOGOUT',
  REFRESH_TOKEN: 'REFRESH_TOKEN',
  SWITCH_ORGANIZATION: 'SWITCH_ORGANIZATION',
  SWITCH_ORGANIZATION_SUCCESS: 'SWITCH_ORGANIZATION_SUCCESS',
  SWITCH_ORGANIZATION_FAILURE: 'SWITCH_ORGANIZATION_FAILURE',
  SWITCH_ACCOUNT: 'SWITCH_ACCOUNT',
  SWITCH_ACCOUNT_SUCCESS: 'SWITCH_ACCOUNT_SUCCESS',
  SWITCH_ACCOUNT_FAILURE: 'SWITCH_ACCOUNT_FAILURE',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE',
  REQUEST_FORGOT_PASSWORD_LINK: 'REQUEST_FORGOT_PASSWORD_LINK',
  REQUEST_FORGOT_PASSWORD_LINK_SUCCESS: 'REQUEST_FORGOT_PASSWORD_LINK_SUCCESS',
  REQUEST_FORGOT_PASSWORD_LINK_FAILURE: 'REQUEST_FORGOT_PASSWORD_LINK_FAILURE',
  RESET_REDIRECT: 'RESET_REDIRECT',
};
