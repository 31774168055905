import { ALERTS } from 'redux/actions/Types';
import { v4 as uuidGen } from 'uuid';


const initialState = {
  alerts: [],
  queue: [],
};

const MAX_VISIBLE_ALERTS = 3;

const AlertReducer = (state = initialState, action) => {
  switch (action.type) {
    case ALERTS.SHOW_ALERT: {
      const newAlert = { id: uuidGen(), ...action.payload };
      if (state.alerts.length < MAX_VISIBLE_ALERTS) {
        return { ...state, alerts: [...state.alerts, newAlert] };
      }
      else {
        return { ...state, queue: [...state.queue, newAlert] };
      }
    }
    case ALERTS.HIDE_ALERT: {
      const remainingAlerts = state.alerts.filter(alert => alert.id !== action.payload.id);
      const nextAlert = state.queue.length > 0 ? state.queue[0] : null;
      const newQueue = state.queue.slice(1);
      return {
        ...state,
        alerts: nextAlert ? [...remainingAlerts, nextAlert] : remainingAlerts,
        queue: newQueue,
      };
    }
    default:
      return state;
  }
};

export const getAppAlerts = state => state.alerts.alerts;

export default AlertReducer;
