import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Paper from '@mui/material/Paper';
import React from 'react';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { habuColors } from 'Theme';
import { styled } from '@mui/material/styles';


export const Code = styled('code')(({ theme }) => ({
  display: 'block',
  fontSize: theme.typography.body2.fontSize,
  fontFamily: ['"Courier New"', 'source-code-pro', 'Menlo', 'Monaco', 'Consolas', 'monospace'].join(','),
}));

export const CodePaper = styled((props) => (
  <Paper variant='outlined' square {...props} />
))(({ theme }) => ({
  padding: '12px',
  backgroundColor: theme.palette.background.default,
  borderColor: '#E1E5EB',
}));

export const HabuAlertInfo = styled(props => (
  <Paper elevation={0} {...props}>
    <Box display='flex' marginRight='12px' component='span'>
      <InfoOutlinedIcon />
    </Box>
    <Typography variant='body2' component='div'>
      {props.children}
    </Typography>
  </Paper>
))({
  display: 'inline-flex',
  alignItems: 'center',
  padding: '8px 16px',
  width: 'max-content',
  backgroundColor: habuColors.secondary[10],
  color: habuColors.secondary[60],
});

export const CodeBlueSpan = styled('span')(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
  color: '#11007B',
}));

export const CodeGreenSpan = styled('span')(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
  color: '#1B5E20',
}));

export const DeleteIconButton = styled(({ loading = false, ...props }) => (
  <IconButton {...props}>
    {
      loading
        ? <CircularProgress color='inherit' size={20} />
        : <DeleteOutlineIcon />
    }
  </IconButton>
))({
  border: '1px solid',
  borderColor: habuColors.neutral[30],
  borderRadius: '2px',
  color: '#EF5350',
  paddingLeft: '8px',
  paddingRight: '8px',
  '& .MuiTouchRipple-root .MuiTouchRipple-child': {
    borderRadius: '8px',
  },
});

export const SettingIconButton = styled(props => (
  <IconButton {...props}>
    <SettingsOutlinedIcon />
  </IconButton>
))({
  border: '1px solid',
  borderColor: habuColors.neutral[30],
  borderRadius: '2px',
  color: habuColors.neutral[90],
  paddingLeft: '8px',
  paddingRight: '8px',
  '& .MuiTouchRipple-root .MuiTouchRipple-child': {
    borderRadius: '8px',
  },
});

export const StyledDialogTitle = styled(DialogTitle)({
  margin: '0px',
  padding: '16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const HtmlTooltip = styled(({ className, children, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }}>
    {children}
  </Tooltip>
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: habuColors.neutral['10'],
    color: theme.palette.primary.main,
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: `1px solid ${habuColors.neutral['30']}`,
  },
}));

export const TabsButtons = styled(Tabs)({
  flexShrink: 0,
  borderBottom: 'none',
  height: 'fit-content',
  position: 'sticky',
  top: 0,
});

export const TabButton = styled(Tab)({
  minHeight: '40px',
  height: '40px',
  alignItems: 'start',
});
