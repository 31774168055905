import { getColumnsLocalStorage, setColumnsLocalStorage } from 'BaseComponents/HDataTable/utils';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';


export const useLocalStorageTable = ({ columns: initColumns, tableName }) => {
  const { crId = '' } = useParams();
  const orgId = useSelector(state => state.session.user.primaryOrganization.organization.ID);
  const tableLocalStorageName = `${tableName}-${orgId}-${crId}-table`;

  const localStorageColumnsMap = getColumnsLocalStorage(tableLocalStorageName);

  const columns = useMemo(() => (
    initColumns.map(i => {
      const curr = localStorageColumnsMap.get(i.field) ?? {};
      return {
        ...i,
        ...curr,
      };
    })
  ), [localStorageColumnsMap, initColumns]);

  return {
    columns,
    getColumnsLocalStorage: () => getColumnsLocalStorage(tableLocalStorageName),
    setColumnsLocalStorage: () => {
      setColumnsLocalStorage(tableLocalStorageName, columns);
    },
  };
};
