import ConfirmationDialog from 'components/Common/ConfirmationDialog';
import React, { createContext, useMemo, useState } from 'react';
import { deleteFlowNode } from 'redux/actions/FlowAction';
import { useDispatch, useSelector } from 'react-redux';


export const DeleteNodeContext = createContext({});

export const DeleteNodeContextProvider = ({ children }) => {
  const dispatch = useDispatch();
  const orgId = useSelector(state => state.session.user?.primaryOrganization.organization.ID ?? '');
  const flowID = useSelector(state => state.flow.flow.ID);

  const [nodeDescription, setNodeDescription] = useState({});
  const isOpen = useMemo(() => Boolean(nodeDescription.nodeId), [nodeDescription]);

  const handleOnCancel = () => {
    setNodeDescription({});
  };

  const handleOnConfirm = async () => {
    await dispatch(deleteFlowNode({
      orgId,
      flowID,
      nodeId: nodeDescription.nodeId,
    }));

    handleOnCancel();
  };

  const flowBuilderContextValue = {
    setNodeDescription,
  };

  return (
    <DeleteNodeContext.Provider value={flowBuilderContextValue}>
      {children}
      {isOpen && (
        <ConfirmationDialog
          open={isOpen}
          title='Delete Node Question?'
          message={`Are you sure you want to delete "${nodeDescription.title}"?`}
          confirmActionLabel='Delete'
          onConfirm={handleOnConfirm}
          onCancel={handleOnCancel}
        />
      )}
    </DeleteNodeContext.Provider>
  );
};
