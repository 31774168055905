import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import React from 'react';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { HButton, HSpinner } from 'BaseComponents';
import { styled } from '@mui/material/styles';


const StyledGrid = styled(Grid)({
  marginTop: 10,
  marginBottom: 5,
});

const StyledTypography = styled(Typography)({
  paddingBottom: 0,
});


const CrOrgInvitationApiModal = ({
  open, onClose, onSave, loading, error,
}) => {
  const [apiKey, setApiKey] = React.useState('');

  const onSubmit = (e, data) => {
    e.preventDefault();
    onSave(data);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth='md'>
      <Card>
        <CardContent>
          <StyledTypography variant='h2'>Add Organization Link ID</StyledTypography>
          <Typography>
            In order to link two clean rooms you will need the Organization Link ID for the
            respective organization.
          </Typography>
          <StyledGrid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label='Organization Link ID'
                margin='normal'
                onChange={({ target }) => setApiKey(target.value)}
                required
                type='text'
                value={apiKey}
                variant='outlined'
              />
            </Grid>
            {error && (<Grid item xs={12}><Typography color='error'>{error}</Typography></Grid>)}
          </StyledGrid>
        </CardContent>
        <Box display='flex' justifyContent='flex-end'>
          <CardActions>
            <HButton variant='outlined' onClick={onClose} size='small'>
              Cancel
            </HButton>
            <HButton
              variant='contained'
              color='primary'
              size='small'
              onClick={e => onSubmit(e, apiKey)}
              endIcon={loading ? <HSpinner isButton /> : null}
              disabled={loading}
            >
              Submit
            </HButton>
          </CardActions>
        </Box>
      </Card>
    </Dialog>
  );
};

export default CrOrgInvitationApiModal;
