import Check from '@mui/icons-material/DoneRounded';
import React from 'react';
import Remove from '@mui/icons-material/RemoveRounded';
import Warning from '@mui/icons-material/WarningRounded';
import clsx from 'clsx';
import {
  StepIcon as MuiStepIcon,
  stepIconClasses,
} from '@mui/material';
import { pxToRem } from 'MotifTheme';


const StepIcon = React.forwardRef(
  (
    {
      active, completed, customIcon, hideStepNumber, icon, variant, ...props
    },
    ref,
  ) => {
    const className = () => clsx([`${stepIconClasses.root}`], {
      'Mui-active': active,
      'Mui-completed': completed,
      'Mui-warning': variant === 'warning',
      'Mui-error': variant === 'error',
      'Mui-canceled': variant === 'canceled',
    });

    const determineIcon = () => {
      if (customIcon) return customIcon;
      if (variant === 'canceled') return <Remove />;
      if (variant === 'warning') return <Warning viewBox='0 1.5 24 24' />;
      if (variant === 'error') return <span style={{ fontSize: `${pxToRem(24)}` }}>!</span>;
      if (completed) return <Check />;
      if (hideStepNumber) return <span>&nbsp;</span>;
      return <span>{icon}</span>;
    };

    return (
      <MuiStepIcon
        ref={ref}
        icon={React.cloneElement(determineIcon(), {
          className: className(),
        })}
        {...props}
      />
    );
  },
);

export default StepIcon;
