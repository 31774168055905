import * as crActions from 'redux/actions/CleanRoomsActions';
import Logger from 'components/Common/Logger';
import PermissionService from 'components/Common/PermissionService';
import http from 'utils/http';
import optimus from 'dataTransformers/optimus';
import { CLEAN_ROOM } from 'redux/actions/Types';
import {
  all, call, put, takeLatest, takeLeading,
} from 'redux-saga/effects';
import { habuSupport } from 'utils/appConstants';
import { showAlert } from 'redux/actions/AlertActions';


function* addCrUser(action) {
  const { user, error } = yield call(http.post, action.url, action.payload);
  if (error) {
    Logger.error(error, 'Clean Rooms: Add User To Clean Room');
    return yield put(crActions.addCrUserError(error));
  }
  return yield put(crActions.addCrUserSuccess(user));
}

function* addQuestion(action) {
  const { question, error } = yield call(http.post, action.url, action.payload);
  if (error) {
    yield put(showAlert({ message: error, type: 'error' }));
    Logger.error(error, 'Clean Rooms: Add Question To Clean Room');
    return yield put(crActions.addQuestionError(error));
  }
  yield put(showAlert({ message: 'Question Added to Clean Room.', type: 'success' }));
  return yield put(crActions.addQuestionSuccess(question));
}

function* createDataset(action) {
  const { dataSet, error } = yield call(http.post, action.url, action.payload);
  if (error) {
    Logger.error(error, 'Clean Rooms: Create Dataset');
    yield put(showAlert({ message: error, type: 'error' }));
    return yield put(crActions.createDatasetError(error));
  }
  yield put(showAlert({ message: 'Dataset configured', type: 'success' }));
  return yield put(crActions.createDatasetSuccess(dataSet));
}

function* createNewRun(action) {
  const { runDetails, error } = yield call(http.post, action.url, action.payload);
  if (error) {
    Logger.error(error, 'Clean Rooms: Create Question Run');
    return yield put(crActions.createNewRunError(error));
  }
  yield put(showAlert({
    message: 'Run Created, successfully!',
    type: 'success',
  }));
  return yield put(crActions.createNewRunSuccess(runDetails));
}

function* fetchScheduledRun(action) {
  const { runDetails, error } = yield call(http.get, action.url);
  if (error) {
    Logger.error(error, 'Clean Rooms: Fetch Scheduled Question Run Details');
    return yield put(crActions.fetchScheduledRunError(error));
  }
  return yield put(crActions.fetchScheduledRunSuccess(runDetails));
}

function* fetchScheduledRuns(action) {
  const { runDetails, error } = yield call(http.get, action.url);
  if (error) {
    Logger.error(error, 'Clean Rooms: Fetch Scheduled Question Runs');
    yield put(showAlert({
      message: error,
      type: 'error',
    }));
    return yield put(crActions.fetchScheduledRunsError(error));
  }
  return yield put(crActions.fetchScheduledRunsSuccess(runDetails));
}

function* updateDataset(action) {
  const { dataSet, error } = yield call(http.put, action.url, action.payload);
  if (error) {
    Logger.error(error, 'Clean Rooms: Update Dataset');
    yield put(showAlert({ message: error, type: 'error' }));
    return yield put(crActions.updateDatasetError(error));
  }
  yield put(showAlert({ message: 'Dataset updated', type: 'success' }));
  return yield put(crActions.updateDatasetSuccess(dataSet));
}

function* updateCrQuestionDescription(action) {
  const { description, error } = yield call(http.put, action.url, action.payload);
  if (error) {
    Logger.error(error, 'Clean Rooms: Update CR Question Description');
    yield put(showAlert({ message: error, type: 'error' }));
    return yield put(crActions.updateCrQuestionDescriptionError(error));
  }
  yield put(showAlert({ message: 'CR question description updated', type: 'success' }));
  return yield put(crActions.updateCrQuestionDescriptionSuccess(description));
}

function* fetchDataset(action) {
  const { dataSet, error } = yield call(http.get, action.url);
  if (error) {
    Logger.error(error, 'Clean Rooms: Fetching Dataset');
    return yield put(crActions.fetchDatasetError(error));
  }
  return yield put(crActions.fetchDatasetSuccess(dataSet));
}

function* fetchAndCacheDataset(action) {
  const { dataSet, error } = yield call(http.get, action.url);
  if (error) {
    Logger.error(error, 'Clean Rooms: Fetching Dataset');
    return yield put(crActions.fetchAndCacheDatasetError(error));
  }
  return yield put(crActions.fetchAndCacheDatasetSuccess(dataSet));
}

function* fetchCr(action) {
  const {
    cleanRoom, cleanRoomUser, cleanRoomType, error,
  } = yield call(http.get, action.url);
  if (error) {
    Logger.error(error, 'Clean Rooms: Fetching Clean Room Details');
    return yield put(crActions.fetchCrError(error));
  }
  PermissionService.initCrPermissions(cleanRoomUser.role?.rolePermissions || []);
  return yield put(crActions.fetchCrSuccess({ cleanRoom, cleanRoomUser, cleanRoomType }));
}

function* fetchCrQuestion(action) {
  const response = yield call(http.get, action.url);
  if (response.error) {
    Logger.error(response.error, 'Clean Rooms: Fetching Clean Room Question');
    yield put(showAlert({ message: response.error, type: 'error' }));
    return yield put(crActions.fetchCrQuestionError(response.error));
  }
  return yield put(crActions.fetchCrQuestionSuccess(response));
}

function* fetchCrList(action) {
  const { cleanRooms, error } = yield call(http.post, action.url, action.payload);
  if (error) {
    Logger.error(error, 'Clean Rooms: Fetching List Of Clean Rooms');
    return yield put(crActions.fetchCrListError(error));
  }
  return yield put(crActions.fetchCrListSuccess(cleanRooms));
}

function* fetchCrOrgUserList(action) {
  const { cleanRoomOrgUsers, error } = yield call(http.get, action.url, action.payload);
  if (error) {
    Logger.error(error, 'Clean Rooms: Fetching List Of Clean Room Org Users');
    return yield put(crActions.fetchCrUserOrgListError(error));
  }
  return yield put(crActions.fetchCrUserOrgListSuccess(cleanRoomOrgUsers));
}


function* fetchCrListFilters(action) {
  const { error, ...filters } = yield call(http.get, action.url);
  if (error) {
    Logger.error(error, 'Clean Rooms: Fetching List Of Clean Rooms Filters');
    return yield put(crActions.fetchCrListFiltersError(error));
  }
  return yield put(crActions.fetchCrListFiltersSuccess(filters));
}

function* fetchCrAllQuestions(action) {
  const { questions, error } = yield call(http.get, action.url, action.payload);
  if (error) {
    Logger.error(error, 'Clean Rooms: Fetching Available Questions');
    return yield put(crActions.fetchCrAvailableQuestionsError(error));
  }
  return yield put(crActions.fetchCrAvailableQuestionsSuccess(questions));
}

function* fetchCrCurrentQuestions(action) {
  const { questions, error } = yield call(http.get, action.url, action.payload);
  if (error) {
    Logger.error(error, 'Clean Rooms: Fetching Current Questions');
    return yield put(crActions.fetchCrCurrentQuestionsError(error));
  }
  return yield put(crActions.fetchCrCurrentQuestionsSuccess(questions));
}

function* fetchCrRunDetails(action) {
  const { details, error } = yield call(http.get, action.url);
  if (error) {
    Logger.error(error, 'Clean Rooms: Fetching Clean Room Question run details');
    return yield put(crActions.fetchCrRunDetailsError(error));
  }
  return yield put(crActions.fetchCrRunDetailsSuccess(details));
}

function* fetchCrDatasetGroups(action) {
  const { jobGroups, error } = yield call(http.get, action.url);
  if (error) {
    Logger.error(error, 'Clean Rooms: Fetching Dataset Groups');
    return yield put(crActions.fetchCrDatasetGroupsError(error));
  }
  return yield put(crActions.fetchCrDatasetGroupsSuccess(jobGroups));
}

function* fetchCrReportFilters(action) {
  const { filters, sorts, error } = yield call(http.get, action.url);

  if (error) {
    Logger.error(error, 'Clean Rooms: Fetching Report Filters');
    if (error !== 'file is too large to download') {
      yield put(showAlert({ message: error, type: 'error' }));
    }
    return yield put(crActions.fetchCrReportFiltersError(error));
  }
  return yield put(crActions.fetchCrReportFiltersSuccess({ filters, sorts }));
}

function* fetchQuestionDatasets(action) {
  const { questionDatasets, error } = yield call(http.get, action.url);
  if (error) {
    Logger.error(error, 'Clean Rooms: Fetching Question Datasets');
    yield put(showAlert({ message: error, type: 'error' }));
    return yield put(crActions.fetchCrQuestionDatasetsError(error));
  }
  return yield put(crActions.fetchCrQuestionDatasetsSuccess(questionDatasets));
}

function* fetchQuestionDataOptions(action) {
  const { dataTypes, error } = yield call(http.get, action.url);
  if (error) {
    Logger.error(error, 'Clean Rooms: Fetching Question Data Options');
    return yield put(crActions.fetchCrQuestionDataOptionsError(error));
  }
  return yield put(crActions.fetchCrQuestionDataOptionsSuccess(dataTypes));
}

function* fetchCrUsers(action) {
  const { users, error } = yield call(http.get, action.url, action.payload);
  if (error) {
    Logger.error(error, 'Clean Rooms: Fetching Users');
    return yield put(crActions.fetchCrUsersError(error));
  }
  return yield put(crActions.fetchCrUsersSuccess(users));
}

function* fetchQuestionDetails(action) {
  const { question, error } = yield call(http.get, action.url, action.payload);
  if (error) {
    Logger.error(error, 'Clean Rooms: Fetching Question Details');
    return yield put(crActions.fetchQuestionDetailsError(error));
  }
  return yield put(crActions.fetchQuestionDetailsSuccess(question));
}

function* getCrRunReport(action) {
  const response = yield call(http.post, action.url, action.payload);

  if (response.error) {
    Logger.error(response.error, 'Clean Rooms: Get Run Report');
    return yield put(crActions.getCrRunReportError(response.error));
  }
  response.report.formattedData = optimus(response.report, action.payload.filters);
  return yield put(crActions.getCrRunReportSuccess(response.report));
}

function* getCrRunTableReport(action) {
  const response = yield call(http.post, action.url, action.payload);

  if (response.error) {
    Logger.error(response.error, 'Clean Rooms: Get Full data Run');
    return yield put(crActions.getCrRunTableReportError(response.error));
  }
  return yield put(crActions.getCrRunTableReportSuccess(response));
}

function* fetchCrQuestionRun(action) {
  const response = yield call(http.get, action.url);
  if (response.error) {
    Logger.error(response.error, 'Clean Rooms: Get Question Run');
    return yield put(crActions.fetchQuestionRunError(response.error));
  }
  return yield put(crActions.fetchQuestionRunSuccess(response));
}

function* getCrRunFiles(action) {
  const { files, error } = yield call(http.get, action.url, action.payload);
  if (error) {
    Logger.error(error, 'Clean Rooms: Get Run Files');
    return yield put(crActions.getCrRunFilesError(error));
  }
  return yield put(crActions.getCrRunFilesSuccess(files));
}

function* getCrRunFilePreview(action) {
  const { output, error } = yield call(http.get, action.url, action.payload);
  if (error) {
    Logger.error(error, 'Clean Rooms: Get Run File Preview');
    return yield put(crActions.getCrRunFilePreviewError(error));
  }
  return yield put(crActions.getCrRunFilePreviewSuccess(output));
}

function* getCrRuns(action) {
  const response = yield call(http.post, action.url, action.payload);
  if (response.error) {
    Logger.error(response.error, 'Clean Rooms: Get Report Run List');
    return yield put(crActions.getCrRunsError(response.error));
  }
  return yield put(crActions.getCrRunsSuccess(response));
}

function* getDPBudgetAndQueries(action) {
  const response = yield call(http.get, action.url, action.payload);
  if (response.error) {
    Logger.error(response.error, 'Clean Rooms: Add DP enabled Clean room');
    return yield put(crActions.getDPBudgetAndQueriesError(response.error));
  }
  return yield put(crActions.getDPBudgetAndQueriesSuccess(response));
}

function* fetchCrRunsFilters(action) {
  const response = yield call(http.get, action.url);
  if (response.error) {
    yield put(showAlert({ type: 'error', message: 'Error fetching Cleanroom Runs Filters.' }));
    Logger.error(response.error, 'Clean Rooms: Could not fetch Clean room runs Filters');
    return yield put(crActions.fetchCrRunsFiltersError(response.error));
  }
  return yield put(crActions.fetchCrRunsFiltersSuccess(response));
}

function* deleteCrQuestion(action) {
  const { success, error } = yield call(http.delete, action.url);
  if (error) {
    Logger.error(error, 'Clean Rooms: Delete Question');
    return yield put(crActions.deleteCrQuestionError(error));
  }
  return yield put(crActions.deleteCrQuestionSuccess(success));
}

function* deleteCrQuestionRun(action) {
  const { success, error } = yield call(http.delete, action.url);
  if (error) {
    Logger.error(error, 'Clean Rooms: Delete Question Run');
    return yield put(crActions.deleteCrQuestionRunError(error));
  }
  return yield put(crActions.deleteCrQuestionRunSuccess(success));
}


function* removeCrUser(action) {
  const { success, error } = yield call(http.delete, action.url);
  if (error || !success) {
    Logger.error(error, 'Clean Rooms: Remove User');
    return yield put(crActions.removeCrUserError(error));
  }
  return yield put(crActions.removeCrUserSuccess(success));
}

function* saveCr(action) {
  const isNew = !(action.payload.cleanRoom.ID);
  const act = isNew ? 'created' : 'updating';
  const { cleanRoom, error } = (isNew) ? yield call(http.post, action.url, action.payload)
    : yield call(http.put, action.url, action.payload);
  if (error) {
    Logger.error(error, `Error ${act} the Clean Room. ${error}`);
    yield put(showAlert({ message: `Error ${act} the Clean Room.${error}`, type: 'error' }));
    return yield put(crActions.saveCrError(error));
  }
  yield put(showAlert({ message: `Sucessfully ${act} the Clean Room`, type: 'success' }));
  return yield put(crActions.saveCrSuccess(cleanRoom));
}

function* updateCrStatus(action) {
  const { error } = yield call(http.patch, action.url, action.payload);
  if (error) {
    Logger.error(error, 'Clean Rooms: Toggle Status');
    return yield put(crActions.updateCrStatusError(
      `Couldn't update status of Clean Room, ${action.meta.name}. ${habuSupport.message}`,
    ));
  }
  return null;
}

function* updateCrUser(action) {
  const { user, error } = yield call(http.put, action.url, action.payload);
  if (error) {
    Logger.error(error, 'Clean Rooms: Toggle Status');
    return yield put(crActions.updateCrUserError(error));
  }
  return yield put(crActions.updateCrUserSuccess(user));
}

function* updateCrQuestionStatus(action) {
  const { question, error } = yield call(http.patch, action.url, action.payload);
  if (error) {
    Logger.error(error, 'Clean Rooms: Update Question Status');
    return yield put(crActions.updateCrQuestionStatusError(
      `Couldn't update question status, ${action.meta.name}. ${habuSupport.message}`,
    ));
  }
  return yield put(crActions.updateCrQuestionStatusSuccess(question));
}

function* updateCrQuestionDatasets(action) {
  const { questionDatasets, error } = yield call(http.post, action.url, action.payload);
  if (error) {
    Logger.error(error, 'Clean Rooms: Update Question Datasets');
    yield put(showAlert({ message: error, type: 'error' }));
    return yield put(crActions.updateCrQuestionDatasetsError(error));
  }
  if (action.isFinalStep) {
    yield put(showAlert({ message: 'Datasets Successfully Assigned', type: 'success' }));
  }
  return yield put(crActions.updateCrQuestionDatasetsSuccess(questionDatasets));
}

/**
 * To fetch summary data for Clean Rooms
 * @param action { url: string }
 */
function* fetchCrSummary(action) {
  const { summaries, error } = yield call(http.get, action.url);
  if (error) {
    Logger.error(`Could not fetch CR summary. Url: ${action.url}. Error: ${error}`,
      'CR: Fetch report summary');
    yield put(showAlert({ message: error, type: 'error' }));
    return yield put(crActions.fetchCrSummaryError(error));
  }
  return yield put(crActions.fetchCrSummarySuccess(summaries));
}

function* verifyPartnerEmail(action) {
  const { valid, error } = yield call(http.post, action.url, action.payload);
  if (error) {
    return yield put(crActions.verifyPartnerEmailError(error));
  }
  return yield put(crActions.verifyPartnerEmailSuccess({ valid }));
}

function* verifyPartnerKey(action) {
  const { organization, error } = yield call(http.post, action.url, action.payload);
  if (error) {
    return yield put(crActions.verifyPartnerKeyError(error));
  }
  return yield put(crActions.verifyPartnerKeySuccess(organization));
}

export default function* root() {
  yield all([
    takeLatest(CLEAN_ROOM.ADD_QUESTION, addQuestion),
    takeLatest(CLEAN_ROOM.ADD_CR_USER, addCrUser),
    takeLatest(CLEAN_ROOM.CREATE_CR_DATASET, createDataset),
    takeLatest(CLEAN_ROOM.CREATE_NEW_RUN, createNewRun),
    takeLatest(CLEAN_ROOM.FETCH_SCHEDULED_RUN, fetchScheduledRun),
    takeLatest(CLEAN_ROOM.FETCH_SCHEDULED_RUNS, fetchScheduledRuns),
    takeLatest(CLEAN_ROOM.DELETE_CR_QUESTION, deleteCrQuestion),
    takeLatest(CLEAN_ROOM.DELETE_CR_QUESTION_RUN, deleteCrQuestionRun),
    takeLatest(CLEAN_ROOM.UPDATE_CR_DATASET, updateDataset),
    takeLatest(CLEAN_ROOM.UPDATE_CR_QUESTION_DETAILS, updateCrQuestionDescription),
    takeLatest(CLEAN_ROOM.UPDATE_CR_QUESTION_DETAILS_V2, updateCrQuestionDescription),
    takeLatest(CLEAN_ROOM.FETCH_CR_DATASET, fetchDataset),
    takeLatest(CLEAN_ROOM.FETCH_CR, fetchCr),
    takeLatest(CLEAN_ROOM.FETCH_CR_QUESTION, fetchCrQuestion),
    takeLatest(CLEAN_ROOM.FETCH_CR_QUESTION_V2, fetchCrQuestion),
    takeLatest(CLEAN_ROOM.FETCH_CR_LIST, fetchCrList),
    takeLatest(CLEAN_ROOM.FETCH_CR_ORG_USER_LIST, fetchCrOrgUserList),
    takeLatest(CLEAN_ROOM.FETCH_CR_LIST_FILTERS, fetchCrListFilters),
    takeLatest(CLEAN_ROOM.FETCH_CR_ALL_QUESTIONS, fetchCrAllQuestions),
    takeLatest(CLEAN_ROOM.FETCH_CR_CURRENT_QUESTIONS, fetchCrCurrentQuestions),
    takeLatest(CLEAN_ROOM.FETCH_CR_DATASET_GROUPS, fetchCrDatasetGroups),
    takeLatest(CLEAN_ROOM.FETCH_AND_CACHE_CR_DATASET, fetchAndCacheDataset),
    takeLatest(CLEAN_ROOM.FETCH_CR_RUN_DETAILS, fetchCrRunDetails),
    takeLeading(CLEAN_ROOM.FETCH_CR_REPORT_FILTERS, fetchCrReportFilters),
    takeLatest(CLEAN_ROOM.FETCH_CR_QUESTION_DATASETS, fetchQuestionDatasets),
    takeLatest(CLEAN_ROOM.FETCH_CR_QUESTION_DATA_OPTIONS, fetchQuestionDataOptions),
    takeLatest(CLEAN_ROOM.FETCH_CR_USERS, fetchCrUsers),
    takeLatest(CLEAN_ROOM.FETCH_QUESTION_DETAILS, fetchQuestionDetails),
    takeLatest(CLEAN_ROOM.GET_CR_RUN_REPORT, getCrRunReport),
    takeLatest(CLEAN_ROOM.GET_CR_RUN_TABLE_REPORT, getCrRunTableReport),
    takeLatest(CLEAN_ROOM.FETCH_QUESTION_RUN, fetchCrQuestionRun),
    takeLatest(CLEAN_ROOM.GET_CR_RUN_FILES, getCrRunFiles),
    takeLatest(CLEAN_ROOM.GET_CR_RUN_FILE_PREVIEW, getCrRunFilePreview),
    takeLatest(CLEAN_ROOM.FETCH_CR_RUNS_FILTERS, fetchCrRunsFilters),
    takeLatest(CLEAN_ROOM.GET_CR_RUNS, getCrRuns),
    takeLatest(CLEAN_ROOM.GET_DP_BUDGET_AND_QUERIES, getDPBudgetAndQueries),
    takeLatest(CLEAN_ROOM.REMOVE_CR_USER, removeCrUser),
    takeLatest(CLEAN_ROOM.SAVE_CR, saveCr),
    takeLatest(CLEAN_ROOM.UPDATE_CR_STATUS, updateCrStatus),
    takeLatest(CLEAN_ROOM.UPDATE_CR_QUESTION_DATASETS, updateCrQuestionDatasets),
    takeLatest(CLEAN_ROOM.UPDATE_CR_USER, updateCrUser),
    takeLatest(CLEAN_ROOM.UPDATE_CR_QUESTION_STATUS, updateCrQuestionStatus),
    takeLatest(CLEAN_ROOM.FETCH_CR_SUMMARY, fetchCrSummary),
    takeLatest(CLEAN_ROOM.VERIFY_PARTNER_EMAIL, verifyPartnerEmail),
    takeLatest(CLEAN_ROOM.VERIFY_PARTNER_KEY, verifyPartnerKey),
  ]);
}

export {
  addQuestion,
  createNewRun,
  fetchCr,
  fetchCrList,
  fetchCrAllQuestions,
  fetchCrDatasetGroups,
  fetchCrReportFilters,
  fetchCrSummary,
  fetchCrQuestion,
  fetchQuestionDetails,
  getCrRunReport,
  getCrRuns,
  getDPBudgetAndQueries,
  saveCr,
  updateCrQuestionDescription,
  updateCrQuestionStatus,
  updateCrStatus,
};
