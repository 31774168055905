import * as spaUrls from 'utils/spaUrls';
import FlowListView from 'pages/Flow/FlowListView';
import NavbarLayout from 'components/Layouts/NavbarLayout';
import PrivateRoute from 'components/Common/PrivateRoute';
import React from 'react';
import { FlowBuilder } from 'pages/Flow/FlowBuilder';
import { Switch } from 'react-router-dom';
import { userTypes } from 'utils/appConstants';


const FlowRouter = () => (
  <Switch>
    <PrivateRoute
      path={spaUrls.flow.landing}
      component={FlowListView}
      allowList={[userTypes.superuser]}
      exact
    />
    <PrivateRoute
      path={spaUrls.flow.builder()}
      component={FlowBuilder}
      allowList={[userTypes.superuser]}
      exact
    />
  </Switch>
);

export default NavbarLayout(FlowRouter);
