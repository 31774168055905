import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import ListViewTable from 'components/Common/ListViewTable';
import React from 'react';
import SectionHeader from 'components/IAM/Admin/SectionHeader';
import SyntheticDataLocationTable from 'components/InternalAdmin/SyntheticDataLocationTable';
import { HButton } from 'BaseComponents';


const DestinationModal = ({ onClose, modalDetails }) => (
  <Dialog
    open={Boolean(modalDetails)}
    onClose={onClose}
    fullWidth
    maxWidth='md'
  >
    <Card>
      <CardContent>
        <SectionHeader title='Destinations' />
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <ListViewTable list={modalDetails}>
              <SyntheticDataLocationTable list={modalDetails} />
            </ListViewTable>
          </Grid>
        </Grid>
        <Box display='flex' justifyContent='flex-end'>
          <CardActions>
            <HButton onClick={onClose} variant='contained' color='inherit'>Close</HButton>
          </CardActions>
        </Box>
      </CardContent>
    </Card>
  </Dialog>
);

export default DestinationModal;
