import React from 'react';
import {
  OrganizationRolesContextProvider,
} from 'pages/OrganizationManagement/organization/pages/OrganizationRoles/OrganizationRoleTable/OrganizationRolesContextProvider';
import {
  OrganizationRolesPageLayout,
} from 'pages/OrganizationManagement/organization/pages/OrganizationRoles/OrganizationRoleTable/OrganizationRolesPageLayout';


export const OrganizationRoleTable = ({ list }) => (
  <OrganizationRolesContextProvider
    list={list}
  >
    <OrganizationRolesPageLayout />
  </OrganizationRolesContextProvider>
);
