import Grid from '@mui/material/Grid';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import React, { memo, useContext } from 'react';
import { ActionCellStyles } from 'BaseComponents/HDataTable/utils/styles';
import { CellShadowStyles } from 'BaseComponents/HDataTable/HTable';
import { DataContext } from 'BaseComponents/HDataTable/DataContextProvider';
import { DropdownMenu } from 'BaseComponents/DropdownMenu';
import { ResizableContext } from 'BaseComponents/HDataTable/ResizableContextProvider';
import { styled } from '@mui/material/styles';


const StyledTd = styled('td', {
  shouldForwardProp: (prop) => prop !== 'isTableFullSize',
})(({ isTableFullSize }) => ({
  ...ActionCellStyles({ isTableFullSize }),
  textAlign: 'left',
  '& .shadow': {
    ...CellShadowStyles,
  },
}));

const StyledActionGrid = styled(Grid)({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
});

export const HActionCell = memo(({ row }) => {
  const { isTableFullSize } = useContext(ResizableContext);

  const { actionMenu, actionButton: ActionButton } = useContext(DataContext);

  if (!actionMenu && !ActionButton) return null;

  return (
    <>
      <StyledTd isTableFullSize={isTableFullSize}>
        <StyledActionGrid>
          {isTableFullSize && <div className='shadow' />}

          {ActionButton && <ActionButton row={row} />}

          {actionMenu && <DropdownMenu icon={<MoreHorizIcon />} menuItems={actionMenu(row)} />}
        </StyledActionGrid>
      </StyledTd>
      {!isTableFullSize && <td />}
    </>
  );
});

HActionCell.displayName = 'HActionCell';
