import Logger from 'components/Common/Logger';
import http from 'utils/http';
import {
  call, put,
} from 'redux-saga/effects';
import { deleteFlowNodeError, deleteFlowNodeSuccess } from 'redux/actions/FlowAction';
import { showAlert } from 'redux/actions/AlertActions';


export function* deleteQuestionNode({ url, payload }) {
  const { error } = yield call(http.delete, url);

  if (error) {
    Logger.error(error, `Could not delete node. Url: ${url}. Error: ${error}`);
    yield put(showAlert({ message: error, type: 'error' }));
    return yield put(deleteFlowNodeError(payload));
  }

  yield put(showAlert({ message: 'Node successfully deleted', type: 'success' }));
  return yield put(deleteFlowNodeSuccess(payload));
}
