import Store from 'Store';
import localStorage from 'utils/localStorage';
import { CACHE } from 'utils/appConstants';
import { auth, homepage } from 'utils/spaUrls';
import { authTokenExpired, refreshToken } from 'redux/actions/AuthenticationActions';


const AuthService = {
  isAuthenticated: 'unsure',

  authenticate() {
    this.isAuthenticated = 'true';
  },

  getAuthStatus(routerHistory, location) {
    const land = location.pathname + location.search || homepage;
    if (this.isAuthenticated === 'true') { return true; }
    else if (this.isAuthenticated === 'unsure') {
      const payload = { authToken: localStorage.get(CACHE.user.jwtRefreshToken) };
      if (!payload.authToken || payload.authToken === '') {
        return false;
      }
      else {
        Store.dispatch(refreshToken(payload, (data) => {
          if (!data || data.error) {
            this.isAuthenticated = false;
            routerHistory.push(auth.login);
          }
          else if (data.user && data.user.ID) {
            this.isAuthenticated = 'true';
            routerHistory.push(land);
          }
          else {
            this.isAuthenticated = false;
            routerHistory.push(auth.login);
          }
        }));
        return 'unsure';
      }
    }
    else {
      return false;
    }
  },

  logout() {
    this.isAuthenticated = 'false';
  },

  handle401Error() {
    Store.dispatch(authTokenExpired());
  },

  handleExpiredRefreshToken() {
    Store.dispatch(refreshToken());
  },
};

export default AuthService;
