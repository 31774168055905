import React from 'react';
import { FALLBACK_QUESTION_VERSION } from 'utils/constants/question.constant';
import { activeOrgSelector } from 'redux/reducer/AuthenticationReducer';
import { axiosInstance } from 'utils/http';
import { cleanRoom, question, questionManagement } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation, useQuery } from 'react-query';


const useCreateQuestion = () => {
  const dispatch = useDispatch();
  const [data, setData] = React.useState([]);
  const {
    isLoading, isError, isSuccess, mutate, mutateAsync,
  } = useMutation(
    async ({ payload }) => axiosInstance.post(question().create, payload),
    {
      onSuccess: (res) => setData(res.data),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    data,
    mutate,
    mutateAsync,
  };
};

const useFetchCleanRoomQuestionList = (cleanRoomID) => {
  const dispatch = useDispatch();
  const [data, setData] = React.useState([]);
  const orgID = useSelector(state => state.session.user.primaryOrganization.organization.ID);
  const {
    isLoading, isError, isSuccess, mutate, reset,
  } = useMutation(
    'FetchCleanRoomQuestionList',
    async (payload) => axiosInstance.post(cleanRoom(orgID).questionList(cleanRoomID), payload),
    {
      refetchOnWindowFocus: false,
      onSuccess: (res) => setData(res.data),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    mutate,
    data,
    reset,
  };
};

const useFetchCleanRoomAvailableQuestionList = (cleanRoomID) => {
  const dispatch = useDispatch();
  const [data, setData] = React.useState([]);
  const orgID = useSelector(state => state.session.user.primaryOrganization.organization.ID);
  const {
    isLoading, isError, isSuccess, mutate, reset,
  } = useMutation(
    'FetchCleanRoomQuestionList',
    async (payload) => axiosInstance.post(
      cleanRoom(orgID).listAvailableQuestions(cleanRoomID), payload,
    ),
    {
      refetchOnWindowFocus: false,
      onSuccess: (res) => setData(res.data),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    mutate,
    data,
    reset,
  };
};

const useFetchAvailableQuestionFlow = (cleanRoomID) => {
  const { ID: orgId } = useSelector(activeOrgSelector);
  const dispatch = useDispatch();

  return useQuery(
    ['availableQuestions', orgId, cleanRoomID],
    () => axiosInstance.get(
      cleanRoom(orgId).listAvailableQuestionsFlow(cleanRoomID)).then(res => res.data),
    {
      refetchOnWindowFocus: false,
      onError: (err) => {
        dispatch(showAlert({
          message: err.response ? err.response.data.message ?? err.response.statusText : '',
          type: 'error',
        }));
      },
    },
  );
};

const useFetchCrCurrentQuestions = (orgId, cleanRoomId) => {
  const dispatch = useDispatch();
  const [data, setData] = React.useState([]);
  const {
    isLoading, isError, isSuccess, refetch, isFetching,
  } = useQuery(
    ['CleanRoomDatasets', orgId, cleanRoomId],
    async () => axiosInstance.get(cleanRoom(orgId).listCurrentQuestions(cleanRoomId)),
    {
      enabled: !!cleanRoomId,
      onSuccess: (res) => setData(res.data.questions),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    data,
    refetch,
    isFetching,
  };
};

const useFetchCleanRoomQuestionFilters = (cleanRoomId) => {
  const dispatch = useDispatch();
  const orgId = useSelector(state => state.session.user.primaryOrganization.organization.ID);
  const [data, setData] = React.useState([]);
  const {
    isError, isSuccess, isFetching,
  } = useQuery(
    ['CleanRoomQuestionFilters', orgId, cleanRoomId],
    async () => axiosInstance.get(cleanRoom(orgId).questionFilters(cleanRoomId)),
    {
      refetchOnWindowFocus: false,
      enabled: !!cleanRoomId,
      onSuccess: (res) => setData(res.data),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isError,
    isSuccess,
    isFetching,
    data,
  };
};

const useFetchCleanRoomDatasets = (cleanRoomId) => {
  const dispatch = useDispatch();
  const orgId = useSelector(state => state?.session?.user?.primaryOrganization?.organization?.ID);
  const [data, setData] = React.useState([]);
  const { isLoading, isError, isSuccess } = useQuery(
    ['CleanRoomDatasets', cleanRoomId],
    async () => axiosInstance.get(question(orgId).datasets(cleanRoomId)),
    {
      enabled: !!cleanRoomId,
      refetchOnWindowFocus: false,
      onSuccess: (res) => setData(res.data.datasets),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    data,
  };
};

const useFetchCleanRoomQuestionParameters = (cleanRoomQuestionID) => {
  const dispatch = useDispatch();

  return useQuery(
    ['CleanRoomQuestionParameters', cleanRoomQuestionID],
    async () => axiosInstance.get(question().crqParameters(cleanRoomQuestionID)),
    {
      enabled: !!cleanRoomQuestionID,
      refetchOnWindowFocus: false,
      select: (res) => res?.data?.cleanRoomQuestionParameters || {},
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );
};

const useFetchQuestion = (questionId, crId, version = FALLBACK_QUESTION_VERSION) => {
  const dispatch = useDispatch();
  const [data, setData] = React.useState({});
  const params = crId ? { cleanRoomID: crId } : {};
  params.version = version;

  const {
    isLoading, isFetching, isError, isSuccess,
  } = useQuery(
    ['QuestionDetail', questionId, version],
    async () => axiosInstance.get(question().detail(questionId), {
      params,
    }),
    {
      enabled: !!questionId,
      refetchOnWindowFocus: false,
      onSuccess: (res) => setData(res.data.question),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading,
    isFetching,
    isError,
    isSuccess,
    data,
  };
};

const usePublishQuestion = () => {
  const dispatch = useDispatch();
  const [data, setData] = React.useState([]);
  const {
    isLoading, isError, isSuccess, mutate, reset,
  } = useMutation(
    async ({ questionId }) => axiosInstance.patch(question().publish(questionId)),
    {
      onSuccess: (res) => {
        setData(res.data.question);
        dispatch(showAlert({ message: 'Question has been published', type: 'success' }));
      },
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    data,
    mutate,
    reset,
  };
};

const useUpdateQuestion = () => {
  const dispatch = useDispatch();
  const [data, setData] = React.useState([]);
  const {
    isLoading, isError, isSuccess, mutate, mutateAsync,
  } = useMutation(
    async ({ questionId, payload }) => {
      await axiosInstance.put(question().detail(questionId), payload);
    },
    {
      onSuccess: (res) => setData(res?.data),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    data,
    mutate,
    mutateAsync,
  };
};

const useValidate = () => {
  const dispatch = useDispatch();
  const [data, setData] = React.useState([]);
  const {
    isLoading, isError, isSuccess, mutate,
  } = useMutation(
    async ({ payload }) => axiosInstance.post(question().validate, payload),
    {
      onSuccess: (res) => setData(res.data),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    data,
    mutate,
  };
};

const useVerify = () => {
  const dispatch = useDispatch();
  const [data, setData] = React.useState([]);
  const {
    isLoading, isError, isSuccess, mutate,
  } = useMutation(
    async ({ payload }) => axiosInstance.post(question().verify, payload),
    {
      onSuccess: (res) => setData(res.data),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    data,
    mutate,
  };
};

const useFetchQueryPackages = ({
  isAuthorized, languageId, orgId,
}) => {
  const dispatch = useDispatch();
  const {
    isLoading, isError, isSuccess, isFetching, data,
  } = useQuery(
    ['QueryLanguagePackages', orgId, languageId],
    async () => axiosInstance.get(
      question(orgId).queryPackages(languageId),
    ),
    {
      enabled: Boolean(
        isAuthorized
        && orgId
        && languageId,
      ),
      // This API is marked for infinite stale time because
      // 1. the return data won't change over the period of time
      // 2. we want to cache the data because of it's size
      // 3. caching the data improves the UI performance and unnecessary API calls
      staleTime: Infinity,
      select: (res) => res?.data?.queryPackages || [],
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    isFetching,
    data,
  };
};

const useExplainQuery = () => {
  const [data, setData] = React.useState([]);
  const {
    isLoading, isError, isSuccess, mutate,
  } = useMutation(
    async (payload) => axiosInstance.post(question().explain, payload),
    {
      onSuccess: (res) => setData(res?.data?.explanation || ''),
      onError: (err) => {
        const errorMessage = err?.response?.data?.message || 'Error while fetching query explanation';
        setData(errorMessage);
      },
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    data,
    mutate,
  };
};

const useGenerateDescription = () => {
  const dispatch = useDispatch();
  const [data, setData] = React.useState([]);
  const {
    isLoading, isError, isSuccess, mutateAsync,
  } = useMutation(
    async (payload) => axiosInstance.post(question().describe, payload),
    {
      onSuccess: (res) => setData(res?.data || ''),
      onError: (err) => dispatch(
        showAlert(
          {
            message: err.response.data.message || 'Error while generating query description',
            type: 'error',
          },
        ),
      ),
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    data,
    mutateAsync,
  };
};

const useFetchQuestionSharedPartners = (orgId, cleanRoomId, questionId) => {
  const dispatch = useDispatch();
  const [data, setData] = React.useState([]);
  const {
    isLoading, isError, isSuccess, isFetching,
  } = useQuery(
    ['QuestionSharedPartners', orgId, cleanRoomId],
    async () => axiosInstance.get(question(orgId).sharedPartners(cleanRoomId, questionId)),
    {
      enabled: !!(orgId && cleanRoomId && questionId),
      onSuccess: (res) => setData(res.data.resultShares),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    data,
    isFetching,
  };
};

const useShareQuestionResults = (onSuccess) => {
  const dispatch = useDispatch();
  const {
    isLoading, isError, isSuccess, mutate,
  } = useMutation(
    async ({
      orgId, crId, questionId, payload,
    }) => axiosInstance.put(
      question(orgId).shareResults(crId, questionId),
      payload,
    ),
    {
      onSuccess: () => {
        onSuccess?.();
        dispatch(showAlert({ message: 'Results shared successfully', type: 'success' }));
      },
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    mutate,
  };
};

const useFetchHISchemaInfo = (orgId, crId, crQuestionId) => {
  const dispatch = useDispatch();
  const [data, setData] = React.useState([]);
  const {
    isLoading, isError, isSuccess, refetch, isFetching,
  } = useQuery(
    ['HISchemaInfo', orgId, crId, crQuestionId],
    async () => axiosInstance.get(cleanRoom(orgId).getHISchemaInfo(crId, crQuestionId)),
    {
      enabled: !!crQuestionId && !!crId,
      refetchOnWindowFocus: false,
      onSuccess: (res) => setData(res.data.hiSchemaInfo),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    data,
    refetch,
    isFetching,
  };
};

const useFetchHISchemaInfoList = (orgId, crId, payload) => {
  const dispatch = useDispatch();
  const [data, setData] = React.useState([]);
  const {
    isLoading, isError, isSuccess, refetch, isFetching,
  } = useQuery(
    ['HISchemaInfoList', orgId, crId],
    () => axiosInstance.post(cleanRoom(orgId).getHISchemaInfoList(crId), payload),
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onSuccess: (res) => setData(res.data.hiSchemaInfo),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    data,
    refetch,
    isFetching,
  };
};

const useImproveQueryWithAI = () => {
  const dispatch = useDispatch();
  const [data, setData] = React.useState([]);
  const {
    isLoading, isError, isSuccess, mutate,
  } = useMutation(
    async (payload) => axiosInstance.post(question().improveQueryWithAI, payload),
    {
      onSuccess: (res) => setData(res.data.suggestedQuery),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    data,
    mutate,
  };
};

const useValidateOutput = () => {
  const dispatch = useDispatch();
  const [data, setData] = React.useState([]);
  const {
    isLoading, isError, isSuccess, mutate,
  } = useMutation(
    async ({ payload }) => axiosInstance.post(question().validateOutput, payload),
    {
      onSuccess: (res) => setData(res.data),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    data,
    mutate,
  };
};

const useEnforceValidation = () => useMutation(
  {
    mutationFn: ({ payload }) => axiosInstance.post(question().validateOutput, payload),
  },
);

const useRequestQuestion = (crId) => {
  const dispatch = useDispatch();

  return useMutation(
    'useRequestQuestion',
    (qId) => axiosInstance.put(questionManagement.requestQuestion(crId, qId)),
    {
      onSuccess: () => {
        dispatch(showAlert({
          message: 'Question was successfully requested.',
          type: 'success',
        }));
      },
      onError: (err) => {
        dispatch(showAlert({
          message: err.response.data.message ?? err.response.statusText,
          type: 'error',
        }));
      },
    },
  );
};

const useRemoveQuestion = (entityType, crId) => {
  const dispatch = useDispatch();

  return useMutation(
    'useRemoveQuestion',
    (qId) => axiosInstance.put(
      questionManagement.deprovisionQuestion(entityType, crId, qId),
    ),
    {
      onSuccess: () => {
        dispatch(showAlert({
          message: 'Question was successfully removed.',
          type: 'success',
        }));
      },
      onError: (err) => {
        dispatch(showAlert({
          message: err.response.data.message ?? err.response.statusText,
          type: 'error',
        }));
      },
    },
  );
};

const useGetGenAIResults = () => {
  const [data, setData] = React.useState();
  const {
    isLoading, isError, isSuccess, mutate,
  } = useMutation(
    ({ payload }) => axiosInstance.post(question().getGenAIResult, payload),
    {
      onSuccess: (res) => setData(res.data),
      onError: (err) => setData({ output: err?.response?.data?.message || 'Error while generating results.' }),
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    data,
    mutate,
  };
};

const useEndGenAISession = () => {
  const dispatch = useDispatch();

  const {
    isLoading, isError, isSuccess, mutate,
  } = useMutation(
    ({ payload }) => axiosInstance.post(
      question().getGenAIResult,
      { ...payload, end_session: true },
    ),
    {
      onSuccess: () => { },
      onError: (err) => {
        dispatch(showAlert({
          message: err?.response?.data?.message
            || 'Error while closing Habu Assistants\'s current session.',
          type: 'error',
        }));
      },
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    mutate,
  };
};

const useFetchHIProvisionedCRQs = (orgId, crId) => {
  const dispatch = useDispatch();
  const {
    isLoading, isError, isSuccess, data,
  } = useQuery(
    ['HIProvisionedCRQs', orgId, crId],
    async () => axiosInstance.get(cleanRoom(orgId).hiProvisionedCRQs(crId)),
    {
      enabled: !!orgId && !!crId,
      select: (res) => res?.data?.questions || [],
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    data,
  };
};

const useGetCrQuestion = (crId, questionId, cleanRoomQuestionID) => {
  const dispatch = useDispatch();
  const { ID: orgId } = useSelector(activeOrgSelector);

  return useQuery(
    ['getCleanroomQuestion', orgId, crId],
    () => axiosInstance.get(
      cleanRoomQuestionID
        ? cleanRoom(orgId).getCleanRoomQuestionV2(crId, cleanRoomQuestionID)
        : cleanRoom(orgId).getCleanRoomQuestion(crId, questionId)),
    {
      enabled: !!crId && !!questionId,
      select: (res) => res?.data || {},
      refetchOnWindowFocus: false,
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );
};

const useGetCrQuestionV2 = (crId, cleanRoomQuestionID) => {
  const dispatch = useDispatch();
  const { ID: orgId } = useSelector(activeOrgSelector);

  return useQuery(
    ['getCleanroomQuestionV2', orgId, crId],
    () => axiosInstance.get(cleanRoom(orgId).getCleanRoomQuestionV2(crId, cleanRoomQuestionID)),
    {
      enabled: !!crId && !!cleanRoomQuestionID,
      select: (res) => res?.data || {},
      refetchOnWindowFocus: false,
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );
};

const useFetchComputeCapacity = (questionId, crqId) => {
  const dispatch = useDispatch();
  const [data, setData] = React.useState([]);
  const params = crqId ? { cleanRoomQuestionID: crqId } : {};

  const {
    isLoading, isFetching, isError, isSuccess,
  } = useQuery(
    ['ComputeCapacity', questionId],
    async () => axiosInstance.get(question().computeCapacity(questionId), {
      params,
    }),
    {
      enabled: !!questionId,
      refetchOnWindowFocus: false,
      onSuccess: (res) => setData(res.data),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading,
    isFetching,
    isError,
    isSuccess,
    data,
  };
};

const useUpdateComputeCapacity = () => {
  const dispatch = useDispatch();
  const {
    isLoading, isError, isSuccess, mutate, reset,
  } = useMutation(
    async ({ questionID, payload }) => axiosInstance
      .patch(question().computeCapacity(questionID), payload),
    {
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    mutate,
    reset,
  };
};

const useFetchRunResultShare = (crId, runId) => {
  const dispatch = useDispatch();
  const { ID: orgId } = useSelector(activeOrgSelector);


  return useQuery(
    [`useFetchRunResultShare${crId}${runId}`],
    () => axiosInstance.get(cleanRoom(orgId).getQuestionRunResultShare(crId, runId)),
    {
      select: (res) => res?.data || {},
      refetchOnWindowFocus: false,
      onError: (err) => {
        dispatch(showAlert({
          message: err.response ? err.response.data.message ?? err.response.statusText : '',
          type: 'error',
        }));
      },
    },
  );
};

const useFetchQuestionVersions = ({
  questionId, enabled = true,
}) => {
  const orgId = useSelector(state => state.session.user.primaryOrganization.organization.ID);
  const dispatch = useDispatch();

  return useQuery(
    ['QuestionVersions', orgId, questionId],
    async () => axiosInstance.get(question(orgId).questionVersions(questionId)),
    {
      enabled: Boolean(orgId && enabled),
      select: (res) => [...res?.data?.availableQuestionVersions] || [1],
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );
};

export {
  useCreateQuestion,
  useFetchCrCurrentQuestions,
  useFetchCleanRoomDatasets,
  useFetchCleanRoomQuestionFilters,
  useFetchCleanRoomQuestionList,
  useFetchCleanRoomQuestionParameters,
  useFetchCleanRoomAvailableQuestionList,
  useFetchQuestion,
  usePublishQuestion,
  useUpdateQuestion,
  useValidate,
  useVerify,
  useFetchQueryPackages,
  useExplainQuery,
  useGenerateDescription,
  useFetchQuestionSharedPartners,
  useShareQuestionResults,
  useFetchHISchemaInfo,
  useFetchHISchemaInfoList,
  useImproveQueryWithAI,
  useValidateOutput,
  useRequestQuestion,
  useRemoveQuestion,
  useGetGenAIResults,
  useEndGenAISession,
  useFetchHIProvisionedCRQs,
  useFetchAvailableQuestionFlow,
  useGetCrQuestion,
  useGetCrQuestionV2,
  useFetchComputeCapacity,
  useUpdateComputeCapacity,
  useEnforceValidation,
  useFetchRunResultShare,
  useFetchQuestionVersions,
};
