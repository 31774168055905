import React, { useContext } from 'react';
import {
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { DataSetDropDown } from 'pages/Flow/QuestionNodesEdgeConnectionDialog/DataSetDropDown';
import { NodesEdgeConnectionContext } from 'pages/Flow/QuestionNodesEdgeConnectionDialog/NodesEdgeConnectionProvider';


export const DatasetMappingTable = ({
  dataSetTypeFieldMap,
}) => {
  const { edgePayload: macroMapping } = useContext(NodesEdgeConnectionContext);

  const noMacrosToMap = dataSetTypeFieldMap.length === 0;

  if (noMacrosToMap) {
    return <Alert severity='success'>No Field Macros available to map</Alert>;
  }

  return (
    <TableContainer sx={{ maxHeight: 450 }}>
      <Table stickyHeader size='small'>
        <TableHead>
          <TableRow>
            <TableCell><Typography variant='subtitle2'>Field</Typography></TableCell>
            <TableCell><Typography variant='subtitle2'>Map To</Typography></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {macroMapping.macros.map((row, index) => (
            <TableRow
              key={row.dataSourceField.ID}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell sx={{
                minWidth: '50%',
                wordBreak: 'break-word',
              }}
              >
                <Typography variant='body3'>{`@${row.dataSourceField.name}`}</Typography>
                <br />
                <Typography variant='body3'>{row.dataSourceField.type.toLowerCase()}</Typography>
              </TableCell>
              <TableCell sx={{ maxWidth: '40%' }}>
                <DataSetDropDown
                  row={row}
                  type={row.dataSourceField.type}
                  index={index}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
