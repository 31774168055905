import Grid from '@mui/material/Grid';
import React from 'react';
import Typography from '@mui/material/Typography';
import { BlankCard, CardHeaderStatus } from 'components/Common/CardStructures';
import { DropdownMenu } from 'BaseComponents';
import { FLOW_STAGE } from 'utils/ignoramusEnums';
import { FlowCardActionButtons } from 'pages/Flow/FlowCard/ActionButtons';
import { MoreHoriz } from '@mui/icons-material';
import { useActionItems } from 'pages/Flow/FlowCard/useActionItems';


export const FlowCard = ({ details }) => {
  const flow = details.flow || {};
  const flowCardActions = useActionItems();

  return (
    <BlankCard
      header={(
        <Grid container spacing={1} direction='row' justifyContent='space-between'>
          <Grid item xs={6}>
            <CardHeaderStatus
              value={FLOW_STAGE[details.stage]?.label}
              textColor={FLOW_STAGE[details.stage]?.stageTextColor}
            />
          </Grid>
          <Grid item xs={6} sx={{ textAlign: 'end' }}>
            <DropdownMenu
              icon={<MoreHoriz fontSize='small' />}
              menuItems={flowCardActions({ details })}
            />
          </Grid>
        </Grid>
      )}
      headerColor={FLOW_STAGE[details.stage]?.color}
      actionButtons={(
        <FlowCardActionButtons flowDetails={details} />
      )}
    >
      <Grid container spacing={2} sx={{ px: 2, pt: 2 }}>
        <Grid item xs={12}>
          <Typography variant='subtitle1'>
            {flow.displayID}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='subtitle1'>
            {flow.name}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='body1'>
            {flow.description}
          </Typography>
        </Grid>
      </Grid>
    </BlankCard>
  );
};
