export const EXPERIMENTS = {
  CREATE_EXP: 'CREATE_EXP',
  FETCH_EXP: 'FETCH_EXP',
  FETCH_EXP_ERROR: 'FETCH_EXP_ERROR',
  FETCH_EXP_SUCCESS: 'FETCH_EXP_SUCCESS',
  FETCH_EXP_LIST: 'FETCH_EXP_LIST',
  FETCH_EXP_LIST_ERROR: 'FETCH_EXP_LIST_ERROR',
  FETCH_EXP_LIST_SUCCESS: 'FETCH_EXP_LIST_SUCCESS',
  FETCH_EXP_FILTERS: 'FETCH_EXP_FILTERS',
  FETCH_EXP_FILTERS_ERROR: 'FETCH_EXP_FILTERS_ERROR',
  FETCH_EXP_FILTERS_SUCCESS: 'FETCH_EXP_FILTERS_SUCCESS',
  FETCH_AUDIENCES: 'FETCH_AUDIENCES',
  FETCH_AUDIENCES_ERROR: 'FETCH_AUDIENCES_ERROR',
  FETCH_AUDIENCES_SUCCESS: 'FETCH_AUDIENCES_SUCCESS',
  FETCH_EXP_PARTNER_CONNECTIONS: 'FETCH_EXP_PARTNER_CONNECTIONS',
  FETCH_EXP_PARTNER_CONNECTIONS_ERROR: 'FETCH_EXP_PARTNER_CONNECTIONS_ERROR',
  FETCH_EXP_PARTNER_CONNECTIONS_SUCCESS: 'FETCH_EXP_PARTNER_CONNECTIONS_SUCCESS',
  FETCH_EXP_SUMMARIES: 'FETCH_EXP_SUMMARIES',
  FETCH_EXP_SUMMARIES_ERROR: 'FETCH_EXP_SUMMARIES_ERROR',
  FETCH_EXP_SUMMARIES_SUCCESS: 'FETCH_EXP_SUMMARIES_SUCCESS',
  SAVE_EXP: 'SAVE_EXP',
  SAVE_EXP_ERROR: 'SAVE_EXP_ERROR',
  SAVE_EXP_SUCCESS: 'SAVE_EXP_SUCCESS',
  UPDATE_EXP_STATUS: 'UPDATE_EXP_STATUS',
  UPDATE_EXP_STATUS_ERROR: 'UPDATE_EXP_STATUS_ERROR',
  UPDATE_EXP_STATUS_SUCCESS: 'UPDATE_EXP_STATUS_SUCCESS',
  END_EXP: 'END_EXP',
  END_EXP_ERROR: 'END_EXP_ERROR',
  END_EXP_SUCCESS: 'END_EXP_SUCCESS',
};
