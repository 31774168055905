import Grid from '@mui/material/Grid';
import React from 'react';
import { ChartSubTypeMap, VisualizationTypesMap } from 'components/CleanRooms/constants';
import { CrQuestionContext } from 'pages/InternalAdmin/application/pages/CleanRoomQuestion/CrQuestionFormBuilder';
import { QuestionSelectField } from 'components/InternalAdmin/QuestionConfigure/QuestionRenderer';


const chartTypes = Object.values(ChartSubTypeMap).filter(k => k.key !== 'COLUMN')
  .map(i => ({ name: i.label, value: i.key }));


const ChartSubType = ({ metric, onChange }) => {
  const question = React.useContext(CrQuestionContext);
  const { visualization } = question;
  return (
    <>
      {visualization === VisualizationTypesMap.BAR_LINE_CHART.key
      && !metric.metricConfig?.tabular && (
        <Grid item xs={4} md={3} lg={2}>
          <QuestionSelectField
            fieldLabel='Chart Type'
            list={chartTypes}
            onChange={onChange}
            required
            value={metric.metricConfig?.chartType}
          />
        </Grid>
      )}
    </>
  );
};

export default ChartSubType;
