import { cleanRoomDesignToken } from 'designToken';


export const CSS_PARAMETER_NAMES = ['NAV_BAR_BG_COLOR', 'NAV_BAR_BG_COLOR_HOVER', 'NAV_BAR_COLOR'];

export const DEFAULT_CSS_PARAMETERS = [
  { name: 'NAV_BAR_BG_COLOR', value: cleanRoomDesignToken.colorPrimary },
  { name: 'NAV_BAR_BG_COLOR_HOVER', value: cleanRoomDesignToken.colorPrimaryDarker },
  { name: 'NAV_BAR_COLOR', value: cleanRoomDesignToken.fontColor },
];

export const DEFAULT_FONT_PARAMETER = [
  { name: 'FONT', value: cleanRoomDesignToken.fontOptions[0] },
];

export const ORGANIZATION_CUSTOMIZATION_PARAMETER_NAME_MAP = {
  'Header Color': 'header_color',
  'Header Background Color': 'header_background_color',
  'Header Background Color Hover': 'header_background_color_hover',
};

export const ORGANIZATION_CUSTOMIZATION_PARAMETER_OPTIONS = {
  NAV_BAR_BG_COLOR: { key: 'NAV_BAR_BG_COLOR', name: 'Navigation Bar Background Color' },
  NAV_BAR_BG_COLOR_HOVER: { key: 'NAV_BAR_BG_COLOR_HOVER', name: 'Navigation Bar Background Color Hover' },
  NAV_BAR_COLOR: { key: 'NAV_BAR_COLOR', name: 'Navigation Bar Color' },
  FONT: { key: 'FONT', name: 'Font' },
  LOGO_URL: { key: 'LOGO_URL', name: 'Logo' },
  PRE_SIGNED_LOGO_URL: { key: 'PRE_SIGNED_LOGO_URL', name: 'Logo' },
};
