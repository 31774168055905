import React, { memo } from 'react';
import { CellTypes } from 'BaseComponents/HDataTable/CellTypes';


export const HBodyCell = memo(({
  children = undefined,
  ...props
}) => (
  <td>
    <CellTypes {...props}>
      {children}
    </CellTypes>
  </td>
));

HBodyCell.displayName = 'HBodyCell';
