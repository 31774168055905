import * as spaUrls from 'utils/spaUrls';
import ConfirmationDialog from 'components/Common/ConfirmationDialog';
import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { ButtonGroup } from '@mui/material';
import { HButton } from 'BaseComponents';
import { Link } from 'react-router-dom';
import { deleteCredentialSource } from 'redux/actions/DataCredentialManagementAction';
import { rowsPerPageOptions } from 'utils/appConstants';
import { useDispatch } from 'react-redux';


const CredentialSourcesTable = ({ sourcesList }) => {
  const dispatch = useDispatch();
  const [rowsPerPage, setRowsPerPage] = React.useState(rowsPerPageOptions[0]);
  const [page, setPage] = React.useState(0);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deletingRowID, setDeletingRowID] = useState(null);

  const onDeleteModalOpen = (id) => {
    setOpenDeleteModal(true);
    setDeletingRowID(id);
  };

  const onDeleteModalClose = () => {
    setOpenDeleteModal(false);
    setDeletingRowID(null);
  };

  return (
    <>
      <TableContainer>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Provider</TableCell>
              <TableCell>Managed Credential</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {sourcesList
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(row => (
                <TableRow hover key={row.ID}>
                  <TableCell>
                    {row.name}
                  </TableCell>
                  <TableCell>
                    {row.provider}
                  </TableCell>
                  <TableCell>
                    {row.managedCredential ? String(row.managedCredential) : 'false' }
                  </TableCell>
                  <TableCell align='right'>
                    <ButtonGroup disableElevation size='small' variant='contained'>
                      <HButton
                        component={Link}
                        to={`${spaUrls.internalAdmin.application.credentialSourceDetail}/${row.ID}`}
                        color='inherit'
                      >
                        Edit Source
                      </HButton>
                      <HButton
                        onClick={() => onDeleteModalOpen(row.ID)}
                        color='inherit'
                      >
                        Delete Source
                      </HButton>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component='div'
        count={sourcesList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
        }}
        onPageChange={(e, newPage) => setPage(newPage)}
        onRowsPerPageChange={e => setRowsPerPage(e.target.value)}
      />
      <ConfirmationDialog
        open={openDeleteModal}
        title='Delete?'
        message='Are you sure you want to delete Credentials Source?'
        confirmActionLabel='Delete'
        onConfirm={() => {
          dispatch(deleteCredentialSource(deletingRowID));
          onDeleteModalClose();
        }}
        onCancel={onDeleteModalClose}
      />
    </>
  );
};

export default CredentialSourcesTable;
