import http from 'utils/http';
import { SyntheticRedux } from 'synthetic-redux';
import { USER_LISTS } from 'redux/actions/Types';
import { combineReducers } from 'redux';
import { userLists } from 'utils/urlConstants';


const fetchUserList = new SyntheticRedux({ type: USER_LISTS.FETCH_LIST }, 'listResponse');
// Pass args to ignite
fetchUserList.ignite = (orgId, filter) => fetchUserList.actions.ignite({
  errorMeta: 'User List: Fetch userLists',
  method: http.post,
  payload: filter,
  url: userLists(orgId).list,
});

const fetchUserListDetail = new SyntheticRedux({ type: USER_LISTS.FETCH_LIST_DETAIL }, 'detailResponse');

fetchUserListDetail.ignite = (orgId, userListId) => fetchUserListDetail.actions.ignite({
  errorMeta: 'User Detail: Fetch userlist detail',
  method: http.get,
  url: userLists(orgId).detail(userListId),
});

const reprocessUserList = new SyntheticRedux({ type: USER_LISTS.REPROCESS_USER_LIST }, 'reprocess');

reprocessUserList.ignite = (orgId, userListId, stage) => reprocessUserList.actions.ignite({
  errorMeta: 'Reprocess: Update User List stage',
  method: http.post,
  payload: { stage },
  url: userLists(orgId).reprocess(userListId),
});

const synUserListReducer = combineReducers({
  userList: fetchUserList.reducer,
  userListDetail: fetchUserListDetail.reducer,
  reprocessUserList: reprocessUserList.reducer,
});

export {
  fetchUserList, fetchUserListDetail, reprocessUserList, synUserListReducer,
};
