import { activeOrgSelector } from 'redux/reducer/AuthenticationReducer';
import { axiosInstance } from 'utils/http';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { userDefinedFunctions } from 'utils/urlConstants';


export const useFetchCrqUdfList = (crId) => {
  const dispatch = useDispatch();
  const { ID: orgId } = useSelector(activeOrgSelector);

  return useQuery(
    ['crqUdfList', orgId],
    async () => {
      const { data } = await axiosInstance.get(userDefinedFunctions(orgId).crqUdfList(crId, 'COMPLETE'));
      return data;
    },
    {
      enabled: !!crId,
      onError: (err) => {
        dispatch(showAlert({
          message: err.response?.data?.message ?? 'Error fetching cleanroom question user-defined functions',
          type: 'error',
        }));
      },
    },
  );
};
