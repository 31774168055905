export const CLEAN_ROOM_PARTNERS = {
  ADD_PARTNER: 'CR/ADD_PARTNER',
  ACCEPT_INVITATION: 'CR/ACCEPT_INVITATION',
  REJECT_INVITATION: 'CR/REJECT_INVITATION',
  FETCH_CR_PARTNERS: 'CR/FETCH_CR_PARTNERS',
  FETCH_CR_PARTNER_INVITATIONS: 'CR/FETCH_CR_PARTNER_INVITATIONS',
  FETCH_CR_RECEIVED_INVITATIONS: 'FETCH_CR_RECEIVED_INVITATIONS',
  FETCH_CR_INVITATIONS: 'CR/FETCH_CR_INVITATIONS',
  LINK_CR_PARTNERS: 'CR/LINK_CR_PARTNERS',
  INVITE_PARTNER: 'CR/INVITE_PARTNER',
  REMOVE_PARTNER: 'CR/REMOVE_PARTNER',
};
