import * as actions from 'redux/actions/DataInAttrsActions';
import Logger from 'components/Common/Logger';
import http from 'utils/http';
import { DATA_IN_ATTRS } from 'redux/actions/Types';
import {
  all, call, put, takeEvery,
} from 'redux-saga/effects';
import { habuSupport } from 'utils/appConstants';
import { showAlert } from 'redux/actions/AlertActions';


function* fetchDataInAttrs(action) {
  const { attributes, error } = yield call(http.get, action.url);
  if (error) {
    Logger.error(error, 'Data Connections: Fetching Attributes');
    yield put(showAlert({ message: `Error fetching attributes. ${habuSupport.message}`, type: 'error' }));
    return yield put(actions.fetchDatasetAttributeListError({ jobId: action.jobId, error }));
  }
  return yield put(actions.fetchDatasetAttributeListSuccess({
    jobId: action.jobId,
    list: attributes,
  }));
}

function* fetchDataInAttributeNameList(action) {
  const { values, error } = yield call(http.get, action.url);
  if (error) {
    Logger.error(error, 'Data Connections: Fetching Attribute Name List');
    return yield put(actions.fetchDatasetAttributeValuesByNameError(error));
  }
  return yield put(actions.fetchDatasetAttributeValuesByNameSuccess(values));
}

function* fetchDataInAttrValues(action) {
  const limit = 25;
  const offset = action.page * limit;
  const requestPayload = {
    ...action.payload,
    query: action.query,
    pagination: { offset, limit },
  };
  const { values, pagination, error } = yield call(http.post, action.url, requestPayload);
  if (error) {
    Logger.error(error, 'Data Connections: Fetching Attribute Values');
    yield put(showAlert({ message: `Error fetching attributes values. ${habuSupport.message}`, type: 'error' }));
    return yield put(actions.fetchDatasetAttributeValueListError({ attrId: action.attrId, error }));
  }
  const hasMorePages = pagination.offset + pagination.limit < pagination.total;
  return yield put(actions.fetchDatasetAttributeValueListSuccess({
    hasMorePages,
    list: values,
    pagination,
    query: action.query,
  }));
}

export default function* root() {
  yield all([
    takeEvery(DATA_IN_ATTRS.FETCH_DATASET_ATTRIBUTE_LIST, fetchDataInAttrs),
    takeEvery(DATA_IN_ATTRS.FETCH_DATASET_ATTRIBUTE_NAME_LIST, fetchDataInAttributeNameList),
    takeEvery(DATA_IN_ATTRS.FETCH_DATASET_ATTRIBUTE_VALUE_LIST, fetchDataInAttrValues),
  ]);
}

export { fetchDataInAttrs, fetchDataInAttrValues };
