import ConfirmModal from 'components/Common/confirmModal';
import React, { useContext } from 'react';
import {
  OrganizationRolesContext,
} from 'pages/OrganizationManagement/organization/pages/OrganizationRoles/OrganizationRoleTable/OrganizationRolesContextProvider';
import {
  OrganizationRolesTableLayout,
} from 'pages/OrganizationManagement/organization/pages/OrganizationRoles/OrganizationRoleTable/OrganizationRolesTableLayout';


export const OrganizationRolesPageLayout = () => {
  const {
    selectedRole,
    showModal,
    isDeleting,
    onCloseModal,
    deleteOrgRole,
  } = useContext(OrganizationRolesContext);
  return (
    <>
      <OrganizationRolesTableLayout />
      {showModal
        && (
        <ConfirmModal
          open={showModal}
          onClose={onCloseModal}
          loading={isDeleting}
          onConfirm={deleteOrgRole}
          confirmButtonText='Confirm'
          denyButtonText='Cancel'
          title='Delete Role'
          body={`Would you like to delete ${selectedRole.roleName} from current organization?`}
        />
        )}
    </>
  );
};
