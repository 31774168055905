import * as actionGenerators from 'redux/actions/FullViewAutomationActions';
import Logger from 'components/Common/Logger';
import http from 'utils/http';
import { FVA } from 'redux/actions/Types';
import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import { showAlert } from 'redux/actions/AlertActions';


function* createFva(action) {
  const { fullViewAutomation, error } = yield call(http.post, action.url, action.payload);
  if (error) {
    Logger.error(error, `FVA : Create FVA. Url: ${action.url}. Error: ${error}`);
    return yield put(actionGenerators.createFvaError(error));
  }
  else {
    return yield put(actionGenerators.createFvaSuccess(fullViewAutomation));
  }
}

function* fetchFvaList(action) {
  const { fvaDetails, error } = yield call(http.post, action.url, action.payload);
  if (error) {
    Logger.error(error, `FVA: Fetch List of FVAs. Url: ${action.url}. Error: ${error}`);
    return yield put(actionGenerators.fetchFvaListError(error));
  }
  else {
    return yield put(actionGenerators.fetchFvaListSuccess(fvaDetails));
  }
}

function* fetchFvaPartnersList(action) {
  const { partners, error } = yield call(http.get, action.url);
  if (error) {
    Logger.error(error, `FVA: Fetch List of FVA Partners. Url: ${action.url}. Error: ${error}`);
    return yield put(actionGenerators.fetchFvaPartnersListError(error));
  }
  else {
    return yield put(actionGenerators.fetchFvaPartnersListSuccess(partners));
  }
}

function* fetchFvaPartnerCredentialSources(action) {
  const { credentials, error } = yield call(http.get, action.url);
  if (error) {
    Logger.error(error, `FVA: Fetch Partner Credential Sources. Url: ${action.url}. Error: ${error}`);
    return yield put(actionGenerators.fetchFvaPartnerCredentialSourcesListError(error));
  }
  else {
    return yield put(actionGenerators.fetchFvaPartnerCredentialSourcesListSuccess(credentials));
  }
}

function* fetchFvaFilters(action) {
  const fvaFilters = yield call(http.get, action.url);
  const { error } = fvaFilters;
  if (error) {
    Logger.error(`Could not fetch FVA Filters. Url: ${action.url}. Error: ${error}`,
      'Full View Automation: Fetch Filters');
    return yield put(actionGenerators.fetchFvaFiltersError(error));
  }
  return yield put(actionGenerators.fetchFvaFiltersSuccess(fvaFilters));
}

function* changeFvaStatus(action) {
  const { success, error } = yield call(http.post, action.url, action.payload);
  if (error) {
    Logger.error(error, `FVA: Error changing FVA status. Url: ${action.url}. Error: ${error}`);
    yield put(showAlert({ message: error, type: 'error' }));
    return yield put(actionGenerators.changeFvaStatusError(error));
  }
  return yield put(actionGenerators.changeFvaStatusSuccess(success));
}

function* changeFvaQuestionStatus(action) {
  const { success, error } = yield call(http.patch, action.url, action.payload);
  if (error) {
    Logger.error(error, `FVA: Error changing Question status. Url: ${action.url}. Error: ${error}`);
    yield put(showAlert({ message: error, type: 'error' }));
    return yield put(actionGenerators.changeFvaQuestionStatusError(error));
  }
  return yield put(actionGenerators.changeFvaQuestionStatusSuccess(success));
}

function* fetchFvaQuestions(action) {
  const { fvaName, fvaQuestions, error } = yield call(http.post, action.url, action.payload);
  if (error) {
    Logger.error(error, `FVA: Fetch FVA Question List. Url: ${action.url}. Error: ${error}`);
    return yield put(actionGenerators.fetchFvaQuestionsError(error));
  }
  else {
    return yield put(actionGenerators.fetchFvaQuestionsSuccess({ fvaName, fvaQuestions }));
  }
}

function* fetchFvaQuestionDetail(action) {
  const { question, error } = yield call(http.get, action.url);
  if (error) {
    Logger.error(`Could not fetch question detail. Url: ${action.url}. Error: ${error}`,
      'FVA: Fetch question detail');
    yield put(actionGenerators.fetchFvaQuestionDetailsError(error));
  }
  else {
    yield put(actionGenerators.fetchFvaQuestionDetailsSuccess(question));
  }
}

function* fetchFvaQuestionsFilters(action) {
  const fvaQuestionsFilters = yield call(http.get, action.url);
  const { error } = fvaQuestionsFilters;
  if (error) {
    Logger.error(`Could not fetch FVA Questions Filters. Url: ${action.url}. Error: ${error}`,
      'Full View Automation: Fetch Questions Filters');
    return yield put(actionGenerators.fetchFvaQuestionsFiltersError(error));
  }
  return yield put(actionGenerators.fetchFvaQuestionsFiltersSuccess(fvaQuestionsFilters));
}

function* fetchFvaReport(action) {
  const response = yield call(http.post, action.url, action.payload);
  if (response.error) {
    Logger.error(`Could not fetch question report. Url: ${action.url}. Error: ${response.error}`,
      'FVA: Fetch question report');
    return yield put(actionGenerators.fetchFvaReportError(response.error));
  }
  else {
    return yield put(actionGenerators.fetchFvaReportSuccess(response));
  }
}

function* fetchFvaReportFilter(action) {
  const { reportFilters, error } = yield call(http.get, action.url);
  if (error) {
    Logger.error(`Could not fetch report filters. Url: ${action.url}. Error: ${error}`,
      'FVA: Fetch report filters');
    return yield put(actionGenerators.fetchFvaReportFiltersError(error));
  }
  else {
    return yield put(actionGenerators.fetchFvaReportFiltersSuccess(reportFilters));
  }
}

function* fetchFvaReportFreq(action) {
  const { details, error } = yield call(http.get, action.url);
  if (error) {
    Logger.error(`Could not fetch report frequency. Url: ${action.url}. Error: ${error}`,
      'FVA: Fetch report frequency');
    return yield put(actionGenerators.fetchFvaReportFreqError(error));
  }
  else {
    return yield put(actionGenerators.fetchFvaReportFreqSuccess(details));
  }
}

function* fetchFvaSummary(action) {
  const { summaries, error } = yield call(http.get, action.url);
  if (error) {
    Logger.error(`Could not fetch Fva summary . Url: ${action.url}. Error: ${error}`,
      'FVA: Fetch report summary');
    return yield put(actionGenerators.fetchFvaSummaryError(error));
  }
  return yield put(actionGenerators.fetchFvaSummarySuccess(summaries));
}


export default function* root() {
  yield all([
    takeLatest(FVA.CREATE_FVA, createFva),
    takeLatest(FVA.FETCH_FVA_LIST, fetchFvaList),
    takeLatest(FVA.FETCH_FVA_FILTERS, fetchFvaFilters),
    takeLatest(FVA.FETCH_FVA_PARTNERS, fetchFvaPartnersList),
    takeLatest(FVA.FETCH_FVA_PARTNER_CREDENTIAL_SOURCES, fetchFvaPartnerCredentialSources),
    takeLatest(FVA.CHANGE_FVA_STATUS, changeFvaStatus),
    takeLatest(FVA.CHANGE_FVA_QUESTION_STATUS, changeFvaQuestionStatus),
    takeLatest(FVA.FETCH_FVA_QUESTIONS, fetchFvaQuestions),
    takeLatest(FVA.FETCH_FVA_QUESTION_DETAILS, fetchFvaQuestionDetail),
    takeLatest(FVA.FETCH_FVA_QUESTIONS_FILTERS, fetchFvaQuestionsFilters),
    takeLatest(FVA.FETCH_FVA_REPORT, fetchFvaReport),
    takeLatest(FVA.FETCH_FVA_REPORT_FILTERS, fetchFvaReportFilter),
    takeLatest(FVA.FETCH_FVA_REPORT_FREQ, fetchFvaReportFreq),
    takeLatest(FVA.FETCH_FVA_SUMMARY, fetchFvaSummary),
  ]);
}

export {
  createFva,
  changeFvaStatus,
  changeFvaQuestionStatus,
  fetchFvaList,
  fetchFvaPartnersList,
  fetchFvaPartnerCredentialSources,
  fetchFvaFilters,
  fetchFvaQuestions,
  fetchFvaQuestionDetail,
  fetchFvaQuestionsFilters,
  fetchFvaReport,
  fetchFvaReportFilter,
  fetchFvaReportFreq,
  fetchFvaSummary,
};
