import { activeOrgSelector } from 'redux/reducer/AuthenticationReducer';
import { axiosInstance } from 'utils/http';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { userDefinedFunctions } from 'utils/urlConstants';


export const useFetchUdfParameters = (udfTypeID) => {
  const dispatch = useDispatch();
  const { ID: orgId } = useSelector(activeOrgSelector);

  return useQuery(
    ['udfParameters', { orgId, udfTypeID }],
    async () => {
      const { data } = await axiosInstance.get(userDefinedFunctions(orgId)
        .udfParameters(udfTypeID));
      return data;
    },
    {
      enabled: !!udfTypeID,
      retry: false,
      onError: (err) => {
        dispatch(showAlert({
          message: err.response?.data?.message ?? err.response?.statusText ?? '',
          type: 'error',
        }));
      },
    },
  );
};
