import { FVA } from 'redux/actions/Types';


const initialState = {};

const FullViewAutomationReducer = (state = initialState, action) => {
  switch (action.type) {
    case FVA.CREATE_FVA:
      return { ...state, meta: { isSaving: true } };
    case FVA.CREATE_FVA_SUCCESS:
      return { ...state, meta: { created: true, isSaving: false } };
    case FVA.CREATE_FVA_ERROR:
      return { ...state, meta: { created: false, isSaving: false, error: action.payload } };
    case FVA.FETCH_FVA_LIST_ERROR:
      return { ...state, fvaList: { error: action.payload } };
    case FVA.FETCH_FVA_LIST_SUCCESS:
      return { ...state, fvaList: action.payload, meta: {} };
    case FVA.FETCH_FVA_FILTERS_SUCCESS:
      return { ...state, fvaFilters: action.payload };
    case FVA.FETCH_FVA_PARTNERS:
      return { ...state, fvaPartners: undefined };
    case FVA.FETCH_FVA_PARTNERS_SUCCESS:
      return { ...state, fvaPartners: action.payload };
    case FVA.FETCH_FVA_PARTNERS_ERROR:
      return { ...state, fvaPartners: { error: action.payload } };
    case FVA.FETCH_FVA_PARTNER_CREDENTIAL_SOURCES:
      return { ...state, fvaPartnerCredentialSources: undefined };
    case FVA.FETCH_FVA_PARTNER_CREDENTIAL_SOURCES_SUCCESS:
      return { ...state, fvaPartnerCredentialSources: action.payload };
    case FVA.FETCH_FVA_PARTNER_CREDENTIAL_SOURCES_ERROR:
      return { ...state, fvaPartnerCredentialSources: { error: action.payload } };
    case FVA.FETCH_FVA_FILTERS_ERROR:
      return { ...state, fvaFilters: { error: action.payload } };
    case FVA.FETCH_FVA_QUESTIONS_FILTERS_SUCCESS:
      return { ...state, fvaQuestionsFilters: action.payload };
    case FVA.FETCH_FVA_QUESTIONS_FILTERS_ERROR:
      return { ...state, fvaQuestionsFilters: { error: action.payload } };
    case FVA.FETCH_FVA_QUESTIONS_ERROR:
      return { ...state, questions: { error: action.payload } };
    case FVA.FETCH_FVA_QUESTIONS_SUCCESS:
      return { ...state, questions: action.payload };
    case FVA.FETCH_FVA_QUESTION_DETAILS_ERROR:
      return { ...state, question: { error: action.payload } };
    case FVA.FETCH_FVA_QUESTION_DETAILS_SUCCESS:
      return { ...state, question: action.payload };
    case FVA.FETCH_FVA_REPORT_ERROR:
      return { ...state, report: { error: action.payload } };
    case FVA.FETCH_FVA_REPORT_SUCCESS:
      return { ...state, report: action.payload };
    case FVA.FETCH_FVA_REPORT_FILTERS:
      return { ...state, reportFilters: { loading: true } };
    case FVA.FETCH_FVA_REPORT_FILTERS_ERROR:
      return { ...state, reportFilters: { error: action.payload } };
    case FVA.FETCH_FVA_REPORT_FILTERS_SUCCESS:
      return { ...state, reportFilters: action.payload };
    case FVA.FETCH_FVA_REPORT_FREQ_ERROR:
      return { ...state, reportFreq: { error: action.payload } };
    case FVA.FETCH_FVA_REPORT_FREQ_SUCCESS:
      return { ...state, reportFreq: action.payload };
    case FVA.FETCH_FVA_SUMMARY_ERROR:
      return { ...state, summaries: { error: action.payload } };
    case FVA.FETCH_FVA_SUMMARY_SUCCESS:
      return { ...state, summaries: action.payload };
    case FVA.CHANGE_FVA_QUESTION_STATUS:
      return { ...state, fvaQuestionStatusChange: {} };
    case FVA.CHANGE_FVA_QUESTION_STATUS_ERROR:
      return { ...state, fvaQuestionStatusChange: { error: action.payload } };
    case FVA.CHANGE_FVA_QUESTION_STATUS_SUCCESS:
      return { ...state, fvaQuestionStatusChange: {} };
    case FVA.FVA_REPORT_RESET:
      return { ...initialState };
    default:
      return state;
  }
};

export default FullViewAutomationReducer;
