import { axiosInstance } from 'utils/http';
import { cleanRoom } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch } from 'react-redux';
import { useQuery } from 'react-query';


const useHIAuthHandler = (crOrgId, crId) => {
  const dispatch = useDispatch();
  const {
    data, isLoading, isError, isSuccess,
  } = useQuery(
    ['HIAuthHandler', crOrgId, crId],
    async () => axiosInstance.get(cleanRoom(crOrgId).habuIntelligenceAuthHandler(crId)),
    {
      enabled: (!!crOrgId && !!crId),
      refetchOnWindowFocus: false,
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    data,
  };
};

const useTriggerHabuIntelligenceRun = (orgId, cleanRoomId, crQuestionId) => {
  const dispatch = useDispatch();

  const {
    isLoading, isError, isSuccess, refetch,
  } = useQuery(
    ['Trigger HI Run', orgId, cleanRoomId, crQuestionId],
    async () => axiosInstance
      .get(cleanRoom(orgId).triggerHabuIntelligenceRun(cleanRoomId, crQuestionId)),
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onSuccess: () => dispatch(showAlert({ message: 'Sync Request Sent', type: 'success' })),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    refetch,
  };
};

export {
  useHIAuthHandler,
  useTriggerHabuIntelligenceRun,
};
