import * as actions from 'redux/actions/IamActions';
import Logger from 'components/Common/Logger';
import http from 'utils/http';
import { IAM } from 'redux/actions/Types';
import {
  all,
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { showAlert } from 'redux/actions/AlertActions';


function* createAccount(action) {
  const { account, error } = yield call(http.post, action.url, action.payload);
  if (error) {
    yield put(showAlert({ message: error, type: 'error' }));
    return yield put(actions.accountCreationFailure(error));
  }
  yield put(showAlert({ message: 'Account saved', type: 'success' }));
  return yield put(actions.accountCreationSuccess(account));
}

function* createAccountAdministrator(action) {
  const { account, error } = yield call(http.put, action.url, { user: action.payload });
  if (error) {
    yield put(showAlert({ message: error, type: 'error' }));
    return yield put(actions.createAccountAdministratorFailure(error));
  }
  yield put(showAlert({ message: 'Account administrator saved', type: 'success' }));
  return yield put(actions.createAccountAdministratorSuccess(account));
}

function* fetchAccount(action) {
  const { account, error } = yield call(http.get, action.url);
  if (error) {
    yield put(showAlert({ message: error, type: 'error' }));
    return yield put(actions.fetchAccountFailure(error));
  }
  else {
    return yield put(actions.fetchAccountSuccess(account));
  }
}

function* fetchAccountList(action) {
  const { accounts, error } = yield call(http.get, action.url);
  if (error) {
    yield put(showAlert({ message: error, type: 'error' }));
    return yield put(actions.receiveAccountListError(error));
  }
  else {
    return yield put(actions.receiveAccountListSuccess(accounts));
  }
}

function* fetchDemoAccountList(action) {
  const { accounts, error } = yield call(http.get, action.url);
  if (error) {
    yield put(showAlert({ message: error, type: 'error' }));
    return yield put(actions.receiveDemoAccountListError(error));
  }
  else {
    return yield put(actions.receiveDemoAccountListSuccess(accounts));
  }
}

function* fetchAccountAdminList(action) {
  const { users, error } = yield call(http.get, action.url);
  if (error) {
    Logger.error(`Could not fetch account admin list. Url: ${action.url}. Error: ${error}`,
      'Account Admin Details: Fetching administrator list');
    yield put(showAlert({ message: error, type: 'error' }));
    return yield put(actions.fetchAccountAdminListError(error));
  }
  else {
    return yield put(actions.fetchAccountAdminListSuccess(users));
  }
}

function* fetchOrganization(action) {
  const { organization, error } = yield call(http.get, action.url);
  if (error) {
    yield put(showAlert({ message: error, type: 'error' }));
    return yield put(actions.fetchOrganizationFailure(error));
  }
  return yield put(actions.fetchOrganizationSuccess(organization));
}

function* updateAccount(action) {
  const { account, error } = yield call(http.put, action.url, action.payload);
  if (error) {
    yield put(showAlert({ message: error, type: 'error' }));
    return yield put(actions.updateAccountFailure(error));
  }
  yield put(showAlert({ message: 'Account saved', type: 'success' }));
  return yield put(actions.updateAccountSuccess(account));
}

function* fetchOrganizationList(action) {
  const { organizations, error } = yield call(http.get, action.url);
  if (error) {
    Logger.error(`Could not fetch Organization List. Url: ${action.url}. Error: ${error}`,
      'IAM: Fetch organization list');
    return yield put(actions.receiveOrganizationListFailure(error));
  }
  return yield put(actions.receiveOrganizationListSuccess(organizations));
}

function* createOrganization(action) {
  const { organization, error } = yield call(http.post, action.url, action.payload);

  if (error) {
    if (error === 'panic triggered: runtime error: invalid memory address or nil pointer dereference') {
      yield put(showAlert({ message: 'That organization name is already taken. Please update the name and try saving again.', type: 'error' }));
    }
    else {
      yield put(showAlert({ message: error, type: 'error' }));
    }

    return yield put(actions.organizationCreationFailure(error));
  }

  yield put(showAlert({ message: 'Organization saved', type: 'success' }));
  return yield put(actions.organizationCreationSuccess(organization));
}

function* updateOrganization(action) {
  const { organization, error } = yield call(http.put, action.url, action.payload);
  if (error) {
    if (error === 'panic triggered: runtime error: invalid memory address or nil pointer dereference') {
      yield put(showAlert({ message: 'That organization name is already taken. Please update the name and try saving again.', type: 'error' }));
    }
    else {
      yield put(showAlert({ message: error, type: 'error' }));
    }

    return yield put(actions.organizationCreationFailure(error));
  }

  yield put(showAlert({ message: 'Organization saved', type: 'success' }));
  return yield put(actions.updateOrganizationSuccess(organization));
}

function* fetchRoleList(action) {
  const { roles, error } = yield call(http.get, action.url);
  if (error) {
    Logger.error(`Could not fetch Organization Role List. Url: ${action.url}. Error: ${error}`,
      'IAM: Fetch organization role list');
    return yield put(actions.receiveRoleListError(error));
  }
  return yield put(actions.receiveRoleListSuccess(roles));
}

function* fetchRole(action) {
  const { role, error } = yield call(http.get, action.url);
  if (error) {
    yield put(showAlert({ message: error, type: 'error' }));
    return yield put(actions.receiveRoleError(error));
  }
  else {
    return yield put(actions.receiveRoleSuccess(role));
  }
}

function* createRole(action) {
  const { roles, error } = yield call(http.post, action.url, action.payload);
  if (error) {
    if (error === 'Internal Server Error. Please contact Habu Support at support@habu.com') {
      yield put(showAlert({ message: 'That role name is already taken. Please update the name and try saving again.', type: 'error' }));
    }
    else {
      yield put(showAlert({ message: error, type: 'error' }));
    }
    return yield put(actions.roleCreationFailure(error));
  }

  yield put(showAlert({ message: 'Successfully created role', type: 'success' }));
  return yield put(actions.roleCreationSuccess(roles));
}

function* updateRole(action) {
  const { role, error } = yield call(http.post, action.url, action.payload);
  if (error) {
    if (error === 'Internal Server Error. Please contact Habu Support at support@habu.com') {
      yield put(showAlert({ message: 'That role name is already taken. Please update the name and try saving again.', type: 'error' }));
    }
    else {
      yield put(showAlert({ message: error, type: 'error' }));
    }
    return yield put(actions.roleUpdationFailure(error));
  }

  yield put(showAlert({ message: 'Successfully updated role', type: 'success' }));
  return yield put(actions.roleUpdationSuccess(role));
}

function* fetchUserList(action) {
  const { users, error } = yield call(http.post, action.url, action.payload);
  if (error) {
    Logger.error(`Could not fetch Organization User List. Url: ${action.url}. Error: ${error}`,
      'IAM: Fetch organization user list');
    return yield put(actions.receiveUserListError(error));
  }
  return yield put(actions.receiveUserListSuccess(users));
}

function* fetchUser(action) {
  const { user, error } = yield call(http.get, action.url);
  if (error) {
    yield put(showAlert({ message: error, type: 'error' }));
    return yield put(actions.fetchUserFailure(error));
  }
  return yield put(actions.receiveUser(user));
}

function* fetchAdmin(action) {
  const { user, error } = yield call(http.get, action.url);
  if (error) {
    Logger.error(`Could not fetch Account Admin. Url: ${action.url}. Error: ${error}`,
      'IAM: Fetch Account Admin');
    yield put(showAlert({ message: error, type: 'error' }));
    return yield put(actions.fetchAdminError(error));
  }
  return yield put(actions.receiveAdmin(user));
}

function* createUser(action) {
  const { users, error } = yield call(http.post, action.url, action.payload);
  if (error) {
    yield put(showAlert({ message: error, type: 'error' }));
    return yield put(actions.userCreationFailure(error));
  }
  yield put(showAlert({ message: 'Successfully created user', type: 'success' }));
  // todo this is weird, do we need back end work for this
  return yield put(actions.userCreationSuccess(users[0]));
}

function* sendWelcomeEmail(action) {
  const { success, error } = yield call(http.post, action.url, { email: action.email });
  if (error) {
    yield put(showAlert({ message: error, type: 'error' }));
    return yield put(actions.sendEmailFailure(error));
  }
  yield put(showAlert({ message: 'Email sent', type: 'success' }));
  return yield put(actions.sendEmailSuccess(success));
}

function* updateUser(action) {
  const { user, error } = yield call(http.post, action.url, action.payload);
  if (error) {
    yield put(showAlert({ message: error, type: 'error' }));
    return yield put(actions.userUpdationFailure(error));
  }
  yield put(showAlert({ message: 'Successfully updated user', type: 'success' }));
  return yield put(actions.userUpdationSuccess(user));
}

function* updateAdmin(action) {
  const { user, error } = yield call(http.put, action.url, action.payload);
  if (error) {
    Logger.error(`Could not update admin. Url: ${action.url}. Error: ${error}`,
      'Iam: Updating Account Administrator');
    yield put(showAlert({ message: error, type: 'error' }));
    return yield put(actions.adminUpdationError(error));
  }
  yield put(showAlert({ message: 'Successfully updated admin', type: 'success' }));
  return yield put(actions.adminUpdationSuccess(user));
}

function* deleteUser(action) {
  const { success, error } = yield call(http.delete, action.url);
  if (error) {
    Logger.error(`Could not delete user. Url: ${action.url}. Error: ${error}`,
      'Iam: Delete user');
    yield put(showAlert({ message: error, type: 'error' }));
    return yield put(actions.deleteUserError(error));
  }
  yield put(showAlert({ message: 'Successfully deleted user', type: 'success' }));
  return yield put(actions.deleteUserSuccess(success));
}

function* fetchProductList(action) {
  const { products, error } = yield call(http.get, action.url);
  if (error) {
    yield put(showAlert({ message: error, type: 'error' }));
    return yield put(actions.receiveProductListError(error));
  }
  return yield put(actions.receiveProductListSuccess(products));
}

function* fetchAccountProducts(action) {
  const { products, error } = yield call(http.get, action.url);
  if (error) {
    yield put(showAlert({ message: error, type: 'error' }));
    return yield put(actions.receiveAccountProductsError(error));
  }
  return yield put(actions.receiveAccountProductsSuccess(products));
}

function* fetchOrganizationProducts(action) {
  const { products, error } = yield call(http.get, action.url);
  if (error) {
    Logger.error(error, 'IAM: Fetching Organization Products');
    yield put(showAlert({ message: error, type: 'error' }));
    return yield put(actions.receiveOrganizationProductsError(error));
  }
  else {
    return yield put(actions.receiveOrganizationProductsSuccess(products));
  }
}

function* submitAccountProduct(action) {
  const { products, error } = yield call(http.post, action.url, action.payload);
  if (error) {
    yield put(showAlert({ message: error, type: 'error' }));
    return yield put(actions.updateAccountProductsError(error));
  }
  yield put(showAlert({ message: 'Successfully updated products', type: 'success' }));
  window.location.reload();
  return yield put(actions.updateAccountProductsSuccess(products));
}

function* submitOrganizationProduct(action) {
  const { products, error } = yield call(http.post, action.url, action.payload);
  if (error) {
    Logger.error(error, 'IAM: Updating Organization Products');
    yield put(showAlert({ message: error, type: 'error' }));
    return yield put(actions.updateOrganizationProductsError(error));
  }
  else {
    yield put(showAlert({ message: 'Successfully updated organization products', type: 'success' }));
    window.location.reload();
    return yield put(actions.updateOrganizationProductsSuccess(products));
  }
}

export default function* root() {
  yield all([
    takeLatest(IAM.CREATE_ACCOUNT, createAccount),
    takeLatest(IAM.CREATE_ACCOUNT_ADMINISTRATOR, createAccountAdministrator),
    takeLatest(IAM.CREATE_ORGANIZATION, createOrganization),
    takeLatest(IAM.CREATE_ROLE, createRole),
    takeLatest(IAM.CREATE_USER, createUser),
    takeLatest(IAM.FETCH_ACCOUNT, fetchAccount),
    takeLatest(IAM.FETCH_ACCOUNT_LIST, fetchAccountList),
    takeLatest(IAM.FETCH_ACCOUNT_ADMIN_LIST, fetchAccountAdminList),
    takeLatest(IAM.FETCH_ACCOUNT_PRODUCTS, fetchAccountProducts),
    takeLatest(IAM.FETCH_ADMIN, fetchAdmin),
    takeLatest(IAM.FETCH_ORGANIZATION_PRODUCTS, fetchOrganizationProducts),
    takeLatest(IAM.FETCH_DEMO_ACCOUNT_LIST, fetchDemoAccountList),
    takeLatest(IAM.FETCH_ORGANIZATION, fetchOrganization),
    takeLatest(IAM.FETCH_ORGANIZATION_LIST, fetchOrganizationList),
    takeLatest(IAM.FETCH_PRODUCT_LIST, fetchProductList),
    takeLatest(IAM.FETCH_ROLE, fetchRole),
    takeLatest(IAM.FETCH_ROLE_LIST, fetchRoleList),
    takeLatest(IAM.FETCH_USER, fetchUser),
    takeLatest(IAM.FETCH_USER_LIST, fetchUserList),
    takeLatest(IAM.SEND_WELCOME_EMAIL, sendWelcomeEmail),
    takeLatest(IAM.UPDATE_ACCOUNT, updateAccount),
    takeLatest(IAM.UPDATE_ACCOUNT_PRODUCTS, submitAccountProduct),
    takeLatest(IAM.UPDATE_ORGANIZATION_PRODUCTS, submitOrganizationProduct),
    takeLatest(IAM.UPDATE_ORGANIZATION, updateOrganization),
    takeLatest(IAM.UPDATE_ROLE, updateRole),
    takeLatest(IAM.UPDATE_USER, updateUser),
    takeLatest(IAM.UPDATE_ADMIN, updateAdmin),
    takeLatest(IAM.DELETE_USER, deleteUser),
  ]);
}

export {
  createAccount,
  createAccountAdministrator,
  createOrganization,
  createRole,
  createUser,
  fetchAccount,
  fetchAccountList,
  fetchAccountAdminList,
  fetchAccountProducts,
  fetchAdmin,
  fetchDemoAccountList,
  fetchOrganization,
  fetchOrganizationList,
  fetchOrganizationProducts,
  fetchProductList,
  fetchRole,
  fetchRoleList,
  fetchUser,
  fetchUserList,
  sendWelcomeEmail,
  submitAccountProduct,
  submitOrganizationProduct,
  updateAccount,
  updateOrganization,
  updateRole,
  updateUser,
  updateAdmin,
  deleteUser,
};
