import { DEMO_DATA, DOWNLOAD } from 'redux/actions/Types';


const downloadCsv = (orgId, url) => ({
  type: DOWNLOAD.DOWNLOAD_CSV,
  orgId,
  url,
});

export const downloadDemoErrorReport = (orgId, fileName, url) => ({
  type: DEMO_DATA.DOWNLOAD_ERROR_FILE,
  fileName,
  orgId,
  url,
});

export const downloadCsvPost = (orgId, fileName, url, payload) => ({
  type: DOWNLOAD.DOWNLOAD_CSV_POST,
  fileName,
  orgId,
  url,
  payload,
});

export default downloadCsv;
