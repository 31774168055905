import * as Enums from 'utils/ignoramusEnums';
import PermissionService from 'components/Common/PermissionService';
import _ from 'lodash';
import { crqPermissionsAccessLevels } from 'components/CleanRooms/constants';
import { productPerm } from 'utils/appConstants';
import { verifyPartnerEmail, verifyPartnerKey } from 'redux/actions/CleanRoomsActions';


export const showCrRunDetailsByJobStatus = jobRunStatus => [
  Enums.runStatus.FAILED.key,
  Enums.runStatus.PARTNER_FAILED.key,
].includes(jobRunStatus);

/**
 * Check all the parameter and ensure no name and display name are identical.
 * @param {[Object]} parameters - An array of run time parameters of clean room question.
 * @returns {boolean}
 */
export const validateParameters = (parameters) => {
  const nameMap = {};
  const displayNameMap = {};
  const valid = parameters.every((currentParam) => {
    if (currentParam.name in nameMap || currentParam.displayName in displayNameMap) {
      return false;
    }
    nameMap[currentParam.name] = 1;
    displayNameMap[currentParam.displayName] = 1;
    return true;
  });
  return valid;
};

export const verifyAdminEmail = (dispatch, ownerOrgId) => _.debounce(
  (partnerOrgId, email) => dispatch(verifyPartnerEmail(ownerOrgId, partnerOrgId, email)),
  1000,
);

export const verifyApiKey = (dispatch, ownerOrgId) => _.debounce(
  key => dispatch(verifyPartnerKey(ownerOrgId, key)), 1000,
);

export const hasCRQuestionPermission = (orgId, ownerOrgId, permissions, neededPermission) => {
  if (!PermissionService.isProductEnabled([productPerm.crQuestionPermissions])) return true;
  if (orgId === ownerOrgId) return true;
  if (!permissions) return false;
  const permission = permissions.find(p => p.permission === neededPermission);
  return permission && permission.access === crqPermissionsAccessLevels.ALL_CLEAN_ROOM_PARTNERS;
};
