import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import PageHeader from 'components/Common/PageHeader';
import React from 'react';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { HButton, HSpinner } from 'BaseComponents';
import { Link } from 'react-router-dom';
import { StyledHButton } from 'components/IAM/Admin/StyledComponents';
import { fetchPermissions } from 'redux/actions/LookupActions';
import { fetchProductList } from 'redux/actions/IamActions';
import { fetchProductPermission, updateProductPermissions } from 'redux/actions/PermissionActions';
import { internalAdmin } from 'utils/spaUrls';
import { useDispatch, useSelector } from 'react-redux';


const empty = [];

const ProductPermissionDetails = (props) => {
  const { prodID } = props.match.params;
  const dispatch = useDispatch();
  const productPermissions = useSelector(state => state.permission.productPermissions || empty);
  const productList = useSelector(state => state.iam.productList) || [];
  const permissions = useSelector(state => state.lookups.permissions);
  const currentProduct = productList.find(el => el.ID === prodID) || [];
  const [prodPerm, updateProdPerm] = React.useState([]);

  React.useEffect(() => {
    dispatch(fetchProductPermission(prodID));
    dispatch(fetchPermissions());
    dispatch(fetchProductList());
  }, [dispatch, prodID]);

  React.useEffect(() => {
    if (productPermissions) {
      updateProdPerm(productPermissions.map(el => el.name));
    }
  }, [updateProdPerm, productPermissions]);

  if (!productPermissions || !productList || !currentProduct || !permissions) {
    return <HSpinner />;
  }

  return (
    <>
      <PageHeader title='Edit Product Permission ' />
      <Grid container direction='row' spacing={2}>
        <Grid item xs={12} md={5}>
          <Typography variant='h3'>{currentProduct.name}</Typography>
          <TextField
            fullWidth
            InputLabelProps={{ shrink: true }}
            label='Permissions'
            margin='normal'
            select
            SelectProps={{
              multiple: true,
              onChange: e => updateProdPerm(e.target.value),
              renderValue: selected => selected.join(', '),
            }}
            variant='outlined'
            value={prodPerm}
          >
            {permissions.map(el => (
              <MenuItem key={el.name} value={el.name}>
                <Checkbox checked={prodPerm.indexOf(el.name) > -1} />
                <ListItemText primary={el.name} />
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <HButton
            component={Link}
            to={internalAdmin.application.productPermissions}
            variant='contained'
            size='small'
          >
            Cancel
          </HButton>
          <StyledHButton
            color='primary'
            onClick={() => dispatch(updateProductPermissions(
              prodID,
              { permissions: prodPerm },
            ))}
            size='small'
            variant='contained'
          >
            Save
          </StyledHButton>
        </Grid>
      </Grid>
    </>
  );
};


export default ProductPermissionDetails;
