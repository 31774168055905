import React from 'react';
import orderBy from 'lodash/orderBy';
import { sortDirections } from 'utils/appConstants';


const defaultOptions = {
  sortField: '',
  direction: '',
  page: 0,
  rowsPerPage: 10,
};


const useSortableTable = (list = [], configOptions) => {
  const options = Object.assign({}, defaultOptions, configOptions);
  const [rowsPerPage, setRowsPerPage] = React.useState(options.rowsPerPage);
  const [page, setPage] = React.useState(options.page);
  const [orderClause, setOrderClause] = React.useState(options.sortField);
  const [orderDirection, setOrderDirection] = React.useState(options.direction);

  const setOrderBy = React.useCallback((clause) => {
    if (orderDirection === sortDirections.desc && orderClause === clause) {
      setOrderDirection('');
      setOrderClause(options.sortField);
    }
    else if (orderDirection === sortDirections.asc && orderClause === clause) {
      setOrderDirection(sortDirections.desc);
    }
    else {
      setOrderDirection(sortDirections.asc);
      setOrderClause(clause);
    }
  }, [orderDirection, setOrderDirection, orderClause, setOrderClause, options.sortField]);


  const pageList = React.useMemo(() => {
    if (Array.isArray(list)) {
      return orderBy(list, orderClause, orderDirection)
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    }
    return [];
  }, [page, rowsPerPage, list, orderClause, orderDirection]);

  return {
    count: list.length,
    pageList,
    page,
    rowsPerPage,
    orderBy: orderClause,
    orderDirection,
    setRowsPerPage,
    setPage,
    setOrderBy,
  };
};

export default useSortableTable;
