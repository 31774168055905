import React, { memo, useContext, useRef } from 'react';
import resizeTableCell from 'assets/icons/resize-table-cell.svg';
import { DataContext } from 'BaseComponents/HDataTable/DataContextProvider';
import { HTableSortLabel } from 'BaseComponents/HDataTable/HTableSortLabel';
import { ResizableContext } from 'BaseComponents/HDataTable/ResizableContextProvider';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';


const StyledDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'absolute',
  cursor: 'col-resize',
  width: 7,
  height: '-webkit-fill-available',
  right: theme.spacing(0),
  top: theme.spacing(0),
  zIndex: 10,
  borderRight: '2px solid transparent',
  alignItems: 'center',

  '&:before': {
    content: `url(${resizeTableCell})`,
    position: 'relative',
    right: 2,
    display: 'none',
  },

  'th:hover &:before': {
    display: 'block',
  },

  '&:hover': {
    borderColor: '#ccc',
  },

  '&:active': {
    borderColor: '#ccc',
  },
}));


export const HHeadCell = memo(({
  field,
}) => {
  const ref = useRef();

  const {
    columnsMap,
  } = useContext(DataContext);

  const { name, width, resize = true } = columnsMap.get(field);

  const {
    mouseDown,
  } = useContext(ResizableContext);

  return (
    <th
      ref={ref}
      width={width}
    >
      <HTableSortLabel field={field}>
        <Typography variant='span' className='text'>{name}</Typography>
      </HTableSortLabel>

      {resize && (
      <StyledDiv
        role='presentation'
        onMouseDown={(e) => mouseDown(e, ref, field)}
      />
      )}
    </th>
  );
});

HHeadCell.displayName = 'HHeadCell';
