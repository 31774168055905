import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';
import { styled } from '@mui/material/styles';


const StyledCircularProgress = styled(CircularProgress, {
  shouldForwardProp: (props) => props !== 'isButton',
})(({ isButton }) => ({
  ...(isButton ? { cursor: 'progress' } : {
    display: 'block',
    position: 'relative',
    left: '50%',
    top: '35%',
  }),
}));

export const HSpinner = ({ isButton }) => {
  const size = isButton ? 15 : 40;
  const color = isButton ? 'inherit' : 'primary';

  return (
    <StyledCircularProgress
      color={color}
      size={size}
      isButton={isButton}
    />
  );
};

