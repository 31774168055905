import { FLOW } from 'redux/actions/Types';
import { addFlowEdgesCheck } from 'redux/reducer/FlowReducer/addFlowEdgesCheck';
import { deleteSelectedFlowTagsInformation } from 'redux/reducer/FlowReducer/deleteSelectedFlowTagsInformation';
import { editQuestionEdgeDialog } from 'redux/reducer/FlowReducer/editQuestionEdgeDialog';
import { getDeleteFlowNodeById } from 'redux/reducer/FlowReducer/getDeleteFlowNodeById';
import { getSelectedFlow } from 'redux/reducer/FlowReducer/getSelectedFlow';
import { initialState } from 'redux/reducer/FlowReducer/contacts';
import {
  mutateAPIEdges,
  mutateAPIFlowNodeResponse,
} from 'redux/reducer/FlowReducer/mutateAPIFlowResponse';
import { onConnectFlowEdges } from 'redux/reducer/FlowReducer/onConnectFlowEdges';
import { openFlowDialogEdgesConnection } from 'redux/reducer/FlowReducer/openFlowDialogEdgesConnection';
import { updateCreateNodeSuccess } from 'redux/reducer/FlowReducer/updateCreateNode';
import { updateFlowEdges } from 'redux/reducer/FlowReducer/updateFlowEdges';
import {
  updateFlowNodes,
} from 'redux/reducer/FlowReducer/updateFlowNodes';
import { updateFlowNodesQuestion } from 'redux/reducer/FlowReducer/updateFlowNodesQuestion';
import { updateSelectedFlowInformation } from 'redux/reducer/FlowReducer/updateSelectedFlowInformation';


export const FlowReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FLOW.FETCH_FLOW_EDGES_SUCCESS:
      return { ...state, edges: payload };
    case FLOW.SUBMIT_FLOW_SUCCESS:
      return {
        ...state,
        listOfFlows: [...state.listOfFlows, payload],
        flowsCache: [...state.flowsCache, payload],
        nodes: [],
        edges: [],
      };

    // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    // ~~~~~~~ new verified flow state
    // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    case FLOW.FETCH_FLOW_DETAILS_SUCCESS:
      return {
        ...state,
        flow: payload.flow,
        nodes: mutateAPIFlowNodeResponse(payload.flow?.nodes),
        edges: mutateAPIEdges(payload.flow?.edges),
      };
    case FLOW.ADD_TEMPORARY_NODE_TO_FLOW:
      return { ...state, nodes: [...state.nodes, payload] };

    case FLOW.UPDATE_FLOW_NODES:
      return { ...state, nodes: updateFlowNodes(payload, state.nodes) };

    case FLOW.CREATE_NODE_SUCCESS:
      return { ...state, nodes: updateCreateNodeSuccess(payload, state.nodes) };

    case FLOW.CLEAR_FLOW_CACHE:
      return {
        ...state, edges: [], nodes: [], flow: getSelectedFlow(state.listOfFlows, ''),
      };

    case FLOW.UPTADE_FLOW_NODES_QUESTION_SUCCESS:
      return {
        ...state, nodes: updateFlowNodesQuestion(payload, state.nodes),
      };
    case FLOW.DELETE_FLOW_NODE_SUCCESS:
      return {
        ...state,
        nodes: getDeleteFlowNodeById(payload, state.nodes),
      };

    case FLOW.OPEN_SIDE_PANEL:
      return {
        ...state,
        sidePanel: {
          open: true,
          type: payload.type,
          data: payload.data,
        },
      };
    case FLOW.CLOSE_SIDE_PANEL:
      return {
        ...state,
        sidePanel: { open: false, data: { } },
      };

    case FLOW.CHECK_FLOW_EDGES_CONNECTION:
      return {
        ...state,
        sidePanel: openFlowDialogEdgesConnection(
          payload, state.nodes,
        ),
      };
    case FLOW.CLOSE_CHECK_FLOW_EDGES_CONNECTION:
      return {
        ...state,
        sidePanel: { open: false, data: { } },
      };
    case FLOW.CONNECT_NODES_WITH_EDGE_SUCCESS:
      return {
        ...state,
        edges: addFlowEdgesCheck(payload, state.edges),
        sidePanel: { open: false, data: { } },
      };
    case FLOW.EDIT_QUESTION_EDGE:
      return {
        ...state,
        sidePanel: editQuestionEdgeDialog(
          payload, state.nodes,
        ),
      };
    case FLOW.UPDATE_FLOW_EDGES:
      return { ...state, edges: updateFlowEdges(payload, state.edges) };
    case FLOW.CONNECT_FLOW_EDGES:
      return {
        ...state,
        edges: onConnectFlowEdges(payload, state.edges),
      };

    case FLOW.UPDATE_SELECTED_FLOW:
      return {
        ...state, flow: getSelectedFlow(state.listOfFlows, payload.flowID),
      };

    case FLOW.UPDATE_SELECTED_FLOW_INFORMATION:
      return {
        ...state,
        flow: updateSelectedFlowInformation(state.flow, payload.type, payload.value),
      };

    case FLOW.DELETE_SELECTED_FLOW_INFORMATION_TAGS:
      return {
        ...state,
        flow: deleteSelectedFlowTagsInformation(state.flow, payload.index),
      };

    case FLOW.DELETE_SELECTED_FLOW_INFORMATION_ALL_TAGS:
      return {
        ...state,
        flow: {
          ...state.flow,
          tags: [],
        },
      };
    case FLOW.NODE_SIZE_CHANGE:
      return {
        ...state,
        nodeSize: payload,
      };
    case FLOW.FETCH_FLOW_DETAILS:
    case FLOW.FETCH_FLOW_DETAILS_ERROR:
    case FLOW.CREATE_NODE:
    case FLOW.CONNECT_NODES_WITH_EDGE:
    case FLOW.CONNECT_NODES_WITH_EDGE_ERROR:
    case FLOW.DELETE_FLOW_NODE:
    case FLOW.DELETE_FLOW_NODE_ERROR:
    case FLOW.UPTADE_FLOW_NODES_QUESTION:
    case FLOW.UPTADE_FLOW_NODES_QUESTION_ERROR:
    default:
      return {
        ...state,
      };
  }
};
