import { axiosInstance } from 'utils/http';
import { cleanRoom } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch } from 'react-redux';
import { useQuery } from 'react-query';


export const useGetDPAccountantView = (orgId, crId, enabled = true) => {
  const dispatch = useDispatch();
  return useQuery(
    ['getDPAccountantView', { orgId, crId }],
    () => axiosInstance.get(cleanRoom(orgId).budgetAccountantView(crId)),
    {
      enabled,
      select: (res) => res?.data || {},
      onError: (err) => {
        dispatch(showAlert({
          message: err.response.data.message ?? err.response.statusText,
          type: 'error',
        }));
      },
    });
};
