import Bouncer from 'components/Common/Bouncer';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import React, { memo } from 'react';
import { HButton } from 'BaseComponents';
import { Link } from 'react-router-dom';


export const HMultipleCreateButton = memo(({
  multipleCreateButton,
}) => (
  <>
    {multipleCreateButton && (
      multipleCreateButton.map(x => (
        <Bouncer allowList={x.permission} key={x.text}>
          <HButton
            component={x.link ? Link : undefined}
            size='small'
            to={x.link ? x.link : '#'}
            onClick={x.onClick ? x.onClick : null}
            variant='outlined'
            disabled={x.disabled}
            {...x.buttonProps}
          >
            {x.icon && <ControlPointIcon fontSize='small' style={{ marginRight: '5px' }} />}
            {x.text}
          </HButton>
        </Bouncer>
      ))
    )}
  </>
));

HMultipleCreateButton.displayName = 'HMultipleCreateButton';
