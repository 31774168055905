export const DATA_IN_ADMIN = {
  FETCH_ALL_IMPORT_SOURCES: 'FETCH_ALL_IMPORT_SOURCES',
  FETCH_ALL_IMPORT_SOURCES_SUCCESS: 'FETCH_ALL_IMPORT_SOURCES_SUCCESS',
  FETCH_ALL_IMPORT_SOURCES_ERROR: 'FETCH_ALL_IMPORT_SOURCES_ERROR',
  FETCH_ORG_IMPORT_SOURCES: 'FETCH_ORG_IMPORT_SOURCES',
  FETCH_ORG_IMPORT_SOURCES_SUCCESS: 'FETCH_ORG_IMPORT_SOURCES_SUCCESS',
  FETCH_ORG_IMPORT_SOURCES_ERROR: 'FETCH_ORG_IMPORT_SOURCES_ERROR',
  UPDATE_ORG_IMPORT_SOURCES: 'UPDATE_ORG_IMPORT_SOURCES',
  UPDATE_ORG_IMPORT_SOURCES_SUCCESS: 'UPDATE_ORG_IMPORT_SOURCES_SUCCESS',
  UPDATE_ORG_IMPORT_SOURCES_ERROR: 'UPDATE_ORG_IMPORT_SOURCES_ERROR',

  FETCH_ALL_IMPORT_DATA_TYPES: 'FETCH_ALL_IMPORT_DATA_TYPES',
  FETCH_ALL_IMPORT_DATA_TYPES_SUCCESS: 'FETCH_ALL_IMPORT_DATA_TYPES_SUCCESS',
  FETCH_ALL_IMPORT_DATA_TYPES_ERROR: 'FETCH_ALL_IMPORT_DATA_TYPES_ERROR',
  FETCH_ORG_IMPORT_DATA_TYPES: 'FETCH_ORG_IMPORT_DATA_TYPES',
  FETCH_ORG_IMPORT_DATA_TYPES_SUCCESS: 'FETCH_ORG_IMPORT_DATA_TYPES_SUCCESS',
  FETCH_ORG_IMPORT_DATA_TYPES_ERROR: 'FETCH_ORG_IMPORT_DATA_TYPES_ERROR',
  UPDATE_ORG_IMPORT_DATA_TYPES: 'UPDATE_ORG_IMPORT_DATA_TYPES',
  UPDATE_ORG_IMPORT_DATA_TYPES_SUCCESS: 'UPDATE_ORG_IMPORT_DATA_TYPES_SUCCESS',
  UPDATE_ORG_IMPORT_DATA_TYPES_ERROR: 'UPDATE_ORG_IMPORT_DATA_TYPES_ERROR',
};
