import Box from '@mui/material/Box';
import Popover, { popoverClasses } from '@mui/material/Popover';
import React from 'react';
import { LinkComponent } from 'BaseComponents';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';


const StyledPopover = styled(Popover)({
  [`& .${popoverClasses.paper}`]: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderRadius: 2,
    minWidth: '300px',
    boxShadow: '0px 3px 3px 0px rgba(0,0,0,0.2)',
  },
});

const StyledBox = styled(Box)({ backgroundColor: '#FFFFFF' });

export const MenuDropdown = ({
  id,
  children,
  handleClose,
  anchor,
  open,
  items,
  testID,
  ...otherProps
}) => (
  <StyledPopover
    id={id}
    open={open}
    anchorEl={anchor}
    onClose={handleClose}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...otherProps}
  >
    <StyledBox>
      {items?.map((item) => {
        const Icon = item.icon;

        return (
          <LinkComponent
            to={item.path}
            key={item.title}
            sx={{
              padding: '12px',
              fontSize: '12px',
              display: 'flex',
              alignItems: 'center',
              borderTop: 'solid #97a6be 1px',
              '&:hover': {
                color: (theme) => theme.palette.link.main,
              },
            }}
          >
            <Icon fontSize='small' sx={{ marginRight: '10px' }} />
            <Typography style={{ fontSize: 12 }}>
              {item.title}
            </Typography>
          </LinkComponent>
        );
      })}
      {children}
    </StyledBox>
  </StyledPopover>
);
