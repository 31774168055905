import React from 'react';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';


const StyledBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  background: 'repeating-linear-gradient( -45deg, #ffffff, #ffffff 10px, #f5f7fb 10px, #f5f7fb 20px )',
  border: '1px dashed #607d8b',
  padding: 48,
  width: '100%',
});

const NoData = ({ text = 'No Data Available' }) => (
  <StyledBox data-testid='no-data'>
    <Typography variant='h5' color='text'>{text}</Typography>
  </StyledBox>
);

export default NoData;
