import * as spaUrls from 'utils/spaUrls';
import APIDoc from 'pages/APIDoc';
import APIKeyManagement from 'pages/APIKeyManagement/APIKeyManagement';
import AppHeader from 'components/Layouts/Header/AppHeader';
import CrSolutionInvitations from 'pages/CleanRooms/CrSolutionInvitations';
import DeeplinkRedirect from 'pages/DeeplinkRedirect';
import FlowRouter from 'pages/Flow/Router';
import GPGKeyManagement from 'pages/GPGKeyManagement/index';
import HabuHelp from 'pages/HabuHelp';
import HelpDocumentation from 'pages/HelpDocumentation';
import IAMRouterWithNavbar from 'pages/OrganizationManagement/Router';
import InternalAdminRouterWithNavbar from 'pages/InternalAdmin/Router';
import LinkedInAuth from 'pages/LinkedInAuth';
import MetabaseAppEmbed from 'pages/MetabaseAppEmbed';
import NavbarLayout from 'components/Layouts/NavbarLayout';
import PrivateRoute from 'components/Common/PrivateRoute';
import React, { Suspense, lazy } from 'react';
import SupportModeHandler from 'pages/SupportMode/SupportModeHandler';
import SupportPortal from 'pages/SupportPortal';
import UserDefinedFunctions from 'pages/DataIngestion/UserDefinedFunctions';
import componentLoader from 'utils/lazyLoadRetry';
import { Redirect, Route, Switch } from 'react-router-dom';
import { cleanRoomPermissions, userPermissions } from 'utils/appConstants';
import { internalAdmin, organizationManagement } from 'utils/spaUrls';


const AccountAdminDetails = lazy(() => componentLoader(() => import('pages/IAM/AccountAdminDetails')));
const AccountAdminList = lazy(() => componentLoader(() => import('pages/IAM/AccountAdminList')));
const TrustCenterRouter = lazy(() => componentLoader(() => import('pages/TrustCenter/Router')));
// Will use this route to only list invitations for account admin
const AccountAdminInvitations = lazy(() => componentLoader(() => import('pages/IAM/AccountAdminInvitations')));
const AccountDetails = lazy(() => componentLoader(() => import('pages/IAM/AccountDetails')));
const AccountList = lazy(() => componentLoader(() => import('pages/IAM/AccountList')));
const ActivationChannels = lazy(() => componentLoader(() => import('pages/ActivationChannels/Router')));
const AuthRouter = lazy(() => componentLoader(() => import('pages/Authentication/Router')));
const CleanRoomRouter = lazy(() => componentLoader(() => import('pages/CleanRooms/Router')));
const DataInRouter = lazy(() => componentLoader(() => import('pages/DataIngestion/Router')));
const ExperimentsRouter = lazy(() => componentLoader(() => import('pages/Experiments/Router')));
const Forbidden = lazy(() => componentLoader(() => import('pages/ErrorPages/Forbidden')));
const FvaRouter = lazy(() => componentLoader(() => import('pages/FullViewAutomation/Router')));
const MuiComponents = lazy(() => componentLoader(() => import('pages/DesignHelper/MuiComponents')));
const OrgCredentialDetails = lazy(() => componentLoader(() => import('pages/DataCredential/OrgCredentialDetails')));
const OrganizationDetails = lazy(() => componentLoader(() => import('pages/IAM/OrganizationDetails')));
const Organizations = lazy(() => componentLoader(() => import('pages/IAM/Organizations')));
const PageNotFound = lazy(() => componentLoader(() => import('pages/ErrorPages/PageNotFoundWrapper')));
const PropertyDetails = lazy(() => componentLoader(() => import('pages/IAM/PropertyDetails')));
const QuestionRouter = lazy(() => componentLoader(() => import('pages/Question/Router')));
const QuestionManagementRouter = lazy(() => componentLoader(() => import('pages/QuestionManagement/Router')));
const ResourceNotFound = lazy(() => componentLoader(() => import('pages/ErrorPages/ResourceNotFound')));
const RoleDetails = lazy(() => componentLoader(() => import('pages/IAM/RoleDetails')));
const UserDetails = lazy(() => componentLoader(() => import('pages/IAM/UserDetails')));
const UserListRouter = lazy(() => componentLoader(() => import('pages/UserLists/Router')));
const WelcomeDetails = lazy(() => componentLoader(() => import('pages/Welcome/WelcomeDetails')));
const UserListsActivation = lazy(() => componentLoader(() => import('pages/DataOut/UserListsActivation')));
const OfflineConversions = lazy(() => componentLoader(() => import('pages/DataOut/OfflineConversions')));
const DataExports = lazy(() => componentLoader(() => import('pages/DataOut/DataExports')));
const CreateActivation = lazy(() => componentLoader(() => import('pages/DataOut/CreateActivation')));
const DataOutDetails = lazy(() => componentLoader(() => import('pages/DataOut/DataOutDetails')));
const CreateExport = lazy(() => componentLoader(() => import('pages/DataOut/CreateExport')));

const Router = () => (
  <Suspense fallback={<div className='suspense-loader' />}>
    <Switch>
      <Redirect path='/' to={spaUrls.homepage} exact />

      <Route path={spaUrls.auth.landing} component={AuthRouter} />

      <Route path={spaUrls.redirect.helpDocs} component={APIDoc} exact />

      <Route path={spaUrls.redirect.habuHelp} component={HabuHelp} exact />

      <Route path={spaUrls.redirect.helpDocumentation} component={HelpDocumentation} exact />

      <Route path={spaUrls.redirect.supportPortal} component={SupportPortal} exact />

      <Route path={spaUrls.redirect.linkedinAuth} component={LinkedInAuth} exact />

      <PrivateRoute
        path={spaUrls.apiKeyManagement.landing}
        component={NavbarLayout(APIKeyManagement, AppHeader)}
        allowList={[
          userPermissions.superuser,
          userPermissions.accountAdmin,
          userPermissions.orgAdmin,
        ]}
        accessList={[userPermissions.ExternalAPIAccess]}
        exact
      />

      <PrivateRoute
        path={spaUrls.organizationManagement.organization.gpgKeyManagement}
        component={NavbarLayout(GPGKeyManagement, AppHeader)}
        allowList={[userPermissions.superuser, userPermissions.GPGEncryption]}
        exact
      />

      {/* Todo move Organization management into its separate router */}
      {/* Data Credential Management */}
      <PrivateRoute
        path={spaUrls.metabase.habuintelligenceEmbed}
        component={NavbarLayout(MetabaseAppEmbed, AppHeader)}
        allowList={[
          userPermissions.superuser,
          userPermissions.accountAdmin,
          userPermissions.habuIntelligenceAdmin,
          userPermissions.habuIntelligence,
        ]}
        exact
      />
      <PrivateRoute
        path={`${spaUrls.organizationManagement.organization.orgDataCredential}/:id?`}
        component={OrgCredentialDetails}
        exact
      />
      <PrivateRoute
        path={organizationManagement.cleanRoom.OrgApiLinking}
        component={NavbarLayout(CrSolutionInvitations, AppHeader)}
        allowList={[userPermissions.superuser]}
        exact
      />
      <PrivateRoute
        path={spaUrls.cleanRoom.landing}
        component={CleanRoomRouter}
        allowList={['CleanRoomsAdmin', 'CleanRoomsReadOnly', 'CleanRoomsPartnerReadOnly']}
      />
      <PrivateRoute
        component={ActivationChannels}
        path={spaUrls.activationChannels.landing()}
        allowList={[userPermissions.ActivationChannels]}
      />

      <PrivateRoute
        path={spaUrls.dataOut.activationReport()}
        component={NavbarLayout(DataOutDetails)}
        allowList={['DataOutAdmin', 'DataOutReadOnly']}
      />

      <PrivateRoute
        path={spaUrls.dataOut.userListsActivation}
        component={NavbarLayout(UserListsActivation)}
        allowList={['DataOutAdmin', 'DataOutReadOnly']}
      />
      <PrivateRoute
        path={spaUrls.dataOut.offlineConversions}
        component={NavbarLayout(OfflineConversions)}
        allowList={['DataOutAdmin', 'DataOutReadOnly']}
      />
      <PrivateRoute
        path={spaUrls.dataOut.dataExports}
        component={NavbarLayout(DataExports)}
        allowList={['DataOutAdmin', 'DataOutReadOnly']}
      />
      <PrivateRoute
        path={spaUrls.dataOut.createActivation()}
        component={NavbarLayout(CreateActivation)}
        allowList={['DataOutAdmin']}
      />
      <PrivateRoute
        path={spaUrls.dataOut.createExport}
        component={NavbarLayout(CreateExport)}
        allowList={['DataOutAdmin']}
      />
      <PrivateRoute
        path={spaUrls.dataOut.userListsActivation}
        component={NavbarLayout(UserListsActivation)}
        allowList={['DataOutAdmin', 'DataOutReadOnly']}
      />
      <PrivateRoute
        path={spaUrls.dataOut.offlineConversions}
        component={NavbarLayout(OfflineConversions)}
        allowList={['DataOutAdmin', 'DataOutReadOnly']}
      />
      <PrivateRoute
        path={spaUrls.dataOut.dataExports}
        component={NavbarLayout(DataExports)}
        allowList={['DataOutAdmin', 'DataOutReadOnly']}
      />

      <PrivateRoute
        component={QuestionRouter}
        path={spaUrls.question.builder.wizard.landing}
        allowList={[
          userPermissions.superuser,
          userPermissions.questionBuilderAdmin,
          cleanRoomPermissions.questionBuilder,
        ]}
      />
      <PrivateRoute
        component={QuestionRouter}
        path={spaUrls.question.builder.clone.landing}
        allowList={[
          userPermissions.superuser,
          userPermissions.questionBuilderAdmin,
          cleanRoomPermissions.questionBuilder,
        ]}
      />
      {/* TODO change permission to SU for testing with product */}
      <PrivateRoute
        component={QuestionManagementRouter}
        path={spaUrls.questionManagement.landing}
        allowList={[
          userPermissions.orgAdmin,
          userPermissions.questionManagementAdmin,
          userPermissions.questionBuilderAdmin,
        ]}
      />
      {/* TODO deprecate Experiments Router */}
      <PrivateRoute
        component={ExperimentsRouter}
        path={spaUrls.experiments.landing}
        allowList={['ExperimentAdmin', 'ExperimentReadOnly']}
      />
      {/* TODO deprecate Full View Automation */}
      <PrivateRoute
        path='/full-view-automation/'
        component={FvaRouter}
        allowList={[userPermissions.superuser]}
      />
      <PrivateRoute
        path={spaUrls.dataIn.landing}
        component={DataInRouter}
        allowList={['DataImportJobsAdmin', 'DataImportJobsReadOnly']}
      />
      <PrivateRoute
        path={spaUrls.dataIn.userDefinedFunctions}
        component={NavbarLayout(UserDefinedFunctions)}
        accessList={[userPermissions.UserDefinedFunctionSupport]}
      />
      {/* IAM */}
      <PrivateRoute
        path={spaUrls.iam.accountList}
        component={AccountList}
        exact
      />
      <PrivateRoute
        path={spaUrls.iam.organizationList}
        component={Organizations}
        exact
      />
      <PrivateRoute
        path='/iam/account/organization/:id?'
        component={OrganizationDetails}
        allowList={['OrgAdmin']}
        exact
      />
      <PrivateRoute
        path='/iam/account/:id?'
        component={AccountDetails}
        allowList={[userPermissions.superuser]}
        exact
      />
      <PrivateRoute
        path={spaUrls.trustCenter.landing}
        component={TrustCenterRouter}
        accessList={[userPermissions.EnableTrustCenter]}
      />
      <PrivateRoute
        path={spaUrls.iam.accountAdmin}
        component={AccountAdminList}
        allowList={[userPermissions.superuser, userPermissions.accountAdmin]}
        exact
      />
      <PrivateRoute
        path={spaUrls.iam.accountAdminInvitations}
        component={AccountAdminInvitations}
        blockList={[userPermissions.consoleUser]}
        exact
      />
      <PrivateRoute
        path={spaUrls.iam.roleDetails()}
        component={RoleDetails}
        exact
      />
      <PrivateRoute
        path={spaUrls.iam.userDetails()}
        component={UserDetails}
        exact
      />
      <PrivateRoute
        path={spaUrls.iam.accountAdminDetails()}
        component={AccountAdminDetails}
        allowList={[userPermissions.superuser, userPermissions.accountAdmin]}
        exact
      />
      <PrivateRoute
        path={internalAdmin.landing}
        component={InternalAdminRouterWithNavbar}
        allowList={[userPermissions.superuser]}
      />
      <PrivateRoute
        path={organizationManagement.landing}
        component={IAMRouterWithNavbar}
      />
      <PrivateRoute
        path={spaUrls.flow.landing}
        component={FlowRouter}
        allowList={[userPermissions.Flow]}
        blockList={[userPermissions.consoleUser, userPermissions.accountAdmin]}
      />
      <PrivateRoute
        path='/properties/:id?'
        component={PropertyDetails}
        exact
      />
      <PrivateRoute
        allowList={['UserListAdmin', 'UserListReadOnly']}
        component={UserListRouter}
        path={spaUrls.userList.list}
      />
      <PrivateRoute
        path={spaUrls.supportMode.enable}
        component={SupportModeHandler}
      />
      <PrivateRoute path={spaUrls.homepage} component={WelcomeDetails} />
      {/* TODO deprecate */}
      <PrivateRoute
        allowList={[userPermissions.superuser]}
        component={MuiComponents}
        path='/design-helpers'
      />
      <PrivateRoute
        path={spaUrls.redirect.deeplinkRedirect}
        component={DeeplinkRedirect}
        exact
      />
      <PrivateRoute
        path={spaUrls.errorPages.pageNotFound}
        component={PageNotFound}
        exact
      />
      <PrivateRoute
        path={spaUrls.errorPages.forbidden}
        component={Forbidden}
        exact
      />
      <PrivateRoute
        path={spaUrls.errorPages.resourceNotFound}
        component={ResourceNotFound}
        exact
      />

      <Redirect from='*' to={spaUrls.errorPages.pageNotFound} />
    </Switch>
  </Suspense>
);

export default Router;
