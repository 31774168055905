import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import React, { useEffect, useMemo, useState } from 'react';
import TextField from '@mui/material/TextField';
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import { isEmpty } from 'lodash';


export const OrgParameterTextField = ({ ...props }) => (
  <TextField
    fullWidth
    required
    size='small'
    InputLabelProps={{ shrink: true }}
    margin='normal'
    variant='outlined'
    {...props}
  />
);

export const OrgParameterSelect = ({ multiple, onChange, ...restProps }) => {
  const filter = useMemo(() => createFilterOptions(), []);

  return (
    <Autocomplete
      fullWidth
      size='small'
      variant='outlined'
      onChange={(_, value) => onChange(value)}
      renderOption={({ ...optionProps }, option, { selected }) => (
        <li key={option} {...optionProps}>
          {multiple ? (
            <Checkbox
              icon={<CheckBoxOutlineBlank fontSize='small' />}
              checkedIcon={<CheckBox fontSize='small' />}
              style={{ marginRight: 0 }}
              checked={selected}
            />
          ) : null}
          {option}
        </li>
      )}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option);
        if (inputValue !== '' && !isExisting) {
          filtered.push(inputValue);
        }

        return filtered;
      }}
      multiple={multiple}
      noOptionsText='No options. Type to add...'
      {...restProps}
    />
  );
};

const useConvertValueToOrgParameter = ({ multiple, value, onChange }) => {
  const [selectValue, setSelectValue] = useState('');
  const [selectMultiple, setSelectMultiple] = useState(false);

  // Transform newValue into a string from array value (if multiple) or string (if no multiple)
  const handleOnChange = (newValue) => {
    if (selectMultiple && newValue !== '') {
      onChange(newValue.join(','));
    }
    else {
      onChange(newValue);
    }
  };

  // Transform prop value to an array (if multiple) or keep as a string (if not multiple)
  useEffect(() => {
    if (multiple) {
      setSelectValue(value === '' ? [] : String(value).split(','));
    }
    else {
      setSelectValue(value);
    }

    setSelectMultiple(multiple);
  }, [multiple, value]);

  return {
    handleOnChange,
    selectValue,
    resetSelectValue: () => setSelectValue(multiple ? [] : ''),
    selectMultiple,
  };
};

export const OrgParameterSelectByName = ({
  nameParameterState, options, onChange, value, multiple, ...restProps
}) => {
  const {
    handleOnChange,
    selectMultiple,
    selectValue,
    resetSelectValue,
  } = useConvertValueToOrgParameter({ multiple, value, onChange });

  // Reset value and input value when name parameter changes
  useEffect(() => {
    if (!isEmpty(selectValue)) {
      resetSelectValue();
      onChange('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nameParameterState]);

  return (
    <OrgParameterSelect
      multiple={selectMultiple}
      value={selectValue}
      onChange={handleOnChange}
      options={options}
      {...restProps}
    />
  );
};
