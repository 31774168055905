import Bouncer from 'components/Common/Bouncer';
import Box from '@mui/material/Box';
import ButtonGroup from '@mui/material/ButtonGroup';
import CachedIcon from '@mui/icons-material/Cached';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import ListFilters from 'components/Common/ListFilters';
import React, { useEffect, useMemo } from 'react';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import localStorage from 'utils/localStorage';
import { CACHE, DEFAULT_AUTO_REFRESH_INTERVAL } from 'utils/appConstants';
import { FilterList, Reorder, ViewModule } from '@mui/icons-material';
import { HButton } from 'BaseComponents';
import { Link } from 'react-router-dom';
import { Paper } from '@mui/material';
import { styled } from '@mui/material/styles';


const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
}));

export const LayoutStyles = {
  card: { text: 'Card View', icon: ViewModule },
  list: { text: 'List View', icon: Reorder },
};

export const ListControls = (
  {
    allFilters = null,
    appliedFilters,
    createButton,
    createPermissions = [],
    helpLinkComp,
    layout: defaultLayout,
    layoutOnToggle,
    multipleCreateButton,
    onFiltersChange,
    onRefresh,
    onSearch,
    searchAttribute,
    searchText,
    autoRefresh,
  },
) => {
  const layout = defaultLayout === LayoutStyles.card.text ? LayoutStyles.list : LayoutStyles.card;

  const onToggleLayout = () => {
    const newLayout = (layout.text === LayoutStyles.card.text)
      ? LayoutStyles.card : LayoutStyles.list;
    layoutOnToggle(newLayout);
    localStorage.set(CACHE.defaultLayout, newLayout.text);
  };

  const [showFilters, toggleShowFilters] = React.useState(false);
  const [isAutoRefresh, setIsAutoRefresh] = React.useState(false);

  const autoRefreshConfig = useMemo(() => {
    if (autoRefresh) {
      setIsAutoRefresh(autoRefresh.enabled);
    }
    return {
      allowed: false,
      enabled: false,
      interval: DEFAULT_AUTO_REFRESH_INTERVAL,
      showTooltip: true,
      ...autoRefresh,
    };
  }, [autoRefresh]);

  const handleAutoRefresh = (event) => {
    setIsAutoRefresh(event.target.checked);
  };

  useEffect(() => {
    const autoRefreshIntervalId = setInterval(() => {
      if (isAutoRefresh) {
        onRefresh();
      }
      else {
        clearInterval(autoRefreshIntervalId);
      }
    }, autoRefreshConfig.interval);

    return () => clearInterval(autoRefreshIntervalId);
  }, [isAutoRefresh, onRefresh, autoRefreshConfig]);

  return (
    <StyledPaper elevation={0}>
      <Grid container spacing={2} justifyContent='space-between'>
        <Grid item xs={12} md={6}>
          <Box display='flex'>
            <ButtonGroup size='small' variant='outlined'>
              {layoutOnToggle ? (
                <HButton onClick={onToggleLayout} size='small' variant='outlined'>
                  <layout.icon fontSize='small' style={{ marginRight: '5px' }} />
                  {layout.text}
                </HButton>
              ) : null}
              {allFilters ? (
                <HButton
                  onClick={() => toggleShowFilters(!showFilters)}
                  size='small'
                  variant='outlined'
                >
                  <FilterList fontSize='small' style={{ marginRight: '5px' }} />
                  {onSearch && searchAttribute ? 'Filters & Search' : 'Filters'}
                </HButton>
              ) : null}
              {autoRefreshConfig?.allowed && (
                <HButton size='small' variant='outlined'>
                  <Tooltip
                    title={autoRefreshConfig.showTooltip
                      ? `Enabling this will auto refresh data after every ${autoRefreshConfig.interval / 1000} seconds`
                      : ''}
                    arrow
                    placement='top'
                  >
                    <FormGroup>
                      <FormControlLabel
                        sx={{ m: 0 }}
                        control={(
                          <Switch
                            size='small'
                            checked={isAutoRefresh}
                            onChange={handleAutoRefresh}
                            data-testid='auto-refresh-toggle'
                          />
                        )}
                        label={<Typography sx={{ fontSize: 'inherit' }}>Auto Refresh</Typography>}
                      />
                    </FormGroup>
                  </Tooltip>
                </HButton>
              )}
            </ButtonGroup>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box display='flex' justifyContent='flex-end'>
            <Stack direction='row' spacing={2} alignItems='center'>
              {helpLinkComp}
              {createButton && (
              <Bouncer allowList={createPermissions}>
                <HButton
                  data-testid='create-button'
                  component={createButton.link ? Link : undefined}
                  size='small'
                  to={createButton.link ? createButton.link : '#'}
                  onClick={createButton.onClick ? createButton.onClick : null}
                  variant='contained'
                  disabled={createButton.disabled}
                >
                  <ControlPointIcon fontSize='small' style={{ marginRight: '5px' }} />
                  {createButton.text}
                </HButton>
              </Bouncer>
              )}
              {multipleCreateButton && (
                multipleCreateButton.map(x => (
                  <Bouncer allowList={x.permission} key={x.text}>
                    <HButton
                      component={x.link ? Link : undefined}
                      size='small'
                      to={x.link ? x.link : '#'}
                      onClick={x.onClick ? x.onClick : null}
                      variant='outlined'
                      disabled={x.disabled}
                    >
                      <ControlPointIcon fontSize='small' style={{ marginRight: '5px' }} />
                      {x.text}
                    </HButton>
                  </Bouncer>
                ))
              )}
              {onRefresh && (
              <Box display='flex' justifyContent='flex-end'>
                <HButton size='small' onClick={onRefresh}>
                  <CachedIcon />
                </HButton>
              </Box>
              )}
            </Stack>
          </Box>
        </Grid>
        {showFilters ? (
          <Grid item xs={12}>
            <ListFilters
              allFilters={allFilters}
              appliedFilters={appliedFilters}
              onChange={onFiltersChange}
              searchAttribute={searchAttribute}
              onSearch={onSearch}
              searchText={searchText}
            />
          </Grid>
        ) : null}
      </Grid>
    </StyledPaper>
  );
};
