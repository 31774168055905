import * as actions from 'pages/InternalAdmin/organization/DataIn/redux/actions';
import Logger from 'components/Common/Logger';
import http from 'utils/http';
import { DATA_IN_ADMIN } from 'redux/actions/Types';
import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import { showAlert } from 'redux/actions/AlertActions';


function* fetchAllImportSources(action) {
  const { dataSources, error } = yield call(http.get, action.url);
  if (error) {
    Logger.error(error, 'IAM: Fetching all import data sources');
    yield put(showAlert({ message: error, type: 'error' }));
    return yield put(actions.fetchAllImportSourcesError(error));
  }
  else {
    return yield put(actions.fetchAllImportSourcesSuccess(dataSources));
  }
}

function* fetchOrgImportSources(action) {
  const { dataSources, error } = yield call(http.get, action.url);
  if (error) {
    Logger.error(error, 'IAM: Fetching all import data sources');
    yield put(showAlert({ message: error, type: 'error' }));
    return yield put(actions.fetchOrgImportSourcesError(error));
  }
  else {
    return yield put(actions.fetchOrgImportSourcesSuccess(dataSources));
  }
}

function* updateOrgImportSources(action) {
  const { dataSources, error } = yield call(http.post, action.url, action.payload);
  if (error) {
    Logger.error(error, 'IAM: Updating Organization Import Sources');
    yield put(showAlert({ message: error, type: 'error' }));
    return yield put(actions.updateOrgImportSourcesError(error));
  }
  else {
    yield put(showAlert({ message: 'Successfully updated organization products', type: 'success' }));
    return yield put(actions.updateOrgImportSourcesSuccess(dataSources));
  }
}

function* fetchAllImportDataTypes(action) {
  const { dataTypes, error } = yield call(http.get, action.url);
  if (error) {
    Logger.error(error, 'IAM: Fetching all import data sources');
    yield put(showAlert({ message: error, type: 'error' }));
    return yield put(actions.fetchAllImportDataTypesError(error));
  }
  else {
    return yield put(actions.fetchAllImportDataTypesSuccess(dataTypes));
  }
}

function* fetchOrgImportDataTypes(action) {
  const { dataTypes, error } = yield call(http.get, action.url);
  if (error) {
    Logger.error(error, 'IAM: Fetching all import data sources');
    yield put(showAlert({ message: error, type: 'error' }));
    return yield put(actions.fetchOrgImportDataTypesError(error));
  }
  else {
    return yield put(actions.fetchOrgImportDataTypesSuccess(dataTypes));
  }
}

function* updateOrgImportDataTypes(action) {
  const { dataTypes, error } = yield call(http.post, action.url, action.payload);
  if (error) {
    Logger.error(error, 'IAM: Updating Organization Import Sources');
    yield put(showAlert({ message: error, type: 'error' }));
    return yield put(actions.updateOrgImportDataTypesError(error));
  }
  else {
    yield put(showAlert({ message: 'Successfully updated organization products', type: 'success' }));
    return yield put(actions.updateOrgImportDataTypesSuccess(dataTypes));
  }
}

export default function* root() {
  yield all([
    takeLatest(DATA_IN_ADMIN.FETCH_ALL_IMPORT_SOURCES, fetchAllImportSources),
    takeLatest(DATA_IN_ADMIN.FETCH_ORG_IMPORT_SOURCES, fetchOrgImportSources),
    takeLatest(DATA_IN_ADMIN.UPDATE_ORG_IMPORT_SOURCES, updateOrgImportSources),
    takeLatest(DATA_IN_ADMIN.FETCH_ALL_IMPORT_DATA_TYPES, fetchAllImportDataTypes),
    takeLatest(DATA_IN_ADMIN.FETCH_ORG_IMPORT_DATA_TYPES, fetchOrgImportDataTypes),
    takeLatest(DATA_IN_ADMIN.UPDATE_ORG_IMPORT_DATA_TYPES, updateOrgImportDataTypes),
  ]);
}

export {
  fetchAllImportSources,
  fetchOrgImportSources,
  updateOrgImportSources,
  fetchAllImportDataTypes,
  fetchOrgImportDataTypes,
  updateOrgImportDataTypes,
};
