import AccountDetails from 'pages/InternalAdmin/account/pages/ProductAccess/AccountDetails';
import Grid from '@mui/material/Grid';
import ListViewTable from 'components/Common/ListViewTable';
import PageHeader from 'components/Common/PageHeader';
import ProductListTable from 'pages/InternalAdmin/account/pages/ProductAccess/ProductListTable';
import React, { useEffect, useState } from 'react';
import { crumbs } from 'components/Common/BreadcrumbsNavigation';
import {
  fetchAccountList,
  fetchAccountProducts,
  fetchProductList,
  submitAccountProducts,
  updateAccountProductReset,
} from 'redux/actions/IamActions';
import { internalAdmin } from 'utils/spaUrls';
import { switchAccount } from 'redux/actions/AuthenticationActions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSearchFlag } from 'pages/InternalAdmin/account/pages/ProductAccess/useSearchFlag';


const ProductAccess = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { name: accountName } = useSelector(state => state.session.user.primaryAccount);
  const accountID = useSelector(state => state.session.user.primaryAccount.ID);

  const {
    accountList,
    productList,
    accountProducts,
    accountProductsUpdated,
  } = useSelector(state => state.iam);
  const {
    search,
    setSearch,
    productListResult,
  } = useSearchFlag(productList);

  const [products, updateProducts] = useState([]);
  useEffect(() => {
    updateProducts(accountProducts);
  }, [accountProducts]);

  const onSubmit = () => {
    const data = products.map(el => el.ID);
    dispatch(submitAccountProducts(accountID, { productIDs: data }));
  };

  useEffect(() => {
    dispatch(fetchProductList());
    dispatch(fetchAccountProducts(accountID));
    dispatch(fetchAccountList());
  }, [accountID, dispatch]);

  useEffect(() => {
    if (accountProductsUpdated) {
      dispatch(updateAccountProductReset());
      history.push(internalAdmin.landing);
    }
  }, [accountProductsUpdated, dispatch, history]);

  return (
    <>
      <PageHeader
        title='Product Access'
        breadcrumbs={[crumbs.helium, crumbs.internalAdmin]}
      />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <AccountDetails
            search={search}
            setSearch={setSearch}
            accountName={accountName}
            accounts={accountList}
            switchAccount={newAccount => dispatch(switchAccount(newAccount))}
          />
        </Grid>
        <Grid item xs={12}>
          <ListViewTable list={productListResult}>
            <ProductListTable
              accProducts={products}
              productList={productListResult}
              updateAccProducts={updateProducts}
              onSubmit={onSubmit}
            />
          </ListViewTable>
        </Grid>
      </Grid>
    </>
  );
};

export default ProductAccess;
