import { axiosInstance } from 'utils/http';
import { organizationIdentity } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch } from 'react-redux';
import { useQuery } from 'react-query';


export const useGetIdentityCredentialSource = () => {
  const dispatch = useDispatch();

  return useQuery(
    ['identityCredentials'],
    () => axiosInstance.get(organizationIdentity('').credentialsSource, {
      params: {
        name: 'Identity Resolution RampID',
      },
    }),
    {
      select: (res) => res?.data?.source ?? {},
      onError: () => {
        dispatch(showAlert({
          message: 'Failed to fetch identity credentials',
          type: 'error',
        }));
      },
    },
  );
};
