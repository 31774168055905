import localStorage from 'utils/localStorage';
import memoize from 'lodash/memoize';
import { CACHE } from 'utils/appConstants';
import { get, lowerCase } from 'lodash';
// eslint-disable-next-line import/no-extraneous-dependencies
import React from 'react';
import { sanitize } from 'dompurify';
import { useLocation } from 'react-router-dom';


const nonZeroToFixed = (num, d = 1) => num.toFixed(d).replace(/\.?0+$/, '');

export const abbrNum = (number, showZero = false) => {
  const MILLION = 1000000;
  const BILLION = 1000000000;
  const TRILLION = 1000000000000;

  if (typeof number !== 'number' || (number === 0 && !showZero) || Number.isNaN(number)) return '-';
  else if (number < 1000) return number;
  else if (number < MILLION) return `${nonZeroToFixed(number / 1000, 1)} K`;
  else if (number < BILLION) return `${nonZeroToFixed(number / MILLION, 1)} M`;
  else if (number < TRILLION) return `${nonZeroToFixed(number / BILLION, 1)} B`;
  else return `${nonZeroToFixed(number / TRILLION, 1)} T`;
};

export const buildQueryString = (filter = {}, url = '') => {
  const params = Object.keys(filter).filter(k => !!filter[k]).map(i => `${i}=${encodeURIComponent(filter[i])}`).join('&');
  if (!params) { return ''; }
  return url.includes('?') ? `&${params}` : `?${params}`;
};

export const buildNestedQueryString = (filter = {}) => {
  const params = Object.keys(filter).filter(k => !!filter[k]).map((i) => {
    if (Array.isArray(filter[i])) {
      return `${i}=${JSON.stringify(filter[i])}`;
    }
    else {
      return `${i}=${encodeURIComponent(filter[i])}`;
    }
  }).join('&');
  return `?${params}`;
};

export const countDecimals = (num) => {
  if (Math.floor(num.valueOf()) === num.valueOf()) return 0;
  return num.toString().split('.')[1]?.length || 0;
};

export const defaultDecimal = (num) => {
  if (countDecimals(num) > 2) {
    return Number(num.toFixed(2));
  }
  return num;
};

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const pow = k ** i;
  return `${parseFloat((bytes / pow).toFixed(dm))} ${sizes[i]}`;
};

export const formatCurrency = (number, currency = '$') => `${currency}${abbrNum(number)}`;

/**
 * Formats a number using fixed-point notation.
 * If decimalPoints is omitted, set decimalNum to 2 as default.
 * If decimalPoints is 0, return it as integer.
 * @param {integer} num: The given integer number
 * @param {integer} decimalPoints: The number of digits to appear after the decimal point
 * @returns {Number} The formatted number
 */
export const formatDecimal = (num, decimalPoints) => {
  let digits;
  if (!decimalPoints && decimalPoints !== 0) {
    digits = 2;
  }
  else {
    digits = decimalPoints;
  }
  if (typeof num !== 'number') {
    return num;
  }
  return Number((Math.round(num * (10 ** digits)) / (10 ** digits))
    .toFixed(digits));
};

export const formatNumber = num => {
  const [intNum, floatNum] = num.toString().split('.');
  if (floatNum) {
    return `${intNum.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}.${floatNum}`;
  }
  else {
    return `${intNum.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
  }
};

export const getInitials = (fullName) => {
  const [firstName, lastName] = (fullName || '').split(' ');
  return `${get(firstName, '[0]', '')}${get(lastName, '[0]', '')}`;
};

export const isHelium = (url = window.location.pathname) => url.indexOf('/helium') >= 0;

export const last = (list) => {
  if (!list) return null;
  const cake = ((list instanceof Array || list instanceof String)) ? list : list.toString();
  const [lastPiece] = cake.slice(-1);
  return lastPiece;
};

export const searchBy = (list, field, text) => {
  if (!list) return [];
  if (!field || !text) return list;
  return list.filter((item) => {
    const fieldValue = item[field] || '';
    return fieldValue.toLowerCase().indexOf(text.toLowerCase()) >= 0;
  });
};

export const snakeToDisplayString = memoize((str = '') => {
  const words = str.toLowerCase().split('_').map(i => i.charAt(0).toUpperCase() + i.slice(1));
  return words.join(' ');
});


export const prettifyHierarchy = (str = '', hierarchyOp = '.') => str.split(hierarchyOp)
  .map(snakeToDisplayString).join(' → ');

export const trimText = (text, length = 20) => {
  if (!text || !(typeof text === 'string' || text instanceof String)) { return text; }
  else if (text.length > length) { return `${text.substring(0, length)}...`; }
  else { return text; }
};

export const updateArrayIndex = (list, index, value) => {
  if (!list) { return []; }
  if (!Array.isArray(list)) { return []; }
  if (index === undefined || index === null) { return list; }
  if (index < 0 || index > list.length) { return list; }
  return [...list.slice(0, index), value, ...list.slice(index + 1)];
};

export const useDefaultLayout = () => localStorage.get(CACHE.defaultLayout);

export const useSideNavDefaultLayout = () => localStorage.get(CACHE.sideNavLayout);

// Custom hook - inspired from react-router-dom
export const useQuery = () => new URLSearchParams(useLocation().search);

export const hasWhiteSpace = (s) => s.indexOf(' ') >= 0;

/**
 * Takes a string and encodes it to Base64
  * @param {string} decodedString
  * @returns {string}
 */
export const encodeBase64 = (decodedString) => btoa(decodedString);

/**
 * Takes a string and decodes it from Base64
 * @param {string} encodedString
 * @returns {string}
 */
export const decodedBase64 = (encodedString) => atob(encodedString);

/**
 * Take a string and download file with the given string.
 * @param {string} fileData
 * @param {string} fileName
 */
export const downloadFile = (fileData, fileName = 'textFile') => {
  const blob = new Blob([fileData], { type: 'application/octet-stream' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.download = `${fileName}`;
  link.href = url;
  link.click();
  URL.revokeObjectURL(url);
};

export const getRgba = (color, alpha = 0) => {
  // Check if the color is in the RGB format
  if (color.startsWith('rgb')) {
    // If it is, convert it to the RGBA format
    const values = color.split(/[\s,)(]+/);
    return `rgba(${values[1]},${values[2]},${values[3]},${alpha})`;
  }
  // If not, convert the hexadecimal color code to an RGBA color code
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
  return result ? `rgba(${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(result[3], 16)},${alpha})` : null;
};

// It returns a display string regardless of the case
export const toDisplayString = (str = '') => {
  const words = lowerCase(str).split(' ').map(i => i.charAt(0).toUpperCase() + i.slice(1));
  return words.join(' ');
};

export const copyToClipboard = async (textString = '') => {
  try {
    await navigator.clipboard.writeText(textString);
    return true;
  }
  catch (err) {
    return false;
  }
};

export const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

export const isHtml = (str) => {
  // Basic check for null, undefined, or empty strings
  if (!str || typeof str !== 'string' || str.trim() === '') {
    return false;
  }

  // Check for presence of <tag> structures
  const tagPattern = /<[^>]+>/;
  if (tagPattern.test(str)) {
    return true;
  }

  // Enhanced checks for common HTML entities or doctype
  const commonPatterns = [
    /<!DOCTYPE html>/i, // Doctype declaration
    /&[a-zA-Z]{2,};/, // HTML entities like &nbsp;, &lt;, etc.
    /<\/?\w+.*?>/, // Opening or closing tags with attributes
  ];

  return commonPatterns.some(pattern => pattern.test(str));
};

export const renderHtmlSafely = (str) => {
  if (!isHtml(str)) {
    return str;
  }

  // eslint-disable-next-line react/react-in-jsx-scope,react/no-danger
  return <div dangerouslySetInnerHTML={{ __html: sanitize(str) }} />;
};

// Function to get the value from the object using the field path dynamically
export function getValueByFieldPath(obj, fieldPath) {
  const parts = fieldPath?.replace(/\[(\d+)\]/g, '.$1').split('.');
  return parts?.reduce((acc, part) => acc?.[part], obj) ?? [];
}
