import { DATA_CONNECTION } from 'redux/actions/Types';
import { cloneDeep } from 'lodash';


const initialState = {
  activeStep: 1,
  job: {},
  parameters: [],
  uploadFiles: {},
  errors: {},
};

const dataConnectionReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case DATA_CONNECTION.RESET: {
      return cloneDeep(initialState);
    }
    case DATA_CONNECTION.SET_ACTIVE_STEP: {
      return {
        ...state,
        activeStep: payload,
      };
    }
    case DATA_CONNECTION.UPDATE_JOB: {
      return {
        ...state,
        job: { ...state.job, ...payload },
      };
    }
    case DATA_CONNECTION.SET_PARAMETERS: {
      return {
        ...state,
        parameters: payload,
      };
    }
    case DATA_CONNECTION.MERGE_PARAMETERS: {
      return {
        ...state,
        parameters: payload.reduce((acc, dsParam) => {
          const matchingParams = state.parameters.filter(
            p => p.importDataSourceParameterID === dsParam.importDataSourceParameterID,
          );
          if (matchingParams.length > 0) {
            matchingParams.forEach(matchingParam => {
              acc.push({
                ...dsParam,
                value: matchingParam.value,
                ID: matchingParam.ID,
                dependantParameters: matchingParam.dependantParameters,
              });
            });
          }
          else {
            acc.push(dsParam);
          }
          return acc;
        }, []),
      };
    }
    case DATA_CONNECTION.UPDATE_PARAMETER: {
      const { value } = payload;
      const params = state.parameters.map(param => {
        if (param.ID === payload.ID) {
          return { ...param, value };
        }
        if (param.hasDependencies && param.dependantParameters.length) {
          const depParams = param.dependantParameters.map(dp => {
            if (dp.ID === payload.ID) {
              return { ...dp, value };
            }
            return dp;
          });
          return { ...param, dependantParameters: depParams };
        }
        return param;
      });

      return {
        ...state,
        parameters: params,
      };
    }
    case DATA_CONNECTION.UPDATE_UPLOAD_FILES: {
      return {
        ...state,
        uploadFiles: { ...state.uploadFiles, ...payload },
      };
    }
    case DATA_CONNECTION.UPDATE_ERRORS: {
      return {
        ...state,
        errors: payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default dataConnectionReducer;
