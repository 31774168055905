export const IAM = {
  FETCH_ACCOUNT_LIST: 'FETCH_ACCOUNT_LIST',
  FETCH_DEMO_ACCOUNT_LIST: 'FETCH_DEMO_ACCOUNT_LIST',
  RECEIVE_ACCOUNT_LIST_SUCCESS: 'RECEIVE_ACCOUNT_LIST',
  RECEIVE_ACCOUNT_LIST_ERROR: 'RECEIVE_ACCOUNT_LIST_ERROR',
  RECEIVE_DEMO_ACCOUNT_LIST_SUCCESS: 'RECEIVE_DEMO_ACCOUNT_LIST',
  RECEIVE_DEMO_ACCOUNT_LIST_ERROR: 'RECEIVE_DEMO_ACCOUNT_LIST_ERROR',
  FETCH_ACCOUNT: 'FETCH_ACCOUNT',
  FETCH_ACCOUNT_SUCCESS: 'FETCH_ACCOUNT_SUCCESS',
  FETCH_ACCOUNT_FAILURE: 'FETCH_ACCOUNT_FAILURE',
  CREATE_ACCOUNT: 'CREATE_ACCOUNT',
  CONFIRM_ACCOUNT_CREATION_SUCCESS: 'CONFIRM_ACCOUNT_CREATION_SUCCESS',
  CONFIRM_ACCOUNT_CREATION_FAILURE: 'CONFIRM_ACCOUNT_CREATION_FAILURE',
  CREATE_ACCOUNT_ADMINISTRATOR: 'CREATE_ACCOUNT_ADMINISTRATOR',
  CREATE_ACCOUNT_ADMINISTRATOR_SUCCESS: 'CREATE_ACCOUNT_ADMINISTRATOR_SUCCESS',
  CREATE_ACCOUNT_ADMINISTRATOR_FAILURE: 'CREATE_ACCOUNT_ADMINISTRATOR_FAILURE',
  UPDATE_ACCOUNT: 'UPDATE_ACCOUNT',
  UPDATE_ACCOUNT_SUCCESS: 'UPDATE_ACCOUNT_SUCCESS',
  UPDATE_ACCOUNT_FAILURE: 'UPDATE_ACCOUNT_FAILURE',
  UPDATE_ACCOUNT_PRODUCTS_RESET: 'UPDATE_ACCOUNT_PRODUCTS_RESET',
  FETCH_ACCOUNT_ADMIN_LIST: 'FETCH_ACCOUNT_ADMIN_LIST',
  FETCH_ACCOUNT_ADMIN_LIST_ERROR: 'FETCH_ACCOUNT_ADMIN_LIST_ERROR',
  FETCH_ACCOUNT_ADMIN_LIST_SUCCESS: 'FETCH_ACCOUNT_ADMIN_LIST_SUCCESS',

  UPDATE_ADMIN: 'UPDATE_ADMIN',
  CONFIRM_ADMIN_UPDATION_SUCCESS: 'CONFIRM_ADMIN_UPDATION_SUCCESS',
  CONFIRM_ADMIN_UPDATION_ERROR: 'CONFIRM_ADMIN_UPDATION_ERROR',
  FETCH_ADMIN: 'FETCH_ADMIN',
  FETCH_ADMIN_ERROR: 'FETCH_ADMIN_ERROR',
  RECEIVE_ADMIN: 'RECEIVE_ADMIN',

  FETCH_ORGANIZATION_LIST: 'FETCH_ORGANIZATION_LIST',
  RECEIVE_ORGANIZATION_LIST_SUCCESS: 'RECEIVE_ORGANIZATION_LIST_SUCCESS',
  RECEIVE_ORGANIZATION_LIST_FAILURE: 'RECEIVE_ORGANIZATION_LIST_FAILURE',
  FETCH_ORGANIZATION: 'FETCH_ORGANIZATION',
  FETCH_ORGANIZATION_SUCCESS: 'FETCH_ORGANIZATION_SUCCESS',
  FETCH_ORGANIZATION_FAILURE: 'FETCH_ORGANIZATION_FAILURE',
  CREATE_ORGANIZATION: 'CREATE_ORGANIZATION',
  CONFIRM_ORGANIZATION_CREATION_SUCCESS: 'CONFIRM_ORGANIZATION_CREATION_SUCCESS',
  CONFIRM_ORGANIZATION_CREATION_FAILURE: 'CONFIRM_ORGANIZATION_CREATION_FAILURE',
  UPDATE_ORGANIZATION: 'UPDATE_ORGANIZATION',
  UPDATE_ORGANIZATION_SUCCESS: 'UPDATE_ORGANIZATION_SUCCESS',
  UPDATE_ORGANIZATION_FAILURE: 'UPDATE_ORGANIZATION_FAILURE',

  FETCH_ROLE_LIST: 'FETCH_ROLE_LIST',
  RECEIVE_ROLE_LIST_SUCCESS: 'RECEIVE_ROLE_LIST_SUCCESS',
  RECEIVE_ROLE_LIST_ERROR: 'RECEIVE_ROLE_LIST_ERROR',
  FETCH_ROLE: 'FETCH_ROLE',
  RECEIVE_ROLE_SUCCESS: 'RECEIVE_ROLE_SUCCESS',
  RECEIVE_ROLE_ERROR: 'RECEIVE_ROLE_ERROR',
  CREATE_ROLE: 'CREATE_ROLE',
  CONFIRM_ROLE_CREATION_SUCCESS: 'CONFIRM_ROLE_CREATION_SUCCESS',
  CONFIRM_ROLE_CREATION_FAILURE: 'CONFIRM_ROLE_CREATION_FAILURE',
  UPDATE_ROLE: 'UPDATE_ROLE',
  CONFIRM_ROLE_UPDATION_SUCCESS: 'CONFIRM_ROLE_UPDATION_SUCCESS',
  CONFIRM_ROLE_UPDATION_FAILURE: 'CONFIRM_ROLE_UPDATION_FAILURE',

  FETCH_USER_LIST: 'FETCH_USER_LIST',
  RECEIVE_USER_LIST_SUCCESS: 'RECEIVE_USER_LIST_SUCCESS',
  RECEIVE_USER_LIST_ERROR: 'RECEIVE_USER_LIST_ERROR',
  FETCH_USER: 'FETCH_USER',
  FETCH_USER_FAILURE: 'FETCH_USER_FAILURE',
  RECEIVE_USER: 'RECEIVE_USER',
  CREATE_USER: 'CREATE_USER',
  RESET_USER: 'RESET_USER',
  CONFIRM_USER_CREATION_SUCCESS: 'CONFIRM_USER_CREATION_SUCCESS',
  CONFIRM_USER_CREATION_FAILURE: 'CONFIRM_USER_CREATION_FAILURE',
  UPDATE_USER: 'UPDATE_USER',
  CONFIRM_USER_UPDATION_SUCCESS: 'CONFIRM_USER_UPDATION_SUCCESS',
  CONFIRM_USER_UPDATION_FAILURE: 'CONFIRM_USER_UPDATION_FAILURE',
  DELETE_USER: 'DELETE_USER',
  DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
  DELETE_USER_ERROR: 'DELETE_USER_ERROR',
  DELETE_USER_RESET: 'DELETE_USER_RESET',

  SEND_WELCOME_EMAIL: 'SEND_WELCOME_EMAIL',
  SEND_WELCOME_EMAIL_SUCCESS: 'SEND_WELCOME_EMAIL_SUCCESS',
  SEND_WELCOME_EMAIL_FAILURE: 'SEND_WELCOME_EMAIL_FAILURE',

  FETCH_PRODUCT_LIST: 'FETCH_PRODUCT_LIST',
  RECEIVE_PRODUCT_LIST_SUCCESS: 'RECEIVE_PRODUCT_LIST_SUCCESS',
  RECEIVE_PRODUCT_LIST_FAILURE: 'RECEIVE_PRODUCT_LIST_FAILURE',
  FETCH_ACCOUNT_PRODUCTS: 'FETCH_ACCOUNT_PRODUCTS',
  RECEIVE_ACCOUNT_PRODUCTS_SUCCESS: 'RECEIVE_ACCOUNT_PRODUCTS_SUCCESS',
  RECEIVE_ACCOUNT_PRODUCTS_FAILURE: 'RECEIVE_ACCOUNT_PRODUCTS_FAILURE',
  UPDATE_ACCOUNT_PRODUCTS: 'UPDATE_ACCOUNT_PRODUCTS',
  UPDATE_ACCOUNT_PRODUCTS_SUCCESS: 'UPDATE_ACCOUNT_PRODUCTS_SUCCESS',
  UPDATE_ACCOUNT_PRODUCTS_FAILURE: 'UPDATE_ACCOUNT_PRODUCTS_FAILURE',

  FETCH_ORGANIZATION_PRODUCTS: 'FETCH_ORGANIZATION_PRODUCTS',
  RECEIVE_ORGANIZATION_PRODUCTS_SUCCESS: 'RECEIVE_ORGANIZATION_PRODUCTS_SUCCESS',
  RECEIVE_ORGANIZATION_PRODUCTS_ERROR: 'RECEIVE_ORGANIZATION_PRODUCTS_ERROR',
  UPDATE_ORGANIZATION_PRODUCTS: 'UPDATE_ORGANIZATION_PRODUCTS',
  UPDATE_ORGANIZATION_PRODUCTS_SUCCESS: 'UPDATE_ORGANIZATION_PRODUCTS_SUCCESS',
  UPDATE_ORGANIZATION_PRODUCTS_ERROR: 'UPDATE_ORGANIZATION_PRODUCTS_ERROR',
};
