import {
  END_DATE_PARAMETER,
  START_DATE_PARAMETER,
} from 'components/InternalAdmin/QuestionConfigure/QuestionParameters';
import { FLOW_STATUS } from 'utils/ignoramusEnums';


const initialNodes = [
  {
    id: 'Q1',
    sourcePosition: 'right',
    position: { x: 0, y: 0 },
    data: { label: 'Simple join question' },
    type: 'question',
  },
  {
    id: 'Q2',
    position: { x: 350, y: 0 },
    sourcePosition: 'right',
    targetPosition: 'left',
    data: { label: 'Campaign Overlap' },
    type: 'question',

  },
  {
    id: 'A1',
    position: { x: 700, y: 0 },
    sourcePosition: 'right',
    targetPosition: 'left',
    data: { label: 'Google Offline Conversions' },
    type: 'activation',
  },
  {
    id: 'A2',
    position: { x: 700, y: 200 },
    sourcePosition: 'right',
    targetPosition: 'left',
    data: { label: 'Twitter Tailored Audiences' },
    type: 'activation',
  },
  {
    id: 'A3',
    position: { x: 700, y: 100 },
    sourcePosition: 'right',
    targetPosition: 'left',
    data: { label: 'Snapchat Audience Match' },
    type: 'activation',
  },
];

const initialEdges = [{
  id: '1-2',
  source: 'Q1',
  type: 'step',
  target: 'Q2',
  label: 'Input',
},
{
  id: '2-3',
  source: 'Q2',
  type: 'step',
  target: 'A1',
  label: 'pending',
  animated: true,
},
{
  id: '2-4',
  source: 'Q2',
  type: 'step',
  target: 'A2',
  label: 'pending',
  animated: true,
},
{
  id: '2-5',
  source: 'Q2',
  type: 'step',
  target: 'A3',
  label: 'pending',
  animated: true,
}];
const initialFlows = [
  {
    ID: '0',
    title: 'Question Chain Activation',
    stage: FLOW_STATUS.READY.key,
    description: 'Question flow with weekly schedule to Google,Snapchat,Twitter.',
    tags: [
      { ID: '1', value: 'Question' },
      { ID: '2', value: 'Activation' },
      { ID: '3', value: 'Schedule' },
    ],
    status: 'CONFIGURATION_COMPLETE',
    nodes: [],
    edges: [],
    runsCompleted: 3,
  },
  {
    ID: '2',
    title: 'Which customers have shopped my category but not my product?',
    stage: FLOW_STATUS.MISSING_DATASETS.key,
    description: 'This question takes in input date range, commodity, and UPC and returns...',
    tags: [
      { ID: '1', value: 'Question' },
    ],
    status: 'DATASET_MAPPING_REQUIRED',
    nodes: [],
    edges: [],
    runsCompleted: 0,
  },
  {
    ID: '2',
    title: 'Flow 02',
    stage: FLOW_STATUS.READY.key,
    description: 'Flow with no runs.',
    tags: [
      { ID: '1', value: 'Question' },
      { ID: '2', value: 'Activation' },
      { ID: '3', value: 'Schedule' },
    ],
    status: 'CONFIGURATION_COMPLETE',
    nodes: [],
    edges: [],
    runsCompleted: 0,
  }];

const mockFlowRunTimeParams = {
  parameters:
  [
    {
      ID: 'dc6bdd73-3663-4cf5-b74f-691e912d6ba0',
      name: 'new_runtime',
      displayName: 'new_runtime',
      index: 0,
      type: 'STRING',
    },
    {
      ...START_DATE_PARAMETER,
    },
    {
      ...END_DATE_PARAMETER,
    },
  ],
};

export {
  mockFlowRunTimeParams,
  initialEdges,
  initialNodes,
  initialFlows,
};
