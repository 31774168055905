import Alert from '@mui/material/Alert';
import GearPopover from 'components/Common/GearPopover';
import React, { useContext } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortIconLabel from 'components/Common/TableSortIconLabel';
import useSortableTable from 'hooks/useSortableTable';
import { Card } from '@mui/material';
import {
  OrganizationRolesContext,
} from 'pages/OrganizationManagement/organization/pages/OrganizationRoles/OrganizationRoleTable/OrganizationRolesContextProvider';
import { rowsPerPageOptions } from 'utils/appConstants';


export const OrganizationRolesTableLayout = () => {
  const {
    roleList,
    getActions,
  } = useContext(OrganizationRolesContext);

  const tableOptions = useSortableTable(roleList, { rowsPerPage: rowsPerPageOptions[0] });
  const [popup, setPopUp] = React.useState(true);

  return (
    <Card>
      {popup && (
      <Alert
        severity='info'
        onClose={() => {
          setPopUp(false);
        }}
      >
        Roles can be edited or deleted, also additional roles and permissions can be added
      </Alert>
      )}
      <TableContainer>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell align='left'>
                <TableSortIconLabel
                  active={tableOptions.orderBy}
                  direction={tableOptions.orderDirection}
                  field='roleName'
                  onClick={tableOptions.setOrderBy}
                  title='Name'
                />
              </TableCell>
              <TableCell align='left'>
                <TableSortIconLabel
                  active={tableOptions.orderBy}
                  direction={tableOptions.orderDirection}
                  field='roleDescription'
                  onClick={tableOptions.setOrderBy}
                  title='Description'
                />
              </TableCell>
              <TableCell align='left'>
                <TableSortIconLabel
                  active={tableOptions.orderBy}
                  direction={tableOptions.orderDirection}
                  field='userCount'
                  onClick={tableOptions.setOrderBy}
                  title='Users'
                />
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {tableOptions.pageList.map(item => (
              <TableRow key={item.ID}>
                <TableCell>
                  {item.roleName}
                </TableCell>
                <TableCell>
                  {item.roleDescription}
                </TableCell>
                <TableCell>
                  {item.userCount}
                </TableCell>
                <TableCell align='right'>
                  <GearPopover
                    actions={getActions(item)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        backIconButtonProps={{ 'aria-label': 'Previous Page' }}
        component='div'
        count={tableOptions.count}
        onPageChange={(e, newPage) => tableOptions.setPage(newPage)}
        onRowsPerPageChange={e => tableOptions.setRowsPerPage(e.target.value)}
        nextIconButtonProps={{ 'aria-label': 'Next Page' }}
        page={tableOptions.page}
        rowsPerPage={tableOptions.rowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions}
      />
    </Card>
  );
};
