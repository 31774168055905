import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import React, {
  memo, useContext, useEffect, useMemo,
} from 'react';
import Select from '@mui/material/Select';
import Skeleton from '@mui/material/Skeleton';
import TextField from '@mui/material/TextField';
import usePreviousProps from 'hooks/usePreviousProps';
import { AlertsRequestPageContext } from 'pages/InternalAdmin/organization/pages/AlertsRequest/AlertsRequestContext';
import { CHAT_STATUS } from 'pages/InternalAdmin/organization/pages/AlertsRequest/constant';
import { DataContext } from 'BaseComponents/HDataTable/DataContextProvider';
import { isEqual } from 'lodash';
import { toDisplayString } from 'utils/jsUtils';


const ALL_FILTER = '-';

const FiltersLoadingSkeleton = () => Array.from({ length: 2 }, (_, i) => (
  <Grid item xs={3} lg={2} key={i}>
    <Skeleton variant='rectangular' sx={{ fontSize: '2rem' }} />
  </Grid>
));


const AlertsRequestControlPanel = memo(() => {
  const {
    refreshListData,
    filters,
    filtersOptions,
    handleFilterChange,
    isLoading,
  } = useContext(DataContext);

  const {
    crId,
    onCleanRoomChange,
    crListQuery: {
      fetchingCRList,
      isCrListError,
      crList,
    },
  } = useContext(AlertsRequestPageContext);

  const statusFilter = useMemo(
    () => filtersOptions?.filters.find(filter => filter.attribute === 'STATUS'),
    [filtersOptions?.filters],
  );

  const prevCrId = usePreviousProps(crId);

  const updateFilter = (attribute, value) => {
    const updatedFilters = filters.filters.filter(f => f.attribute !== attribute);
    if (value !== ALL_FILTER) {
      updatedFilters.push({
        attribute,
        values: [value],
      });
    }
    handleFilterChange({
      ...filters,
      offset: 0,
      filters: updatedFilters,
    });
  };

  useEffect(() => {
    if (!isEqual(crId, prevCrId)) {
      refreshListData();
    }
  }, [crId, prevCrId, refreshListData]);

  return (
    <Grid container mb={2} spacing={2}>
      <Grid container item xs spacing={2}>
        <Grid item xs={3} lg={2}>
          {
            (fetchingCRList || isCrListError)
              ? <Skeleton variant='rectangular' sx={{ fontSize: '2rem' }} />
              : (
                <FormControl size='small' fullWidth>
                  <InputLabel id='clean-room-option'>Clean Room</InputLabel>
                  <Select
                    labelId='clean-room-option'
                    id='clean-room-select'
                    value={crId || ''}
                    label='Clean Room'
                    placeholder='Select Clean Room'
                    onChange={(e) => {
                      onCleanRoomChange(e.target.value);
                    }}
                  >
                    {crList?.map((option) => (
                      <MenuItem key={option.ID} value={option.ID}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )
          }
        </Grid>
        {
          (isLoading || !filtersOptions) ? <FiltersLoadingSkeleton /> : (
            <Grid item xs={3} lg={2}>
              <TextField
                fullWidth
                select
                size='small'
                variant='outlined'
                label={`Filter by ${toDisplayString(statusFilter.attribute)}`}
                value={filters.filters
                  .find(af => af.attribute === statusFilter.attribute)?.values[0] || ALL_FILTER}
                onChange={e => updateFilter(statusFilter.attribute, e.target.value)}
              >
                <MenuItem value={ALL_FILTER}>All</MenuItem>
                {
                  statusFilter.options.map(o => (
                    <MenuItem key={o.name} value={o.name}>
                      {CHAT_STATUS[o.name].label}
                    </MenuItem>
                  ))
                }
              </TextField>
            </Grid>
          )
        }
      </Grid>
    </Grid>
  );
});

AlertsRequestControlPanel.displayName = 'AlertsRequestControlPanel';

export default AlertsRequestControlPanel;
