import React from 'react';
import {
  Box,
  Grid,
  Skeleton,
} from '@mui/material';
import { tableBodySkeletonHeight, tableHeaderSkeletonHeight } from 'BaseComponents/HDataTable/constants';


export const HTableSkeleton = ({ height }) => {
  const bodyHeight = height ? (height - tableHeaderSkeletonHeight) : tableBodySkeletonHeight;

  return (
    <Grid container wrap='wrap' data-testid='list-view-loading'>
      <Skeleton height={tableHeaderSkeletonHeight} width='100%' />
      <Box width='100%' my={1}>
        <Skeleton variant='rectangular' height={bodyHeight} />
      </Box>
    </Grid>
  );
};
