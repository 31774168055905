import http from 'utils/http';
import { CLEAN_ROOM_USER_LIST } from 'redux/actions/Types';
import { SyntheticRedux } from 'synthetic-redux';
import { all } from 'redux-saga/effects';
import { combineReducers } from 'redux';
import { crUserList } from 'utils/urlConstants';


const fetchListCrUserList = new SyntheticRedux(
  { type: CLEAN_ROOM_USER_LIST.FETCH_CR_USER_LISTS }, 'list',
);
fetchListCrUserList.ignite = (orgId, crId, filter) => fetchListCrUserList.actions.ignite({
  errorMeta: 'User List: Fetch userLists',
  method: http.post,
  payload: filter,
  url: crUserList(orgId, crId).list,
});

const fetchCrUserList = new SyntheticRedux(
  { type: CLEAN_ROOM_USER_LIST.FETCH_CR_USER_LIST_DETAILS }, 'detail',
);

fetchCrUserList.ignite = (orgId, crId, id) => fetchCrUserList.actions.ignite({
  errorMeta: 'User List: Fetch Userlist Detail',
  method: http.get,
  url: crUserList(orgId, crId).details(id),
});

const updateCrUserListStatus = new SyntheticRedux(
  { type: CLEAN_ROOM_USER_LIST.UPDATE_CR_USER_LIST_STATUS }, 'updateStatus',
);

updateCrUserListStatus.ignite = (orgId, crId, id, status) => updateCrUserListStatus.actions.ignite({
  errorMeta: 'User List: Update UserList Status',
  method: http.put,
  url: crUserList(orgId, crId).updateStatus(id),
  payload: { status },
});

const deleteCrUserList = new SyntheticRedux(
  { type: CLEAN_ROOM_USER_LIST.DELETE_CR_USER_LIST }, 'delete',
);

deleteCrUserList.ignite = (orgId, crId, id) => deleteCrUserList.actions.ignite({
  errorMeta: 'User List: Delete UserList',
  method: http.delete,
  url: crUserList(orgId, crId).delete(id),
});

const fetchCrDatasetByIdentities = new SyntheticRedux(
  { type: CLEAN_ROOM_USER_LIST.FETCH_CR_DATASET_BY_IDENTITY_TYPE }, 'identities',
);
fetchCrDatasetByIdentities.ignite = (orgId, crId) => fetchCrDatasetByIdentities.actions.ignite({
  errorMeta: 'User List: Fetch Datasets By Identities',
  method: http.get,
  url: crUserList(orgId, crId).datasetByIdentities,
});

const saveCrUserList = new SyntheticRedux(
  { type: CLEAN_ROOM_USER_LIST.SAVE_CR_USER_LISTS }, 'detail',
);
saveCrUserList.ignite = (orgId, crId, payload, id) => saveCrUserList.actions.ignite({
  errorMeta: 'User List: Save User List',
  method: (id) ? http.put : http.post,
  payload,
  url: (id) ? `${crUserList(orgId, crId).saveUserList}/${id}` : crUserList(orgId, crId).saveUserList,
});

const crUserListReducer = combineReducers({
  datasetsById: fetchCrDatasetByIdentities.reducer,
  save: saveCrUserList.reducer,
  userList: fetchListCrUserList.reducer,
  userListDetail: fetchCrUserList.reducer,
  userListUpdateStatus: updateCrUserListStatus.reducer,
  deleteUserList: deleteCrUserList.reducer,
});

function* crUserListSaga() {
  yield all([
    fetchCrDatasetByIdentities.sagaEffect,
    fetchCrUserList.sagaEffect,
    fetchListCrUserList.sagaEffect,
    updateCrUserListStatus.sagaEffect,
    deleteCrUserList.sagaEffect,
    saveCrUserList.sagaEffect,
  ]);
}

export {
  crUserListReducer,
  crUserListSaga,
  deleteCrUserList,
  fetchCrDatasetByIdentities,
  fetchCrUserList,
  fetchListCrUserList,
  updateCrUserListStatus,
  saveCrUserList,
};
