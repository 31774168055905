import Paper from '@mui/material/Paper';
import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortIconLabel from 'components/Common/TableSortIconLabel';
import moment from 'moment';
import useSortableTable from 'hooks/useSortableTable';
import { CleanRoomInvitationStatus } from 'components/CleanRooms/constants';
import { HButton } from 'BaseComponents';
import { UI_DATE_FORMAT, rowsPerPageOptions } from 'utils/appConstants';


const CrPartnerInvitationTable = ({ list, onLinkOrg }) => {
  const tableOptions = useSortableTable(list, { rowsPerPage: rowsPerPageOptions[1] });

  return (
    <Paper>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align='left'>
                <TableSortIconLabel
                  active={tableOptions.orderBy}
                  direction={tableOptions.orderDirection}
                  field='timeAudit.createdAt'
                  onClick={tableOptions.setOrderBy}
                  title='Created At'
                />
              </TableCell>
              <TableCell align='left'>
                <TableSortIconLabel
                  active={tableOptions.orderBy}
                  direction={tableOptions.orderDirection}
                  field='timeAudit.updatedAt'
                  onClick={tableOptions.setOrderBy}
                  title='Last Updated At'
                />
              </TableCell>
              <TableCell align='left'>
                <TableSortIconLabel
                  active={tableOptions.orderBy}
                  direction={tableOptions.orderDirection}
                  field='invitorEmail'
                  onClick={tableOptions.setOrderBy}
                  title='Invitor Email'
                />
              </TableCell>
              <TableCell align='left'>
                <TableSortIconLabel
                  active={tableOptions.orderBy}
                  direction={tableOptions.orderDirection}
                  field='cleanRoomName'
                  onClick={tableOptions.setOrderBy}
                  title='Clean Room Name'
                />
              </TableCell>
              <TableCell align='left'>
                <TableSortIconLabel
                  active={tableOptions.orderBy}
                  direction={tableOptions.orderDirection}
                  field='partnerAdminEmail'
                  onClick={tableOptions.setOrderBy}
                  title='Partner Email'
                />
              </TableCell>
              <TableCell align='left'>
                <TableSortIconLabel
                  active={tableOptions.orderBy}
                  direction={tableOptions.orderDirection}
                  field='status'
                  onClick={tableOptions.setOrderBy}
                  title='Status'
                />
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {tableOptions.pageList.map((row) => (
              <TableRow key={row.ID}>
                <TableCell align='left'>{moment(row.timeAudit.createdAt).format(UI_DATE_FORMAT)}</TableCell>
                <TableCell align='left'>{moment(row.timeAudit.updatedAt).format(UI_DATE_FORMAT)}</TableCell>
                <TableCell align='left'>{row.invitorEmail}</TableCell>
                <TableCell align='left'>{row.cleanRoomName}</TableCell>
                <TableCell align='left'>{row.partnerAdminEmail}</TableCell>
                <TableCell align='left'>{CleanRoomInvitationStatus[row.status].label}</TableCell>
                <TableCell>
                  {row.status === CleanRoomInvitationStatus.PENDING.key ? (
                    <HButton
                      color='primary'
                      onClick={() => onLinkOrg(row.ID, row.cleanRoomID)}
                      size='small'
                      variant='contained'
                    >
                      Link
                    </HButton>
                  ) : null}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        backIconButtonProps={{ 'aria-label': 'Previous Page' }}
        component='div'
        count={tableOptions.count}
        onPageChange={(e, newPage) => tableOptions.setPage(newPage)}
        onRowsPerPageChange={e => tableOptions.setRowsPerPage(e.target.value)}
        nextIconButtonProps={{ 'aria-label': 'Next Page' }}
        page={tableOptions.page}
        rowsPerPage={tableOptions.rowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions}
      />
    </Paper>
  );
};

export default CrPartnerInvitationTable;
