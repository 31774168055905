import { CACHE } from 'utils/appConstants';
import { buttonBaseClasses, inputAdornmentClasses } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { defaultDesignToken } from 'designToken';
import {
  motifBaseTheme, motifComponentsOverride, motifLineHeight, pxToRem,
} from 'MotifTheme';
import {
  orange, red,
} from '@mui/material/colors';


const htmlFontSize = 16;
const fontSize = 12;

export const navBarDrawerWidth = 280;

export const navBarCollapsedWidth = 160;

export const crNavBarCollapsedWidth = 138;

export const habuColors = {
  primary: {
    10: '#FFFCEB',
    20: '#FFF9D7',
    30: '#FFED86',
    60: '#FFE136',
    80: '#FFC700',
    90: '#FFB800',
    100: '#FB870D',
  },
  secondary: {
    10: '#E9E6FD',
    20: '#D3CCFC',
    30: '#A699F9',
    60: '#2100EF',
    80: '#11007B',
  },
  neutral: {
    white: '#ffffff',
    5: '#FAFAFA',
    10: '#EEEEEE',
    20: '#E6E6E6',
    30: '#D5D5D5',
    50: '#B1B1B1',
    60: '#696969',
    80: '#373737',
    90: '#212022',
  },
  signal: {
    success: {
      10: '#E2F8D9',
      20: '#64D57A',
      40: '#4CAF50',
      70: '#2E7D32',
      80: '#1B5E20',
    },
    warning: {
      10: '#FEEDCB',
      20: '#FDD698',
      40: '#FFC657',
      70: '#ED6C02',
      80: '#E65100',
    },
    alert: {
      10: '#FCE3D5',
      20: '#FAC0AC',
      40: '#EF5350',
      70: '#D32F2F',
      80: '#C62828',
    },
  },
};

export const ourColors = {
  primaryColor: habuColors.neutral['90'],
  primaryContrastText: habuColors.primary['60'],
  secondaryColor: habuColors.primary['60'],
  linkColorActive: habuColors.secondary['60'],
  linkColorInActive: habuColors.neutral['60'],
  groupBarLineColors: ['#294383', '#4C27A2', '#FEB019', '#00E396'],
  groupLineColors: ['#294383', '#4C27A2', '#FEB019', '#00E396'],
  groupBarColors: ['#294383', '#4C27A2', '#FEB019', '#00E396'],
  groupLineBarSyncAxisColors: ['#294383', '#4C27A2', '#FEB019', '#00E396'],
  HeatmapColors: ['#4C27A2'],
  impersonationBar: habuColors.primary['100'],
  LineColumnColors: ['#8c94b3', '#4C27A2'],
  DateBarColors: ['#4C27A2'],
  treeMapColors: ['#5653FE'],
  universalBarLineAreaColors: ['#294383', '#4C27A2', '#FEB019', '#00E396'],
};

export const zIndex = {
  normal: 1,
  high: 10,
};


export const defaultThemeConfig = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 768,
      lg: 1200,
      xl: 1400,
    },
  },
  palette: {
    mode: localStorage.getItem(CACHE.themeType) || 'light',
    primary: {
      main: ourColors.primaryColor,
      light: habuColors.neutral['80'],
      dark: '#14181e',
      contrastText: ourColors.primaryContrastText,
    },
    secondary: {
      main: ourColors.secondaryColor,
      contrastText: habuColors.neutral['90'],
    },
    error: {
      main: red[500],
    },
    warning: {
      main: orange[400],
    },
    info: {
      main: habuColors.neutral['60'],
    },
    link: {
      main: ourColors.linkColorActive,
      inactive: ourColors.linkColorInActive,
    },
    background: {
      dark: '#90a4ae',
      light: '#f2f5f5',
      main: '#778187',
      orgTabBackground: habuColors.neutral['90'],
      default: '#fafafa',
    },
    border: {
      grey: ourColors.grey,
    },
    header: {
      background: '#CDDCF8',
      darkBackground: '#1263E9',
      lightBackground: '#FFFFFF',
    },
    sideNavigation: {
      main: '#E1E5EB',
      backgroundColor: habuColors.neutral['90'],
      leftBorder: habuColors.primary['60'],
      button: {
        color: '#FFFFFF',
        hoverColor: '#FFFFFF',
        backgroundHoverColor: habuColors.primary['60'],
      },
    },
    slider: {
      label: '#2c3e50',
    },
    outlined: {
      main: '#e0e0e0',
      dark: '#d2d0d0',
      contrastText: 'black',
    },
    impersonation: {
      main: habuColors.primary['100'],
    },
    flow: {
      question: {
        borderColor: '#FFB800',
        backgroundColor: '#FFEF9A',
      },
      transformation: {
        borderColor: '#EF5350',
        backgroundColor: '#FAC0AC',
      },
      activation: {
        borderColor: '#C2F2B4',
        backgroundColor: '#4CAF50',
      },
      identity: {
        borderColor: '#ABE8DD',
        backgroundColor: '#5CC9B5',
      },
      scheduling: {
        borderColor: '#CDDCF8',
        backgroundColor: '#519CF6',
      },
      dataset: {
        borderColor: '#519CF6',
        backgroundColor: '#F0F7FF',
      },
    },
  },
  typography: {
    htmlFontSize,
    fontSize,
    fontFamily: `${defaultDesignToken.fontFamily.join(',')}`,
    fontWeight: 400,
    h1: {
      fontSize: '2rem',
      lineHeight: '2.5rem',
      fontWeight: 600,
      fontStyle: 'normal',
    },
    h2: {
      fontSize: '2rem',
      lineHeight: '2.625rem',
      fontWeight: 600,
    },
    h3: {
      fontSize: '1.375rem',
      lineHeight: '1.75rem',
      fontWeight: 500,
      letterSpacing: '0.5px',
    },
    h4: {
      fontSize: '1.5rem',
      lineHeight: '2.125rem',
      fontWeight: 600,
    },
    h5: {
      fontSize: '1.25rem',
      lineHeight: '1.75rem',
      fontWeight: 600,
    },
    h6: {
      fontSize: '0.875rem',
      fontWeight: 600,
    },
    subtitle1: {
      fontSize: '1rem',
      lineHeight: '1.25rem',
      fontWeight: 600,
    },
    subtitle2: {
      fontSize: '0.875rem',
      lineHeight: '1.125rem',
      fontWeight: 600,
    },
    subtitle3: {
      fontWeight: 600,
      fontSize: '0.75rem',
      lineHeight: '1rem',
      letterSpacing: '0.8px',
    },
    subtitle4: {
      fontWeight: 600,
      fontSize: '0.625rem',
      lineHeight: '0.75rem',
    },
    body1: {
      fontSize: '1rem',
      lineHeight: '1.5rem',
      fontWeight: 400,
    },
    body2: {
      fontSize: '0.875rem',
      lineHeight: '1.125rem',
      fontWeight: 400,
    },
    body3: {
      fontSize: '0.75rem',
      fontStyle: 'normal',
      fontWeight: 500,
      letterSpacing: '0.8px',
      lineHeight: '16px',
    },
    caption: {
      fontSize: '0.75rem',
      lineHeight: '1rem',
      fontWeight: 500,
    },
    bold: {
      fontSize: '0.875rem',
      lineHeight: '1.125rem',
      fontWeight: 600,
    },
    button: {
      borderRadius: '3px',
      padding: '6px 12px',
      textTransform: 'none',
      fontWeight: 600,
      fontSize: '.875rem',
      lineHeight: '24px',
      letterspacing: '.8px',
    },
    linkText: {
      fontSize: 12,
      fontWeight: 'bold',
      color: ourColors.linkColorActive,
    },
    flowNodeTitle: {
      fontSize: '1.1rem',
      fontWeight: 600,
      lineHeight: '1.25rem',
      letterSpacing: '0.8px',
    },
  },
  components: {
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          background: '#eff3f8',
          minHeight: '2rem',
          '&.Mui-expanded': {
            minHeight: '2rem',
          },
        },
        content: {
          alignItems: 'center',
          minHeight: '2rem',
          margin: '0.25rem 0',
          '&.Mui-expanded': {
            margin: '0.5rem 0',
            minHeight: '2rem',
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          height: '76px',
        },
        colorPrimary: {
          backgroundColor: '#FFFFFF',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '2px',
          textTransform: 'capitalize',
        },
        containedPrimary: {
          '&:hover': { color: '#FFFFFF' },
        },
        containedSecondary: {
          border: `2px solid${habuColors.primary['60']}`,
          '&:hover': {
            backgroundColor: '#FFFFFF',
            border: `2px solid${habuColors.primary['60']}`,
          },
          '&:disabled': {
            border: '#e0e0e0',
          },
        },
        outlinedPrimary: {
          '&:hover': {
            backgroundColor: `${habuColors.neutral['30']}`,
          },
        },
      },
    },
    MuiCollapse: {
      styleOverrides: {
        wrapperInner: {
          zIndex: zIndex.normal,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          borderBottom: '1px solid #E5E5E5',
        },
        indicator: {
          backgroundColor: habuColors.primary['60'],
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minHeight: 'inherit',
          fontSize: 16,
          '&:hover': { color: habuColors.neutral['60'], opacity: 0.8 },
          '&.Mui-selected': {
            color: habuColors.neutral['90'],
            fontWeight: 'bold',
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '12px',
        },
      },
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          backgroundColor: 'inherit',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          lineHeight: '1.5rem',
        },
        head: {
          fontWeight: 600,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: { color: ourColors.linkColorActive },
      },
    },
    MuiAlert: {
      styleOverrides: {
        standardInfo: {
          backgroundColor: habuColors.neutral['10'],
          color: habuColors.neutral['80'],
          fontSize: '.875rem',
        },
        standardInfoIcon: {
          color: habuColors.neutral['60'],
        },
      },
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          color: habuColors.neutral['80'],
          fontSize: '.875rem',
          fontWeight: 600,
        },
      },
    },
  },
  apexCharts: {
    Heatmap: {
      colors: ['#4C27A2'],
    },
    LineColumn: {
      colors: ['#8c94b3', '#4C27A2'],
    },
    DateBar: {
      colors: ['#4C27A2'],
    },
  },
};

export const habuWithMotifThemeConfig = {
  breakpoints: {
    ...defaultThemeConfig.breakpoints,
  },
  palette: {
    ...motifBaseTheme.palette,
    background: {
      ...motifBaseTheme.palette.background,
      default: motifBaseTheme.palette.background.light,
      orgTabBackground: motifBaseTheme.palette.background.hue[800],
    },
    link: {
      main: motifBaseTheme.palette.text.hoverLink,
      inactive: motifBaseTheme.palette.text.link,
    },
    border: {
      // TO-DO: Border styles will break because Motif hue's number are larger than Habu
      grey: motifBaseTheme.palette.secondary.hue,
    },
    header: {
      ...defaultThemeConfig.palette.header,
      background: motifBaseTheme.palette.background.main,
      darkBackground: motifBaseTheme.palette.background.dark,
      lightBackground: motifBaseTheme.palette.background.light,
    },
    sideNavigation: {
      main: motifBaseTheme.palette.common.black,
      backgroundColor: motifBaseTheme.palette.common.black,
      leftBorder: motifBaseTheme.palette.secondary.hover,
      button: {
        color: motifBaseTheme.palette.common.white,
        hoverColor: motifBaseTheme.palette.common.white,
        backgroundHoverColor: motifBaseTheme.palette.secondary.hover,
      },
    },
    slider: {
      ...defaultThemeConfig.palette.slider,
    },
    outlined: {
      ...defaultThemeConfig.palette.outlined,
      contrastText: motifBaseTheme.palette.common.black,
    },
    impersonation: {
      main: motifBaseTheme.palette.primary.main,
    },
    flow: {
      ...defaultThemeConfig.palette.flow,
    },
  },
  typography: {
    ...motifBaseTheme.typography,
    fontWeight: motifBaseTheme.typography.fontWeightRegular,
    h1: {
      ...motifBaseTheme.typography.h1,
      fontWeight: motifBaseTheme.typography.fontWeightBold,
    },
    h2: {
      ...motifBaseTheme.typography.h2,
      fontWeight: motifBaseTheme.typography.fontWeightBold,
    },
    h3: {
      ...motifBaseTheme.typography.h3,
      fontWeight: motifBaseTheme.typography.fontWeightBold,
    },
    h4: {
      ...motifBaseTheme.typography.h4,
      fontWeight: motifBaseTheme.typography.fontWeightBold,
    },
    h5: {
      fontFamily: motifBaseTheme.typography.fontFamily,
      fontSize: motifBaseTheme.typography.h3.fontSize,
      fontWeight: motifBaseTheme.typography.fontWeightBold,
      lineHeight: motifLineHeight,
    },
    h5Branding: {
      fontFamily: motifBaseTheme.typography.fontFamilyHeader,
      fontSize: motifBaseTheme.typography.h3.fontSize,
      fontWeight: motifBaseTheme.typography.fontWeightBold,
      lineHeight: motifLineHeight,
    },
    h6: {
      fontFamily: motifBaseTheme.typography.fontFamily,
      fontSize: pxToRem(14),
      fontWeight: motifBaseTheme.typography.fontWeightBold,
      lineHeight: motifLineHeight,
    },
    h6Branding: {
      fontFamily: motifBaseTheme.typography.fontFamilyHeader,
      fontSize: pxToRem(14),
      fontWeight: motifBaseTheme.typography.fontWeightBold,
      lineHeight: motifLineHeight,
    },
    subtitle4: {
      fontFamily: motifBaseTheme.typography.fontFamily,
      fontSize: pxToRem(10),
      fontWeight: motifBaseTheme.typography.fontWeightBold,
      lineHeight: motifLineHeight,
    },
    caption: {
      ...motifBaseTheme.typography.caption,
      fontWeight: motifBaseTheme.typography.fontWeightMedium,
    },
    bold: {
      fontSize: pxToRem(14),
      lineHeight: motifLineHeight,
      fontWeight: motifBaseTheme.typography.fontWeightBold,
    },
    button: {
      textTransform: 'none',
    },
    linkText: {
      ...motifBaseTheme.typography.body3,
    },
    flowNodeTitle: {
      ...defaultThemeConfig.typography.button,
      fontSize: pxToRem(17),
      fontWeight: motifBaseTheme.typography.fontWeightBold,
      lineHeight: motifLineHeight,
    },
  },
  components: {
    ...defaultThemeConfig.components,
    ...motifComponentsOverride,
    MuiAppBar: {
      ...defaultThemeConfig.components.MuiAppBar,
      styleOverrides: {
        ...defaultThemeConfig.components.MuiAppBar.styleOverrides,
        root: {
          height: 'auto',
        },
      },
    },
    MuiAlert: {
      ...motifComponentsOverride.MuiAlert,
      styleOverrides: {
        ...motifComponentsOverride.MuiAlert.styleOverrides,
        root: {
          ...motifComponentsOverride.MuiAlert.styleOverrides.root,
          margin: 'unset',
        },
      },
    },
    MuiButton: {
      ...motifComponentsOverride.MuiButton,
      styleOverrides: {
        ...motifComponentsOverride.MuiButton.styleOverrides,
        contained: {
          ...motifComponentsOverride.MuiButton.styleOverrides.contained,
          backgroundColor: motifBaseTheme.palette.primary.main,
        },
        outlined: {
          ...motifComponentsOverride.MuiButton.styleOverrides.outlined,
          backgroundColor: 'transparent',
        },
        text: {
          ...motifComponentsOverride.MuiButton.styleOverrides.text,
          backgroundColor: 'transparent',
        },
        sizeLarge: {
          ...motifBaseTheme.typography.subtitle1,
        },
      },
    },
    MuiInputAdornment: {
      ...motifComponentsOverride.MuiInputAdornment,
      styleOverrides: {
        ...motifComponentsOverride.MuiInputAdornment.styleOverrides,
        outlined: {
          ...motifComponentsOverride.MuiInputAdornment.styleOverrides.outlined,
          // Override the spacing when Input Adornment has an Icon Button/Button embedded
          [`&.${inputAdornmentClasses.positionStart} .${buttonBaseClasses.root}`]: {
            marginLeft: pxToRem(4),
            padding: '4px',
          },
          [`&.${inputAdornmentClasses.positionEnd} .${buttonBaseClasses.root}`]: {
            marginRight: pxToRem(4),
            padding: '4px',
          },
        },
        standard: {
          ...motifComponentsOverride.MuiInputAdornment.styleOverrides.standard,
          // Override the spacing when Input Adornment has an Icon Button/Button embedded
          [`&.${inputAdornmentClasses.positionStart} .${buttonBaseClasses.root}`]: {
            marginLeft: pxToRem(4),
            padding: '4px',
          },
          [`&.${inputAdornmentClasses.positionEnd} .${buttonBaseClasses.root}`]: {
            marginRight: pxToRem(4),
            padding: '4px',
          },
        },
      },
    },
    MuiOutlinedInput: {
      ...motifComponentsOverride.MuiOutlinedInput,
      defaultProps: {
        notched: false,
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        colorPrimary: {
          color: 'initial',
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        input: {
          width: 'auto',
        },
      },
    },
  },
  apexCharts: {
    ...defaultThemeConfig.apexCharts,
  },
};

const Theme = createTheme(habuWithMotifThemeConfig);

export default Theme;
