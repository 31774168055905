import { activeOrgSelector } from 'redux/reducer/AuthenticationReducer';
import { axiosInstance } from 'utils/http';
import { organizationParameters } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation, useQueryClient } from 'react-query';


export const useUpdateOrganizationParameters = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { ID: orgId } = useSelector(activeOrgSelector);

  const {
    isLoading,
    isSuccess,
    isError,
    mutate,
    reset,
  } = useMutation(
    async (
      { paramName, payload },
    ) => axiosInstance.put(organizationParameters(orgId).update(paramName), payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['UpdateOrganizationParametersWithCategory', orgId] });
        dispatch(showAlert({ message: 'Organization parameters updated successfully.', type: 'success' }));
      },
      onError: (err) => {
        dispatch(showAlert({
          message: err.response.data.message,
          type: 'error',
        }));
      },
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    mutate,
    reset,
  };
};
