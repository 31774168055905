import Logger from 'components/Common/Logger';
import axios from 'axios';
import localStorage from 'utils/localStorage';
import { CACHE, habuSupport } from 'utils/appConstants';


export const defaultErrorMsg = `Oops, something went wrong. Don't worry, we're here to help!
Contact us at ${habuSupport.email} or your admin for assistance.`;

const httpErrorMessage = (httpStatus) => {
  switch (httpStatus) {
    case 400:
      return 'Server cannot or will not process the request due to something that is perceived to be a client error';
    case 403:
      return 'Unauthorized';
    case 404:
      return 'Page does not exist';
    default: {
      return defaultErrorMsg;
    }
  }
};

// Common response handling before the individual Sagas do the component specific thing
async function responseGateway(response) {
  /*
    We should handle all HTTP 500 here
    But gRPC error codes don't map to HTTP error codes
    We'll do the best here until we have consistent error handling in the backend services
  */
  const requestId = response.headers.get('X-Request-Id');

  if (response.status === 504) {
    Logger.error({
      msg: 'Service is either down or a misconfiguration',
      httpError: response.status,
      statusText: response.statusText,
      requestId,
    }, 'Network error');
    return {
      error: requestId === null ? defaultErrorMsg
        : `${defaultErrorMsg}\n\nRequest Id: ${requestId}`,
    };
  }
  else if (response.status === 400 || response.status === 404 || response.status === 403) {
    Logger.error({
      msg: httpErrorMessage(response.status),
      httpError: response.status,
      statusText: response.statusText,
      requestId,
    }, 'Network error');

    const res = response.json().then((r) => {
      const temp = r;
      const apiErrMsg = r.error;
      const htmlCodeErrMsg = httpErrorMessage(response.status);
      temp.error = requestId
        ? `${apiErrMsg}. \n${htmlCodeErrMsg}\n\nRequest Id: ${requestId}`
        : `${apiErrMsg}. \n${htmlCodeErrMsg}`;
      return temp;
    });
    return res;
  }
  // Return the whole Response instance instead of the body JSON, so that the generator function
  // refreshToken in AuthenticationSaga.js can handle the logic accordingly
  else if (response.status === 401 && response.url.includes('/auth/refresh_token')) {
    return response;
  }
  else {
    return response.json();
  }
}

// eslint-disable-next-line no-underscore-dangle
const _fetch = async (url, { method, otherHeaders, ...rest }) => {
  const headers = new Headers([
    ['Accept', 'application/json'],
    ['Access-Control-Allow-Credentials', true],
    ['Access-Control-Allow-Origin', true],
    ['Access-Control-Request-Method', method],
    /* This should always be in localStorage irrespective of the cache used */
    ['Authorization', localStorage.get(CACHE.user.jwtAccessToken)],
    ['Content-Type', 'application/json'],
  ]);

  if (otherHeaders) {
    otherHeaders.forEach((value, key) => {
      if (value === 'null') {
        headers.delete(key);
      }
      else {
        headers.set(key, value);
      }
    });
  }

  try {
    const response = await fetch(url, {
      method,
      headers,
      credentials: 'include',
      ...rest,
    });
    /* data.error contains the errors which will be read by the reducer */
    return await responseGateway(response);
  }
  catch (err) {
    /* Potential network/non-API errors */
    return { error: String(err) };
  }
};

// eslint-disable-next-line no-underscore-dangle
const _fetchRaw = async (url, { method, otherHeaders, ...rest }) => {
  const headers = new Headers([
    ['Accept', 'application/json'],
    ['Access-Control-Allow-Credentials', true],
    ['Access-Control-Allow-Origin', true],
    /* This should always be in localStorage irrespective of the cache used */
    ['Authorization', localStorage.get(CACHE.user.jwtAccessToken)],
    ['Content-Type', 'application/json'],
  ]);

  if (otherHeaders) { otherHeaders.forEach((value, key) => headers.set(key, value)); }

  try {
    // returning response
    return await fetch(url, {
      method,
      headers,
      credentials: 'include',
      ...rest,
    });
  }
  catch (err) {
    /* Potential network/non-API errors */
    return err;
  }
};

const http = {
  get: async url => _fetch(url, { method: 'GET' }),

  patch: async (url, payload) => _fetch(url, { method: 'PATCH', body: JSON.stringify(payload) }),

  post: async (url, payload) => _fetch(url, { method: 'POST', body: JSON.stringify(payload) }),

  postFile: async (url, body) => _fetch(url, {
    method: 'POST',
    body,
    otherHeaders: new Headers([
      ['Content-Type', null],
    ]),
  }),

  put: async (url, payload) => _fetch(url, { method: 'PUT', body: JSON.stringify(payload) }),

  delete: async url => _fetch(url, { method: 'DELETE' }),

  refreshJwt: async (url, payload) => _fetch(url, {
    method: 'POST',
    body: JSON.stringify(payload),
    otherHeaders: new Headers([
      ['Authorization', localStorage.get(CACHE.user.jwtRefreshToken)],
    ]),
  }),
};

export const httpRaw = {
  get: async url => _fetchRaw(url, { method: 'GET' }),
  post: async (url, payload) => _fetchRaw(url, { method: 'POST', body: JSON.stringify(payload) }),
};

export const axiosInstance = axios.create();

// Axios request interceptor for API calls
axiosInstance.interceptors.request.use(
  async config => {
    // eslint-disable-next-line no-param-reassign
    config.headers = {
      'Access-Control-Allow-Credentials': true,
      'Access-Control-Allow-Origin': true,
      /* This should always be in localStorage irrespective of the cache used */
      Authorization: localStorage.get(CACHE.user.jwtAccessToken),
    };
    return config;
  },
  error => {
    Promise.reject(error);
  },
);

// Axios response interceptor for API calls
axiosInstance.interceptors.response.use((response) => response, async (error) => {
  const originalRequest = error.config;
  // eslint-disable-next-line no-underscore-dangle
  if (error.response.status === 401 && !originalRequest._retry) {
    // eslint-disable-next-line no-underscore-dangle
    originalRequest._retry = true;
    axios.defaults.headers.common.Authorization = localStorage.get(CACHE.user.jwtAccessToken);
    return axiosInstance(originalRequest);
  }
  return Promise.reject(error);
});


export default http;
