import { DATA_OUT } from 'redux/actions/Types';
import { dataOut } from 'utils/urlConstants';


export const activateUserList = (orgId, partnerId, payload) => ({
  type: DATA_OUT.CREATE_ACTIVATION,
  url: dataOut(orgId).activateUserList(partnerId),
  payload,
});

export const activateOfflineConversion = (orgId, partnerId, payload) => ({
  type: DATA_OUT.CREATE_ACTIVATION,
  url: dataOut(orgId).activateOfflineConversion(partnerId),
  payload,
});

export const activatePartnerAccounts = (orgId, userListId, payload) => ({
  type: DATA_OUT.CREATE_ACTIVATION,
  url: dataOut(orgId).activatePartnerAccounts(userListId),
  payload,
});

export const createActivationSuccess = payload => ({
  type: DATA_OUT.CREATE_ACTIVATION_SUCCESS,
  payload,
});

export const createActivationError = payload => ({
  type: DATA_OUT.CREATE_ACTIVATION_ERROR,
  payload,
});

export const createPartnerAccount = (orgId, payload) => ({
  type: DATA_OUT.CREATE_PARTNER_ACCOUNT,
  url: dataOut(orgId).createPartnerAccount,
  payload,
});

export const createPartnerAccountSuccess = payload => ({
  type: DATA_OUT.CREATE_PARTNER_ACCOUNT_SUCCESS,
  payload,
});

export const createPartnerAccountError = payload => ({
  type: DATA_OUT.CREATE_PARTNER_ACCOUNT_ERROR,
  payload,
});


export const deleteActivation = (orgId, jobId) => ({
  type: DATA_OUT.DELETE_ACTIVATION,
  url: dataOut(orgId).deleteActivation(jobId),
});

export const deleteActivationSuccess = payload => ({
  type: DATA_OUT.DELETE_ACTIVATION_SUCCESS,
  payload,
});

export const deleteActivationError = payload => ({
  type: DATA_OUT.DELETE_ACTIVATION_ERROR,
  payload,
});

export const deleteActivationRun = (orgId, jobId, runId) => ({
  type: DATA_OUT.DELETE_ACTIVATION_RUN,
  url: dataOut(orgId).deleteActivationRun(jobId, runId),
});

export const deleteActivationRunSuccess = payload => ({
  type: DATA_OUT.DELETE_ACTIVATION_RUN_SUCCESS,
  payload,
});

export const deleteActivationRunError = payload => ({
  type: DATA_OUT.DELETE_ACTIVATION_RUN_ERROR,
  payload,
});

export const fetchActivation = (orgId, jobId) => ({
  type: DATA_OUT.FETCH_ACTIVATION,
  url: dataOut(orgId).activation(jobId),
});

export const fetchActivationSuccess = payload => ({
  type: DATA_OUT.FETCH_ACTIVATION_SUCCESS,
  payload,
});

export const fetchActivationError = payload => ({
  type: DATA_OUT.FETCH_ACTIVATION_ERROR,
  payload,
});

export const fetchActivationNames = (orgId, filter) => ({
  type: DATA_OUT.FETCH_ACTIVATION_NAMES,
  url: dataOut(orgId).activationNames(filter),
});

export const fetchActivationNamesError = payload => ({
  type: DATA_OUT.FETCH_ACTIVATION_NAMES_ERROR,
  payload,
});

export const fetchActivationNamesSuccess = payload => ({
  type: DATA_OUT.FETCH_ACTIVATION_NAMES_SUCCESS,
  payload,
});

export const fetchActivationFilters = (orgId, jobType) => ({
  type: DATA_OUT.FETCH_ACTIVATION_FILTERS,
  url: dataOut(orgId).activationFilters(jobType),
});

export const fetchActivationFiltersSuccess = payload => ({
  type: DATA_OUT.FETCH_ACTIVATION_FILTERS_SUCCESS,
  payload,
});

export const fetchActivationFiltersError = payload => ({
  type: DATA_OUT.FETCH_ACTIVATION_FILTERS_ERROR,
  payload,
});

export const fetchActivationReport = (orgId, jobId, filter) => ({
  type: DATA_OUT.FETCH_ACTIVATION_REPORT,
  url: dataOut(orgId).activationReport(jobId, filter),
});

export const fetchActivationReportSuccess = payload => ({
  type: DATA_OUT.FETCH_ACTIVATION_REPORT_SUCCESS,
  payload,
});

export const fetchActivationReportError = payload => ({
  type: DATA_OUT.FETCH_ACTIVATION_REPORT_ERROR,
  payload,
});

export const fetchPartnerAccounts = (orgId, filter) => ({
  type: DATA_OUT.FETCH_PARTNER_ACCOUNTS,
  url: dataOut(orgId).partnerAccounts,
  payload: filter,
});

export const fetchPartnerAccountsSuccess = payload => ({
  type: DATA_OUT.FETCH_PARTNER_ACCOUNTS_SUCCESS,
  payload,
});

export const fetchPartnerAccountsError = payload => ({
  type: DATA_OUT.FETCH_PARTNER_ACCOUNTS_ERROR,
  payload,
});

export const fetchPartnerAccountFilters = orgId => ({
  type: DATA_OUT.FETCH_PARTNER_ACCOUNT_FILTERS,
  url: dataOut(orgId).partnerAccountFilters,
});

export const fetchPartnerAccountFiltersSuccess = payload => ({
  type: DATA_OUT.FETCH_PARTNER_ACCOUNT_FILTERS_SUCCESS,
  payload,
});

export const fetchPartnerAccountFiltersError = payload => ({
  type: DATA_OUT.FETCH_PARTNER_ACCOUNT_FILTERS_ERROR,
  payload,
});

export const fetchPartnerJobsVerification = (orgId, partnerAccountID, payload) => ({
  type: DATA_OUT.FETCH_PARTNER_JOBS_VERIFICATION,
  url: dataOut(orgId).jobsVerificationStatus(partnerAccountID),
  payload,
});

export const fetchPartnerJobsVerificationError = payload => ({
  type: DATA_OUT.FETCH_PARTNER_JOBS_VERIFICATION_ERROR,
  payload,
});

export const fetchPartnerJobsVerificationSuccess = payload => ({
  type: DATA_OUT.FETCH_PARTNER_JOBS_VERIFICATION_SUCCESS,
  payload,
});

export const verifyJobPartners = (orgId, sourceID, payload) => ({
  type: DATA_OUT.VERIFY_JOB_PARTNERS,
  url: dataOut(orgId).jobPartnersVerification(sourceID),
  payload,
});

export const verifyJobPartnersError = payload => ({
  type: DATA_OUT.VERIFY_JOB_PARTNERS_ERROR,
  payload,
});

export const verifyJobPartnersSuccess = payload => ({
  type: DATA_OUT.VERIFY_JOB_PARTNERS_SUCCESS,
  payload,
});


export const fetchPartners = () => ({
  type: DATA_OUT.FETCH_PARTNERS,
  url: dataOut().partners,
});

export const fetchPartnersSuccess = payload => ({
  type: DATA_OUT.FETCH_PARTNERS_SUCCESS,
  payload,
});

export const fetchPartnersError = payload => ({
  type: DATA_OUT.FETCH_PARTNERS_ERROR,
  payload,
});

export const fetchDataOutSummary = orgId => ({
  type: DATA_OUT.FETCH_DATA_OUT_SUMMARY,
  url: dataOut(orgId).dataOutSummary,
});

export const fetchDataOutSummaryError = payload => ({
  type: DATA_OUT.FETCH_DATA_OUT_SUMMARY_ERROR,
  payload,
});

export const fetchDataOutSummarySuccess = payload => ({
  type: DATA_OUT.FETCH_DATA_OUT_SUMMARY_SUCCESS,
  payload,
});

export const updateActivationStatus = (orgId, jobId, status) => ({
  type: DATA_OUT.UPDATE_ACTIVATION_STATUS,
  url: dataOut(orgId).updateActivationStatus(jobId),
  payload: { status },
});

export const updateActivationStatusSuccess = payload => ({
  type: DATA_OUT.UPDATE_ACTIVATION_STATUS_SUCCESS,
  payload,
});

export const updateActivationStatusError = payload => ({
  type: DATA_OUT.UPDATE_ACTIVATION_STATUS_ERROR,
  payload,
});
