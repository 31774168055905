export const CLEAN_ROOM_USER_LIST = {
  CREATE_CR_USER_LIST: 'CR/UL/CREATE_USER_LIST',
  DELETE_CR_USER_LIST: 'CR/UL/DELETE_CR_USER_LIST',
  FETCH_CR_USER_LIST_DETAILS: 'CR/UL/FETCH_CR_USER_LIST_DETAILS',
  FETCH_CR_DATASET_BY_IDENTITY_TYPE: 'CR/DS/FETCH_CR_DATASET_BY_IDENTITY_TYPE',
  FETCH_CR_USER_LISTS: 'CR/UL/FETCH_CR_USER_LISTS',
  FETCH_CR_USER_LISTS_FILTERS: 'CR/UL/FETCH_CR_USER_LISTS_FILTERS',
  UPDATE_CR_USER_LIST_STATUS: 'CR/UL/UPDATE_CR_USER_LIST_STATUS',
  SAVE_CR_USER_LISTS: 'CR/UL/SAVE',
};
