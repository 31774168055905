import { USER_LISTS } from 'redux/actions/Types';


const initialState = {};

const UserListsReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_LISTS.CHANGE_STATUS:
      return { ...state, detail: undefined };
    case USER_LISTS.CHANGE_STATUS_ERROR:
      return { ...state, detail: { error: action.payload } };
    case USER_LISTS.CHANGE_STATUS_SUCCESS:
      return { ...state, detail: { userList: action.payload } };

    case USER_LISTS.DELETE_USER_LIST:
      return { ...state, meta: { deleting: true } };
    case USER_LISTS.DELETE_USER_LIST_ERROR:
      return { ...state, meta: { deleting: false, deleteError: action.payload } };
    case USER_LISTS.DELETE_USER_LIST_SUCCESS:
      return { ...state, meta: { deleted: true } };

    case USER_LISTS.CREATE_USER_LIST:
      return { ...state, detail: undefined };

    case USER_LISTS.FETCH_UL_FILTERS_ERROR:
      return { ...state, userListFilters: { error: action.payload } };
    case USER_LISTS.FETCH_UL_FILTERS_SUCCESS:
      return { ...state, userListFilters: action.payload };

    case USER_LISTS.FETCH_LIST_DETAIL_RUNS_ERROR:
      return { ...state, runs: { error: action.payload } };
    case USER_LISTS.FETCH_LIST_DETAIL_RUNS_SUCCESS:
      return { ...state, runs: { list: action.payload } };

    case USER_LISTS.FETCH_LIST_SUMMARY_ERROR:
      return { ...state, summaries: { error: action.payload } };
    case USER_LISTS.FETCH_LIST_SUMMARY_SUCCESS:
      return { ...state, summaries: action.payload };

    case USER_LISTS.SAVE_USER_LIST:
      return { ...state, meta: { submitting: true } };
    case USER_LISTS.SAVE_USER_LIST_ERROR:
      return { ...state, meta: { submitting: false, error: action.payload } };
    case USER_LISTS.SAVE_USER_LIST_SUCCESS:
      return { ...state, meta: { submitting: false, updated: true } };

    default: return state;
  }
};

export default UserListsReducer;
